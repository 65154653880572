import { FlatList, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { styles } from "../../styles/Places.style";
import { styles as eventStyles } from "../../styles/EventDetail.style";
import { PageNames } from "../../navigation/NavigationUtils";

interface PropsInterface {
	navigation: any;
	eventsList: any;
	disableNavigation?: boolean;
}

const EventsList = (props: PropsInterface) => {
	return (
		<>
			{props.eventsList.length > 0 ? (
				<FlatList
					data={props.eventsList}
					showsVerticalScrollIndicator={false}
					renderItem={({ item }) => (
						<View style={styles.placeContainer}>
							<Text style={styles.placeName}>{item.name}</Text>
							{item.date && (
								<View style={eventStyles.eventDateTags}>
									<Text style={styles.dateEventTag}>{item.date.day}</Text><Text> | </Text><Text style={styles.dateEventTag}>{item.date.hour}</Text>
								</View>
							)}
							{!props.disableNavigation && <Pressable onPress={() => props.navigation.navigate(PageNames.eventDetail, { event: item.event, navigation: props.navigation })}>
								<Text style={styles.openEvent}>{strings.openEvent}</Text>
							</Pressable>
							}
						</View>
					)}
				/>
			) : (
				<View style={{ height: 300 }}>
					<Text style={styles.emptyState}>{strings.emptyEvents}</Text>
				</View>
			)}
		</>
	);
};

export default EventsList;
