import React from "react";
import { Text, View, FlatList, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { styles } from "../../styles/CreateEventScreen";
import Button from "../Button";
import { colors } from "../../resources/colors";
import Pagination from "../Pagination";

interface PropsInterface {
	users: any;
	setUsers: (value: any) => void;
	userIds: any;
	setUserIds: (value: any) => void;
	filters: any;
	setFilters: (value: any) => void;
	USER_PER_PAGE: number;
	isWeb: boolean;
	isReadOnly: boolean;
	text1: string; 
	text2: string;
	updateStatus?: (id: string, flag: boolean) => void;
}

const UsersList = (props: PropsInterface) => {

	return (
		<>
			<FlatList
				data={props.users.items}
				renderItem={({ item, index }) => {
					return (
						<Pressable
							style={[styles.rowView, styles.usersContainer, item.invited ? { backgroundColor: colors.red2 } : {}]}
							onPress={() => {
								props.isReadOnly
									? null
									: props.setUsers({
											totalCounts: props.users.totalCounts,
											items: [
												...props.users.items.slice(0, index),
												{ ...props.users.items[index], invited: !item.invited },
												...props.users.items.slice(index + 1),
											],
									  });
								if (item.invited) {
									props.userIds.items.splice(props.userIds.items.indexOf(item.id), 1);
									props.updateStatus ? props.updateStatus(item.id, false) : null;
								} else {
									let items: string[] = props.userIds.items;
									items.push(item.id);
									props.setUserIds({ items: items });
									props.updateStatus ? props.updateStatus(item.id, true) : null;
								}
							}}
						>
							<Text style={[styles.fieldDescription, { paddingTop: 5, paddingBottom: 5, margin: 2 }]}>{item.firstName + " " + item.lastName}</Text>
							<Text style={styles.invite}>{item.invited ? props.text2 : props.text1}</Text>
						</Pressable>
					);
				}}
				keyExtractor={(item) => item.id || item.email}
				keyboardShouldPersistTaps="handled"
			/>
			<View style={{ width: "100%" }}>
				<View style={styles.pagination}>
					{props.isWeb ? (
						<Pagination
							pages={Math.ceil(props.users.totalCounts / props.USER_PER_PAGE) || 1}
							currentPage={props.filters.page}
							handlePageSelect={(newPage: number) => props.setFilters({ ...props.filters, page: newPage })}
						/>
					) : Math.ceil(props.users.totalCounts / props.USER_PER_PAGE) > props.filters.page ? (
						<Button
							text={strings.users.loadMore}
							handleButtonPress={() => props.setFilters({ ...props.filters, page: props.filters.page + 1 })}
							filledButton={true}
						/>
					) : null}
				</View>
			</View>
		</>
	);
};

export default UsersList;
