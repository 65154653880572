import { TouchableOpacity } from "react-native";
import { Text, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface IButtonProps {
	text: string;
	selected: boolean;
	onChange: () => void;
	style?: any;
}

const FilterButton = (props: IButtonProps) => {
	return (
		<TouchableOpacity
			style={[props.selected ? styles.buttonSelected : styles.button, props.style && props.style]}
			onPress={() => {
				props.onChange();
			}}
		>
			<Text style={[styles.buttonText, props.selected ? { color: colors.white } : { color: colors.blue2 }]}>{props.text}</Text>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	buttonSelected: {
		alignSelf: "center",
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 10,
		marginLeft: 5,
		marginRight: 5,
		borderRadius: 25,
		height: 35,
		alignContent: "center",
		zIndex: 9999,
		backgroundColor: colors.blueButton,
	},
	button: {
		alignSelf: "center",
		height: 35,
		alignContent: "center",
		zIndex: 9999,
		backgroundColor: colors.white,
		borderColor: colors.blueButton,
		borderRadius: 25,
		borderWidth: 2,
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 8,
		marginLeft: 5,
		marginRight: 5,
	},
	buttonText: {
		fontSize: 14,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		textTransform: "uppercase",
		color: colors.white,
	},
});

export default FilterButton;
