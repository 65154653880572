import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Image, Modal, Pressable, Platform } from "react-native";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { FileInfo, Message } from "../../models/chatModels";
import { FileUtils } from "../../utils/FileUtils";

interface ModalProps {
	visible: boolean;
	setVisible: (isVisible: boolean) => any;
	file: FileInfo | undefined;
	message?: Message
	setLoader?: (isLoader: boolean) => void;
}

const FileOverview = ({ visible, setVisible, file, message, setLoader }: ModalProps) => {
	return (
		<Modal animationType="fade" transparent={true} visible={visible}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => setVisible(false)} />
			<View style={styles.iconsContainer}>
				<View style={{ flexDirection: "row", width: "85%" }}>
					<Pressable onPress={() => setVisible(false)}>
						<FontAwesomeIcon size={20} color={colors.white} icon={faArrowLeft} />
					</Pressable>
					<Text numberOfLines={1} style={styles.fileName}>
						{file?.name}
					</Text>
				</View>

				<Pressable onPress={() => {
					!file?.type.includes('image/') && !!setLoader ? setLoader(true) : null
					!!file && !!message && FileUtils.downloadFile(file, message, setLoader)
				}}>
					<FontAwesomeIcon size={20} color={colors.white} icon={faDownload} />
				</Pressable>
			</View>
			{!!file && (
				<View style={styles.container}>
					<View style={styles.imageContainer}>
						{Platform.OS === "web" ? (
							<Image style={styles.image} source={file.data} />
						) : (
							<Image resizeMode="contain" style={[styles.image]} source={{ uri: file.data }} />
						)}
					</View>
				</View>
			)}
		</Modal>
	);
};

const styles = StyleSheet.create({
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.8,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	iconsContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: Platform.OS === "web" ? "5%" : "10%",
		marginHorizontal: "5%",
	},
	imageContainer: {
		width: Platform.OS === "web" ? "50%" : "80%",
		height: Platform.OS === "web" ? "50%" : "80%",
	},
	image: {
		width: "100%",
		height: "100%",
	},
	fileName: {
		fontFamily: fonts.RalewayBold,
		fontSize: 14,
		marginLeft: 15,
		color: colors.white,
	},
});

export default FileOverview;
