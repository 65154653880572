import { colors } from "../resources/colors";
import { dashboardType } from "../utils/DashboardType";
import { EventActivityStatus, EventStatus } from "../utils/EventStatus";
import { EventParticipationType } from "../utils/types/EventType";
import { Participant } from "../utils/types/Partecipants";

export interface Event {
	id?: string;
	userId: string;
	datetime: Date; // timestamp of creation
	field: string;
	type: string;
	participationType: EventParticipationType.inPerson | EventParticipationType.both | EventParticipationType.online;
	title: string;
	img: string;
	description: string;
	internalOperator?: {
		id?: string;
		firstName?: string;
		lastName?: string;
	};
	externalOperator?: {
		id?: string;
		firstName?: string;
		lastName?: string;
	};
	placeId?: string;
	collaborators?: EventCollaborators[];
	textArea: string; // collaborators text area
	listOfInvitedUser: string[];
	maxNumberOfUsers: string;
	isSubEvent?: boolean;
	correlatedEventId?: string;
	groceryList?: string[];
	menu?: {
		appetizer?: string;
		mainCourse?: string;
		secondCourse?: string;
		dessert?: string;
		other?: string;
	};
	startDate: Date;
	endDate?: Date;
	status: EventStatus;
	place?: {
		_id: string;
		name: string;
		placeManagerId: string;
		coordinates: any[];
		workspaceEmail: string;
		calendarId: string;
	};
	placeFreeText?: string;
	createSubEvent?: boolean;
	participants: [
		{
			userId: string;
			name: string;
			type: Participant
			mode: EventParticipationType;
		}
	],
	chatRoomId: string;
	calendarEventId: string;
	activities?: EventActivity[];
	operatorId?: string;
}

export interface EventActivity {
	_id? : string;
	field : string;
	title?: string;
	startDate?: Date | null;
	endDate?: Date | null;
	place?: {
		_id: string;
		name: string;
		placeManagerId: string;
		coordinates: any[];
		workspaceEmail: string;
		calendarId: string;
	};
	placeFreeText?: string;
	img?: string;
	description?: string;
	externalOperator?: { id: string; firstName: string; lastName: string };
	status: EventActivityStatus | undefined
	placeId?: string;
	externalOperatorId?: string;
}

export interface EventCollaborators {
	n: number | string;
	text: string
}

export interface CalendarEvents {
	[date: string]: AgendaData[];
}
export interface AgendaData {
	name: string;
	start: string;
	end: string;
	type: "unavailable" | "event"
}

export interface PlacesAvailabilityResponse {
	summary: string;
	description: string;
	location: string;
	eventStart: string;
	eventEnd: string;
	id: string;
}

export enum ENUM_COLORS {
	'food' = colors.yellowCard,
	'wellness' = colors.greenCard
}

export const getFieldColor = (field: string) => {
	return ENUM_COLORS[field as keyof typeof ENUM_COLORS];
};

export interface CreateEventImage {
	img: string;
	modified: boolean;
}

export interface CreateEventPlaceImages {
	img1: string;
	img2: string;
	img3: string;
	img4: string;
}

export interface CreateEventCollaborator {
	key: string;
	name: string;
	value: string;
}

export interface CreateEventUser {
	label: string;
	value: string;
	firstName: string;
	lastName: string;
}

export interface CreateEventOperator {
	firstName?: string;
	lastName?: string;
}

export interface CreateEventInvitedUsers {
	items: Array<string>;
}

export interface ActivityDate {
    day: string;
    hour: string;
}