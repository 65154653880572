import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { useEffect, useState } from "react";
import { Pressable, View, TouchableOpacity, Text, StyleSheet } from "react-native";
import { Calendar, CalendarTouchableOpacityProps, ICalendarEventBase, WeekNum, eventCellCss, getDatesInWeek } from "react-native-big-calendar";
import { colors } from "../../resources/colors";

interface BigCalendarWrapperProps {
	height?: number;
	events?: ICalendarEventBase[];
	focusDate?: Date;
	swipeEnabled: boolean;
	onWeekChange?: (Date?: any) => void;

}

const BigCalendar = ({ height, events, focusDate, swipeEnabled, onWeekChange }: BigCalendarWrapperProps) => {
	const strRemove = /Anzitutto \| Evento - /i;

	const [weekDate, setWeekDate] = useState(focusDate);

	useEffect(() => {
		events?.forEach((event) => {
			event.title = event.title.replace(strRemove, "");
		});
	}, [events]);

	useEffect(() => {
		setWeekDate(focusDate);
	}, [focusDate]);

	const eventDuration = <T extends ICalendarEventBase>(event: T): number => {
		return dayjs(event.end).diff(dayjs(event.start), "minute");
	};

	const styleConditions = <T extends ICalendarEventBase>(event: T): boolean => {
		return eventDuration(event) <= 30;
	};

	const changeWeek = (isForward?: boolean, date?: dayjs.Dayjs, weekNum?: WeekNum, locale?: string) => {
		const weeks = !!isForward ?
			getDatesInWeek(dayjs(date).weekday(+7), weekNum, locale) : getDatesInWeek(dayjs(date)!.weekday(-7), weekNum, locale);

		return weeks
	}



	const renderEvent = <T extends ICalendarEventBase>(event: T, touchableOpacityProps: CalendarTouchableOpacityProps) => {
		const styleCondition = styleConditions(event);
		return (
			<TouchableOpacity {...touchableOpacityProps}>
				<Text style={styles.eventTitleStyle} numberOfLines={styleCondition ? 1 : undefined}>
					{event.title}
				</Text>
				{eventDuration(event) >= 30 ? (
					<Text style={styles.eventTimeStyle}>{`${dayjs(event.start).format("HH:mm")} - ${dayjs(event.end).format("HH:mm")}`}</Text>
				) : (
					<></>
				)}
			</TouchableOpacity>
		);
	};

	return (
		<View style={{ flexDirection: "row" }}>
			<Pressable
				onPress={() => {
					setWeekDate(dayjs(weekDate).weekday(-7));
					if (!!onWeekChange) {
						onWeekChange(changeWeek(false, dayjs(weekDate), 1 ,'it'));
					}
				}}
			>
				<FontAwesomeIcon size={20} icon={faAngleLeft} />
			</Pressable>
			<View style={{ flex: 1 }}>
			<Text style={{ textAlign: 'center', color: colors.grey2, fontSize: 16, fontWeight: 'bold' }}>
				{`${dayjs(weekDate!).format('MMMM')} ${dayjs(weekDate!).format('YYYY')}`}</Text>

				<Calendar
					events={events ?? []}
					height={height ?? 600}
					scrollOffsetMinutes={480}
					weekStartsOn={1}
					locale="it"
					date={weekDate}
					swipeEnabled={swipeEnabled}
					hourRowHeight={60}
					eventCellStyle={(event) => {
						if (styleConditions(event)) {
							return {
								padding: 0,
								paddingLeft: 3,
								flex: 1,
								flexDirection: "column",
								minWidth: "33%",
								maxWidth: "100%",
							};
						} else {
							return { eventCellCss };
						}
					}}
					renderEvent={renderEvent}

				/>
			</View>
			<Pressable
				onPress={() => {
					setWeekDate(dayjs(weekDate).weekday(+7));
					if (!!onWeekChange) {
						onWeekChange(changeWeek(true, dayjs(weekDate), 1 ,'it'));
					}
				}}
			>
				<FontAwesomeIcon size={20} icon={faAngleRight} style={{ marginLeft: 30 }} />
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	eventTitleStyle: {
		fontSize: 12,
		color: colors.white,
	},
	eventTimeStyle: {
		fontSize: 10,
		color: colors.white,
	},
});

export default BigCalendar;
