import { ActionSheetProvider, connectActionSheet, useActionSheet } from "@expo/react-native-action-sheet";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationProp } from "@react-navigation/core";
import dayjs from "dayjs";
import "dayjs/locale/it";
import * as localeData from "dayjs/plugin/localeData";
import "intl";
import "intl/locale-data/jsonp/it";
import { useEffect, useRef, useState } from "react";
import { Alert, Image, Platform, Pressable, Text, View, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { registerTranslation } from "react-native-paper-dates";
import RadioGroup, { RadioButton, RadioButtonProps } from "react-native-radio-buttons-group";
import { SafeAreaView } from "react-native-safe-area-context";
import BasicAlert from "../components/Alerts/BasicAlert";
import Button from "../components/Button";
import CustomTextInput from "../components/CustomTextInput";
import DatePickerComponent from "../components/Datepicker";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import MultiselectComponent from "../components/MultiselectComponent";
import PageNavigation from "../components/PageNavigation";
import PageNumber from "../components/PageNumber";
import PrivacyPolicyAlert from "../components/PrivacyPolicyAlert";
import ProfileCreatedAlert from "../components/ProfileCreatedAlert";
import SearchBarWithAutocomplete from "../components/SearchBarWithAutocomplete";
import BasicToast from "../components/Toasts/BasicToast";
import { strings } from "../locales/strings";
import { ProfileImageMapper } from "../mappers/ProfileImageMapper";
import { ImageInfo, LoginResponse, Profile, ProfileImageRequest, Roles, ValueType } from "../models/models";
import { PageNames } from "../navigation/NavigationUtils";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { images } from "../resources/images";
import { EndPoints } from "../services/EndPoints";
import { NotificationEndPoints } from "../services/NotificationEndPoint";
import { styles } from "../styles/SignUp.style";
import { AlertTypes } from "../utils/AlertTypes";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { AttachmentPicker } from "../utils/AttachmentPicker";
import { genders } from "../utils/Genders";
import { roles } from "../utils/Roles";
import { rules } from "../utils/Rules";
import { RecaptchaHandles } from "react-native-recaptcha-that-works";
import RecaptchaComponent from "../components/Recaptcha/RecapatchaComponent";
import { MacroAreaType } from "../utils/types/MacroAreaType";
import Select from "../components/Select";

export type PredictionType = {
	description: string;
	place_id: string;
	reference: string;
	matched_substrings: any[];
	tructured_formatting: Object;
	terms: Object[];
	types: string[];
};

registerTranslation("it", {
	save: strings.save,
	selectSingle: strings.select,
	selectMultiple: strings.select,
	selectRange: strings.select,
	notAccordingToDateFormat: strings.notAccordingToDateFormat,
	mustBeHigherThan: strings.mustBeHigherThan,
	mustBeLowerThan: strings.mustBeLowerThan,
	mustBeBetween: strings.mustBeBetween,
	dateIsDisabled: strings.dateIsDisabled,
	previous: strings.previous,
	next: strings.next,
	typeInDate: strings.typeInDate,
	pickDateFromCalendar: strings.pickDateFromCalendar,
	close: strings.close,
});

const attachmentPicker = new AttachmentPicker();

dayjs.locale("it");
if (Platform.OS === "web") {
	dayjs.extend(localeData as any);
}

interface PropsInterface {
	navigation: NavigationProp<any>;
	showActionSheetWithOptions: any;
	route: {
		params: {
			signupFromCoordinator: boolean;
		};
	};
}

export const SignUpScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		console.log("GENERATE PW" + getGeneratedPassword());
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
	}, [props]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const [isFirstPage, setIsFirstPage] = useState(true);

	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [birthDate, setBirthDate] = useState<Date | undefined>();
	const [invalidBirthDate, setInvalidBirthDate] = useState(false);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [phoneNumber, setPhoneNumber] = useState("");
	const [fiscalCode, setfiscalCode] = useState("");
	const [VAT, setVAT] = useState("");
	const [parentPhoneNumber, setParentPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [coordinates, setCoordinates] = useState({ lat: "", lng: "" });
	const [token, setToken] = useState("");

	const [note, setNote] = useState("");
	const [profilePicture, setProfilePicture] = useState("");
	const [profilePictureLoaded, setProfilePictureLoaded] = useState(false);
	const [profilePictureObject, setProfilePictureObject] = useState<ProfileImageRequest | null>(null);

	const [invalidEmail, setInvalidEmail] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [passwordNotMatching, setPasswordNotMatching] = useState(false);
	const [invalidReCapatcha, setInvalidRecapatcha] = useState(true);

	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [roleMultiselectVisible, setRoleMultiselectVisible] = useState(false);

	const [profileCreatedAlertVisible, setProfileCreatedAlertVisible] = useState(false);
	const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

	const [errorAlert, setErrorAlert] = useState(false);
	const [errorTitle, setErrorTitle] = useState(strings.errorTitle);
	const [errorText, setErrorText] = useState(strings.errorText);
	const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });

	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const clearFields = () => {
		setIsFirstPage(true);
		setName("");
		setSurname("");
		setBirthDate(undefined);
		setEmail("");
		setPassword("");
		setConfirmPassword("");
		setPhoneNumber("");
		setfiscalCode("");
		setVAT("");
		setScope("");
		setParentPhoneNumber("");
		setAddress("");
		setNote("");
		setToken("");
		setProfilePicture("");
		setProfilePictureLoaded(false);
		setProfilePictureObject(null);
		setInvalidEmail(false);
		setInvalidPassword(false);
		setInvalidRecapatcha(true);
		setPasswordNotMatching(false);
		setPrivacyPolicy(false);
		setGender("");
		setWebRoles(undefined);
		setMobileRole1(undefined);
		setMobileRole2(undefined);
		setCoordinates({ lat: "", lng: "" });
		radioButtons.forEach((value) => {
			value.selected = false;
		});
	};

	const genderSelection: any[] = [
		{
			id: "1",
			label: strings.woman,
			value: genders.woman,
			labelStyle: { fontFamily: fonts.RalewayRegular, fontSize: 14 },
		},
		{
			id: "2",
			label: strings.man,
			value: genders.man,
			labelStyle: { fontFamily: fonts.RalewayRegular, fontSize: 14 },
		},
		{
			id: "3",
			label: strings.other,
			value: genders.other,
			labelStyle: { fontFamily: fonts.RalewayRegular, fontSize: 14 },
		},
		{
			id: "4",
			label: strings.notShare,
			value: genders.notShare,
			labelStyle: { fontFamily: fonts.RalewayRegular, fontSize: 14 },
		},
	];

	const [gender, setGender] = useState("");
	const [radioButtons, setRadioButtons] = useState(genderSelection);

	const recaptcha = useRef<RecaptchaHandles>(null);

	const openRecaptcha = () => {
		recaptcha.current?.open();
	};

	const validateEmail = (email: string) => {
		return rules.emailRegex.test(email) === true;
	};

	const validatePassword = (password: string) => {
		return rules.passwordRegex.test(password) === true;
	};

	const checkPassword = async (doSignUp: boolean, tokenRecaptcha?: string) => {
		try {
			await EndPoints.checkPassword(props.route.params.signupFromCoordinator ? getGeneratedPassword() : password);
			doSignUp ? signUp(tokenRecaptcha) : null;
		} catch (e: any) {
			showErrorAlert(e.status, e.error.message);
		}
	};

	const { showActionSheetWithOptions } = useActionSheet();
	const addProfilePicture = async () => {
		const imageInfo = Platform.OS === "web" ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
		if (imageInfo) {
			if (Platform.OS === "web" && !(imageInfo as ImageInfo).uri.includes("image")) {
				setErrorToastVisible({ isVisible: true, text: strings.errorPhotoUploaded });
			} else {
				if (attachmentPicker.isSmallSize(Platform.OS === "web" ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
					if (Platform.OS === "web") {
						setProfilePicture((imageInfo as ImageInfo).uri);
					} else {
						setProfilePicture(
							Platform.OS === "android" && (imageInfo as ImageInfo[])[0].uri ? (imageInfo as ImageInfo[])[0].uri : (imageInfo as ImageInfo[])[0].fileURI
						);
					}
					setProfilePictureLoaded(true);
					const image = Platform.OS === "web" ? (imageInfo as ImageInfo) : (imageInfo as ImageInfo[])[0];
					setProfilePictureObject(Platform.OS === "web" ? ProfileImageMapper.mapWeb(image) : ProfileImageMapper.mapMobile(image));
				} else {
					Platform.OS === "web"
						? setErrorToastVisible({ isVisible: true, text: strings.errorPhotoTooBig })
						: showErrorAlert(strings.error, strings.errorPhotoTooBig);
				}
			}
		}
	};

	const openActionSheet = () => {
		const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
		const destructiveButtonIndex = undefined;
		const cancelButtonIndex = 2;
		return new Promise((resolve, reject) => {
			showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
				switch (buttonIndex) {
					case 0: {
						attachmentPicker._pickFromGallery("photo", resolve, reject);
						break;
					}
					case 1: {
						attachmentPicker._takePhoto(resolve, reject);
						break;
					}
					case 2: {
						break;
					}
				}
			});
		});
	};

	const deleteProfilePicture = async () => {
		if (Platform.OS === "web") {
			setProfilePicture("");
			setProfilePictureLoaded(false);
			setProfilePictureObject(null);
		} else {
			Alert.alert(strings.sureToDeleteImage, "", [
				{
					text: strings.no,
					onPress: () => console.log("Cancel Pressed"),
					style: "cancel",
				},
				{
					text: strings.yes,
					onPress: () => {
						setProfilePicture("");
						setProfilePictureLoaded(false);
						setProfilePictureObject(null);
					},
				},
			]);
		}
	};

	const signUp = async (tokenRecaptcha?: string) => {
		setLoaderVisible(true);
		try {
			const rolesSelected = (Platform.OS === "web" ? webRoles : [mobileRole1, mobileRole2])?.filter((r) => !!r);
			const pw = props.route.params.signupFromCoordinator ? getGeneratedPassword() : password;

			let roleList: Array<Roles | roles.user | undefined>;
			if (props.route.params.signupFromCoordinator && rolesSelected?.length) {
				roleList = rolesSelected;
			} else {
				roleList = [roles.user];
			}

			const user: Profile = {
				firstName: name,
				lastName: surname,
				birthDate: birthDate || new Date(),
				email: email,
				gender: gender,
				address: address,
				coordinates: coordinates,
				phoneNumber: phoneNumber,
				field: scope,
				fiscalCode: fiscalCode,
				VAT: VAT,
				relativesPhoneNumber: parentPhoneNumber,
				notes: note,
				passwordToBeChanged: props.route.params.signupFromCoordinator,
				readTermsAndConditions: {
					[roleList[0]]: {
						general: privacyPolicy,
					},
				},
				roles: roleList,
				password: pw,
				tokenRecaptcha: tokenRecaptcha ?? token,
			};

			if (props.route.params.signupFromCoordinator) {
				user.temporaryPassword = pw;
			}

			let profileSaved;
			try {
				profileSaved = await EndPoints.updateProfile(user);
			} catch (e: any) {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				showErrorAlert(e.status, e.error.message);
				return;
			}

			if (!props.route.params.signupFromCoordinator && !!profileSaved) {
				const loginRes: LoginResponse = await EndPoints.logIn({ username: email, password: pw });
				await AsyncStorage.setItem(asyncStorageUtils.accessToken, loginRes.item.accessToken);
				await AsyncStorage.setItem(asyncStorageUtils.refreshToken, loginRes.item.refreshToken);
				await AsyncStorage.setItem(asyncStorageUtils.role, loginRes.item.role);
				await AsyncStorage.setItem(asyncStorageUtils.userId, profileSaved.item.id);
				await AsyncStorage.setItem(asyncStorageUtils.name, profileSaved.item.firstName + " " + profileSaved.item.lastName);
				await AsyncStorage.setItem(asyncStorageUtils.notificationsActive, String(profileSaved.item.settings?.notificationsActive!));

				NotificationEndPoints.getCountNotifications().then((res) => {
					const countNotifications = res.item?.notifications;

					AsyncStorage.setItem(asyncStorageUtils.inviteCount, String(countNotifications));
				});

				try {
					if (!!profilePictureObject) {
						await EndPoints.uploadProfilePicture(profilePictureObject!);
					}
				} catch (e: any) {
					if (Platform.OS === "ios") {
						setTimeout(() => {
							setLoaderVisible(false);
						}, 500);
					} else {
						setLoaderVisible(false);
					}
					showErrorAlert(e.status, e.error.message);
					return;
				}
			}
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			setProfileCreatedAlertVisible(true);
		} catch (e: any) {
			showErrorAlert(e.status, e.error.message);
		}
	};

	var getGeneratedPassword = (wishlist = rules.passwordRules) => {
		while (true) {
			const pw = Array(12)
				.fill("")
				.map((e, i) => wishlist[Math.floor(Math.random() * wishlist.length)])
				.join("");
			if (validatePassword(pw)) {
				return pw;
			}
		}
	};
	const [mobileRole1, setMobileRole1] = useState<Roles>();
	const [mobileRole2, setMobileRole2] = useState<Roles>();
	const [webRoles, setWebRoles] = useState<Array<Roles>>();

	const showErrorAlert = (title: string, text: string) => {
		setErrorTitle(title);
		setErrorText(text);
		setErrorAlert(true);
	};
	const [loaderVisible, setLoaderVisible] = useState(false);

	const [scope, setScope] = useState<ValueType>();

	const selectType = (value: any) => {
		setScope(value);
	};


	return (
		<SafeAreaView style={styles.safeArea}>
			<Header width={width} navigation={props.navigation} onPress={() => clearFields()} showMenu={props.route.params.signupFromCoordinator} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicToast
				title={errorToastVisible.text}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible.isVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
			/>

			<ProfileCreatedAlert
				signupFromCoordinator={props.route.params.signupFromCoordinator}
				modalVisible={profileCreatedAlertVisible}
				setModalVisible={(isVisible: boolean) => {
					setProfileCreatedAlertVisible(isVisible);
					clearFields();
					props.navigation.navigate(PageNames.home);
				}}
			/>

			<PrivacyPolicyAlert
				modalVisible={privacyPolicyVisible}
				termsAndConditions={false}
				setPrivacyPolicyVisible={(isVisible: boolean) => setPrivacyPolicyVisible(isVisible)}
				changeTermAndConditionsStatus={false}
			/>

			<BasicAlert
				title={errorTitle}
				description={errorText}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={errorAlert}
				setAlertVisible={(isVisible: boolean) => setErrorAlert(isVisible)}
			/>

			<KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : Platform.OS === "android" ? "height" : undefined}
				style={{ flex: 1 }}>
				<TouchableWithoutFeedback onPress={() => (Platform.OS === "web" ? null : Keyboard.dismiss)}>

					<ScrollView style={{ marginTop: 30, paddingBottom: 100 }} ref={(c) => setScrollRefScreen(c)}>
						{Platform.OS === "web" ?
							<PageNavigation navigationEvent={clearFields} />
							: null}

						<View style={styles.container}>
							<Text style={styles.title}>{props.route.params.signupFromCoordinator ? strings.signupAnzituttoOperator : strings.signUpAnzitutto}</Text>

							<PageNumber isFirstPage={isFirstPage} />

							<View style={styles.cardSignUp}>
								<View style={isWeb ? styles.rowView : null}>
									{isFirstPage ? (
										<>
											<View
												style={[isWeb ? { width: "50%", marginTop: 20 } : null, roleMultiselectVisible && Platform.OS === "web" ? { marginBottom: 85 } : null]}
											>
												<View style={isWeb ? styles.nameRow : null}>
													{/* name */}
													<CustomTextInput
														value={name}
														placeholder={strings.insertName}
														title={strings.name}
														obligatory={true}
														halfWidth={isWeb}
														setValue={(value: string) => setName(value)}
													/>

													{/* surname */}
													<CustomTextInput
														value={surname}
														placeholder={strings.insertSurname}
														title={strings.surname}
														obligatory={true}
														halfWidth={isWeb}
														setValue={(value: string) => setSurname(value)}
													/>
												</View>

												{/* birthDate */}
												<View style={styles.titleContainer}>
													<Text style={styles.textInputTitle}>{strings.birthDate}</Text>
													<Text style={styles.asterisk}>*</Text>
												</View>
												<DatePickerComponent
													styleTextInput={invalidBirthDate ? [{ borderColor: colors.red }] : null}
													onDateChange={(value: Date) => {
														if (dayjs(value).isValid()) {
															setBirthDate(value);
															setInvalidBirthDate(false);
														} else {
															setInvalidBirthDate(true);
														}
													}}
													value={birthDate}
												/>
												{invalidBirthDate ? <Text style={styles.errorText}>{strings.invalidBirthDate}</Text> : null}

												{/* gender */}
												<View style={styles.titleContainer}>
													<Text style={styles.textInputTitle}>{strings.gender}</Text>
													<Text style={styles.asterisk}>*</Text>
												</View>
												<RadioGroup
													containerStyle={styles.gender}
													radioButtons={radioButtons}
													layout={isWeb ? "row" : "column"}
													onPress={(radioButtons: RadioButtonProps[]) => {
														radioButtons.forEach((value) => {
															value.selected && value.value != null ? setGender(value.value) : null;
														});
														setRadioButtons(radioButtons);
													}}
												/>

												{/* email */}
												<CustomTextInput
													value={email}
													placeholder={strings.insertEmail}
													title={strings.email}
													obligatory={true}
													styleTextInput={invalidEmail ? [{ borderColor: colors.red }] : null}
													onBlur={() => {
														validateEmail(email) || email.length == 0 ? setInvalidEmail(false) : setInvalidEmail(true);
													}}
													setValue={(value: string) => {
														setEmail(value);
														validateEmail(email) || email.length == 0 ? setInvalidEmail(false) : null;
													}}
												/>
												{invalidEmail ? <Text style={styles.errorText}>{strings.emailNotValid}</Text> : null}

												{props.route?.params?.signupFromCoordinator ? (
													<View>
														<View style={styles.titleContainer}>
															<Text style={styles.textInputTitle}>{strings.role}</Text>
															<Text style={styles.asterisk}>*</Text>
														</View>

														<MultiselectComponent
															setWebRoles={(value?: Array<Roles>) => setWebRoles(value)}
															setMobileRole1={(value?: Roles) => setMobileRole1(value)}
															setMobileRole2={(value?: Roles) => setMobileRole2(value)}
															isVisible={(value: any) => setRoleMultiselectVisible(value)}
															value={Platform.OS === "web" ? webRoles : [mobileRole1, mobileRole2]}
														/>
													</View>
												) : (
													<>
														<View style={isWeb ? [styles.rowView, styles.noMargin] : null}>
															{/* password */}
															<CustomTextInput
																value={password}
																placeholder={strings.insertPassword}
																title={strings.password}
																obligatory={true}
																secureTextEntry={true}
																halfWidth={isWeb}
																onBlur={() => {
																	validatePassword(password) || password.length == 0 ? setInvalidPassword(false) : setInvalidPassword(true);
																	checkPassword(false);
																	password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : setPasswordNotMatching(true);
																}}
																styleTextInput={invalidPassword ? [{ borderColor: colors.red }] : null}
																setValue={(value: string) => {
																	setPassword(value);
																	validatePassword(password) || password.length == 0 ? setInvalidPassword(false) : null;
																	password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : null;
																}}
															/>
															{invalidPassword && !isWeb ? <Text style={styles.errorText}>{strings.passwordNotValid}</Text> : null}

															{/* confirm password */}
															<CustomTextInput
																value={confirmPassword}
																placeholder={strings.insertConfirmPassword}
																title={strings.confirmPassword}
																obligatory={true}
																secureTextEntry={true}
																halfWidth={isWeb}
																styleTextInput={passwordNotMatching ? [{ borderColor: colors.red }] : null}
																onBlur={() => {
																	password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : setPasswordNotMatching(true);
																}}
																setValue={(value: string) => {
																	setConfirmPassword(value);
																	password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : null;
																}}
															/>
															{passwordNotMatching && !isWeb ? <Text style={styles.errorText}>{strings.passwordsNotMatch}</Text> : null}
														</View>
														{isWeb ? (
															<View style={styles.rowView}>
																{invalidPassword ? (
																	<Text style={[styles.errorTextWeb, { marginLeft: "3%" }]}>{strings.passwordNotValid}</Text>
																) : (
																	<Text style={styles.errorTextWeb}></Text>
																)}
																{passwordNotMatching ? <Text style={styles.errorTextWeb}>{strings.passwordsNotMatch}</Text> : null}
															</View>
														) : null}
													</>
												)}
											</View>

											<View style={isWeb ? { width: "50%", marginTop: 20 } : null}>
												{/* web profile picture */}
												{isWeb && !props.route.params.signupFromCoordinator ? (
													<View style={styles.profilePictureWebContainer}>
														<Text style={[styles.loadPicture, { textAlign: "center" }]}>{strings.loadYourImage}</Text>
														{profilePictureLoaded ? (
															<>
																<View style={styles.profilePictureWeb}>
																	<Image resizeMode="cover" style={[styles.profilePicture, styles.alignCenter]} source={{ uri: profilePicture }}></Image>
																</View>

																<View style={{ flexDirection: "row", justifyContent: "center" }}>
																	<View style={styles.buttonProfilePictureWebContainer}>
																		<Button
																			handleButtonPress={() => addProfilePicture()}
																			text={strings.changeImage}
																			style={styles.buttonProfilePictureWeb}
																			filledButton={true}
																		/>
																	</View>
																	<View style={styles.buttonProfilePictureWebContainer}>
																		<Button
																			handleButtonPress={() => deleteProfilePicture()}
																			text={strings.deleteImage}
																			style={styles.buttonProfilePictureWeb}
																			filledButton={false}
																		/>
																	</View>
																</View>
															</>
														) : (
															<>
																<View>
																	<FontAwesomeIcon style={[styles.profilePicture, { alignSelf: "center" }]} size={140} icon={faUserCircle} />
																	<Button
																		handleButtonPress={() => addProfilePicture()}
																		text={strings.uploadImage}
																		style={{ alignSelf: "center" }}
																		filledButton={true}
																	/>
																</View>
															</>
														)}
														{/* web note */}
														<CustomTextInput
															numberOfLine={4}
															maxLenght={500}
															value={note}
															placeholder={strings.insertNote}
															title={""}
															obligatory={false}
															styleTextInput={styles.noteWeb}
															setValue={(value: string) => setNote(value)}
														/>
													</View>
												) : (
													<>
														{isWeb && props.route.params.signupFromCoordinator ? (
															<View style={[styles.imageFirstPageContainerCoordinator]}>
																<Image style={styles.image} source={images.signupImg}></Image>
															</View> 
														) : null}
													</>
												)}
											</View>
										</>
									) : (
										<>
											<View style={isWeb ? { width: "50%", marginTop: 20 } : null}>
												{/* phone number */}
												<CustomTextInput
													value={phoneNumber}
													placeholder={strings.insertPhoneNumber}
													title={strings.phoneNumber}
													obligatory={true}
													keyboardType={"numeric"}
													setValue={(value: string) => setPhoneNumber(value)}
													fieldValidationPattern={rules.phoneNumberRegex}
													patternErrorMsg={strings.phoneNumberNotValid}
												/>

												{props.route.params.signupFromCoordinator ? null : (
													<>
														{/* parent phone number */}
														<CustomTextInput
															value={parentPhoneNumber}
															placeholder={strings.insertParentPhoneNumber}
															title={strings.parentPhoneNumber}
															obligatory={false}
															keyboardType={"numeric"}
															setValue={(value: string) => setParentPhoneNumber(value)}
															fieldValidationPattern={rules.phoneNumberRegex}
															patternErrorMsg={strings.phoneNumberNotValid}
														/>
													</>
												)}

												{/* address */}
												<View style={styles.titleContainer}>
													<Text style={styles.textInputTitle}>{strings.address}</Text>
													<Text style={styles.asterisk}>*</Text>
												</View>
												<View style={{ zIndex: 9999 }}>
													<SearchBarWithAutocomplete
														placeholder={strings.insertAddress}
														setAddress={(value: any) => setAddress(value)}
														setCoordinates={(lat: any, lng: any) => setCoordinates({ lat: lat, lng: lng })}
														address={address}
													/>
												</View>

												{((Platform.OS === 'web' && webRoles?.includes(roles.externalUser)) || (Platform.OS !== 'web' && (mobileRole1 === roles.externalUser || mobileRole2 === roles.externalUser))) &&
													<View style={isWeb ? null : { width: "100%", marginTop: 20 }}>
														<View style={isWeb ? styles.externalView : null}>

															{/*Scope */}
															<View style={isWeb ? styles.select : null}>
																<View style={styles.titleContainer}>
																	<Text style={styles.textInputTitle}>{strings.scope}</Text>
																	<Text style={styles.asterisk}>*</Text>
																</View>
																<View style={{ zIndex: 9999 }}>
																	<Select
																		options={
																			MacroAreaType.map(
																				(scope) => ({
																					value: scope.type,
																					label: scope.label,
																				})
																			) || []
																		}
																		placeholder={strings.select}
																		value={scope}
																		onChange={selectType}
																	/>
																</View>
															</View>
															<View style={{marginLeft:'3%',marginBottom:20}}>
															<Select

																options={
																	MacroAreaType.map(
																		(scope) => ({
																			value: scope.type,
																			label: scope.label,
																		})
																	) || []
																}
																placeholder={strings.select}
																value={scope}
																onChange={
																	selectType
																}
															/>
															</View>

															{/*cf*/}
															<View style={isWeb ? { width: "33%" } : { width: '100%' }}>
																<CustomTextInput
																	value={fiscalCode}
																	placeholder={strings.insertFiscalCode}
																	title={strings.fiscalCode}
																	obligatory={false}
																	keyboardType={"default"}
																	setValue={(value: string) => setfiscalCode(value)}

																/>
															</View>
															<View style={isWeb ? { width: "33%" } : { width: '100%' }}>

																{/*VAT*/}
																<CustomTextInput
																	value={VAT}
																	placeholder={strings.insertVat}
																	title={strings.vat}
																	obligatory={false}
																	keyboardType={"default"}
																	setValue={(value: string) => setVAT(value)}

																/>
															</View>
														</View>
													</View>}

												{!isWeb ? (
													<>
														{/* iOS and Android note */}
														<CustomTextInput
															numberOfLine={4}
															maxLenght={500}
															value={note}
															obligatory={false}
															placeholder={strings.insertNote}
															title={strings.note}
															setValue={(value: string) => setNote(value)}
														/>
													</>
												) : null}

												{/* iOS and Android profile picture */}
												{!isWeb && !props.route.params.signupFromCoordinator ? (
													<View style={{ marginLeft: "5%", marginRight: "5%", marginTop: 20, marginBottom: 20 }}>
														<Text style={styles.loadPicture}>{strings.loadYourImage}</Text>
														<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
															{profilePictureLoaded ? (
																<>
																	<View style={styles.profilePictureMobile}>
																		<Image resizeMode="cover" style={[styles.profilePicture]} source={{ uri: profilePicture }}></Image>
																	</View>
																	<View style={styles.profilePictureMobile}>
																		<Button
																			handleButtonPress={() => addProfilePicture()}
																			text={strings.changeImage}
																			style={styles.buttonProfilePictureMobile}
																			filledButton={true}
																		/>
																		<Button
																			handleButtonPress={() => deleteProfilePicture()}
																			text={strings.deleteImage}
																			style={styles.buttonProfilePictureMobile}
																			filledButton={false}
																		/>
																	</View>
																</>
															) : (
																<>
																	<View style={styles.profilePictureMobile}>
																		<FontAwesomeIcon style={[styles.profilePicture]} size={140} icon={faUserCircle} />
																	</View>
																	<View style={styles.profilePictureMobile}>
																		<Button
																			handleButtonPress={() => addProfilePicture()}
																			text={strings.uploadImage}
																			style={styles.buttonProfilePictureMobile}
																			filledButton={true}
																		/>
																	</View>
																</>
															)}
														</View>
													</View>
												) : (
													<View style={{ marginLeft: "5%", marginRight: "5%", marginTop: 20, marginBottom: 20 }}></View>
												)}

												{/*Recaptcha */}

												<View style={[isWeb ? [styles.rowView, { zIndex: -1 }] : {},
												webRoles?.includes(roles.externalUser) ? { marginBottom: 10 } : { marginTop: 20, marginBottom: 90 }]}>
													<RecaptchaComponent
														action="login"
														forwardedRef={recaptcha}
														onVerify={(token) => {
															setToken(token);
															setInvalidRecapatcha(false);
															if (Platform.OS !== "web") {
																checkPassword(true, token);
															}
														}}
														onExpired={() => {
															setInvalidRecapatcha(true);
														}}
														onError={() => {
															recaptcha?.current?.close();
														}}
													></RecaptchaComponent>
												</View>
												{/* terms and conditions */}
												{props.route.params.signupFromCoordinator ? null : (
													<View style={styles.privacyPolicyContainer}>
														<View style={styles.radioButtonPrivacyPolicy}>
															<RadioButton
																containerStyle={styles.checkboxContainer}
																layout="row"
																selected={privacyPolicy}
																onPress={() => setPrivacyPolicy(!privacyPolicy)}
																id={strings.privacyPolicy}
															/>
														</View>
														<View>
															<Pressable onPress={() => setPrivacyPolicyVisible(true)}>
																<View style={{ flexDirection: "row" }}>
																	<Text style={styles.checkBoxText}>{strings.acceptTermsAndConditions}</Text>
																	<Text style={styles.asterisk}> *</Text>
																</View>
															</Pressable>
														</View>
													</View>
												)}
											</View>

											{isWeb ? (
												<View style={[isWeb ? styles.imageSecondPageContainer : null, props.route.params.signupFromCoordinator ? { height: 530 } : null]}>
													<Image style={styles.image} source={images.login}></Image>
												</View>
											) : null}
										</>
									)}
								</View>

								<View style={{ marginBottom: 20, marginTop: 20, zIndex: 0 }}>
									{isFirstPage ? (
										<View style={[isWeb && props.route.params.signupFromCoordinator ? { width: "50%", marginTop: 20 } : null]}>
											{roleMultiselectVisible && Platform.OS === "web" ? null : (
												<Button
													handleButtonPress={() => { scrollToTop(); setIsFirstPage(false); }}
													text={strings.forward}
													filledButton={true}
													disabled={
														name == "" ||
														surname == "" ||
														!birthDate ||
														birthDate.toString() == "" ||
														invalidBirthDate ||
														email == "" ||
														!validateEmail(email) ||
														gender == "" ||
														(props.route.params.signupFromCoordinator
															? Platform.OS === "web"
																? webRoles === undefined || webRoles.length === 0
																: mobileRole1 === undefined || mobileRole1.length === 0
															: password == "" || confirmPassword == "" || password != confirmPassword)
													}
													style={styles.forwardButton}
												/>
											)}
										</View>
									) : (
										<View
											style={isWeb ? styles.buttonsView : null}
										>
											<View style={[styles.rowView, !isWeb ? styles.alignCenter : null]}>
												<Button handleButtonPress={() => {
													scrollToTop(); setToken("");
													setInvalidRecapatcha(true); setIsFirstPage(true);
												}} text={strings.back} style={{ marginRight: 10 }} filledButton={false} />
												<Button
													handleButtonPress={() => {
														if (Platform.OS !== "web") {
															openRecaptcha();
														} else {
															checkPassword(true);
														}
													}}
													style={{ marginLeft: 10 }}
													disabled={phoneNumber === "" || address === "" || gender === "" ||   (props.route.params.signupFromCoordinator ? false : !privacyPolicy) ||
														(Platform.OS === "web" && invalidReCapatcha)
													|| webRoles?.includes(roles.externalUser)  && scope === undefined }
													text={strings.createProfile}
													filledButton={true}
												/>
											</View>
										</View>
									)}
								</View>
							</View>
						</View>
						<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={false} />
					</ScrollView>
				</TouchableWithoutFeedback>
			</KeyboardAvoidingView>
		</SafeAreaView >
	);
};

const SignUpScreenConnected = connectActionSheet(SignUpScreen);

const SignUpScreenConnectedContainer = (props: PropsInterface) => {
	return (
		<ActionSheetProvider>
			<SignUpScreenConnected
				navigation={props.navigation}
				showActionSheetWithOptions={props.showActionSheetWithOptions}
				route={{
					params: {
						signupFromCoordinator: props.route.params.signupFromCoordinator,
					},
				}}
			/>
		</ActionSheetProvider>
	);
};

export default SignUpScreenConnectedContainer;
