import { ImageInfo, ProfileImageRequest } from "../models/models";

export class ProfileImageMapper {
	static mapWeb(input: ImageInfo): ProfileImageRequest {
		return {
			fileURI: input.uri,
		};
	}

	static mapMobile(input: ImageInfo): ProfileImageRequest {
		return {
			fileURI: input.base64,
			fileType: input.fileType,
			fileExtension: input.fileExtension,
			fileName: input.fileName,
		};
	}
}
