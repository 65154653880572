import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useNavigation } from "@react-navigation/native";
import { Text, StyleSheet, View, Platform, Pressable } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { PageNames } from "../navigation/NavigationUtils";
import { strings } from "../locales/strings";
import { Event, EventActivity } from "../models/eventsModels";

interface props {
	navigationEvent?: () => void;
}

const PageNavigation = ({ navigationEvent }: props) => {
	const navigation = useNavigation();

	// List of routes from which the breadcrumb must start (after Home). If the route is not listed here it can appear as a child of any other route
	const mainRoutes: Array<PageNames> = [
		PageNames.dashboard,
		PageNames.places,
		PageNames.users,
		PageNames.profile,
		PageNames.yourEvents,
		PageNames.login,
		PageNames.signup,
		PageNames.settings,
		PageNames.createEventFood,
		PageNames.createEventWellness,
		PageNames.groups,
	];

	const handleBreadcrumbNavigation = (route: ReturnType<typeof getRouteByKey>): void => {
		if (route?.name) {
			if (navigationEvent) {
				navigationEvent();
			}
			navigation.navigate(route.name, route.params);
		}
	};

	const getRouteByKey = (key: string) => {
		return navigation.getState().routes.find((route) => route.key === key);
	};

	const getRouteByName = (name: string) => {
		return navigation.getState().routes.find((route) => route.name === name);
	};

	const getPageName = (route: ReturnType<typeof getRouteByKey>): string => {
		if (!route) {
			console.error("Breadcrumb route is not defined");
			return "...";
		}

		let pageName;

		switch (route.name as PageNames) {
			case PageNames.eventDetail:
				pageName = (route.params as unknown as { event: Event })?.event.title;
				break;

			case PageNames.activityDetail:
				pageName = (route.params as unknown as { activity: EventActivity })?.activity.title;
				break;
		}

		return pageName || route.name;
	};

	// Keep only latest main route (prevent too long breadcrumb)
	let history = navigation.getState().history;
	if (history) {
		for (let i = history.length - 1; i >= 0; i--) {
			const route = getRouteByKey((history[i] as any)?.key);

			if (route?.name && mainRoutes.includes(route.name as PageNames)) {
				history.splice(0, i);
				break;
			}
		}
	}

	return (
		<View style={styles.container}>
			<Pressable onPress={() => handleBreadcrumbNavigation(getRouteByName(PageNames.home))}>
				<Text style={[styles.pageName, styles.pressablePage]}>{strings.home}</Text>
			</Pressable>
			<FontAwesomeIcon style={styles.icon} size={12} icon={faAngleRight}></FontAwesomeIcon>
			{history?.map((page: any, index, array) => {
				const route = getRouteByKey(page?.key);

				if (!route) {
					return;
				}

				const pageName = getPageName(route);

				if (index !== array.length - 1) {
					return (
						<View style={styles.crumbContainer} key={`navigation${index}`}>
							<Pressable onPress={() => handleBreadcrumbNavigation(route)}>
								<Text style={[styles.pageName, styles.pressablePage]}>{pageName}</Text>
							</Pressable>
							<FontAwesomeIcon style={styles.icon} size={12} icon={faAngleRight}></FontAwesomeIcon>
						</View>
					);
				} else {
					// Last breadcrumb should not be clickable
					return (
						<Text key={`navigation${index}`} style={styles.pageName}>
							{pageName}
						</Text>
					);
				}
			})}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "flex-start",
		marginHorizontal: "5%",
	},
	crumbContainer: {
		display: "flex",
		flexDirection: "row",
	},
	pageName: {
		fontSize: 18,
		color: colors.grey4,
		fontFamily: fonts.RalewayRegular,
	},
	pressablePage: {
		textDecorationLine: "underline",
		color: colors.blue3,
	},
	icon: {
		marginLeft: 10,
		marginRight: 10,
		marginTop: 3,
	},
});

export default PageNavigation;
