export const privacyPolicyHtml = `
<h1>PRIVACY POLICY</h1>
<p>Oggetto del presente &ldquo;Contratto di licenza d&rsquo;uso con l&rsquo;utente finale&rdquo; (EULA) &egrave; la piattaforma prodotta dal consorzio Solidariet&agrave; In Rete (&ldquo;SiR&rdquo;), societ&agrave; cooperativa sociale con sede legale in via Lorenzo Valla n. 25, c.a.p. 20141, a Milano con i seguenti dati di contatto: 02/84470111 e <a href="mailto:segreteria@consorziosir.it">segreteria@consorziosir.it</a>. &nbsp;</p>
    <p>Questo EULA &egrave; un contratto tra il cliente/utente inteso come persona fisica o giuridica (&ldquo;Licenziatario&rdquo;) e il consorzio SiR in base al quale quest&rsquo;ultimo permette l&rsquo;uso della piattaforma, di propriet&agrave; del consorzio SiR, concessa in licenza d&rsquo;uso al cliente/utente. Questo contratto deve essere letto con attenzione e per qualsiasi chiarimento siete pregati di contattare il consorzio SiR. Inoltre si ricorda fin da ora che qualsiasi installazione, copia, accesso o utilizzo della piattaforma concessa in licenza d&rsquo;uso costituisce accettazione e promessa di aderire a tutti i termini e le condizioni presenti in questo contratto.&nbsp;</p>
    <p>Per gli scopi previsti da questo EULA, la piattaforma rappresenta tutti i servizi offerti dal prodotto, tramite l&rsquo;applicazione per dispositivi mobili e siti internet, per la gestione digitale e per eseguire specifiche operazioni a vantaggio dei clienti/utenti tramite il dettaglio delle utenze, presenti nel progetto, e l&rsquo;elenco delle funzionalit&agrave; in carico a ciascuna di queste utenze.&nbsp;</p>
    <p>Utilizzando questa piattaforma, il licenziatario viene a conoscenza e accetta i termini di questo EULA; se il licenziatario non accetta i termini, lo stesso licenziatario non potr&agrave; utilizzare la piattaforma.</p>
    <p>1. CONCESSIONE DI LICENZA D&rsquo;USO. &nbsp;</p>
    <p>Il consorzio SiR concede al licenziatario, che accetta, i seguenti diritti a condizione che lo stesso rispetti tutti i termini e le condizioni di questo EULA.&nbsp;</p>
    <p>Il consorzio SiR concede al licenziatario la sola licenza di utilizzo della piattaforma, personale e non esclusiva, per la durata stabilita tra le parti. Il licenziatario non ha il diritto di usare la piattaforma fornita dal consorzio SiR per scopi non indicati nelle finalit&agrave; di questo EULA.&nbsp;</p>
    <p>La licenza d&rsquo;uso &egrave; concessa a condizione che il cliente/utente abbia ottenuto la piattaforma dal consorzio SiR e che rispetti i termini della licenza. Inoltre il consorzio SiR si riserva la facolt&agrave; di modificare il prodotto senza preavviso e in qualsiasi momento. La licenza non comprende la facolt&agrave; di ottenere la piattaforma in formato sorgente, n&eacute; di disporre della relativa documentazione logica e/o di progetto.</p>
    <p>1.1 UTENTI AUTORIZZATI. &nbsp;</p>
    <p>Le licenze qui garantite sono soggette alla condizione che il licenziatario assicuri che siano solamente gli utenti autorizzati ad accedere alla piattaforma e a utilizzarla. &nbsp;</p>
    <p>Si precisa inoltre che il licenziatario sar&agrave; qualificato in base alla tipologia di utenza (a titolo esemplificativo si avr&agrave; l&rsquo;utente, il gestore dei luoghi, l&rsquo;operatore interno oppure esterno e il coordinatore dei servizi) e alle sue specifiche funzionalit&agrave; e finalit&agrave; (gestione del proprio profilo, creazione e visualizzazione eventi, messaggistica e videomessaggi con altri utenti) che saranno raggiunte grazie alla licenza d&rsquo;uso della piattaforma.&nbsp;</p>
    <p>Per l&rsquo;accesso ai servizi presenti sulla piattaforma, l&rsquo;utente dovr&agrave; previamente fornire alcuni dati necessari per la creazione e la successiva attivazione del proprio account. In particolare, all&rsquo;utente verr&agrave; chiesto di inserire il proprio nome, cognome, indirizzo e-mail e password di cui ne sar&agrave; custode. L&rsquo;utente, poi, verr&agrave; guidato dalla piattaforma per la conclusione della procedura di attivazione dell&rsquo;account. L&rsquo;utente potr&agrave; registrarsi anche mediante l&rsquo;utilizzo del proprio profilo Facebook, Linkedin e Apple, restando l&rsquo;unico responsabile della veridicit&agrave; dei dati forniti al consorzio SiR direttamente o per il tramite di questa piattaforma. L&rsquo;utente sar&agrave; obbligato ad associare l&rsquo;automazione all&rsquo;utente mediante l&rsquo;invio di un link fornito via e-mail. Una volta completata l&rsquo;associazione, l&rsquo;utente assumer&agrave; la qualifica di utente gestore. &nbsp;</p>
    <p>L&rsquo;utente gestore, con sua espressa autorizzazione, potr&agrave; consentire l&rsquo;attivazione dei servizi di geolocalizzazione del suo dispositivo mobile e di notifiche push.</p>
    <p>1.2 TRASFERIMENTO DELLA PIATTAFORMA.&nbsp;</p>
    <p>Il licenziatario non pu&ograve; trasferire il presente EULA o i diritti in esso sanciti per far utilizzare la piattaforma o gli aggiornamenti garantiti a un terzo n&eacute; permettere ad altre parti di fare ci&ograve;.</p>
    <p>2. RISERVA DI DIRITTI E PROPRIET&Agrave;.&nbsp;</p>
    <p>Il consorzio SiR e i suoi fornitori si riservano tutti i diritti non espressamente garantiti ai licenziatari in questo EULA. La piattaforma &egrave; protetta dalle leggi sul copyright e da altre leggi sulla propriet&agrave; intellettuale, nonch&eacute; dai trattati. Il consorzio SiR o i suoi fornitori detengono il titolo, il copyright e gli altri diritti di propriet&agrave; intellettuale relativi alla piattaforma. La piattaforma &egrave; concessa in licenza, non venduta. Il licenziatario comprende e accetta che il consorzio SiR possa concedere in licenza la piattaforma o parte di essa attraverso uno o pi&ugrave; licenziatari terzi, che qualsiasi licenziatario terzo pertinente si intende come beneficiario terzo di questo EULA e che qualsiasi licenziatario terzo, i propri successori o assegnatari potrebbero applicare qualsiasi termine o tutti i termini di questo EULA e nulla di quanto esposto nei presenti dettagli dovr&agrave; limitare tali diritti legali o equi detenuti dal licenziatario terzo (compresi i provvedimenti ingiuntivi) n&eacute; i vantaggi o i rimedi di qualsiasi natura previsti o causati dal presente EULA.</p>
    <p>Il licenziatario riconosce e d&agrave; atto che tutte le realizzazioni tecnologiche alla base della piattaforma e dei servizi sono di propriet&agrave; del consorzio SiR.&nbsp;</p>
    <p>3. LIMITAZIONI AI DIRITTI DELL&rsquo;UTENTE FINALE.&nbsp;</p>
    <p>Al licenziatario &egrave; proibito decodificare, decompilare, disassemblare o tentare in altro modo di scoprire il codice sorgente o gli algoritmi della piattaforma o modificare o disabilitare qualsiasi funzionalit&agrave; della piattaforma o creare opere derivate basate sulla piattaforma. Al licenziatario &egrave; proibito noleggiare, affittare, prestare, concedere in sub-licenza o fornire servizi di hosting commerciale con la piattaforma.</p>
    <p>4. ATTIVAZIONE DELLA PIATTAFORMA.</p>
    <p>La piattaforma concessa in licenza d&rsquo;uso richiede l&rsquo;attivazione della stessa mediante l&rsquo;accettazione esplicita del presente EULA e l&rsquo;inserimento di un codice di attivazione (personale e strettamente legalo al cliente/utente per pervenire l&rsquo;uso illegale) che verr&agrave; fornito del consorzio SiR al momento dell&rsquo;acquisto completato.&nbsp;</p>
    <p>Con l&rsquo;accettazione di questo EULA, l&rsquo;utente si obbliga a:</p>
    <ol>
        <li>rispettare tutto quanto ivi indicato e/o successivamente modificato e integrato previa ulteriore accettazione da parte del licenziatario;&nbsp;</li>
        <li>fornire al consorzio SiR informazioni veritiere, valide e corrette;&nbsp;</li>
        <li>fare in modo che i propri dispositivi siano compatibili con la piattaforma;&nbsp;</li>
        <li>aggiornare la piattaforma alle versioni volta per volta disponibili;&nbsp;</li>
        <li>non porre in essere alcuna azione che possa compromettere le funzionalit&agrave; della piattaforma;&nbsp;</li>
        <li>non utilizzare e/o consentire di utilizzare impropriamente la piattaforma;&nbsp;</li>
        <li>custodire con la dovuta diligenza le proprie credenziali di accesso all&rsquo;account, nonch&eacute; a modificarle tempestivamente in caso di loro furto/smarrimento, restando in ogni caso il solo responsabile per tutti gli accessi non autorizzati.&nbsp;</li>
    </ol>
    <p>5. RACCOLTA DI DATI AGGREGATI.&nbsp;</p>
    <p>Il licenziatario riconosce e accetta che il consorzio SiR possa, come consentito dalla legge:</p>
    <ol>
        <li>raccogliere, elaborare e utilizzare i dati aggregati, salvati dalla piattaforma o a essa relativi, compresi, in via non limitativa, i dati generati dalla piattaforma o i dati generati da qualsiasi dispositivo che utilizza la piattaforma e creare archivi di dati resi anonimi e/o aggregati che non permettano al consorzio SiR di identificare nessuna persona (&ldquo;dati aggregati&rdquo;);</li>
        <li>usare tali i dati aggregati per migliorare la piattaforma, sviluppare una nuova piattaforma o dei nuovi servizi, comprendere le tendenze del settore, creare e pubblicare white paper, rapporti o database che riassumano i precedenti e generalmente per qualsiasi altro scopo legittimo relativo all&rsquo;attivit&agrave; del consorzio SiR;</li>
        <li>usare i dati aggregati per indagare, affrontare e/o prevenire attivit&agrave; illecite potenziali o in corso di svolgimento.</li>
    </ol>
    <p>6. INFORMAZIONI SULLA POSIZIONE.&nbsp;</p>
    <p>Il licenziatario pu&ograve; consentire alla piattaforma di raccogliere i dati basati sulla posizione di uno o pi&ugrave; dispositivi del cliente che potrebbero consentirgli di tenere traccia degli eventi che ha creato o a cui ha partecipato.&nbsp;</p>
    <p>7. TRATTAMENTO DI DATI PERSONALI.&nbsp;</p>
    <p>Per ci&ograve; che concerne il rispetto della normativa in materia di privacy la piattaforma, in relazione ai dati dei clienti/utenti fruitori della licenza d&rsquo;uso (chiamati interessati), acquisisce la qualifica di responsabile del trattamento; il consorzio SiR &egrave; il titolare del trattamento, in quanto ne definisce finalit&agrave; e mezzi. La piattaforma, quale responsabile del trattamento, &egrave; obbligata contrattualmente al rispetto delle norme di cui all&rsquo;articolo 28 del regolamento generale protezione dati UE 2016/679.&nbsp;</p>
    <p>Le informazioni sul trattamento dei dati dei clienti/utenti sono consegnate dal consorzio SiR al momento della sottoscrizione di questo Eula e che si considera incorporata in questo contratto. &nbsp;Gli utenti finali per iscriversi e utilizzare la piattaforma inviano i propri dati personali al consorzio SiR e, relativamente al loro uso di hardware o piattaforma e ai modi in cui il consorzio SiR raccoglie e utilizza i dati, sono regolati dalle informazioni sul corretto trattamento degli stessi in conformit&agrave; con il regolamento europeo 679/2016.&nbsp;</p>
    <p>Il consorzio SiR viene a conoscenza dei dati personali dei clienti/utenti, in particolare dei dati identificativi e di contatto (nominativo, data di nascita, genere, et&agrave;, indirizzo email e password, numero di telefono personale, indirizzo con indicazione del c.a.p. e geolocalizzazione), dati particolari (note mediche ed eventuali intolleranze) e dati inerenti a hobby e interessi da scegliere traendo spunto da una lista predefinita con selezione multipla). Inoltre il consorzio SiR verr&agrave; a conoscenza degli eventi creati e di quelli a cui il cliente/utente parteciper&agrave;, compresi i luoghi frequentati con la possibilit&agrave; di vedere gli eventi in base alla propria posizione geografica grazie alla geolocalizzazione. &Egrave; possibile inserire, come opzione, anche il numero di telefono di un parente per il recupero dei dati di accesso al proprio profilo.&nbsp;</p>
    <p>8. VERSIONI PIATTAFORMA.&nbsp;</p>
    <p>I fornitori del consorzio SiR possono fornire al Licenziatario versioni aggiornate della piattaforma pubblicate dopo la data di rilascio delle credenziali per accedere alla piattaforma al licenziatario. Il presente EULA si applica a tutti i componenti della versione eventualmente fornita al Licenziatario dopo la data di rilascio della prima copia della piattaforma, a meno che il consorzio SiR non comunichi altri termini di licenza cui tale versione &egrave; soggetta. Per alcune funzionalit&agrave; della piattaforma potrebbe essere necessaria una connessione a internet ed esse potrebbero essere soggette a restrizioni imposte dalla rete o dal provider di rete.</p>
    <p>9. TRASFERIMENTO.&nbsp;</p>
    <p>Il licenziatario non pu&ograve; trasferire il presente EULA o alcuno dei suoi diritti o obblighi (per mezzo legale o in altro modo) senza il previo consenso scritto del consorzio SiR.&nbsp;</p>
    <p>Il consorzio SiR ha facolt&agrave; di trasferire il presente EULA e i suoi diritti e obblighi senza il consenso del licenziatario. Nel rispetto di quanto sopra, il presente EULA sar&agrave; vincolante ed entrer&agrave; in vigore a beneficio delle parti interessate e dei rispettivi rappresentanti legali, successori e assegnatari autorizzati.</p>
    <p>10. RECESSO E CHIUSURA DELL&rsquo;ACCOUNT.&nbsp;</p>
    <p>Questo EULA ha effetto fino al termine previsto dalle parti. Se il licenziatario non rispetta uno qualsiasi dei termini e delle condizioni di questo EULA, i suoi diritti relativamente a questa licenza verranno terminati automaticamente senza avviso da parte del consorzio SiR.&nbsp;</p>
    <p>Il licenziatario potr&agrave;, in qualunque momento, avanzare la richiesta di chiusura del proprio account utilizzando la funzione &ldquo;Elimina Account&rdquo; presente sulla piattaforma. A far data dal 15&deg; (quindicesimo) giorno successivo alla richiesta di cancellazione, l&rsquo;utente ricever&agrave; una e-mail che confermer&agrave; la cancellazione stessa. L&rsquo;utente inoltre potr&agrave; inviare una e-mail all&rsquo;indirizzo &hellip;&hellip;&hellip;&hellip;&hellip;&hellip; per qualsiasi informazione in merito alla chiusura del proprio account. &nbsp;Salvo diversa previsione di legge, l&rsquo;utente ha il diritto di recedere dall&rsquo;EULA entro 14 giorni dalla sottoscrizione dello stesso senza dover fornire alcuna motivazione. Per poter recedere, l&rsquo;utente si avvarr&agrave; della medesima procedura prevista per la chiusura dell&rsquo;account. &nbsp;</p>
    <p>Il consorzio SiR ha il diritto di risolvere con effetto immediato questo EULA qualora l&rsquo;utente utilizzi la piattaforma:</p>
    <ol>
        <li>per finalit&agrave; diverse dall&rsquo;uso convenuto;&nbsp;</li>
        <li>per scopi contrari alla legge e/o alla pubblica sicurezza e/o in violazione di provvedimenti della pubblica autorit&agrave;;&nbsp;</li>
        <li>in violazione degli obblighi previsti in questo EULA;&nbsp;</li>
        <li>nel caso in cui l&rsquo;utente chiuda l&rsquo;account;&nbsp;</li>
        <li>su espressa richiesta della pubblica autorit&agrave;. &nbsp;</li>
    </ol>
    <p>11. ESCLUSIONE DI GARANZIA E TOLLERANZE.&nbsp;</p>
    <p>Salvi i casi di dolo o colpa grave, il consorzio SiR non &egrave; responsabile per qualsivoglia danno e/o perdita subita dall&rsquo;utente nei casi in cui: &nbsp;</p>
    <ul>
        <li>la piattaforma sospenda l&rsquo;esecuzione dei servizi per ragioni di pubblica sicurezza e/o per ottemperare a un ordine di una pubblica autorit&agrave;; &nbsp;</li>
        <li>il danno e/o la perdita derivino dal dolo o dalla colpa dell&rsquo;utente; &nbsp;</li>
        <li>il malfunzionamento della piattaforma derivi dalla sua mancata manutenzione periodica;&nbsp;</li>
        <li>l&rsquo;utente utilizzi la piattaforma per finalit&agrave; contrarie alla legge e/o in violazione degli obblighi derivanti dalla condizioni di questo EULA; &nbsp;</li>
        <li>il danno e/o la perdita subita dall&rsquo;utente derivino da circostanze di impossibilit&agrave; sopravvenuta o forza maggiore (a titolo esemplificativo e non esaustivo si citano gli scioperi, le rivolte, guerre, invasioni, terremoti, epidemie, disastri naturali). Sono in ogni caso esclusi danni o perdite indiretti o eventuali mancati guadagni o perdite di opportunit&agrave;; &nbsp;</li>
        <li>malfunzionamenti delle reti telefoniche e internet. &nbsp;</li>
    </ul>
    <p>&nbsp;</p>
    <p>12. CONTESTUALE UTILIZZO DELLA PIATTAFORMA DI TERZE PARTI.&nbsp;</p>
    <p>Terze parti che utilizzano la piattaforma possono interagire con il cliente/utente. Il consorzio SiR non rilascia alcuna dichiarazione riguardo all&rsquo;utilizzo di queste terze parti. Dato che il consorzio SiR non ha alcun controllo su tali utilizzi, in particolare per la creazione di eventi, il licenziatario accetta e acconsente che il consorzio SiR non &egrave; responsabile per questo utilizzo. Il licenziatario riconosce e accetta espressamente che ogni utilizzo della piattaforma da parte di terzi &egrave; a suo rischio esclusivo e che l&rsquo;intero rischio di qualit&agrave;, prestazioni, accuratezza e funzionamento non soddisfacenti ricade su di lui. Il licenziatario accetta di considerare il consorzio SiR non responsabile, direttamente o indirettamente, di eventuali danni o perdite.&nbsp;</p>
    <p>13. LIMITAZIONE DI RESPONSABILIT&Agrave;.&nbsp;</p>
    <p>Il consorzio SiR non &egrave; responsabile per eventuali danni di qualunque tipo derivanti dall&rsquo;uso o dalla mancata possibilit&agrave; di usare la piattaforma, applicazioni esterne, contenuti o funzionalit&agrave;, inclusi ma non limitati a danni causati dall&rsquo;uso o correlati a errori, omissioni, interruzioni, difetti, ritardi nel funzionamento o nella trasmissione, virus informatici, impossibilit&agrave; di connettersi, addebiti di rete, acquisti in app e qualsiasi altro danno diretto, indiretto, speciale, esemplare o consequenziale anche se il consorzio SiR sia stata avvisata della possibilit&agrave; di tali danni.&nbsp;</p>
    <p>14. RISOLUZIONE.&nbsp;</p>
    <p>Il consorzio SiR pu&ograve; risolvere il contratto, senza per questo pregiudicare altri suoi diritti, se il cliente/utente non si attiene alle condizioni e alle clausole di questo EULA.&nbsp;</p>
    <p>15. CONTROVERSIE.</p>
    <p>I rapporti nascenti con l&rsquo;accettazione di questo EULA e tutti i rapporti da essi conseguenti sono e saranno regolati in via esclusiva dalla legge italiana. Ogni controversia che dovesse insorgere in relazione all&rsquo;interpretazione, esecuzione e validit&agrave; di queste condizioni sar&agrave; devoluta in via esclusiva alla giurisdizione italiana. Il foro competente in via esclusiva sar&agrave; quello di residenza o domicilio elettivo del licenziatario.</p>
`;