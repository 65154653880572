export enum colors {
	primary = "#E7F0FA",
	white = "#FFFFFF",
	black = "#000000",
	blackTag = "#202020",
	grey = "#D5D5D5",
	grey2 = "#989898",
	grey3 = "#F2F2F2",
	grey4 = "#2A2C41",
	grey5 = "#2A2C411A",
	grey4Border = "#2A2C4180",

	blue = "#436CA1",
	blue2 = "#2A2C41",
	blueButton = "#2E4A6E",
	blue2Shadow = "#2A2C411A",
	blue2ShadowAndroid = "rgba(42, 44, 65, 0.4)",
	red = "#BB0A1E",
	red2 = "#EE6F6B",
	red2Background = "#ee6f6b1a",

	green = "#52BB97",
	green2 = "#C7EDA7",
	greenCard = "#D9EAD3",
	yellow = "#FCB645",
	yellow2 = "#FFED93",
	yellowCard = '#FFF2CC',
	red3 = "#B64F4C",
	blue3 = "#4F7DBB",

	orange = "#FFAA66",
	lilac = "#C8A2C8",

	infoBanner = "#FFEBCD",
	greyReadOnly = "#EBECF0", // fixme
}
