
import {  useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { strings } from '../../../../../locales/strings';
import { AlertTypes } from '../../../../../utils/AlertTypes';
import { EventsEndPoint } from '../../../../../services/EventsEndPoint';
import { SecondPageViewModel } from '../../../../../models/createEventModels';
import { useNavigation } from '@react-navigation/native';
import { PageNames } from '../../../../../navigation/NavigationUtils';

const useSecondPageController = ({ propsEvent }: SecondPageViewModel) => {

    const navigation = useNavigation<any>();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
    }, []);

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });

    const [scrollRef, setScrollRef] = useState(null);
    const [scrollX, setScrollX] = useState<number>(0);

    const openSubEvent = () => {
        setLoaderVisible(true);
        if (propsEvent?.correlatedEventId) {
            EventsEndPoint.getEvent(propsEvent.correlatedEventId)
                .then((res: any) => {
                    navigation.navigate(PageNames.eventDetail, { event: res.item, navigation: navigation });
                })
                .catch((e: any) => {
                    setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
                });
        }
        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
    };
    

    return {
        navigation, isWeb,
        scrollRef, setScrollRef,
        scrollX, setScrollX,
        openSubEvent,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
    };
};

export default useSecondPageController;

