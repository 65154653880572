import { Platform, Pressable, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import FilterButton from "../components/FilterButton";
import Searchbar from "../components/Searchbar";
import Button from "../components/Button";
import { styles } from "../styles/GroupsScreen.style";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import ProfileImage from "../components/ProfileImage";
import { useIsFocused } from "@react-navigation/native";
import Footer from "../components/Footer";
import ChatIcon from "../components/Icons/ChatIcon";
import VideochatIcon from "../components/Icons/VideochatIcon";
import ExitIcon from "../components/Icons/ExitIcon";
import { PageNames } from "../navigation/NavigationUtils";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleRight, faShareSquare } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../resources/colors";
import { GroupService } from "../services/chat/GroupService";
import { GetGroupsResponse, Group, GroupMember, GROUPS_DEAFULT_FILTERS } from "../models/groupsModels";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { MeetService } from "../services/MeetService";
import { Meet } from "../models/models";
import ChatUnreadIcon from "../components/Icons/ChatUnreadIcon";
import { ChatService } from "../services/chat/ChatService";
import GroupIcon from "../components/Icons/GroupIcon";

interface PropsInterface {
	navigation?: any;
}

const GroupsScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const focused = useIsFocused();
	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });
	const [loaderVisible, setLoaderVisible] = useState(false);

	const [groups, setGroups] = useState<Group[] | undefined>(undefined);
	const [filters, setFilters] = useState<GROUPS_DEAFULT_FILTERS>({ page: 1, pageSize: 10, totalMessages: 0, searchTerm: "" });
	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const [userId, setUserId] = useState<string | undefined>(undefined);
	const [leaveGroupAlertVisible, setLeaveGroupAlertVisible] = useState<boolean>(false);
	const [groupId, setGroupId] = useState<string>("");

	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		const getUserId = async () => {
			const user = await AsyncStorage.getItem(asyncStorageUtils.userId);
			!!user ? setUserId(user) : null;
		};
		getUserId();
		getGroups();
	}, [focused]);

	const getGroups = async () => {
		setLoaderVisible(true);
		try {
			const groupsList: GetGroupsResponse = await GroupService.getGroups(filters.page, filters.searchTerm);
			const promises: Promise<boolean>[] = [];

			// Too little time to improve double forEach with Promise.all ;(
			groupsList.items.forEach((group) => {
				promises.push(getHasUnreadMessages(group.chatRoomId));
			});
			const hasUnreadMessages = await Promise.all(promises);
			groupsList.items.forEach((group, index) => {
				group.hasUnreadMessages = hasUnreadMessages[index];
			});

			setGroups(groupsList.items);
			setFilters({ ...filters, totalMessages: groupsList.total });
		} catch (e: any) {
			setError({ isVisible: true, title: e?.status, description: e?.error?.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const getHasUnreadMessages = async (roomId: string) => {
		const unreadMessages = await ChatService.getRoomUnreadMessagesNumber(roomId);
		return unreadMessages.item.unreadMessages > 0;
	};

	const leaveGroup = async (id: string) => {
		setLoaderVisible(true);
		try {
			const leaveGroup = await GroupService.leaveGroup(id);
		} catch (e: any) {
			setError({ isVisible: true, title: e?.status, description: e?.error?.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	useEffect(() => {
		getGroups();
	}, [filters.searchTerm, filters.page]);

	const clearFields = () => { };

	const modifyGroup = ({ item, index }: { item: Group; index: number }) => {
		return (
			<Pressable
				style={[styles.modifyGroupLabelMobile, item.ownerId === userId ? { width: isWeb ? "30%" : "100%" } : null]}
				onPress={() => props.navigation.navigate(PageNames.createGroup, { creation: false, group: item })}
			>
				<View style={{ alignContent: "flex-end", flexDirection: "row" }}>
					<Text style={styles.modifyGroupLabel}>{strings.modifyGroup}</Text>
					<FontAwesomeIcon color={colors.blue} icon={faAngleRight} />
				</View>
			</Pressable>
		);
	};

	const getListOfUsers = (users: GroupMember[]): string[] => {
		const members: string[] = [];
		users.forEach((el: GroupMember) => {
			members.push(el._id);
		});
		return members;
	};

	const goToMeetInstructions = async (groupId: string): Promise<void> => {
		setLoaderVisible(true);
		try {
			const meet = await MeetService.getGroupMeet(groupId);
			props.navigation?.navigate(PageNames.meetInstructions, { meet: meet?.item });
		} catch (e: any) {
			setError({ isVisible: true, title: e?.status, description: e?.error?.message });
		} finally {
			setLoaderVisible(false);
		}
	};

	const getMeet = async (groupId: string): Promise<Meet> => {
		const meet = await MeetService.getGroupMeet(groupId);
		return meet?.item;
	};

	const renderTableItem = ({ item, index }: { item: Group; index: number }) => {
		if (!item || !item.id) {
			return <></>;
		}

		return (
			<View style={[styles.groupRow, index % 2 === 0 ? { backgroundColor: colors.grey5 } : null]} key={`groups${index}`}>
				<View style={[styles.groupItemName, item.ownerId === userId && { width: isWeb ? "40%" : "50%" }, !isWeb && { width: "50%" }]}>
					<ProfileImage
						style={!!item.event ? { backgroundColor: colors.yellow2 } : { backgroundColor: colors.blue3 }}
						profilePicture={item.groupPicture}
						firstName={item.name}
						lastName={""}
					/>
					<View>
						<Text style={styles.groupName}>{item.name}</Text>
						{item.ownerId === userId && !isWeb && modifyGroup({ item, index })}
					</View>
				</View>
				<View
					style={[
						isWeb && { flexDirection: "row" },
						{ marginHorizontal: isWeb ? 50 : 0, alignItems: isWeb ? "center" : "flex-end" },
						item.ownerId === userId && { width: isWeb ? "30%" : "50%" },
						!isWeb && { width: "50%" },
					]}
				>
					<View style={[{ flexDirection: "row" }, !isWeb && { alignContent: "flex-end", flexWrap: "wrap", width: "65%" }]}>
						<Pressable
							onPress={() => {
								props.navigation?.navigate(PageNames.chat, {
									isSpace: true,
									roomId: item.chatRoomId,
									coverPhoto: item.groupPicture ?? undefined,
									spaceTitle: item.name,
									members: getListOfUsers(item.members),
									getMeet: () => getMeet(item.id),
								});
							}}
						>
							{item.hasUnreadMessages ? <ChatUnreadIcon /> : <ChatIcon />}
						</Pressable>
						<Pressable style={{ marginHorizontal: 10 }} onPress={() => goToMeetInstructions(item.id)}>
							<VideochatIcon />
						</Pressable>
						<Pressable
							onPress={() => {
								setLeaveGroupAlertVisible(true);
								setGroupId(item.id);
							}}
						>
							<ExitIcon />
						</Pressable>

						<Pressable style={{ marginHorizontal: isWeb ? 10 : item.ownerId === userId ? 20 : 2, marginVertical: !isWeb ? 5 : 0 }}
							onPress={() => {
								props.navigation.navigate(PageNames.groupDetail, { group: item })
							}}
						>

							<GroupIcon />
						</Pressable>

						{item.event && (
							<Pressable
								style={{ marginHorizontal: isWeb ? 10 : 8, marginVertical: !isWeb ? 5 : 0 }}
								onPress={async () => {
									props.navigation.navigate(PageNames.eventDetail, { event: item.event });
								}}
							>
								<FontAwesomeIcon size={20} icon={faShareSquare} />
							</Pressable>
						)}
					</View>
				</View>
				{item.ownerId === userId && isWeb && modifyGroup({ item, index })}
			</View>
		);
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={props.navigation} onPress={clearFields} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={error.title}
				description={error.description}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={error.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setError({ isVisible: isVisible, title: error.title, description: error.description });
				}}
			/>

			{/* Leave group alert */}
			<BasicAlert
				title={strings.leaveGroupAlert.title}
				description={strings.leaveGroupAlert.description}
				buttonText={strings.leaveGroupAlert.cancel}
				alertType={AlertTypes.warning}
				alertVisible={leaveGroupAlertVisible}
				secondButtonText={strings.leaveGroupAlert.delete}
				secondButtonAction={() => {
					leaveGroup(groupId);
					setGroupId("");
				}}
				setAlertVisible={(isVisible: boolean) => setLeaveGroupAlertVisible(isVisible)}
				styleSecondButton={{ backgroundColor: colors.red }}
			/>

			<ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb && <PageNavigation navigationEvent={clearFields} />}

				<View style={[styles.whiteContainer, isWeb ? styles.rowView : null, isWeb ? styles.webContainer : styles.mobileContainer]}>
					<View style={[styles.groupsContainer, isWeb ? styles.groupsContainerWeb : null]}>
						<Text style={styles.title}>{strings.groups}</Text>

						<View style={[styles.actions, isWeb ? styles.actionsWeb : null]}>
							<View style={styles.rowView}>
								{/* <FilterButton
									text={strings.all}
									selected={true}
									onChange={() => null}
									style={{ marginRight: 10 }}
								/> */}
								<FilterButton
									text={strings.createGroup}
									selected={false}
									onChange={() => props.navigation.navigate(PageNames.createGroup, { creation: true })}
								/>
							</View>

							<View>
								<Searchbar placeholder={strings.searchGroup} search={(val) => setFilters({ ...filters, searchTerm: val })} />
							</View>
						</View>

						<View style={styles.groupTable}>
							{!!groups && groups.length > 0 ? (
								<View style={{ width: "100%" }}>{groups.map((item: Group, index: number) => renderTableItem({ item: item, index: index }))}</View>
							) : (
								<></>
							)}
						</View>

						<View style={{ width: "100%" }}>
							<View style={styles.pagination}>
								{isWeb ? (
									<Pagination
										pages={Math.ceil(filters.totalMessages / filters.pageSize) || 1}
										currentPage={filters.page}
										handlePageSelect={(newPage: number) => setFilters({ ...filters, page: newPage })}
									/>
								) : Math.ceil(filters.totalMessages / filters.pageSize) > filters.page ? (
									<Button text={strings.loadMore} handleButtonPress={() => setFilters({ ...filters, page: filters.page + 1 })} filledButton={true} />
								) : null}
							</View>
						</View>
					</View>
				</View>	
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default GroupsScreen;
