import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Platform, Pressable } from "react-native";
import { DOTS, usePagination } from "../hooks/usePagination";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface PaginationProps {
	pages: number;
	currentPage: number;
	handlePageSelect: (pageSelected: number) => void;
}

const Pagination = ({ pages, currentPage, handlePageSelect }: PaginationProps) => {
	const paginationRange = usePagination({
		currentPage,
		totalPageCount: pages,
	});

	if (currentPage === 0 || !paginationRange) {
		return null;
	}

	return (
		<View style={styles.container}>
			<Pressable style={[styles.paginationItem, styles.navigationItem]} disabled={currentPage === 1} onPress={() => handlePageSelect(currentPage - 1)}>
				<FontAwesomeIcon style={styles.icon} size={12} icon={faAngleLeft}></FontAwesomeIcon>
			</Pressable>

			{paginationRange.map((pageNumber, index) => {
				if (pageNumber === DOTS) {
					return (
						<Text key={`dots${index + 1}`} style={styles.paginationItem}>
							&#8230;
						</Text>
					);
				}

				const current = currentPage === Number(pageNumber);
				return (
					<Pressable
						key={`page-${index + 1}`}
						style={[styles.paginationItem, current ? styles.currentPage : null]}
						disabled={current}
						onPress={() => handlePageSelect(Number(pageNumber))}
					>
						<Text>{pageNumber}</Text>
					</Pressable>
				);
			})}

			<Pressable style={[styles.paginationItem, styles.navigationItem]} disabled={currentPage === pages} onPress={() => handlePageSelect(currentPage + 1)}>
				<FontAwesomeIcon style={styles.icon} size={12} icon={faAngleRight}></FontAwesomeIcon>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	pageName: {
		fontSize: 14,
		color: colors.grey4,
		fontFamily: fonts.RalewayRegular,
	},
	pressablePage: {
		textDecorationLine: "underline",
		color: colors.blue3,
	},
	icon: {
		height: 16,
		color: colors.blue3,
	},

	paginationItem: {
		paddingVertical: 0,
		paddingHorizontal: 12,
		textAlign: "center",
		marginVertical: "auto",
		marginHorizontal: 4,
		color: colors.grey4,
		display: "flex",
		alignItems: "center",
		borderRadius: 10,
		lineHeight: 1.43,
		minWidth: 50,
		height: 50,
		justifyContent: "center",
	},

	currentPage: {
		backgroundColor: colors.red2Background,
	},

	navigationItem: {
		borderColor: colors.blue3,
		borderRadius: 50,
		borderWidth: 1,
	},
});

export default Pagination;
