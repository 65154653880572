import { useEffect, useState } from "react";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import Button from "../Button";

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	title: string;
	list?: { title: string; text: string }[];
	text?: string;
}

const EventInfoAlert = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, [props]);

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => props.setModalVisible(false)}>
			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setModalVisible(false)} />
				<View style={[styles.content, { width: isWeb ? "40%" : "80%" }]}>
					<Text style={[styles.title, { marginBottom: 30 }]}>{props.title}</Text>

					<ScrollView style={{ height: 300 }}>
						{props.text ? <Text style={styles.description}>{props.text}</Text> : null}

						{props.list ? (
							<View style={{ width: "100%" }}>
								{
									props.list.map((item: any, index: number) => (
										<View key={`info${index}`}>
											<Text style={[styles.title, { fontSize: 16 }]}>{item.title}</Text>
											<Text style={[styles.description, { marginBottom: 10 }]}>{item.text}</Text>
										</View>
									))
								}
							</View>
						) : null}
					</ScrollView>

					<Button
						style={{ marginTop: 30, marginBottom: isWeb ? 0 : 20 }}
						handleButtonPress={() => props.setModalVisible(false)}
						text={strings.close}
						filledButton={false}
					/>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		fontFamily: fonts.RalewayRegular,
		marginLeft: "5%",
		marginRight: "5%",
	},
	description: {
		fontFamily: fonts.RalewayRegular,
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "2%",
		fontSize: 14,
		color: colors.grey4,
	},
	button: {
		marginTop: Platform.OS === "web" ? "2%" : "10%",
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
});

export default EventInfoAlert;
