import { TouchableOpacity } from "react-native";
import { Text, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import TreeDotsIcon from "./Icons/TreeDotsIcon";

interface IButtonProps {
	handleButtonPress: () => void;
	text?: string;
	style?: any;
	textStyle?: any;
	disabled?: boolean;
	filledButton?: boolean;
	invertColor?: boolean;
}

const MoreButton = ({ handleButtonPress, text, style, textStyle, disabled = false, filledButton = true, invertColor = false }: IButtonProps) => {
	const btnColor = !!invertColor ? (filledButton ? styles.bkgWhite : { ...styles.bkgBlue, ...styles.borderWhite }) : {};

	return (
		<TouchableOpacity
			style={[styles.button, filledButton ? styles.buttonFilled : styles.buttonEmpty, btnColor, style && style]}
			disabled={disabled}
			onPress={() => handleButtonPress()}
		>
			<TreeDotsIcon size={20} />
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	button: {
		alignSelf: "center",
		padding: 6,
		borderRadius: 25,
		height: 35,
		alignContent: "center",
		zIndex: 1,
	},
	buttonFilled: {
		backgroundColor: colors.blue,
	},
	buttonEmpty: {
		backgroundColor: colors.white,
		borderColor: colors.blue,
		borderWidth: 2,
	},
	buttonText: {
		fontSize: 14,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		textTransform: "uppercase",
		color: colors.white,
	},
	colWhite: {
		color: colors.white,
	},
	colBlue: {
		color: colors.blue,
	},
	bkgWhite: {
		backgroundColor: colors.white,
	},
	bkgBlue: {
		backgroundColor: colors.blue,
	},
	borderWhite: {
		borderColor: colors.blue,
	},
	borderBlue: {
		borderColor: colors.blue,
	},
});

export default MoreButton;
