import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { FlatList, Pressable, Text, View } from "react-native";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/YourEvents.style";

interface PropsInterface {
	openComponent: boolean;
	setOpenComponent: (value: boolean) => void;
	value: any;
	setValue: (value: any) => void;
	list: any;
	isWeb: boolean;
}

const FilterEventWeb = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	return (
		<>
			<Pressable style={styles.rowView} onPress={() => props.setOpenComponent(!props.openComponent)}>
				<Text style={[styles.selectTitle, { width: props.isWeb ? "100%" : "100%", marginLeft: props.isWeb ? 15 : 0 }]}> {props.value.label} </Text>
				{props.openComponent ? (
					<FontAwesomeIcon style={[styles.selectIcon, { right: props.isWeb ? 15 : 30 }]} size={15} icon={faAngleUp}></FontAwesomeIcon>
				) : (
					<FontAwesomeIcon style={[styles.selectIcon, { right: props.isWeb ? 15 : 30 }]} size={15} icon={faAngleDown}></FontAwesomeIcon>
				)}
			</Pressable>
			{props.openComponent ? (
				<View style={[styles.selectComponent, { width: props.isWeb ? 200 : "100%", marginLeft: props.isWeb ? 15 : 0 }]}>
					<FlatList
						data={props.list}
						style={{ width: props.isWeb ? 200 : "100%" }}
						renderItem={({ item, index }) => {
							return (
								<Pressable
									style={[styles.rowView, { borderBottomColor: colors.grey3, borderBottomWidth: 1, zIndex: 9999 }]}
									onPress={() => {
										props.setValue({ type: item.type, label: item.label });
										props.setOpenComponent(false);
									}}
								>
									<Text style={[styles.selectItem, { padding: 10 }]} numberOfLines={1}>
										{item.label}
									</Text>
									{item.type === props.value.type ? <FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon> : null}
								</Pressable>
							);
						}}
						keyExtractor={(item) => item.type}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
			) : null}
		</>
	);
};

export default FilterEventWeb;
