import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { NavigationProp } from "@react-navigation/core";
import { useEffect, useState } from "react";
import { Dimensions, FlatList, Platform, Pressable, ScrollView, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import BasicAlert from "../components/Alerts/BasicAlert";
import Button from "../components/Button";
import EventCard from "../components/EventCard";
import EventCarousel from "../components/EventCarousel";
import FilterButton from "../components/FilterButton";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import MapView from "../components/Places/MapView";
import Searchbar from "../components/Searchbar";
import { strings } from "../locales/strings";
import { Event } from "../models/eventsModels";
import { PageNames } from "../navigation/NavigationUtils";
import { colors } from "../resources/colors";
import { EventsEndPoint } from "../services/EventsEndPoint";
import { styles } from "../styles/Dashboard.style";
import { AlertTypes } from "../utils/AlertTypes";
import { dashboardType } from "../utils/DashboardType";
import { EventStatus } from "../utils/EventStatus";
import { MacroAreaType } from "../utils/types/MacroAreaType";
import { MacroArea } from "../models/models";
import { useNavigation } from "@react-navigation/native";

const { height: windowHeight } = Dimensions.get("window");

interface PropsInterface {
	navigation: NavigationProp<any>;
	route: {
		params: {
			dashboardType: dashboardType;
		};
	};
}

interface SearchProps {
	search: string;
	page: number;
	pageSize: number;
	type: "all" | "lunch" | "grocery";
	ended: boolean;
	sort:string;
}

export const EVENTS_STATUS: EventStatus[] = [EventStatus.wait_internal_operator, EventStatus.wait_external_operator, EventStatus.approved];
const EVENTS_PER_PAGE = 15;

const DEAFULT_FILTERS: SearchProps = {
	search: "",
	page: 1,
	pageSize: EVENTS_PER_PAGE,
	type: "all",
	sort:"startDate",
	ended: false,
};

const DashboardScreen = (props: PropsInterface) => {
	const dashboardType = props.route.params.dashboardType;
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const isPlatformWeb = Platform.OS === "web";
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });
	const [carouselEvents, setCarouselEvents] = useState<Event[]>([]);
	const [events, setEvents] = useState<{
		totalCounts: number;
		items: Event[];
	}>({
		totalCounts: 0,
		items: [],
	});
	const [filters, setFilters] = useState<SearchProps>(DEAFULT_FILTERS);

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen !== null && scrollToTop();
		setCarouselEvents([]);
		setEvents({ totalCounts: 0, items: [] })
		setFilters(DEAFULT_FILTERS);
		searchCarouselEvent();
		searchEvents(DEAFULT_FILTERS);
	}, [props]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};
	const [macroArea, setMacroArea] = useState<MacroArea>({ key: '', label: '' });

	const searchEvents = async (filtersSelected?: SearchProps) => {
		setLoaderVisible(true);
		try {
			const f = !!filtersSelected ? filtersSelected : filters;
			const eventsRes = await EventsEndPoint.getEvents({
				...f,
				status: EVENTS_STATUS,
				dashboardType,
			});
			const items = isPlatformWeb ? eventsRes.page.items : filters.page === 1 ? eventsRes.page.items : events.items.concat(eventsRes.page.items);

			setEvents({
				totalCounts: eventsRes.total,
				items,
			});
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	useEffect(() => {
		if (filters !== DEAFULT_FILTERS) {
			searchEvents();
		}
	}, [filters]);

	const searchCarouselEvent = async () => {
		setLoaderVisible(true);
		try {
			const res = await EventsEndPoint.getEvents({
				page: 1,
				pageSize: 3,
				dashboardType: props.route.params.dashboardType,
				status: EVENTS_STATUS,
				ended: false,
			});
			res.total && setCarouselEvents(res.page.items);
			const item = MacroAreaType.find((item) => item.type === props.route.params.dashboardType)
			if (item) {
				setMacroArea({ key: item?.type, label: item?.label });
			}
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const [mapViewVisible, setMapViewVisible] = useState(false);
	return (
		<>
			<SafeAreaView style={styles.safearea}>
				<ScrollView showsHorizontalScrollIndicator={false} ref={(c) => setScrollRefScreen(c)}>
					<Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} macroarea={macroArea} />
					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<BasicAlert
						title={error.title}
						description={error.description}
						buttonText={strings.ok}
						alertType={AlertTypes.error}
						alertVisible={error.isVisible}
						setAlertVisible={(isVisible: boolean) => {
							setError({ isVisible: isVisible, title: error.title, description: error.description });
						}}
					/>

					<MapView
						navigation={props.navigation}
						modalVisible={mapViewVisible}
						setModalVisible={(isVisible: boolean) => setMapViewVisible(isVisible)}
						event={null}
					/>

					{carouselEvents.length ? (
						<View style={styles.carouselContainer}>
							<EventCarousel isWeb={isWeb || isPlatformWeb} events={carouselEvents} navigation={props.navigation} />
						</View>
					) : (
						<></>
					)}
					<View style={[{ marginTop: windowHeight * 0.75 }, styles.contentBox]}>
						<Text style={styles.title}>{strings.dashboard.title}</Text>

						<View style={[styles.actions, isWeb ? styles.actionsWeb : null]}>
							<ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
								<FilterButton
									text={strings.dashboard.filters.all}
									selected={filters.type === "all"}
									onChange={() =>
										filters.type !== "all" &&
										setFilters({
											...DEAFULT_FILTERS,
											type: "all",
										})
									}
								/>

								{props.route.params.dashboardType === 'food' ? (
									<>
										<FilterButton
											text={strings.lunchTogether}
											selected={filters.type === "lunch"}
											onChange={() =>
												filters.type !== "lunch" &&
												setFilters({
													...DEAFULT_FILTERS,
													type: "lunch",
												})
											}
										/>
										<FilterButton
											text={strings.groupGrocery}
											selected={filters.type === "grocery"}
											onChange={() =>
												filters.type !== "grocery" &&
												setFilters({
													...DEAFULT_FILTERS,
													type: "grocery",
												})
											}
										/>
									</>
								) : (<></>)
								}

							</ScrollView>
							<View style={[{ flexDirection: "row", marginTop: isWeb ? -10 : 0 }, isWeb ? {} : { width: "100%" }]}>
								<View style={[{ marginRight: 10, width: "85%" }]}>
									<Searchbar
										placeholder={strings.eventCard.search}
										search={(val: string) =>
											setFilters({
												...DEAFULT_FILTERS,
												search: val,
											})
										}
									/>
								</View>
								<Pressable style={styles.locationIcon} onPress={() => setMapViewVisible(true)}>
									<FontAwesomeIcon size={20} color={colors.blue} icon={faLocationDot} />
								</Pressable>
							</View>
						</View>

						{events.totalCounts ? (
							<>
								<View style={{ width: "100%", marginTop: 50 }}>
									{events.items.map((item: Event, index: number) => (
										<EventCard event={item} navigation={props.navigation} key={`dashboard-event${index}`} />
									))}
								</View>

								<View style={{ width: "100%" }}>
									<View style={styles.pagination}>
										{isWeb ? (
											<Pagination
												pages={Math.ceil(events.totalCounts / EVENTS_PER_PAGE) || 1}
												currentPage={filters.page}
												handlePageSelect={(newPage: number) =>
													setFilters({
														...filters,
														page: newPage,
													})
												}
											/>
										) : Math.ceil(events.totalCounts / EVENTS_PER_PAGE) > filters.page ? (
											<Button
												text={strings.users.loadMore}
												handleButtonPress={() =>
													setFilters({
														...filters,
														page: filters.page + 1,
													})
												}
												filledButton={true}
											/>
										) : null}
									</View>
								</View>
							</>
						) : (
							<Text>{strings.dashboard.noEvents}</Text>
                        )}
                        <Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
					</View>
				</ScrollView>
			</SafeAreaView>
		</>
	);
};

export default DashboardScreen;
