import { dashboardType } from "../utils/DashboardType";

export const strings = {
	anzitutto: "Anzitutto",
	signUpAnzitutto: "Registrati ad Anzitutto",
	signupAnzituttoDescription: "Registrati su Anzitutto per partecipare agli eventi organizzati sul portale!",
	name: "Nome",
	insertName: "Inserisci il nome",
	surname: "Cognome",
	insertSurname: "Inserisci il cognome",
	birthDate: "Data di nascita",
	select: "Seleziona",
	email: "E-mail",
	message: "Messaggio",
	insertEmail: "Inserisci la mail",
	from: "dalle",
	to: "alle",
	password: "Password",
	insertPassword: "Inserisci la password",
	confirmPassword: "Conferma la password",
	insertConfirmPassword: "Inserisci di nuovo la password",
	forward: "Avanti",
	phoneNumber: "Numero di telefono",
	insertPhoneNumber: "Inserisci il numero di tel.",
	parentPhoneNumber: "Numero di telefono di un familiare",
	insertParentPhoneNumber: "Inserisci il numero di tel. di un familiare",
	fiscalCode:"Codice fiscale",
	insertFiscalCode:"Inserisci codice fiscale",
	insertVat:"Inserisci partita IVA",
	vat : "Partita IVA",
	address: "Indirizzo",
	insertAddress: "Inserisci l'indirizzo",
	note: "Note",
	insertNote: "Se vuoi aggiungere altre informazioni sul profilo inseriscile qui",
	back: "Indietro",
	create: "Crea",
	events: "Eventi",
	createProfile: "Crea profilo",
	updateProfile: "Aggiorna",
	loadYourImage: "Carica l'immagine profilo",
	changeImage: "Cambia foto",
	deleteImage: "Rimuovi foto",
	uploadImage: "Carica foto",
	cameraUnavailable: "Camera non disponibile",
	photoLibraryUnavailable: "Galleria non disponibile",
	sureToDeleteImage: "Sei sicuro di voler rimuovere l'immagine profilo?",
	ok: "Ok",
	yes: "Si",
	no: "No",
	takePhoto: "Scatta foto",
	cancel: "Annulla",
	uploadPhotoFromGallery: "Carica foto dalla galleria",
	needHelp: "Hai bisogno di aiuto?",
	needHelpDescription: "Se hai bisogno di aiuto o per qualsiasi domanda, contattaci! Siamo qui per aiutarti!",
	placeDescription:"Scopri gli appuntamenti  intorno a te",
	braccialettiDescription:"Vivi in sicurezza con il braccialetto della salute",
	youTubeDescription:"scopri perché entrare nella community",
	askAnOperator: "Chiedi a un operatore",
	showPlaces: "Vai alla mappa",
	shopBraccialetti: "Prova il braccialetto",
	linkYouTube:"Guarda il video",
	gender: "Genere",
	woman: "Donna",
	man: "Uomo",
	other: "Altro",
	notShare: "Preferisco non dirlo",
	privacyPolicy: "Accetto i termini e condizioni",
	username: "Email",
	save: "Salva",
	close: "Chiudi",
	notAccordingToDateFormat: (inputFormat: any) => `Il formato della data deve essere ${inputFormat}`,
	mustBeHigherThan: (date: any) => `La data deve essere successiva a ${date}`,
	mustBeLowerThan: (date: any) => `La data deve essere antecedente a  ${date}`,
	mustBeBetween: (startDate: any, endDate: any) => `La data deve essere compresa tra ${startDate} e ${endDate}`,
	dateIsDisabled: "Questo giorno non è selezionabile",
	previous: "Precedente",
	next: "Successivo",
	typeInDate: "Tipo in Date",
	pickDateFromCalendar: "Seleziona data dal calendario",
	profileCreated: "Ottimo! Profilo creato",
	profileCreatedDescription:
		"Congratulazioni, il tuo profilo è stato creato! Entra nel portale e comincia ad esplorare.",
	shareWithFriends: "Condividilo con i tuoi amici",
	login: "Login",
	logout: "Esci",
	access: "Accedi ad anzitutto",
	notAlreadyRegistered: "Non sei ancora registrato?",
	signup: "Registrati",
	home: "Home",
	socialShare: "Ciao amici, mi sono iscritto ad Anzitutto!! Iscrivetevi anche voi!",
	enter: "Accedi",
	forgotPassword: "Hai dimenticato la tua password?",
	rememberNextTime: "Ricorda per la prossima volta",
	or: "Oppure",
	facebookLogin: "Accedi con Facebook",
	googleLogin: "Accedi con Google",
	appleLogin: "Accedi con Apple",
	changePassword: "Cambio password",
	changePasswordFirstAccess: "Hai effettuato l'accesso con una password temporanea, è necessario impostare la tua nuova password",
	termsAndConditions: "Termini e condizioni",
	acceptTermsAndConditions: "Ho preso visione e accetto i termini e condizioni",
	errorTitle: "Errore",
	errorText: "Si è verificato un errore, riprovare più tardi",
	hello: "Ciao",
	role: "Ruolo",
	places: "Luoghi",
	placesTitle: "Luoghi Anzitutto!",
	placesDescription: "Registrati per partecipare, creare eventi e invitare i tuoi amici oden ipsum loden ipsum loden ipsum",
	searchPlace: "Cerca un luogo",
	add: "Aggiungi",
	friends: "Amici",
	addPlace: "Aggiungi luogo",
	updatePlace: "Modifica luogo",
	placeName: "Nome del luogo",
	place:"Luogo",
	placesAvailable: "Posti disponibili",
	manageImages: "Gestisci le tue immagini",
	selectRole: "Seleziona il ruolo",
	search: "Cerca",
	errorPhotoUploaded: "Nessuna immagine selezionata",
	numberOfSeats: "Posti disponibili",
	kitchen: "E' presente la cucina?",
	privatePlace: "Luogo privato",
	publicPlace:"Luogo pubblico",
	field:"Ambito",
	parkingSpots: "Numero di parcheggi",
	architecturalBarriers: "Barriere architettoniche",
	maxNumberOfUsers: "Numero massimo di partecipanti",
	addDocument: "Allega un documento",
	choose: "Sfoglia",
	removeDoc: "Rimuovi documento",
	modify: "Modifica",
	activityFilter: "Filtra per attività",
	distanceFilter: "Filtra per distanza",
	selectMonthTitle: "Seleziona mese del ",
	selectYearTitle: "Seleziona anno",
	pastEvents: "Eventi passati",
	inProgram: "In Programma",
	all: "Tutti",
	twoKm: "2Km",
	threeKm: "3Km",
	fiveKm: "5Km",
	sixKm: "6Km",
	tenKm: "10Km",
	filter: "Filtri",
	modifyPlace: "Modifica Luogo",
	delete: "Elimina",
	cancelCard:"Cancella",
	deletePlace: "Elimina luogo",
	sureToExit: "Sei sicuro di voler uscire?",
	sureToModify:"Sei sicuro di voler uscire?",
	sureToExitDescription:
		"Se confermi eventuali modifiche non salvate andranno perse.",
	sureToModifyDescription:"Se confermi le modifiche verranno sovrascritte.",
	backToModify: "Torna alle modifiche",
	exitWithoutSaving: "Esci senza salvare",
	exitModify:"Modifca",
	placeAdded: "Ottimo! Luogo aggiunto",
	placedAddedDescription:
		"Le informazioni sono state aggiunte correttamente.",
	placeModified: "Ottimo! Luogo aggiornato",
	placeModifiedDescription:
		"Le informazioni sono state modificate correttamente.",
	placeDeleted: "Ottimo! Luogo cancellato.",
	placeDeletedDescription:
		"Le informazioni sono state cancellate correttamente.",
	sureToDeletePlace: "Sei sicuro di voler eliminare questo luogo?",
	sureToDeletePlaceDescription:
		"Questa azione non è reversibile, non sarà più possibile recuperare le informazioni cancellate",
	deletePlaceButton: "Si, elimina",
	openEvent: "Vai all'evento ->",
	choosePlaceManager: "Gestore dei luoghi",
	kitchenNote: "Note cucina",
	activityDetailNote: "Dettaglio attività",
	activityDetail:"Attività disponibili",
	openingInfo: "Informazioni apertura luogo",
	emptyPlaces: "Nessun luogo disponibile",
	emptyEvents: "Nessun evento disponibile",
	placeManager: "Gestore del luogo",
	profile: "Profilo",
	yourGroups: "I tuoi gruppi",
	yourEvents: "I tuoi eventi",
	invitations: "Inviti ricevuti",
	payments: "Pagamenti",
	settings: "Impostazioni",
	welcome: "Bentornato",
	todayIs: "Oggi è",
	addFriend: "Aggiungi un nuovo",
	friend: "Amico",
	createNew: "Crea un nuovo",
	group: "Gruppo",
	event: "Evento",
	loadMore: "Carica ancora",
	usersList: "Lista utenti",
	follow: "Segui",
	notAvalilable: "Non disponibile",
	createEventTitle: "Inserisci le informazioni principali",
	createEventScope: "Evento di ambito",
	selectTypology: "Cosa vuoi organizzare",
	selectTypologyWellness: "Tipo partecipazione Evento",
	eventTitle: "Titolo dell'evento",
	activityTitle:"Titolo",
	activityStatus:"Stato attività",
	eventTitlePlaceholder: "Es. Pranzo di Ferragosto",
	hour: "Hour",
	itHour: "ore",
	coverImage: "Immagine di copertina",
	choose2: "Scegli",
	selectPlaceTypes: "Scegli il luogo",
	placeTypes: {
		conventionedPlace: "Luogo Anzitutto",
		otherPlace: "Altri luoghi",
	},
	conventionedPlace: "Dove lo vuoi organizzare",
	otherPlace: {
		label: "Non hai trovato la struttura che ti interessa?",
		description: "Scrivila tu stesso compilando il campo qui sotto",
		placeholder: "Es. Pasticceria Blignè - Viale Bligny, 41, 20126 Milano"
	},
	imagesPlaceSelected: "Qui trovi alcune immagini del luogo scelto:",
	imagesFreeTextPlaceSelected: "Il calendario e le fotografie della struttura non sono disponibili per le strutture NON convenzionate Anzitutto",
	availabilityPlaceSelected: "Quando lo vuoi utilizzare",
	selectStartHour: "Seleziona orario di inizio",
	selectEndHour: "Seleziona orario di fine",
	errorHours: "L'ora  di fine è antecedente alla data di inizio. Per piacere, selezionare una data valida",
	lunchTogether: "Mangiamo in compagnia",
	groupGrocery: "Spesa di gruppo",
	inPerson: "In presenza",
	both: "Tutti e due",
	online: "Online",
	videoCall: "in videocall",
	createEvent: "Crea un nuovo evento",
	subStringEvent: "seleziona l'ambito dell'evento che vuoi creare",
	createEventDescription:
		"Scegli il giorno, l'ora e il luogo dell'evento che vuoi organizzare. Invita i tuoi amici a partecipare e condividi la tua esperienza su Facebook e Instagram",
	createEventLabel: "Speriamo che tu e i tuoi amici possiate trascorrere insieme una piacevole giornata",
	tellYourEvent: "Racconta il tuo evento",
	saveAsDraft: "Salva come bozza",
	saveAsDraftQuestion: "Non riesci a completare tutte le informazioni ora?",
	description: "Descrivi il tuo evento",
	descriptionActivity: "Descrizione attività",
	menu: "Menù",
	groceryList: "Cosa vuoi acquistare",
	appetizer: "Antipasti",
	appetizerPlaceholder: "- Es. Tagliere affettati misti",
	mainCourse: "Primi",
	mainCoursePlaceholder: "- Es. Penne con ragù",
	secondCourse: "Secondi",
	secondCoursePlaceholder: "- Es. Cotoletta alla milanese",
	dessert: "Dolci",
	activities: "Attività",
	fromEventActivities: "Descrivi il programma",
	addActivity: "AGGIUNGI ATTIVITÀ",
	newActivity: "Nuova attività",
	activityInfo: "Aggiungi almeno una attività al tuo evento",
	dessertPlaceholder: "- Es. Crostata di frutta",
	referenceOperator: "Operatore Anzitutto",
	groceryPlaceholder: "- Es. Pane, Pasta ...",
	collaborators: "Volontari",
	otherPlaceholder: "- Es. Bibite",
	inviteFriends: "Invita i tuoi amici e fai conoscere l'evento",
	searchAndInviteFriends: "Cerca e invita i tuoi amici",
	invite: "Invita",
	invited: "Invitata/o",
	haveYouInvitedAllFriends: "Hai invitato tutti i tuoi amici?",
	haveYouInvitedAllFriendsDescription:
		"Ricordati di invitare tutti gli amici che vuoi a questo evento.",
	publish: "Pubblica",
	createSubEvents: "Crea l'evento spesa associato",
	macroArea: "Macro Area",
	foodTitle: "Cibo",
	wellnessTitle: "Benessere",
	cultureTitle: "Cultura",
	scope: "Ambito",
	scopePlaces: "Selezionare un ambito",
	maxNumberOfPartecipans: "Il numero massimo dei partecipanti comprende i collaboratori e gli operatori",
	startHour: "Ora d'inizio",
	duration: "Durata",
	eventWhereIPartecipate: "A cui partecipo",
	eventsCreatedByMe: "Creati da me",
	eventsToVerify: "Da verificare",
	eventsToAccept: "Da accettare",
	eventsWhereIAmInvited: "A cui sono invitato",
	collaboratorsDescription: "Inserisci sotto le attività nelle quali altri partecipanti di Anzitutto potrebbero aiutarti! Ad esempio: per apparecchiare, lavare i piatti….",
	externalOperator: "Operatore esterno",
	approve: "Approva",
	approved: "Approvata",
	join: "Scopri di più",
	searchEvent: "Cerca un evento",
	version: "Version",
	apply: "Applica",
	filterEvent: "Filtra eventi",
	attentionGroceryEvent: "Attenzione, esiste un evento di spesa associato a questo evento",
	attentionLunchEvent: "Attenzione, esiste un evento pranzo associato a questo evento",
	invitedPartecipant: "Hai partecipato come invitato",
	invitePartecipant: "Parteciperai come invitato",
	goToEvent: "Vai all'evento",
	inviteFriend: "Invita amico",
	editEvent: "Riprendi la creazione dell'evento",
	eventPublished: "Evento pubblicato con successo!",
	eventApproved: "Evento approvato con successo!",
	eventSaved: "Evento salvato con successo!",
	goBack: "Torna indietro",
	partecipate: "Partecipa",
	map: "Mappa",
	inviteFriendsToPartecipate: "Invita i tuoi amici a partecipare",
	partecipants: "Partecipanti",
	shareEventWithFriends: "Condividi l'evento con i tuoi amici",
	seeMenu: "Dai un occhio al menù",
	seeGrocery: "Dai un occhio alla spesa",
	grocery: "Spesa",
	somePhotosOf: "Alcune foto di",
	addAFriend: "Aggiungi amico",
	noPartecipants: "Nessun partecipante",
	showAll: "Mostra tutti",
	showLess: "Mostra meno",
	less: "Pochi",
	noPlace: "Nessun piatto",
	noGrocery: "Nessuna spesa",
	goToSubEvent: "Vai all'evento associato",
	deleteEvent: "Sei sicuro di voler eliminare quest'evento?",
	deleteEventDescription:
		"Una volta confermata questa azione non sarà più possibile recuperare le informazioni cancellate. Sei sicuto di voler procedere?",
	deleteActivity: "Sei sicuro di voler eliminare l'attività?",
	eventDeleted: "Evento eliminato!",
	activityDeleted: "Evento eliminato!",
	activityApproved:"Attività approvata con successo",
	draftSaved: "Evento salvato come bozza",
	activitySaved: "Attività salvata con successo",
	searchAndFollowFriends: "Cerca e segui i tuoi amici",
	download: "Scarica",
	followed: "Seguito",
	approveEvent: "Approva evento",
	profileUpdated: "Ottimo! Profilo aggiornato",
	profileUpdatedDescription: "Le infromazioni sono state aggiornate correttamente.",
	notificationTitle: "Notifiche",
	enableNotifications: "Consenti le notifiche",
	settingsDescription: "In questa sezione puoi modificare le tue impostazioni!",

	receivedInvites: "Inviti ricevuti",
	partecipationTitle: "Scegli come partecipare",
	partecipationDescription:
		"Avrai qualche compito specifico per questo evento? Sceglilo qui sotto per confermare.",
	partecipationAs: "Parteciperai come ",
	partecipatedAs: "Hai partecipato come ",
	participateInPerson: "Partecipa in presenza",
	participateOnline: "Partecipa virtualmente",
	errorPhotoTooBig: 'L\'immagine selezionata è troppo grande',
	error: 'Error',
	removeFriend: 'Rimuovi amico',
	signupAnzituttoOperator: "Registrazione operatore",

	partecipant: {
		dresser: "Apparecchiatore",
		busser: "Sparecchiatore",
		sousChef: "Aiuto cuoco",
		wineExpert: "Esperto di vini",
		shoppingHelp: "Aiuto spesa",
		driver: "Autista",
		user: "Invitato",
	},

	//Events status
	eventStatus: {
		draft: "Bozze",
		to_be_approved: "Da approvare",
		wait_internal_operator: "Attesa operatore interno",
		wait_external_operator: "Attesa operatore esterno",
		approved: "Approvati",
	},

	singularEventStatus: {
		draft: "Bozza",
		to_be_approved: "Da approvare",
		wait_internal_operator: "Attesa operatore interno",
		wait_external_operator: "Attesa operatore esterno",
		approved: "Approvato",
	},
	signupOperator: "Creazione utente operativo",
	insertCurrentPassword: "Inserisci la password corrente",
	currentPassword: "Password corrente",
	resetPassword: "Recupero password",
	resetPasswordCompleted:
		"Se sei registrato ad Anzitutto ti arriverà una mail all'indirizzo indicato, controlla la casella di posta per continuare la procedura",

	//Homepage
	food: {
		label: "Anzitutto IL CIBO",
		title: "Mangiare insieme per con-dividere, conoscere, raccontarsi, per il piacere di stare con gli altri.",
		description:
			"Un pranzo, una cena o una merenda in cui le persone anziane organizzano l'evento con il supporto di un operatore, decidendo il menù, collaborando alla preparazione dei piatti, scegliendo quali amici invitare e accogliendone di nuovi, organizzandosi per fare la spesa in gruppo...",
	},
	wellness: {
		label: "Anzitutto IL BENESSERE",
		title: "Stiamo bene quando testa, cuore e fisico sono in armonia tra loro.",
		description:
			"Una semplice passeggiata, una biciclettata o una sessione di ginnastica dolce.. scegliendo quali amici invitare e accogliendone di nuovi. Un momento di piacevole e salutare condivisione di esperienze, supervisionati e coordinati da un operatore.",
	},
	culture: {
		label: "Anzitutto LA CULTURA",
		title: "Soltanto nel divertimento, nella passione e nel ridere si ottiene una vera crescita culturale.",
		description:
			"La presentazione di un libro, la visita di un museo o la scoperta di una zona storica della città, descritta e raccontata dall'amico che ha organizzato l'evento. In compagnia e sempre supervisionati da un operatore si riesce a organizzare un momento culturale di piacevole condivisione.",
	},
	mustLogin: {
		title: "Accesso necessario",
		description: "Per poter accedere agli eventi devi accedere alla piattaforma. Se non hai un account creane uno!",
	},
	noAvailableEvents: {
		title: "Non ci sono eventi disponibili",
		description: "Per ora non ci sono eventi a cui è possibile partecipare"
	},

	roles: {
		user: "Utente",
		"service-coordinator": "Coordinatore dei servizi",
		"external-user": "Operatore esterno",
		"internal-user": "Operatore interno",
		"place-manager": "Gestore dei luoghi",
	},

	shareEvent: {
		title: "Partecipa anche te all'evento: ",
		message: "",
	},

	// ERRORS
	invalidBirthDate: "Data di nascita non valida",
	emailNotValid: "Email non valida",
	phoneNumberNotValid: "Numero di telefono non valido",
	passwordNotValid: "Inserisci una password sicura: lunga almeno 8 caratteri, deve contenere lettere minuscole, maiuscole, numeri e caratteri speciali",
	passwordsNotMatch: "Le password non corrispondono",
	users: {
		title: "Utenti",
		filters: {
			all: "Tutti",
			following: "Amici che seguo",
			followers: "Amici che mi seguono",
			search: "Cerca un amico",
			name: "Nome",
		},
		followFriend: "Segui amico",
		unfollow: "Non seguire più",
		goToProfile: "Vai al profilo completo",
		loadMore: "Carica altri utenti",
		eventsOf: (name: string = "") => `Eventi di ${name}`,
		bithday: "Compleanno",
		field: "Ambito",
		fiscalCode:"Codice Fiscale",
		VAT: "Partita IVA",
		voidNote: "Nessuna nota...",
	},
	profilePage: {
		title: "Il tuo profilo",
		breadcrumb: "Profilo",
		edit: "Modifica profilo",
		delete: "Elimina profilo",
		warningDelete: {
			title: "Cancellazione del profilo",
			description: "Attenzione, stai per cancellare il tuo profilo in modo irreversibile. Sei sicuto di voler continuare?",
			button: "Cancella il mio profilo",
		},
		changeRole: "Cambia ruolo",
		promoTile: {
			title: "Stai seguendo tutti i tuoi amici?",
			body: "Non perdere di vista i tuoi amici! Ricordati di seguirli anche qui su Anzitutto.",
			cta: "Aggiungi un amico",
		},
		changeRoleModal: {
			title: "Scegli il ruolo",
			desc: "Segli con che ruolo vuoi lavorare sul portale.",
			btn: "Ho deciso!",
		},
		loadMoreEvents: "Carica altri eventi",
	},

	dashboard: {
		title: "Cosa vuoi fare?",
		filters: {
			all: "Tutti gli eventi",
		},
		noEvents: "Nessun evento trovato",
	},

	eventCard: {
		attendees: "partecipante/i",
		search: "Cerca un evento",
	},

	footer: {
		copyright: "RSA 2022. Tutti i diritti riservati",
		support: "Supporto",
		faq: "FAQ",
		privacy: "Informazioni sulla privacy",
	},
	send: "Invia",
	support: {
		header: "Scrivi qui il tuo messaggio e il tuo recapito. Ti risponderemo il prima possibile!",
		mustAcceptConditions: "È necessario accettare i termini e le condizioni di servizio",
		sent: "Messaggio inviato con successo",
		successMessage: "Verrai contattato al più presto",
	},
	nocoordinates: "L'indirizzo specificato non ha riscontro. Inserire un altro indirizzo, o rivolgersi all'assistenza.",
	//chat
	chatAdd: "Aggiungi",
	chatPlaceholder: "La storia è attiva",
	chatStatus: {
		active: "Attivo",
		inAMeeting: "In un incontro",
		away: "Via"
	},
	chatIcons: {
		photos: "Foto",
		camera: "Camera",
		gif: "Gif",
		videochat: "Videochat",
		calendar: "Calendario",
		document: "Documento"
	},

	min: " min",
	now: "Adesso",
	chatWith: "Chatta con ",
	openMeetWith: "Apri meet con ",
	deleteMessage: "Elimina il messaggio",
	editMessage: "Modifica il messaggio",
	messageDeleted: "Il messaggio è stato cancellato",
	edited: "Modificato",
	deleteMessageDescription: "Sei sicuro di voler eliminare il messaggio?",
	loadMoreMessages: "Carica altri messaggi",
	eventChat: "Chat evento",
	members: "Membri",
	modifyGroup: "Modifica gruppo",
	searchGroup: "Cerca il gruppo",
	groups: "Gruppi",
	createGroup: "Crea gruppo",
	createNewGroup: "Crea nuovo gruppo",
	loadGroupImage: "Carica l'immagine profilo del gruppo",
	groupNote: "Se vuoi aggiungere altre informazioni sul tuo gruppo inseriscile qui",
	groupName: "Nome",
	insertGroupName: "Inserisci il nome del gruppo",
	addPartecipants: "Aggiungi partecipanti",
	remove: "Rimuovi",
	seeAllGroups: "Vedi tutti i",
	saveChanges: "Salva le modifiche",
	editGroup: "Modifica gruppo:",
	deleteGroup: "Elimina gruppo",

	deleteGroupAlert: {
		title: "Sei sicuro di voler eliminare il gruppo?",
		description: "",
		cancel: "Annulla",
		delete: "Elimina"
	},
	deletedGroupAlert: {
		title: "Ottimo! Gruppo eliminato",
		description: "",
		close: "Chiudi"
	},
	groupCreated: {
		title: "Ottimo! Gruppo creato",
		description: "",
		close: "Chiudi",
		socialShareTitle: "Ciao amici ho creato un nuovo gruppo su Anzitutto!"
	},
	editedGroupAlert: {
		title: "Ottimo! Gruppo aggiornato",
		description: "",
		close: "Chiudi"
	},
	leaveGroupAlert: {
		title: "Sei sicuro di voler lasciare il gruppo?",
		description: "",
		cancel: "Annulla",
		delete: "Lascia"
	},
	weekDays: {
		monday: "Lunedì",
		tuesday: "Martedì",
		wednesday: "Mercoledì",
		thursday: "Giovedì",
		friday: "Venedì",
		saturday: "Sabato",
		sunday: "Domenica",
	},
	today: "Oggi",
	eventCoverImage: "Scegli l'immagine di copertina del tuo evento",
	activityCoverImage: "Scegli l'immagine principale",
	selectStartDate: "Data e ora di inizio",
	selectEndDate: "Data e ora di fine",
	houres: "ore",
	tapToChooseStartDate: "Clicca per scegliere la data e l'ora di inizio",
	tapToChooseEndDate: "Clicca per scegliere la data e l'ora di fine",
	invalidEndDate: "Attenzione: la data di fine evento è antecedente alla data di inizio.",
	invalidEndDateActivity: "Attenzione: la data di fine attività è antecedente alla data di inizio.",
	eventNull: "Durata evento non valida: la data di fine evento non può essere uguale alla data di inizio",
	activityNull: "Durata attività non valida: la data di fine attività non può essere uguale alla data di inizio",
	eventNotIncludeActivity: "L'inizio e/o la fine dell'attività non sono contenuti nell'inizio e/o fine dell'evento contenitore.",
	confirm: "Conferma",
	tapToSelectTime: "Clicca per selezionare l'ora",
	selectTime: "Selezionare l'ora",
	noEventsAvailable: "Non ci sono eventi disponibili per oggi",
	slotNotAvailable: "Attenzione: stai organizzando un evento in un periodo in cui ci sono altri eventi. Verrai contattato al più presto per verificarne la disponibilità.",
	activitySlotNotAvailable: "Attenzione: stai organizzando una attività in un periodo in cui ci sono altre attività. Verrai contattato al più presto per verificarne la disponibilità.",
	dateNotModify:"Non è possibile modificare la data dell'evento - ci sono attività presenti",
	participationNotModify:"Non è possibile modificare il tipo di partecipazione all'evento - ci sono attività presenti",
	myCalendar: "mio calendario",
	myCalendarMenu: "Il mio calendario",
	viewThe: "Vedi il",
	view: "Vedi",
	calendar: "Calendario",
	calendarOf: "Calendario di ",
	openPlaceCalendar: "Vedi il calendario",
	insertIndisponibility: "Inserisci indisponibilità",
	disponibility: "Orari disponibilità",
	workingHoursPlace: "Orario di apertura luogo",
	reasonForUnavailability: "Motivo dell'indisponibilità",
	emptyChat: "Inizia a chattare!",
	openMeet: "Apri meet",
	errorLinkNotSupported: "Il link non è supportato dal dispositivo",
	meetInstructions: {
		step1: 'Seleziona il pulsante "APRI MEET"',
		step2: 'Se non trovi il tuo account @anzitutto.net seleziona "utilizza un nuovo account"',
		step3: (email: string = "") => `Inserisci la seguente mail anzitutto: "${email}", poi clicca "Avanti"`,
		step4: 'Inserisci la password con cui hai fatto l\'accesso ad Anzitutto e poi clicca "Avanti" e sarai pronto a partecipare alla videochiamata',
	},
	attachmentUnavailable: {
		title: "Allegato non disponibile",
		description: "Questo allegato potrebbe essere stato rimosso."
	},
	maxUsersReached: "Numero massimo di partecipanti raggiunto per il luogo selezionato",
	noDescription: "Nessuna descrizione",
	anzituttoPlace: "I luoghi di Anzitutto",
	braccialetti: "Anzitutto salute",
	channelYouTube: "Cos’è Anzitutto"
};
