
import { GetGroupsResponse, Group } from "../../models/groupsModels";
import { ProfileImageRequest } from "../../models/models";
import { APIBaseService } from "../ApiBaseService";


export class GroupService extends APIBaseService {

    public static async createGroup(name: string, members: string[], description: string): Promise<any> {
        return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/groups`, false, 
            {
                name: name,
                members: members,
                description: description
            });
    }
    
    public static async getGroups(page: number, searchTerm: string): Promise<GetGroupsResponse> {
        const search = !!searchTerm ? `&search=${searchTerm}` : '';
        return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/groups?page=${page}${search}`, false);
    }

    public static async getGroup(id: string): Promise<any> {
        return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/groups/${id}`, false);
    }

    public static async deleteGroup(id: string): Promise<any> {
      return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/groups/${id}`, false);
    }

    public static async editGroup(id: string, name: string, members: string[], description: string): Promise<any> {
        return await APIBaseService.performRequest("patch", `${APIBaseService.baseUrl}/groups/${id}`, false,
            {
                name: name,
                members: members,
                description: description
            });
    }

    public static async leaveGroup(id: string): Promise<any> {
        return await APIBaseService.performRequest("put", `${APIBaseService.baseUrl}/groups/${id}/leave`, false);
    }

    public static async uploadGroupPicture(id: string, image: ProfileImageRequest): Promise<any> {
        return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/groups/${id}/picture`, false, image);
    }

    public static async deleteGroupPicture(id: string): Promise<any> {
        return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/groups/${id}/picture`, false);
    }

}
