import { strings } from "../locales/strings";
import { SelectItem } from "../models/models";
import { colors } from "../resources/colors";
import FoodIcon from "../components/Icons/FoodIcon";
import WellnessIcon from "../components/Icons/WellnessIcon";

export const MacroAreaEventSelect: Array<SelectItem> = [
    {
        id: 0,
        label: strings.foodTitle,
        color: colors.yellowCard,
        icon: <FoodIcon />,
    },
    {
        id: 1,
        label: strings.wellnessTitle,
        icon: <WellnessIcon />,
        color: colors.greenCard,
    },
];
