import AsyncStorage from "@react-native-async-storage/async-storage";
import { nativeApplicationVersion } from "expo-application";
import Checkbox from "expo-checkbox";
import { useEffect, useState } from "react";
import { Image, Platform, Pressable, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import BasicAlert from "../components/Alerts/BasicAlert";
import Button from "../components/Button";
import CustomTextInput from "../components/CustomTextInput";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ChangePasswordAlert from "../components/InsertPasswordAlert";
import Loader from "../components/Loader";
import PageNavigation from "../components/PageNavigation";
import PrivacyPolicyAlert from "../components/PrivacyPolicyAlert";
import ResetPassword from "../components/ResetPassword";
import { strings } from "../locales/strings";
import { GetProfile } from "../models/models";
import { PageNames } from "../navigation/NavigationUtils";
import { colors } from "../resources/colors";
import { images } from "../resources/images";
import { EndPoints } from "../services/EndPoints";
import { FirebaseMessaging } from "../services/FirebaseMessaging";
import { NotificationEndPoints } from "../services/NotificationEndPoint";
import { UsersEndpoints } from "../services/UsersEndpoints";
import { styles } from "../styles/LogIn.style";
import { AlertTypes } from "../utils/AlertTypes";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { roles } from "../utils/Roles";

interface PropsInterface {
	navigation: any;
}

const LogInScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
	}, [props]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);

	const [insertPasswordVisible, setInsertPasswordVisible] = useState(false);
	const [termsAndConditionsVisible, setTermsAndConditionsVisible] = useState(false);
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [userRole, setUserRole] = useState<roles>(roles.user);

	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const clearFields = () => {
		setUsername("");
		setPassword("");
		setRememberMe(false);
	};

	const logIn = async () => {
		try {
			setLoaderVisible(true);
			const res = await EndPoints.logIn({ username: username, password: password });
			await AsyncStorage.setItem(asyncStorageUtils.accessToken, res.item.accessToken);
			await AsyncStorage.setItem(asyncStorageUtils.refreshToken, res.item.refreshToken);
			await AsyncStorage.setItem(asyncStorageUtils.rememberMe, rememberMe ? asyncStorageUtils.rememberMe : "");
			await AsyncStorage.setItem(asyncStorageUtils.role, res.item.role);
			setUserRole(res.item.role);

			const profile: GetProfile = await EndPoints.getProfile();
			await AsyncStorage.setItem(asyncStorageUtils.name, profile.item.firstName + " " + profile.item.lastName);
			await AsyncStorage.setItem(asyncStorageUtils.userId, profile.item.id!);
			await AsyncStorage.setItem(asyncStorageUtils.notificationsActive, String(profile.item.settings?.notificationsActive!));

			NotificationEndPoints.getCountNotifications().then((res) => {
				const countNotifications = res.item?.notifications;

				AsyncStorage.setItem(asyncStorageUtils.inviteCount, String(countNotifications));
			});

			const token = await FirebaseMessaging.getToken();
			if (token) {
				await UsersEndpoints.addFirebaseTokens(token);
			}

			const insertNewPassword = res.item.passwordToChange;
			const readtermsAndConditions = profile.item?.readTermsAndConditions && profile.item?.readTermsAndConditions[res.item.role]?.general;
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}

			insertNewPassword ? null : clearFields();

			setTermsAndConditions(readtermsAndConditions);
			if (insertNewPassword) {
				setInsertPasswordVisible(true);
			} else if (!readtermsAndConditions) {
				setTermsAndConditionsVisible(true);
			} else {
				props.navigation.navigate(PageNames.home);
			}
		} catch (e: any) {
			console.log(e);

			showErrorAlert(e?.status, e?.error?.message || strings.errorText);
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const showErrorAlert = (title: string, text: string) => {
		setBasicAlertValues({ isVisible: true, title: title, description: text || strings.errorText, type: AlertTypes.error, buttonText: strings.close });
	};

	const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);

	return (
		<SafeAreaView style={styles.safearea}>
			<Header width={width} navigation={props.navigation} onPress={() => clearFields()} showMenu={false} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<ChangePasswordAlert
				oldPassword={password}
				modalVisible={insertPasswordVisible}
				setModalVisible={(isVisible: boolean) => {
					setInsertPasswordVisible(isVisible);
					if (termsAndConditions) {
						clearFields();
						props.navigation.navigate(PageNames.home);
					} else {
						setTermsAndConditionsVisible(true);
					}
				}}
			/>

			<PrivacyPolicyAlert
				modalVisible={termsAndConditionsVisible}
				termsAndConditions={true}
				changeTermAndConditionsStatus={true}
				setPrivacyPolicyVisible={(isVisible: boolean) => {
					setTermsAndConditionsVisible(isVisible);
					clearFields();
					props.navigation.navigate(PageNames.home);
				}}
				role={userRole}
			/>

			<ResetPassword
				modalVisible={resetPasswordVisible}
				setModalVisible={(isVisible: boolean, isError?: boolean, title?: string, message?: string) => {
					setResetPasswordVisible(isVisible);
					if (!isVisible && (title || message)) {
						setBasicAlertValues({ isVisible: true, title: title ? title : '', description: message ? message : '', type: isError ? AlertTypes.error : AlertTypes.success, buttonText: strings.close });
					}
				}}
				isWeb={isWeb}
			/>

			<ScrollView style={{ marginTop: 30 }} ref={(c) => setScrollRefScreen(c)}>
				{Platform.OS === "web" ? (
					<PageNavigation navigationEvent={clearFields} />
				) : null}

				<View style={[isWeb ? styles.rowView : null, styles.container]}>
					{isWeb ? (
						<View style={styles.imageContainer}>
							<Image style={styles.image} source={images.login}></Image>
						</View>
					) : null}

					<View style={isWeb ? { width: "50%" } : { width: "100%" }}>
						<View style={styles.loginContainer}>
							<Text style={styles.enter}> {strings.enter}</Text>
							<View style={{ width: "40%", marginRight: "5%" }}>
								<Text style={styles.notAlreadyRegistered}>{strings.notAlreadyRegistered}</Text>
								<Pressable
									onPress={() => {
										clearFields();
										props.navigation.navigate(PageNames.signup, { signupFromCoordinator: false });
									}}
								>
									<Text style={styles.signup}> {strings.signup}</Text>
								</Pressable>
							</View>
						</View>
						<View style={isWeb ? styles.loginInputs : null}>
							<CustomTextInput
								title={strings.username}
								styleText={styles.titleTextInput}
								value={username}
								placeholder={""}
								setValue={(value: string) => setUsername(value)}
								obligatory={false}
							/>

							<CustomTextInput
								value={password}
								styleText={styles.titleTextInput}
								placeholder={""}
								title={strings.password}
								secureTextEntry={true}
								obligatory={false}
								setValue={(value: string) => setPassword(value)}
							/>
						</View>

						<Pressable onPress={() => setResetPasswordVisible(true)}>
							<Text style={styles.forgotPassword}> {strings.forgotPassword}</Text>
						</Pressable>

						<View style={styles.rememberMeContainer}>
							<Checkbox value={rememberMe} onValueChange={(value: boolean) => setRememberMe(value)} color={colors.grey} />
							<Text style={styles.rememberMeText}>{strings.rememberNextTime}</Text>
						</View>

						<Button
							style={styles.button}
							text={strings.access}
							filledButton={true}
							handleButtonPress={() => logIn()}
							disabled={username.length == 0 || password.length == 0}
						/>

						{/* LOG IN SOCIAL */}

						{/* <View>
							<View style={styles.rememberMeContainer}>
								<View style={styles.line}></View>
								<Text style={styles.or}>{strings.or}</Text>
								<View style={styles.line}></View>
							</View>

							<SocialLoginButton
								onPress={() => console.log("login with facebook")}
								text={strings.facebookLogin}
								imagePath={images.icFacebook}
								socialLogin={socialLogin.facebook}
								icon={undefined}/>

							<SocialLoginButton
								onPress={() => console.log("login with google")}
								text={strings.googleLogin}
								imagePath={images.icGoogle}
								socialLogin={socialLogin.google}
								icon={undefined}/>

							<SocialLoginButton
								onPress={() => console.log("login with apple")}
								text={strings.appleLogin}
								imagePath={images.icApple}
								socialLogin={socialLogin.apple}
								icon={undefined}/>
						</View> */}
					</View>
				</View>
				{Platform.OS !== "web" ? (
					<Text style={styles.version}>
						{strings.version}: {nativeApplicationVersion}
					</Text>
				) : null}					
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={false} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default LogInScreen;
