import axios, { AxiosRequestConfig, AxiosResponse, Method, AxiosInstance } from "axios";
import { Platform } from "react-native";
import BuildConfig from "react-native-build-config";
import { urls } from "../resources/urls";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { EndPoints } from "./EndPoints";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { FirebaseMessaging } from "./FirebaseMessaging";
import { UsersEndpoints } from "./UsersEndpoints";

export abstract class APIBaseService {
	protected static baseUrl: string = process.env.BASE_URL!;
	protected static authBaseUrl: string = process.env.AUTH_BASE_URL!;
	protected static placeBaseUrl: string = process.env.PLACE_BASE_URL!;
	protected static chatBaseUrl: string = process.env.CHAT_BASE_URL!;

	protected static googleBaseUrl: string = urls.googlePlacesBaseUrl;

	protected static timeout: number = 10000;
	protected static debugLoggingEnabled: boolean = false;

	public static getEnvironment(): string {
		if (Platform.OS == "web") {
			return "dev";
		} else {
			return BuildConfig.FLAVOR.toLowerCase().includes("prod") ? "prod" : "dev";
		}
	}

	private static async getDefaultHeaders(): Promise<any> {
		return {};
	}

	protected static async getAxiosInstance(config?: { headers?: any }): Promise<AxiosInstance> {
		const requestConfig: AxiosRequestConfig = {
			baseURL: this.baseUrl,
			headers:
				config && config.headers
					? {
							...(await this.getDefaultHeaders()),
							...config.headers,
					  }
					: await this.getDefaultHeaders(),
			timeout: this.timeout,
		};
		return axios.create(requestConfig);
	}


	static getHeaders = async (auth: boolean, isFormData: boolean): Promise<any> => {
		if (auth && isFormData) {
			return { 
				Authorization: "Bearer " + (await AsyncStorage.getItem(asyncStorageUtils.accessToken)),
				"Content-Type": `multipart/form-data;`,
		}
		} else if (auth) {
				return { 
					Authorization: "Bearer " + (await AsyncStorage.getItem(asyncStorageUtils.accessToken)),
				}
		} else {
			return Promise.resolve();
			}
	}

	protected static async performRequest<T = any>(method: Method, url: string, isNotAuth: boolean, data?: any, isChunk?: boolean): Promise<T> {
		try {
			console.log("🐈 Request", "(" + method + "): " + url + "    Body: " + JSON.stringify(data));

			const instance = await this.getAxiosInstance({
				headers: await this.getHeaders(!isNotAuth, isChunk ?? false)
			});

			const response = await instance.request<T>({ method, url, data});

			console.debug("🐈 Response", JSON.stringify(response?.data));

			return response.data;
		} catch (error: any) {
			console.log("🐈 error", JSON.stringify(error));

			if (error.response?.status == 401 && !url.includes("refresh-token")) {
				const res = await this.refreshToken();
				console.log("🐈 Response refresh token api call " + JSON.stringify(res.item));
				await AsyncStorage.setItem(asyncStorageUtils.accessToken, res.item.accessToken);
				await AsyncStorage.setItem(asyncStorageUtils.refreshToken, res.item.refreshToken);

				// FIXME - understand if this is necessary 
				// const token = await FirebaseMessaging.getToken();
				// if (token) {
				// 	await UsersEndpoints.addFirebaseTokens(token);
				// }

				const response = await this.performRequest(method, url, data);
				console.log("🐈 Response", JSON.stringify(response.data));
				return response;
				
			} else if (error.response?.status === 308) {
				console.log("Request error 308")
				return error;
			} else {
				if (url.includes("refresh-token")) {
					await AsyncStorage.clear();
				}
				throw { code: error?.response?.status, ...error?.response?.data };
				// throw this.getErrorMessage((error.response?.status) ? (error.response?.status) : 0);
			}
		}
	}

	protected static async getErrorMessage(errorStatus: number): Promise<string> {
		if (errorStatus === 404) {
			return "resource_not_found";
		}

		if (errorStatus === 400) {
			return "bad_request";
		}

		if (errorStatus === 401) {
			return "authentication failed";
		}

		return "generic_error";
	}

	public static arrayToQueryString(field: string, arr: (number | string)[]) {
		return arr.map((i) => `${field}=${i}`).join("&");
	}
	public static async refreshToken(): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/refresh-token/local`, true, {
			accessToken: await AsyncStorage.getItem(asyncStorageUtils.accessToken),
			refreshToken: await AsyncStorage.getItem(asyncStorageUtils.refreshToken),
		});
	}
}
