import { StyleSheet, View, Modal, Pressable, Image } from "react-native";
import { colors } from "../resources/colors";
import { gif } from "../resources/gif";

interface props {
	loaderVisible: boolean;
	setLoaderVisible: (isVisible: boolean) => any;
}

const Loader = (props: props) => {
	return (
		<Modal animationType="fade" transparent={true} visible={props.loaderVisible} onRequestClose={() => props.setLoaderVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} />
			<View style={styles.container}>
				<Image source={gif.loader} style={{ width: 50, height: 50 }} />
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.5,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
});

export default Loader;
