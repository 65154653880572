import { useEffect, useRef, useState } from "react";
import { AppState, ScrollView, TextInput, View, Text, Platform, AppStateStatus } from "react-native";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { privacyPolicyHtml } from "../locales/privacyPolicy";
import { styles } from "../styles/StaticFooterPages.style";
import RenderHtml from "react-native-render-html";
import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { SafeAreaView } from "react-native-safe-area-context";
import { PageNames } from "../navigation/NavigationUtils";

interface PropsInterface {
	navigation: any;
}

const PrivacyPolicyScreen = (props: PropsInterface) => {

	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
    const isPlatformWeb = Platform.OS === "web";
	const focused = useIsFocused();
	const [isLogged, setIsLogged] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	
    useEffect(() => {
        if (isPlatformWeb) {
            window.addEventListener("resize", () => {
                console.log('width ' + window.innerWidth )
                setWidth(window.innerWidth);
            });
        }
        checkIsLogged();
    }, [focused])

	const checkIsLogged = async () => {
        const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
        if (id && id != '') {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
	};

    return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				onPress={() => props.navigation.navigate(PageNames.home)}
				showMenu={isLogged}
				showLoginButton={!isLogged}
			/>
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			
			<ScrollView>
				<View style={styles.container}>
					<RenderHtml source={{ html: privacyPolicyHtml }} contentWidth={500} />
				</View>
				<Footer
					isWeb={isWeb}
					width={width}
					navigation={props.navigation}
				/>
			</ScrollView>
		</SafeAreaView>
	);
};

export default PrivacyPolicyScreen;
