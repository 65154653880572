import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal, Platform, Pressable, StyleSheet, TextInput, View, Text, TouchableWithoutFeedback } from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import CloseIcon from "./CloseIcon";

dayjs.locale("it");

interface props {
	onDateChange: (value: Date) => void;
	value?: Date | string;
	disabled?: boolean;
	styleTextInput?: any;
}

interface InputProps {
	value?: string | Date;
	date?: Date;
	dateChanges: Function;
	setDate: Function;
	datePickerVisible: boolean;
	setDatePickerVisible: Function;
	disabled: boolean;
	styleTextInput?: any;
}

const CustomInput = ({ dateChanges, setDate, datePickerVisible, setDatePickerVisible, disabled, styleTextInput, value }: InputProps) => {
	const inputValue = !!value ? dayjs(value).format("DD/MM/YYYY") : "";
	const [textDate, setTextDate] = useState<string>("");

	React.useEffect(() => {
		setTextDate(inputValue);
	}, [inputValue]);

	return (
		<View style={[styles.datePickerContainer, styleTextInput]}>
			<TouchableWithoutFeedback
				style={[styles.datePickerText, styleTextInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}, styles.datePickerPressable]}
				onPressIn={() => {
					setDatePickerVisible(!datePickerVisible);
				}}
			>
				<TextInput
					style={[styles.datePickerText, styleTextInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
					onBlur={(text) => {
						if (dayjs(text?.nativeEvent?.text, 'DD/MM/YYYY').isValid()) {
							dateChanges(
								dayjs(text.nativeEvent.text, "DD/MM/YYYY")
									.add(6, "hour" as dayjs.OpUnitType)
									.toDate()
							);
							setDate(
								dayjs(text.nativeEvent.text, "DD/MM/YYYY")
									.add(6, "hour" as dayjs.OpUnitType)
									.toDate()
							);
						}
					}}
					onChangeText={(text: string) => {
						setTextDate(text);
					}}
					editable={!disabled}
					placeholder={strings.select}
					value={textDate}
					placeholderTextColor={colors.grey}
				></TextInput>
			</TouchableWithoutFeedback>
		</View>
	);
};

const DatePickerComponent = ({ onDateChange, value, disabled = false, styleTextInput }: props) => {
	useEffect(() => {
		dayjs.extend(require("dayjs/plugin/localeData"));
		dayjs.extend(require("dayjs/plugin/customParseFormat"));
	}, []);

	const [date, setDate] = useState(value ? new Date(value) : undefined);
	const [datePickerVisible, setDatePickerVisible] = useState(false);

	const initialDate = dayjs(date);

	return (
		<View style={[styles.container]}>
			{	Platform.OS === "web" ?
				<CustomInput
					dateChanges={onDateChange}
					setDate={setDate}
					datePickerVisible={datePickerVisible}
					setDatePickerVisible={setDatePickerVisible}
					styleTextInput={styleTextInput}
					value={date}
					disabled={ disabled }
				/>
				:
				<Pressable style={styles.datePickerContainer} onPress={() => setDatePickerVisible(true)}>
					<Text style={[styles.datePickerText, { width: "97%", marginHorizontal: "1.5%", paddingTop: 15, color: value ? colors.black : colors.grey }]}>{value ? dayjs(date).format("DD/MM/YYYY").toString() : strings.select}</Text>
				</Pressable>
			}
			
			{!disabled && datePickerVisible ? (
				<>
					{Platform.OS === "web" ? (
						<View style={styles.webContainer}>
							<CalendarPicker
								width={320}
								maxDate={new Date()}
								selectedDayColor={colors.blue}
								weekdays={dayjs.weekdaysShort()}
								months={dayjs.months()}
								previousTitle={strings.previous}
								nextTitle={strings.next}
								selectMonthTitle={strings.selectMonthTitle}
								selectYearTitle={strings.selectYearTitle}
								onDateChange={(date: Date) => {
									if (dayjs(date).isValid()) {
										onDateChange(date);
										setDate(date);
										setDatePickerVisible(false);
									}
								}}
								selectedStartDate={initialDate.toDate()}
								initialDate={initialDate.toDate()}
								textStyle={{ fontFamily: fonts.RalewayRegular }}
								todayBackgroundColor={colors.white}
							/>
						</View>
					) : (
						<Modal animationType="fade" transparent={true} visible={datePickerVisible} onRequestClose={() => setDatePickerVisible(false)}>
							<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => setDatePickerVisible(false)} />
							<CloseIcon pressHandler={() => setDatePickerVisible(false)} />
							<View style={styles.mobileContainer}>
								<CalendarPicker
									maxDate={new Date()}
									selectedStartDate={initialDate.toDate()}
									initialDate={initialDate.toDate()}
									selectedDayColor={colors.grey}
									weekdays={dayjs.weekdaysShort()}
									months={dayjs.months()}
									previousTitle={strings.previous}
									nextTitle={strings.next}
									selectMonthTitle={strings.selectMonthTitle}
									selectYearTitle={strings.selectYearTitle}
									onDateChange={(date: Date) => {
										if (dayjs(date).isValid()) {
											onDateChange(date);
											setDate(date);
											setDatePickerVisible(false);
										}
									}}
									textStyle={{ fontFamily: fonts.RalewayRegular }}
									todayBackgroundColor={colors.white}
								/>
							</View>
						</Modal>
					)}
				</>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.white,
		borderColor: colors.grey,
		zIndex: 99999,
	},
	datePickerContainer: {
		backgroundColor: colors.white,
		flexDirection: "row",
		alignItems: "flex-start",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
		zIndex: 999,
		marginHorizontal: 16,
	},
	datePickerText: {
		backgroundColor: colors.white,
		color: colors.black,
		height: 47,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		fontFamily: fonts.RalewayRegular,
		borderColor: colors.grey,
	},
	webContainer: {
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 1,
		borderRadius: 10,
		position: "absolute",
		marginTop: 50,
		marginLeft: "1.75%",
		paddingTop: 5,
		zIndex: 99999,
		backgroundColor: colors.white,
		opacity: 1,
	},
	mobileContainer: {
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 1,
		borderRadius: 10,
		top: "25%",
		paddingTop: 5,
		zIndex: 99999,
		backgroundColor: colors.white,
		opacity: 1,
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.5,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	datePickerPressable: {
		paddingLeft: 0,
		paddingRight: 0,
	},
});

export default DatePickerComponent;
