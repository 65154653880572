
import { useState } from "react";
import { View, Text } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import { ActionSheetProvider, connectActionSheet } from "@expo/react-native-action-sheet";
import dayjs from "dayjs";
import BasicAlert from "../../../../components/Alerts/BasicAlert";
import SaveDraftComponent from "../../../../components/Events/SaveDraftComponent";
import Footer from "../../../../components/Footer";
import Loader from "../../../../components/Loader";
import PageNavigation from "../../../../components/PageNavigation";
import PageNumber from "../../../../components/PageNumber";
import BasicToast from "../../../../components/Toasts/BasicToast";
import { strings } from "../../../../locales/strings";
import { MacroArea } from "../../../../models/models";
import { PageNames } from "../../../../navigation/NavigationUtils";
import { styles } from "../../../../styles/CreateEventScreen";
import { AlertTypes } from "../../../../utils/AlertTypes";
import { roles } from "../../../../utils/Roles";
import { FirstPageView } from "./pages/FirstPageView";
import { Event } from "../../../../models/eventsModels";
import Header from "../../../../components/Header";
import { SecondPageView } from "./pages/SecondPageView";
import { ThirdPageView } from "./pages/ThirdPageView";
import useCreateEventWellnessController from "../controller/useCreateEventWellnessController";
import DeleteActivity from "../../../../components/Events/Activities/DeleteActivity";
import DeletePlace from "../../../../components/Places/DeletePlace";
import { EventParticipationType } from "../../../../utils/types/EventType";
import { EventStatus } from "../../../../utils/EventStatus";

dayjs.extend(require("dayjs/plugin/localeData"));
dayjs.locale("it");

interface PropsInterface {
	navigation?: any;
	showActionSheetWithOptions: any;
	route: {
		params: {
			event: Event;
			userId: string;
			role: roles;
			edit: boolean;
			macroarea: MacroArea;
			page?: PageNames,
			filters?: string,
		};
	};
}

const CreateEventScreenWellness = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const {
		isReadOnly, pageSelected, macroArea, role,
		eventId, event, setEvent, saveEvent,calendarEventId,
		activity, setActivity,
		activities, setActivities,
		loaderVisible, setLoaderVisible,
		basicAlertValues, setBasicAlertValues,
		errorToastVisible, setErrorToastVisible,
		deleteAlert, setDeleteAlert,
		deleteActivityAlert,setDeleteActivityAlert,
		setScrollRefScreen, clearFields,
		deleteActivity, searchActivity,
		changeActivityStatus,
		openInternalUserPicker, setOpenInternalUserPicker,
		setPlaceImages,placeImages,

		// FIRST PAGE
		placesList, setPlacesList,
		validDate, setValidDate,
		image, setImage, uploadImage,
		handleFirstPageForwardButton,

		// SECOND PAGE
		firstRender, setFirstRender,
		propsEvent,
		internalOperator, setInternalOperator,
		externalOperator, setExternalOperator,
		internalUsers, externalUsers,
		handleSecondPageBackButton, handleSecondPageForwardButton,

		// THIRD PAGE
		invitedUserIds, setInvitedUserIds,
		handleThirdPageBackButton,page,filters
	} = useCreateEventWellnessController(props);


	return (
		<SafeAreaView style={styles.safeArea}>
			<Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} />
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
					basicAlertValues.buttonText === strings.back && pageSelected===2 ? handleSecondPageBackButton() : null

				}}
			/>

			<BasicToast
				title={errorToastVisible.text}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible.isVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
			/>



			<DeleteActivity
				title={strings.deleteActivity}
				description={strings.deleteEventDescription}
				modalVisible={deleteActivityAlert}
				setDeleteActivityVisible={(isVisible: boolean) => setDeleteActivityAlert(isVisible)}
				activity={activity}
				delete={(id: string) => {
					deleteActivity(id)
					setDeleteActivityAlert(false)

				}}
			/>
			<DeletePlace
				title={strings.deleteEvent}
				description={strings.deleteEventDescription}
				modalVisible={deleteAlert}
				setDeletePlaceVisible={(isVisible: boolean) => setDeleteAlert(isVisible)}
				delete={() => {
					setDeleteAlert(false);
					saveEvent(false, false, true);

				}}
			/>

			<ScrollView ref={(c) => setScrollRefScreen(c)}>
				<View style={styles.scrollView}>
					{isWeb ? (
						<>
							<PageNavigation navigationEvent={clearFields} />
							{props?.route?.params?.edit && props?.route?.params?.event?.status !== EventStatus.draft? null : (
								<View style={[styles.rowView, { marginHorizontal: "5%" }]}>
									<View style={{ width: "70%" }}>
										<Text style={[styles.title, { marginTop: 20, marginBottom: 20 }]}>{strings.createEvent + "!"}</Text>
										<Text style={styles.description}>{strings.createEventDescription}</Text>
										<Text style={styles.label}>{strings.createEventLabel}</Text>
									</View>
									<View style={{ width: "30%" }}>
										<SaveDraftComponent
											label={strings.saveAsDraft}
											disabled={
												event.field === "" ||
												event.title === "" ||
												event.startDate === null ||
												event.endDate === null ||
												(event.participationType !== EventParticipationType.online ? event.place?._id === "" && !event.placeFreeText : null)
											}
											saveDraft={() => saveEvent(true, false, false)}
										/>
									</View>
								</View>
							)}
						</>
					) : null}
					<View style={[isWeb ? styles.rowView : {}, { marginHorizontal: "5%" }]}>
						<View style={isWeb ? { width: "70%" } : {}}>
							{props.route.params.edit ? null : (
								<>
									{pageSelected === 1 ? <Text style={styles.title}>{strings.createEventTitle}</Text> : null}
									{pageSelected === 1 ? <Text style={styles.subTitle}>{strings.createEventScope} {macroArea.label}</Text> : null}
									{pageSelected === 2 ? <Text style={styles.title}>{strings.tellYourEvent}</Text> : null}
									{pageSelected === 3 ? <Text style={styles.title}>{strings.inviteFriends}</Text> : null}
								</>
							)}
						</View>
						<View style={isWeb ? { width: "30%" } : {}}>
							<PageNumber pageSelected={pageSelected} pageNumber={3} />
						</View>
					</View>

					<View style={{ marginHorizontal: "5%" }}>
						{pageSelected === 1 &&
							<FirstPageView
							macroArea={macroArea}
							isReadOnly={isReadOnly}
							edit={props.route.params.edit}
							role={role}
							eventId={eventId}
							calendarEventId={calendarEventId}
							event={event}
							propsEvent={propsEvent}
							setEvent={setEvent}
							handleFirstPageForwardButton={handleFirstPageForwardButton}
							setTypology={() => { } }
							placesList={placesList}
							setPlacesList={setPlacesList}
							validDate={validDate}
							setValidDate={setValidDate}
							image={image}
							setImage={setImage}
							uploadImage={uploadImage} 
							setPlaceImages={setPlaceImages} 
							placeImages={placeImages}							/>}

						{pageSelected === 2 &&
							<SecondPageView
								macroArea={macroArea}
								isReadOnly={isReadOnly}
								edit={props.route.params.edit}
								role={role}
								eventId={eventId}
								event={event}
								setEvent={setEvent}
								typology={undefined}
								placesList={placesList}
								firstRender={firstRender}
								setFirstRender={setFirstRender}
								propsEvent={propsEvent}
								handleSecondPageBackButton={handleSecondPageBackButton}
								handleSecondPageForwardButton={handleSecondPageForwardButton}
								internalOperator={internalOperator}
								setInternalOperator={setInternalOperator}
								externalOperator={externalOperator}
								setExternalOperator={setExternalOperator}
								internalUsers={internalUsers}
								externalUsers={externalUsers}
								activity={activity}
								setActivity={setActivity}
								setEditActivy={false}
								setActivities={setActivities}
								activities={activities}
								searchActivity={searchActivity}
								setDeleteActivityAlert={setDeleteActivityAlert}
								changeActivityStatus={changeActivityStatus}setOpenInternalUserPicker={setOpenInternalUserPicker}
								openInternalUserPicker={openInternalUserPicker}
							    userId={props.route.params.userId}

				/>}


						{pageSelected === 3 &&
							<ThirdPageView
							isReadOnly={isReadOnly}
							macroArea={macroArea}
							edit={props.route.params.edit}
							userId={props.route.params.userId}
							eventId={eventId}
							event={event}
							propsEvent={propsEvent}
							setEvent={setEvent}
							invitedUserIds={invitedUserIds}
							setInvitedUserIds={setInvitedUserIds}
							setDeleteAlert={setDeleteAlert}
							handleThirdPageBackButton={handleThirdPageBackButton}
							saveEvent={saveEvent} 
							createSubEvent={false} 
							setCreateSubEvent={()=>{}}
							role={role}
							page = {props.route.params.page}
							filters={props.route.params.filters}
							/>}
					</View>

					{!isWeb && !props?.route?.params?.edit && props?.route?.params?.event?.status !== EventStatus.draft ? (
						<View style={{ marginHorizontal: "5%" }}>
							<SaveDraftComponent
								label={strings.saveAsDraft}
								disabled={
									event.field === "" || event.title === "" || event.startDate === null || event.endDate === null || event?.place?._id === ""
								}
								saveDraft={() => saveEvent(true, false, false)}
							/>
						</View>
					) : null}
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

const CreateEventScreenConnected = connectActionSheet(CreateEventScreenWellness);

const CreateEventScreenWellnessConnectedContainer = (props: PropsInterface) => {
	return (
		<ActionSheetProvider>
			<CreateEventScreenConnected
				navigation={props.navigation}
				showActionSheetWithOptions={props.showActionSheetWithOptions}
				route={{
					params: {
						event: props.route.params.event,
						userId: props.route.params.userId,
						role: props.route.params.role,
						edit: props.route.params.edit,
						macroarea: props.route.params.macroarea,
						page:props.route.params.page,
						filters:props.route.params.filters,
					},
				}}
			/>
		</ActionSheetProvider>
	);
};

export default CreateEventScreenWellnessConnectedContainer;
