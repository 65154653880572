import { Platform, Pressable, Text, View, StyleSheet, Linking, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Loader from "../components/Loader";
import { useIsFocused } from "@react-navigation/native";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import Button from "../components/Button";
import { Meet } from "../models/models";
import { EndPoints } from "../services/EndPoints";
import { images } from "../resources/images";


interface PropsInterface {
	navigation?: any;
	route: {
		params: {
			meet: Meet;
		};
	};
}

const MeetInstructionsScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const focused = useIsFocused();
	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [userMeetEmail, setUserMeetEmail] = useState("");

	const [scrollRefScreen, setScrollRefScreen] = useState(null);

	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;

		initUserMeetEmail();
	}, [focused]);

	const initUserMeetEmail = async (): Promise<void> => {
		setLoaderVisible(true);
		try {
			const profile = await EndPoints.getProfile();
			setUserMeetEmail(profile.item.workspaceEmail);
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const clearFields = () => { };

	const goBack = () => {
		props.navigation.goBack();
	};

	const openMeetWindow = async () => {
		const meetLink = props.route.params.meet.link;
		// No documentation found for this url an its parameters
		const meetLoginLink = `https://accounts.google.com/AccountChooser/signinchooser?continue=${meetLink}&flowEntry=AccountChooser`;
		const supported = await Linking.canOpenURL(meetLoginLink);

		if (supported) {
			await Linking.openURL(meetLoginLink);
		} else {
			setError({ isVisible: true, title: strings.errorTitle, description: strings.errorLinkNotSupported });
		}
	};

	const openMeetButton = () => (
		<View style={styles.openMeetButton}>
			<Button handleButtonPress={() => openMeetWindow()} text={strings.openMeet} filledButton={false} />
		</View>
	);

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={props.navigation} onPress={clearFields} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={error.title}
				description={error.description}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={error.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setError({ isVisible: isVisible, title: error.title, description: error.description });
				}}
			/>

			<ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb && <PageNavigation navigationEvent={clearFields} />}

				<View style={[styles.whiteContainer, isWeb ? styles.webContainer : styles.mobileContainer]}>
					<Pressable style={styles.backContainer} onPress={() => goBack()}>
						<FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
						<Text style={styles.backText}>{strings.goBack}</Text>
					</Pressable>
					<View style={styles.instructionsContainer}>
						<View style={styles.stepContainer}>
							<Text style={styles.text}>{`1. ${strings.meetInstructions.step1}`}</Text>
							{openMeetButton()}
						</View>
						<View style={styles.stepContainer}>
							<Text style={styles.text}>{`2. ${strings.meetInstructions.step2}`}</Text>
							<Image resizeMode="contain" style={[{ height: 500, width: "100%" }]} source={images.meetStep2}></Image>
						</View>
						<View style={styles.stepContainer}>
							<Text style={styles.text}>{`3. ${strings.meetInstructions.step3(userMeetEmail)}`}</Text>
							<Image resizeMode="contain" style={[{ height: 500, width: "100%" }]} source={images.meetStep3}></Image>
						</View>
						<View style={styles.stepContainer}>
							<Text style={styles.text}>{`4. ${strings.meetInstructions.step4}`}</Text>
							<Image resizeMode="contain" style={[{ height: 500, width: "100%" }]} source={images.meetStep4}></Image>
						</View>
					</View>
					{openMeetButton()}
				</View>				
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		padding: "4%",
	},
	webContainer: {
		marginHorizontal: "5%",
		marginBottom: "5%",
	},
	mobileContainer: {
		marginBottom: "5%",
		marginHorizontal: "5%",
	},
	backContainer: {
		flexDirection: "row",
		textDecorationLine: "underline",
		color: colors.blue,
	},
	backText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.blue,
		marginLeft: 5,
	},
	backIcon: {
		marginTop: 2,
	},
	instructionsContainer: {
		width: "100%",
		paddingVertical: 30,
		paddingHorizontal: 16,
	},
	text: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 18,
		color: colors.black,
	},
	stepContainer: {
		marginBottom: 15,
	},
	openMeetButton: {
		marginTop: 10,
		marginBottom: 10,
	},
});

export default MeetInstructionsScreen;
