import { Platform, Pressable, Text, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import { styles } from "../styles/CreateGroup.style";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Loader from "../components/Loader";
import { useIsFocused } from "@react-navigation/native";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleLeft, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import CustomTextInput from "../components/CustomTextInput";
import { ImageInfo, Profile, ProfileImageRequest, USERS_FILTERS } from "../models/models";
import { ProfileImageMapper } from "../mappers/ProfileImageMapper";
import { ActionSheetProvider, connectActionSheet, useActionSheet } from "@expo/react-native-action-sheet";
import { AttachmentPicker } from "../utils/AttachmentPicker";
import BasicToast from "../components/Toasts/BasicToast";
import { UsersEndpoints } from "../services/UsersEndpoints";
import { capitalizeName } from "../utils/UserUtils";
import { colors } from "../resources/colors";
import Searchbar from "../components/Searchbar";
import Pagination from "../components/Pagination";
import PeopleIcon from "../components/Icons/PeopleIcon";
import { CreateGroup, Group } from "../models/groupsModels";
import { GroupService } from "../services/chat/GroupService";


interface PropsInterface {
	navigation?: any;
	showActionSheetWithOptions: any;
	route: {
		params: {
			creation: boolean;
			group?: Group;
		};
	};
}

const attachmentPicker = new AttachmentPicker();

const CreateGroupScreen = (props: PropsInterface) => {
	const params = props.route.params;
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const focused = useIsFocused();
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [profilePictureObject, setProfilePictureObject] = useState<ProfileImageRequest | null>(null);
	const [group, setGroup] = useState<CreateGroup>({
		id: "",
		members: [],
		name: "",
		groupPicture: "",
		description: "",
	});
	const [users, setUsers] = useState<{ totalCounts: number; items: Profile[] }>({ totalCounts: 0, items: [] });
	const [usersFilters, setUsersFilters] = useState<USERS_FILTERS>({ searchTerm: "", pageSize: 6, page: 1, userFilter: "all", sorting: "asc" });
	const [deleteGroupAlertVisible, setDeleteGroupAlertVisible] = useState(false);
	const [groupCreatedAlertVisible, setGroupCreatedAlertVisible] = useState(false);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		if (!params.creation && !!params.group) {
			// modify group
			const members: string[] = [];
			params.group.members.forEach((el) => {
				members.push(el._id);
			});
			setGroup({
				id: params.group.id,
				name: params.group.name,
				members: members,
				description: params.group.description,
				groupPicture: params.group.groupPicture,
			});
		} else {
			clearFields();
		}
	}, [focused]);

	const searchUsers = async () => {
		setLoaderVisible(true);
		try {
			const usersRes = await UsersEndpoints.getUsers(
				usersFilters.page,
				usersFilters.pageSize,
				usersFilters.searchTerm,
				usersFilters.sorting,
				[],
				usersFilters.userFilter != "all" ? usersFilters.userFilter : undefined
			);
			const items = isWeb ? usersRes.page.items : usersFilters.page === 1 ? usersRes.page.items : users.items.concat(usersRes.page.items);
			setUsers({
				totalCounts: usersRes.total,
				items,
			});
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, buttonText: strings.ok, type: AlertTypes.error });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	useEffect(() => {
		searchUsers();
	}, [usersFilters]);

	const createGroup = async () => {
		setLoaderVisible(true);
		try {
			const res: any = await GroupService.createGroup(group.name, group.members, group.description ?? "");
			if (!!profilePictureObject && !!res && !!res._id) {
				const resUpload = await GroupService.uploadGroupPicture(res._id, profilePictureObject);
				if (resUpload.status === "ok") {
					setGroupCreatedAlertVisible(true);
					goBack();
				}
			} else {
				setGroupCreatedAlertVisible(true);
				goBack();
			}
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, buttonText: strings.ok, type: AlertTypes.error });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const modifyGroup = async () => {
		setLoaderVisible(true);
		try {
			const res: Group = await GroupService.editGroup(group.id, group.name, group.members, group.description ?? "");
			if (!!res) {
				if (!!profilePictureObject && !!res && !!res.id) {
					const resUpload = await GroupService.uploadGroupPicture(res.id, profilePictureObject);
					if (resUpload.status === "ok") {
						setBasicAlertValues({
							isVisible: true,
							title: strings.editedGroupAlert.title,
							description: strings.editedGroupAlert.description,
							buttonText: strings.editedGroupAlert.close,
							type: AlertTypes.success,
						});
						goBack();
					}
				} else if (profilePictureObject === null && !!res.groupPicture) {
					const resUpload = await GroupService.deleteGroupPicture(res.id);
					if (resUpload.status === "ok") {
						setBasicAlertValues({
							isVisible: true,
							title: strings.editedGroupAlert.title,
							description: strings.editedGroupAlert.description,
							buttonText: strings.editedGroupAlert.close,
							type: AlertTypes.success,
						});
						goBack();
					}
				} else {
					setBasicAlertValues({
						isVisible: true,
						title: strings.editedGroupAlert.title,
						description: strings.editedGroupAlert.description,
						buttonText: strings.editedGroupAlert.close,
						type: AlertTypes.success,
					});
					goBack();
				}
			}
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, buttonText: strings.ok, type: AlertTypes.error });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const deleteGroup = () => {
		setLoaderVisible(true);
		GroupService.deleteGroup(group.id)
			.then((res: any) => {
				setBasicAlertValues({
					isVisible: true,
					title: strings.deletedGroupAlert.title,
					description: strings.deletedGroupAlert.description,
					buttonText: strings.deletedGroupAlert.close,
					type: AlertTypes.success,
				});
				goBack();
			})
			.catch((e: any) => {
				setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, buttonText: strings.ok, type: AlertTypes.error });
			})
			.finally(() => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			});
	};

	const { showActionSheetWithOptions } = useActionSheet();
	const addCoverImage = async () => {
		const imageInfo = Platform.OS === "web" ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
		if (imageInfo) {
			if (Platform.OS === "web" && !(imageInfo as ImageInfo).uri.includes("image")) {
				setErrorToastVisible({ isVisible: true, text: strings.errorPhotoUploaded });
			} else {
				if (attachmentPicker.isSmallSize(Platform.OS === "web" ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
					if (Platform.OS === "web") {
						setGroup({ ...group, groupPicture: (imageInfo as ImageInfo).uri });
					} else {
						const mobileImage =
							Platform.OS === "android" && (imageInfo as ImageInfo[])[0].uri ? (imageInfo as ImageInfo[])[0].uri : (imageInfo as ImageInfo[])[0].fileURI;
						setGroup({ ...group, groupPicture: mobileImage });
					}
					const image = Platform.OS === "web" ? (imageInfo as ImageInfo) : (imageInfo as ImageInfo[])[0];
					setProfilePictureObject(Platform.OS === "web" ? ProfileImageMapper.mapWeb(image) : ProfileImageMapper.mapMobile(image));
				} else {
					Platform.OS === "web"
						? setErrorToastVisible({ isVisible: true, text: strings.errorPhotoTooBig })
						: setBasicAlertValues({
								isVisible: true,
								title: strings.error,
								description: strings.errorPhotoTooBig,
								buttonText: strings.ok,
								type: AlertTypes.error,
						  });
				}
			}
		}
	};

	const openActionSheet = () => {
		const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
		const destructiveButtonIndex = undefined;
		const cancelButtonIndex = 2;
		return new Promise((resolve, reject) => {
			showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
				switch (buttonIndex) {
					case 0: {
						attachmentPicker._pickFromGallery("photo", resolve, reject);
						break;
					}
					case 1: {
						attachmentPicker._takePhoto(resolve, reject);
						break;
					}
					case 2: {
						break;
					}
				}
			});
		});
	};

	const goBack = () => {
		clearFields();
		props.navigation.goBack();
	};

	const clearFields = () => {
		setGroup({ id: "", members: [], name: "", groupPicture: "", description: "" });
		setUsers({ totalCounts: 0, items: [] });
		setUsersFilters({ searchTerm: "", pageSize: 6, page: 1, userFilter: "all", sorting: "asc" });
	};

	const UserRow = (item: Profile, index: number) => {
		return (
			<Pressable
				style={[styles.rowView, styles.userRow, group.members.includes(item.id ?? "") ? { backgroundColor: colors.blue } : null]}
				key={`partecipants${index}`}
				onPress={() => {
					const members = group.members;
					if (group.members.includes(item.id ?? "")) {
						members.forEach((member, index) => {
							if (member === item.id) {
								members.splice(index, 1);
							}
						});
					} else {
						!!item.id ? members.push(item.id) : "";
					}
					setGroup({ ...group, members: members });
				}}
			>
				<Text style={[styles.userName, group.members.includes(item.id ?? "") ? { color: colors.white } : null]}>{capitalizeName(item)}</Text>
				<Text
					style={[
						styles.userName,
						{ textDecorationLine: "underline", textTransform: "uppercase" },
						group.members.includes(item.id ?? "") ? { color: colors.white } : null,
					]}
				>
					{item.id && group.members.includes(item.id) ? strings.remove : strings.add}
				</Text>
			</Pressable>
		);
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={props.navigation} onPress={clearFields} />
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicToast
				title={errorToastVisible.text}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible.isVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
			/>

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => setBasicAlertValues({ ...basicAlertValues, isVisible: isVisible })}
			/>

			{/* Delete group alert */}
			<BasicAlert
				title={strings.deleteGroupAlert.title}
				description={strings.deleteGroupAlert.description}
				buttonText={strings.deleteGroupAlert.cancel}
				alertType={AlertTypes.warning}
				alertVisible={deleteGroupAlertVisible}
				secondButtonText={strings.deleteGroupAlert.delete}
				secondButtonAction={() => deleteGroup()}
				setAlertVisible={(isVisible: boolean) => setDeleteGroupAlertVisible(isVisible)}
				styleSecondButton={{ backgroundColor: colors.red }}
			/>

			{/* Group created alert */}
			<BasicAlert
				title={strings.groupCreated.title}
				description={strings.groupCreated.description}
				buttonText={strings.groupCreated.close}
				alertType={AlertTypes.success}
				alertVisible={groupCreatedAlertVisible}
				socialShareEnable={true}
				socialSharing={{ title: strings.groupCreated.title }}
				setAlertVisible={(isVisible: boolean) => setGroupCreatedAlertVisible(isVisible)}
			/>

			<ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb ? <PageNavigation navigationEvent={clearFields} /> : null}

				<View style={[styles.whiteContainer, isWeb ? styles.rowView : null]}>
					<View style={[styles.groupsContainer]}>
						<Text style={styles.title}>{params.creation ? strings.createNewGroup : strings.editGroup}</Text>

						<Pressable style={styles.rowView} onPress={() => goBack()}>
							<FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
							<Text style={styles.backText}>{strings.goBack}</Text>
						</Pressable>

						<View style={[isWeb ? [styles.rowView, { marginHorizontal: "5%" }] : null, { marginTop: 50 }]}>
							<View style={{ width: isWeb ? "50%" : "100%" }}>
								<CustomTextInput
									title={strings.groupName}
									value={group.name}
									placeholder={strings.insertGroupName}
									obligatory={false}
									setValue={(value: string) => setGroup({ ...group, name: value })}
								/>

								<View style={[styles.rowView, { justifyContent: "space-between", marginTop: 40 }]}>
									<Text style={styles.addPartecipants}>{strings.addPartecipants}</Text>
									<View style={styles.rowView}>
										<PeopleIcon />
										<Text style={styles.partecipants}>{group.members.length + " " + strings.partecipants}</Text>
									</View>
								</View>
								<View>
									{users.items.length > 0 && (
										<View style={{ width: "100%" }}>
											<View style={{ marginTop: 5, marginBottom: 10, marginHorizontal: 16 }}>
												<Searchbar
													placeholder={strings.name + " " + strings.surname}
													search={(val) => setUsersFilters({ ...usersFilters, page: 1, searchTerm: val })}
												/>
											</View>
											{users.items.map((item: Profile, index: number) => UserRow(item, index))}
											<View style={{ width: "100%" }}>
												<View style={styles.pagination}>
													{isWeb ? (
														<Pagination
															pages={Math.ceil(users.totalCounts / usersFilters.pageSize) || 1}
															currentPage={usersFilters.page}
															handlePageSelect={(newPage: number) => setUsersFilters({ ...usersFilters, page: newPage })}
														/>
													) : Math.ceil(users.totalCounts / usersFilters.pageSize) > usersFilters.page ? (
														<Button
															text={strings.users.loadMore}
															handleButtonPress={() => setUsersFilters({ ...usersFilters, page: usersFilters.page + 1 })}
															filledButton={true}
														/>
													) : null}
												</View>
											</View>
										</View>
									)}
								</View>
							</View>

							<View style={{ width: isWeb ? "50%" : "100%" }}>
								<View style={isWeb ? styles.coverImageWebContainer : styles.coverImageMobileContainer}>
									<Text style={[styles.loadPicture, { textAlign: "center" }]}>{strings.loadGroupImage}</Text>
									{!!group?.groupPicture ? (
										<View style={!isWeb ? { flexDirection: "row", justifyContent: "center" } : null}>
											<View style={styles.coverImageWeb}>
												<Image resizeMode="cover" style={styles.coverImage} source={{ uri: group.groupPicture }}></Image>
											</View>

											<View
												style={
													isWeb
														? { flexDirection: "row", justifyContent: "center" }
														: { width: "50%", alignContent: "center", alignItems: "center", alignSelf: "center" }
												}
											>
												<View style={isWeb ? styles.buttonCoverImageWebContainer : styles.buttonCoverImageMobileContainer}>
													<Button
														handleButtonPress={() => addCoverImage()}
														text={strings.changeImage}
														style={isWeb ? styles.buttonCoverImageWeb : styles.buttonCoverImageMobile}
														filledButton={true}
													/>
												</View>
												<View style={isWeb ? styles.buttonCoverImageWebContainer : styles.buttonCoverImageMobileContainer}>
													<Button
														handleButtonPress={() => setGroup({ ...group, groupPicture: undefined })}
														text={strings.deleteImage}
														style={isWeb ? styles.buttonCoverImageWeb : styles.buttonCoverImageMobile}
														filledButton={false}
													/>
												</View>
											</View>
										</View>
									) : (
										<View style={!isWeb ? { flexDirection: "row", justifyContent: "space-between" } : null}>
											<FontAwesomeIcon style={styles.coverImage} size={140} icon={faUserCircle} />
											<Button handleButtonPress={() => addCoverImage()} text={strings.uploadImage} style={{ alignSelf: "center" }} filledButton={true} />
										</View>
									)}
									<CustomTextInput
										numberOfLine={4}
										maxLenght={500}
										value={group?.description ?? ""}
										placeholder={strings.groupNote}
										title={isWeb ? "" : strings.note}
										obligatory={false}
										styleTextInput={styles.noteWeb}
										setValue={(value: string) => setGroup({ ...group, description: value })}
									/>
								</View>
							</View>
						</View>
						<View style={params.creation ? styles.createButton : isWeb ? styles.buttons : styles.buttonsMobile}>
							{!params.creation && (
								<Button
									handleButtonPress={() => setDeleteGroupAlertVisible(true)}
									text={isWeb ? strings.deleteGroup : strings.delete}
									filledButton={true}
									style={{ backgroundColor: colors.red }}
								/>
							)}
							<Button
								handleButtonPress={() => (params.creation ? createGroup() : modifyGroup())}
								text={params.creation ? strings.createGroup : isWeb ? strings.saveChanges : strings.save}
								filledButton={true}
							/>
						</View>
					</View>
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

const CreateGroupScreenConnected = connectActionSheet(CreateGroupScreen);

const CreateGroupScreenConnectedContainer = (props: PropsInterface) => {
	return (
		<ActionSheetProvider>
			<CreateGroupScreenConnected
				navigation={props.navigation}
				showActionSheetWithOptions={props.showActionSheetWithOptions}
				route={{
					params: {
						creation: props.route.params.creation,
						group: props.route.params.group,
					},
				}}
			/>
		</ActionSheetProvider>
	);
};

export default CreateGroupScreenConnectedContainer;
