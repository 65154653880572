
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { View, Pressable, Platform, ScrollView, Text } from "react-native";
import CustomTextInput from "../../../../../components/CustomTextInput";
import MenuCarousel from "../../../../../components/Events/MenuCarousel";
import ScopeListMenu from "../../../../../components/Events/ScopeListMenu";
import { strings } from "../../../../../locales/strings";
import { colors } from "../../../../../resources/colors";
import { styles } from "../../../../../styles/CreateEventScreen";
import { EventStatus } from "../../../../../utils/EventStatus";
import { roles } from "../../../../../utils/Roles";
import { EventType } from "../../../../../utils/types/EventType";
import { SecondPageViewModel } from "../../../../../models/createEventModels";
import useSecondPageController from "../../controller/pages/useSecondPageController";
import Button from "../../../../../components/Button";
import { CollaboratorsView } from "../../../components/CollaboratorsView";
import { OperatorsAndNumberOfPartecipants } from "../../../components/OperatorsAndNumberOfPartecipantsView";
import BasicAlert from "../../../../../components/Alerts/BasicAlert";
import Loader from "../../../../../components/Loader";
import BasicToast from "../../../../../components/Toasts/BasicToast";
import { AlertTypes } from "../../../../../utils/AlertTypes";

export const SecondPageView = (props: SecondPageViewModel) => {

    const {
        isWeb, scrollRef, setScrollRef, scrollX, setScrollX, openSubEvent,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
    } = useSecondPageController(props);

    return <View style={styles.container}>
        {/* Description */}
        {props.isReadOnly ? (
            <>
                <Text style={styles.fieldTitle}>{strings.description}</Text>
                <Text style={[styles.readonly, props.event.description === "" && { height: 150 }]}>{props.event.description}</Text>
            </>
        ) : (
            <CustomTextInput
                numberOfLine={8}
                maxLenght={500}
                value={props.event.description}
                placeholder={""}
                title={strings.description}
                obligatory={false}
                styleText={{ marginLeft: 0, marginRight: 0 }}
                styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                setValue={(value: string) => props.setEvent({ ...props.event, description: value })}
            />
        )}

        {props.typology && props.typology.key === EventType[0].type && (
            <View>
                {/* LUNCH */}
                <View style={[styles.rowView, { marginTop: 20 }]}>
                    <Text style={[styles.fieldTitle]}>{strings.menu}</Text>
                    <View style={styles.rowView}>
                        <Pressable
                            onPress={() => {
                                if (Platform.OS === "web") {
                                    scrollRef?.scrollTo({ x: scrollX - 50, y: 0, animated: true });
                                    setScrollX(scrollX - 50);
                                } else {
                                    props.menuIndex > 0 && props.setMenuIndex(props.menuIndex - 1);
                                }
                            }}
                        >
                            <View style={[styles.menuIconsContainer, { marginRight: 5 }]}>
                                <FontAwesomeIcon style={[styles.menuIcons]} color={colors.white} size={15} icon={faAngleLeft}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                        <Pressable
                            onPress={() => {
                                if (Platform.OS === "web") {
                                    scrollRef?.scrollTo({ x: scrollX + 50, y: 0, animated: true });
                                    setScrollX(scrollX + 50);
                                } else {
                                    props.menuIndex < 4 && props.setMenuIndex(props.menuIndex + 1);
                                }
                            }}
                        >
                            <View style={styles.menuIconsContainer}>
                                <FontAwesomeIcon style={[styles.menuIcons]} color={colors.white} size={15} icon={faAngleRight}></FontAwesomeIcon>
                            </View>
                        </Pressable>
                    </View>
                </View>

                {/* Menu */}
                {Platform.OS === "web" ? (
                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} ref={(c) => setScrollRef(c)}>
                        <ScopeListMenu
                            isReadOnly={props.isReadOnly}
                            objectKey={"eventMenu"}
                            value={props.event.menu.appetizer}
                            placeholder={strings.appetizerPlaceholder}
                            title={strings.appetizer}
                            setValue={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, appetizer: value } })}
                        />
                        <ScopeListMenu
                            isReadOnly={props.isReadOnly}
                            objectKey={"eventMenu"}
                            value={props.event.menu.mainCourse}
                            placeholder={strings.mainCoursePlaceholder}
                            title={strings.mainCourse}
                            setValue={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, mainCourse: value } })}
                        />
                        <ScopeListMenu
                            isReadOnly={props.isReadOnly}
                            objectKey={"eventMenu"}
                            value={props.event.menu.secondCourse}
                            placeholder={strings.secondCoursePlaceholder}
                            title={strings.secondCourse}
                            setValue={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, secondCourse: value } })}
                        />
                        <ScopeListMenu
                            isReadOnly={props.isReadOnly}
                            objectKey={"eventMenu"}
                            value={props.event.menu.dessert}
                            placeholder={strings.dessertPlaceholder}
                            title={strings.dessert}
                            setValue={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, dessert: value } })}
                        />
                        <ScopeListMenu
                            isReadOnly={props.isReadOnly}
                            objectKey={"eventMenu"}
                            value={props.event.menu.other}
                            placeholder={strings.otherPlaceholder}
                            title={strings.other}
                            setValue={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, other: value } })}
                        />
                    </ScrollView>
                ) : (
                    <MenuCarousel
                        isReadOnly={props.isReadOnly}
                        objectKey="eventMenu"
                        menuIndex={props.menuIndex}
                        setMenuIndex={props.setMenuIndex}
                        appetizer={props.event.menu.appetizer}
                        setAppetizer={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, appetizer: value } })}
                        mainCourse={props.event.menu.mainCourse}
                        setMainCourse={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, mainCourse: value } })}
                        secondCourse={props.event.menu.secondCourse}
                        setSecondCourse={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, secondCourse: value } })}
                        dessert={props.event.menu.dessert}
                        setDessert={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, dessert: value } })}
                        other={props.event.menu.other}
                        setOther={(value: string) => props.setEvent({ ...props.event, menu: { ...props.event.menu, other: value } })}
                        firstRender={props.firstRender}
                        setFirstRester={props.setFirstRender}
                    />
                )}
                <View style={styles.line}></View>

                <View style={[isWeb ? styles.rowView : null]}>
                    <CollaboratorsView
                        isFullWidth={!isWeb}
                        isWeb={isWeb}
                        edit={props.edit}
                        role={props.role}
                        isReadOnly={props.isReadOnly}
                        propsEvent={props.propsEvent}
                        event={props.event}
                        setEvent={props.setEvent}
                        collaborators={props.collaborators}
                        setCollaborators={props.setCollaborators}
                        openSubEvent={openSubEvent}
                    />
                    <OperatorsAndNumberOfPartecipants
                        isFullWidth={!isWeb}
                        isWeb={isWeb}
                        edit={props.edit}
                        role={props.role}
                        isReadOnly={props.isReadOnly}
                        typology={props.typology}
                        placesList={props.placesList}
                        event={props.event}
                        setEvent={props.setEvent}
                        internalOperator={props.internalOperator}
                        externalOperator={props.externalOperator}
                        internalUsers={props.internalUsers}
                        externalUsers={props.externalUsers}
                        setInternalOperator={props.setInternalOperator}
                        setExternalOperator={props.setExternalOperator}
                    />
                </View>
            </View>
        )}

        {props.typology && props.typology.key === EventType[1].type && (
            <View>
                {/* GROCERY */}
                <View style={isWeb ? styles.rowView : null}>
                    <View style={isWeb ? { width: "50%", marginRight: 0 } : null}>
                        {/* Grocery List */}
                        {props.isReadOnly ? (
                            <>
                                <Text style={styles.fieldTitle}>{strings.groceryList}</Text>
                                <Text style={styles.readonly}>{props.groceryList}</Text>
                            </>
                        ) : (
                            <CustomTextInput
                                numberOfLine={8}
                                maxLenght={500}
                                value={props.groceryList}
                                placeholder={strings.groceryPlaceholder}
                                title={strings.groceryList}
                                obligatory={false}
                                styleText={{ marginLeft: 0, marginRight: 0 }}
                                styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                                style={{ height: 200 }}
                                setValue={props.setGroceryList}
                            />
                        )}
                        <OperatorsAndNumberOfPartecipants
                            isFullWidth={true}
                            isWeb={isWeb}
                            edit={props.edit}
                            role={props.role}
                            isReadOnly={props.isReadOnly}
                            typology={props.typology}
                            event={props.event}
                            setEvent={props.setEvent}
                            placesList={props.placesList}
                            internalOperator={props.internalOperator}
                            externalOperator={props.internalOperator}
                            internalUsers={props.internalUsers}
                            externalUsers={props.externalUsers}
                            setInternalOperator={props.setInternalOperator}
                            setExternalOperator={props.setExternalOperator}
                        />
                    </View>
                    <View style={isWeb ? { width: "50%", marginLeft: 10, marginRight: 10, zIndex: 0 } : { marginTop: 10 }}>
                        <CollaboratorsView
                            isFullWidth={true}
                            isWeb={isWeb}
                            edit={props.edit}
                            role={props.role}
                            isReadOnly={props.isReadOnly}
                            propsEvent={props.propsEvent}
                            event={props.event}
                            setEvent={props.setEvent}
                            collaborators={props.collaborators}
                            setCollaborators={props.setCollaborators}
                            openSubEvent={openSubEvent}
                        />
                    </View>
                </View>
            </View>
        )}

        <View style={[styles.rowView, { marginTop: 50 }]}>
            <Button
                handleButtonPress={props.handleSecondPageBackButton}
                text={strings.back}
                filledButton={true}
                style={{ marginTop: 10 }}
            />
            <Button
                handleButtonPress={props.handleSecondPageForwardButton}
                text={strings.forward}
                filledButton={true}
                style={{ marginTop: 10 }}
                disabled={props.role === roles.serviceCoordinator && props.event.operatorId === "" && props.edit && props.event.status != EventStatus.draft}
            />
        </View>

        <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

        <BasicAlert
            title={basicAlertValues.title}
            description={basicAlertValues.description}
            buttonText={basicAlertValues.buttonText}
            alertType={basicAlertValues.type}
            alertVisible={basicAlertValues.isVisible}
            setAlertVisible={(isVisible: boolean) => {
                setBasicAlertValues({
                    isVisible: isVisible,
                    title: basicAlertValues.title,
                    description: basicAlertValues.description,
                    type: basicAlertValues.type,
                    buttonText: basicAlertValues.buttonText,
                });
            }}
        />

        <BasicToast
            title={errorToastVisible.text}
            alertType={AlertTypes.error}
            alertVisible={errorToastVisible.isVisible}
            setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
        />

    </View>;
}