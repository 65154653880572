import { EntityResponse } from "../models/exchange";
import { Meet } from "../models/models";
import { APIBaseService } from "./ApiBaseService";

export class MeetService extends APIBaseService {
  public static async getGroupMeet(groupId: string): Promise<EntityResponse<Meet>> {
    return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/meet/group/${groupId}`, false);
  }

  public static async getEventMeet(eventId: string): Promise<EntityResponse<Meet>> {
    return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/meet/event/${eventId}`, false);
  }

  public static async getUserMeet(userId1: string, userId2: string): Promise<EntityResponse<Meet>> {
    return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/meet/user1/${userId1}/user2/${userId2}`, false);
  }
}
