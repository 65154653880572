import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface props {
	value: any;
	setValue: (value: any) => void;
	style?: any;
	isReadOnly?: boolean;
	maxValue?: number | string; // Sometimes this is a string, IDK
	maxValueMessage?: string; // Message show when maxValue is reached
}

const Counter = (props: props) => {
	useEffect(() => {
		if (props.value != "") {
			setValue(props.value.toString());
		}
	}, [props]);

	const [value, setValue] = useState("");

	const add = () => {
		if (props.value === "") {
			props.setValue(0);
			setValue("0");
		} else if (parseInt(props.value) < 10000) {
			let v = parseInt(props.value) + 1;
			v = parseInt(checkMaxValue(v.toString()));
			props.setValue(v);
			setValue(v.toString());
		}
	};

	const remove = () => {
		if (props.value > 0) {
			let v = parseInt(props.value) - 1;
			v = parseInt(checkMaxValue(v.toString()));
			props.setValue(v);
			setValue(v.toString());
		} else {
			props.setValue("");
			setValue("");
		}
	};

	const checkMaxValue = (value: string): string => {
		if (props.maxValue == null || props.maxValue === "") {
			return value;
		}

		if (parseInt(value) > Number(props.maxValue)) {
			return props.maxValue.toString();
		}
		return value;
	};

	const isMaxValueReached = (): boolean => {
		return parseInt(value) === props.maxValue;
	};

	return (
		<View style={[styles.container, props.style && props.style]}>
			<View style={[styles.inputContainer, props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {}]}>
				<Pressable style={[styles.minus, { userSelect: "none" } as any]} onPress={() => (props.isReadOnly ? null : remove())}>
					<FontAwesomeIcon size={20} icon={faMinus} />
				</Pressable>
				{props.isReadOnly ? (
					<Text style={[styles.text, props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {}]}>{props.value}</Text>
				) : (
					<TextInput
						style={[styles.text, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
						keyboardType={"numeric"}
						value={value}
						placeholder="_"
						onChangeText={(currentValue) => {
							if (props.value < 10000 || currentValue === "") {
								let v = currentValue.replace(/[^0-9]/g, "");
								v = checkMaxValue(v);
								props.setValue(v);
								setValue(v);
							}
						}}
					/>
				)}
				<Pressable style={[styles.plus, { userSelect: "none" } as any]} onPress={() => (props.isReadOnly ? null : add())} disabled={isMaxValueReached()}>
					<FontAwesomeIcon size={20} icon={faPlus} color={isMaxValueReached() ? "grey" : undefined} />
				</Pressable>
			</View>
			{props.maxValueMessage && isMaxValueReached() ? <Text style={styles.errorLabel}>{props.maxValueMessage}</Text> : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginLeft: "5%",
	},
	text: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
	},
	inputContainer: {
		backgroundColor: colors.white,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
		width: Platform.OS ==="web" ? "50%" : "100%",
		paddingLeft: 10,
		paddingRight: 10,
	},
	minus: {
		position: "absolute",
		left: 15,
		top: 15,
		zIndex: 9999,
	},
	plus: {
		position: "absolute",
		right: 15,
		top: 15,
		zIndex: 9999,
	},
	errorLabel: {
		color: colors.red,
		marginTop: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
	},
});

export default Counter;
