import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
		marginBottom: 12,
	},
	description: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		marginTop: 20,
		marginRight: "5%",
		marginLeft: "5%",
		marginBottom: "2%",
	},
	rowView: {
		flexDirection: "row",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	webContainer: {
		marginHorizontal: "5%",
		marginBottom: "5%",
	},
	mobileContainer: {
		marginBottom: "5%",
		marginHorizontal: "5%"
	},
	groupsContainerWeb: {
		height: "100%",
		width: "100%",
		paddingRight: 58,
		paddingLeft: 58,
	},
	groupsContainer: {
		width: "100%",
		paddingVertical: 30,
		paddingHorizontal: 16,
	},
	actions: {
		display: "flex",
		flexDirection: "column",
	},
	actionsWeb: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	groupName: {
		fontSize: 18,
		marginLeft: 16,
		color: colors.black,
		fontFamily: fonts.RalewayBold,
	},
	groupRow: {
		flexDirection: "row",
		paddingVertical: 4,
		paddingHorizontal: 4,
 		backgroundColor: colors.white,
		justifyContent: 'space-between',
	},
	sortIcon: {
		color: colors.grey4Border,
	},
	pressableHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	groupTable: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 24,
	},
	searchbarContainer: {
		backgroundColor: colors.white,
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderColor: colors.grey4,
		height: 50,
	},
	nextIcon: {
		marginLeft: 10,
		marginRight: 10,
		marginTop: 3,
		color: colors.blue3,
	},
	back: {
		marginLeft: 10,
		fontSize: 12,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	webImageContainer: {
		width: "100%",
		height: 130,
	},
	mobileImageContainer: {
		width: "95%",
		marginLeft: 10,
		marginRight: 10,
		marginTop: 30,
		height: 130,
	},
	image: {
		height: "100%",
		width: "100%",
	},
	backContainer: {
		margin: 20,
	},
	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	groupItemName: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	modifyGroupLabel: {
		fontFamily: fonts.RalewayBold,
		color: colors.blue,
		textTransform: 'uppercase',
		textAlign: 'right',
		marginRight: 2,
		fontSize: 14
	},
	modifyGroupLabelMobile: {
		marginLeft: 16,
		marginTop: 5
	}
});
