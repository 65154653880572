import { Text, StyleSheet, Platform, Modal, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import PlaceManager from "./PlaceManager";
import ExitFromModify from "./ExitFromModify";
import { useState } from "react";
import { roles } from "../../utils/Roles";

interface props {
	modalVisible: boolean;
	update: boolean;
	placeId: string;
	delete: () => any;
	setPlaceManagerVisible: (isVisible: boolean) => any;
	ableToDelete: boolean;
	role: roles | undefined;
}

const PlaceManagerAlert = (props: props) => {
	const [exitFromModify, setExitFromModify] = useState(false);

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => (props.update ? setExitFromModify(true) : props.setPlaceManagerVisible(false))} />

			<Pressable style={styles.close} onPress={() => (props.update ? setExitFromModify(true) : props.setPlaceManagerVisible(false))}>
				<FontAwesomeIcon color={colors.white} size={20} icon={faClose} />
				<Text style={styles.closeText}>{strings.close}</Text>
			</Pressable>

			<ExitFromModify
				modalVisible={exitFromModify}
				setExitFromModifyVisible={(isVisible: boolean) => setExitFromModify(isVisible)}
				exitWithoutSaving={() => {
					setExitFromModify(false);
					props.setPlaceManagerVisible(false);
				}}
			/>

			<PlaceManager
				ableToDelete={props.ableToDelete}
				delete={() => props.delete()}
				update={props.update}
				placeId={props.placeId}
				setPlaceManagerVisible={(isVisible: boolean) => props.setPlaceManagerVisible(isVisible)} 
				helper={0} 
				role={props.role}			
			/>
		</Modal>
	);
};

const styles = StyleSheet.create({
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	close: {
		position: "absolute",
		right: "10%",
		top: Platform.OS === "web" ? "5%" : "9%",
		flexDirection: "row",
		zIndex: 9999,
	},
	closeText: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		color: colors.white,
		marginLeft: 5,
	},
});

export default PlaceManagerAlert;
