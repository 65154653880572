
import { faExclamationTriangle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { View, Pressable, Text, FlatList } from "react-native";
import CustomTextInput from "../../../../../components/CustomTextInput";
import { strings } from "../../../../../locales/strings";
import { colors } from "../../../../../resources/colors";
import { styles } from "../../../../../styles/CreateEventScreen";
import { SecondPageViewModel } from "../../../../../models/createEventModels";
import useSecondPageController from "../../controller/pages/useSecondPageController";
import Button from "../../../../../components/Button";
import BasicAlert from "../../../../../components/Alerts/BasicAlert";
import Loader from "../../../../../components/Loader";
import BasicToast from "../../../../../components/Toasts/BasicToast";
import { AlertTypes } from "../../../../../utils/AlertTypes";
import SelectOperator from "../../../../../components/Events/SelectOperator";
import Counter from "../../../../../components/Counter";
import ActivityCard from "../../../../../components/Events/Activities/ActivityCard";
import ActivityModal from "../../../../../components/Events/Activities/ActivityModal";
import { EventActivityStatus, EventStatus } from "../../../../../utils/EventStatus";



export const SecondPageView = (props: SecondPageViewModel) => {

    const {
        isWeb,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
        editActivity, setEditActivy, setIsModalVisible, isModalVisible,
    } = useSecondPageController(props);


    return <View style={styles.container}>
        {/* Description */}
        {props.isReadOnly ? (
            <>
                <Text style={styles.fieldTitle}>{strings.description}</Text>
                <Text style={[styles.readonly, props.event.description === "" && { height: 150 }]}>{props.event.description}</Text>
            </>
        ) : (
            <CustomTextInput
                numberOfLine={8}
                maxLenght={500}
                value={props.event.description}
                placeholder={""}
                title={strings.description}
                obligatory={false}
                styleText={{ marginLeft: 0, marginRight: 0 }}
                styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                setValue={(value: string) => props.setEvent({ ...props.event, description: value })}
            />
        )}

        <View>
            <View style={[{ marginBottom: 20 }, isWeb ? styles.rowView : null]}>
                <View style={isWeb ? { width: '50%', paddingRight: 20 } : { width: '100%' }}>
                    <SelectOperator
                        isReadOnly={props.isReadOnly}
                        event={props.event}
                        setEvent={(value: any) => props.setEvent(value)}
                        userSelected={props.internalOperator}
                        internalUsers={props.internalUsers}
                        setUserSelected={(firstName: string, lastName: string) => props.setInternalOperator({ firstName: firstName, lastName: lastName })}
                        isInternal={true}
                        openInternalUserPicker={props.openInternalUserPicker}
                        setOpenInternalUserPicker={(value: boolean) => props.setOpenInternalUserPicker(value)}
                        typology={undefined}
                        pageName={""}
                        obligatory={props.event.status !== EventStatus.draft}


                    />
                </View>
                <View style={{ width: '100%', zIndex: 1 }}>
                    {/* Max Number of Users */}
                    <Text style={[styles.fieldTitle, { marginTop: 20 }]}>{strings.maxNumberOfUsers}</Text>
                    <View style={{ marginBottom: isWeb ? 50 : 0, marginTop: 10 }}>
                        <Counter
                            isReadOnly={props.isReadOnly}
                            value={props.event.maxNumberOfUsers}
                            setValue={(value) => props.setEvent({ ...props.event, maxNumberOfUsers: value.toString() })}
                            style={{ marginLeft: 0, width: '100%' }}
                            maxValue={props.placesList.find((place) => place.id === props.event.place._id)?.maxNumberOfUsers}
                            maxValueMessage={strings.maxUsersReached}
                        ></Counter>
                    </View>
                </View>
            </View>

            <View style={[isWeb ? styles.container : { padding: 6 }, { position: 'relative', zIndex: -1 }]}>

                <View style={styles.line}></View>

                <View style={isWeb ? styles.rowView : null}>
                    <Text style={styles.fieldTitle}>{strings.fromEventActivities}</Text>
                    <View style={[isWeb ? styles.webRightItem : styles.rowView, { marginTop: 10 }]}>
                        <>
                            {props.activities.totalCounts > 0
                                ?
                                <>
                                    {props.event.status === EventStatus.wait_external_operator ? null : (
                                        <Pressable style={styles.button}
                                            onPress={() => {
                                                setEditActivy(false)
                                                setIsModalVisible(true)
                                            }} >
                                            <View style={styles.rowViewButton}>
                                                <FontAwesomeIcon color={colors.white} size={15} icon={faPlus}></FontAwesomeIcon>
                                                <Text style={styles.textButton} >{strings.addActivity}</Text>
                                            </View>
                                        </Pressable>
                                    )}
                                </>
                                : (
                                    <View style={styles.noActivityContainer}>
                                        <View style={styles.noActivityRowViewButton}>
                                            <FontAwesomeIcon color={colors.orange} size={15} icon={faExclamationTriangle}></FontAwesomeIcon>
                                            <Text style={styles.noActivityDescription}>{strings.activityInfo}</Text>
                                        </View>
                                    </View>
                                )}
                        </>
                    </View>
                </View>
                {props.activities.totalCounts > 0
                    ?
                    <FlatList
                        data={props.activities.items}
                        renderItem={({ item, index }) => {
                            return (
                                <View style={{ marginTop: 20 }} key={`myactivity${index}`}>
                                    <ActivityCard
                                        activity={item}
                                        edit={() => {
                                            setEditActivy(true);
                                            props.setActivity({ ...item });
                                            setIsModalVisible(true);
                                        }}
                                        cancel={() => {
                                            props.setActivity({ ...item });
                                            props.setDeleteActivityAlert(true);
                                        }}
                                        canEdit={(props.event.status === EventStatus.wait_external_operator && item.status === EventActivityStatus.WAIT_EXTERNAL_OPERATOR
                                            && item.externalOperator?.id === props.userId)
                                        }
                                        changeStatus={() => {
                                            props.changeActivityStatus(item._id);
                                        }}
                                    />
                                </View>
                            );
                        }}
                    />
                    :
                    <View style={styles.activityContainer}>
                        <Pressable style={styles.button}
                            onPress={() => {
                                {
                                    setEditActivy(false)
                                    setIsModalVisible(true)
                                }
                            }} >
                            <View style={styles.rowViewButton}>
                                <FontAwesomeIcon color={colors.white} size={15} icon={faPlus}></FontAwesomeIcon>
                                <Text style={styles.textButton} >{strings.addActivity}</Text>
                            </View>
                        </Pressable>
                    </View>

                }
                {isModalVisible ? (
                    <ActivityModal
                        modalVisible={isModalVisible}
                        isReadOnly={props.isReadOnly}
                        onClose={() => {
                            props.searchActivity();
                            setIsModalVisible(false)
                        }}
                        setOpenActivity={(open: boolean) => setIsModalVisible(open)}
                        event={props.event}
                        edit={editActivity}
                        eventId={props.eventId}
                        activity={editActivity ? props.activity : undefined} />
                ) : (null)}

            </View>
        </View>


        <View style={[styles.rowView, { marginTop: isWeb ? 50 : 20 }]}>
            <Button
                handleButtonPress={props.handleSecondPageBackButton}
                text={strings.back}
                filledButton={true}
                style={{ marginTop: 10 }}
            />
            <Button
                handleButtonPress={props.handleSecondPageForwardButton}
                text={strings.forward}
                filledButton={true}
                style={{ marginTop: 10 }}
                disabled={props.event.status !== EventStatus.draft && props.event.operatorId === ""
                    || props.activities.totalCounts === 0 ||
                    (props.event.status === EventStatus.wait_external_operator &&
                        props.activities.items.some((value: { status: EventActivityStatus; }) => {

                            return value.status === EventActivityStatus.WAIT_EXTERNAL_OPERATOR
                        })
                    )}
            />
        </View>

        <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

        <BasicAlert
            title={basicAlertValues.title}
            description={basicAlertValues.description}
            buttonText={basicAlertValues.buttonText}
            alertType={basicAlertValues.type}
            alertVisible={basicAlertValues.isVisible}
            setAlertVisible={(isVisible: boolean) => {
                setBasicAlertValues({
                    isVisible: isVisible,
                    title: basicAlertValues.title,
                    description: basicAlertValues.description,
                    type: basicAlertValues.type,
                    buttonText: basicAlertValues.buttonText,
                });
            }}
        />

        <BasicToast
            title={errorToastVisible.text}
            alertType={AlertTypes.error}
            alertVisible={errorToastVisible.isVisible}
            setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
        />
    </View>;
}