import { strings } from "../../locales/strings";
import { EventParticipationType } from "./EventType";

export enum Participant {
	DRESSER = "dresser",
	BUSSER = "busser",
	SOUS_CHEF = "sous-chef",
	WINE_EXPERT = "wine-expert",
	SHOPPING_HELP = "shopping-help",
	DRIVER = "driver",
	USER = "user",
}
export const ParticipationsMode =  [
	{
		value:EventParticipationType.inPerson,
		label: strings.participateInPerson,

	},
	{
		value:EventParticipationType.online,
		label: strings.participateOnline,

	}
]
export const Partecipants = [
	{
		value: "dresser",
		label: strings.partecipant.dresser,
		type: "lunch"
	},
	{
		value: "busser",
		label: strings.partecipant.busser,
		type: "lunch"
	},
	{
		value: "sous-chef",
		label: strings.partecipant.sousChef,
		type: "lunch"
	},
	{
		value: "wine-expert",
		label: strings.partecipant.wineExpert,
		type: "lunch"
	},
	{
		value: "shopping-help",
		label: strings.partecipant.shoppingHelp,
		type: "lunch"
	},
	{
		value: "driver",
		label: strings.partecipant.driver,
		type: "grocery"
	},
	{
		value: "user",
		label: strings.partecipant.user,
		type: "lunch"
	},
];

export const GetPartepationType = (type: Participant): string => {
	let label = '';
	switch(type) {
		case Participant.BUSSER: {
			label = strings.partecipant.busser;
			break;
		};
		case Participant.DRESSER: {
			label = strings.partecipant.dresser;
			break;
		};
		case Participant.DRIVER: {
			label = strings.partecipant.driver;
			break;
		};
		case Participant.SHOPPING_HELP: {
			label = strings.partecipant.shoppingHelp;
			break;
		};
		case Participant.SOUS_CHEF: {
			label = strings.partecipant.sousChef;
			break;
		};
		case Participant.USER: {
			label = strings.partecipant.user;
			break;
		};
		case Participant.WINE_EXPERT: {
			label = strings.partecipant.wineExpert;
			break;
		};
	}
	return label;
}