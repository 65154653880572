import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		backgroundColor: colors.primary,
		width: "94%",
		marginHorizontal: "3%",
		marginTop: "5%",
		borderRadius: 10,
		paddingBottom: "5%",
		display: "flex",
		justifyContent: "space-between",
	},
	headerContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	headerColumn: {
		flexBasis: "auto",
		marginTop: 5,
		marginRight: "5%",
	},
	button: {
		marginTop: 10,
		width: "auto",
		padding: "0.5%",
		backgroundColor: colors.blue,
	},
	card: {
		backgroundColor: colors.white,
		margin: "2%",
		flex: 4,
		borderRadius: 10,
	},
	cardContent: {
		margin: "5%",
	},
	cardLabel: {
		marginHorizontal: "3%",
		marginTop: 3,
		fontSize: 12,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
	},
	cardTitle: {
		fontSize: 20,
		fontFamily: fonts.RalewayBold,
		marginHorizontal: "3%",
		marginBottom: "3%",
		justifyContent: "space-between",
	},
	cardDescription: {
		margin: "3%",
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		justifyContent: "space-between",
	},
	coverImage: {
		height: 200,
		width: "100%",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	disabledCard: {
		opacity: 0.3
	}
});
