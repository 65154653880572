import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		backgroundColor: colors.white,
		margin: "5%",
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: "5%",
		marginRight: "5%",
	},
	notAlreadyRegistered: {
		fontSize: 14,
		textAlign: "right",
		color: colors.blue3,
		fontFamily: fonts.RalewayBold,
	},
	signup: {
		textTransform: "uppercase",
		textAlign: "right",
		fontFamily: fonts.RalewayBold,
		color: colors.blue3,
		fontSize: 14,
		marginTop: 5,
	},
	button: {
		marginTop: "5%",
		width: "90%",
		marginLeft: "5%",
		marginRight: "5%",
		height: 50,
		paddingTop: 15,
	},
	hamburger: {
		alignItems: "center",
		justifyContent: "center",
		paddingVertical: 12,
		paddingHorizontal: 32,
		borderRadius: 4,
		elevation: 3,
		width: "5%",
	},
	loginContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	enter: {
		fontFamily: fonts.RalewayBold,
		fontSize: 40,
		marginLeft: "3%",
	},
	titleTextInput: {
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
	},
	forgotPassword: {
		fontSize: 14,
		textAlign: "right",
		color: colors.blue3,
		fontFamily: fonts.RalewayBold,
		marginTop: "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	rememberMeContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: "10%",
		marginBottom: "10%",
	},
	rememberMeText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.grey4,
		marginLeft: 10,
	},
	line: {
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		width: "35%",
	},
	or: {
		width: "30%",
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		color: colors.grey4,
		textTransform: "lowercase",
	},
	image: {
		width: "95%",
		height: "100%",
		borderRadius: 10,
	},
	imageContainer: {
		width: "53%",
		marginLeft: "-3%",
		borderRadius: 10,
	},
	loginInputs: {
		marginHorizontal: 16,
	},
	version: {
		color: colors.blue,
		textAlign: "right",
		marginRight: "5%",
		marginBottom: "3%"
	},
});
