import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Platform, Pressable, Text, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { SafeAreaView } from "react-native-safe-area-context";
import BasicAlert from "../components/Alerts/BasicAlert";
import BackButton from "../components/BackButton";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ChatIcon from "../components/Icons/ChatIcon";
import ChatUnreadIcon from "../components/Icons/ChatUnreadIcon";
import Loader from "../components/Loader";
import PageNavigation from "../components/PageNavigation";
import ProfileImage from "../components/ProfileImage";
import UserInfo from "../components/UserInfo";
import { strings } from "../locales/strings";
import { MessageHistory, PrivateRoomResponse } from "../models/chatModels";
import { Meet, Profile } from "../models/models";
import { PageNames } from "../navigation/NavigationUtils";
import { ChatService } from "../services/chat/ChatService";
import { EndPoints } from "../services/EndPoints";
import { MeetService } from "../services/MeetService";
import { UsersEndpoints } from "../services/UsersEndpoints";
import { styles } from "../styles/User.style";
import { AlertTypes } from "../utils/AlertTypes";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { capitalizeName } from "../utils/UserUtils";


const UserScreen = ({ route, navigation }: any) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const isFocused = useIsFocused();
	const isPlatformWeb = Platform.OS === "web";
	const { userId } = route.params;
	const [user, setUser] = useState<Profile | null>(null);
	const [isUser, setIsUser] = useState(false);
	const [ableToFollow, setAbleToFollow] = useState(false);
	const [currentUser, setCurrentUser] = useState<Profile | null>(null);
	const followUser = currentUser?.follows?.includes(userId) || false;
	const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
	const clearState = () => {
		setUser(null);
	};

	useEffect(() => {
		if (isPlatformWeb) {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		clearState();
		checkRoles();
		getMyProfile();
	}, []);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	useEffect(() => {
		userId && getProfile(userId);
	}, [userId, isFocused]);

	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [shouldLogout, setShouldLogout] = useState<boolean>(false);

	const getProfile = async (userId: string) => {
		await UsersEndpoints.getUser(userId)
			.then((res) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setUser(res.item);
				setAbleToFollow(res.item.roles.includes("user"));
			})
			.catch((e) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setError({ isVisible: true, title: e.status, description: e.error.message });
				setShouldLogout(e.code == 409);
			});
		await connectSocket(userId);
	};

	const getMyProfile = async () => {
		await EndPoints.getProfile()
			.then((res) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setCurrentUser(res.item);
			})
			.catch((e) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setError({ isVisible: true, title: e.status, description: e.error.message });
				setShouldLogout(e.code == 409);
			});
	};

	const toogleFollow = async (userId: string, type: "follow" | "unfollow") => {
		setLoaderVisible(true);
		try {
			const result = await UsersEndpoints.updateUserFollow({
				userId,
				type,
			});
			result.item && setCurrentUser(result.item);
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
			setShouldLogout(e.code == 409);
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const checkRoles = async () => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setIsUser(userRole === "user");
	};

	const [messageHistory, setMessageHistory] = useState<MessageHistory | undefined>(undefined);
	const [privateRoomResponse, setPrivateRoomResponse] = useState<PrivateRoomResponse | undefined>(undefined);

	const connectSocket = async (userId: string) => {
		const privateRoomResponse: PrivateRoomResponse = await ChatService.getPrivateRoom(userId);
		const history: MessageHistory = await ChatService.getRoomMessages(privateRoomResponse.item.id, 1);
		setMessageHistory(history);
		setPrivateRoomResponse(privateRoomResponse);
		getHasUnreadMessages(privateRoomResponse.item.id);
	};

	const getHasUnreadMessages = async (roomId: string) => {
		const unreadMessages = await ChatService.getRoomUnreadMessagesNumber(roomId);
		setHasUnreadMessages(unreadMessages.item.unreadMessages > 0);
	};

	const openPrivateChat = () => {
		navigation?.navigate(PageNames.chat, {
			firstName: user?.firstName,
			lastName: user?.lastName,
			coverPhoto: user?.profilePicture,
			isSpace: false,
			userId: user?.id,
			messageHistory: messageHistory,
			privateRoomResponse: privateRoomResponse,
			getMeet,
		});
	};

	const goToMeetInstructions = async (): Promise<void> => {
		setLoaderVisible(true);
		try {
			const meet = await MeetService.getUserMeet(currentUser?.id!, userId);
			navigation?.navigate(PageNames.meetInstructions, { meet: meet.item });
		} catch (e: any) {
			setError({ isVisible: true, title: e?.status, description: e?.error?.message });
		} finally {
			setLoaderVisible(false);
		}
	};

	const getMeet = async (): Promise<Meet> => {
		const meet = await MeetService.getUserMeet(currentUser?.id!, userId);
		return meet.item;
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={navigation} onPress={() => { }} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={error.title}
				description={error.description}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={error.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setError({ isVisible: isVisible, title: error.title, description: error.description });
					if (shouldLogout) {
						AsyncStorage.clear();
						navigation?.navigate(PageNames.home);
					}
				}}
			/>

			<ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb ? (
					<PageNavigation navigationEvent={clearState} />
				) : (
					<View style={styles.backButtonContainer}>
						<BackButton
							onPress={() => {
								clearState();
								navigation.navigate(PageNames.users);
							}}
						/>
					</View>
				)}

				{user && (
					<View style={{ marginBottom: "5%" }}>
						<View style={[styles.whiteContainer, isWeb ? styles.rowView : null, isWeb ? styles.webContainer : null]}>
							<View style={[styles.usersMobile, isWeb ? styles.usersWeb : null]}>
								<View style={[styles.userTitle, !isWeb ? styles.userTitleMobile : null]}>
									{!isWeb && (
										<ProfileImage
											firstName={user.firstName}
											lastName={user.lastName}
											profilePicture={user.profilePicture}
											key={"profile-picture"}
											size={180}
											style={!isWeb ? styles.userTitleMargin : null}
										/>
									)}
									<Text style={[styles.title, !isWeb ? styles.userTitleMargin : null, isWeb ? styles.titleMargin : null]}>{capitalizeName(user)}</Text>
									{isUser && ableToFollow ? (
										<Button
											text={followUser ? strings.users.unfollow : strings.users.followFriend}
											handleButtonPress={() => user.id && toogleFollow(user.id, followUser ? "unfollow" : "follow")}
											filledButton={followUser ? false : true}
											style={!isWeb ? styles.userTitleMargin : null}
										/>
									) : (
										<></>
									)}
									<Button
										handleButtonPress={() =>
											navigation.navigate(PageNames.calendar, {
												isMyCalendar: false,
												userId: user.id,
												roles: user.roles,
												userName: user.firstName + " " + user.lastName,
											})
										}
										text={strings.openPlaceCalendar}
										filledButton={true}
										style={[!isWeb ? styles.userTitleMargin : null, { marginLeft: 10 }]}
									/>
									<View style={{ flexDirection: "row" }}>
										{/* <Pressable style={{ flexDirection: "row" }} onPress={() => goToMeetInstructions()}>
											<VideochatIcon />
											<Text style={[styles.message, !isWeb ? styles.userTitleMargin : null, isWeb ? styles.titleMargin : null, { textAlign: "right" }]}>
												{strings.openMeetWith + capitalizeName(user)}
											</Text>
										</Pressable> */}

										<Pressable style={{ flexDirection: "row" }} onPress={() => openPrivateChat()}>
											{hasUnreadMessages ? <ChatUnreadIcon /> : <ChatIcon />}
											{isWeb ? (
												<Text style={[styles.message, !isWeb ? styles.userTitleMargin : null, isWeb ? styles.titleMargin : null, { textAlign: "right" }]}>
													{strings.chatWith + capitalizeName(user)}
												</Text>
											) : null}
										</Pressable>
									</View>
								</View>

								<UserInfo user={user} isWeb={isWeb} />
							</View>
						</View>

						<View style={[styles.whiteContainer, isWeb ? styles.rowView : null, isWeb ? styles.webContainer : null]}>
							<View style={[styles.usersMobile, isWeb ? styles.usersWeb : null]}>
								<Text style={styles.title}>{strings.users.eventsOf(user.firstName)}</Text>
							</View>
						</View>
					</View>
				)}
				
				<Footer isWeb={isWeb} width={width} navigation={navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default UserScreen;
