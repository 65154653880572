import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useEffect, useState } from "react";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { strings } from "../locales/strings";
import { GetProfile, SearchProps } from "../models/models";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { EndPoints } from "../services/EndPoints";
import { EventsEndPoint } from "../services/EventsEndPoint";
import { UsersEndpoints } from "../services/UsersEndpoints";
import { AlertTypes } from "../utils/AlertTypes";
import { roles } from "../utils/Roles";
import { rules } from "../utils/Rules";
import BasicAlert from "./Alerts/BasicAlert";
import Button from "./Button";
import CustomTextInput from "./CustomTextInput";
import UsersList from "./Events/InvitedUsersList";
import Loader from "./Loader";
import Searchbar from "./Searchbar";

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	isWeb: boolean;
	eventId?: string;
	invite: boolean;
	invitedUsers?: string[];
	partecipants?: string[];
}

const FollowUsersAlert = (props: props) => {
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const USER_PER_PAGE = 15;
	const DEAFULT_FILTERS: SearchProps = { searchTerm: "", page: 1, userFilter: "all", sorting: "asc" };
	const [users, setUsers] = useState<{ totalCounts: number; items: any[] }>({ totalCounts: 0, items: [] });
	const [userIds, setUserIds] = useState<{ items: string[] }>({ items: [] });

	const [filters, setFilters] = useState<SearchProps>(DEAFULT_FILTERS);
	const getUsers = async (filtersSelected?: SearchProps, followed?: string[]) => {
		try {
			const res = await UsersEndpoints.getUsers(
				filtersSelected ? filtersSelected.page : filters.page, 
				USER_PER_PAGE, 
				filtersSelected ? filtersSelected.searchTerm : filters.searchTerm, 
				filtersSelected ? filtersSelected.sorting : filters.sorting, 
				[roles.user]
			);
			const items = props.isWeb ? res.page.items : ((filtersSelected ? filtersSelected.page : filters.page) === 1 )? res.page.items : users.items.concat(res.page.items);
			let usersInvited: any = [];
			items.forEach((item) => {
				if ((props.partecipants && !props.partecipants.includes(item.id) || !props.partecipants)) {
					let userItem = {
						id: item.id,
						firstName: item.firstName,
						lastName: item.lastName,
						invited: false
					};
	
					if (props.invite) {
						userItem.invited = item.invited || (props.invitedUsers && props.invitedUsers.includes(item.id)) ? true : false
					} else {
						userItem.invited = item.invited || userIds.items.includes(item.id) || (followed ? followed.includes(item.id) : null) ? true : false;
					}
					usersInvited.push(userItem);
				} 
			});
			setUsers({ totalCounts: res.total, items: usersInvited });
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		}
	};

	const handleInvite = async (id: string, isInvited: boolean) => {
		if (props.eventId) {
			if (isInvited) {
				await EventsEndPoint.addInvite({ userId: id, eventId: props.eventId });
			} else {
				await EventsEndPoint.deleteInvite(id, props.eventId);
			}
		}
	};

	useEffect(() => {
		if (!!props.modalVisible) {
			getProfile();
		}
	}, [props.modalVisible]);

	const close = () => {
		setFilters(DEAFULT_FILTERS);
		props.setModalVisible(false);
	}

	const updateUserFollow = async (id: string, follow: boolean) => {
		setLoaderVisible(true);
		await UsersEndpoints.updateUserFollow({ userId: id, type: follow ? "follow" : "unfollow" })
			.then(() => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	const getProfile = async () => {
		await EndPoints.getProfile()
			.then((res: GetProfile) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setUserIds({ items: res.item.follows ?? [] });
				getUsers(undefined, res.item.follows);
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => close()}>
			<Pressable style={[styles.closeIcon, { right: props.isWeb ? "30%" : "10%" }]} onPress={() => close()}>
				<Text style={styles.closeBtnText}>{strings.close}</Text>
				<FontAwesomeIcon style={{ marginTop: 2 }} color={colors.white} size={15} icon={faClose}></FontAwesomeIcon>
			</Pressable>
			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => close()} />
				<View style={[styles.content, { width: props.isWeb ? "40%" : "80%" }]}>
					<BasicAlert
						title={basicAlertValues.title}
						description={basicAlertValues.description}
						buttonText={basicAlertValues.buttonText}
						alertType={basicAlertValues.type}
						alertVisible={basicAlertValues.isVisible}
						setAlertVisible={(isVisible: boolean) => {
							setBasicAlertValues({
								isVisible: isVisible,
								title: basicAlertValues.title,
								description: basicAlertValues.description,
								type: basicAlertValues.type,
								buttonText: basicAlertValues.buttonText,
							});
						}}
					/>
					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<Text style={styles.title}>{(props.invite ? strings.inviteFriends : strings.searchAndFollowFriends) + ":"}</Text>

					<Searchbar
						placeholder={strings.name + " " + strings.surname}
						search={(value: string) => {
							setFilters({ ...DEAFULT_FILTERS, userFilter: filters.userFilter, searchTerm: value });
							getUsers({ ...DEAFULT_FILTERS, userFilter: filters.userFilter, searchTerm: value })
						}}
					/>

					<ScrollView style={{ maxHeight: 300 }}>
						<UsersList
							isReadOnly={false}
							users={users}
							setUsers={(value: any) => setUsers(value)}
							userIds={userIds}
							setUserIds={(value: any) => setUserIds(value)}
							updateStatus={(id: string, followOrInvited: boolean) => props.invite ? handleInvite(id, followOrInvited) : updateUserFollow(id, followOrInvited)}
							filters={filters}
							setFilters={(value: any) => {
								setFilters(value)
								getUsers(value)
							}}
							USER_PER_PAGE={USER_PER_PAGE}
							isWeb={props.isWeb}
							text1={props.invite ? strings.invite : strings.follow}
							text2={props.invite ? strings.invited : strings.followed}
						/>
					</ScrollView>

					<View style={styles.buttonContainer}>
						<Button style={styles.button} handleButtonPress={() => close()} text={strings.save} filledButton={true} />
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	buttonContainer: {
		marginTop: 50,
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
	button: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	closeIcon: {
		position: "absolute",
		top: "10%",
		flexDirection: "row",
		zIndex: 9999,
		flex: 1,
	},
	closeBtnText: {
		fontFamily: fonts.RalewayBold,
		color: colors.white,
		fontSize: 16,
		marginRight: "2%",
	},
});

export default FollowUsersAlert;
