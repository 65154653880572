import { View, Pressable, StyleSheet, Text, Platform } from "react-native";
import { colors } from "../resources/colors";

interface Props {
	text: string;
	type?: "draft" | "waiting" | "complete" | "default";
	color?: string;
	onPress?: () => void | Promise<void>;
	style?: any;
}

const Tag = ({ text, type = "default", color, onPress, style }: Props) => {
	const Component = (
		<View style={[styles.tag, { backgroundColor: colorPicker(type, color) }, style]}>
			<Text style={styles.text}>{text}</Text>
		</View>
	);

	return !!onPress ? <Pressable onPress={onPress}>{Component}</Pressable> : Component;
};

const styles = StyleSheet.create({
	tag: {
		paddingHorizontal: 20,
		paddingVertical: 5,
		borderRadius: 10,
	},
	text: {
		color: colors.blackTag,
		fontWeight: "bold",
		fontSize : Platform.OS ==="web" ? 14 : 10,

	},
});

const colorPicker = (type: "draft" | "waiting" | "complete" | "default", color?: string): string => {
	switch (type) {
		case "draft":
			return colors.grey;
		case "waiting":
			return colors.yellow2;
		case "complete":
			return colors.green2;
		default:
			return color || colors.grey;
	}
};

export default Tag;
