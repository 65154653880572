
import { useActionSheet } from '@expo/react-native-action-sheet';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { ICalendarEventBase } from 'react-native-big-calendar';
import { strings } from '../../../../../locales/strings';
import { PlaceType, getPlaceTypeValueFromEvent, setPlaceTypeValue } from '../../../../../models/PlaceType';
import { FistPageViewModel } from '../../../../../models/createEventModels';
import { CalendarEvents,  EventActivity, PlacesAvailabilityResponse } from '../../../../../models/eventsModels';
import { ImageInfo, KeyLabel, Place } from '../../../../../models/models';
import { EventsEndPoint } from '../../../../../services/EventsEndPoint';
import { AlertTypes } from '../../../../../utils/AlertTypes';
import { AttachmentPicker } from '../../../../../utils/AttachmentPicker';
import { CalendarUtils } from '../../../../../utils/CalendarUtils';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { EventParticipation, EventParticipationType } from '../../../../../utils/types/EventType';

const useFistPageController = ({ edit, eventId, calendarEventId, propsEvent, event, setEvent, image, setImage, uploadImage, setPlacesList, setPlaceImages, placeImages }: FistPageViewModel) => {

    const navigation = useNavigation<any>();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;
	const isFocused = useIsFocused();

    useEffect(()=>{
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        clearFields();
    }, [isFocused]);

    useEffect(() => {
        if(!propsEvent) {
            setPlaceType(getPlaceTypeValueFromEvent(event));
        } else {
            setPlaceType(getPlaceTypeValueFromEvent(propsEvent));

        }
    }, [propsEvent?.place?._id ,propsEvent?.placeFreeText ]);




    const attachmentPicker = new AttachmentPicker();
    const { showActionSheetWithOptions } = useActionSheet();

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    const [participationType, setParticipationType] = useState<KeyLabel>();
    const [openParticipationTypeComponent, setOpenParticipationTypeComponent] = useState(false);
    const [monthDataWeb, setMonthDataWeb] = useState<Array<ICalendarEventBase>>([]);
    const [monthDataMobile, setMonthDataMobile] = useState<CalendarEvents | undefined>(undefined);
    const [webAgendaDaySelected, setWebAgendaDaySelected] = useState(new Date().toString());
    const [markedDates, setMarkedDates] = useState(undefined);
    const [agendaKey, setAgendaKey] = useState<number>();

    const [openConventionedPlace, setOpenConventionedPlace] = useState(false);
    const [placeType, setPlaceType] = useState<PlaceType>();
    const [participationTypeModify, setParticipationTypeModify] = useState(true);
    const [modifyDate, setModifyDate] = useState(true);

    useEffect (()=>{
        setEvent({ ...event, placeId : ""});

    },[placeType])
    const selectParticipationType = (type: any) => {
        if (type) {
            setParticipationType({ key: type.type, label: type.label });
            setEvent({ ...event, participationType: type.type });
        }
        setOpenParticipationTypeComponent(false);
    };

    const selectPlaceType = (placeType: PlaceType) => {
        setPlaceImages({img1:"",img2:"",img3:"",img4:""})
        setPlaceTypeValue(event, placeType);
        setPlaceType(placeType);
        setEvent(event);
    }

    const openActionSheet = () => {
        const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
        const destructiveButtonIndex = undefined;
        const cancelButtonIndex = 2;
        return new Promise((resolve, reject) => {
            showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
                switch (buttonIndex) {
                    case 0: {
                        attachmentPicker._pickFromGallery("photo", resolve, reject);
                        break;
                    }
                    case 1: {
                        attachmentPicker._takePhoto(resolve, reject);
                        break;
                    }
                    case 2: {
                        break;
                    }
                }
            });
        });
    };

    const addImage = async () => {
        const imageInfo = Platform.OS === "web" ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
        if (imageInfo) {
            console.log("image info  " + JSON.stringify(imageInfo));
            if (Platform.OS === "web" && !(imageInfo as ImageInfo).uri.includes("image")) {
                setErrorToastVisible({ isVisible: true, text: strings.errorPhotoUploaded });
            } else {
                if (attachmentPicker.isSmallSize(Platform.OS === "web" ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
                    if (Platform.OS === "web") {
                        setImage({ img: (imageInfo as ImageInfo).uri, modified: true });
                    } else {
                        setImage({
                            img: Platform.OS === "android" && (imageInfo as ImageInfo[])[0].uri ? (imageInfo as ImageInfo[])[0].uri : (imageInfo as ImageInfo[])[0].fileURI,
                            modified: true,
                        });
                    }
                } else {
                    Platform.OS === "web"
                        ? setErrorToastVisible({ isVisible: true, text: strings.errorPhotoTooBig })
                        : setBasicAlertValues({
                            isVisible: true,
                            title: strings.error,
                            description: strings.errorPhotoTooBig,
                            type: AlertTypes.error,
                            buttonText: strings.ok,
                        });
                }
            }
        }
    };

    const removeImage = async () => {
        setImage({ img: "", modified: true });
        if (event.img != "") {
            await EventsEndPoint.deletePicture(eventId, event.img).then(() => {
                setImage({ img: "", modified: false });
                setEvent({ ...event, img: "" });
            });
        }
    };

    let month = !!event.startDate ? (dayjs(event.startDate).month() + 1) : (dayjs().month() + 1);
    let year = !!event.startDate ? dayjs(event.startDate).year() : dayjs().year();

    const getEvents = async () => {
        if (!!event?.place?._id) {
            // get places events
            setLoaderVisible(true);
            EventsEndPoint.getEventsByDate(event.place._id, month, year)
                .then((res: PlacesAvailabilityResponse[]) => {
                    let parsed: React.SetStateAction<ICalendarEventBase[]> = [];
                    if (!!propsEvent) {
                        parsed = CalendarUtils.parseEventsWeb(res).filter((ev) => ev.id !== propsEvent.calendarEventId);
                    } else {
                        parsed = CalendarUtils.parseEventsWeb(res).filter((ev) => ev.id !== calendarEventId);

                    }
                    setMonthDataWeb((arr: any[]) => arr.concat(parsed));
                    if (Platform.OS !== "web") {
                        setMonthDataMobile(CalendarUtils.parseEventsMobile(res));
                        setAgendaKey(!!agendaKey ? agendaKey + 1 : 1);
                    }
                    setMarkedDates(CalendarUtils.parseMarkedDate(res));
                    if (Platform.OS === "ios") {
                        setTimeout(() => {
                            setLoaderVisible(false);
                        }, 500);
                    } else {
                        setLoaderVisible(false);
                    }
                })
                .catch((e: any) => {
                    console.log("Error " + e);
                    if (Platform.OS === "ios") {
                        setTimeout(() => {
                            setLoaderVisible(false);
                            setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                        }, 500);
                    } else {
                        setLoaderVisible(false);
                        setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                    }
                });
        } else {
            setMonthDataWeb([]);
        }
    };

    const getPlaceList = async () => {
        if (!!event.startDate && !!event.endDate && (event.placeId === "" || event.placeId === undefined)) {
            // get places
            if (!!event.startDate && !!event.endDate && dayjs(event.startDate).isBefore(dayjs(event.endDate))) {
                setLoaderVisible(true);
                EventsEndPoint.getPlacesByDate(dayjs(event.startDate).toISOString(), dayjs(event.endDate).toISOString(), Array(event.field))
                    .then((res: Place[]) => {
                        setPlacesList(res);
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                        }
                    })
                    .catch((e: any) => {
                        console.log("Error " + e);
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                                setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                            setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                        }
                    });
            }
        }
    };

    const clearFields = () => {
        setParticipationType({ key: EventParticipation[0].type, label: EventParticipation[0].label })
        setMonthDataWeb([]);
        setWebAgendaDaySelected(new Date().toString());
        setParticipationTypeModify(true);
        setModifyDate(true);
        !edit ? setPlaceType(undefined) : null;
        setOpenConventionedPlace(false);
    };



    useEffect(() => {
        setMonthDataWeb([]);
        getEvents();
        if (event.startDate) {
            setWebAgendaDaySelected(new Date(event.startDate).toString());
            if (new Date(event.startDate).getMonth() + 1 === 12) {
                month = 1;
                year = Number(year) + 1;
                getEvents();

            }

        }
  
    }, [event.place]);


    const isDateEventModify = () => {
        if (event.id) {
            EventsEndPoint.getEvent(event.id).then((res) => {
                const activities: EventActivity[] = res.item.activities;
                if (activities.length > 0) {
                    const isModify: boolean = activities.every((element: EventActivity) => {
                        return (dayjs(element.startDate).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", "[]")
                            && dayjs(element.endDate).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", []))
                    });
                    if (!isModify) {
                        setModifyDate(false)

                    } else {
                        setModifyDate(true)

                    }

                }

            }).catch((e: any) => {
                console.log("Error " + e);

            });
        }
    }

    const isParticipationEventModify = () => {
        if (event.id) {
            EventsEndPoint.getEvent(event.id).then((res) => {
                const activities: EventActivity[] = res.item.activities;
                if (activities.length > 0 && propsEvent) {
                    const isModify: boolean = activities.every((activity: EventActivity) => {
                        activity.place?._id !== ""
                    });

                    if (propsEvent.participationType === EventParticipationType.online &&
                        event.participationType === EventParticipationType.inPerson && !isModify) {
                        setParticipationTypeModify(false)
                    } else if (propsEvent.participationType === EventParticipationType.inPerson
                        && event.participationType === EventParticipationType.online && !isModify) {
                        setParticipationTypeModify(false)

                    } else {
                        setParticipationTypeModify(true)

                    }
                }

            }).catch((e: any) => {
                console.log("Error " + e);

            });
        }
    }

    useEffect(() => {

        !!event?.place?._id ? null : getPlaceList();
        !!event.startDate ? setWebAgendaDaySelected(event.startDate) : null;
    }, [event.endDate]);

    useEffect(() => {
        edit ? isDateEventModify() : null;
    }, [event.startDate, event.endDate])


    useEffect(() => {
        edit ? isParticipationEventModify() : null;
        EventParticipation.forEach((item: any) => {
            item.type === event?.participationType ? setParticipationType({ key: item.type, label: item.label }) : "";
        });
    }, [event.participationType])


    return {
        navigation, isWeb,
        participationType, setParticipationType, openParticipationTypeComponent, setOpenParticipationTypeComponent, selectParticipationType,
        image, setImage, addImage, removeImage, uploadImage,
        monthDataWeb, setMonthDataWeb,
        monthDataMobile, setMonthDataMobile,
        webAgendaDaySelected, setWebAgendaDaySelected,
        markedDates, setMarkedDates,
        agendaKey, setAgendaKey,
        placeImages, setPlaceImages,
        participationTypeModify,
        openConventionedPlace, setOpenConventionedPlace,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
        selectPlaceType, placeType, setPlaceType,
        modifyDate,
    };
};

export default useFistPageController;

