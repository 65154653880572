export enum asyncStorageUtils {
	accessToken = "accessToken",
	refreshToken = "refreshToken",
	rememberMe = "rememberMe",
	userId = "userId",
	role = "role",
	name = "name",
	inviteCount = "inviteCount",
	notificationsActive = "notificationsActive",
}
