import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import Button from "../Button";

interface props {
	title?: string;
	description?: string;
	alertVisible: boolean;
	onClose: () => void;
	children?: any;
	closeButton?: boolean;
	actions?: {
		label: string;
		filled?: boolean;
		onPress: () => void;
	}[];
	disabled?: boolean;
}

const ActionAlert = ({ title, description, alertVisible, onClose, children, actions = [], closeButton = true, disabled }: props) => {
	return (
		<Modal animationType="fade" transparent={true} visible={alertVisible} onRequestClose={onClose}>
			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={onClose} />
				<View style={styles.content}>
					{closeButton && (
						<Pressable style={styles.closeBtn} onPress={onClose}>
							<Text style={styles.closeBtnText}>{strings.close}</Text>
							<FontAwesomeIcon size={15} color={colors.white} icon={faClose}></FontAwesomeIcon>
						</Pressable>
					)}

					{title && <Text style={styles.title}>{title}</Text>}
					{description && <Text style={styles.text}>{description}</Text>}
					{!!children && children}
					{actions.length > 0 && (
						<View style={styles.actionsCnt}>
							{actions.map(({ label, onPress, filled = true }) => (
								<Button
									style={{ marginHorizontal: 5 }}
									key={label}
									handleButtonPress={onPress}
									disabled={disabled ? disabled : false}
									text={label}
									filledButton={filled}
								/>
							))}
						</View>
					)}
				</View>
			</View>
		</Modal>
	);
};

const modalWidth = {
	width: "80%",
	maxWidth: 500,
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 30,
		paddingBottom: 50,
		...modalWidth,
	},
	overlay: {
		backgroundColor: colors.grey4,
		opacity: 0.8,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: 20,
		marginTop: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	actionsCnt: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: "5%",
		zIndex: 0,
	},
	closeBtnText: {
		color: colors.white,
		fontFamily: fonts.RalewaySemiBold,
		fontSize: 16,
		marginRight: 8,
	},
	closeBtn: {
		flexDirection: "row",
		position: "absolute",
		top: -24,
		right: 0,
		alignItems: "center",
	},
});

export default ActionAlert;
