// import MapView from 'react-native-map-clustering';
import { useEffect } from "react";
import MapView, { Circle, Marker } from "react-native-maps";
import { Markers } from "../../models/models";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/Places.style";
import { Utils } from "../../utils/Utils";

interface PropsInterface {
	navigation?: any;
	latitude: number;
	longitude: number;
	latitudeDelta: number;
	longitudeDelta: number;
	markers: Markers[];
	placeDetails: boolean;
	getPlaceDetails: (markerId: string, markerUserId?: string) => any;
	selectedMarker?: Markers | null;
	placesLoading: boolean;
}

const Map = (props: PropsInterface) => {
	useEffect(() => {
		console.log(props);
	}, [props]);


	let map: google.maps.Map;
	function initMap(): void {
		map = new google.maps.Map(document.getElementById("map") as HTMLElement, {});
	}

	return (
		<>
			{!props.placesLoading ? (
				<MapView
					mapType="standard"
					style={Utils.isWeb ? styles.mapWeb : styles.mapMobile}
					region={{ latitude: props.latitude, longitude: props.longitude, latitudeDelta: props.latitudeDelta, longitudeDelta: props.longitudeDelta }}
					options={{ styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off" }] }] }}
				>
					<>
						{props.markers.map((marker, index) => (
							<MapView.Marker
								key={`${marker.id}-${new Date().getMilliseconds()}`}
								title={marker.title}
								description={marker.description}
								coordinate={marker.coordinates}
								onPress={() => (!marker.dummy ? props.getPlaceDetails(marker.id, marker.userId) : null)}
							></MapView.Marker>
						))}
						{props.selectedMarker ? (
							<MapView.Circle center={props.selectedMarker?.coordinates!} radius={100} fillColor={colors.blue} strokeColor={colors.blue}></MapView.Circle>
						) : (
							<></>
						)}
					</>
				</MapView>
			) : (
				<></>
			)}
		</>
	);
};

export default Map;
