import { View, Platform, Text } from "react-native";
import { styles } from "../styles/CalendarScreen.style";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import Loader from "../components/Loader";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Footer from "../components/Footer";
import { PageNames } from "../navigation/NavigationUtils";
import CalendarComponent from "../components/Calendar/CalendarComponent";
import { useIsFocused } from "@react-navigation/native";
import { CalendarEvents, PlacesAvailabilityResponse, Event } from "../models/eventsModels";
import { EventsEndPoint } from "../services/EventsEndPoint";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { strings } from "../locales/strings";
import { CalendarUtils } from "../utils/CalendarUtils";
import { UserCalendarService } from "../services/UserCalendarService";
import PageNavigation from "../components/PageNavigation";
import { roles } from "../utils/Roles";
import Button from "../components/Button";
import DisponibilityAlert from "../components/Calendar/DisponibilityAlert";
import InsertIndisponibilityAlert from "../components/Calendar/InsertIndisponibilityAlert";
import { ICalendarEventBase, getDatesInWeek } from "react-native-big-calendar";


interface PropsInterface {
	navigation: any;
	route: {
		params: {
			// my calendar
			isMyCalendar: boolean;

			// place calendar
			placeId?: string;
			placeName?: string;

			// user calendar
			userId?: string;
			userName?: string;
			roles?: roles[];
		}
	}
}
interface containerOfmonthsAndYears {
	month: number
	year: number
}

const CalendarScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const isFocused = useIsFocused();
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const params = props.route.params;
	const [name, setName] = useState<string>('');

	const [monthDataWeb, setMonthDataWeb] = useState<ICalendarEventBase[]>([]);
	const [monthDataMobile, setMonthDataMobile] = useState<CalendarEvents | undefined>(undefined);
	// const [webAgendaDaySelected, setWebAgendaDaySelected] = useState((new Date()).toString());
	const [markedDates, setMarkedDates] = useState(undefined);
	const [agendaKey, setAgendaKey] = useState<number>();
	const [agendaDay, setAgendaDay] = useState<Date>(new Date());
	const [eventQueryParams, setEventQueryParams] = useState(null);

	const [disponibilityAlert, setDisponibilityAlert] = useState<boolean>(false);
	const [insertIndisponibilityAlert, setInsertIndisponibilityAlert] = useState<boolean>(false);

	const [userRole, setUserRole] = useState<string>(roles.user);
	const [containerOfmonthsAndYears, setcontainerOfmonthsAndYears] = useState<containerOfmonthsAndYears[]>([]);
	const [dateRangeOfWeek, setDateRangeOfWeek] = useState(getDatesInWeek(agendaDay, 1, 'it'));

	React.useEffect(() => {
		cleanUp();
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		const getName = async () => {
			const name = await AsyncStorage.getItem(asyncStorageUtils.name);
			!!name ? setName(name) : '';
		}
		const getRole = async () => {
			const role = await AsyncStorage.getItem(asyncStorageUtils.role);
			!!role ? setUserRole(role) : '';
		}
		getRole();
		if (params?.isMyCalendar) { // my calendar
			console.log("my calendar");
			getName();
		} else if (params?.placeId && params?.placeName) { // place calendar
			console.log("place calendar");
			!!params.placeName ? setName(params.placeName) : null;
		} else if (params?.userId && params?.roles && params?.userName) { // user calendar
			console.log("user calendar");
			setName(params?.userName);
		} else {
			console.log("params " + JSON.stringify(params))
		}

		if (!eventQueryParams)
			setEventQueryParams({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
	}, [isFocused]);


	useEffect(() => {
		if (!eventQueryParams) {
			return;
		}
		if (params?.isMyCalendar || params?.userId) {
			getUserEvents();
		} else {
			getPlaceEvents();
		}
	}, [eventQueryParams]);

	useEffect(() => {
		getEvents()
	}, [dateRangeOfWeek]);

	const getEvents = async () => {
		const firstAndLastDateOfWeek = [dateRangeOfWeek[0], dateRangeOfWeek[6]];
		const filterFirstAndLastDateOfWeek = firstAndLastDateOfWeek.filter((day) => {

			const isQueryParams = !containerOfmonthsAndYears.some((dateMonthAndYear) => {
				return dateMonthAndYear.month === day.month() + 1 && dateMonthAndYear.year === day.year();
			})
			return isQueryParams;

		})
		if (firstAndLastDateOfWeek[0].month() + 1 !== firstAndLastDateOfWeek[1].month() + 1) {

			await filterFirstAndLastDateOfWeek.reduce(async (acc, filterDate) => {
				await acc;
				setEventQueryParams({ month: filterDate.month() + 1, year: filterDate.year() });
			}, Promise.resolve());

		} else {
			if (!!filterFirstAndLastDateOfWeek.length) {
				setEventQueryParams({ month: firstAndLastDateOfWeek[1].month() + 1, year: firstAndLastDateOfWeek[1].year() });
			}
		}
	}
	const getUserEvents = async () => {
		setLoaderVisible(true);
		let userId: string = '';
		if (params?.isMyCalendar) {
			userId = await AsyncStorage.getItem(asyncStorageUtils.userId) ?? '';
		} else {
			userId = params.userId ?? '';
		}

		const { year, month } = eventQueryParams;

		UserCalendarService.getCalendarEvents(userId!, month, year).then((response: any) => {
			let parsed = CalendarUtils.parseEventsWeb(response)
			const monthAndYear: containerOfmonthsAndYears[] = [].concat({
				month: month,
				year: year
			});

			setcontainerOfmonthsAndYears((arr: containerOfmonthsAndYears[]) => arr.concat(monthAndYear));
			setMonthDataWeb((arr: any[]) => arr.concat(parsed));
			setMarkedDates(CalendarUtils.parseMarkedDate(response));
			if (Platform.OS !== "web") {
				setMonthDataMobile(CalendarUtils.parseEventsMobile(response));
				setAgendaKey(!!agendaKey ? agendaKey + 1 : 1);
			}

			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}).catch((e: any) => {
			console.log("Error " + e);
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
					setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
				}, 500);
			} else {
				setLoaderVisible(false);
				setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
			}
		})
	}


	const getPlaceEvents = async () => {
		if (params.placeId) {
			setLoaderVisible(true);

			const { year, month } = eventQueryParams;

			EventsEndPoint.getEventsByDate(params.placeId, month, year).then((res: PlacesAvailabilityResponse[]) => {
				const parsed = CalendarUtils.parseEventsWeb(res);
				const monthAndYear: containerOfmonthsAndYears[] = [].concat({
					month: month,
					year: year
				});


				setcontainerOfmonthsAndYears((arr: containerOfmonthsAndYears[]) => arr.concat(monthAndYear));
				setMonthDataWeb((arr: any[]) => arr.concat(parsed));

				if (Platform.OS !== "web") {
					setMonthDataMobile(CalendarUtils.parseEventsMobile(res));
					setAgendaKey(!!agendaKey ? agendaKey + 1 : 1);
				}
				setMarkedDates(CalendarUtils.parseMarkedDate(res));
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			}).catch((e: any) => {
				console.log("Error " + e);
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
						setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
					}, 500);
				} else {
					setLoaderVisible(false);
					setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
				}
			})
		}
	}

	const cleanUp = () => {
		setName('');
		setEventQueryParams(null);
		setAgendaDay(new Date());
		setcontainerOfmonthsAndYears([]);
		setMonthDataWeb([]);
	}

	return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				onPress={() => props.navigation.navigate(PageNames.home)}
				showMenu={true}
				showLoginButton={false}
			/>
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<DisponibilityAlert
				alertVisible={disponibilityAlert}
				setAlertVisible={(isVisible: boolean) => setDisponibilityAlert(isVisible)}
				userId={params.userId ?? ''}
			/>

			<InsertIndisponibilityAlert
				alertVisible={insertIndisponibilityAlert}
				setAlertVisible={(isVisible: boolean) => setInsertIndisponibilityAlert(isVisible)}
				userId={params.userId ?? ''}
			/>

			<ScrollView>
				{isWeb ? <PageNavigation /> : null}
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: "5%", marginVertical: 20 }}>
					<View>
						<Text style={styles.title}>{strings.calendarOf + name}</Text>
					</View>

					{userRole === roles.serviceCoordinator && params?.roles && (params?.roles.includes(roles.externalUser) || params?.roles.includes(roles.internalUser)) &&
						<View style={{ flexDirection: 'row' }}>
							<>
								<Button
									handleButtonPress={() => setInsertIndisponibilityAlert(true)}
									text={strings.insertIndisponibility}
									filledButton={true}
									style={{ marginRight: 5 }}
								/>

								<Button
									handleButtonPress={() => setDisponibilityAlert(true)}
									text={strings.disponibility}
									filledButton={true}
									style={{ marginLeft: 5 }}
								/>
							</>
						</View>
					}
				</View>
				<View style={styles.container}>
					<View style={[isWeb ? { flexDirection: 'row', justifyContent: 'space-between' } : null, { width: '100%' }]}>
						{isWeb &&
							<View style={{ width: "20%" }}>
								<CalendarComponent
									minDate={undefined}
									maxDate={undefined}
									date={undefined}
									readonly={false}
									onDayPress={(day: any) => {
										setAgendaDay(day);
										setDateRangeOfWeek(getDatesInWeek(day, 1, 'it'));

									}}

									showTimePicker={false}
									position={"relative"}
									showAgenda={false}
									monthDataWeb={monthDataWeb}
									monthDataMobile={monthDataMobile}
									webAgendaDaySelected={undefined}
									markedDates={markedDates}

								/>
							</View>
						}

						<View style={{ width: isWeb ? "70%" : "100%" }}>
							<CalendarComponent
								containerStyle={{ marginTop: Platform.OS === 'web' ? 0 : 50, borderRadius: 0 }}
								position={'relative'}
								minDate={undefined}
								maxDate={undefined}
								date={undefined}
								readonly={false}
								onDayPress={(date: string) => {

								}}
								onWeekOnChange={(weeks) => {
									setDateRangeOfWeek(weeks);
								}}

								showTimePicker={false}
								showAgenda={true}
								monthDataWeb={monthDataWeb}
								monthDataMobile={monthDataMobile}
								webAgendaDaySelected={agendaDay}
								markedDates={markedDates}
								key={agendaKey}
							/>
						</View>
					</View>
				</View>
		
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default CalendarScreen;
