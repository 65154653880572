import { Platform, View, Text, ScrollView, Switch, Pressable } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { useEffect, useState } from "react";
import { strings } from "../../locales/strings";
import BasicAlert from "../Alerts/BasicAlert";
import AddImage from "../AddImage";
import Button from "../Button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../../utils/AsyncStorageUtils";
import { PlacesEndPoint } from "../../services/PlacesEndPoint";
import { Place, PlaceImageDocRes, PlaceManagerMultivalue } from "../../models/models";
import Counter from "../Counter";
import CustomTextInput from "../CustomTextInput";
import { AlertTypes } from "../../utils/AlertTypes";
import ExitFromModify from "./ExitFromModify";
import DeletePlace from "./DeletePlace";
import SearchBarWithAutocomplete from "../SearchBarWithAutocomplete";
import Loader from "../Loader";
import DropDownMenu from "../DropdownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DocumentPicker from "react-native-document-picker";
import { UsersEndpoints } from "../../services/UsersEndpoints";
import { roles } from "../../utils/Roles";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Permissions from "expo-permissions";
import { SaveFile } from "../../services/SaveFile";
import { string } from "yup";
import BasicToast from "../Toasts/BasicToast";
import WorkingHours from "../WorkingHours";
import { isWorkingHoursDaysEmpty, mapWorkingDayToWorkingHoursDay, mapWorkingHoursDayToWorkingDay, WorkingDay } from "../../utils/WorkingHours";
import { MacroAreaType } from "../../utils/types/MacroAreaType";
import TypologyComponent from "../Events/TypologyComponent";
import Select from "../Select";

interface props {
	update: boolean;
	helper: number;
	placeId: string;
	setPlaceManagerVisible: (isVisible: boolean) => any;
	delete: () => any;
	ableToDelete: boolean;
	role: roles | undefined;
}

const PlaceManager = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		console.log("role ee" + props.role);
		props.update ? getPlaceDetails() : {};
		getPlaceManagers();
	}, [props.update, props.placeId, props.helper]);

	const [placeName, setPlaceName] = useState("");
	const [address, setAddress] = useState("");
	const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
	const [numberOfSeats, setNumberOfSeats] = useState(0);
	const [kitchen, setKitchen] = useState(false);
	const [kitchenNote, setKitchenNote] = useState("");
	const [isPrivatePlace, setIsPrivatePlace] = useState(true);
	const [activityDetails, setactivityDetails] = useState("");
	const [parkingSpots, setParkingSpots] = useState(0);
	const [architecturalBarriers, setArchitecturalBarriers] = useState("");
	const [maxNumberOfUsers, setMaxNumberOfUsers] = useState<number | "">("");
	const toggleSwitchKitchen = () => setKitchen((previousState) => !previousState);
	const toggleSwitchPrivatePlace = () => setIsPrivatePlace((previousState) => !previousState);

	const [openingInfo, setOpeningInfo] = useState("");
	const [note, setNote] = useState("");

	const [image1, setImage1] = useState({ img: "", modified: false });
	const [image2, setImage2] = useState({ img: "", modified: false });
	const [image3, setImage3] = useState({ img: "", modified: false });
	const [image4, setImage4] = useState({ img: "", modified: false });

	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const [doc, setDoc] = useState("");
	const [docModified, setDocModified] = useState(false);
	const [docName, setDocName] = useState("");

	const [isFirstPage, setIsFirstPage] = useState(true);

	const [workingDays, setWorkingDays] = useState<WorkingDay[]>([]);

	const uploadImages = async (): Promise<string[]> => {
		let imgLinks: string[] = [];
		try {
			if (image1.img != "") {
				image1.modified
					? await PlacesEndPoint.addPicture({ fileURI: image1.img }).then((res: PlaceImageDocRes) => {
						imgLinks.push(res.item.link);
					})
					: imgLinks.push(image1.img);
			}

			if (image2.img != "") {
				image2.modified
					? await PlacesEndPoint.addPicture({ fileURI: image2.img }).then((res: PlaceImageDocRes) => {
						imgLinks.push(res.item.link);
					})
					: imgLinks.push(image2.img);
			}

			if (image3.img != "") {
				image3.modified
					? await PlacesEndPoint.addPicture({ fileURI: image3.img }).then((res: PlaceImageDocRes) => {
						imgLinks.push(res.item.link);
					})
					: imgLinks.push(image3.img);
			}

			if (image4.img != "") {
				image4.modified
					? await PlacesEndPoint.addPicture({ fileURI: image4.img }).then((res: PlaceImageDocRes) => {
						imgLinks.push(res.item.link);
					})
					: imgLinks.push(image4.img);
			}
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		}
		return imgLinks;
	};

	const uploadDocument = async (): Promise<string> => {
		let documentLink: string = "";
		if (doc != "") {
			if (docModified) {
				await PlacesEndPoint.addDocument({ fileURI: doc, fileName: docName })
					.then((res: PlaceImageDocRes) => {
						documentLink = res.item.link;
					})
					.catch((e: any) => {
						setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
					});
			} else {
				documentLink = doc;
			}
		}
		return documentLink;
	};

	const registerPlace = async () => {
		if (!coordinates.lat && !coordinates.lng) {
			setBasicAlertValues({
				isVisible: true,
				title: strings.errorTitle,
				description: strings.nocoordinates,
				type: AlertTypes.error,
				buttonText: strings.close,
			});
			return;
		}

		setLoaderVisible(true);

		const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
		const imgLinks = await uploadImages();
		const docLink = await uploadDocument();
		if (userId) {
			const place: Place = {
				field: scope,
				address: address,
				coordinates: [coordinates.lng, coordinates.lat],
				serviceCoordinatorId: userId,
				placeManager: {
					id: placeManager.value,
					name: placeManager.label,
					workspaceEmail: placeManager.workspaceEmail,
				},
				name: placeName,
				numberOfSeats: numberOfSeats,
				kitchen: kitchen,
				kitchenNotes: kitchenNote,
				isPrivatePlace: isPrivatePlace,
				activityDetails: activityDetails,
				parkingSpots: parkingSpots,
				barrierArch: architecturalBarriers,
				maxNumberOfUsers: maxNumberOfUsers,
				opening: openingInfo,
				notes: note,
				docLink: docLink,
				imgLinks: imgLinks,
				deleted: false,
			};
			await PlacesEndPoint.registerPlace(place)
				.then((res) => {
					console.log(res);
					setLoaderVisible(false);
					if (Platform.OS === "ios") {
						setTimeout(() => {
							setLoaderVisible(false);
							setBasicAlertValues({
								isVisible: true,
								title: strings.placeAdded,
								description: strings.placedAddedDescription,
								type: AlertTypes.success,
								buttonText: strings.close,
							});
						}, 1000);
					} else {
						setLoaderVisible(false);
						setBasicAlertValues({
							isVisible: true,
							title: strings.placeAdded,
							description: strings.placedAddedDescription,
							type: AlertTypes.success,
							buttonText: strings.close,
						});
					}
				})
				.catch((e) => {
					if (Platform.OS === "ios") {
						setTimeout(() => {
							setLoaderVisible(false);
							setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
						}, 1000);
					} else {
						setLoaderVisible(false);
						setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
					}
				});
		}
	};

	const [placeManagers, setPlaceManagers] = useState([{ label: "", value: "", workspaceEmail: "" }]);

	const [scope, setScope] = useState(MacroAreaType[1].type);


	const selectType = (value: any) => {
		clearFields();
		setScope(value);

	};
	const getPlaceManagers = async () => {
		let placeManagerList: PlaceManagerMultivalue[] = [];
		await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.placeManager], undefined, true)
			.then((res) => {
				res.page.items.forEach((value: any) => {
					const user: PlaceManagerMultivalue = {
						value: value.id,
						label: value.firstName + " " + value.lastName,
						workspaceEmail: value.workspaceEmail,
					};
					placeManagerList.push(user);
				});
				setPlaceManagers(placeManagerList);
			})
			.catch(() => { });
	};

	const modifyPlace = async (deleted: boolean) => {
		if (!coordinates.lat && !coordinates.lng) {
			setBasicAlertValues({
				isVisible: true,
				title: strings.errorTitle,
				description: strings.nocoordinates,
				type: AlertTypes.error,
				buttonText: strings.close,
			});
			return;
		}

		setLoaderVisible(true);
		const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
		const imgLinks = await uploadImages();
		const docLink = await uploadDocument();
		if (userId) {
			const place: Place = {
				field: scope,
				address: address,
				coordinates: [coordinates.lng, coordinates.lat],
				placeManager: {
					id: placeManager.value,
					name: placeManager.label,
					workspaceEmail: placeManager.workspaceEmail,
				},
				name: placeName,
				numberOfSeats: numberOfSeats,
				kitchen: kitchen,
				kitchenNotes: kitchenNote,
				isPrivatePlace: isPrivatePlace,
				activityDetails: activityDetails,
				parkingSpots: parkingSpots,
				barrierArch: architecturalBarriers,
				maxNumberOfUsers: maxNumberOfUsers,
				opening: openingInfo,
				notes: note,
				docLink: docLink,
				imgLinks: imgLinks,
				deleted: deleted,
			};
			await PlacesEndPoint.modifyPlace(place, props.placeId)
				.then(async (res) => {
					if (props.role !== roles.placeManager) {
						return Promise.resolve();
					}

					try {
						const workingHoursDay = mapWorkingDayToWorkingHoursDay(workingDays);
						if (isWorkingHoursDaysEmpty(workingHoursDay)) {
							// By default if there are no hours it must be always free
							await PlacesEndPoint.deleteWorkingHours(res.item.id);
						} else {
							await PlacesEndPoint.editWorkingHours(res.item.id, workingHoursDay);
						}
					} catch (e: any) {
						setLoaderVisible(false);
						if (Platform.OS === "ios") {
							setTimeout(() => {
								setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
							}, 1000);
						} else {
							setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
						}
					}
				})
				.then(() => {
					if (deleted) {
						if (Platform.OS === "ios") {
							setTimeout(() => {
								setLoaderVisible(false);
								setBasicAlertValues({
									isVisible: true,
									title: strings.placeDeleted,
									description: strings.placeDeletedDescription,
									type: AlertTypes.success,
									buttonText: strings.close,
								});
							}, 1000);
						} else {
							setLoaderVisible(false);
							setBasicAlertValues({
								isVisible: true,
								title: strings.placeDeleted,
								description: strings.placeDeletedDescription,
								type: AlertTypes.success,
								buttonText: strings.close,
							});
						}
					} else {
						if (Platform.OS === "ios") {
							setTimeout(() => {
								setLoaderVisible(false);
								setBasicAlertValues({
									isVisible: true,
									title: strings.placeModified,
									description: strings.placeModifiedDescription,
									type: AlertTypes.success,
									buttonText: strings.close,
								});
							}, 1000);
						} else {
							setLoaderVisible(false);
							setBasicAlertValues({
								isVisible: true,
								title: strings.placeModified,
								description: strings.placeModifiedDescription,
								type: AlertTypes.success,
								buttonText: strings.close,
							});
						}
					}
				})
				.catch((e) => {
					setLoaderVisible(false);
					if (Platform.OS === "ios") {
						setTimeout(() => {
							setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
						}, 1000);
					} else {
						setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
					}
				});
		}
	};

	const getPlaceDetails = async () => {
		setLoaderVisible(true);
		try {
			const [res, workingHours] = await Promise.all([
				PlacesEndPoint.getPlaceDetails(props.placeId),
				props.role === roles.placeManager ? PlacesEndPoint.getWorkingHours(props.placeId) : null,
			]);
			if (props.role === roles.placeManager && workingHours) {
				if (workingHours.item.days.length > 0) {
					setWorkingDays(mapWorkingHoursDayToWorkingDay(workingHours.item.days));
				}
			}
			setScope(res.item.field)
			setAddress(res.item.address);
			setPlaceName(res.item.name);
			setNumberOfSeats(res.item.numberOfSeats);
			setKitchen(res.item.kitchen);
			setIsPrivatePlace(res.item.isPrivatePlace);
			setParkingSpots(res.item.parkingSpots);
			setArchitecturalBarriers(res.item.barrierArch);
			setMaxNumberOfUsers(res.item.maxNumberOfUsers);
			setCoordinates({
				lat: res.item.coordinates && res.item.coordinates[1] ? res.item.coordinates[1] : 0,
				lng: res.item.coordinates && res.item.coordinates[0] ? res.item.coordinates[0] : 0,
			});
			res.item.imgLinks && res.item.imgLinks.length > 0 ? setImage1({ img: res.item.imgLinks[0], modified: false }) : null;
			res.item.imgLinks && res.item.imgLinks.length > 1 ? setImage2({ img: res.item.imgLinks[1], modified: false }) : null;
			res.item.imgLinks && res.item.imgLinks.length > 2 ? setImage3({ img: res.item.imgLinks[2], modified: false }) : null;
			res.item.imgLinks && res.item.imgLinks.length > 3 ? setImage4({ img: res.item.imgLinks[3], modified: false }) : null;
			res.item.docLink ? setDoc(res.item.docLink) : null;
			res.item.docLink ? setDocName(res.item.docLink.split("/")[res.item.docLink.split("/").length - 1].split(";")[0]) : null;
			setOpeningInfo(res.item.opening);
			setNote(res.item.notes);
			setKitchenNote(res.item.kitchenNotes);
			setactivityDetails(res.item.activityDetails)
			setPlaceManager({ value: res.item.placeManager.id, label: res.item.placeManager.name, workspaceEmail: res.item.placeManager.workspaceEmail });
			console.log(res)


		} catch (e: any) {
			console.log(e);
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}

	};

	const pickDocument = async () => {
		if (Platform.OS === "web") {
			const DocumentPicker = require("expo-document-picker");
			const doc: any = await DocumentPicker.getDocumentAsync({
				copyToCacheDirectory: true,
				multiple: false,
				type: ["pdf"],
			});
			setDoc(doc.uri);
			setDocName(doc.name);
			setDocModified(true);
			console.log("doc" + JSON.stringify(doc));
		} else {
			const result = await DocumentPicker.pickSingle({ type: [DocumentPicker.types.pdf] });
			const fileType = result.type;
			const fileName = result.name;
			const fileExtension = fileType ? fileType.substr(fileType.indexOf("/") + 1) : null;
			const fileURI = Platform.select({ android: result.uri, ios: decodeURI(result.uri) });
			setDoc(Platform.OS === "android" ? result.uri : decodeURI(result.uri));
			setDocName(fileName);
			setDocModified(true);
		}
	};

	const clearFields = () => {
		setScope("");
		setPlaceName("");
		setAddress("");
		setNumberOfSeats(0);
		setKitchen(false);
		setIsPrivatePlace(true)
		setactivityDetails(""),
		setParkingSpots(0);
		setArchitecturalBarriers("");
		setMaxNumberOfUsers("");
		setCoordinates({ lat: 0, lng: 0 });
		setImage1({ img: "", modified: false });
		setImage2({ img: "", modified: false });
		setImage3({ img: "", modified: false });
		setImage4({ img: "", modified: false });
		setDoc("");
		setDocModified(false);
		setDocName("");
		setIsFirstPage(true);
		setOpeningInfo("");
		setNote("");
		setPlaceManager({ label: "", value: "", workspaceEmail: "" });
		setWorkingDays([]);
	};

	const closeManager = () => {
		clearFields();
		props.setPlaceManagerVisible(false);
	};

	const downloadFile = async () => {
		if (Platform.OS === "web") {
			SaveFile.saveFile(doc, docName);
		} else {
			const uri = doc;
			let fileUri = FileSystem.documentDirectory + docName;
			FileSystem.downloadAsync(uri, fileUri)
				.then(({ uri }) => saveFile(uri))
				.catch((error) => console.error(error));
		}
	};

	const saveFile = async (fileUri: string) => {
		const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
		if (status === "granted") {
			const asset = await MediaLibrary.createAssetAsync(fileUri);
			await MediaLibrary.createAlbumAsync("Download", asset, false);
		}
	};

	const goBackMobile = () => {
		props.update ? setExitFromModify(true) : closeManager();
	};

	const [exitFromModify, setExitFromModify] = useState(false);
	const [deletePlace, setDeletePlace] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);

	const [placeManager, setPlaceManager] = useState({ label: "", value: "", workspaceEmail: "" });

	const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
	const showErrorImage = (text: string) => {
		Platform.OS === "web"
			? setErrorToastVisible({ isVisible: true, text: text })
			: setBasicAlertValues({ isVisible: true, title: strings.error, description: strings.errorPhotoTooBig, type: AlertTypes.error, buttonText: strings.ok });
	};
	const renderPlaceManager = () => {
		return (
			<>
				{isFirstPage ? (
					<View style={[Platform.OS === "web" ? { width: '100%', display: 'flex', alignItems: 'center', marginTop: 0 } : { marginLeft: '5%' }]}>
						<Text style={[styles.selectTitle]}>{strings.scopePlaces + " *"}</Text>
						<View style={[Platform.OS === "web" ? { width: '26%', alignItems: 'center' } : { width: '100%' }]}>
							<Select
								options={
									MacroAreaType.map(
										(scope) => ({
											value: scope.type,
											label: scope.label,
										})
									) || []
								}
								placeholder={scope}
								value={scope}
								onChange={
									selectType
								}
							/>
						</View>
					</View>
				) : null}

				<>
					<View style={isWeb ? styles.rowView : {}}>
						<View style={isWeb ? { width: "50%" } : {}}>
							{(!isWeb && isFirstPage) || isWeb ? (
								<>


									{/* Place Name */}
									<CustomTextInput
										title={strings.placeName + " *"}
										value={placeName}
										placeholder={""}
										obligatory={false}
										setValue={(value: string) => setPlaceName(value)}
										disabled={props.update && !!props.role && props.role === roles.placeManager}
									/>

									{/* address */}
									<Text style={styles.fieldTitle}>{strings.address + " *"}</Text>
									<SearchBarWithAutocomplete
										style={styles.addressBox}
										address={address}
										placeholder=""
										setAddress={(value: any) => setAddress(value)}
										setCoordinates={(lat: any, lng: any) => setCoordinates({ lat: lat, lng: lng })}
										disabled={props.update && !!props.role && props.role === roles.placeManager}
									/>
									{scope === 'food' ? (
										<>
											{/* Kitchen */}

											<Text style={[styles.fieldTitle, Platform.OS === "android" ? { marginBottom: 40 } : null]}>{strings.kitchen}</Text>
											<Switch
												trackColor={{ false: colors.primary, true: colors.blue3 }}
												thumbColor={kitchen ? colors.blue : colors.primary}
												ios_backgroundColor={colors.primary}
												onValueChange={toggleSwitchKitchen}
												value={kitchen}
												style={styles.switch}
												disabled={props.update && !!props.role && props.role === roles.placeManager}
											/>
											{kitchen ? (
												<CustomTextInput
													numberOfLine={4}
													value={kitchenNote}
													placeholder={strings.kitchenNote}
													title={""}
													styleTextInput={{ marginLeft: "5%", marginRight: "5%", marginTop: Platform.OS === "web" ? 0 : -30 }}
													obligatory={false}
													maxLenght={500}
													setValue={(value: string) => setKitchenNote(value)}
												/>
											) : null}
										</>
									) : <>
										{/* Private Place */}

										<Text style={[styles.fieldTitle, Platform.OS === "android" ? { marginBottom: 40 } : null]}>{strings.privatePlace + " *"}</Text>
										<Switch
											trackColor={{ false: colors.primary, true: colors.blue3 }}
											thumbColor={isPrivatePlace ? colors.blue : colors.primary}
											ios_backgroundColor={colors.primary}
											onValueChange={toggleSwitchPrivatePlace}
											value={isPrivatePlace}
											style={styles.switch}
											disabled={props.update && !!props.role && props.role === roles.placeManager}
										/>

									</>}


									{/* Number Of Seats */}
									<Text style={styles.fieldTitle}>{strings.numberOfSeats}</Text>
									<Counter
										isReadOnly={props.update && !!props.role && props.role === roles.placeManager}
										value={numberOfSeats}
										setValue={(value: number) => setNumberOfSeats(value)}
									/>

									{/* Parking Spots */}
									<Text style={styles.fieldTitle}>{strings.parkingSpots}</Text>
									<Counter
										isReadOnly={props.update && !!props.role && props.role === roles.placeManager}
										value={parkingSpots}
										setValue={(value: number) => setParkingSpots(value)}
									/>

									{/* Max Number Of Users */}
									<Text style={styles.fieldTitle}>{strings.maxNumberOfUsers}</Text>
									<Counter
										isReadOnly={props.update && !!props.role && props.role === roles.placeManager || !isPrivatePlace}
										value={maxNumberOfUsers}
										setValue={(value: number) => setMaxNumberOfUsers(value)}

									/>
									{/* Activity Detail*/}
									{scope === 'wellness' ? (
										<CustomTextInput
											numberOfLine={4}
											value={activityDetails}
											placeholder={""}
											title={strings.activityDetailNote}

											obligatory={false}
											maxLenght={500}
											setValue={(value: string) => setactivityDetails(value)}
										/>
									) : null}

									{/* Architectural Barriers */}
									<CustomTextInput
										numberOfLine={4}
										maxLenght={500}
										value={architecturalBarriers}
										placeholder={""}
										title={strings.architecturalBarriers}
										obligatory={false}
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										setValue={(value: string) => setArchitecturalBarriers(value)}
									/>

									{/* Opening Info */}
									<CustomTextInput
										numberOfLine={4}
										value={openingInfo}
										maxLenght={500}
										placeholder={""}
										title={strings.openingInfo}
										obligatory={false}
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										setValue={(value: string) => setOpeningInfo(value)}
									/>

									{/* Opening Hours */}
									{props.role === roles.placeManager ? (
										<>
											<Text style={styles.fieldTitle}>{strings.workingHoursPlace}</Text>
											<WorkingHours
												workingDays={workingDays}
												onWorkingDaysChange={(workingDays) => setWorkingDays(workingDays)}
												isWeb={isWeb}
												style={{ marginLeft: "5%", marginRight: "5%" }}
											/>
										</>
									) : null}
								</>
							) : null}
						</View>

						<View style={isWeb ? { width: "50%", justifyContent: "flex-start" } : {}}>
							{(!isWeb && !isFirstPage) || isWeb ? (
								<>
									<View style={{ zIndex: 9999 }}>
										<Text style={[styles.fieldTitle, { marginBottom: 0 }]}>{strings.choosePlaceManager + " *"}</Text>
										{props.update && !!props.role && props.role === roles.placeManager ? ( // READONLY
											<>
												<Text style={styles.readOnlyText}>{placeManager.label}</Text>
											</>
										) : (
											<DropDownMenu
												setSelected={(value: any) => {
													if (value != undefined) {
														console.log("value " + JSON.stringify(value));
														placeManagers.forEach((manager: any) => {
															if (value.label == manager.label && value.value == manager.value) {
																setPlaceManager({
																	label: manager.label,
																	value: manager.value,
																	workspaceEmail: manager.workspaceEmail,
																});
															}
														});
													} else {
														setPlaceManager({ label: "", value: "", workspaceEmail: "" });
													}
												}}
												selected={placeManager}
												list={placeManagers}
											/>
										)}
									</View>

									{/* Images */}
									<Text style={styles.fieldTitle}>{strings.manageImages}</Text>
									<AddImage
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										image={image1.img}
										setImage={(value: string) => setImage1({ img: value, modified: true })}
										showError={(text: string) => showErrorImage(text)}
									/>
									<AddImage
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										image={image2.img}
										setImage={(value: string) => setImage2({ img: value, modified: true })}
										showError={(text: string) => showErrorImage(text)}
									/>
									<AddImage
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										image={image3.img}
										setImage={(value: string) => setImage3({ img: value, modified: true })}
										showError={(text: string) => showErrorImage(text)}
									/>
									<AddImage
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										image={image4.img}
										setImage={(value: string) => setImage4({ img: value, modified: true })}
										showError={(text: string) => showErrorImage(text)}
									/>

									{/* Note */}
									<CustomTextInput
										numberOfLine={4}
										maxLenght={500}
										value={note}
										placeholder={""}
										title={strings.note}
										obligatory={false}
										disabled={props.update && !!props.role && props.role === roles.placeManager}
										setValue={(value: string) => setNote(value)}
									/>

									{/* Document picker */}
									<Text style={[styles.fieldTitle, { marginTop: 16, paddingTop: 5 }]}>{strings.addDocument}</Text>
									{doc == "" && docName == "" ? (
										<Button
											disabled={props.update && !!props.role && props.role === roles.placeManager}
											style={styles.chooseDocumentButton}
											handleButtonPress={() => pickDocument()}
											text={strings.choose}
											filledButton={false}
										/>
									) : (
										<View style={[width > 1400 ? styles.rowView : null]}>
											<Text style={styles.docName}>{docName}</Text>
											<Button
												style={[docModified ? styles.hidden : null, styles.chooseDocumentButton]}
												handleButtonPress={() => downloadFile()}
												text={strings.download}
												filledButton={false}
											/>
											<Button
												style={styles.chooseDocumentButton}
												handleButtonPress={() => {
													setDoc("");
													setDocName("");
												}}
												text={strings.removeDoc}
												disabled={props.update && !!props.role && props.role === roles.placeManager}
												filledButton={false}
											/>
										</View>
									)}
								</>
							) : null}
						</View>
					</View>
				</>

				<View style={{ width: "100%", marginTop: 50 }}>
					{props.update && props.ableToDelete ? (
						<Button
							style={styles.deleteButton}
							handleButtonPress={() => setDeletePlace(true)}
							text={strings.deletePlace}
							filledButton={true}
							disabled={placeName === "" || address === "" || placeManager.value === ""}
						/>
					) : null}
				</View>
			</>
		);
	};

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

				<BasicToast
					title={errorToastVisible.text}
					alertType={AlertTypes.error}
					alertVisible={errorToastVisible.isVisible}
					setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
				/>

				<BasicAlert
					title={basicAlertValues.title}
					description={basicAlertValues.description}
					buttonText={basicAlertValues.buttonText}
					alertType={basicAlertValues.type}
					alertVisible={basicAlertValues.isVisible}
					setAlertVisible={(isVisible: boolean) => {
						if (!isVisible && basicAlertValues.type === AlertTypes.success) {
							closeManager();
						}
						setBasicAlertValues({
							isVisible: isVisible,
							title: basicAlertValues.title,
							description: basicAlertValues.description,
							type: basicAlertValues.type,
							buttonText: basicAlertValues.buttonText,
						});
					}}
				/>

				<DeletePlace
					title={strings.sureToDeletePlace}
					description={strings.sureToDeletePlaceDescription}
					modalVisible={deletePlace}
					setDeletePlaceVisible={(isVisible: boolean) => setDeletePlace(isVisible)}
					delete={() => {
						props.delete();
						setDeletePlace(false);
						modifyPlace(true);
					}}
				/>

				<ExitFromModify
					modalVisible={exitFromModify}
					setExitFromModifyVisible={(isVisible: boolean) => setExitFromModify(isVisible)}
					exitWithoutSaving={() => {
						setExitFromModify(false);
						closeManager();
					}}
				/>

				{Platform.OS === "web" ? (
					<>
						<Text style={styles.title}>{(props.update ? strings.updatePlace : strings.addPlace) + " :"}</Text>
						<ScrollView>{renderPlaceManager()}</ScrollView>
					</>
				) : (
					<>
						{/* MOBILE */}
						<ScrollView>
							<Pressable style={styles.backContainer} onPress={() => goBackMobile()}>
								<View style={styles.rowView}>
									<FontAwesomeIcon size={15} icon={faArrowLeft}></FontAwesomeIcon>
									<Text style={styles.back}>{strings.back}</Text>
								</View>
							</Pressable>
							{renderPlaceManager()}
						</ScrollView>
					</>
				)}

				<View style={styles.bottonsContainer}>
					{!isWeb && isFirstPage ? (
						<Button style={styles.button} handleButtonPress={() => setIsFirstPage(false)} text={strings.forward} filledButton={true} />
					) : null}
					{!isWeb && !isFirstPage ? (
						<Button style={styles.button} handleButtonPress={() => setIsFirstPage(true)} text={strings.back} filledButton={false} />
					) : null}
					{(!isWeb && !isFirstPage) || isWeb ? (
						<Button
							style={styles.button}
							handleButtonPress={() => {
								props.update ? modifyPlace(false) : registerPlace();
							}}
							text={props.update ? strings.modify : strings.add}
							filledButton={true}
							disabled={placeName === "" || address === "" || placeManager.value === ""}
						/>
					) : null}
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		width: Platform.OS === "web" ? "80%" : "100%",
		height: Platform.OS === "web" ? "80%" : "100%",
		borderRadius: 10,
		padding: "3%",
		paddingBottom: Platform.OS === "web" ? "0%" : "5%",
		marginTop: Platform.OS === "web" ? "0%" : "10%",
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? 50 : "10%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	rowView: {
		marginTop: '5%',
		flexDirection: "row",
	},
	bottonsContainer: {
		flexDirection: "row",
		justifyContent: Platform.OS === "web" ? "flex-end" : "center",
	},
	fieldTitle: {
		backgroundColor: colors.white,
		width: "100%",
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: 20,
		fontSize: 14,
		height: 30,
		fontFamily: fonts.RalewayBold,
	},
	selectTitle: {
		backgroundColor: colors.white,
		alignItems: 'center',
		fontSize: 16,
		fontFamily: fonts.RalewayLightItalic,
		color: colors.grey2,
	},
	switch: {
		marginLeft: "5%",
		marginTop: 10,
		position: Platform.OS === "android" ? "absolute" : "relative",
		left: Platform.OS === "android" ? -20 : 0,
		top: Platform.OS === "android" ? 250 : 0,
	},
	backContainer: {
		margin: 20,
	},
	back: {
		marginLeft: 10,
		fontSize: 15,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	button: {
		marginTop: 20,
		marginBottom: 20,
		marginRight: Platform.OS === "web" ? "3%" : 5,
		marginLeft: 5,
		alignSelf: Platform.OS === "web" ? "flex-end" : "center",
	},
	deleteButton: {
		backgroundColor: colors.red,
		alignSelf: "center",
	},
	chooseDocumentButton: {
		alignSelf: "flex-start",
		marginBottom: 20,
		marginTop: 18,
		marginLeft: "10%",
	},
	docName: {
		fontSize: 16,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: 30,
	},
	readOnlyText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 30,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: colors.greyReadOnly,
		width: "96%",
		marginLeft: "2%",
		marginRight: "2%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
	},
	addressBox: {
		marginLeft: "5%",
		marginRight: "5%",
	},
	hidden: {
		display: "none",
	},
});

export default PlaceManager;
