import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
		marginBottom: 12,
	},
	rowView: {
		flexDirection: "row",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		marginHorizontal: "5%",
		marginBottom: "5%",
		elevation: 5,
	},
	groupsContainerWeb: {
		height: "100%",
		width: "100%",
		paddingRight: 58,
		paddingLeft: 58,
	},
	groupsContainer: {
		width: "100%",
		paddingVertical: 30,
		paddingHorizontal: '3%',
	},
	nextIcon: {
		marginLeft: 10,
		marginRight: 10,
		marginTop: 3,
		color: colors.blue3,
	},
	back: {
		marginLeft: 10,
		fontSize: 12,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	webImageContainer: {
		width: "100%",
		height: 130,
	},
	mobileImageContainer: {
		width: "95%",
		marginLeft: 10,
		marginRight: 10,
		marginTop: 30,
		height: 130,
	},
	coverImageWebContainer: {
		marginHorizontal: "5%",
		width: "90%",
		height: 550,
		backgroundColor: colors.grey3,
	},
	coverImageMobileContainer: {
		marginHorizontal: "5%",
		marginTop: 50,
		marginBottom: 30
	},
	loadPicture: {
		fontSize: 15,
		fontFamily: fonts.RalewayBold,
		marginTop: "5%",
	},
	coverImageWeb: {
		width: "50%",
		justifyContent: "center",
		alignSelf: "center",
	},
	buttonCoverImageWebContainer: {
		marginHorizontal: 5,
		marginVertical: 5,
		flex: 1
	},
	buttonCoverImageMobileContainer: {
		width: "100%",
		marginRight: 5,
	},
	buttonCoverImageWeb: {
		alignSelf: "flex-end",
		width: "80%",
		marginLeft: "10%",
		marginRight: "10%",
	},
	buttonCoverImageMobile: {
		alignSelf: "flex-end",
		width: "100%",
		marginVertical: 5,
	},
	coverImage: {
		margin: "5%",
		width: 140,
		height: 140,
		borderRadius: 100,
		alignSelf: "center"
	},
	noteWeb: {
		height: 100,
		marginBottom: "5%",
		marginTop: "5%",
		paddingTop: 10,
	},
	addPartecipants: {
		fontFamily: fonts.RalewayBold,
		fontSize: 16,
		marginHorizontal: 16
	},
	partecipants: {
		fontFamily: fonts.RalewayRegular,
		color: colors.grey,
		fontSize: 16,
		marginRight: 16,
		marginLeft: 2
	},
	userName: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
	},
	userRow: {
		paddingVertical: 14,
		paddingHorizontal: 10,
		justifyContent: 'space-between',
		marginHorizontal: 16,
		borderRadius: 10,
	},
	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	backText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.blue,
		marginLeft: 5,
	},
	backIcon: {
		marginTop: 2,
	},
	buttons: {
		width: '50%',
		marginLeft: '50%',
		flexDirection: "row",
		justifyContent: 'space-between',
		paddingRight: '7%',
		marginTop: 10
	},
	buttonsMobile: {
		flexDirection: "row",
		justifyContent: 'space-between',
		paddingHorizontal: '7%',
		marginTop: 10
	},
	createButton: {
		alignSelf: 'flex-end',
		paddingHorizontal: '7%',
		marginTop: 10
	},

	groupDescriptionWeb: {
		minHeight: 180,
		width: '85%',
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 16,
		marginHorizontal: 35,
		marginTop: 50,
		fontFamily: fonts.RalewayRegular
	},

	groupDescriptionMobile: {
		borderColor: colors.grey,
		borderStyle: "solid",
		borderWidth: 1,
		minHeight: 180,
		width: '85%',
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 16,
		marginTop: 20,
		fontFamily: fonts.RalewayRegular
	}

});
