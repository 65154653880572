import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import Button from "./Button";
import Checkbox from "expo-checkbox";
import { useState } from "react";
import { EndPoints } from "../services/EndPoints";
import RenderHtml from "react-native-render-html";
import { termsAndConditionsHtml } from "../locales/termsAndConditions";
import BasicAlert from "./Alerts/BasicAlert";
import Loader from "./Loader";
import { AlertTypes } from "../utils/AlertTypes";
import { roles } from "../utils/Roles";

interface props {
	modalVisible: boolean;
	termsAndConditions: boolean;
	userId?: string;
	changeTermAndConditionsStatus: boolean;
	setPrivacyPolicyVisible: (isVisible: boolean) => any;
	role: roles;
	onRoleUpdated?: (result: any) => any;
}

const PrivacyPolicyAlert = (props: props) => {
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const readTermsAndConditions = async () => {
		setLoaderVisible(true);
		await EndPoints.termsAndConditions(props.role, termsAndConditions)
			.then((res) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				console.log("entro2");

				props.onRoleUpdated && props.onRoleUpdated(res);
				console.log("entro3");

				close();
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	const close = () => {
		setTermsAndConditions(false);
		props.setPrivacyPolicyVisible(false);
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => close()}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => close()} />
			<View style={styles.container}>
				<View style={styles.contenModal}>
					<BasicAlert
						title={basicAlertValues.title}
						description={basicAlertValues.description}
						buttonText={basicAlertValues.buttonText}
						alertType={basicAlertValues.type}
						alertVisible={basicAlertValues.isVisible}
						setAlertVisible={(isVisible: boolean) => {
							setBasicAlertValues({
								isVisible: isVisible,
								title: basicAlertValues.title,
								description: basicAlertValues.description,
								type: basicAlertValues.type,
								buttonText: basicAlertValues.buttonText,
							});
						}}
					/>

					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<Text style={styles.title}>{strings.termsAndConditions}</Text>
					<ScrollView style={{ marginLeft: 20, marginRight: 20 }}>
						<RenderHtml source={{ html: termsAndConditionsHtml }} contentWidth={500} tagsStyles={tagsStyles.terms} systemFonts={[fonts.RalewayRegular]} />
					</ScrollView>

					{props.termsAndConditions ? (
						<View style={styles.termsAndConditionsContainer}>
							<Checkbox value={termsAndConditions} onValueChange={(value: boolean) => setTermsAndConditions(value)} color={colors.grey} />
							<Text style={styles.termsAndConditionsText}>{strings.acceptTermsAndConditions}</Text>
						</View>
					) : null}

					<Button
						style={styles.button}
						handleButtonPress={() => (props.changeTermAndConditionsStatus ? readTermsAndConditions() : close())}
						text={props.termsAndConditions ? strings.save : strings.close}
						filledButton={false}
						disabled={props.termsAndConditions ? !termsAndConditions : false}
					/>
				</View>
			</View>
		</Modal>
	);
};

const tagsStyles = {
	terms: {
		p: {
			fontFamily: fonts.RalewayRegular
		}
	}
}

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		width: Platform.OS === "web" ? "40%" : "80%",
		height: Platform.OS === "web" ? "50%" : "70%",
		borderRadius: 10,
		padding: "3%",
	},
	contenModal: {
		backgroundColor: colors.white,
		width: "80%",
		height: "70%",
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "10%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	textModal: {
		fontSize: 16,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
		width: "95%",
		height: 900,
	},
	button: {
		marginTop: Platform.OS === "web" ? "2%" : "10%",
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
	termsAndConditionsContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: "5%",
		marginBottom: "5%",
	},
	termsAndConditionsText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.grey4,
		marginLeft: 10,
	},
});

export default PrivacyPolicyAlert;
