import { RadioButtonProps } from "react-native-radio-buttons-group";
import { strings } from "../locales/strings";
import { Profile } from "../models/models";
import { genders } from "./Genders";

export const capitalizeName = (user: Partial<Profile>): string => {
	const name = user.firstName ? user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) : "";
	const surname = user.lastName ? user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1) : "";

	return `${name} ${surname}`.trim();
};

export const genderSelection: RadioButtonProps[] = [
	{
		id: "1",
		label: strings.woman,
		value: genders.woman,
	},
	{
		id: "2",
		label: strings.man,
		value: genders.man,
	},
	{
		id: "3",
		label: strings.other,
		value: genders.other,
	},
	{
		id: "4",
		label: strings.notShare,
		value: genders.notShare,
	},
];

export const getGenderLabel = (value: string) => {
	const prop = genderSelection.find((gender) => gender.value === value);

	return prop?.label || "";
};
