import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useEffect, useRef } from "react";
import { FlatList, Platform, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { PageNames } from "../../navigation/NavigationUtils";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/CreateEventScreen";
import { EventParticipationType } from "../../utils/types/EventType";

interface PropsInterface {
	event?: any;
	setEvent?: (value: any) => void;
	activity?: any;
	setActivity?: (value: any) => void;
	openConventionedPlace: boolean;
	setOpenConventionedPlace: (value: boolean) => void;
	placesList: any;
	setPlaceImages?: (value: any) => void;
	isReadOnly: boolean;
	navigation: any;
	pageName: string;
	onPress?: () => void;
    setOpenActivity?: (open: boolean) => void; 
    handleOnPress?: () => void;
}

const SelectPlaceComponent = (props: PropsInterface) => {
	React.useEffect(() => { }, [props]);

	const dropdownRef = useRef<View>(null);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				props.setOpenConventionedPlace(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);


	useEffect(() => {
		!!props.setActivity && props.setActivity({ ...props.activity, place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] } })
	}, [!!props.activity ? props.activity.field : null])

	const getList = (): { type: string; label: string; description: string }[] => {
		let list: { type: string; label: string; description: string }[] = [];
		props.placesList.forEach((item: any) => {
			list.push({ type: item.id, label: item.name, description: item.address });
		});
		return list;
	};

	const handleSetFilter = (filter: any) => {
		props.placesList.forEach((item: any) => {
			if (!!props.setEvent && item.id === filter.type) {
				props.setEvent({
					...props.event,
					place: {
						_id: item.id,
						name: item.name,
						placeManagerId: item.placeManager.id,
						coordinates: [item.coordinates[0], item.coordinates[1]],
						calendarId: item.calendarId,
						workspaceEmail: item.placeManager.workspaceEmail,
					},
				});
				!!props.setPlaceImages ? props.setPlaceImages({
					img1: item.imgLinks.length > 0 ? item.imgLinks[0] : "",
					img2: item.imgLinks.length > 1 ? item.imgLinks[1] : "",
					img3: item.imgLinks.length > 2 ? item.imgLinks[2] : "",
					img4: item.imgLinks.length > 3 ? item.imgLinks[3] : "",
				}) : null;
			} else {
				!!props.setActivity && props.setActivity({
					...props.activity,
					place: {
						_id: item.id,
						name: item.name,
						placeManagerId: item.placeManager.id,
						coordinates: [item.coordinates[0], item.coordinates[1]],
						calendarId: item.calendarId,
						workspaceEmail: item.placeManager.workspaceEmail,
					},
				});
			}
		});
	}

    const handleOnPress = () => {
        if (props.handleOnPress && Platform.OS !== 'web') {
            props.handleOnPress();
        } else {
            props.onPress && props.onPress();
            if (!props.isReadOnly) {
                if (Platform.OS === "web") {
                    props.setOpenConventionedPlace(!props.openConventionedPlace);
                } else {
                    props.navigation.navigate(PageNames.filter, {
                        title: strings.conventionedPlace,
                        filters: getList(),
                        filterSelected: !!props.setEvent ? props.event.place._id : props.activity.place._id,
                        navigatePage: props.pageName,
                        setFilter: handleSetFilter,
                        isActivity: !!props.setActivity,
                        setOpenActivity: props.setOpenActivity,
                        handleOnPress: props.handleOnPress,
                    })
                }
            }
        }
	};

	return (
		<View ref={dropdownRef} style={{ zIndex: 9999 }}>
			<Pressable
				style={styles.rowView}
				onPress={handleOnPress}
			>
				<Text
					style={[
						styles.selectTitle,
						!!!!props.event && !!props.event.place ? props.event.place.name != "" ? { color: colors.black } : { color: colors.grey } : null,
						!!props.activity && !!props.activity.place ? props.activity.place.name != "" ? { color: colors.black } : { color: colors.grey } : null,
						props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
					]}
				>
					{!!props.event && !props.activity ? props.event.place.name != "" ? props.event.place.name : props.event.participationType === EventParticipationType.online ? strings.videoCall : strings.select : null}
					{!!props.event && !!props.activity ? props.activity.place.name != "" ? props.activity.place.name : props.event.participationType === EventParticipationType.online ? strings.videoCall : strings.select : null} </Text>

				{props.openConventionedPlace ? (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleUp}></FontAwesomeIcon>
				) : (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleDown}></FontAwesomeIcon>
				)}
			</Pressable>
			{props.openConventionedPlace ? (
				<View style={styles.selectComponent}>
					<FlatList
						style={{ height: 220, zIndex: 9999 }}
						data={props.placesList}
						renderItem={({ item, index }) => {
							return (
								<Pressable
									style={[styles.rowView, { borderBottomColor: colors.grey3, borderBottomWidth: 1, zIndex: 9999 }]}
									onPress={() => {

										if (!!props.setEvent && props.event.place._id === item.id) {
											props.setEvent({ ...props.event, place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] } });
											!!props.setPlaceImages ? props.setPlaceImages({ img1: "", img2: "", img3: "", img4: "" }) : null;
										} else {
											props.event.participationType === EventParticipationType.online ?

												!!props.setEvent && props.setEvent({ ...props.event, place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] } }) :

												!!props.setEvent && props.setEvent({
													...props.event,
													place: {
														_id: item.id,
														name: item.name,
														placeManagerId: item.placeManager.id,
														coordinates: [item.coordinates[0], item.coordinates[1]],
														calendarId: item.calendarId,
														workspaceEmail: item.placeManager.workspaceEmail,
													},
												});
											props.setPlaceImages ? props.setPlaceImages({
												img1: item.imgLinks.length > 0 ? item.imgLinks[0] : "",
												img2: item.imgLinks.length > 1 ? item.imgLinks[1] : "",
												img3: item.imgLinks.length > 2 ? item.imgLinks[2] : "",
												img4: item.imgLinks.length > 3 ? item.imgLinks[3] : "",
											}) : null;
										}
										if (!!props.setActivity && props.activity.place._id === item.id) {
											props.setActivity({ ...props.activity, place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] } });

										} else {
											props.event.participationType === EventParticipationType.online ?

												!!props.setActivity && props.setActivity({ ...props.activity, place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] } }) :

												!!props.setActivity && props.setActivity({
													...props.activity,
													place: {
														_id: item.id,
														name: item.name,
														placeManagerId: item.placeManager.id,
														coordinates: [item.coordinates[0], item.coordinates[1]],
														calendarId: item.calendarId,
														workspaceEmail: item.placeManager.workspaceEmail,
													},
												});
										}
										props.setOpenConventionedPlace(false);
									}}
								>
									<View>
										<Text style={styles.placeName} numberOfLines={1}>
											{item.name}
										</Text>
										<Text style={styles.placeAddress} numberOfLines={1}>
											{item.address}
										</Text>
									</View>
									{!!props.event && !props.activity && item.id === props.event.place._id ? <FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon> : null}
									{!!props.activity && item.id === props.activity.place._id ? <FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon> : null}

								</Pressable>
							);
						}}
						keyExtractor={(item) => item.id}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
			) : null}
		</View>
	);
};

export default SelectPlaceComponent;
