import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { strings } from "../../locales/strings";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faClose } from "@fortawesome/free-solid-svg-icons";

interface props {
	name: string;
}

const HeaderMenu = (props: props) => {
	const User = () => {
		return (
			<View style={{ width: 140 }}>
				<Text style={styles.welcome}>{strings.hello + ","}</Text>
				<Text style={styles.title}>{props.name}</Text>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			<View style={styles.content}>
				<View style={styles.row}>
					<User />
					<View style={{ width: 110 }}>
						<View style={styles.todayView}>
							<Text style={[styles.welcome, { textAlign: "right" }]}>{strings.todayIs + " :"}</Text>
							<Text style={[styles.title, { textAlign: "right" }]}>{dayjs(new Date()).format("DD MMMM")}</Text>
						</View>
					</View>
				</View>
			</View>
			<View>
				<FontAwesomeIcon style={styles.angleIcon} size={15} icon={faAngleDown}></FontAwesomeIcon>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "flex-start",
		alignItems: "flex-end",
		flex: 1,
	},
	content: {
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 5,
		flexDirection: "row",
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginLeft: 5,
		marginRight: 5,
	},
	text: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.blue,
		margin: 5,
	},
	welcome: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
		margin: 5,
		paddingTop: 5,
	},
	todayView: {
		backgroundColor: colors.primary,
		borderRadius: 10,
		paddingBottom: 10,
	},
	greyBorder: {
		borderLeftColor: colors.grey,
		borderLeftWidth: Platform.OS === "web" ? 1 : 0,
		paddingLeft: 5,
		marginBottom: 10,
	},
	closeIcon: {
		position: "absolute",
		right: 5,
		top: 10,
		zIndex: 9999,
	},
	angleIcon: {
		backgroundColor: colors.white,
		borderRadius: 50,
		padding: 5,
		marginTop: -12,
		marginRight: 120,
		color: colors.blue,
	},
});

export default HeaderMenu;
