import AsyncStorage from "@react-native-async-storage/async-storage";
import { EntityResponse } from "../models/exchange";
import { ImageResponse, Profile, ProfileImageRequest, SupportEmailRequest, UpdateProfile, User } from "../models/models";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { roles } from "../utils/Roles";
import { APIBaseService } from "./ApiBaseService";

export class EndPoints extends APIBaseService {
	public static async signUp(user: User): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/signup/local`, true, user);
	}

	public static async updateProfile(user: Profile): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/profile`, true, user);
	}

	public static async getProfile(): Promise<EntityResponse<Profile>> {
		return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/user/profile`, false);
	}

	public static async updateUserProfile(body: UpdateProfile): Promise<EntityResponse<Profile>> {
		return await APIBaseService.performRequest("put", `${APIBaseService.baseUrl}/user/profile`, false, body);
	}

	public static async logIn(user: User): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/login/local`, true, user);
	}

	public static async logOut(): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/logout/local`, false);
	}

	public static async refreshToken(): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/refresh-token/local`, true, {
			accessToken: await AsyncStorage.getItem(asyncStorageUtils.accessToken),
			refreshToken: await AsyncStorage.getItem(asyncStorageUtils.refreshToken),
		});
	}

	public static async checkPassword(password: string): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/pwd-test`, true, {
			password: password,
		});
	}

	public static async uploadProfilePicture(picture: ProfileImageRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/profile-picture`, false, picture);
	}

	public static async uploadProfilePictureAuthorized(picture: ProfileImageRequest): Promise<EntityResponse<ImageResponse>> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/profile-picture`, false, picture);
	}

	public static async deleteProfilePicture(): Promise<EntityResponse<Profile>> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/user/profile-picture`, false);
	}

	public static async termsAndConditions(role: roles, readTermsAndConditions: Boolean): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/terms-and-conditions`, false, {
			readTermsAndConditions,
			role,
		});
	}

	public static async linkUser(userId: string): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/link/local`, false, {
			userId: userId,
		});
	}

	public static async changeRole(newRole: roles): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.authBaseUrl}/auth/change-role/local`, false, { newRole });
	}

	public static async sendSupportEmail(message: SupportEmailRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/support`, false, message);
	}
	
	public static async deleteUser(): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/user/delete/profile`, false, {});
	}

	public static async changePassword(oldPassword: string, newPassword: string, confirmPassword: string, isPwReset: boolean): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/change-password?isPwReset=${isPwReset}`, false, {
			oldPassword: oldPassword,
			newPassword: newPassword,
			confirmNewPassword: confirmPassword,
		});
	}


	public static async markPasswordForReset(email: string): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/user/reset-password`, true, { email });
	}

	public static async manageSettings(notificationsActive: boolean): Promise<any> {
		return await APIBaseService.performRequest("put", `${APIBaseService.baseUrl}/user/settings`, false, {
			settings: {
				notificationsActive: notificationsActive,
			},
		});
	}
}
