import { Platform, View, Text, Pressable } from "react-native";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/CreateEventScreen";
import CalendarComponent from "./CalendarComponent";
import CalendarIcon from "../Icons/CalendarIcon";
import { FormatDate } from "../../utils/FormatDateUtils";
import { ICalendarEventBase } from "react-native-big-calendar";

dayjs.extend(require("dayjs/plugin/isBetween"));

interface props {
	minDate: string | undefined;
	maxDate: string | undefined;
	startDate: string | undefined;
	endDate: string | undefined;
	isReadOnly: boolean;
	event: any;
	setEvent?: (event: any) => void;
	activity?: any;
	isActivity?: boolean;
	setActivity?: (activity: any) => void;
	setValidDate: (isValid: boolean) => void;
	monthDataWeb: ICalendarEventBase[];
	dateActivityContained?: ICalendarEventBase[];
	monthDataMobile: any;
	webAgendaDaySelected: any;
	markedDates: any;
}

const CreateEventCalendarsComponent = ({
	startDate,
	endDate,
	isReadOnly,
	event,
	setEvent,
	activity,
	setActivity,
	setValidDate,
	monthDataWeb,
	monthDataMobile,
	webAgendaDaySelected,
	markedDates,
	isActivity = false,
	dateActivityContained,

}: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const [showStartDatePicker, setShowStartDatePicker] = useState(false);
	const [showEndDatePicker, setShowEndDatePicker] = useState(false);
	const [invalidEndDate, setInvalidEndDate] = useState(false);
	const [eventNull, setEventNull] = useState(false);
	const [isContainedActivity, setIsContainedActivity] = useState(true)

	const [slotAvailable, setSlotAvailable] = useState<boolean>(true);

	const today = new Date();
	const tomorrow = new Date(today.setDate(today.getDate() + 1));

	const startPickerRef = useRef<View>(null);
	const endPickerRef = useRef<View>(null);

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	useEffect(() => {
		if (!!event.startDate && !!event.endDate) {
			checkSlotAvailability();
		}
	}, [event.startDate, event.endDate]);


	useEffect(() => {
		if (!!activity && !!activity.startDate && !!activity.endDate) {
			checkSlotAvailability();
		}
	}, [isActivity && activity.startDate, isActivity && activity.endDate]);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (startPickerRef.current && !startPickerRef.current.contains(event.target)) {
				setShowStartDatePicker(false);
			}

			if (endPickerRef.current && !endPickerRef.current.contains(event.target)) {
				setShowEndDatePicker(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);

	const isValidDate = (date1: string, date2: string) => {
		const isAfter = !!date1 && !!date2 && dayjs(date1).isAfter(dayjs(date2));
		const isNull = dayjs(date1).isSame(dayjs(date2));
		const isContained = dayjs(date1).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", "[]") &&
			dayjs(date2).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", "[]")
		const isValid = !!isAfter || !!isNull ||
			isActivity && !isContained;

		setInvalidEndDate(isAfter);
		setIsContainedActivity(isContained)
		setEventNull(isNull);
		setValidDate(isValid);
	};




	const checkSlotAvailability = () => {
		if (!!monthDataWeb) {
			let isAvailable = true;
			monthDataWeb.forEach((el: any) => {
				if (isAvailable) {
					if (!isActivity) {
						isAvailable =
							!dayjs(event.startDate).isBetween(dayjs(el.start), dayjs(el.end), "minutes", "[)") &&
							!dayjs(event.endDate).isBetween(dayjs(el.start), dayjs(el.end), "minutes", "(]") &&
							!dayjs(el.start).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", "[)") &&
							!dayjs(el.end).isBetween(dayjs(event.startDate), dayjs(event.endDate), "minutes", "(]");
					} else {
						isAvailable =
							!dayjs(activity.startDate).isBetween(dayjs(el.start), dayjs(el.end), "minutes", "[]") &&
							!dayjs(el.start).isBetween(dayjs(activity.startDate), dayjs(activity.endDate), "minutes", "[)") ||

							!dayjs(activity.endDate).isBetween(dayjs(el.start), dayjs(el.end), "minutes", "[]") &&
							!dayjs(el.end).isBetween(dayjs(activity.startDate), dayjs(activity.endDate), "minutes", "(]");
					}
				}
			});
			setSlotAvailable(isAvailable);
		} else setSlotAvailable(true);
	};

	return (
		<View>
			{/* START DATE */}
			<Text style={[styles.fieldTitle, !isWeb ? { marginTop: 20 } : null]}>{strings.selectStartDate + " *"}</Text>
			<View style={{ zIndex: 99999 }} ref={startPickerRef}>
				<Pressable
					disabled={isReadOnly}
					style={[styles.datePickerContainer, showStartDatePicker ? null : { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]}
					onPress={() => {
						setShowEndDatePicker(false);
						setShowStartDatePicker(!showStartDatePicker);
					}}
				>
					<CalendarIcon color={colors.blue} size={24} />
					{!isActivity ? (
						<Text style={styles.datePickerText}>{!!startDate ? FormatDate.createEventDate(startDate) : strings.tapToChooseStartDate}</Text>

					) : (
						<Text style={styles.datePickerText}>{!!activity.startDate ? FormatDate.createEventDate(activity.startDate) : strings.tapToChooseStartDate}</Text>

					)}

				</Pressable>
				{showStartDatePicker && (
					<View style={styles.calendarContainer}>
						<CalendarComponent
							maxDate={!!isActivity ? dayjs(event.endDate).subtract(1, 'days').toString()
							: undefined}
							minDate={!isActivity ? tomorrow.toString() : event.startDate}
							readonly={isReadOnly}
							onDayPress={(date: string) => {
								if (dayjs(date).isValid()) {
									if (!isActivity) {
										setEvent ? setEvent({
											...event,
											startDate: date,
											endDate:
												!endDate || dayjs(date).isAfter(dayjs(endDate)) || dayjs(date).isSame(dayjs(endDate))
													? new Date(new Date(date).setMinutes(new Date(date).getMinutes() + 15)).toString()
													: endDate,
										}) : null;
										isValidDate(date, event.EndDateOnchangeStart);
									} else {
										const endDateOnChangeStart = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + 15)).toString()
										setActivity ? setActivity({
											...activity,
											startDate: date,
											endDate:
												!endDate || dayjs(date).isAfter(dayjs(endDate)) || dayjs(date).isSame(dayjs(endDate))
													? endDateOnChangeStart
													: endDate,
										}) : null;
										!endDate || dayjs(date).isAfter(dayjs(endDate)) || dayjs(date).isSame(dayjs(endDate)) ?
											isValidDate(date, endDateOnChangeStart) : isValidDate(date, activity.endDate);
									}
								}

							}}
							date={startDate}
							showTimePicker={true}
							position={"absolute"}
							showAgenda={false}
							startDate={!!event.startDate ? event.startDate : !!activity && !!activity.startDate ? activity.startDate : undefined}
							monthDataWeb={monthDataWeb}
							monthDataMobile={monthDataMobile}
							webAgendaDaySelected={webAgendaDaySelected}
							markedDates={markedDates}
							isStartDate={true}
							dateActivityContained={isActivity ? dateActivityContained:undefined}


						/>
					</View>
				)}
			</View>

			{/* END DATE */}
			<Text style={[styles.fieldTitle, { marginTop: 20, }]}>{strings.selectEndDate + " *"}</Text>
			<View style={{ zIndex: 0 }} ref={endPickerRef}>
				<Pressable
					style={[styles.datePickerContainer, showEndDatePicker ? null : { borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }]}
					disabled={event.startDate === null || isReadOnly || !!activity && activity.startDate === null}
					onPress={() => {
						if (!!event.startDate) {
							setShowStartDatePicker(false);
							setShowEndDatePicker(!showEndDatePicker);
						} else if (!!activity.startDate) {
							setShowStartDatePicker(false);
							setShowEndDatePicker(!showEndDatePicker);
						}
					}}
				>
					<CalendarIcon color={colors.blue} size={24} />
					<Text style={styles.datePickerText}>{!!endDate ? FormatDate.createEventDate(endDate!) : strings.tapToChooseEndDate}</Text>
				</Pressable>
				{showEndDatePicker && (
					<View style={styles.calendarContainer}>
						<CalendarComponent
							maxDate={isActivity ? dayjs(event.endDate).subtract(1, 'days').toString() : undefined}
							minDate={isActivity ? event.startDate : startDate}
							readonly={isReadOnly}
							onDayPress={(date: string) => {
								if (dayjs(date).isValid()) {
									if (!isActivity) {
										isValidDate(event.startDate, date);
										setEvent ? setEvent({ ...event, endDate: date }) : null;
									} else {
										setActivity ? setActivity({ ...activity, endDate: date }) : null;
										isValidDate(activity.startDate, date);
									}
								}
							}}
							date={endDate ?? undefined}
							showTimePicker={true}
							position={"absolute"}
							showAgenda={false}
							startDate={!!event.startDate ? event.startDate : !!activity.startDate ? activity.startDate : undefined}
							monthDataWeb={monthDataWeb}
							monthDataMobile={monthDataMobile}
							webAgendaDaySelected={webAgendaDaySelected}
							markedDates={markedDates}
							isStartDate={false}
							dateActivityContained={isActivity ? dateActivityContained:undefined}
						/>
					</View>
				)}
			</View>

			{!isActivity && invalidEndDate && <Text style={styles.errorLabel}>{strings.invalidEndDate}</Text>}
			{isActivity && invalidEndDate && <Text style={styles.errorLabel}>{strings.invalidEndDateActivity}</Text>}
			{!isActivity && eventNull && <Text style={styles.errorLabel}>{strings.eventNull}</Text>}
			{isActivity && eventNull && <Text style={styles.errorLabel}>{strings.activityNull}</Text>}
			{isActivity && !isContainedActivity && <Text style={styles.errorLabel}>{strings.eventNotIncludeActivity}</Text>}
			{!isActivity && !slotAvailable && !!event.startDate && !!event.endDate && event.place?._id && <Text style={styles.errorLabel}>{strings.slotNotAvailable}</Text>}
			{isActivity && !slotAvailable && !!activity.startDate && !!activity.endDate  && <Text style={styles.errorLabel}>{strings.activitySlotNotAvailable}</Text>}

		</View>
	);
};

export default CreateEventCalendarsComponent;
