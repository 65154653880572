import { APIBaseService } from "./ApiBaseService";
import { Event, EventActivity, PlacesAvailabilityResponse } from "../models/eventsModels.s";
import { EntityResponse, PagedResponse } from "../models/exchange";
import { EventActivityStatus, ActivityStatus, EventStatus } from "../utils/EventStatus";
import { Invite, Place, ProfileImageRequest } from "../models/models";

export class EventsEndPoint extends APIBaseService {
	public static async createEvent(event: Event): Promise<any> {
		const res = await APIBaseService.performRequest("POST", `${APIBaseService.baseUrl}/event`, false, event);
		return res;
	}

	public static async createEventActivity(eventId:string,activity:EventActivity): Promise<any> {
		const res = await APIBaseService.performRequest("POST", `${APIBaseService.baseUrl}/event/${eventId}/activity`, false, activity);
		return res;
	}

	public static async deleteEventActivity(eventId: string, activityId:string): Promise<any> {
		return await APIBaseService.performRequest("DELETE", `${APIBaseService.baseUrl}/event/${eventId}/activity/${activityId}`, false);
	}

	public static async modifyActivity(eventId: string, activityId: string, activity:EventActivity ): Promise<any> {
		const res = await APIBaseService.performRequest("PUT", `${APIBaseService.baseUrl}/event/${eventId}/activity/${activityId}`, false, activity);
		return res;
	}


	public static async modifyEvent(event: Event, eventId: string): Promise<any> {
		const res = await APIBaseService.performRequest("PUT", `${APIBaseService.baseUrl}/event/${eventId}`, false, event);
		return res;
	}

	public static async partecipateEvent(event: { userId: string }, eventId: string): Promise<any> {
		const res = await APIBaseService.performRequest("PUT", `${APIBaseService.baseUrl}/event/${eventId}`, false, event);
		return res;
	}

	public static async getEvent(eventId: string): Promise<any> {
		const res = await APIBaseService.performRequest("GET", `${APIBaseService.baseUrl}/event/${eventId}`, false);
		return res;
	}

	public static async changeActivityStatus(eventId: string, activityId: string, status:EventActivityStatus ): Promise<any> {
		const res = await APIBaseService.performRequest("PUT", `${APIBaseService.baseUrl}/event/${eventId}/activity/${activityId}/status`, false,
		{ status: status});

		return res;
	}




	public static async getEvents({
		page = 1,
		pageSize,
		dashboardType,
		type,
		status,
		createdBy,
		search,
		placeId,
		isParticipatedByMe,
		ended,
		isInvited,
		checkOperator,
		activityStatus,
		checkActivityOperator,
		sort,
	}: {
		page: number;
		pageSize: number;
		dashboardType?: string;
		type?: string;
		status?: EventStatus[];
		createdBy?: string;
		search?: string;
		placeId?: string;
		isParticipatedByMe?: boolean;
		ended?: boolean;
		isInvited?: boolean;
		checkOperator?: boolean;
		activityStatus?: ActivityStatus[];
		checkActivityOperator?: boolean;
		sort?:string;
	}) {
		const field = dashboardType ? `&field=${dashboardType}` : "";
		const _type = type && type !== "all" ? `&type=${type}` : "";
		const _status = status?.length ? `&${APIBaseService.arrayToQueryString("status", status)}` : "";
		const _createdBy = createdBy ? `&userId=${createdBy}` : "";
		const _search = search ? `&search=${search}` : "";
		const _placeId = placeId ? `&placeId=${placeId}` : "";
		const _isParticipatedByMe = isParticipatedByMe ? `&isParticipatedByMe=${isParticipatedByMe}` : "";
		const _ended = (ended === false || ended === true) ? `&ended=${ended}` : "";
		const _isInvited = isInvited ? `&isInvited=${isInvited}` : "";
		const _checkOperator = checkOperator ? `&checkOperator=true` : "";
		const _activityStatus = activityStatus?.length ? `&${APIBaseService.arrayToQueryString("activityStatus", activityStatus)}` : "";
		const _checkActivityOperator = checkActivityOperator ? `&checkActivityOperator=true` : "";
		const _sort = sort ? `&sort=${sort}` : "";

		const res = await APIBaseService.performRequest<PagedResponse<Event>>(
			"GET",
			`${APIBaseService.baseUrl}/event?page=${page}&pageSize=${pageSize}${field}${_type}${_status}${_createdBy}${_search}${_isParticipatedByMe}${_ended}${_isInvited}${_placeId}${_checkOperator}${_activityStatus}${_checkActivityOperator}${_sort}`,
			false
		);
		return res;
	}

	public static async hasFieldEvents({ dashboardType, status, ended }: { dashboardType: string[], status?: EventStatus[], ended?: boolean }): Promise<EntityResponse<Record<string, boolean>>> {
		const field = `${APIBaseService.arrayToQueryString("field", dashboardType)}`;
		const _status = status?.length ? `&${APIBaseService.arrayToQueryString("status", status)}` : "";
		const _ended = (ended === false || ended === true) ? `&ended=${ended}` : "";

		return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/event/hasFieldEvents?${field}${_status}${_ended}`, false);
	}

	public static async getCollaborators(eventType: string): Promise<any> {
		return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/event/collaborators/${eventType}`, false);
	}

	public static async addPicture(eventId: string, image: ProfileImageRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/event/${eventId}/image`, false, image);
	}

	public static async addActivityPicture(eventId: string, activityId:string, image: ProfileImageRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/event/${eventId}/activity/${activityId}/image`, false, image);

	}
	public static async deletePicture(eventId: string, image: string): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/event/${eventId}/image`, false, image);
	}
	public static async deleteActivityPicture(eventId: string, activityId:string, image: string): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/event/${eventId}/activity/${activityId}/image`, false, image);

	}

	public static async partecipate(eventId: string, userId: string, name: string, type: string, mode: string): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/event/${eventId}/participation`, false, {
			userId: userId,
			name: name,
			type: type,
			mode: mode,
		});
	}

	public static async addInvite(invite: Invite): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/invite`, false, invite);
	}

	public static async deleteInvite(userId: string, eventId: string): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/invite?userId=${userId}&eventId=${eventId}`, false);
	}

	public static async getEventsByDate(placeId: string, month: number | string, year: number | string): Promise<PlacesAvailabilityResponse[]> {
		return await APIBaseService.performRequest("get", `${APIBaseService.placeBaseUrl}/places-availability/place/${placeId}?month=${month}&year=${year}`, false);
	}

	public static async getPlacesByDate(startTime: string, endTime: string, field:string[]): Promise<Place[]> {
		return await APIBaseService.performRequest("get", `${APIBaseService.placeBaseUrl}/places-availability/by-time-window?startTime=${startTime}&endTime=${endTime}&field[0]=${field}`, false);
	}
}
