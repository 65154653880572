
import { useState } from 'react';
import { EventParticipation, EventType } from '../../../../utils/types/EventType';
import { EventStatus } from '../../../../utils/EventStatus';
import { MacroArea, Place, PlaceImageDocRes, ProfileImageRequest } from '../../../../models/models';
import { CreateEventCollaborator, CreateEventImage, CreateEventOperator, CreateEventUser, Event, EventCollaborators } from '../../../../models/eventsModels';
import { Platform } from 'react-native';
import { AttachmentPicker } from '../../../../utils/AttachmentPicker';
import { strings } from '../../../../locales/strings';
import { AlertTypes } from '../../../../utils/AlertTypes';
import { EventsEndPoint } from '../../../../services/EventsEndPoint';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { roles } from '../../../../utils/Roles';
import { asyncStorageUtils } from '../../../../utils/AsyncStorageUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PageNames } from '../../../../navigation/NavigationUtils';
import { dashboardType } from '../../../../utils/DashboardType';
import { GetPartepationType } from '../../../../utils/types/Partecipants';
import React from 'react';
import dayjs from 'dayjs';
import { PlacesEndPoint } from '../../../../services/PlacesEndPoint';
import { UsersEndpoints } from '../../../../services/UsersEndpoints';
import { MacroAreaType } from '../../../../utils/types/MacroAreaType';

interface SearchProps {
    searchTerm: string;
    page: number;
    userFilter: "all" | "following" | "followers";
    sorting: "asc" | "desc";
}

interface PropsInterface {
    navigation?: any;
    showActionSheetWithOptions: any;
    route: {
        params: {
            event: Event;
            userId: string;
            role: roles;
            edit: boolean;
            macroarea: MacroArea;
        };
    };
}


const useCreateEventFoodController = (props: PropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;
    const attachmentPicker = new AttachmentPicker();
    const { showActionSheetWithOptions } = useActionSheet();
    const propsEvent = props.route?.params?.event;
    const [scrollRefScreen, setScrollRefScreen] = useState<any>(null);
    const [role, setRole] = useState<roles>();
    const [pageSelected, setPageSelected] = useState<number>(1);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
    const [macroArea, setMacroArea] = useState<MacroArea>({ key: "", label: "" });
    const [groceryList, setGroceryList] = useState<string>("");
    const [placesList, setPlacesList] = useState<Array<Place>>([]);
    const [invitedUserIds, setInvitedUserIds] = useState<{ items: Array<string> }>({ items: [] });
    const [eventId, setEventId] = useState<string>("");
    const [typology, setTypology] = useState<MacroArea>();
    const [internalOperator, setInternalOperator] = useState<CreateEventOperator>({ firstName: "", lastName: "" });
    const [externalOperator, setExternalOperator] = useState<CreateEventOperator>({ firstName: "", lastName: "" });
    const [collaborators, setCollaborators] = useState<Array<CreateEventCollaborator>>([]);
    const [createSubEvent, setCreateSubEvent] = useState<boolean>(false);
    const [menuIndex, setMenuIndex] = useState<number>(0);
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [internalUsers, setInternalUsers] = useState<Array<CreateEventUser>>([{ label: "", value: "", firstName: "", lastName: "" }]);
    const [showExternalOperatorSelect, setShowExternalOperatorSelect] = useState(false);
    const [externalUsers, setExternalUsers] = useState<Array<CreateEventUser>>([{ label: "", value: "", firstName: "", lastName: "" }]);
    const [validDate, setValidDate] = useState<boolean>(false);
    const [image, setImage] = useState<CreateEventImage>({ img: "", modified: true });
    const [imageObject, setImageObject] = useState<ProfileImageRequest | null>(null);
    const [calendarEventId, setCalendarEventId] = useState<string>("");
    const [placeImages, setPlaceImages] = useState({ img1: "", img2: "", img3: "", img4: "" });


    const [event, setEvent] = useState({
        userId: "",
        field: "",
        type: "",
        participationType: EventParticipation[0].type,
        title: "",
        img: "",
        description: "",
        operatorId: "", // INTERNAL OPERATOR
        externalOperatorId: "", // EXTERNAL OPERATOR
        placeId: "",
        placeName: "",
        place: {
            _id: "",
            name: "",
            placeManagerId: "",
            coordinates: [null, null],
            calendarId: "",
            workspaceEmail: "",
        },
        collaborators: [],
        collaboratorsDescription: "",
        listOfInvitedUser: [],
        maxNumberOfUsers: "",
        isSubEvent: false,
        correlatedEventId: "",
        groceryList: [""],
        menu: { appetizer: "", mainCourse: "", secondCourse: "", dessert: "", other: "" },
        startDate: null,
        endDate: null,
        status: EventStatus.draft,
    });
    
    const uploadImage = async (eventId: string) => {
        try {
            if (image?.img !== "" && image?.modified) {
                await EventsEndPoint.addPicture(eventId, { fileURI: image.img }).then((res: PlaceImageDocRes) => {
                    setImage({ ...image, modified: false });
                    setEvent({ ...event, img: res.item.link });
                });
            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
        }
    };
    
    const saveEvent = async (save: boolean, nextStepSave: boolean, deleted: boolean) => {
        // splitting grocery list in array
        let groceryArray: string[] = groceryList != "" ? groceryList.split(/[,. -;]+/) : [];
        if (groceryArray.length > 0) {
            let aux: string[] = [];
            groceryArray.forEach((item) => {
                if (item != "") {
                    aux.push(item);
                }
            });
            groceryArray = aux;
        }

        let startDate = !!event.startDate ? new Date(event.startDate) : null;
        let endDate = !!event.endDate ? new Date(event.endDate) : null;

        const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);

        let eventCollaborators: EventCollaborators[] | null = [];
        if (role === roles.serviceCoordinator && props.route.params.edit) {
            collaborators.forEach((item) => {
                eventCollaborators!.push({
                    n: item.value != "" ? (item.value as unknown as number) : "Infinity",
                    text: item.name,
                });
            });
        } else {
            // Do not update - Remove from payload
            eventCollaborators = null;
        }

        const eventInfo: Event = {
            datetime: new Date(),
            userId: event.userId ? event.userId : userId ?? "",
            field: event.field,
            type: event.type,
            participationType: event.participationType,
            title: event.title,
            description: event.description,
            internalOperator: internalOperator.firstName !== "" && internalOperator.lastName !== "" ? {
                id: event.operatorId,
                firstName: internalOperator.firstName,
                lastName: internalOperator.lastName,
            } : undefined,
            externalOperator:  
            externalOperator.firstName !== "" && externalOperator.lastName !== "" ? {
                id: event?.externalOperatorId ,
                firstName: externalOperator.firstName,
                lastName: externalOperator.lastName,
            } : undefined,
            place: {
                _id: event.place._id,
                name: event.place.name,
                placeManagerId: event.place.placeManagerId,
                coordinates: [event.place.coordinates[0], event.place.coordinates[1]],
                calendarId: event.place.calendarId,
                workspaceEmail: event.place.workspaceEmail,
            },
            ...(eventCollaborators && { collaborators: eventCollaborators }),
            textArea: event.collaboratorsDescription,
            listOfInvitedUser: invitedUserIds.items,
            maxNumberOfUsers: event.maxNumberOfUsers,
            isSubEvent: event.isSubEvent,
            createSubEvent: createSubEvent,
            correlatedEventId: event.correlatedEventId,
            groceryList: groceryArray,
            menu: {
                appetizer: event.menu.appetizer,
                mainCourse: event.menu.mainCourse,
                secondCourse: event.menu.secondCourse,
                dessert: event.menu.dessert,
                other: event.menu.other,
            },
            startDate: startDate,
            endDate: endDate,
            status: event.status,
        };
        console.log("ACTUAL STATUS " + event.status + "   save ?" + save + "edit? " + props.route.params.edit);

        switch (true) {
            case deleted: {
                eventInfo.status = EventStatus.deleted;
                deleteEvent(eventInfo);
                break;
            }

            // post DRAFT
            case !props.route.params.edit && save && eventId === "": {
                eventInfo.status = EventStatus.draft;
                // Loader to avoid going to next step before getting id of created event, causing the UI to re-do the create event call
                setLoaderVisible(true);
                EventsEndPoint.createEvent(eventInfo)
                    .then((res) => {
                        console.log("res post event " + JSON.stringify(res));
                        uploadImage(res.item._id);
                        setEventId(res.item._id);
                        setCalendarEventId(res.item.calendarEventId)

                        nextStepSave
                            ? null
                            : setBasicAlertValues({ isVisible: true, title: "", description: strings.draftSaved, type: AlertTypes.success, buttonText: strings.close });
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                        }
                    })
                    .catch((e) => {
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                                setBasicAlertValues({ isVisible: true, title: AlertTypes[e?.status as keyof typeof AlertTypes], description: e?.error?.message, type: AlertTypes.error, buttonText: strings.back });
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                            setBasicAlertValues({ isVisible: true, title: AlertTypes[e?.status as keyof typeof AlertTypes], description: e?.error?.message, type: AlertTypes.error, buttonText: strings.back });

                        }
                    });
                break;
            }

            // put TO_BE_APPROVED
            case !props.route.params.edit && !save: {
                eventInfo.status = EventStatus.to_be_approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da DRAFT a TO_BE_APPROVED
            case props.route.params.edit && propsEvent.status === EventStatus.draft && !save: {
                eventInfo.status = EventStatus.to_be_approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da TO_BE_APPROVED a WAIT_INTERNAL_OPERATOR
            case props.route.params.edit && propsEvent.status === EventStatus.to_be_approved && !save: {
                eventInfo.status = EventStatus.wait_internal_operator;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da WAIT_INTERNAL_OPERATOR a WAIT_EXTERNAL_OPERATOR se presente external operator
            case props.route.params.edit &&
                propsEvent.status === EventStatus.wait_internal_operator &&
                !save &&
                propsEvent.externalOperator &&
                propsEvent.externalOperator.id != "": {
                    eventInfo.status = EventStatus.wait_external_operator;
                    postEvent(eventInfo, save, nextStepSave);
                    break;
                }

            // modifica da WAIT_INTERNAL_OPERATOR a APPROVED se non presente external operator
            case props.route.params.edit && propsEvent.status === EventStatus.wait_internal_operator && !save && propsEvent.externalOperator.id === "": {
                eventInfo.status = EventStatus.approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da WAIT_EXTERNAL_OPERATOR a APPROVED
            case props.route.params.edit && propsEvent.status === EventStatus.wait_external_operator && !save: {
                eventInfo.status = EventStatus.approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // JUST SAVE
            default: {
                eventInfo.status = event.status;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }
        }
    };

    const deleteEvent = (eventInfo: any) => {
        eventInfo.status = EventStatus.deleted;
        EventsEndPoint.modifyEvent(eventInfo, eventId)
            .then((res) => {
                setBasicAlertValues({ isVisible: true, title: "", description: strings.eventDeleted, type: AlertTypes.success, buttonText: strings.close });
                clearFields();
                props.navigation.navigate(PageNames.home);

            })
            .catch((e) => {
                setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
            });
    };

    const postEvent = (eventInfo: any, save: boolean, nextStepSave: boolean) => {
        console.log("ACTUAL STATUS2 " + eventInfo.status + "   save ?" + save + "edit? " + props.route.params.edit);

        // PUBLISH EVENT
        EventsEndPoint.modifyEvent(eventInfo, eventId)
            .then((res) => {
                uploadImage(res.item.id);
                if (createSubEvent && !event.isSubEvent && !save) {
                    setEventId(res.item.correlatedEventId);
                }
                nextStepSave
                    ? null
                    : setBasicAlertValues({
                        isVisible: true,
                        title: "",
                        description:
                            eventInfo.status === EventStatus.to_be_approved
                                ? strings.eventPublished
                                : eventInfo.status === EventStatus.draft
                                    ? strings.draftSaved
                                    : strings.eventApproved,
                        type: AlertTypes.success,
                        buttonText: strings.close,
                    });
            })
            .catch((e) => {
                nextStepSave
                    ? null
                    : setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
            });

        // IF HAS SUBEVENT
        if (createSubEvent && !event.isSubEvent && !save) {
            setEvent({
                ...event,
                field: dashboardType.food,
                type: EventType[1].type,
                title: "",
                img: "",
                description: "",
                operatorId: "", // INTERNAL OPERATOR
                externalOperatorId: "", // EXTERNAL OPERATOR
                placeId: "",
                placeName: "",
                place: {
                    _id: "",
                    placeManagerId: "",
                    name: "",
                    coordinates: [null, null],
                    calendarId: "",
                    workspaceEmail: "",
                },
                collaborators: [],
                collaboratorsDescription: "",
                listOfInvitedUser: [],
                maxNumberOfUsers: "",
                isSubEvent: true,
                correlatedEventId: eventId,
                groceryList: [""],
                menu: { appetizer: "", mainCourse: "", secondCourse: "", dessert: "", other: "" },
                startDate: new Date(),
                endDate: new Date(),
                startHour: { hours: 0, minutes: 0 },
                endHour: { hours: 0, minutes: 0 },
                status: EventStatus.draft,
            });
            setImage({ img: "", modified: false });
            setTypology({ key: EventType[1].type, label: EventType[1].label }); // SUBEVENT GROCERY
        } else {
            if (!save) {
                clearFields();
                props.navigation.navigate(PageNames.home);
            }
        }
    };

    const scrollToTop = () => {
        scrollRefScreen && scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
    };

    const getCollaborators = async () => {
        if (typology) {
            const res = await EventsEndPoint.getCollaborators(typology.key);
            let collaboratorsValues: any = [];
            res.item.forEach((item: any) => {
                let collaborator = {
                    key: item,
                    name: GetPartepationType(item),
                    value: "",
                };
                collaboratorsValues.push(collaborator);
            });
            setCollaborators(collaboratorsValues);
        }
    };

    const handleFirstPageForwardButton = () => {
        role === roles.serviceCoordinator && props.route.params.edit ? getCollaborators() : null;
        setPageSelected(2);
        setFirstRender(true);
        setMenuIndex(0);
        saveEvent(true, true, false);
        scrollToTop();
    }

    const handleSecondPageBackButton = () => {
        setPageSelected(1);
        scrollToTop();
    }

    const handleSecondPageForwardButton = () => {
        setPageSelected(3);
        scrollToTop();
        saveEvent(true, true, false);
    }
    
    const handleThirdPageBackButton = () => {
        setFirstRender(true);
        setMenuIndex(0);
        setPageSelected(2);
        scrollToTop();
    }


    const clearFields = () => {
        setEvent({
            userId: "",
            field: !!props.route.params.macroarea ? props.route.params.macroarea.key : "",
            type: "",
            participationType: EventParticipation[0].type,
            title: "",
            img: "",
            description: "",
            operatorId: "", // INTERNAL OPERATOR
            externalOperatorId: "", // EXTERNAL OPERATOR
            placeId: "",
            placeName: "",
            place: {
                _id: "",
                name: "",
                placeManagerId: "",
                coordinates: [null, null],
                calendarId: "",
                workspaceEmail: "",
            },
            collaborators: [],
            collaboratorsDescription: "",
            listOfInvitedUser: [],
            maxNumberOfUsers: "",
            isSubEvent: false,
            correlatedEventId: "",
            groceryList: [""],
            menu: { appetizer: "", mainCourse: "", secondCourse: "", dessert: "", other: "" },
            startDate: null,
            endDate: null,
            status: EventStatus.draft,
        });
        setGroceryList("");
        !!props.route.params.macroarea ? setMacroArea(props.route.params.macroarea) : setMacroArea({ key: "", label: "" });
        setTypology({ key: "", label: "" });
        setInvitedUserIds({ items: [] });
        setInternalOperator({ firstName: "", lastName: "" });
        setExternalOperator({ firstName: "", lastName: "" });
        setPlaceImages({ img1: "", img2: "", img3: "", img4: "" });

        setImage({ img: "", modified: true });
        setImageObject(null);
        setEventId("");
        setCalendarEventId("");
        setValidDate(false);
        setPageSelected(1);
        setCreateSubEvent(false);
    };

    const flow = async () => {
        try {
            clearFields();
            setLoaderVisible(true);
            const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
            const role = await AsyncStorage.getItem(asyncStorageUtils.role);
            setShowExternalOperatorSelect(role === roles.externalUser || role === roles.internalUser || role === roles.serviceCoordinator);
            setRole(role ? role as roles : undefined);
            setIsReadOnly(props.route.params.edit && role != roles.serviceCoordinator && propsEvent.status != EventStatus.draft);

            // GET INTERNAL USERS
            setShowExternalOperatorSelect(true);
            let internalUsersList: any[] = [];
            const internalUserRes = await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.internalUser], undefined, true);
            internalUserRes.page.items.forEach((value: any) => {
                const user: any = {
                    value: value.id,
                    label: value.firstName + " " + value.lastName,
                    firstName: value.firstName,
                    lastName: value.lastName,
                };
                internalUsersList.push(user);
            });
            setInternalUsers(internalUsersList);

            // GET EXTERNAL USERS
            let externalUsersList: any[] = [];
            if (role === roles.externalUser || role === roles.internalUser || role === roles.serviceCoordinator) {
                const externalUserRes = await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.externalUser], undefined, true);
                externalUserRes.page.items.forEach((value: any) => {
                    const user: any = {
                        value: value.id,
                        label: value.firstName + " " + value.lastName,
                        firstName: value.firstName,
                        lastName: value.lastName,
                    };
                    externalUsersList.push(user);
                });
                setExternalUsers(externalUsersList);
            }

            // GET PLACES
            const placesRes = props.route.params.edit ? await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined, field: Array(props.route?.params?.event.field) }) :
                await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined, field: Array(props.route.params.macroarea.key) });
            let placeList = [];

            setPlacesList(placesRes.page.items);
            placeList = placesRes.page.items;

            props.route.params.edit ? editEvent(placeList) : null;
            if (Platform.OS === "ios") {
                setTimeout(() => {
                    setLoaderVisible(false);
                }, 500);
            } else {
                setLoaderVisible(false);
            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title:' e?.status', description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
            if (Platform.OS === "ios") {
                setTimeout(() => {
                    setLoaderVisible(false);
                }, 500);
            } else {
                setLoaderVisible(false);
            }
        }
    };

    const editEvent = (places: []) => {
        let placeName = "";
        let place = { _id: "", name: "", placeManagerId: "", coordinates: [null, null], calendarId: "", workspaceEmail: "" };
        places.forEach((item: any) => {
            if (propsEvent.place && item.id === propsEvent?.place._id) {
                setPlaceImages({
                    img1: item.imgLinks?.length > 0 ? item.imgLinks[0] : "",
                    img2: item.imgLinks?.length > 1 ? item.imgLinks[1] : "",
                    img3: item.imgLinks?.length > 2 ? item.imgLinks[2] : "",
                    img4: item.imgLinks?.length > 3 ? item.imgLinks[3] : "",
                });
                placeName = item.name;
                place = {
                    _id: item.id,
                    name: item.name,
                    placeManagerId: item.placeManager.id,
                    coordinates: [item.coordinates[0], item.coordinates[1]],
                    calendarId: item.calendarId,
                    workspaceEmail: item.placeManager.workspaceEmail,
                };
            }
        });
        let collaboratorsValues: any = [];
        propsEvent.collaborators?.forEach((item: any) => {
            let collaborator = {
                key: item.text,
                name: GetPartepationType(item.text),
                value: item.n,
            };
            collaboratorsValues.push(collaborator);
        });

        setCollaborators(collaboratorsValues);
        setEventId(propsEvent.id ? propsEvent.id : "");
        setEvent({
            userId: propsEvent?.userId ? propsEvent?.userId : "",
            field: propsEvent?.field ? propsEvent?.field : "",
            type: propsEvent?.type ? propsEvent?.type : "",
            participationType: propsEvent?.participationType ? propsEvent.participationType : EventParticipation[0].type,
            title: propsEvent?.title ? propsEvent?.title : "",
            img: propsEvent?.img ? propsEvent?.img : "",
            description: propsEvent?.description ? propsEvent?.description : "",
            operatorId: propsEvent?.internalOperator?.id ? propsEvent?.internalOperator.id : "", // INTERNAL OPERATOR
            externalOperatorId: propsEvent?.externalOperator?.id ? propsEvent?.externalOperator.id : "", // EXTERNAL OPERATOR
            place: {
                _id: place._id,
                name: place.name,
                placeManagerId: place.placeManagerId,
                coordinates: place.coordinates,
                calendarId: place.calendarId,
                workspaceEmail: place.workspaceEmail,
            },
            collaborators: [],
            collaboratorsDescription: propsEvent?.textArea ? propsEvent?.textArea : "",
            listOfInvitedUser: propsEvent?.listOfInvitedUser ? propsEvent?.listOfInvitedUser : [],
            maxNumberOfUsers: propsEvent?.maxNumberOfUsers ? propsEvent?.maxNumberOfUsers : "",
            isSubEvent: propsEvent?.isSubEvent ? propsEvent?.isSubEvent : false,
            correlatedEventId: propsEvent?.correlatedEventId ? propsEvent?.correlatedEventId : "",
            groceryList: propsEvent?.groceryList ? propsEvent?.groceryList : [""],
            menu: {
                appetizer: propsEvent?.menu?.appetizer ? propsEvent?.menu?.appetizer : "",
                mainCourse: propsEvent?.menu?.mainCourse ? propsEvent?.menu?.mainCourse : "",
                secondCourse: propsEvent?.menu?.secondCourse ? propsEvent?.menu?.secondCourse : "",
                dessert: propsEvent?.menu?.dessert ? propsEvent?.menu?.dessert : "",
                other: propsEvent?.menu?.other ? propsEvent?.menu?.other : "",
            },
            startDate: propsEvent?.startDate ? propsEvent?.startDate : null,
            endDate: propsEvent?.endDate ? propsEvent?.endDate : null,
            status: propsEvent.status,
        });
        setValidDate(propsEvent?.startDate ? true : false);

        if (propsEvent?.groceryList) {
            let grocery = "";
            propsEvent?.groceryList.forEach((item, index) => {
                if (item !== "" && propsEvent && propsEvent.groceryList) {
                    index === propsEvent.groceryList.length - 1 ? (grocery = grocery + item) : (grocery = grocery + item + ", ");
                }
            });
            setGroceryList(grocery);
        }
        setImage({ img: propsEvent.img, modified: false });
        setInternalOperator({ firstName: propsEvent.internalOperator?.firstName, lastName: propsEvent.internalOperator?.lastName });
        setExternalOperator({ firstName: propsEvent.externalOperator?.firstName, lastName: propsEvent.externalOperator?.lastName });

        MacroAreaType.forEach((item: any) => {
            item.type === propsEvent?.field ? setMacroArea({ key: item.type, label: item.label }) : null;
        });

        EventType.forEach((item: any) => {
            item.type === propsEvent?.type ? setTypology({ key: item.type, label: item.label }) : null;
        });

        setInvitedUserIds({ items: propsEvent.listOfInvitedUser });

        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
    };

    React.useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        dayjs.extend(require("dayjs/plugin/localeData"));
        dayjs.extend(require("dayjs/plugin/customParseFormat"));
        scrollRefScreen != null ? scrollToTop() : null;
        flow();
    }, [props]);

    return {
        isReadOnly, pageSelected, macroArea, role,
        eventId, event, setEvent, saveEvent,
        loaderVisible, setLoaderVisible,
        basicAlertValues, setBasicAlertValues,
        errorToastVisible, setErrorToastVisible,
        deleteAlert, setDeleteAlert,
        setScrollRefScreen, clearFields,
        calendarEventId,

        // FIRST PAGE
        placesList, setPlacesList,
        typology, setTypology,
        validDate, setValidDate,
        image, setImage, uploadImage,
        handleFirstPageForwardButton,
        setPlaceImages, placeImages,


        // SECOND PAGE
        menuIndex, setMenuIndex,
        handleSecondPageBackButton, handleSecondPageForwardButton,
        firstRender, setFirstRender,
        groceryList, setGroceryList,
        propsEvent,
        collaborators, setCollaborators,
        internalOperator, setInternalOperator,
        externalOperator, setExternalOperator,
        internalUsers, externalUsers,
        
        // THIRD PAGE
        invitedUserIds, setInvitedUserIds,
        createSubEvent, setCreateSubEvent,
        handleThirdPageBackButton
    };
};

export default useCreateEventFoodController;

