import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faCircleCheck, faInfoCircle, faShare, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { AlertTypes } from "../../utils/AlertTypes";import Button from "../Button";

import { ShareOptions, socialSharing } from "../../utils/SocialShareUtils/SocialShare";
import { anzituttoLink } from "../../utils/SocialShareUtils/SocialShareUtils";

interface props {
	title: string;
	description: string;
	buttonText: string;
	alertType: AlertTypes;
	alertVisible: boolean;
	setAlertVisible: (isVisible: boolean) => any;

	//has second button
	secondButtonText?: string;
	secondButtonAction?: () => any;
	styleSecondButton?: any;

	//social share
	socialShareEnable?: boolean;
	socialSharing?: {
		title?: string;
		message?: string;
	}
}

const BasicAlert = (props: props) => {
	const getIconColor = (): string => {
		let color = colors.black;
		switch (props.alertType) {
			case AlertTypes.success:
				color = colors.green;
				break;
			case AlertTypes.error:
				color = colors.red3;
				break;
			case AlertTypes.info:
				color = colors.blue3;
				break;
			case AlertTypes.warning:
				color = colors.yellow;
				break;
		}
		return color;
	};

	const getIcon = (): IconProp => {
		let icon: IconProp = faCheckCircle;
		switch (props.alertType) {
			case AlertTypes.success:
				icon = faCheckCircle;
				break;
			case AlertTypes.error:
				icon = faWarning;
				break;
			case AlertTypes.info:
				icon = faInfoCircle;
				break;
			case AlertTypes.warning:
				icon = faWarning;
				break;
		}
		return icon;
	};

	const share = async () => {
		if (props.socialSharing && props.socialSharing.title) {
			const shareOptions: ShareOptions = {
				title: props.socialSharing.title,
				message: props.socialSharing.message ?? "",
				url: anzituttoLink,
				social: '',
			};
			socialSharing(shareOptions);
		}
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.alertVisible} onRequestClose={() => props.setAlertVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setAlertVisible(false)} />
			<View style={styles.container}>
				<View style={styles.content}>
					<FontAwesomeIcon size={50} color={getIconColor()} icon={getIcon()}></FontAwesomeIcon>
					<Text style={styles.title}>{props.title}</Text>
					<Text style={styles.text}>{props.description}</Text>

					{ 	!!props.socialShareEnable && 
						<>
							<Text style={styles.share}>{strings.shareWithFriends}</Text>
							<View style={styles.shareIconsContainer}>
								<Pressable onPress={() => share()}>
									<FontAwesomeIcon style={styles.shareIcon} size={20} icon={faShare}></FontAwesomeIcon>
								</Pressable>
							</View>
						</>
					}

					{	!!props.secondButtonText && !!props.secondButtonAction ?
						<View style={styles.buttonsContainer}>
							<Button
								style={{ marginRight: 5 }} 
								handleButtonPress={() => props.setAlertVisible(false)} 
								text={props.buttonText} 
								filledButton={true} 
							/>
							<Button 
								handleButtonPress={() => {
									!!props.secondButtonAction ? props.secondButtonAction() : null;
									props.setAlertVisible(false)
								}} 
								text={props.secondButtonText}
								style={[props.styleSecondButton && props.styleSecondButton, { marginLeft: 5 }]}
								filledButton={true}
							/>
						</View>	
						:
						<Button handleButtonPress={() => props.setAlertVisible(false)} text={props.buttonText} filledButton={true} />
					}
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 30,
		paddingBottom: 50,
		width: Platform.OS === "web" ? 500 : "80%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: 20,
		marginTop: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	buttonsContainer: {
		flexDirection: 'row',
		justifyContent: 'center'
	},
	share: {
		fontSize: 18,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	button: {
		marginTop: Platform.OS === "web" ? "1%" : "5%",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
	},
	shareIconsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: "2%",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
	},
	shareIcon: {
		margin: 10,
		height: 50,
		width: 50,
	},
});

export default BasicAlert;
