import { Text, View, Image, Platform, Pressable, AppState, AppStateStatus } from "react-native";
import { styles } from "../styles/Home.style";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import React, { useRef, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { EndPoints } from "../services/EndPoints";
import { ScrollView } from "react-native-gesture-handler";
import { strings } from "../locales/strings";
import { images } from "../resources/images";
import { dashboardType } from "../utils/DashboardType";
import Loader from "../components/Loader";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import { FirebaseMessaging } from "../services/FirebaseMessaging";
import { UsersEndpoints } from "../services/UsersEndpoints";
import { NotificationEndPoints } from "../services/NotificationEndPoint";
import Footer from "../components/Footer";
import { PageNames } from "../navigation/NavigationUtils";
import { EventsEndPoint } from "../services/EventsEndPoint";
import { EVENTS_STATUS } from "./DashboardScreen";

interface PropsInterface {
	navigation: any;
}

const HomeScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [isLogged, setIsLogged] = useState(false);
	const [hasFieldEvents, setHasFieldEvents] = useState<Record<string, boolean>>({});
	const appState = useRef(AppState.currentState);

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const _handleAppStateChange = async (nextAppState: AppStateStatus) => {
		appState.current = nextAppState;
		if (appState.current.match("inactive")) {
			checkRememberMe();
		}
	};

	const checkRememberMe = async () => {
		await AsyncStorage.getItem(asyncStorageUtils.rememberMe).then((value: any) => {
			value !== asyncStorageUtils.rememberMe ? logout() : null;
		});
	};

	React.useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		const unsubscribe = props.navigation.addListener("focus", async () => {
			const accessToken = await AsyncStorage.getItem(asyncStorageUtils.accessToken);
			accessToken != null ? getProfile() : setIsLogged(false);

			if (accessToken != null) {
				getHasFieldEvents();
			}
		});
		const subscription = AppState.addEventListener("change", _handleAppStateChange);
		return unsubscribe;
	}, [props]);

	const getHasFieldEvents = async () => {
		setLoaderVisible(true);
		try {
			const response = await EventsEndPoint.hasFieldEvents({
				dashboardType: [...Object.keys(dashboardType)],
				status: EVENTS_STATUS,
				ended: false,
			});

			setHasFieldEvents(response.item);
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.ok });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const getProfile = async () => {
		setIsLogged(true);
		setLoaderVisible(true);
		try {
			const res = await EndPoints.getProfile();
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}

			setFirstName(res.item.firstName);
			setLastName(res.item.lastName);
			await AsyncStorage.setItem(asyncStorageUtils.name, res.item.firstName + " " + res.item.lastName);
			await AsyncStorage.setItem(asyncStorageUtils.notificationsActive, String(res.item.settings?.notificationsActive!));

			NotificationEndPoints.getCountNotifications().then((res) => {
				const countNotifications = res.item?.notifications;

				AsyncStorage.setItem(asyncStorageUtils.inviteCount, String(countNotifications));
			});

			const token = await FirebaseMessaging.getToken();
			if (token) {
				await UsersEndpoints.addFirebaseTokens(token);
			}
		} catch (e: any) {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			logout();
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		}
	};

	const logout = async () => {
		setLoaderVisible(true);
		try {
			await EndPoints.logOut();
		} catch (error) { }
		if (Platform.OS === "ios") {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		} else {
			setLoaderVisible(false);
		}
		AsyncStorage.clear();
		setIsLogged(false);
	};

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const redirectIfIsLogged = async (dashboardType: string) => {
		if (!isLogged) {
			setBasicAlertValues({
				isVisible: true,
				title: strings.mustLogin.title,
				description: strings.mustLogin.description,
				type: AlertTypes.error,
				buttonText: strings.close,
			});
			return;
		}

		if (!hasFieldEvents[dashboardType]) {
			setBasicAlertValues({
				isVisible: true,
				title: strings.noAvailableEvents.title,
				description: strings.noAvailableEvents.description,
				type: AlertTypes.info,
				buttonText: strings.close,
			});
			return;
		}

		props.navigation.navigate(PageNames.dashboard, { dashboardType: dashboardType });
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				onPress={() => props.navigation.navigate(PageNames.home)}
				showMenu={isLogged}
				showLoginButton={!isLogged}
				setIsLogged={(isLogged: boolean) => setIsLogged(isLogged)}
				hideButton = {true}		
			/>
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<ScrollView ref={(c) => setScrollRefScreen(c)}>
				<View style={[styles.container, { flexDirection: isWeb ? "row" : "column" }]}>
					<Pressable style={styles.card} onPress={() => redirectIfIsLogged(dashboardType.food)}>
						<Image resizeMode="cover" style={styles.coverImage} source={images.foodImage}></Image>
						<View style={styles.cardContent}>
							<Text style={styles.cardLabel}>{strings.food.label}</Text>
							<Text style={styles.cardTitle}>{strings.food.title}</Text>
							<Text style={styles.cardDescription}>{strings.food.description}</Text>
						</View>
					</Pressable>

					<Pressable style={[styles.card]} onPress={() => redirectIfIsLogged(dashboardType.wellness)}>
						<Image resizeMode="cover" style={styles.coverImage} source={images.wellnessImage}></Image>
						<View style={styles.cardContent}>
							<Text style={styles.cardLabel}>{strings.wellness.label}</Text>
							<Text style={styles.cardTitle}>{strings.wellness.title}</Text>
							<Text style={styles.cardDescription}>{strings.wellness.description}</Text>
						</View>
					</Pressable>

					<Pressable style={[styles.card, styles.disabledCard]} disabled={true} onPress={() => redirectIfIsLogged(dashboardType.culture)}>
						<Image resizeMode="cover" style={styles.coverImage} source={images.cultureImage}></Image>
						<View style={styles.cardContent}>
							<Text style={styles.cardLabel}>{strings.culture.label}</Text>
							<Text style={styles.cardTitle}>{strings.culture.title}</Text>
							<Text style={styles.cardDescription}>{strings.culture.description}</Text>
						</View>
					</Pressable>
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={isLogged} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default HomeScreen;
