import { View, Platform } from "react-native";
import Svg, { Image } from 'react-native-svg'
import { images } from "../../resources/images";
import IcPeople from '../../assets/images/ic_people.svg';
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";


interface Props {
	color?: string;
	size?: number;
}

const PeopleIcon = ({ size = 24, color }: Props) => {
    return (
        <View>
            {   Platform.OS === 'web' ?
                <Svg width={`${size}`} height={`${size}`} fill={!!color ? color : colors.black}>
                    <Image width={size} height={size} href={images.icPeople} opacity={!!color ? 1 : 0.5}/>
                </Svg>
                :
                <IcPeople width={size} height={size} fill={!!color ? color : colors.black} opacity={!!color ? 1 : 0.5}/>
            }
        </View>
    );
};  
export default PeopleIcon;