import React from "react";
import { Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { styles } from "../../styles/CreateEventScreen";
import Button from "../Button";

interface PropsInterface {
	saveDraft: () => void;
	disabled: boolean;
	label: string;
}

const SaveDraftComponent = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	return (
		<View style={styles.saveDraftContainer}>
			<Text style={[styles.description, { marginBottom: 20, marginTop: 0 }]}>{strings.saveAsDraftQuestion}</Text>
			<Button
				disabled={props.disabled}
				textStyle={{ paddingLeft: 30, paddingRight: 30 }}
				handleButtonPress={() => props.saveDraft()}
				text={props.label}
				filledButton={false}
			/>
		</View>
	);
};

export default SaveDraftComponent;
