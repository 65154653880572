import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
    safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
    container: {
		backgroundColor: colors.primary,
		marginLeft: "5%",
		marginRight: "5%",
		borderRadius: 10,
		paddingBottom: "5%",
		display: "flex",
        flexDirection: "column"
	},
    titleContainer: {
		flexDirection: "row",
		height: 30,
        marginTop: 15,
	},
    textInputTitle: {
		marginHorizontal: 16,
		fontSize: 16,
		fontFamily: fonts.RalewayBold
	},
    inputContainer: {
		backgroundColor: colors.white,
		// width: '90%',
		marginHorizontal: 16,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
	},
	inputContainerError: {
		backgroundColor: colors.white,
		// width: '90%',
		marginHorizontal: 16,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.red,
		borderWidth: 1,
	},
    textInput: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
        height: 50, 
	},
    textAreaInput: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		padding: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
        height: 150, 
	},
    checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		margin: '2%'
	},
	checkboxText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.grey4,
		marginLeft: 10,
	},
	errorText: {
		color: colors.red,
		marginLeft: "1.5%",
		marginTop: "0.5%",
		fontFamily: fonts.RalewayRegular,
	},
});
