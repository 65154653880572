
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { strings } from '../../../../../locales/strings';
import { AlertTypes } from '../../../../../utils/AlertTypes';
import { SecondPageViewModel } from '../../../../../models/createEventModels';
import { useNavigation } from '@react-navigation/native';


const useSecondPageController = ({ propsEvent }: SecondPageViewModel) => {

    const navigation = useNavigation<any>();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
    }, []);

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    const [setDeleteAlert, deleteAlert] = useState(false)

    const [scrollRef, setScrollRef] = useState(null);
    const [scrollX, setScrollX] = useState<number>(0);
    const [editActivity, setEditActivy] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

   
    return {
        navigation, isWeb,
        scrollRef, setScrollRef,
        scrollX, setScrollX,
        editActivity,
        setEditActivy,
        setIsModalVisible,
        isModalVisible,
        setDeleteAlert, deleteAlert,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
    };
};

export default useSecondPageController;

