import { View, Platform, StyleProp, ViewStyle } from "react-native";
import Svg, { Image } from "react-native-svg";
import { images } from "../../resources/images";
import IcVideocam from "../../assets/images/ic_videocam.svg";
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";

interface Props {
	color?: string;
	size?: number;
	style?: StyleProp<ViewStyle> | undefined;

}

const VideochatIcon = ({ size = 24, color,style }: Props) => {
	return (
		<View style={style}>
			{Platform.OS === "web" ? (
				<Svg width={`${size}`} height={`${size}`} fill={!!color ? color : colors.black}>
					<Image 
					width={size} height={size} href={images.icVideocam} />
				</Svg>
			) : (
				<IcVideocam width={size} height={size} fill={!!color ? color : colors.black} />
			)}
		</View>
	);
};
export default VideochatIcon;