import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: Platform.OS === "web" ? 24 : 20,
		fontFamily: fonts.RalewayBold,
		marginTop: Platform.OS === "web" ? 30 : 0,
		marginRight: "5%",
		marginLeft: "5%",
	},
	rowView: {
		flexDirection: "row",
	},
	webMapContainer: {
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: 30,
		marginBottom: "10%",
		backgroundColor: colors.white,
	},
	mobileContainer: {
		borderRadius: 10,
		backgroundColor: colors.white,
		marginTop: Platform.OS === "web" ? 10 : -5,
	},
	mobileMapContainer: {
		marginTop: Platform.OS === "web" ? 30 : 0,
	},
	placesWeb: {
		width: "40%",
		height: "100%",
	},
	placesMobile: {
		width: "100%",
		backgroundColor: colors.white,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10
	},
	mapWeb: {
		backgroundColor: colors.grey,
		height: "100%",
		width: "100%",
	},
	mapWebContainer: {
		backgroundColor: colors.grey,
		height: "100%",
		width: "60%",
	},
	mapMobile: {
		backgroundColor: colors.grey,
		width: "100%",
	},
	searchbarContainer: {
		backgroundColor: colors.white,
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderColor: colors.grey4,
		height: 50,
	},
	placesTitle: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		color: colors.blue3,
		marginTop: 30,
		paddingLeft: "2%",
		paddingRight: "2%",
		marginLeft: Platform.OS === "web" ? "2%" : 0,
		marginRight: Platform.OS === "web" ? "2%" : 0,
	},
	filterList: {
		marginTop: Platform.OS === "web" ? "5%" : 10,
		marginLeft: Platform.OS === "web" ? "2%" : 0,
		marginRight: Platform.OS === "web" ? "2%" : 0,
	},
	map: {
		width: 500,
		height: 500,
	},
	placeContainer: {
		borderTopColor: colors.grey,
		borderTopWidth: 1,
		paddingTop: 20,
		paddingBottom: 20,
		paddingLeft: Platform.OS === "web" ? 10 : 0,
		paddingRight: Platform.OS === "web" ? 10 : 0,
	},
	placeName: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		color: colors.blue2,
		paddingLeft: "2%",
		paddingRight: "2%",
	},
	placeAddress: {
		fontSize: 12,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		paddingTop: 5,
		paddingLeft: "2%",
		paddingRight: "2%",
	},
	openEvent: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.blue,
		paddingTop: 10,
		paddingLeft: "2%",
		paddingRight: "2%",
		textDecorationLine: "underline",
	},
	placeAddress2: {
		fontSize: 12,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
	},
	back: {
		marginLeft: 10,
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	webImageContainer: {
		width: "100%",
		height: 130,
	},
	mobileImageContainer: {
		width: "95%",
		marginLeft: 10,
		marginRight: 10,
		marginTop: 30,
		height: 130,
	},
	image: {
		height: "100%",
		width: "100%",
	},
	backContainer: {
		margin: 20,
	},
	placeInfo: {
		marginTop: Platform.OS === "web" ? 20 : 0,
		marginBottom: 20,
		paddingBottom: 20,
		paddingRight: Platform.OS === "web" ? 10 : 0,
		paddingLeft: Platform.OS === "web" ? 10 : 0,
		borderColor: colors.grey,
		borderBottomWidth: 1,
	},
	eventsButtonsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginBottom: 30,
	},
	placeInfoTitle: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		color: colors.blue3,
		paddingLeft: 10,
		marginTop: 10,
	},
	placeInfoDescription: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginLeft: 10,
		marginTop: 10,
	},
	mobileSearchbar: {
		position: "absolute",
		zIndex: 9999,
		justifyContent: "center",
		width: "90%",
		marginTop: Platform.OS === "web" ? 0 : 80,
		marginLeft: "5%",
		marginRight: "5%",
	},
	emptyState: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		color: colors.black,
		textAlign: "center",
		paddingTop: "35%",
		borderTopColor: colors.grey,
		borderTopWidth: 1,
		alignContent: "center",
		textAlignVertical: "center",
	},
	expandIcons: {
		paddingTop: Platform.OS === "web" ? 10 : 30,
		paddingBottom: Platform.OS === "web" ? 10 : 30,
		alignSelf: "center",
	},
	loadMore: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		color: colors.blue,
		textDecorationLine: "underline",
		marginLeft: 10,
		marginTop: 30,
		textAlign: "center",
		marginBottom: 30,
	},
	searchbarMobile: {
		zIndex: 9999,
		justifyContent: "center",
		width: "90%",
		marginLeft: "4%",
		marginRight: "4%",
		marginBottom: 10
	},
	dateEventTag: {
		paddingLeft: "2%",
		paddingRight: "2%",
	},
	openCalendarButton: { 
		marginTop: 15, 
		marginBottom: 20
	}
});
