import { Text, View, Platform, Switch } from "react-native";
import { styles } from "../styles/Settings.style";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import React, { useState } from "react";
import { EndPoints } from "../services/EndPoints";
import { ScrollView } from "react-native-gesture-handler";
import { strings } from "../locales/strings";
import Loader from "../components/Loader";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import { colors } from "../resources/colors";
import PageNavigation from "../components/PageNavigation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { then } from "../metro.config";
import { PageNames } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";

interface PropsInterface {
	navigation: any;
}

const SettingsScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	React.useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}

		AsyncStorage.getItem(asyncStorageUtils.notificationsActive).then((notificationActive) => {
			setAbleNotifications(notificationActive == "true");
		});
	}, [props]);

	const [ableNotifications, setAbleNotifications] = useState(false);

	const enableNotifications = async (enable: boolean) => {
		setLoaderVisible(true);
		AsyncStorage.setItem(asyncStorageUtils.notificationsActive, String(enable));
		EndPoints.manageSettings(enable)
			.then((res: any) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			})
			.catch((e: any) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
		setAbleNotifications(enable);
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				onPress={() => props.navigation.navigate(PageNames.home)}
				showMenu={true}
				showLoginButton={false}
			/>
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<ScrollView style={{ paddingTop: 30 }}>
				{isWeb ? (
					<>
						<PageNavigation/>
					</>
				) : null}

				<Text style={[styles.pageTitle, { marginTop: isWeb ? 50 : 10, marginBottom: 20 }]}>{strings.settings}</Text>
				<Text style={styles.pageDescription}>{strings.settingsDescription}</Text>

				<View style={[styles.container]}>
					{/* Notifications */}
					<View style={{ padding: 20 }}>
						<Text style={styles.title}>{strings.notificationTitle}</Text>
						<View style={[styles.rowView, isWeb ? null : { justifyContent: "space-between" }]}>
							<Text style={[styles.description, { marginRight: 20 }]}>{strings.enableNotifications}</Text>
							<Switch
								trackColor={{ false: colors.primary, true: colors.blue3 }}
								thumbColor={ableNotifications ? colors.blue : colors.primary}
								ios_backgroundColor={colors.primary}
								onValueChange={() => enableNotifications(!ableNotifications)}
								value={ableNotifications}
							/>
						</View>
					</View>
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation}/>
			</ScrollView>
		</SafeAreaView>
	);
};

export default SettingsScreen;
