import dayjs from "dayjs";
import { CalendarEvents, EventActivity, PlacesAvailabilityResponse } from "../models/eventsModels";
import { colors } from "../resources/colors";
import { CalendarEventWeb } from "../models/calendarEventsModels";

export class CalendarUtils {
	static unavailability = { key: "unavailability", color: colors.grey, selectedDotColor: colors.grey };
	static event = { key: "event", color: colors.primary, selectedDotColor: colors.primary };


	static parseEventsWeb = (items: PlacesAvailabilityResponse[]): CalendarEventWeb[] => {
		const events: CalendarEventWeb[] = [];
		items.forEach((el: PlacesAvailabilityResponse) => {
			events.push({
				start: new Date(el.eventStart),
				end: new Date(el.eventEnd),
				id: el.id,
				title: el.summary
			})
		})
		return events;
	}

	static parsedActivityWeb = (items:EventActivity[]) => {
		const activity: { start: Date; end: Date; id: string; title: string; }[] = [];
		items.forEach((el: EventActivity) => {
			activity.push({
				start: new Date(el.startDate),
				end: new Date(el.endDate),
				id: el._id!,
				title: el.title
			})
		})
		return activity;
	}

	static parseEventsMobile = (items: PlacesAvailabilityResponse[]): CalendarEvents => {
		let events: CalendarEvents = {};
		items.forEach((el: PlacesAvailabilityResponse) => {
			events[dayjs(el.eventStart).format("YYYY-MM-DD").toString()] = [{
				name: el.summary,
				start: dayjs(el.eventStart).format("YYYY-MM-DD hh-mm-ss"),
				end: dayjs(el.eventEnd).format("YYYY-MM-DD hh-mm-ss"),
				type: "event"
			}]
		})
		return events;
	}

	static parseMarkedDate = (items: PlacesAvailabilityResponse[]): any => {
		const events: any = {};
		items.forEach((el: PlacesAvailabilityResponse) => {
			events[dayjs(el.eventStart).format("YYYY-MM-DD")] = {
				marked: true, dotColor: colors.blue, activeOpacity: 0
			}
		})
		return events;
	}
}
