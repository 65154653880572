export enum EventStatus {
	draft = "draft",
	to_be_approved = "to_be_approved",
	wait_internal_operator = "wait_internal_operator",
	wait_external_operator = "wait_external_operator",
	approved = "approved",
	deleted = "deleted",
}

export enum EventActivityStatus {
	WAIT_EXTERNAL_OPERATOR = "wait_external_operator",
	APPROVED = "approved",
}

export interface respStatus  {
	status: string;
	item: {
		status: EventActivityStatus;
		eventStatus?:EventStatus,

	};
}

export enum ActivityStatus {
	wait_external_operator = "wait_external_operator",
	approved = "approved",
}
