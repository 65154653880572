import { EntitiesResponse, EntityResponse, PagedResponse } from "../models/exchange";
import { FollowUser, Profile } from "../models/models";
import { roles } from "../utils/Roles";
import { APIBaseService } from "./ApiBaseService";

export class UsersEndpoints extends APIBaseService {
	public static async getUser(userId: string): Promise<EntityResponse<Profile>> {
		return await APIBaseService.performRequest<EntityResponse<Profile>>("get", `${APIBaseService.baseUrl}/user/profile/${userId}`, false);
	}

	public static async getUsers(
		page: number,
		pageSize: number,
		searchTerm: string,
		sorting: string,
		roles?: string[],
		followType?: "following" | "followers" | "unFollowing",
		includeCurrent?: boolean
	): Promise<PagedResponse<Profile>> {
		const search = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : "";
		const follow = followType ? `&followType=${followType}` : "";
		const _roles = roles?.length ? `&roles=${roles.join(",")}` : "";
		const _includeCurrent = includeCurrent ? `&includeCurrent=${includeCurrent}` : "";
		return await APIBaseService.performRequest<PagedResponse<Profile>>(
			"get",
			`${APIBaseService.baseUrl}/user/list?page=${page}&pageSize=${pageSize}&order=${sorting}${search}${follow}${_roles}${_includeCurrent}`,
			false
		);
	}


	public static async getUserFollows(searchTerm: string): Promise<EntitiesResponse<FollowUser[]>> {
		const search = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : "";
		return await APIBaseService.performRequest<EntitiesResponse<FollowUser[]>>("get", `${APIBaseService.baseUrl}/user/follows`, false);
	}

	public static async updateUserFollow(body: { userId: string; type: "follow" | "unfollow" }): Promise<EntityResponse<Profile>> {
		return await APIBaseService.performRequest<EntityResponse<Profile>>("patch", `${APIBaseService.baseUrl}/user/follows`, false, body);
	}

	public static async getUserByRole(role: roles): Promise<any> {
		return await APIBaseService.performRequest<any>("get", `${APIBaseService.baseUrl}/user/${role}`, false);
	}

	public static async addFirebaseTokens(token: string): Promise<any> {
		return await APIBaseService.performRequest<any>("post", `${APIBaseService.baseUrl}/user/token`, false, { token });
	}
}
