import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import AsyncStorage from "@react-native-async-storage/async-storage";
import dayjs from "dayjs";
import { nativeApplicationVersion } from "expo-application";
import { useEffect, useState } from "react";
import { Modal, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { PageNames } from "../../navigation/NavigationUtils";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { EndPoints } from "../../services/EndPoints";
import { NotificationEndPoints } from "../../services/NotificationEndPoint";
import { asyncStorageUtils } from "../../utils/AsyncStorageUtils";
import { roles } from "../../utils/Roles";
import Button from "../Button";
import CloseIcon from "../CloseIcon";
import Loader from "../Loader";

interface props {
	name: string;
	setMenuVisible: (isVisible: boolean) => any;
	isVisible: boolean;
	navigation: any;
	setShowMenu: (isVisible: boolean) => any;
	setShowLogin: (isVisible: boolean) => any;
}

const Menu = (props: props) => {
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [countNotifications, setCountNotifications] = useState(0);

	const logOut = async () => {
		setLoaderVisible(true);
		try {
			await EndPoints.logOut();
		} catch (error) {}
		if (Platform.OS === 'ios') {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		} else {
			setLoaderVisible(false);
		}
		await AsyncStorage.clear();
		props.setMenuVisible(false);
		props.setShowMenu(false);
		props.setShowLogin(true);
		props.navigation.navigate(PageNames.home);
	};

	useEffect(() => {
		checkRole();

		AsyncStorage.getItem(asyncStorageUtils.inviteCount).then((count) => {
			setCountNotifications(parseInt(count ?? "0"));
		});
	}, [props]);

	const checkRole = async () => {
		const role = await AsyncStorage.getItem(asyncStorageUtils.role);
		setShowPlaces(role === roles.serviceCoordinator || role === roles.placeManager);
		setShowSignUpOperator(role === roles.serviceCoordinator);
		setShowInvites(role === roles.user);
	};

	const [showPlaces, setShowPlaces] = useState(false);
	const [showSignUpOperator, setShowSignUpOperator] = useState(false);
	const [showInvites, setShowInvites] = useState(false);

	const navigate = (page: string) => {
		props.navigation.navigate(page);
		props.setMenuVisible(false);
	};

	const markInvitesAsRead = async () => {
		await NotificationEndPoints.deleteNotifications();
		setCountNotifications(0);
		AsyncStorage.setItem(asyncStorageUtils.inviteCount, "0");
	};

	const User = () => {
		return (
			<View style={{ width: 140 }}>
				<Text style={styles.welcome}>{strings.hello + ","}</Text>
				<Text style={styles.title}>{props.name}</Text>
				{Platform.OS === "web" ? <CloseIcon pressHandler={() => props.setMenuVisible(false)} /> : null}
			</View>
		);
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.isVisible} onRequestClose={() => props.setMenuVisible(false)}>
			<View style={styles.container}>
				<Pressable style={[styles.backdrop, Platform.OS === "web" ? null : styles.overlay]} onPress={() => props.setMenuVisible(false)} />
				<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />
				<View style={styles.content}>
					<View style={styles.row}>
						{Platform.OS !== "web" ? <CloseIcon pressHandler={() => props.setMenuVisible(false)} /> : null}
						{Platform.OS === "web" ? <User /> : null}

						<View style={Platform.OS === "web" ? { width: 110 } : { width: "100%" }}>
							{Platform.OS === "web" ? (
								<View style={styles.todayView}>
									<Text style={[styles.welcome, { textAlign: "right" }]}>{strings.todayIs + " :"}</Text>
									<Text style={[styles.title, { textAlign: "right" }]}>{dayjs(new Date()).format("DD MMMM")}</Text>
								</View>
							) : null}
							<View style={styles.greyBorder}>
								{Platform.OS !== "web" ? <User /> : null}
								<Pressable onPress={() => navigate(PageNames.profile)}>
									<Text style={[styles.text, { marginTop: 10 }]}>{strings.profile}</Text>
								</Pressable>
								<Pressable onPress={() => {
										props.navigation.navigate(PageNames.calendar, { isMyCalendar: true });
										props.setMenuVisible(false);
									}}>
									<Text style={styles.text}>{strings.myCalendarMenu}</Text>
								</Pressable>
								<Pressable onPress={() => navigate(PageNames.users)}>
									<Text style={styles.text}>{strings.usersList}</Text>
								</Pressable>
								<Pressable
									onPress={() => {
										props.navigation.navigate(PageNames.yourEvents, { filterSelected: { type: "all", label: strings.all } });
										props.setMenuVisible(false);
									}}
								>
									<Text style={styles.text}>{strings.yourEvents}</Text>
								</Pressable>

								{showInvites ? (
									<Pressable
										onPress={() => {
											props.navigation.navigate(PageNames.yourEvents, { filterSelected: { type: "all", label: strings.all }, eventType: "invited" });
											props.setMenuVisible(false);
											markInvitesAsRead();
										}}
									>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<Text style={styles.text}>{strings.receivedInvites}</Text>
											{countNotifications ? (
												<View style={styles.circle}>
													<Text style={styles.circleText}> {countNotifications}</Text>
												</View>
											) : null}
										</View>
									</Pressable>
								) : null}

								{showSignUpOperator ? (
									<Pressable
										onPress={() => {
											props.navigation.navigate(PageNames.signup, { signupFromCoordinator: true });
											props.setMenuVisible(false);
										}}
									>
										<Text style={styles.text}>{strings.signupOperator}</Text>
									</Pressable>
								) : null}

								<Pressable onPress={() => navigate(PageNames.settings)}>
									<Text style={styles.text}>{strings.settings}</Text>
								</Pressable>

								<Button
									style={[{ marginTop: 20, width: 100 }, Platform.OS === "web" ? {} : { alignSelf: "flex-start" }]}
									handleButtonPress={() => logOut()}
									text={strings.logout}
									filledButton={false}
								/>
							</View>
							{Platform.OS !== "web" ? (
								<Text style={styles.version}>
									{strings.version}: {nativeApplicationVersion}
								</Text>
							) : null}
						</View>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "flex-start",
		alignItems: "flex-end",
		flex: 1,
	},
	content: {
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		margin: Platform.OS === "web" ? 0 : 20,
		marginRight: Platform.OS === "web" ? "5%" : 20,
		marginTop: Platform.OS === "web" ? 25 : 50,
		padding: 5,
		flexDirection: "row",
	},
	row: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: Platform.OS === 'web' ? 12 : 14,
		textAlign: "left",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginLeft: 5,
		marginRight: 5,
	},
	text: {
		fontSize: Platform.OS === 'web' ? 12 : 14,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.blue,
		margin: 5,
	},
	welcome: {
		fontSize: Platform.OS === 'web' ? 12 : 14,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
		margin: 5,
		paddingTop: 5,
	},
	todayView: {
		backgroundColor: colors.primary,
		borderRadius: 10,
		paddingBottom: 10,
		marginBottom: 10,
	},
	greyBorder: {
		borderLeftColor: colors.grey,
		borderLeftWidth: Platform.OS === "web" ? 1 : 0,
		paddingLeft: 5,
		marginBottom: 10,
	},
	version: {
		color: colors.blue,
		textAlign: "right",
		marginRight: "5%",
		marginBottom: 10,
	},
	closeIcon: {
		position: "absolute",
		right: 5,
		top: 10,
		zIndex: 9999,
	},
	circle: {
		width: 18,
		height: 18,
		borderRadius: 18 / 2,
		backgroundColor: colors.red2,
	},
	circleText: {
		fontSize: 12,
		fontFamily: fonts.RalewayBold,
		color: colors.white,
		textAlign: "center",
		textAlignVertical: "center",
	},
});

export default Menu;
