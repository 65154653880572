import { faCheckCircle, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Platform, Modal, Pressable, Linking, Image } from "react-native";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { images } from "../resources/images";
import Button from "./Button";
import { ShareOptions, socialSharing } from "../utils/SocialShareUtils/SocialShare";
import { anzituttoLink } from "../utils/SocialShareUtils/SocialShareUtils";

interface profileCreatedProps {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	signupFromCoordinator: boolean;
}

const ProfileCreatedAlert = (props: profileCreatedProps) => {
	const share = async () => {
		const shareOptions: ShareOptions = {
			title: strings.socialShare,
			message: "",
			url: anzituttoLink,
			social: '',
		};
		socialSharing(shareOptions)
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => props.setModalVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setModalVisible(false)} />
			<View style={styles.container}>
				<View style={styles.content}>
					<View style={styles.icon}>
						<FontAwesomeIcon size={50} color={colors.green} icon={faCheckCircle} />
					</View>

					<Text style={styles.title}>{strings.profileCreated}</Text>
					<Text style={styles.description}>{strings.profileCreatedDescription}</Text>

					{props.signupFromCoordinator ? Platform.OS !== "web" : (
						<>
							<Text style={styles.share}>{strings.shareWithFriends}</Text>
							<View style={styles.shareIconsContainer}>
								<Pressable onPress={() => share()}>
									<FontAwesomeIcon style={styles.shareIcon} size={20} icon={faShare}></FontAwesomeIcon>
								</Pressable>
							</View>
						</>
					)}

					<Button handleButtonPress={() => props.setModalVisible(false)} text={strings.close} style={styles.button} filledButton={false} />
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		width: Platform.OS === "web" ? "40%" : "80%",
		borderRadius: 10,
		paddingTop: Platform.OS === "web" ? "1%" : "10%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	description: {
		fontSize: 16,
		textAlign: "center",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
		marginLeft: "5%",
		marginRight: "5%",
		color: colors.grey4,
		fontFamily: fonts.RalewayRegular,
	},
	share: {
		fontSize: 18,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	button: {
		marginTop: Platform.OS === "web" ? "1%" : "5%",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
	},
	icon: {
		alignSelf: "center",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
		marginTop: Platform.OS === "web" ? "1%" : "5%",
	},
	shareIconsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: "2%",
		marginBottom: Platform.OS === "web" ? "5%" : "10%",
	},
	shareIcon: {
		margin: 10,
		height: 50,
		width: 50,
	},
});

export default ProfileCreatedAlert;
