import { SelectOption } from '../components/Select';
import { strings } from '../locales/strings';
import { Event, EventActivity } from './eventsModels';

export enum PlaceType {
  CONVENTIONED = 'conventioned',
  OTHER = 'other'
}

export const placeTypeOptions: SelectOption<PlaceType>[] = [{
  label: strings.placeTypes.conventionedPlace,
  value: PlaceType.CONVENTIONED
}, {
  label: strings.placeTypes.otherPlace,
  value: PlaceType.OTHER
}];

export function getPlaceTypeValueFromEvent(event: Event | EventActivity): PlaceType | undefined {
  if(event.place?._id) {
    return PlaceType.CONVENTIONED;
  }

  if(event.placeFreeText) {
    return PlaceType.OTHER;
  }

  return undefined;
}

export function setPlaceTypeValue<T extends Event | EventActivity>(event: T, placeType: PlaceType) {
  switch (placeType) {
    case PlaceType.CONVENTIONED:
        delete event.placeFreeText;
        break;
    case PlaceType.OTHER:
        // Cannot just delete place otherwise everything breaks event though by type it can be undefined. Nice
        event.place = {
            _id: "",
            name: "",
            placeManagerId: "",
            coordinates: [null, null],
            calendarId: "",
            workspaceEmail: "",
        };
        break;
  }
}