import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	title: {
		fontSize: 24,
		marginTop: "2%",
		fontFamily: fonts.RalewayBold,
	},
	safeArea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		marginHorizontal: "5%",
		marginVertical: 3,
		marginBottom: "5%"
	},
	cardSignUp: {
		backgroundColor: colors.white,
		borderRadius: 10,
	},
	forwardButton: {
		alignSelf: "flex-end",
		marginRight: "5%",
		marginTop: 50,
		zIndex: 0,
	},
	loadPicture: {
		fontSize: 15,
		fontFamily: fonts.RalewayBold,
		marginTop: "5%",
	},
	profilePicture: {
		margin: "5%",
		width: 140,
		height: 140,
		borderRadius: 100,
	},
	profilePictureWebContainer: {
		marginLeft: "5%",
		marginRight: "5%",
		width: "90%",
		height: 500,
		backgroundColor: colors.grey3,
	},
	profilePictureWeb: {
		width: "50%",
		justifyContent: "center",
		alignSelf: "center",
	},
	buttonProfilePictureWebContainer: {
		width: "50%",
		marginRight: 5,
	},
	buttonProfilePictureWeb: {
		alignSelf: "flex-end",
		width: "80%",
		marginLeft: "10%",
		marginRight: "10%",
	},
	profilePictureMobile: {
		width: "50%",
		justifyContent: "center",
	},
	buttonProfilePictureMobile: {
		width: "100%",
		marginBottom: "3%",
	},
	noteWeb: {
		height: 80,
		marginBottom: "5%",
		marginTop: "5%",
		paddingTop: 10,
	},
	textInputTitle: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
	},
	datePicker: {
		width: 100,
		display: "flex",
	},
	errorText: {
		color: colors.red,
		marginLeft: "5%",
		fontFamily: fonts.RalewayRegular,
	},
	errorTextWeb: {
		color: colors.red,
		fontFamily: fonts.RalewayRegular,
		width: "45%",
	},
	gender: {
		fontFamily: fonts.RalewayRegular,
		marginHorizontal: 16,
		marginBottom: 10,
		alignContent: "flex-start",
		alignItems: "flex-start",
	},
	checkboxContainer: {
		marginLeft: "5%",
		marginRight: "5%",
		alignSelf: "flex-start",
		marginTop: 10,
		marginBottom: 10,
		width: "90%",
		flexDirection: "row",
	},
	checkBoxText: {
		fontSize: 16,
		color: colors.grey4,
		fontFamily: fonts.RalewayRegular,
		textDecorationLine: "underline",
		marginTop: 10,
	},
	checkbox: {
		marginTop: Platform.OS === "ios" ? "50%" : "4%",
		marginRight: "2%",
		height: 20,
		width: 20,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 10,
		marginRight: 10,
		zIndex: 0,
	},
	noMargin: {
		marginLeft: 0,
		marginRight: 0,
	},
	nameRow: {
		flexDirection: "row",
		justifyContent: "space-between",
		zIndex: 0,
	},

	externalView: {
		flexDirection: "row",
		justifyContent: "space-between",
		zIndex: 1,

	},


	select: {
		flexDirection: "column",
		width:"33%",

	},
	buttonsView: {
		width: "44%",
		marginLeft: "3%",
		marginRight: "3%",

	},
	alignCenter: {
		alignSelf: "center",
	},
	privacyPolicyContainer: {
		flexDirection: "row",
		justifyContent: "flex-start",
		marginTop: 50,
		marginBottom: 10,
	},
	radioButtonPrivacyPolicy: {
		marginLeft: "5%",
		marginRight: Platform.OS === "web" ? "3%" : 0,
	},
	imageSecondPageContainer: {
		position: "absolute",
		width: "50%",
		height: 635,
		right: -10,
		top: 0,
		borderBottomRightRadius: 10,
	},
	imageFirstPageContainer: {
		width: "100%",
		height: 722,
		position: "absolute",
		right: -10,
		top: -20,
	},
	imageFirstPageContainerCoordinator: {
		width: "100%",
		height: 663,
		position: "absolute",
		right: -10,
		top: -20,
		borderBottomRightRadius: 10,
		borderTopRightRadius: 10,
	},
	image: {
		width: "100%",
		height: "100%",
		borderBottomRightRadius: 10,
		borderTopRightRadius: 10,
	},
	titleContainer: {
		flexDirection: "row",
		marginTop: 20,
		marginHorizontal: 16,
	},
	asterisk: {
		fontFamily: fonts.RalewayBold,
		fontSize: 30,
		marginTop: -5,
		marginLeft: 5,
		color: colors.black,
	},
	scrollView: {
		paddingBottom: 50,
	},
	recapatcha: {
		fontSize: 18,
		textAlign: "center",
		color: colors.blue3,
		fontFamily: fonts.RalewayBold,
		marginTop: "5%",

	},
});
