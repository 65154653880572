import { useEffect, useState } from "react";
import { Platform, Pressable, Text, View, Image, Linking, Dimensions } from "react-native";
import { StyleSheet } from "react-native";
import { strings } from "../locales/strings";
import { ENUM_COLORS, Event, getFieldColor } from "../models/eventsModels";
import { colors } from "../resources/colors";
import { getDefaultImageByScope, getDefaultImageByType, getEventDate, getTypeString } from "../utils/EventUtils";
import Button from "./Button";
import Tag from "./Tag";
import { images } from "../resources/images";
import UsersIcon from "../components/Icons/UsersIcon";
import MoreButton from "./MoreButton";
import { fonts } from "../resources/fonts";
import { EventStatus } from "../utils/EventStatus";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import dayjs from "dayjs";
import { GetPartepationType } from "../utils/types/Partecipants";
import FollowUsersAlert from "./FollowUsersAlert";
import { PageNames } from "../navigation/NavigationUtils";
import { dashboardType } from "../utils/DashboardType";

interface Props {
	event: Event;
	navigation: any;
	canEdit?: boolean;
	edit?: () => void;
	backgroundColor?: string;
	tagsColor?: string;
	partecipants?: boolean;
	partecipantsText?: string;
	created?: boolean;
	isPastEvent?: boolean;
}

const EventCard = ({ event, navigation, canEdit, edit, backgroundColor, tagsColor = colors.primary, partecipants, partecipantsText, created, isPastEvent }: Props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [showInviteUsers, setShowInvitedUsers] = useState(false);

	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		checkPartecipants();
	}, [event]);

	const date = getEventDate(event);

	const getStatusLabel = (): string => {
		let statusLabel = "";
		switch (event.status) {
			case EventStatus.draft: {
				statusLabel = strings.singularEventStatus.draft;
				break;
			}
			case EventStatus.to_be_approved: {
				statusLabel = strings.singularEventStatus.to_be_approved;
				break;
			}
			case EventStatus.wait_internal_operator: {
				statusLabel = strings.singularEventStatus.wait_internal_operator;
				break;
			}
			case EventStatus.wait_external_operator: {
				statusLabel = strings.singularEventStatus.wait_external_operator;
				break;
			}
			case EventStatus.approved: {
				statusLabel = strings.singularEventStatus.approved;
				break;
			}
		}
		return statusLabel;
	};

	const getBackgroundColor = (): string => {
		let color = "";
		switch (event.status) {
			case EventStatus.draft: {
				color = colors.grey;
				break;
			}
			case EventStatus.to_be_approved: {
				color = colors.orange;
				break;
			}
			case EventStatus.wait_internal_operator: {
				color = colors.yellow2;
				break;
			}
			case EventStatus.wait_external_operator: {
				color = colors.lilac;
				break;
			}
			case EventStatus.approved: {
				color = colors.green2;
				break;
			}
		}
		return color;
	};


	const [isPartecipant, setIsPartecipant] = useState(false);
	const [partecipantType, setPartecipantType] = useState("");
	const checkPartecipants = async () => {
		if (event.participants) {
			let userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
			let partecipants = event.participants;
			partecipants.forEach((user: any) => {
				if (user.userId === userId) {
					setIsPartecipant(true);
					setPartecipantType(GetPartepationType(user.type));
				}
			});
		}
	};

	const checkDate = (): boolean => {
		return dayjs().isAfter(dayjs(event.startDate));
	};

	const AttendeeRow = () => (
		<View style={[styles.attendees, isWeb ? { marginTop: 80 } : null]}>
			<UsersIcon size={20} />
			<Text style={styles.attendeesLabel}>{`${event.participants ? event.participants.length : 0} ${strings.eventCard.attendees}`}</Text>
		</View>
	);

	const getPartecipantsList = (): string[] => {
		let p: string[] = [];
		if (event.participants && event.participants.length > 0) {
			event.participants.forEach((item) => {
				p.push(item.userId);
			})
		}
		return p;
	}

	return (
		<View style={[{ borderColor: getFieldColor(event.field) },
		styles.eventContainer, isWeb && styles.webEventContainer,
		backgroundColor ? { backgroundColor: backgroundColor } : null]}>
			{event.img ? (
				<Image source={{ uri: event.img }} style={[styles.image, isWeb && styles.webImage]}></Image>
			) : (
				<Image resizeMode="cover" style={[styles.image, isWeb && styles.webImage]} source={event.field === dashboardType.food ? getDefaultImageByType(event.type): getDefaultImageByScope(event.field) }></Image>
			)}
			<View style={[styles.infoContainer, isWeb && styles.webInfoContainer]}>
				<View style={[styles.items, isWeb ? { width: "60%" } : null]}>
					<Text style={styles.eventType}>{getTypeString(event.type)}</Text>
					<Pressable
						onPress={() => {
							navigation.navigate(PageNames.eventDetail, { event: event });
							event.id && console.log("go to event");
						}}
					>
						<Text numberOfLines={1} style={[styles.eventName, isWeb ? {} : { marginVertical: 0, marginBottom: 5 }]}>
							{event.title}
						</Text>
					</Pressable>
					{date && (
						<View style={styles.eventDateTags}>
							<Tag text={date.day} color={tagsColor} style={styles.eventDateTag} />
							<Tag text={date.hour} color={tagsColor} style={styles.eventDateTag} />
						</View>
					)}
					{event.place && event.place.name ? <Text style={[styles.eventPlace, styles.marginVertical]}>{event.place.name}</Text> : null}
					{event.placeFreeText ? <Text style={[styles.eventPlace, styles.marginVertical]}>{event.placeFreeText}</Text> : null}
				</View>
				<View style={[styles.items, isWeb && styles.webRightItem, !isWeb && styles.secondItemMobile]}>
					{!isWeb && !partecipants && !created ? <AttendeeRow /> : <></>}
					<View style={[styles.buttonContainer, isWeb ? null : { marginTop: 15 }]}>
						{!isWeb && !partecipants && !created && (
							<MoreButton handleButtonPress={() => navigation.navigate(PageNames.eventDetail, { event: event })} filledButton={false} />
						)}

						{(partecipants && isPartecipant) || created ? (
							<Text
								style={[
									styles.invitedText,
									!isWeb ? { marginBottom: created ? 0 : 20, overflow: "hidden" } : null,
									created ? { backgroundColor: getBackgroundColor(), color: colors.black, fontFamily: fonts.RalewayBold } : null,
								]}
							>
								{partecipants
									? isPartecipant
										? (checkDate() ? strings.partecipatedAs : strings.partecipationAs) + partecipantType
										: strings.partecipate
									: getStatusLabel()}
							</Text>
						) : (
							<Button
								style={{ backgroundColor: getFieldColor(event.field) }}
								text={strings.join}
								handleButtonPress={() => navigation.navigate(PageNames.eventDetail, { event: event })}
								filledButton={true}
								textStyle={{ color: colors.black }}
							/>
						)}
					</View>

					<FollowUsersAlert
						modalVisible={showInviteUsers}
						setModalVisible={(isVisible: boolean) => setShowInvitedUsers(isVisible)}
						isWeb={width > 1050}
						invite={true}
						eventId={event.id!}
						partecipants={getPartecipantsList()}
					/>

					{isWeb && !partecipants && !created ? <AttendeeRow /> : <></>}
					{partecipants ? (
						<View style={{ flexDirection: "row", position: isWeb ? "absolute" : "relative", bottom: 10, justifyContent: "flex-end" }}>
							<Pressable onPress={() => navigation.navigate(PageNames.eventDetail, { event: event })}>
								<Text style={[styles.label, { marginRight: 10 }]}>{strings.goToEvent}</Text>
							</Pressable>
							{isPastEvent ? null : (
								<>
									<View style={styles.verticalLine} />
									<Pressable onPress={() => setShowInvitedUsers(true)}>
										<Text style={[styles.label, { marginLeft: 10 }]}>{strings.inviteFriend}</Text>
									</Pressable>
								</>
							)}
						</View>
					) : null}
					{created && canEdit ? (
						<View style={{ marginTop: isWeb ? 55 : 10, marginBottom: 10 }}>
							<Pressable onPress={() => edit!()}>
								{event.status === EventStatus.approved ?
									<Text style={[styles.label]}>{strings.modify}</Text> :
									<Text style={[styles.label]}>{event.status === EventStatus.draft ? strings.editEvent : strings.approveEvent}</Text>
								}

							</Pressable>
						</View>
					) : null}
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	eventContainer: {
		paddingHorizontal: 10,
		paddingVertical: 10,
		borderRadius: 10,
		borderWidth: 6,
		backgroundColor: colors.white,
		marginTop: 50,
		marginBottom: 16,
	},

	webEventContainer: {
		paddingHorizontal: 10,
		marginLeft: 50,
		marginTop: 0,
		flexDirection: "row",
	},

	infoContainer: {
		width: "auto",
		flex: 1,
		flexDirection: "column",
		paddingVertical: 10,
		paddingHorizontal: 10,
		marginTop: -55,
	},

	webInfoContainer: {
		marginLeft: -40,
		marginTop: 0,
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-between",
	},

	items: {
		flexDirection: "column",
	},

	webRightItem: {
		alignItems: "flex-end",
	},
	eventPlace: {
		color: colors.grey4,
	},

	eventType: {
		textTransform: "uppercase",
		color: colors.grey4,
		opacity: 0.5,
	},
	eventName: {
		color: colors.blue3,
		fontSize: 30,
		fontWeight: "bold",
		marginVertical: 8,
	},
	eventDateTags: {
		display: "flex",
		flexDirection: "row",
	},
	eventDateTag: {
		marginRight: 8,
	},
	white: {
		color: colors.white,
	},
	marginVertical: {
		marginVertical: 8,
	},
	marginVertical16: {
		marginVertical: 16,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	share: {
		marginTop: 16,
		fontSize: 14,
		textAlign: "center",
		color: colors.grey4,
	},
	shareIconsContainer: {
		marginBottom: 16,
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	shareIcon: {
		margin: 10,
		height: 30,
		width: 30,
	},
	attendees: {
		flexDirection: "row",
	},
	attendeesLabel: {
		color: colors.grey4,
		opacity: 0.5,
		marginHorizontal: 8,
	},
	image: {
		width: "100%",
		height: 190,
		borderRadius: 10,
		transform: [{ translateY: -60 }],
		zIndex: 2,
	},
	webImage: {
		width: 240,
		height: "auto",
		transform: [{ translateX: -60 }],
	},
	secondItemMobile: {
		marginTop: 5,
	},
	invitedText: {
		backgroundColor: colors.red2,
		color: colors.white,
		borderRadius: 10,
		padding: 5,
		paddingHorizontal: 10,
	},
	label: {
		color: colors.blue,
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		textDecorationLine: "underline",
	},
	verticalLine: {
		borderColor: colors.grey,
		borderLeftWidth: 1,
	},
});

export default EventCard;
