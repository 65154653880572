import { View, StyleSheet, Text, Pressable } from "react-native";
import { fonts } from "../../resources/fonts";
import { colors } from "../../resources/colors";
import { SelectItem } from "../../models/models";

interface props {
	data: SelectItem,
	onPress: () => void,
	isSelected: boolean,
}

const SelectCard = ({ data, onPress, isSelected }: props) => {

	return <View style={styles.container}>
		<Pressable
			style={[styles.card, isSelected ? { backgroundColor: data.color, borderWidth: 0 } : { borderColor: data.color }]}
			onPress={onPress}
		>
			{data.icon}
			<Text style={[styles.cardTitle, !isSelected ? { color: colors.black } : { color: colors.black }]}>
				{data.label}
			</Text>
		</Pressable>
	</View>;
}


const styles = StyleSheet.create({
	container: {
		borderRadius: 10,
		display: "flex",
		alignItems: 'center',
		margin: 5,
		justifyContent: "center",
	},
	card: {
		minWidth: 200,
		minHeight: 200,
		borderRadius: 10,
		borderWidth: 4,
		shadowOpacity: 0.3,
		shadowRadius: 2,
		shadowOffset: {
			height: 1,
			width: 1
		},
		justifyContent: "center",
		alignItems: 'center',
	},
	cardContent: {
		width: '100%',
		paddingVertical: '20%',
		justifyContent: "center",
		alignItems: 'center',
	},
	cardTitle: {
		fontSize: 20,
		fontFamily: fonts.RalewayBold,
		marginBottom: "3%",
		marginTop: 30,
	},
});

export default SelectCard;
