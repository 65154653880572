import { useState } from "react";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { EndPoints } from "../services/EndPoints";
import { AlertTypes } from "../utils/AlertTypes";
import { rules } from "../utils/Rules";
import BasicAlert from "./Alerts/BasicAlert";
import Button from "./Button";
import CustomTextInput from "./CustomTextInput";
import Loader from "./Loader";

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	oldPassword: string;
}

const ChangePasswordAlert = (props: props) => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [passwordNotMatching, setPasswordNotMatching] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const validatePassword = (password: string) => {
		return rules.passwordRegex.test(password) === true;
	};

	const save = async () => {
		setLoaderVisible(true);
		await EndPoints.changePassword(props.oldPassword, password, confirmPassword, true)
			.then(() => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				props.setModalVisible(false);
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => props.setModalVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setModalVisible(false)} />
			<View style={styles.container}>
				<View style={styles.content}>
					<BasicAlert
						title={basicAlertValues.title}
						description={basicAlertValues.description}
						buttonText={basicAlertValues.buttonText}
						alertType={basicAlertValues.type}
						alertVisible={basicAlertValues.isVisible}
						setAlertVisible={(isVisible: boolean) => {
							setBasicAlertValues({
								isVisible: isVisible,
								title: basicAlertValues.title,
								description: basicAlertValues.description,
								type: basicAlertValues.type,
								buttonText: basicAlertValues.buttonText,
							});
						}}
					/>
					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<Text style={styles.title}>{strings.changePassword}</Text>
					<Text style={styles.description}>{strings.changePasswordFirstAccess}</Text>

					{/* password */}
					<CustomTextInput
						value={password}
						obligatory={false}
						placeholder={strings.insertPassword}
						title={strings.password}
						secureTextEntry={true}
						onBlur={() => (validatePassword(password) || password.length == 0 ? setInvalidPassword(false) : setInvalidPassword(true))}
						styleTextInput={invalidPassword ? [{ borderColor: colors.red }] : null}
						setValue={(value: string) => {
							setPassword(value);
							validatePassword(value) || value.length == 0 ? setInvalidPassword(false) : null;
						}}
					/>
					{invalidPassword ? <Text style={styles.errorText}>{strings.passwordNotValid}</Text> : null}

					{/* confirm password */}
					<CustomTextInput
						value={confirmPassword}
						obligatory={false}
						placeholder={strings.insertConfirmPassword}
						title={strings.confirmPassword}
						secureTextEntry={true}
						onBlur={() => (password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : setPasswordNotMatching(true))}
						styleTextInput={passwordNotMatching ? [{ borderColor: colors.red }] : null}
						setValue={(value: string) => {
							setConfirmPassword(value);
							password === value || value.length == 0 ? setPasswordNotMatching(false) : null;
						}}
					/>
					{passwordNotMatching ? <Text style={styles.errorText}>{strings.passwordsNotMatch}</Text> : null}

					<Button
						style={styles.button}
						handleButtonPress={() => save()}
						disabled={password == "" || confirmPassword == "" || password != confirmPassword}
						text={strings.save}
						filledButton={false}
					/>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		width: Platform.OS === "web" ? "40%" : "80%",
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	description: {
		fontFamily: fonts.RalewayRegular,
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "2%",
		fontSize: 14,
		textAlign: "center",
		color: colors.grey4,
	},
	errorText: {
		color: colors.red,
		marginLeft: "5%",
		marginRight: "5%",
		fontFamily: fonts.RalewayRegular,
	},
	button: {
		marginTop: Platform.OS === "web" ? "2%" : "10%",
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
});

export default ChangePasswordAlert;
