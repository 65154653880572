import { useEffect, useRef, useState } from "react";
import { Modal, Platform, Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { strings } from "../../../locales/strings";
import { CalendarEvents, Event, EventActivity } from "../../../models/eventsModels";
import { ActivityFilter, Filter, ImageInfo, Place, PlaceImageDocRes, ProfileImageRequest } from "../../../models/models";
import { colors } from "../../../resources/colors";
import { fonts } from "../../../resources/fonts";
import { EventsEndPoint } from "../../../services/EventsEndPoint";
import { PlacesEndPoint } from "../../../services/PlacesEndPoint";
import { AttachmentPicker } from "../../../utils/AttachmentPicker";
import { EventParticipationType } from "../../../utils/types/EventType";
import { MacroAreaType } from "../../../utils/types/MacroAreaType";
import Button from "../../Button";
import CreateEventCalendarsComponent from "../../Calendar/CreateEventCalendarsComponent";
import CustomTextInput from "../../CustomTextInput";
import Select from "../../Select";
import CoverImage from "../CoverImage";
import SelectPlaceComponent from "../SelectPlaceComponent";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { ICalendarEventBase } from "react-native-big-calendar";
import { ProfileImageMapper } from "../../../mappers/ProfileImageMapper";
import { AlertTypes } from "../../../utils/AlertTypes";
import BasicAlert from "../../Alerts/BasicAlert";
import Loader from "../../Loader";
import ExitFromModify from "../../Places/ExitFromModify";
import BasicToast from "../../Toasts/BasicToast";
import { CalendarUtils } from "../../../utils/CalendarUtils";
import SelectOperator from "../SelectOperator";
import { UsersEndpoints } from "../../../services/UsersEndpoints";
import { roles } from "../../../utils/Roles";
import { PlaceType, getPlaceTypeValueFromEvent, placeTypeOptions, setPlaceTypeValue } from '../../../models/PlaceType';
import { useNavigation } from "@react-navigation/native";
import { PageNames } from "../../../navigation/NavigationUtils";
import { EventActivityStatus } from "../../../utils/EventStatus";
import FilterView from "../../../screens/filterScreen/FilterView";

interface props {
    modalVisible: boolean;
    setOpenActivity: (open: boolean) => void;
    isReadOnly: boolean;

    onClose: () => void;
    event: Event;
    edit: boolean;
    activity?: EventActivity;
    eventId:string;
}

const ActivityModal = (props: props) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;
    const attachmentPicker = new AttachmentPicker();
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [placesList, setPlacesList] = useState<Place[]>([]);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    const [validDate, setValidDate] = useState<boolean>(false);
    const [monthDataWeb, setMonthDataWeb] = useState<ICalendarEventBase[]>([]);
    const [dateActivityContained, setDateActivityContained] = useState<ICalendarEventBase[]>([]);

    const [monthDataMobile, setMonthDataMobile] = useState<CalendarEvents | undefined>(undefined);
    const [webAgendaDaySelected, setWebAgendaDaySelected] = useState(new Date().toString());
    const [isEdit, setIsEdit] = useState(props.edit);
    const [placeType, setPlaceType] = useState<PlaceType>();
    const [filters, setFilters] = useState<ActivityFilter>({
        visible: false,
        title: '',
        filterList: [],
        selectedItem: undefined,
        onPress: (value: any) => { },
        handleBackPress: () => { },
    });

    const navigation = useNavigation<any>();
    const propsEvent = props.event;
    const propsActivity = props.activity
    const [activity, setActivity] = useState<EventActivity>({
        _id: "",
        field: "",
        title: "",
        startDate: null,
        endDate: null,
        placeId: "",

        place: {
            _id: "",
            name: "",
            placeManagerId: "",
            coordinates: [null, null],
            workspaceEmail: "",
            calendarId: "",
        },
        img: "",
        description: "",
        externalOperatorId: "",
        externalOperator: { id: "", firstName: "", lastName: "" },
        status: undefined,

    });

    useEffect(() => {
        !!propsActivity && setPlaceType(getPlaceTypeValueFromEvent(propsActivity))
    }, [propsActivity?.place?._id, propsActivity?.placeFreeText]);


    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        !!isWeb ? clearFields() : null;
        flow();
    }, [props.modalVisible])

    const flow = async () => {
        getExternalUser();
        // GET PLACES
        const placesRes = await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined });
        let placeList = [];

        setPlacesList(placesRes.page.items);
        placeList = placesRes.page.items;

        props.edit ? editActivity(placeList) : null;
        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
        getActivities();
    }

    const clearFields = () => {
        setActivity({
            _id: "",
            field: "",
            title: "",
            startDate: null,
            endDate: null,
            placeId: "",

            place: {
                _id: "",
                name: "",
                placeManagerId: "",
                coordinates: [null, null],
                workspaceEmail: "",
                calendarId: "",
            },
            img: "",
            description: "",
            externalOperator: { id: "", firstName: "", lastName: "" },
            externalOperatorId: "",
            status: undefined,
        });

        setImage({ img: "", modified: true });
        setIsEdit(props.edit);
        setMonthDataWeb([]);
        setDateActivityContained([]);
        setMonthDataMobile(undefined);
        setWebAgendaDaySelected(new Date().toString());
        setImageObject(null);
        setValidDate(false);
        setField("");
        !props.edit ? setPlaceType(undefined) : null;
        setExternalOperator({ firstName: "", lastName: "" });
    };
    const getExternalUser = async () => {
        // GET EXTERNAL USERS
        let externalUsersList: any[] = [];
        const externalUserRes = await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.externalUser], undefined, true);
        externalUserRes.page.items.forEach((value: any) => {
            const user: any = {
                value: value.id,
                label: value.firstName + " " + value.lastName,
                firstName: value.firstName,
                lastName: value.lastName,
            };
            externalUsersList.push(user);
        });
        setExternalUsers(externalUsersList);
    }

    const [openConventionedPlace, setOpenConventionedPlace] = useState(false);
    const [field, setField] = useState('');
    const [openFieldComponent, setOpenFieldComponent] = useState(false);
    const [image, setImage] = useState({ img: "", modified: true });

    const selectField = (type: any) => {
        if (type) {
            setIsEdit(false)
            setField(type);
            setActivity({ ...activity, field: type });
        }
        setOpenFieldComponent(false);
    };
    const [imageObject, setImageObject] = useState<ProfileImageRequest | null>(null);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [exitFromModify, setExitFromModify] = useState(false);


    useEffect(() => {
        !!activity.startDate ? setWebAgendaDaySelected(activity.startDate.toString()) : null;
    }, [activity?.place?._id]);

    useEffect(() => {
        !!activity.startDate ? setWebAgendaDaySelected(activity.startDate.toString()) : null;
    }, [activity?.endDate]);

    useEffect(() => {
        getActivities();
    }, [activity.startDate, activity.endDate])


    useEffect(() => {
        !!activity.field ? getPlace() : null;
    }, [activity.field])


    const getPlace = async () => {
        // GET PLACES
        const placesRes =
            await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined, field: activity.field ? Array(activity.field) : [] });

        let placeList = [];
        setPlacesList(placesRes.page.items);
        placeList = placesRes.page.items;
        isEdit ? editActivity(placeList) : null;

        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
    }

    const uploadImage = async (eventId: string, activityId: string) => {
        try {
            if (image.img != "" && image.modified) {
                await EventsEndPoint.addActivityPicture(eventId, activityId, { fileURI: image.img }).then((res: PlaceImageDocRes) => {
                    setImage({ ...image, modified: false });
                    setActivity({ ...activity, img: res.item.link });
                });
            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
        }
    };


    const editActivity = (places: []) => {
        let placeName = "";
        let place = { _id: "", name: "", placeManagerId: "", coordinates: [null, null], calendarId: "", workspaceEmail: "" };
        if (propsActivity) {
            places.forEach((item: any) => {
                if (propsActivity.place && item.id === propsActivity.place._id) {
                    placeName = item.name;
                    place = {
                        _id: item.id,
                        name: item.name,
                        placeManagerId: item.placeManager.id,
                        coordinates: [item.coordinates[0], item.coordinates[1]],
                        calendarId: item.calendarId,
                        workspaceEmail: item.placeManager.workspaceEmail,
                    };
                }
            });
            setField(!!propsActivity && !!propsActivity.field ? propsActivity.field : "")
            setImage({ img: propsActivity?.img ?? '', modified: false });
            setExternalOperator({ firstName: propsActivity?.externalOperator?.firstName ?? '', lastName: propsActivity?.externalOperator?.lastName ?? '' })
            setActivity({
                field: !!propsActivity.field ? propsActivity.field : "",
                title: !!propsActivity.title ? propsActivity.title : "",
                startDate: !!propsActivity.startDate ? propsActivity.startDate : null,
                endDate: !!propsActivity.endDate ? propsActivity.endDate : null,
                place: {
                    _id: !!propsActivity.place?._id ? propsActivity.place._id : "",
                    name: !!propsActivity.place?.name ? propsActivity.place.name : "",
                    placeManagerId: !!propsActivity.place?.placeManagerId ? propsActivity.place.placeManagerId : "",
                    coordinates: [
                        !!propsActivity.place ? propsActivity.place.coordinates[0] : null,
                        !!propsActivity.place ? propsActivity.place.coordinates[1] : null,],
                    calendarId: !!propsActivity && !!propsActivity.place?.calendarId ? propsActivity.place.calendarId : "",
                    workspaceEmail: !!propsActivity && !!propsActivity.place?.workspaceEmail ? propsActivity.place.workspaceEmail : "",
                },
                ...propsActivity.placeFreeText && { placeFreeText: propsActivity.placeFreeText },
                img: !!propsActivity.img ? propsActivity.img : "",
                description: !!propsActivity && !!propsActivity.description ? propsActivity.description : "",
                externalOperatorId: propsActivity?.externalOperator?.id ? propsActivity?.externalOperator.id : "", // EXTERNAL OPERATOR
                status: activity.status,
            })
            setLoaderVisible(true);
        }
    }


    const saveActivity = (save: boolean) => {
        let place: {
            _id: string,
            name: string,
            placeManagerId: string,
            coordinates: any,
            calendarId: string,
            workspaceEmail: string,
        } | undefined;
        if (propsEvent.participationType === EventParticipationType.online) {
            place = undefined;
        } else {
            place = {
                _id: activity?.place?._id ?? '',
                name: activity?.place?.name ?? '',
                placeManagerId: activity?.place?.placeManagerId ?? '',
                coordinates: [activity?.place?.coordinates[0], activity?.place?.coordinates[1]],
                calendarId: activity?.place?.calendarId ?? '',
                workspaceEmail: activity?.place?.workspaceEmail ?? '',
            }
        }

        let startDate = !!activity.startDate ? new Date(activity.startDate) : null;
        let endDate = !!activity.endDate ? new Date(activity.endDate) : null;

        const activityInfo: EventActivity = {
            field: activity.field,
            title: activity.title,
            startDate: startDate,
            endDate: endDate,
            ...place?._id && { place },
            ...activity.placeFreeText && { placeFreeText: activity.placeFreeText },
            description: activity.description,

            externalOperator:
                externalOperator.firstName !== "" && externalOperator.lastName !== "" ? {
                    id: activity?.externalOperatorId ?? '',
                    firstName: externalOperator.firstName,
                    lastName: externalOperator.lastName,
                } : undefined,

            status: activity.externalOperatorId === "" ? EventActivityStatus.APPROVED : EventActivityStatus.WAIT_EXTERNAL_OPERATOR,
        }
        setLoaderVisible(true);

        if (!!save ) {

            EventsEndPoint.createEventActivity(props.eventId, activityInfo)
                .then((res) => {
                    uploadImage(props.eventId, res.item._id);
                    setBasicAlertValues({ isVisible: true, title: "", description: strings.activitySaved, type: AlertTypes.success, buttonText: strings.close });
                    if (Platform.OS === "ios") {
                        setTimeout(() => {
                            setLoaderVisible(false);
                        }, 500);
                    } else {
                        setLoaderVisible(false);
                    }
                })
                .catch((e) => {
                    setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                    setLoaderVisible(false);
                });
        } else if (propsEvent?.id && propsActivity?._id) {
            EventsEndPoint.modifyActivity(propsEvent.id, propsActivity._id, activityInfo)
                .then((res) => {
                    uploadImage(propsEvent.id!, res.item._id);
                    setBasicAlertValues({ isVisible: true, title: "", description: strings.activitySaved, type: AlertTypes.success, buttonText: strings.close });
                    if (Platform.OS === "ios") {
                        setTimeout(() => {
                            setLoaderVisible(false);
                        }, 500);
                    } else {
                        setLoaderVisible(false);
                    }
                })
                .catch((e) => {
                    setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
                    setLoaderVisible(false)
                });
        }
    }

    const addImage = async () => {
        const imageInfo = Platform.OS === "web" ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
        if (imageInfo) {
            if (Platform.OS === "web" && !(imageInfo as ImageInfo).uri.includes("image")) {
                setErrorToastVisible({ isVisible: true, text: strings.errorPhotoUploaded });
            } else {
                if (attachmentPicker.isSmallSize(Platform.OS === "web" ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
                    if (Platform.OS === "web") {
                        setImage({ img: (imageInfo as ImageInfo).uri, modified: true });
                    } else {
                        setImage({
                            img: Platform.OS === "android" && (imageInfo as ImageInfo[])[0].uri ? (imageInfo as ImageInfo[])[0].uri : (imageInfo as ImageInfo[])[0].fileURI,
                            modified: true,
                        });
                    }
                    const image = Platform.OS === "web" ? (imageInfo as ImageInfo) : (imageInfo as ImageInfo[])[0];
                    setImageObject(Platform.OS === "web" ? ProfileImageMapper.mapWeb(image) : ProfileImageMapper.mapMobile(image));

                } else {
                    Platform.OS === "web"
                        ? setErrorToastVisible({ isVisible: true, text: strings.errorPhotoTooBig })
                        : setBasicAlertValues({
                            isVisible: true,
                            title: strings.error,
                            description: strings.errorPhotoTooBig,
                            type: AlertTypes.error,
                            buttonText: strings.ok,
                        });
                }
            }
        }
    };
    // EXTERNAL OPERATOR
    const [externalOperator, setExternalOperator] = useState({ firstName: "", lastName: "" });
    const [externalOperatorSelected, setExternalOperatorSelected] = useState<Filter>();

    const [openExternalUserPicker, setOpenExternalUserPicker] = useState(false);
    const [externalUsers, setExternalUsers] = useState([{ label: "", value: "", firstName: "", lastName: "" }]);
    const removeImage = async () => {
        setImage({ img: "", modified: true });
        if (activity.img != "" && props.edit && !!propsActivity) {
            await EventsEndPoint.deleteActivityPicture(propsEvent.id!, propsActivity._id!, propsActivity.img!).then(() => {
                setImage({ img: "", modified: false });
                setActivity({ ...activity, img: "" });
            });
        }
        setImageObject(null);
    };
    const { showActionSheetWithOptions } = useActionSheet();
    const openActionSheet = () => {
        const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
        const destructiveButtonIndex = undefined;
        const cancelButtonIndex = 2;
        return new Promise((resolve, reject) => {
            showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
                switch (buttonIndex) {
                    case 0: {
                        attachmentPicker._pickFromGallery("photo", resolve, reject);
                        break;
                    }
                    case 1: {
                        attachmentPicker._takePhoto(resolve, reject);
                        break;
                    }
                    case 2: {
                        break;
                    }
                }
            });
        });
    };

    const getActivities = async () => {
        if (propsEvent.id) {
            setLoaderVisible(true);
            EventsEndPoint.getEvent(propsEvent.id).then((res) => {
                let parsed: React.SetStateAction<ICalendarEventBase[]> = [];
                let mobileParsed = undefined;
                if (!!propsActivity && props) {
                    parsed = CalendarUtils.parsedActivityWeb(res.item.activities).filter((act) => act.id !== propsActivity._id);
                    mobileParsed = CalendarUtils.parseEventsMobile(res.item.activities);
                } else {
                    parsed = CalendarUtils.parsedActivityWeb(res.item.activities);
                    mobileParsed = CalendarUtils.parseEventsMobile(res.item.activities);
                }
                let eventParsed: React.SetStateAction<ICalendarEventBase[]> = [];
                eventParsed.push({      
                    start: propsEvent.startDate,
                    end: propsEvent.endDate,
                    title: propsEvent.title}
              
                )
                setDateActivityContained(eventParsed)
                setMonthDataWeb(parsed);

                setMonthDataMobile(mobileParsed);

                if (Platform.OS === "ios") {
                    setTimeout(() => {
                        setLoaderVisible(false);
                    }, 500);
                } else {
                    setLoaderVisible(false);
                }

            }).catch((e: any) => {
                console.log("Error " + e);
                if (Platform.OS === "ios") {
                    setTimeout(() => {
                        setLoaderVisible(false);
                        setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                    }, 500);
                } else {
                    setLoaderVisible(false);
                    setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
                }
            });
        }
    };

    const selectPlaceType = (placeType: PlaceType) => {
        setPlaceTypeValue(activity, placeType);
        setPlaceType(placeType);
        setActivity(activity);
    }


    const getList = (items: Array<any>): { type: string; label: string }[] => {
        let list: { type: string; label: string }[] = [];
        items.forEach((item: any) => {
            list.push({ type: item.value, label: item.label });
        });
        return list;
    };

    const getPlaceList = (): { type: string; label: string; description: string }[] => {
        let list: { type: string; label: string; description: string }[] = [];
        placesList.forEach((item: any) => {
            list.push({ type: item.id, label: item.name, description: item.address });
        });
        return list;
    };

    return (
        <Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={(props.onClose)}>
            {filters.visible ?
                <View style={styles.filterContainer}>
                    <FilterView
                        title={filters.title}
                        filters={getList(filters.filterList)}
                        filterSelected={filters.selectedItem}
                        handleOnPress={filters.onPress}
                        navigatePage={""}
                        handleBackPress={filters.handleBackPress}
                    />
                </View>
                :
                <View style={styles.containerModal}>
                    <Pressable style={[styles.overlay, styles.backdrop]} onPress={() => { setExitFromModify(true) }} />
                    <View style={[styles.content]}>
                        <Pressable style={styles.closeBtn}
                            onPress={() => { setExitFromModify(true) }}>
                            <Text style={styles.closeBtnText}>{strings.close}</Text>
                            <FontAwesomeIcon size={15} color={colors.white} icon={faClose}></FontAwesomeIcon>
                        </Pressable>
                        <View style={[styles.rowView, isWeb ? { marginTop: 20, marginBottom: 20, paddingLeft: 30 } : null]}>
                            <Text style={styles.title}>{strings.newActivity}</Text>
                        </View>
                        <ScrollView showsVerticalScrollIndicator={false}>
                            <View style={styles.container}>

                                <View style={isWeb ? styles.rowView : null}>
                                    <View style={isWeb ? { width: "50%" } : { width: '100%' }}>
                                        <Text style={[styles.fieldTitle, { marginTop: 20, marginBottom: 10 }]}>{strings.scope + " *"}</Text>

                                        <Select
                                            options={
                                                MacroAreaType.map(
                                                    (scope) => ({
                                                        value: scope.type,
                                                        label: scope.label,
                                                    })
                                                ) || []
                                            }
                                            placeholder={strings.scope}
                                            value={field}
                                            onChange={(type) => selectField(type)}
                                            disabled={props.isReadOnly}
                                        />

                                        {/* Title */}
                                        <View style={isWeb ? { marginRight: 20 } : {}}>
                                            {props.isReadOnly ? (
                                                <>
                                                    <Text style={[styles.fieldTitle]}>{strings.activityTitle + " *"}</Text>
                                                    <Text style={styles.readonly}>{activity.title}</Text>
                                                </>
                                            ) : (
                                                <CustomTextInput
                                                    title={strings.activityTitle + " *"}
                                                    value={activity.title}
                                                    placeholder={strings.activityTitle}
                                                    obligatory={false}
                                                    styleText={{ marginLeft: 0, marginRight: 0, marginBottom: 10 }}
                                                    styleTextInput={[{ marginLeft: 0, marginRight: 0, width: "100%" }]}
                                                    setValue={(value: string) => setActivity({ ...activity, title: value })}
                                                />
                                            )}
                                        </View>
                                    </View>

                                    {/* start date & end date */}
                                    <View style={isWeb ? { width: "50%", zIndex: 9999 } : null}>
                                        <CreateEventCalendarsComponent
                                            maxDate={propsEvent.endDate?.toString()}
                                            minDate={propsEvent.startDate.toString()}
                                            isReadOnly={props.isReadOnly}
                                            startDate={activity.startDate ? activity.startDate.toString() : propsEvent.startDate.toString()}
                                            endDate={activity.endDate?.toString() ?? undefined}
                                            event={propsEvent}
                                            activity={activity}
                                            setActivity={(activity: any) => { setActivity(activity) }}
                                            setValidDate={(isValid: boolean) => {
                                                setValidDate(isValid)
                                            }}
                                            monthDataWeb={monthDataWeb}
                                            monthDataMobile={monthDataMobile}
                                            dateActivityContained={dateActivityContained}
                                            webAgendaDaySelected={webAgendaDaySelected}
                                            markedDates={undefined}
                                            isActivity={true}
                                        />
                                    </View>
                                </View>
                                <View style={styles.line}></View>

                                <View style={isWeb ? [styles.rowView, { position: 'relative', zIndex: -1 }] : null}>
                                    <View style={isWeb ? { width: "50%" } : null}>
                                        {/* Place type */}
                                        {propsEvent.participationType !== EventParticipationType.online ? (
                                            <View style={{ zIndex: 2 }}>
                                                <Text style={[styles.fieldTitle, { marginTop: 20, marginBottom: 10 }]}>{strings.selectPlaceTypes + " *"}</Text>
                                                <Select
                                                    options={placeTypeOptions}
                                                    placeholder={strings.select}
                                                    value={placeType}
                                                    disabled={isReadOnly}
                                                    onChange={(value) => selectPlaceType(value)}
                                                />
                                            </View>
                                        ) : null}

                                        {/* Conventioned place */}
                                        {placeType === PlaceType.CONVENTIONED || propsEvent.participationType === EventParticipationType.online ? (
                                            <View style={{ zIndex: 1 }}>
                                                <Text style={[styles.fieldTitle, { marginTop: 20 }]}>{strings.place + " *"}</Text>
                                                <SelectPlaceComponent
                                                    navigation={navigation}
                                                    isReadOnly={props.isReadOnly || propsEvent.participationType === EventParticipationType.online || activity.field === ""}
                                                    activity={activity}
                                                    event={propsEvent}
                                                    setActivity={(value: any) => setActivity(value)}
                                                    openConventionedPlace={openConventionedPlace}
                                                    setOpenConventionedPlace={(value: boolean) => setOpenConventionedPlace(value)}
                                                    placesList={placesList}
                                                    pageName={PageNames.createEventWellness}
                                                    onPress={() => Platform.OS !== 'web' && props.onClose()}
                                                    setOpenActivity={props.setOpenActivity}
                                                    handleOnPress={() => {
                                                        setFilters({
                                                            visible: true,
                                                            title: strings.conventionedPlace,
                                                            filterList: getList(placesList),
                                                            selectedItem: '',
                                                            onPress: (value: any) => {
                                                                setActivity({ ...activity, place: value });
                                                                setFilters({ ...filters, visible: false });
                                                            },
                                                            handleBackPress: () => setFilters({ ...filters, visible: false })
                                                        })
                                                    }}
                                                />
                                            </View>
                                        ) : null}

                                        {/* Free text place */}
                                        {placeType === PlaceType.OTHER ? (
                                            <>
                                                <CustomTextInput
                                                    title={strings.otherPlace.label}
                                                    description={strings.otherPlace.description}
                                                    value={activity.placeFreeText ?? ''}
                                                    placeholder={strings.otherPlace.placeholder}
                                                    obligatory={true}
                                                    isReadOnly={isReadOnly}
                                                    styleText={{ marginLeft: 0, marginRight: 0, marginBottom: 10 }}
                                                    styleTextInput={[{ marginLeft: 0, marginRight: 16 }]}
                                                    setValue={(value) => setActivity({ ...activity, placeFreeText: value })}
                                                />
                                            </>
                                        ) : null}
                                    </View>
                                    <View style={isWeb ? { width: "50%" } : null}>
                                        {/* N.max participant */}
                                        <View style={isWeb ? { marginRight: 20, marginTop: 20 } : {}}>
                                            <Text style={[styles.fieldTitle]}>{strings.maxNumberOfUsers}</Text>
                                            <Text style={[styles.readonly, { height: 50 }]}>{propsEvent.maxNumberOfUsers}</Text>
                                        </View>
                                    </View>
                                </View>

                                {/* Cover image Web */}

                                <View style={isWeb ? [styles.rowView, { position: 'relative', zIndex: -2 }] : null}>

                                    <View style={[isWeb ? { width: "50%", paddingRight: 20 } : null]}>
                                        <Text style={[styles.fieldTitle, { marginTop: 20, marginBottom: -10 }]}>{strings.activityCoverImage}</Text>
                                        <CoverImage
                                            isWeb={isWeb}
                                            image={image.img}
                                            addImage={() => addImage()}
                                            removeImage={() => removeImage()}
                                            isReadOnly={props.isReadOnly} />
                                    </View>
                                    <View style={isWeb ? { width: "50%" } : null}>
                                        {/*External Operator*/}
                                        <View>
                                            <SelectOperator
                                                isReadOnly={props.isReadOnly}
                                                typology={'typology'}
                                                openInternalUserPicker={openExternalUserPicker}
                                                userSelected={externalOperator}
                                                setOpenInternalUserPicker={(value: boolean) => setOpenExternalUserPicker(value)}
                                                internalUsers={externalUsers}
                                                setUserSelected={(firstName: string, lastName: string) =>
                                                    setExternalOperator({ firstName: firstName, lastName: lastName })}
                                                isInternal={false}
                                                event={activity}
                                                setEvent={(value: any) => {
                                                    setActivity(value);
                                                }}
                                                pageName={""}
                                                handleOnPress={() => {
                                                    setFilters({
                                                        visible: true,
                                                        title: strings.externalOperator,
                                                        filterList: externalUsers,
                                                        selectedItem: externalOperator,
                                                        onPress: (value: any) => {
                                                            setExternalOperator({ firstName: value.label, lastName: '' });
                                                            setActivity({ ...activity, externalOperatorId: value.type });
                                                            setFilters({ ...filters, visible: false });
                                                            setExternalOperatorSelected(value)
                                                        },
                                                        handleBackPress: () => setFilters({ ...filters, visible: false })
                                                    })
                                                }}
                                            />
                                        </View>
                                        {/* Description */}
                                        <View style={{
                                            position: 'relative',
                                            zIndex: -2
                                        }}>
                                            {props.isReadOnly ? (
                                                <>
                                                    <Text style={styles.fieldTitle}>{strings.descriptionActivity}</Text>
                                                    <Text style={[styles.readonly, activity.description === "" && { height: 150 }]}>{activity.description}</Text>
                                                </>
                                            ) : (
                                                <CustomTextInput
                                                    numberOfLine={8}
                                                    maxLenght={500}
                                                    value={activity.description ?? ''}
                                                    placeholder={""}
                                                    title={strings.descriptionActivity}
                                                    obligatory={false}
                                                    styleText={{ marginLeft: 0, marginRight: 0 }}
                                                    styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
                                                    setValue={(value: string) => setActivity({ ...activity, description: value })}
                                                />
                                            )}
                                        </View>
                                    </View>
                                </View>
                                <ExitFromModify
                                    modalVisible={exitFromModify}
                                    setExitFromModifyVisible={(isVisible: boolean) => setExitFromModify(isVisible)}
                                    exitWithoutSaving={() => {
                                        clearFields();
                                        props.onClose();
                                        setExitFromModify(false);
                                    }}
                                />
                            </View>
                        </ScrollView>
                        {/* MODAL ACTIONS */}
                        <View style={[isWeb ? styles.buttonContainer : styles.rowView]}>
                            <Button
                                style={{ marginRight: 10 }}
                                handleButtonPress={() => setExitFromModify(true)}
                                text={strings.cancelCard} filledButton={false}
                            />
                            <Button
                                handleButtonPress={() => {
                                    props.edit ? saveActivity(false) : saveActivity(true);
                                }}
                                text={strings.save}
                                filledButton={true}
                                disabled={activity.field === "" ||
                                    activity.startDate == null || activity.endDate == null || activity.title === "" || validDate
                                    || (propsEvent.participationType !== EventParticipationType.online && !activity?.place?._id && !activity?.placeFreeText)
                                }
                            />
                        </View>
                        <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

                        <BasicAlert
                            title={basicAlertValues.title}
                            description={basicAlertValues.description}
                            buttonText={basicAlertValues.buttonText}
                            alertType={basicAlertValues.type}
                            alertVisible={basicAlertValues.isVisible}
                            setAlertVisible={(isVisible: boolean) => {
                                setBasicAlertValues({
                                    isVisible: isVisible,
                                    title: basicAlertValues.title,
                                    description: basicAlertValues.description,
                                    type: basicAlertValues.type,
                                    buttonText: basicAlertValues.buttonText,
                                });
                                clearFields();
                                props.onClose();
                            }}
                        />

                        <BasicToast
                            title={errorToastVisible.text}
                            alertType={AlertTypes.error}
                            alertVisible={errorToastVisible.isVisible}
                            setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
                        />
                    </View>
                </View>
            }
        </Modal>
    );
};


const styles = StyleSheet.create({
    containerModal: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    container: {
        backgroundColor: colors.primary,
        borderRadius: 10,
        padding: Platform.OS === "web" ? 20 : 6,
        marginVertical: '2%',
    },
    content: {
        borderRadius: 10,
        padding: 20,
        width: Platform.OS === "web" ? '80%' : '90%',
        backgroundColor: colors.white,
        marginTop: Platform.OS === "web" ? '10%' : '20%',
        marginBottom: Platform.OS === "web" ? '10%' : '10%',
        height: Platform.OS === "web" ? '80%' : '70%'
    },
    buttonContainer: {
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        marginTop: 20,
    },
    overlay: {
        backgroundColor: colors.grey4,
        opacity: 0.8,
    },
    backdrop: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    title: {
        fontSize: 30,
        fontFamily: fonts.RalewayBold,
        color: colors.grey4,
        marginHorizontal: 2,
    },
    text: {
        fontSize: 16,
        textAlign: "center",
        fontFamily: fonts.RalewayRegular,
        color: colors.grey4,
        marginBottom: 10,
        marginLeft: "5%",
        marginRight: "5%",
    },
    rowView: {
        flexDirection: "row",
        justifyContent: "space-between",

    },
    closeBtnText: {
        color: colors.white,
        fontFamily: fonts.RalewaySemiBold,
        fontSize: 16,
        marginRight: 8,
    },
    closeBtn: {
        flexDirection: "row",
        position: "absolute",
        top: -24,
        right: 0,
        alignItems: "center",
    },
    line: {
        borderTopColor: colors.grey,
        borderTopWidth: 1,
        marginTop: 20,
        marginBottom: 20,
        zIndex: -1,
    },
    fieldTitle: {
        fontFamily: fonts.RalewayBold,
        fontSize: 16,
    },
    readonly: {
        backgroundColor: colors.greyReadOnly,
        color: colors.black,
        width: "100%",
        borderRadius: 10,
        fontSize: 14,
        padding: 15,
        marginTop: 10,
        fontFamily: fonts.RalewayRegular,
        borderColor: colors.grey,
        borderWidth: 1,
        opacity: 0.8,
        overflow: "hidden",
        zIndex: 1,
    },
    filterContainer: {
        paddingTop: '20%'
    }

});

export default ActivityModal;
