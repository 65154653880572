import { StyleSheet, View, Modal, Pressable } from "react-native";
import { colors } from "../resources/colors";
import { strings } from "../locales/strings";
import { useState } from "react";
import HeaderCreationComponent from "./HeaderCreationComponent";
import { images } from "../resources/images";
import { MenuItems } from "../utils/MenuItems";
import CloseIcon from "./CloseIcon";
import FollowUsersAlert from "./FollowUsersAlert";
import { PageNames } from "../navigation/NavigationUtils";
import SelectModal from "./Select/SelectModal";
import { MacroAreaEventSelect } from "../utils/SelectUtils";
import { MacroAreaType } from "../utils/types/MacroAreaType";
import { MacroArea } from "../models/models";
import { dashboardType } from "../utils/DashboardType";

interface props {
	setMenuVisible: (isVisible: boolean) => any;
	isVisible: boolean;
	navigation: any;
	width: number;
	ableToFollowFriends: boolean;
	macroarea?: MacroArea;
}

const HeaderCreationModal = (props: props) => {
	const navigate = (screen: string, params?: any) => {
		props.navigation.navigate(screen, params);
		props.setMenuVisible(false);
	};

	const [showInviteUsers, setShowInvitedUsers] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const navigateToCreateEvent = () => {
        if (props.macroarea && props.macroarea?.key && props.macroarea?.label) {
            if (props.macroarea.key === dashboardType.food) {
                props.navigation.navigate(PageNames.createEventFood, { event: undefined, userId: undefined, role: undefined, edit: false, macroarea: props?.macroarea });
            } else if (props.macroarea.key === dashboardType.wellness) {
                props.navigation.navigate(PageNames.createEventWellness, { event: undefined, userId: undefined, role: undefined, edit: false, macroarea: props?.macroarea });
            }
			props.setMenuVisible(false);
		} else {
			setIsModalVisible(true);
		}
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.isVisible} onRequestClose={() => props.setMenuVisible(false)}>
			<SelectModal
				modalVisible={isModalVisible}
				title={strings.createEvent}
				subTitle={strings.subStringEvent}
				onClose={() => setIsModalVisible(false)}
				items={MacroAreaEventSelect}
				buttons={[
					{
						text: strings.create,
                        onPress: (itemId: number) => {
                            if (MacroAreaType[itemId].type === dashboardType.food) {
                                props.navigation.navigate(PageNames.createEventFood,
                                    {
                                        event: undefined,
                                        userId: undefined, role: undefined,
                                        macroarea: {
                                            key: MacroAreaType[itemId].type,
                                            label: MacroAreaType[itemId].label
                                        },
                                        edit: false,
                                    });
                            } else if (MacroAreaType[itemId].type === dashboardType.wellness) {
                                props.navigation.navigate(PageNames.createEventWellness,
                                    {
                                        event: undefined,
                                        userId: undefined, role: undefined,
                                        macroarea: {
                                            key: MacroAreaType[itemId].type,
                                            label: MacroAreaType[itemId].label
                                        },
                                        edit: false,
                                    });
                            }
							setIsModalVisible(false);
							props.setMenuVisible(false);
						}
					}
				]}
			/>

			<CloseIcon pressHandler={() => props.setMenuVisible(false)} />

			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setMenuVisible(false)} />
				<FollowUsersAlert
					modalVisible={showInviteUsers}
					setModalVisible={(isVisible: boolean) => setShowInvitedUsers(isVisible)}
					isWeb={props.width > 1050}
					invite={false}
				/>

				<HeaderCreationComponent
					title={strings.event}
					text={strings.createNew}
					buttonText={strings.events}
					imagePath={images.icEvent}
					iconType={MenuItems.createEvent}
					onClick={navigateToCreateEvent}
				/>

				<HeaderCreationComponent
					title={strings.myCalendar}
					text={strings.viewThe}
					buttonText={strings.calendar}
					imagePath={images.icCalendar}
					iconType={MenuItems.myCalendar}
					onClick={() => navigate(PageNames.calendar, { isMyCalendar: true })}
				/>

				{props.ableToFollowFriends ? (
					<HeaderCreationComponent
						title={strings.friend}
						text={strings.addFriend}
						buttonText={strings.friends}
						imagePath={images.icAddUser}
						iconType={MenuItems.addFriend}
						onClick={() => setShowInvitedUsers(true)}
					/>
				) : null}
				<HeaderCreationComponent
					title={strings.groups}
					text={strings.seeAllGroups}
					buttonText={strings.groups}
					imagePath={images.icGroup}
					iconType={MenuItems.createGroup}
					onClick={() => navigate(PageNames.groups)}
				/>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.8,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	closeIcon: {
		position: "absolute",
		right: "20%",
		top: "15%",
		zIndex: 9999,
	},
});

export default HeaderCreationModal;
