import { useEffect } from "react";

interface props {
	action: string;
	onVerify?: (token?: string) => void;
	onExpired?: () => void;
}

const RecaptchaComponentWeb = (props: props) => {
	useEffect(() => {
		initRecaptcha();
	}, []);

	const initRecaptcha = function () {
		try {
			grecaptcha.enterprise.render("recapatcha", {
				sitekey: process.env.SITEKEY,
				callback: props.onVerify,
				"expired-callback": props.onExpired,
				action: props.action, // from the documentation there should be a parameter action here :(
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div style={{ marginLeft: 10 }}>
			<div id="recapatcha"></div>
		</div>
	);
};

export default RecaptchaComponentWeb;
