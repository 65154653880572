
export enum fonts {
	RalewayBlack = "RalewayBlack",
	RalewayBlackItalic = "RalewayBlackItalic",
	RalewayBold = "RalewayBold",
	RalewayBoldItalic = "RalewayBoldItalic",
	RalewayExtraBold = "RalewayExtraBold",
	RalewayExtraBoldItalic = "RalewayExtraBoldItalic",
	RalewayExtraLight = "RalewayExtraLight",
	RalewayExtraLightItalic = "RalewayExtraLightItalic",
	RalewayItalic = "RalewayItalic",
	RalewayLight = "RalewayLight",
	RalewayLightItalic = "RalewayLightItalic",
	RalewayMedium = "RalewayMedium",
	RalewayMediumItalic = "RalewayMediumItalic",
	RalewayRegular = "RalewayRegular",
	RalewaySemiBold = "RalewaySemiBold",
	RalewaySemiBoldItalic = "RalewaySemiBoldItalic",
	RalewayThin = "RalewayThin",
	RalewayThinItalic = "RalewayThinItalic",
}
 