import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import { Settings, StyleSheet, View } from "react-native";
import DashboardScreen from "../screens/DashboardScreen";
import YourEventsScreen from "../screens/YourEventsScreen";
import EventDetailScreen from "../screens/EventDetailScreen";
import FilterScreen from "../screens/filterScreen/FilterScreen";
import HomeScreen from "../screens/HomeScreen";
import LogInScreen from "../screens/LogInScreen";
import PlaceManagerScreen from "../screens/PlaceManagerScreen";
import PlacesScreen from "../screens/PlacesScreen";
import ProfileScreenContainer from "../screens/ProfileScreen";
import SettingsScreen from "../screens/SettingsScreen";
import SignUpScreenConnectedContainer from "../screens/SignUpScreen";
import UserScreen from "../screens/UserScreen";
import ProfileScreen from "../screens/ProfileScreen";
import SupportScreen from "../screens/SupportScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import FaqScreen from "../screens/faqScreen";
import UsersScreen from "../screens/UsersScreen";
import { PageNames } from "./NavigationUtils";
import GroupsScreen from "../screens/GroupsScreen";
import CreateGroupScreenConnectedContainer from "../screens/CreateGroupScreen";
import ChatScreen from "../screens/ChatScreen";
import CalendarScreen from "../screens/CalendarScreen";
import MeetInstructionsScreen from "../screens/MeetInstructionsScreen";
import GroupDetailScreen from "../screens/GroupDetailScreen";
import useCreateEventFoodController from "../screens/createEvent/food/controller/useCreateEventFoodController";
import CreateEventScreenFoodConnectedContainer from "../screens/createEvent/food/view/CreateEventScreenFood";
import CreateEventScreenWellnessConnectedContainer from "../screens/createEvent/wellness/view/CreateEventScreenWellness";
import ActivityDetailScreen from "../screens/ActivityDetailScreen";

const Drawer = createDrawerNavigator();

export default function Menu(props: any) {
	return (
		<>
			<NavigationContainer>
				<Drawer.Navigator initialRouteName={PageNames.home} backBehavior="history">
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.home}
						component={HomeScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.signup}
						component={SignUpScreenConnectedContainer}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.login}
						component={LogInScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.dashboard}
						component={DashboardScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.places}
						component={PlacesScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.placeManager}
						component={PlaceManagerScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.users}
						component={UsersScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.user}
						component={UserScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.profile}
						component={ProfileScreenContainer}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.createEventFood}
						component={CreateEventScreenFoodConnectedContainer}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.createEventWellness}
						component={CreateEventScreenWellnessConnectedContainer}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.yourEvents}
						component={YourEventsScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.eventDetail}
						component={EventDetailScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.activityDetail}
						component={ActivityDetailScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.filter}
						component={FilterScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.settings}
						component={SettingsScreen}
					/>

					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.support}
						component={SupportScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.privacyPolicy}
						component={PrivacyPolicyScreen}
					/>
					<Drawer.Screen
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.faq}
						component={FaqScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.chat}
						component={ChatScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.groups}
						component={GroupsScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: false,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.groupDetail}
						component={GroupDetailScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.createGroup}
						component={CreateGroupScreenConnectedContainer}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.calendar}
						component={CalendarScreen}
					/>
					<Drawer.Screen
						{...props}
						options={{
							headerShadowVisible: false,
							headerShown: false,
							swipeEnabled: true,
							drawerItemStyle: { height: 0 },
						}}
						name={PageNames.meetInstructions}
						component={MeetInstructionsScreen}
					/>
				</Drawer.Navigator>
			</NavigationContainer>
		</>
	);
}
