import { Platform, View, Text, Pressable, Modal, StyleSheet } from "react-native";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import Button from "../Button";
import CreateEventCalendarsComponent from "./CreateEventCalendarsComponent";
import { UserCalendarService } from "../../services/UserCalendarService";
import CustomTextInput from "../CustomTextInput";
import { CreateCalendarEventPayload } from "../../models/calendarEventsModels";

interface Props {
	alertVisible: boolean;
	setAlertVisible: (isVisible: boolean) => any;
    userId: string;
}

const InsertIndisponibilityAlert = (props: Props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;


    const [date, setDate] = useState<{ startDate: string | undefined, endDate: string | undefined }>({
        startDate: undefined,
        endDate: undefined
    })
    const [reason, setReason] = useState('');
   


	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

    const save = async () => {
        try {
           if (date.startDate && date.endDate) {
                const payload: CreateCalendarEventPayload = {
                    summary: reason,
                    start: {
                        dateTime: dayjs(date.startDate).toISOString(),
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    },
                    end: {
                        dateTime: dayjs(date.endDate).toISOString(),
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                   
                }
                await UserCalendarService.createClosedEvent(props.userId, payload);
                
                clearAndExit();
            }
        } catch (e: any) {
            console.log("error " + JSON.stringify(e))
        }
    }

    const clearAndExit = () => {
        setDate({ startDate: undefined, endDate: undefined });
        props.setAlertVisible(false);
    }

	return (
		<Modal animationType="fade" transparent={true} visible={props.alertVisible} onRequestClose={() => props.setAlertVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setAlertVisible(false)} />
			<View style={styles.container}>
				<View style={styles.content}>
                    <Text style={styles.title}>{strings.insertIndisponibility}</Text>

                    <CustomTextInput
                        title={strings.reasonForUnavailability}
                        value={reason}
                        placeholder={''}
                        setValue={(value: string) => setReason(value)} 
                        obligatory={true}                    
                    />

                    <View style={{ zIndex: 999 }}>
                        <CreateEventCalendarsComponent
                            maxDate={undefined}
                            minDate={(new Date()).toString()}
                            isReadOnly={false}
                            startDate={date.startDate ?? undefined}
                            endDate={date.endDate ?? undefined}
                            event={date}
                            setEvent={(event: any) => setDate(event)} 
                            setValidDate={(isValid: boolean) => null}
                            monthDataWeb={[]}
                            monthDataMobile={null}
                            webAgendaDaySelected={null}
                            markedDates={null}
                        />
                    </View>


                    <View style={{ flexDirection: 'row', justifyContent: 'center', zIndex: 0 }}>
                        <Button 
                            handleButtonPress={() => clearAndExit()} 
                            text={strings.cancel} 
                            filledButton={false}
                            style={{ marginTop: 50, marginRight: 5, zIndex: 0  }}
                        />
                        <Button 
                            handleButtonPress={() => save()} 
                            text={strings.save} 
                            filledButton={true}
                            disabled={(date.startDate === undefined || date.endDate === undefined || reason === '')}
                            style={{ marginTop: 50, marginLeft: 5,  zIndex: 0 }}
                        />
                    </View>
                    

				</View>
			</View>
		</Modal>
	);
};


const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 30,
		paddingBottom: 50,
		width: Platform.OS === "web" ? 500 : "80%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: 20,
		marginTop: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
});


export default InsertIndisponibilityAlert;
