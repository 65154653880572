import { TouchableOpacity } from "react-native";
import { Text, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface IButtonProps {
	handleButtonPress: () => void;
	text: string;
	style?: any;
	textStyle?: any;
	disabled?: boolean;
	filledButton: boolean;
	invertColor?: boolean;
}

const Button = (props: IButtonProps) => {
	const textColor = !!props.invertColor ? (props.filledButton ? styles.colBlue : styles.colWhite) : {};

	const btnColor = !!props.invertColor ? (props.filledButton ? styles.bkgWhite : { ...styles.bkgBlue, ...styles.borderWhite }) : {};

	return (
		<>
			{props.disabled ? (
				<TouchableOpacity
					style={[styles.button, props.filledButton ? styles.buttonFilled : styles.buttonEmpty, { opacity: 0.5 }, btnColor, props.style && props.style]}
					disabled={props.disabled}
					onPress={() => props.handleButtonPress()}
				>
					<Text
						style={[styles.buttonText, props.filledButton ? { color: colors.white } : { color: colors.blue }, textColor, props.textStyle && props.textStyle]}
					>
						{props.text}
					</Text>
				</TouchableOpacity>
			) : (
				<TouchableOpacity
					style={[styles.button, props.filledButton ? styles.buttonFilled : styles.buttonEmpty, btnColor, props.style && props.style]}
					disabled={props.disabled}
					onPress={() => props.handleButtonPress()}
				>
					<Text
						style={[styles.buttonText, props.filledButton ? { color: colors.white } : { color: colors.blue }, textColor, props.textStyle]}
					>
						{props.text}
					</Text>
				</TouchableOpacity>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	button: {
		alignSelf: "center",
		paddingLeft: 15,
		paddingRight: 15,
		borderRadius: 25,
		paddingTop: 10,
		paddingBottom: 10,
		alignContent: "center",
		zIndex: 1,
	},
	buttonFilled: {
		backgroundColor: colors.blue,
	},
	buttonEmpty: {
		backgroundColor: colors.white,
		borderColor: colors.blue,
		borderWidth: 2,
	},
	buttonText: {
		fontSize: 14,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		textTransform: "uppercase",
		color: colors.white,
	},
	colWhite: {
		color: colors.white,
	},
	colBlue: {
		color: colors.blue,
	},
	bkgWhite: {
		backgroundColor: colors.white,
	},
	bkgBlue: {
		backgroundColor: colors.blue,
	},
	borderWhite: {
		borderColor: colors.blue,
	},
	borderBlue: {
		borderColor: colors.blue,
	},
});

export default Button;
