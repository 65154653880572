
import { View, Pressable, Text } from "react-native";
import CustomTextInput from "../../../components/CustomTextInput";
import { strings } from "../../../locales/strings";
import { colors } from "../../../resources/colors";
import { styles } from "../../../styles/CreateEventScreen";
import { EventStatus } from "../../../utils/EventStatus";
import { roles } from "../../../utils/Roles";
import CollaboratorsList from "../../../components/Events/CollaboratorsList";
import { CreateEventCollaborator, Event } from "../../../models/eventsModels";

interface IProps {
    isFullWidth: boolean;
    isWeb: boolean;
    edit: boolean;
    role: roles;
    isReadOnly: boolean;
    propsEvent?: Event;
    event: any;
    setEvent: (event: any) => void;
    collaborators: Array<CreateEventCollaborator>;
    setCollaborators: (value: Array<CreateEventCollaborator>) => void;
    openSubEvent: () => void;
}

export const CollaboratorsView = (props: IProps) => {

    return <View style={{ width: props.isFullWidth ? "100%" : "50%", zIndex: 0 }}>
        <View style={{ marginRight: props.isWeb ? 10 : 0 }}>
            {/* Collaborators List */}
            <Text style={[styles.fieldTitle, { marginTop: 20 }]}>{strings.collaborators}</Text>

            {props.edit && props.role === roles.serviceCoordinator && props.propsEvent?.correlatedEventId && props.propsEvent.correlatedEventId !== "" &&
                <>
                    {/* IF HAS SUBEVENT */}
                    {props.propsEvent?.correlatedEventId && props.propsEvent.correlatedEventId != "" ? (
                        <>
                            <Text style={[styles.fieldDescription, { marginRight: 20, color: colors.red }]}>
                                {props.propsEvent.type === "lunch" ? strings.attentionGroceryEvent : strings.attentionLunchEvent}
                            </Text>
                            <Pressable onPress={props.openSubEvent}>
                                <Text style={[styles.fieldDescription, { marginRight: 20, color: colors.red, textDecorationLine: "underline" }]}>
                                    {strings.goToSubEvent}
                                </Text>
                            </Pressable>
                        </>
                    ) : null}
                </>}

            <Text style={[styles.fieldDescription, { marginRight: 20 }]}>{strings.collaboratorsDescription}</Text>
            {props.isReadOnly ?
                <Text style={[styles.readonly, props.event.collaboratorsDescription === "" ? { height: 150 } : null]}>{props.event.collaboratorsDescription}</Text>
                :
                <CustomTextInput
                    maxLenght={500}
                    numberOfLine={8}
                    value={props.event.collaboratorsDescription}
                    placeholder={""}
                    title={""}
                    obligatory={false}
                    styleTextInput={[{ marginLeft: 0, marginRight: 0 }, props.isWeb ? { width: "100%" } : { width: "100%" }]}
                    setValue={(value: string) => props.setEvent({ ...props.event, collaboratorsDescription: value })}
                />}
            {props.role === roles.serviceCoordinator && props.edit && props.propsEvent?.status != EventStatus.draft &&
                <CollaboratorsList collaborators={props.collaborators} setCollaborators={props.setCollaborators} />}
        </View>
    </View>;
}
