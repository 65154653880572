
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { strings } from '../../../../../locales/strings';
import { AlertTypes } from '../../../../../utils/AlertTypes';
import { ThirdPageViewModel } from '../../../../../models/createEventModels';
import { useNavigation } from '@react-navigation/native';
import { SearchProps } from '../../../../../models/models';
import { UsersEndpoints } from '../../../../../services/UsersEndpoints';


const useThirdPageController = ({ propsEvent, invitedUserIds}: ThirdPageViewModel) => {

    const navigation = useNavigation<any>();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
    }, []);

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    
    const USER_PER_PAGE = 10;
    const DEFAULT_FILTERS: SearchProps = { searchTerm: "", page: 1, userFilter: "all", sorting: "asc" };
    const [usersUnFollowing, setUsersUnFollowing] = useState<{ totalCounts: number; items: any[] }>({ totalCounts: 0, items: [] });
    const [usersFollowing, setUsersFollowing] = useState<{ totalCounts: number; items: any[] }>({ totalCounts: 0, items: [] });

    const [filtersUnFollowing, setFiltersUnFollowing] = useState<SearchProps>(DEFAULT_FILTERS);
    const [filtersFollowing, setFiltersFollowing] = useState<SearchProps>(DEFAULT_FILTERS);

    const getUsersUnFollowing = async () => {
        try {
            const res = await UsersEndpoints.getUsers(
                filtersUnFollowing.page,
                USER_PER_PAGE,
                filtersUnFollowing.searchTerm,
                filtersUnFollowing.sorting,
                [],
                "unFollowing"
            );

            let items = isWeb || filtersUnFollowing.page === 1 ? res.page.items : usersUnFollowing.items.concat(res.page.items);
            let usersUnFollowingInvited: any = [];

            items.forEach((item) => {
                let userItem = {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    invited: item.invited || invitedUserIds.items.includes(item.id) || propsEvent?.listOfInvitedUser?.includes(item.id) ? true : false,
                };

                usersUnFollowingInvited.push(userItem);
            });

            setUsersUnFollowing({ totalCounts: res.total, items: usersUnFollowingInvited });
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
        }
    };
    
    const getUsersFollowing = async () => {
        try {
            const resFollowing = await UsersEndpoints.getUsers(
                filtersFollowing.page,
                USER_PER_PAGE,
                filtersFollowing.searchTerm,
                filtersFollowing.sorting,
                [],
                "following"
            );

            let itemsFollowing = isWeb || filtersFollowing.page === 1 ? resFollowing.page.items : usersFollowing.items.concat(resFollowing.page.items);
            let usersFollowingInvited: any = [];
            itemsFollowing.forEach((item) => {
                let userItem = {
                    id: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    invited: item.invited || invitedUserIds.items.includes(item.id) || propsEvent?.listOfInvitedUser?.includes(item.id) ? true : false,
                };
                usersFollowingInvited.push(userItem);
            });

            setUsersFollowing({ totalCounts: resFollowing.total, items: usersFollowingInvited });
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
        }
    };
    
    const clearFields = () => {
        setUsersUnFollowing({ totalCounts: 0, items: [] });
        setUsersFollowing({ totalCounts: 0, items: [] });
        setFiltersUnFollowing(DEFAULT_FILTERS);
        setFiltersFollowing(DEFAULT_FILTERS);
    };

    useEffect(() => {
        getUsersUnFollowing();
    }, [filtersUnFollowing]);

    useEffect(() => {
        getUsersFollowing();
    }, [filtersFollowing]);
    
    return {
        navigation, isWeb, width, DEFAULT_FILTERS, USER_PER_PAGE,
        usersUnFollowing, setUsersUnFollowing,
        usersFollowing, setUsersFollowing,
        filtersUnFollowing, setFiltersUnFollowing,
        filtersFollowing, setFiltersFollowing,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
    };
};

export default useThirdPageController;

