import WorkingHours from "../components/WorkingHours";
import { strings } from "../locales/strings";
import { WorkingHoursDay } from "../models/models";

export interface WorkingDay {
  weekDay: Weekday;
  selected: boolean,
  workingHours: RangeHours[],
}

export interface RangeHours {
  start: Hour,
  end: Hour,
}

export interface Hour {
  hour: string,
  min: string
}


export enum Weekday {
  MO = "MO",
  TU = "TU",
  WE = "WE",
  TH = "TH",
  FR = "FR",
  SA = "SA",
  SU = "SU",
}

export const WeekDayTranslations: Record<Weekday, string> = {
  [Weekday.MO]: strings.weekDays.monday,
  [Weekday.TU]: strings.weekDays.tuesday,
  [Weekday.WE]: strings.weekDays.wednesday,
  [Weekday.TH]: strings.weekDays.thursday,
  [Weekday.FR]: strings.weekDays.friday,
  [Weekday.SA]: strings.weekDays.saturday,
  [Weekday.SU]: strings.weekDays.sunday,
}

export interface WorkingHoursSlot {
  start: string;
  end: string;
}

export interface WorkingHoursReponse {
  placeId: string;
  days: WorkingHoursDay[];
}

export function mapWorkingDayToWorkingHoursDay(workingDay: WorkingDay[]): WorkingHoursDay[] {
  return workingDay.map(day => {
    return {
      weekDay: day.weekDay,
      slots: !day.selected ? [] : day.workingHours.map(range => {
        return {
          start: mapHourToString(range.start),
          end: mapHourToString(range.end),
        }
      })
    }
  });
}

export function mapWorkingHoursDayToWorkingDay(workingHoursDay: WorkingHoursDay[]): WorkingDay[] {
  return workingHoursDay.map(day => {
    return {
      weekDay: day.weekDay,
      selected: day.slots.length > 0,
      workingHours: day.slots.map(slot => {
        return {
          start: mapStringToHour(slot.start),
          end: mapStringToHour(slot.end),
        }
      })
    }
  });
}

export function mapHourToString(time: Hour): string {
  const timeMinParsed = parseInt(time.min);

  return `${time.hour}:${timeMinParsed < 10 ? '0' : ''}${timeMinParsed}`;
}

export function mapStringToHour(time: string): Hour {
  const [hour, min] = time.split(':');
  return {
    hour,
    min
  }
}

/**
 * Check if there is at least a slot specified
 * @param workingHoursDay 
 */
export function isWorkingHoursDaysEmpty(workingHoursDay: WorkingHoursDay[]): boolean {
  return !workingHoursDay.find(day => day.slots.length > 0);
}