import { useState } from "react";
import { KeyboardTypeOptions, Platform, TextInput, View } from "react-native";
import { Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { colors } from "../resources/colors";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { fonts } from "../resources/fonts";

interface props {
	title: string;
	description?: string,
	value?: string;
	placeholder: string;
	numberOfLine?: number;
	styleTextInput?: any;
	styleText?: any;
	style?: any;
	keyboardType?: KeyboardTypeOptions;
	secureTextEntry?: boolean;
	halfWidth?: boolean;
	obligatory: boolean;
	setValue?: (value: string) => void;
	onBlur?: (e?: any) => void;
	disabled?: boolean;
	containerStyle?: any;
	maxLenght?: number;
	fieldValidationPattern?: RegExp,
	patternErrorMsg?: string
	isReadOnly?: boolean,
}

const CustomTextInput = (props: props) => {
	const [isVisible, setIsVisible] = useState(props.secureTextEntry);

	const [showPatternErrorMsg, setShowPatternErrorMsg] = useState(false);

	const validateFieldValue = (fieldValue: string, fieldValidationPattern: RegExp | undefined = props.fieldValidationPattern): void => {
		if (!fieldValidationPattern) {
			setShowPatternErrorMsg(false);
			return;
		}

		// Always allow empty field (handled by required eventually)
		if (fieldValue === '') {
			setShowPatternErrorMsg(false);
			return;
		}

		const isValid = fieldValidationPattern.test(fieldValue);
		setShowPatternErrorMsg(!isValid);
	}

	return (
		<View style={[styles.container, props.halfWidth ? { width: "50%" } : null, props.containerStyle]}>
			<View style={[styles.headerContainer, props.styleText && props.styleText]}>
				<View style={styles.titleContainer}>
					<Text style={[styles.textInputTitle]}>{props.title}</Text>
					{props.obligatory ? <Text style={[styles.asterisk]}>*</Text> : null}
				</View>
				{props.description ? (<Text style={[styles.fieldDescription]}>{props.description}</Text>) : null}
			</View>

			{props.isReadOnly ?
				(<Text style={[styles.readonly, props.styleTextInput && props.styleTextInput]}>{props.value}</Text>)
				:
				<>
					<View style={[styles.inputContainer, props.styleTextInput && props.styleTextInput, showPatternErrorMsg ? { borderColor: colors.red } : null]}>
						<TextInput
							maxLength={props.maxLenght ? props.maxLenght : 100}
							autoCapitalize="none"
							multiline={props.numberOfLine != null}
							numberOfLines={props.numberOfLine ? props.numberOfLine : 1}
							style={[
								styles.textInput,
								props.numberOfLine ? { height: Platform.OS === "web" ? "100%" : 80, paddingTop: 10 } : { height: 50 },
								Platform.OS === "web" ? { outlineWidth: 0 } : null,
								props.style && props.style,
								props.secureTextEntry ? { paddingRight: 40 } : null,
								props.disabled && { backgroundColor: colors.greyReadOnly }
							]}
							onChangeText={(value) => !!props.setValue && props.setValue(value)}
							value={props.value}
							onBlur={() => { validateFieldValue(props.value ?? ""); props.onBlur ? props.onBlur() : null }}
							secureTextEntry={props.secureTextEntry ? isVisible : false}
							placeholder={props.placeholder}
							placeholderTextColor={colors.grey}
							keyboardType={props.keyboardType ? props.keyboardType : "default"}
							disabled={!!props.disabled}
							showSoftInputOnFocus={!props.disabled}
							editable={!props.disabled}
						/>
						<View style={styles.passwordIcon}>
							{props.secureTextEntry ? (
								<TouchableOpacity onPress={() => setIsVisible(!isVisible)}>
									{!isVisible ? <FontAwesomeIcon size={20} icon={faEye} /> : <FontAwesomeIcon size={20} icon={faEyeSlash} />}
								</TouchableOpacity>
							) : null}
						</View>
					</View>

					{showPatternErrorMsg ? (
						<Text style={styles.errorText}>{props.patternErrorMsg}</Text>
					) : null}
				</>}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 20,
	},
	textInputTitle: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
	},
	textInput: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
	},
	inputContainer: {
		backgroundColor: colors.white,
		//width: "90%",
		marginHorizontal: 16,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
	},
	passwordIcon: {
		height: 20,
		width: 20,
		position: "absolute",
		right: 10,
		top: 15,
	},
	headerContainer: {
		marginBottom: 10,
		marginHorizontal: 16,
	},
	titleContainer: {
		flexDirection: "row",
		height: 'auto',
	},
	asterisk: {
		fontFamily: fonts.RalewayBold,
		fontSize: 30,
		height: 0, // Otherwise it makes the row height more than necessary
		marginTop: -5,
		color: colors.black,
	},
	errorText: {
		color: colors.red,
		marginLeft: "5%",
		fontFamily: fonts.RalewayRegular,
	},
	fieldDescription: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
	},
	readonly: {
		backgroundColor: colors.greyReadOnly,
		color: colors.black,
		borderRadius: 10,
		fontSize: 14,
		padding: 15,
		fontFamily: fonts.RalewayRegular,
		borderColor: colors.grey,
		borderWidth: 1,
		opacity: 0.8,
		overflow: "hidden",
	},
});

export default CustomTextInput;
