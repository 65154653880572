import { View, StyleSheet } from "react-native";
import { strings } from "../locales/strings";
import Tag from "./Tag";

interface Props {
	roles: string[];
	rolesToNotShow?: string[];
}

const RoleTags = ({ roles, rolesToNotShow = [] }: Props) => (
	<View style={[styles.tags]}>
		{roles
			.filter((r) => !rolesToNotShow.includes(r))
			.map((role) => (
				<Tag style={{ marginRight: 10 }} text={strings.roles[role] || role} key={`tag-${role}`} />
			))}
	</View>
);

const styles = StyleSheet.create({
	tags: {
		flexDirection: "row",
		marginRight: 10,
		marginVertical: 5
	},
});

export default RoleTags;
