
import { Text, StyleSheet, View, Modal, Pressable } from "react-native";
import { MobileChatIcons } from "../../models/models";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { ChatUtils, mobileChatIcons } from "../../utils/ChatUtils";

interface ModalProps {
	visible: boolean;
	setVisible: (isVisible: boolean) => void;
	onMobileIconClicked: (index: number) => void;
}

const FeaturesChatModal = ({visible, setVisible, onMobileIconClicked}: ModalProps) => {

    const close = () => {
        setVisible(false);
    }

	return (
		<Modal animationType="fade" transparent={true} visible={visible} onRequestClose={() => close()}>
			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => close()} />
				<View style={[styles.content]}>
                    {mobileChatIcons.map((item: MobileChatIcons, index: number) => (
                        <Pressable style={styles.row} onPress={() => {
									onMobileIconClicked(index);
								}} 
								key={`chat-icon${index}`}>
								{ ChatUtils.getChatIcon(item.icon) }                            
							<Text style={styles.iconName}>{item.name}</Text>
                        </Pressable>
                    ))}
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "flex-end",
		alignItems: "baseline",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingHorizontal: "3%",
        width: '100%',
        paddingTop: 20,
        paddingBottom: 40
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
    iconName: {
        fontFamily: fonts.RalewayRegular,
		color: colors.black,
        fontSize: 16,
        marginLeft: 10,
    },
    row: {
		flexDirection: 'row',
        marginVertical: 15,
		marginHorizontal: 10
    }
});

export default FeaturesChatModal;
