import { strings } from "../locales/strings";
import { dashboardType } from "./DashboardType";
import { MacroAreaType } from "./types/MacroAreaType";



export const activityFilter = {
	title: strings.activityFilter,
	filters: [
		{ key: '',  value :  strings.all, selected: true },
		{ key: MacroAreaType[0].type, value: MacroAreaType[0].label, selected: false },
		{ key: MacroAreaType[1].type, value:  MacroAreaType[1].label,selected: false }, 
		/*{ key:  MacroAreaType[2].type, value:  MacroAreaType[2].label,selected: false }, // not available right now*/
	],
};

export const distanceFilter = {
	title: strings.distanceFilter,
	filters: [
		{ key: strings.all, selected: true, value: NaN },
		{ key: strings.twoKm, selected: false, value: 2 },
		{ key: strings.threeKm, selected: false, value: 3 },
		{ key: strings.fiveKm, selected: false, value: 5 },
	],
};
