import IcMan from '../../assets/images/ic_man.svg';
import IcGender from '../../assets/images/ic_gender.svg';
import IcWoman from '../../assets/images/ic_women.svg';
import Svg, { Image } from 'react-native-svg'
import { Platform, View } from "react-native";
import { images } from "../../resources/images";
import { IconProps } from "../../models/models";
import { genders } from "../../utils/Genders";

type Props = IconProps & {
  gender?: genders | string
}

const UserGenderIcon = ({ size = 24, gender = genders.other }: Props) => {

  const path = gender === 'man'
    ? images.icMan
    : gender === 'woman'
      ? images.icWoman
      : images.icGender

  const Icon = gender === 'man'
    ? IcMan
    : gender === 'woman'
      ? IcWoman
      : IcGender
  return (
    <View >
      {
        Platform.OS === 'web' ?
          <Svg width={`${size}`} height={`${size}`}>
            <Image width={size} height={size} href={path} />
          </Svg>
          :
          <Icon width={size} height={size} />
      }
    </View>
  );
};

export default UserGenderIcon;
