import { RoleOption } from "../models/models";

export enum roles {
	user = "user",
	serviceCoordinator = "service-coordinator",
	externalUser = "external-user",
	internalUser = "internal-user",
	placeManager = "place-manager",
}

export const rolesList: RoleOption[] = [
	{
		label: "Coordinatore dei servizi",
		name: "Coordinatore dei servizi",
		value: "service-coordinator",
		id: "service-coordinator",
	},
	{
		label: "Operatore esterno",
		name: "Operatore esterno",
		value: "external-user",
		id: "external-user",
	},
	{
		label: "Operatore interno",
		name: "Operatore interno",
		value: "internal-user",
		id: "internal-user",
	},
	{
		label: "Gestore dei luoghi",
		name: "Gestore dei luoghi",
		value: "place-manager",
		id: "place-manager",
	},
];
