import React, { useState } from "react";
import { View, Image } from "react-native";
import { styles } from "../../styles/CreateEventScreen";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

interface PropsInterface {
	rightImage: boolean;
	placeImage: string;
	hidePlaceholcer?: boolean;
}

const PlaceImage = (props: PropsInterface) => {
	const [img, setImg] = useState('')
	React.useEffect(() => {
		setImg(props.placeImage)
	}, [props]);

	return (
		<View style={[styles.placeImageContainer, !props.rightImage ? { marginRight: 10 } : null]}>
			{img === "" ? (
				<>{props.hidePlaceholcer ? null : <FontAwesomeIcon size={30} icon={faImage}></FontAwesomeIcon>}</>
			) : (
				<Image style={{ height: "100%", width: "100%", borderRadius: 10 }} source={{ uri: img, cache: "reload", }}></Image>
			)}
		</View>
	);
};

export default PlaceImage;
