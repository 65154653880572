import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Pressable, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface props {
	pressHandler: () => void;
}

const CloseIcon = (props: props) => {
	return (
		<Pressable style={styles.closeIcon} onPress={props.pressHandler}>
			<FontAwesomeIcon color={colors.white} size={15} icon={faClose}></FontAwesomeIcon>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		marginLeft: "5%",
	},
	text: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
	},
	inputContainer: {
		backgroundColor: colors.white,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
		width: "50%",
		paddingLeft: 10,
		paddingRight: 10,
	},
	minus: {
		position: "absolute",
		left: 15,
		top: 15,
		zIndex: 9999,
	},
	closeIcon: {
		position: "absolute",
		right: 5,
		top: 10,
		zIndex: 9999,
	},
});

export default CloseIcon;
