import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safeArea: {
		backgroundColor: colors.primary,
	},
	content: {
		marginTop: Platform.OS === "web" ? 50 : 0,
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "5%"
	},
	webContainer: {
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "2%",
	},
	mainContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		shadowColor: colors.blue2Shadow,
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		elevation: 5,
		padding: 20,
		marginBottom: 40,
	},
	mainContainerWeb: {
		flexDirection: "row",
		width: "60%",
		marginRight: "3%",
		marginLeft: "8%",
	},
	smallWhiteContainer: {
		padding: 20,
		backgroundColor: colors.white,
		borderRadius: 10,
		shadowColor: colors.blue2Shadow,
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		elevation: 5,
	},
	sideColumn: {
		width: "29%",
	},
	imageContainer: {
		width: "100%",
		height: 200,
		marginTop: 20,
		borderRadius: 10,
	},
	webImageContainer: {
		transform: [{ translateX: -60 }],
		marginRight: -60,
	},
	coverImage: {
		height: "100%",
		marginRight: 10,
		borderRadius: 10,
	},
	backContainer: {
		flexDirection: "row",
		textDecorationLine: "underline",
		color: colors.blue,
	},
	backText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.blue,
		marginLeft: 5,
	},
	backIcon: {
		marginTop: 2,
	},
	eventType: {
		color: colors.grey,
		fontFamily: fonts.RalewayBold,
		fontSize: 12,
		textTransform: "uppercase",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
	},
	description: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		marginTop: 50,
	},
	eventDateTag: {
		marginRight: 8,
	},
	eventDateTags: {
		display: "flex",
		flexDirection: "row",
		marginTop: 20,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	eventPlace: {
		width: "70%",
		fontSize: 15,
		fontFamily: fonts.RalewayRegular,
	},
	locationIcon: {
		borderRadius: 50,
		borderWidth: 1,
		borderColor: colors.blue,
		padding: 10,
		height: 42
	},
	subtitle: {
		fontFamily: fonts.RalewayBold,
		fontSize: 14,
	},
	partecipateButton: {
		marginTop: 10,
		marginBottom: 10,
		alignSelf: "flex-start",
		paddingRight: 30,
		paddingLeft: 30,
	},
	button: {
		alignSelf: "flex-start",
		width: 100,
	},
	line: {
		borderTopColor: colors.grey,
		borderTopWidth: 1,
		marginTop: 20,
		marginBottom: 20,
	},
	operatorName: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.blue,
		textDecorationLine: "underline",
	},
	somePhotoOf: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
	},
	partecipantName: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
	},
	partecipantsIcon: {
		position: "absolute",
		marginTop: 10,
	},
	shareIcon: {
		margin: 10,
		height: 30,
		width: 30,
	},
	slideContainer: {
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		zIndex: -1,
	},
	overlay: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: colors.white,
		opacity: 0.5,
	},
	subevent: {
		fontSize: 15,
		fontFamily: fonts.RalewayRegular,
		marginTop: 10,
		marginBottom: 10,
		color: colors.red2,
		textDecorationLine: "underline",
	},
	videochatIcon: {
		marginLeft: 10,
		justifyContent: 'center',
		marginTop: -3
	},
	partecipantNameContainer: {
		marginLeft: 0,
		padding: 6,
		display: 'flex',
		flexDirection: 'row'
	}
});
