import Checkbox from "expo-checkbox";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { AppState, ScrollView, TextInput, View, Text, Platform, AppStateStatus } from "react-native";
import BasicAlert from "../components/Alerts/BasicAlert";
import Button from "../components/Button";
import CustomTextInput from "../components/CustomTextInput";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { strings } from "../locales/strings";
import { GetProfile, SupportEmailRequest } from "../models/models";
import { colors } from "../resources/colors";
import { EndPoints } from "../services/EndPoints";
import { styles } from "../styles/StaticFooterPages.style";
import { rules } from "../utils/Rules";
import * as yup from "yup";
import { AlertTypes } from "../utils/AlertTypes";
import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { roles } from "../utils/Roles";
import { SafeAreaView } from "react-native-safe-area-context";
import { PageNames } from "../navigation/NavigationUtils";

interface PropsInterface {
	navigation: any;
}

const SupportScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const isPlatformWeb = Platform.OS === "web";
	const focused = useIsFocused();
	const [isLogged, setIsLogged] = useState(false);

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [alertModal, setAlertModal] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText, type: AlertTypes.info });

	const validator = yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		email: yup.string().email().required(),
		message: yup.string().required(),
		termsAndConditions: yup.boolean().oneOf([true]),
	});

	const newMessage = {
		firstName: "",
		lastName: "",
		email: "",
		message: "",
		termsAndConditions: false,
	};

	const showAlert = (isVisible: boolean, title: string, text: string, type: AlertTypes) => {
		setAlertModal({ isVisible: isVisible, title: title, description: text, type: type });
	};

	const submitForm = async (values: SupportEmailRequest, { resetForm }: FormikHelpers<typeof newMessage>) => {
		setLoaderVisible(true);

		const emailMessage: SupportEmailRequest = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			message: values.message,
		};

		try {
			await EndPoints.sendSupportEmail(emailMessage);
		} catch (e: any) {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			showAlert(true, e.status, e.error.message, AlertTypes.error);
			return;
		}

		if (Platform.OS === "ios") {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		} else {
			setLoaderVisible(false);
		}
		showAlert(true, strings.support.sent, strings.support.successMessage, AlertTypes.success);
		resetForm({ values: newMessage });
	};

	useEffect(() => {
		if (isPlatformWeb) {
			window.addEventListener("resize", () => {
				console.log("width " + window.innerWidth);
				setWidth(window.innerWidth);
			});
		}
		checkIsLogged();
	}, [focused]);

	const checkIsLogged = async () => {
		const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
		if (id && id != "") {
			setIsLogged(true);
		} else {
			setIsLogged(false);
		}
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<ScrollView showsHorizontalScrollIndicator={false}>
				<Header
					width={width}
					navigation={props.navigation}
					onPress={() => props.navigation.navigate(PageNames.home)}
					showMenu={isLogged}
					showLoginButton={!isLogged}
					isWeb={isWeb}
				/>

				<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

				<BasicAlert
					title={alertModal.title}
					description={alertModal.description}
					buttonText={strings.ok}
					alertType={alertModal.type}
					alertVisible={alertModal.isVisible}
					setAlertVisible={(isVisible: boolean) => showAlert(isVisible, alertModal.title, alertModal.description, alertModal.type)}
				/>

				<Formik initialValues={newMessage} onSubmit={submitForm} enableReinitialize={true} validationSchema={validator}>
					{({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
						<View style={styles.container}>
							<View style={[styles.titleContainer, { height: "auto" }]}>
								<Text style={styles.textInputTitle}>{strings.support.header}</Text>
							</View>

							<View style={styles.titleContainer}>
								<Text style={styles.textInputTitle}>{strings.name}</Text>
							</View>
							<View style={styles.inputContainer}>
								<TextInput style={styles.textInput} value={values.firstName} onChangeText={handleChange("firstName")} />
							</View>

							<View style={styles.titleContainer}>
								<Text style={styles.textInputTitle}>{strings.surname}</Text>
							</View>
							<View style={styles.inputContainer}>
								<TextInput style={styles.textInput} value={values.lastName} onChangeText={handleChange("lastName")} />
							</View>

							<View style={styles.titleContainer}>
								<Text style={styles.textInputTitle}>{strings.email}</Text>
							</View>
							<View style={errors.email && touched.email && values.email != "" ? styles.inputContainerError : styles.inputContainer}>
								<TextInput style={styles.textInput} value={values.email} onChangeText={handleChange("email")} onBlur={handleBlur("email")} />
							</View>
							{errors.email && touched.email && values.email != "" ? <Text style={styles.errorText}>{strings.emailNotValid}</Text> : null}

							<View style={styles.titleContainer}>
								<Text style={styles.textInputTitle}>{strings.message}</Text>
							</View>
							<View style={styles.inputContainer}>
								<TextInput style={styles.textAreaInput} value={values.message} multiline onChangeText={handleChange("message")} />
							</View>

							<View style={styles.checkboxContainer}>
								<Checkbox value={values.termsAndConditions} onValueChange={(nextValue) => setFieldValue("termsAndConditions", nextValue)} color={colors.grey} />
								<Text style={styles.checkboxText}>{strings.acceptTermsAndConditions}</Text>
							</View>
							{errors.termsAndConditions ? <Text style={styles.errorText}>{strings.support.mustAcceptConditions}</Text> : null}

							<Button
								handleButtonPress={() => {
									handleSubmit();
								}}
								text={strings.send}
								filledButton={false}
								disabled={Object.keys(errors).length > 0 || values.firstName === "" || values.lastName === "" || values.email === "" || values.message === ""}
							/>
						</View>
					)}
				</Formik>

				<Footer isWeb={isWeb} width={width} navigation={props.navigation} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default SupportScreen;
