import { faAngleLeft, faLocationDot, faShare, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useEffect, useState } from "react";
import { View, Image, Pressable, Linking, Dimensions } from "react-native";
import { Platform, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Button from "../components/Button";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Tag from "../components/Tag";
import { strings } from "../locales/strings";
import { Event, getFieldColor } from "../models/eventsModels";
import { colors } from "../resources/colors";
import { getDefaultImageByScope, getDefaultImageByType, getEventDate, getTypeString } from "../utils/EventUtils";
import { EventParticipationType, EventType, EventTypeEnum } from "../utils/types/EventType";
import { styles } from "../styles/EventDetail.style";
import PlaceImage from "../components/Events/PlaceImage";
import { PlacesEndPoint } from "../services/PlacesEndPoint";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { UsersEndpoints } from "../services/UsersEndpoints";
import EventInfoAlert from "../components/Events/EventInfoAlert";
import PageNavigation from "../components/PageNavigation";
import { EventsEndPoint } from "../services/EventsEndPoint";
import { AlertTypes } from "../utils/AlertTypes";
import BasicAlert from "../components/Alerts/BasicAlert";
import MapView from "../components/Places/MapView";
import ActionAlert from "../components/Alerts/ActionAlert";
import Select from "../components/Select";
import { GetPartepationType, Partecipants, ParticipationsMode } from "../utils/types/Partecipants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { EndPoints } from "../services/EndPoints";
import { GetProfile, MacroArea, Meet } from "../models/models";
import dayjs from "dayjs";
import { roles } from "../utils/Roles";
import { EventStatus } from "../utils/EventStatus";
import FollowUsersAlert from "../components/FollowUsersAlert";
import { PageNames, } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";
import { ShareOptions, socialSharing } from "../utils/SocialShareUtils/SocialShare";
import ChatIcon from "../components/Icons/ChatIcon";
import VideochatIcon from "../components/Icons/VideochatIcon";
import { MeetService } from "../services/MeetService";
import ChatUnreadIcon from "../components/Icons/ChatUnreadIcon";
import { ChatService } from "../services/chat/ChatService";
import { dashboardType } from "../utils/DashboardType";
import { MacroAreaType } from "../utils/types/MacroAreaType";
const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

interface PropsInterface {
	navigation: any;
	route: {
		params: {
			event: Event;
		};
	};

}
enum ENUM_COLORS {
	'food' = colors.yellowCard,
	'wellness' = colors.greenCard
}

const EventDetailScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const [propsEvent, setPropsEvent] = useState(props.route.params.event);
	const [showInviteUsers, setShowInvitedUsers] = useState(false);
	const [macroArea, setMacroArea] = useState<MacroArea>({ key: '', label: '' });
	const [role, setRole] = useState("");

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		getUserRole();
		props.route.params.event.place ? getPlace(props.route.params.event.place._id) : null;
		getProfile(props.route.params.event);
		setPropsEvent(props.route.params.event);
		getHasUnreadMessages(props.route.params.event.chatRoomId);
		scrollRefScreen != null ? scrollToTop() : null;
		const item = MacroAreaType.find((item) => item.type === props.route.params.event.field);
		if (item) {
			setMacroArea({ key: item?.type, label: item?.label });
		}
	}, [props]);


	useEffect(() => {
		if (propsEvent.participationType === EventParticipationType.online || propsEvent.participationType === EventParticipationType.inPerson) {
			setPartecipantRoleSelected(Partecipants[6].value);
			setPartecipantModeSelected(propsEvent.participationType)
		}
	}, [propsEvent])

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const [mapViewVisible, setMapViewVisible] = useState(false);

	const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

	const date = getEventDate(propsEvent);

	const [placeImages, setPlaceImages] = useState({ img1: "", img2: "", img3: "", img4: "" });

	const getEvent = () => {
		if (propsEvent.id) {
			EventsEndPoint.getEvent(propsEvent.id)
				.then((res: any) => {
					getProfile(res.item);
					setPropsEvent(res.item);
					getHasUnreadMessages(res.item.chatRoomId);
				})
				.catch((e: any) => {
					setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
				});
		}
	};




	const getPlace = (id: string) => {
		PlacesEndPoint.getPlaceDetails(id)
			.then((res: any) => {
				console.log("images " + JSON.stringify(res.item.imgLinks));
				setPlaceImages({
					img1: res.item.imgLinks.length > 0 ? res.item.imgLinks[0] : "",
					img2: res.item.imgLinks.length > 1 ? res.item.imgLinks[1] : "",
					img3: res.item.imgLinks.length > 2 ? res.item.imgLinks[2] : "",
					img4: res.item.imgLinks.length > 3 ? res.item.imgLinks[3] : "",
				});
			})
			.catch((e: any) => { });
	};

	const getHasUnreadMessages = async (roomId: string) => {
		const unreadMessages = await ChatService.getRoomUnreadMessagesNumber(roomId);
		setHasUnreadMessages(unreadMessages.item.unreadMessages > 0);
	};

	const getUserRole = async () => {
		const role = await AsyncStorage.getItem(asyncStorageUtils.role);
		setRole(role ? role : "");
	};



	const [followingUserIds, setFollowingUserIds] = useState<{ items: string[] | undefined }>({ items: [] });
	const getProfile = async (eventDetails: any) => {
		await EndPoints.getProfile()
			.then((res: GetProfile) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setFollowingUserIds({ items: res.item.follows });
				getPartecipants(eventDetails, true, res.item.follows);
			})
			.catch((e) => {
				if (Platform.OS === "ios") {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	const [isPartecipant, setIsPartecipant] = useState(false);
	const [partecipantType, setPartecipantType] = useState("");
	const getPartecipants = async (eventDetails: any, isShortList: boolean, following?: string[]) => {
		if (eventDetails) {
			let userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
			let partecipants = eventDetails.participants ? eventDetails.participants : [];

			if (partecipants && partecipants.length > 0) {
				partecipants.forEach((user: any) => {
					console.log("partecipants " + JSON.stringify(user));
					if (user.userId === userId) {
						setIsPartecipant(true);
						setPartecipantType(GetPartepationType(user.type));
					}
					user.friends = userId === user.userId ? true : following ? following.includes(user.userId) : followingUserIds.items?.includes(user.userId);
				});
				isShortList ? setPartecipantsList(partecipants.slice(0, 6)) : setPartecipantsList(partecipants);
			}

			setPartecipantsNumber(partecipants.length);
		}
	};

	const checkDate = (): boolean => {
		return dayjs().isAfter(dayjs(propsEvent.startDate));
	};

	const [partecipantsNumber, setPartecipantsNumber] = useState(0);
	const [partecipantsList, setPartecipantsList] = useState<{ userId: string; name: string; type: string; friends: boolean }[]>([]);

	const [eventInfoVisible, setEventInfoVisible] = useState(false);
	const getMenuList = (): { title: string; text: string }[] => {
		let menu: { title: string; text: string }[] = [];
		menu.push({ title: strings.appetizer, text: propsEvent.menu?.appetizer ? propsEvent.menu?.appetizer : strings.noPlace });
		menu.push({ title: strings.mainCourse, text: propsEvent.menu?.mainCourse ? propsEvent.menu?.mainCourse : strings.noPlace });
		menu.push({ title: strings.secondCourse, text: propsEvent.menu?.secondCourse ? propsEvent.menu?.secondCourse : strings.noPlace });
		menu.push({ title: strings.dessert, text: propsEvent.menu?.dessert ? propsEvent.menu?.dessert : strings.noPlace });
		menu.push({ title: strings.other, text: propsEvent.menu?.other ? propsEvent.menu?.other : strings.noPlace });
		return menu;
	};

	const getGrocery = (): string => {
		let grocery = "";
		if (propsEvent && propsEvent.groceryList) {
			propsEvent.groceryList.forEach((item, index) => {
				if (item !== "" && propsEvent && propsEvent.groceryList) {
					index === propsEvent.groceryList.length - 1 ? (grocery = grocery + item) : (grocery = grocery + item + ", ");
				}
			});
		}
		return grocery != "" ? grocery : strings.noGrocery;
	};

	const share = async () => {
		if (Platform.OS === "android" || Platform.OS === "ios") {
			const shareOptions: ShareOptions = {
				title: strings.shareEvent.title + props.route.params.event.title,
				message: strings.shareEvent.message,
				url: props.route.params.event.img,
				social: "",
			};

			socialSharing(shareOptions);
		} else {
			console.log("social share web");
		}
	};

	const openSubEvent = () => {
		setLoaderVisible(true);
		if (propsEvent.correlatedEventId) {
			EventsEndPoint.getEvent(propsEvent.correlatedEventId)
				.then((res: any) => {
					setPropsEvent(res.item);
				})
				.catch((e: any) => {
					setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
				});
		}
		if (Platform.OS === "ios") {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		} else {
			setLoaderVisible(false);
		}
	};

	const [partecipantRoleSelected, setPartecipantRoleSelected] = useState("");
	const [showPartecipationModal, setShowPartecipationModal] = useState(false);
	const partecipateToEvent = async () => {
		setLoaderVisible(true);
		const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
		const name = await AsyncStorage.getItem(asyncStorageUtils.name);
		if (propsEvent.id && userId && name) {

			await EventsEndPoint.partecipate(propsEvent.id, userId, name, partecipantRoleSelected, partecipantModeSelected)
				.then((res: any) => {
					clearFields();
					getEvent();
				})
				.catch((e: any) => {
					clearFields();
					setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
				});
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const [partecipantModeSelected, setPartecipantModeSelected] = useState("");

	const clearFields = () => {
		setPartecipantRoleSelected("");
		setShowPartecipationModal(false);
		setPartecipantsList([]);
		setIsPartecipant(false);
		setPartecipantType("");
	};

	const selectType = (value: any) => {
		console.log("select type " + value);
		setPartecipantRoleSelected(value);
		setPartecipantModeSelected(EventParticipationType.inPerson)

	};
	const selectMode = (value: any) => {
		setPartecipantRoleSelected(Partecipants[6].value);
		setPartecipantModeSelected(value)
	}



	const addFriend = async (id: string, friends: boolean) => {
		setLoaderVisible(true);
		await UsersEndpoints.updateUserFollow({ userId: id, type: friends ? "unfollow" : "follow" })
			.then(() => {
				setLoaderVisible(false);
				getProfile(propsEvent);
			})
			.catch((e) => {
				setLoaderVisible(false);
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	const placePhotos = () => {
		return (
			<>
				<Text style={[styles.somePhotoOf, { marginTop: isWeb ? 20 : 0 }]}>{strings.somePhotosOf}</Text>
				{propsEvent.place ? (<Text style={[styles.subtitle, { marginBottom: 10 }]}>{propsEvent.place.name}</Text>) : (null)}
				<View style={!isWeb ? { marginBottom: -40 } : null}>
					<View style={[styles.rowView, { zIndex: -1, width: "100%" }, isWeb ? { transform: [{ translateX: -60 }], marginRight: -60 } : null]}>
						<PlaceImage rightImage={false} placeImage={placeImages.img1} hidePlaceholcer={true} />
						<PlaceImage rightImage={true} placeImage={placeImages.img2} hidePlaceholcer={true} />
					</View>
					<View style={[styles.rowView, isWeb ? { transform: [{ translateX: -60 }] } : null]}>
						<PlaceImage rightImage={false} placeImage={placeImages.img3} hidePlaceholcer={true} />
						<PlaceImage rightImage={true} placeImage={placeImages.img4} hidePlaceholcer={true} />
					</View>
				</View>
			</>
		);
	};

	const getPartecipantsList = (): string[] => {
		let p: string[] = [];
		if (propsEvent.participants && propsEvent.participants.length > 0) {
			propsEvent.participants.forEach((item) => {
				p.push(item.userId);
			});
		}
		return p;
	};

	const getListOfUsers = (users: any): string[] => {
		const members: string[] = [];
		users.forEach((el: any) => {
			members.push(el.userId);
		});
		return members;
	};

	const goToMeetInstructions = async (): Promise<void> => {
		setLoaderVisible(true);
		try {
			const meet = await MeetService.getEventMeet(propsEvent.id!);
			props.navigation?.navigate(PageNames.meetInstructions, { meet: meet.item });
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		} finally {
			setLoaderVisible(false);
		}
	};

	const getMeet = async (): Promise<Meet> => {
		const meet = await MeetService.getEventMeet(propsEvent.id!);
		return meet.item;
	};

	const showCollaboratorParticipation = (participant: (typeof Partecipants)[0]): boolean => {
		const collaborator = propsEvent.collaborators?.find((collab) => collab.text === participant.label);
		if (!collaborator) {
			return true;
		}

		const participantsNumber = propsEvent.participants?.filter((eventParticipant) => eventParticipant.type === participant.value).length;

		return Number(collaborator.n) > participantsNumber;
	};

	return (
		<SafeAreaView style={styles.safeArea}>
			<View style={[{ height: windowHeight * 0.8, width: "100%" }, styles.slideContainer]}>
				<Image
					source={propsEvent.img ? { uri: propsEvent.img } : propsEvent.field === dashboardType.food ? getDefaultImageByType(propsEvent.type) : getDefaultImageByScope(propsEvent.field)}
					style={{ width: "100%", height: windowHeight * 0.8, position: "absolute", top: 0 }}
				></Image>
				<View style={styles.overlay} />
			</View>

			<Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} macroarea={macroArea} />

			<FollowUsersAlert
				modalVisible={showInviteUsers}
				setModalVisible={(isVisible: boolean) => setShowInvitedUsers(isVisible)}
				isWeb={width > 1050}
				invite={true}
				eventId={propsEvent.id!}
				invitedUsers={propsEvent.listOfInvitedUser}
				partecipants={getPartecipantsList()}
			/>

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<EventInfoAlert
				modalVisible={eventInfoVisible}
				setModalVisible={(isVisible: boolean) => setEventInfoVisible(isVisible)}
				title={propsEvent.type === EventTypeEnum.lunch ? strings.menu : strings.grocery}
				list={propsEvent.type === EventTypeEnum.lunch ? getMenuList() : []}
				text={propsEvent.type === EventTypeEnum.grocery ? getGrocery() : ""}
			/>
			{propsEvent.field === dashboardType.food ? (
				<>
					<ActionAlert
						title={strings.partecipationTitle}
						description={strings.partecipationDescription}
						alertVisible={showPartecipationModal}
						disabled={partecipantRoleSelected === ""}
						onClose={() => {
							setPartecipantRoleSelected("");
							setShowPartecipationModal(false);
						}}
						actions={[
							{
								label: strings.partecipate,
								onPress: partecipateToEvent,
							},
						]}
					>
						<View style={{ zIndex: 3 }}>
							<Select
								options={
									Partecipants.filter((participant) => participant.type === props.route.params.event.type && showCollaboratorParticipation(participant)).map(
										(partecipant) => ({
											value: partecipant.value,
											label: partecipant.label,
										})
									) || []
								}
								placeholder={strings.select}
								value={partecipantRoleSelected}
								onChange={selectType}
							/>
						</View>
					</ActionAlert>

				</>

			) : (
				<>

					{propsEvent.participationType === EventParticipationType.both ? (
						<ActionAlert
							title={strings.partecipationTitle}
							description={strings.partecipationDescription}
							alertVisible={showPartecipationModal}
							disabled={partecipantModeSelected === ""}
							onClose={() => {

								setPartecipantModeSelected("");
								setShowPartecipationModal(false);
							}}
							actions={[
								{
									label: strings.partecipate,
									onPress:
										partecipateToEvent,
								},
							]}
						>
							<View style={{ zIndex: 3 }}>
								<Select
									options={
										ParticipationsMode.filter(
											(partecipant) => ({
												value: partecipant.value,
												label: partecipant.label,
											})
										) || []
									}
									placeholder={strings.select}
									value={partecipantModeSelected}
									onChange={selectMode}
								/>
							</View>
						</ActionAlert>
					) : null}
				</>
			)}
			<MapView
				navigation={props.navigation}
				modalVisible={mapViewVisible}
				setModalVisible={(isVisible: boolean) => setMapViewVisible(isVisible)}
				event={propsEvent}
			/>

			{isWeb ? <PageNavigation /> : null}

			<ScrollView ref={(c) => setScrollRefScreen(c)} contentContainerStyle={{ paddingBottom: 100 }}>
				<View style={[styles.content, isWeb ? { flexDirection: "row" } : null]}>
					<View style={[styles.mainContainer, {
						borderColor: getFieldColor(propsEvent.field)
						, borderWidth: 6
					}, isWeb ? styles.mainContainerWeb : null]}>
						<View style={{ width: isWeb ? "40%" : "100%" }}>
							<Pressable
								style={styles.backContainer}
								onPress={() => {
									clearFields();
									props.navigation.goBack();
								}}
							>
								<FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
								<Text style={styles.backText}>{strings.goBack}</Text>
							</Pressable>

							<View style={[styles.imageContainer, isWeb ? styles.webImageContainer : null]}>
								<Image
									source={propsEvent.img ? { uri: propsEvent.img } : propsEvent.field === dashboardType.food ? getDefaultImageByType(propsEvent.type) : getDefaultImageByScope(propsEvent.field)}
									style={[styles.coverImage, {
										borderColor: getFieldColor(propsEvent.field),
										borderWidth: 6
									}, isWeb ? null : { width: "100%" }]}
								></Image>
							</View>

							{isWeb ? <>{placePhotos()}</> : null}
						</View>

						<View style={{ width: isWeb ? "60%" : "100%" }}>
							<Text style={[styles.eventType, { marginTop: isWeb ? 40 : 10 }]}>{getTypeString(propsEvent.type)}</Text>
							<Text style={styles.title}>{propsEvent.title}</Text>

							{/* Date and time */}
							<View>
								{date ? (
									<View style={styles.eventDateTags}>
										<Tag text={date.day} color={colors.primary} style={styles.eventDateTag} />
										<Tag text={date.hour} color={colors.primary} style={styles.eventDateTag} />
									</View>
								) : null}
							</View>

							{/* Address and maps */}
							<View style={[styles.rowView, { marginTop: 20, marginBottom: 20 }]}>
								{propsEvent.place ? <Text style={[styles.eventPlace]}>{propsEvent.place.name}</Text> : (null)}
								{propsEvent.placeFreeText ? <Text style={[styles.eventPlace]}>{propsEvent.placeFreeText}</Text> : (null)}
								{propsEvent.place?._id ?
									(<>
										{isWeb ? (
											<Button handleButtonPress={() => setMapViewVisible(true)} text={strings.map} filledButton={false} />
										) : (
											<Pressable style={styles.locationIcon} onPress={() => setMapViewVisible(true)}>
												<FontAwesomeIcon size={20} color={colors.blue} icon={faLocationDot} />
											</Pressable>
										)}
									</>) : null}
							</View>
							{(propsEvent.type === EventTypeEnum.lunch || !propsEvent.correlatedEventId) &&
								!isPartecipant &&
								!checkDate() &&
								role === roles.user &&
								propsEvent.status != EventStatus.to_be_approved &&
								propsEvent.status != EventStatus.draft ? (
								<Button
									handleButtonPress={() => (isPartecipant ? null :
										propsEvent.participationType === EventParticipationType.inPerson || propsEvent.participationType === EventParticipationType.online ?
											partecipateToEvent() : setShowPartecipationModal(true))}
									text={isPartecipant ? (checkDate() ? strings.partecipatedAs : strings.partecipationAs) + partecipantType : strings.partecipate}
									filledButton={true}
									style={[isWeb ? styles.partecipateButton : { width: "100%" }, isPartecipant ? { backgroundColor: colors.red2 } : null]}
								/>
							) : null}

							{propsEvent.description ? <Text style={styles.description}>{propsEvent.description}</Text> : null}
							{/* Menu or Grocery */}
							{propsEvent.field === dashboardType.food ? (
								<>
									<View style={styles.line} />
									<View style={[isWeb ? styles.rowView : null]}>
										<Text style={styles.subtitle}>{propsEvent.type === EventTypeEnum.lunch ? strings.seeMenu : strings.seeGrocery}</Text>
										<Button
											handleButtonPress={() => setEventInfoVisible(true)}
											text={propsEvent.type === EventTypeEnum.lunch ? strings.menu : strings.grocery}
											filledButton={false}
											style={[styles.button, isWeb ? null : { marginTop: 10 }]}
										/>
									</View>
								</>

							) : null}


							{/* Internal Operator */}
							{propsEvent.internalOperator?.id ? (
								<>
									<View style={styles.line} />
									<View style={[isWeb ? styles.rowView : null]}>
										<Text style={styles.subtitle}>{strings.referenceOperator}</Text>
										<Pressable onPress={() => props.navigation.navigate(PageNames.user, { userId: propsEvent.internalOperator.id })}>
											<Text style={styles.operatorName}>{propsEvent.internalOperator.firstName + " " + propsEvent.internalOperator.lastName}</Text>
										</Pressable>
									</View>
								</>
							) : null}

							{/* External Operator */}
							{propsEvent.externalOperator?.id ? (
								<>
									<View style={styles.line} />
									<View style={[isWeb ? styles.rowView : null]}>
										<Text style={styles.subtitle}>{strings.externalOperator}</Text>
										<Pressable onPress={() => props.navigation.navigate(PageNames.user, { userId: propsEvent.externalOperator.id })}>
											<Text style={styles.operatorName}>{propsEvent.externalOperator.firstName + " " + propsEvent.externalOperator.lastName}</Text>
										</Pressable>
									</View>
								</>
							) : null}

							{/* Activity*/}
							{propsEvent.activities && propsEvent.activities?.length > 0 ? (
								<>
									<View style={styles.line} />
									<View style={isWeb ? [styles.rowView] : null}>
										<Text style={styles.subtitle}>{strings.activities}</Text>
										<FlatList
											data={propsEvent.activities}
											renderItem={({ item, index }) => {
												return (
													<View style={[{ marginBottom: 20 }, isWeb ? { flexDirection: "row", justifyContent: "flex-end" } : null]}
														key={`myactivity${index}`}>
														<Pressable
															onPress={() => props.navigation.navigate(PageNames.activityDetail, { activity: item, event: propsEvent })}>
															<Text style={styles.operatorName}>{item.title}</Text>
														</Pressable>
													</View>

												);

											}}
										/>
									</View>

								</>
							) : (null)}

							{/* Chat */}
							{isPartecipant ? (
								<>
									<View style={styles.line} />
									<View style={[styles.rowView]}>
										<Text style={styles.subtitle}>{strings.eventChat}</Text>
										<View style={{ flexDirection: "row" }}>
											<Pressable
												onPress={() =>
													props.navigation?.navigate(PageNames.chat, {
														isSpace: true,
														roomId: propsEvent.chatRoomId,
														coverPhoto: propsEvent.img,
														spaceTitle: propsEvent.title,
														members: getListOfUsers(propsEvent.participants),
														getMeet,
													})
												}
											>
												{hasUnreadMessages ? <ChatUnreadIcon /> : <ChatIcon />}
											</Pressable>
											<Pressable style={{ marginLeft: 20, marginTop: -2 }} onPress={() => goToMeetInstructions()}>
												<VideochatIcon size={26} color={colors.black} />
											</Pressable>
										</View>
									</View>
								</>
							) : null}

							{/* IF HAS SUBEVENT */}
							{propsEvent.correlatedEventId && propsEvent.correlatedEventId != "" ? (
								<>
									<View style={styles.line} />
									<Pressable onPress={() => openSubEvent()}>
										<Text style={styles.subevent}>{strings.goToSubEvent}</Text>
									</Pressable>
								</>
							) : null}

							{!isWeb ? (
								<>
									<View style={styles.line} />
									{placePhotos()}
								</>
							) : null}
						</View>
					</View>

					<View style={[isWeb ? styles.sideColumn : { marginBottom: 100 }]}>
						<View style={[styles.smallWhiteContainer, isWeb ? { marginBottom: "2%" } : { marginTop: "2%" }, isWeb ? styles.rowView : null]}>
							<Text style={styles.subtitle}>{strings.inviteFriendsToPartecipate}</Text>
							<Button
								style={[styles.button, isWeb ? null : { marginTop: 10 }]}
								handleButtonPress={() => {
									setShowInvitedUsers(true);
								}}
								text={strings.invite}
								filledButton={false}
								disabled={checkDate()}
							/>
						</View>
						<View style={[styles.smallWhiteContainer, isWeb ? { marginBottom: "2%" } : { marginTop: "2%" }]}>
							<Text style={[styles.subtitle, { marginBottom: 20 }]}>{strings.partecipants}</Text>
							{partecipantsList.length > 0 ? (
								<>
									<View style={{ width: "100%" }}>
										{partecipantsList.map((item: any, index: number) => (
											<View style={[styles.rowView, { marginBottom: 10 }]} key={`partecipant${index}`}>
												<Pressable
													style={styles.partecipantNameContainer}
													onPress={() => props.navigation.navigate(PageNames.user, { userId: item.userId })}>
													<Text style={[styles.partecipantName, { textDecorationLine: "underline" }]}>
														{item.name}
													</Text>
													{item.mode === 'online' &&
														<VideochatIcon style={styles.videochatIcon} color={colors.black} />}
												</Pressable>

												{role === "user" ? (
													<Pressable onPress={() => addFriend(item.userId, item.friends)}>
														<Text style={styles.operatorName}>{item.friends ? strings.removeFriend : strings.addAFriend}</Text>
													</Pressable>
												) : null}
											</View>
										))}
									</View>
									<View style={styles.line} />
									<View style={styles.rowView}>
										<FontAwesomeIcon style={styles.partecipantsIcon} size={15} icon={faUserGroup}></FontAwesomeIcon>
										<Text style={[styles.partecipantName, { color: colors.grey, marginLeft: 25, marginTop: 10 }]}>
											{partecipantsNumber + " " + strings.partecipants}
										</Text>
										{partecipantsNumber > 6 ? (
											<Button
												handleButtonPress={() => getPartecipants(propsEvent, partecipantsNumber === partecipantsList.length)}
												text={partecipantsNumber === partecipantsList.length ? strings.less : strings.all}
												filledButton={false}
											/>
										) : null}
									</View>
								</>
							) : (
								<Text style={[styles.partecipantName, { marginTop: 10, marginBottom: 10 }]}>{strings.noPartecipants}</Text>
							)}
						</View>
						{Platform.OS === "web" ? null : (
							<View style={[styles.smallWhiteContainer, isWeb ? { marginBottom: "2%" } : { marginTop: "2%" }, isWeb ? styles.rowView : null]}>
								<Text style={styles.subtitle}>{strings.shareEventWithFriends}</Text>
								<View style={[{ flexDirection: "row", justifyContent: isWeb ? "flex-end" : "flex-start", marginTop: isWeb ? 0 : 10 }]}>
									<Pressable onPress={() => share()}>
										<FontAwesomeIcon style={styles.shareIcon} size={20} icon={faShare}></FontAwesomeIcon>
									</Pressable>
								</View>
							</View>
						)}
					</View>
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default EventDetailScreen;
