import IcUsers from "../../assets/images/ic_users.svg";
import Svg, { Image } from "react-native-svg";
import { Platform, View } from "react-native";
import { images } from "../../resources/images";
import { IconProps } from "../../models/models";

const UsersIcon = ({ size = 24 }: IconProps) => {
	return (
		<View>
			{Platform.OS === "web" ? (
				<Svg width={`${size}`} height={`${size}`}>
					<Image width={size} height={size} href={images.icUsers} />
				</Svg>
			) : (
				<IcUsers width={size} height={size} />
			)}
		</View>
	);
};

export default UsersIcon;
