
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import DeleteIcon from "../Icons/DeleteIcon";
import EditIcon from "../Icons/EditIcon";

interface ModalProps {
	visible: boolean;
	setVisible: (isVisible: boolean) => any;
	onDelete: () => void;
	onEdit: () => void;
}

const ManageMessageModal = ({visible, setVisible, onDelete, onEdit}: ModalProps) => {

	return (
		<Modal animationType="fade" transparent={true} visible={visible} onRequestClose={() => setVisible(false)}>
			<View style={styles.container}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => setVisible(false)} />
				<View style={[styles.content]}>
                    <Pressable style={styles.row} onPress={() => {
							onEdit();
							setVisible(false);
						}}>
                        <EditIcon/>                       
                        <Text style={styles.iconName}>{strings.editMessage}</Text>
                    </Pressable>
					<Pressable style={styles.row} onPress={() => {
							onDelete();
							setVisible(false);
						}}>
                        <DeleteIcon/>                       
                        <Text style={styles.iconName}>{strings.deleteMessage}</Text>
                    </Pressable>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "flex-end",
		alignItems: "baseline",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingHorizontal: "3%",
        width: '100%',
        paddingTop: 20,
        paddingBottom: 40
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
    iconName: {
        fontFamily: fonts.RalewayRegular,
		color: colors.black,
        fontSize: 16,
        marginLeft: 10,
    },
    row: {
		flexDirection: 'row',
        marginVertical: 15,
		marginHorizontal: 10
    }
});

export default ManageMessageModal;
