// eslint-disable-next-line react-native/split-platform-components
import { Platform, Alert } from "react-native";
import { launchImageLibrary, launchCamera, CameraOptions } from "react-native-image-picker";
import { strings } from "../locales/strings";
import * as ImagePicker from "expo-image-picker";
import { ImageInfo } from "../models/models";

export class AttachmentPicker {

	_pickImage = (resolve: (payload: any) => void, reject: (payload: any) => void) => {
		this._pickFromGallery("photo", resolve, reject);
	};

	_pickFromGallery = (mediaType: any, resolve: (payload?: any) => void, reject: (payload: any) => void) => {
		const { check, PERMISSIONS, request, RESULTS } = require("react-native-permissions");

		if (Platform.OS === "ios") {
			check(PERMISSIONS.IOS.PHOTO_LIBRARY).then((result: any) => {
				console.log("resut " + JSON.stringify(result))
				switch (result) {
					case RESULTS.UNAVAILABLE:
						console.log("Photo library is not available");
						this.photoLibraryUnavailableAlert();
						break;
					case RESULTS.DENIED:
						console.log("Photo liberay is denied");
						request(PERMISSIONS.IOS.PHOTO_LIBRARY).then(() => {
							this.openGallery(mediaType, resolve, reject);
						});
						break;
					case RESULTS.LIMITED:
						console.log("Photo library is limited");
						this.photoLibraryUnavailableAlert();
						break;
					case RESULTS.GRANTED:
						console.log("Photo library is granted");
						this.openGallery(mediaType, resolve, reject);
						break;
					case RESULTS.BLOCKED:
						request(PERMISSIONS.IOS.PHOTO_LIBRARY).then(() => {
							this.openGallery(mediaType, resolve, reject);
						});
						console.log("Photo library is blocked");
						break;
				}
			})
		} else {
			this.openGallery(mediaType, resolve, reject);
		}
	
	};

	openGallery = (mediaType: any, resolve: (payload?: any) => void, reject: (payload: any) => void) => {
		setTimeout(() => {
			launchImageLibrary(
				{
					selectionLimit: 1,
					mediaType: mediaType,
					includeBase64: true,
					videoQuality: "medium",
					quality: 0.5,
					//copyTo: 'cachesDirectory'
				},
				(response: any) => {
					if (response.didCancel) {
						console.log("Document picker action cancelled");
						resolve();
					} else if (response.errorCode) {
						console.log("ImagePicker error ", response.errorCode + response.errorMessage);
						reject(response.errorMessage);
					} else {
						const photosList: ImageInfo[] = [];
						for (let i = 0; i < response.assets.length; i++) {
							const b64Content = response.assets[i].base64;
							const fileType = response.assets[i].type;
							const fileURI = Platform.select({ android: response.assets[i].uri, ios: decodeURI(response.assets[i].uri as string) });
							const fileCopyURI = Platform.select({
								android: response.assets[i].fileCopyUri,
								ios: decodeURI(response.assets[i].fileCopyUri ?? ""),
							});

							let fileExtension = fileType ? String(fileType).substr(String(fileType).indexOf("/") + 1) : undefined;

							if (!fileExtension && fileCopyURI) {
								// workaround for video (some info are missing)
								const splitted = String(fileCopyURI).split(".");
								fileExtension = splitted[splitted.length - 1];
							}

							const fileName = response.assets[i].fileName ?? `uploaded.${fileExtension}`;
							const base64 = `data:${fileType};base64,${b64Content}`;
							photosList.push({ base64, fileURI, fileCopyURI, fileType, fileExtension, fileName });
						}
						console.log(JSON.stringify(photosList));
						resolve(photosList);
					}
				}
			);
		}, 1500);
	}

	_takePhoto = (resolve: (payload: any) => void, reject: (payload: any) => void) => {
		if (Platform.OS === "web") {
			return;
		}

		const { check, PERMISSIONS, request, RESULTS } = require("react-native-permissions");

		const options: CameraOptions = {
			durationLimit: 10,
			saveToPhotos: false,
			includeBase64: true,
			cameraType: "back",
			mediaType: "photo",
		};

		if (Platform.OS === "ios") {
			check(PERMISSIONS.IOS.CAMERA).then((result: any) => {
				switch (result) {
					case RESULTS.UNAVAILABLE:
						console.log("Camera is not available");
						this.cameraUnavailableAlert();
						break;
					case RESULTS.DENIED:
						console.log("Camera is denied");
						request(PERMISSIONS.IOS.CAMERA).then(() => {
							launchCamera(options, (response) => {
								if (response.didCancel) {
									console.log("cancel image picker");
								} else if (response.errorCode) {
									console.log("error image picker");
								} else {
									console.log(response.assets);
									console.log("image assets " + response.assets);
									resolve(response.assets);
								}
							});
						});
						break;
					case RESULTS.LIMITED:
						console.log("Camera is limited");
						this.cameraUnavailableAlert();
						break;
					case RESULTS.GRANTED:
						console.log("Camera is granted");
						launchCamera(options, (response) => {
							if (response.didCancel) {
								console.log("cancel image picker");
							} else if (response.errorCode) {
								console.log("error image picker");
							} else {
								console.log(response.assets);
								console.log("image assets " + response.assets);
								resolve(response.assets);
							}
						});
						break;
					case RESULTS.BLOCKED:
						request(PERMISSIONS.IOS.CAMERA).then(() => {
							launchCamera(options, (response) => {
								if (response.didCancel) {
									console.log("cancel image picker");
								} else if (response.errorCode) {
									console.log("error image picker");
								} else {
									console.log(response.assets);
									console.log("image assets " + JSON.stringify(response.assets));
									resolve(response.assets);
								}
							});
						});
						console.log("Camera is blocked");
						break;
				}
			});
		} else {
			check(PERMISSIONS.ANDROID.CAMERA).then((result: any) => {
				switch (result) {
					case RESULTS.UNAVAILABLE:
						console.log("Camera is not available");
						this.cameraUnavailableAlert();
						break;
					case RESULTS.DENIED:
						console.log("Camera is denied");
						request(PERMISSIONS.ANDROID.CAMERA).then(() => {
							launchCamera(options, (response) => {
								if (response.didCancel) {
									console.log("cancel image picker");
								} else if (response.errorCode) {
									console.log("error image picker");
								} else {
									console.log(response.assets);
									console.log("image assets " + response.assets);
									resolve(response.assets);
								}
							});
						});
						break;
					case RESULTS.LIMITED:
						console.log("Camera is limited");
						this.cameraUnavailableAlert();
						break;
					case RESULTS.GRANTED:
						console.log("Camera is granted");
						launchCamera(options, (response) => {
							if (response.didCancel) {
								console.log("cancel image picker");
							} else if (response.errorCode) {
								console.log("error image picker");
							} else {
								console.log(response.assets);
								console.log("image assets " + response.assets);
								resolve(response.assets);
							}
						});
						break;
					case RESULTS.BLOCKED:
						request(PERMISSIONS.IOS.CAMERA).then(() => {
							launchCamera(options, (response) => {
								if (response.didCancel) {
									console.log("cancel image picker");
								} else if (response.errorCode) {
									console.log("error image picker");
								} else {
									console.log(response.assets);
									console.log("image assets " + JSON.stringify(response.assets));
									resolve(response.assets);
								}
							});
						});
						console.log("Camera is blocked");
						break;
				}
			});
		}
	};

	cameraUnavailableAlert = () =>
		Alert.alert(strings.cameraUnavailable, "", [
			{
				text: strings.ok,
				onPress: () => {
					return null;
				},
			},
		]);

	photoLibraryUnavailableAlert = () =>
		Alert.alert(strings.photoLibraryUnavailable, "", [
			{
				text: strings.ok,
				onPress: () => {
					return null;
				},
			},
		]);

	pickImageFromWeb = async () => {
		let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
		if (permissionResult.granted === false) {
			console.log("Permission to access camera roll is required!");
			return;
		}
		let pickerResult = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			base64: true
		});
		return pickerResult;
	};

	isSmallSize = (base64: string): boolean => {
		const size = (base64.length / 1e+6);
		console.log("size " + size)
		return size < 5;
	}
}
