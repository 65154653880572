import { Dimensions, Platform, Image, Text, FlatList, View, Pressable } from "react-native";
import { StyleSheet } from "react-native";
import { Event } from "../models/eventsModels";
import { useCallback, useEffect, useRef, useState } from "react";
import { colors } from "../resources/colors";
import { getDefaultImageByScope, getDefaultImageByType, getEventDate, translateEventType } from "../utils/EventUtils";
import Tag from "./Tag";
import { strings } from "../locales/strings";
import Button from "./Button";
import { PageNames } from "../navigation/NavigationUtils";
import { dashboardType } from "../utils/DashboardType";

const { width: windowWidth, height: windowHeight } = Dimensions.get("window");
const TIMER = 10000;

interface CarouselProps {
	events: Event[];
	isWeb: boolean;
	navigation: any;
}

interface SlideProps {
	data: Event;
	navigation: any;
}

const Slide = ({ data, navigation }: SlideProps) => {
	const date = getEventDate(data);
	return (
		<View style={styles.slideContainer}>
			<Image source={data.img ? { uri: data.img } :data.field === dashboardType.food ?  getDefaultImageByType(data.type) : getDefaultImageByScope(data.field)} style={styles.image}></Image>
			<View style={styles.overlay} />

			<View style={styles.infoContainer}>
				<Text style={styles.eventType}>{translateEventType(data)}</Text>
				<Text style={styles.eventName}>{data.title}</Text>
				{date && (
					<View style={styles.eventDateTags}>
						<Tag text={date.day} color={colors.primary} style={styles.eventDateTag} />
						<Tag text={date.hour} color={colors.primary} style={styles.eventDateTag} />
					</View>
				)}
				<Text style={[styles.white, styles.marginVertical]}>{data?.place?.name}</Text>
				<View style={styles.buttonContainer}>
					<Button text={strings.join} handleButtonPress={() => navigation.navigate(PageNames.eventDetail, { event: data, navigation: navigation })} filledButton={true} invertColor={true} />
				</View>
			</View>
		</View>
	);
};

const Pagination = ({ index, slides, onPress }: { index: number; slides: any[]; onPress: (index: number) => void }) => {
	return (
		<View style={styles.pagination}>
			{slides.map((_, i) => {
				return (
					<Pressable
						key={i}
						style={[styles.paginationDot, index === i && styles.paginationDotActive]}
						onPress={() => {
							index !== i && onPress(i);
						}}
					/>
				);
			})}
		</View>
	);
};

const EventCarousel = ({ events, isWeb, navigation }: CarouselProps) => {
	const [index, setIndex] = useState(0);
	const indexRef = useRef(index);
	indexRef.current = index;
	const flatListRef = useRef<FlatList<any>>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			const eventsL = events.length;
			if (eventsL > 1) {
				const next = index + 1 >= eventsL ? 0 : index + 1;
				handleChange(next);
			}
		}, TIMER);
		return () => clearInterval(interval);
	}, [index, events]);

	const onScroll = useCallback((event) => {
		const slideSize = event.nativeEvent.layoutMeasurement.width;
		const index = event.nativeEvent.contentOffset.x / slideSize;
		const roundIndex = Math.round(index);

		const distance = Math.abs(roundIndex - index);
		const isNoMansLand = 0.4 < distance;

		if (roundIndex !== indexRef.current && !isNoMansLand) {
			setIndex(roundIndex);
		}
	}, []);

	if (!events) {
		return <></>;
	}

	const flatListOptimizationProps = {
		initialNumToRender: 0,
		maxToRenderPerBatch: 1,
		removeClippedSubviews: true,
		scrollEventThrottle: 16,
		windowSize: 2,
		keyExtractor: useCallback((e) => e.id, []),
		getItemLayout: useCallback(
			(_, index) => ({
				index,
				length: windowWidth,
				offset: index * windowWidth,
			}),
			[]
		),
	};

	const handleChange = (index: number) => {
		if (flatListRef.current) {
			flatListRef.current.scrollToIndex({
				animated: true,
				index,
			});
		}
	};

	return (
		<View style={styles.carouselContainer}>
			<FlatList
				ref={flatListRef}
				data={events}
				style={styles.flexList}
				renderItem={({ item }) => {
					return <Slide data={item} navigation={navigation} />;
				}}
				pagingEnabled={true}
				horizontal={true}
				showsHorizontalScrollIndicator={false}
				onScroll={onScroll}
				snapToInterval={windowWidth * 0.8}
				snapToAlignment="start"
				{...flatListOptimizationProps}
			/>
			<Pagination index={indexRef.current} slides={events} onPress={handleChange} />
		</View>
	);
};

const styles = StyleSheet.create({
	carouselContainer: {
		height: windowHeight * 0.8,
	},
	flexList: {
		flex: 1,
	},
	slideContainer: {
		height: windowHeight * 0.8,
		width: windowWidth,
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
	},
	image: {
		width: windowWidth,
		height: windowHeight * 0.8,
		position: "absolute",
		top: 0,
	},
	infoContainer: {
		position: "absolute",
		bottom: "10%",
		left: 0,
		marginHorizontal: "5%",
		display: "flex",
		flexDirection: "column",
		padding: "2%",
		// backgroundColor: colors.blue,
	},
	eventType: {
		textTransform: "uppercase",
		color: colors.white,
	},
	eventName: {
		color: colors.white,
		fontSize: 50,
		fontWeight: "bold",
		marginVertical: 16,
	},
	pagination: {
		position: "absolute",
		bottom: 8,
		width: "auto",
		flexDirection: "row",
		zIndex: 2,
		left: "5%",
		paddingHorizontal: "2%",
	},
	paginationDot: {
		width: 90,
		height: 4,
		borderRadius: 20,
		marginHorizontal: 2,
		opacity: 0.4,
		backgroundColor: colors.white,
	},
	eventDateTags: {
		display: "flex",
		flexDirection: "row",
	},
	eventDateTag: {
		marginRight: 8,
	},
	white: {
		color: colors.white,
	},
	marginVertical: {
		marginVertical: 8,
	},
	buttonContainer: {
		flexDirection: "row",
	},
	paginationDotActive: {
		opacity: 1,
	},
	overlay: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: colors.blue3,
		opacity: 0.3,
	},
});

export default EventCarousel;
