import { View, Platform } from "react-native";
import Svg, { Image } from 'react-native-svg'
import { images } from "../../resources/images";
import IcFood from '../../assets/images/ic_food.svg';
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";


const FoodIcon = ({ size = 30 }: IconProps) => {
    return (
        <View>
            {Platform.OS === 'web' ?
                <Svg width={`${size}`} height={`${size}`}>
                    <Image width={size} height={size} href={images.icFood} />
                </Svg>
                :
                <IcFood width={size} height={size} fill={colors.black} />
            }
        </View>
    );
};
export default FoodIcon;