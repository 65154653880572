import { initializeApp } from "firebase/app";
import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
export class FirebaseMessaging {
	static token: Promise<string>;
	static messaging: Messaging;
	static inviteCount: number;

	constructor() {
		const firebaseConfig = {
			apiKey: "AIzaSyBdVg3geMsby363_CX4A8GpKVN8DdJjc5o",
			authDomain: "anzitutto.firebaseapp.com",
			projectId: "anzitutto",
			storageBucket: "anzitutto.appspot.com",
			messagingSenderId: "916518264323",
			appId: "1:916518264323:web:21017159d45dd81d2cb836",
		};

		const app = initializeApp(firebaseConfig);
		FirebaseMessaging.messaging = getMessaging(app);

		onMessage(FirebaseMessaging.messaging, async (payload) => {
			let inviteCount = await AsyncStorage.getItem(asyncStorageUtils.inviteCount),
				inviteCountNumber;
			if (!!inviteCount) {
				inviteCountNumber = parseInt(inviteCount) + 1;
			} else {
				inviteCountNumber = 1;
			}
			await AsyncStorage.setItem(asyncStorageUtils.inviteCount, String(inviteCountNumber));
		});
	}

	static async getToken() {
		try {
			FirebaseMessaging.token = getToken(this.messaging);
		} catch (error) {
			console.log(error);
		}
		try {
			console.log("Token: " + FirebaseMessaging.token);
			return await FirebaseMessaging.token;
		} catch (error) {
			console.log(error);
		}
	}
}
