import { FlatList, Platform, Pressable, TextInput, View, Image, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import { PageNames } from "../navigation/NavigationUtils";
import { faAngleLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import FeaturesChatModal from "../components/Chat/FeaturesChatModal";
import MessageComponent from "../components/Chat/MessageComponent";
import SendIcon from "../components/Icons/SendIcon";
import ProfileImage from "../components/ProfileImage";
import { strings } from "../locales/strings";
import { ImageInfo, Meet, ProfileImageRequest, UploadedSessionUrlResponse, WebChatIcons } from "../models/models";
import { colors } from "../resources/colors";
import { ChatStatus, ChatUtils, webChatIcons } from "../utils/ChatUtils";
import { FormatDate } from "../utils/FormatDateUtils";
import { capitalizeName } from "../utils/UserUtils";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Socket } from "../services/chat/Socket";
import { styles } from "../styles/ChatScreenStyle";
import ChatIcAddCircleIcon from "../components/Icons/AddCircleIcon";
import { ChatService } from "../services/chat/ChatService";
import { CHAT_DEAFULT_FILTERS, FileInfo, Message, MessageHistory, MessageMeet, PrivateRoomResponse, ResponseUploadChunk } from "../models/chatModels";
import ManageMessageModal from "../components/Chat/ManageMessageModal";
import EditIcon from "../components/Icons/EditIcon";
import { useIsFocused } from "@react-navigation/native";
import ActionAlert from "../components/Alerts/ActionAlert";
import Button from "../components/Button";
import DocumentPicker from "react-native-document-picker";
import { AttachmentPicker } from "../utils/AttachmentPicker";
import { ProfileImageMapper } from "../mappers/ProfileImageMapper";
import BasicToast from "../components/Toasts/BasicToast";
import FileOverview from "../components/Chat/FileOverview";
import { FileUtils } from "../utils/FileUtils";

interface PropsInterface {
	navigation: any;
	route: {
		params: {
			navigation: any;
			firstName?: string; // private room
			lastName?: string; // private room
			isSpace: boolean;
			userId?: string; // private room
			members?: string[]; // space
			coverPhoto?: string;
			spaceTitle?: string; // space
			roomId?: string; // space
			messageHistory?: MessageHistory;
			privateRoomResponse?: PrivateRoomResponse;
			getMeet: () => Promise<Meet>;
		};
	};
}

const attachmentPicker = new AttachmentPicker();

const ChatScreen = (props: PropsInterface) => {
	const params = props.route.params;
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
	const [profilePictureObject, setProfilePictureObject] = useState<ProfileImageRequest | null>(null);
	const [socketService, setSocketService] = useState<Socket | undefined>(undefined);
	const [showModal, setShowModal] = useState(false);
	const [showManageMessageModal, setShowManageMessageModal] = useState(false);
	const [messageToManage, setMessageToManage] = useState<Message | undefined>(undefined);
	const [editMode, setEditMode] = useState(false);
	const [messageToSend, setMessageToSend] = useState("");
	const focused = useIsFocused();
	const [messages, setMessages] = useState<Message[] | undefined>([]);
	const [userId, setUserId] = useState<string>();
	const [isOnHover, setIsOnHover] = useState(false);
	const [itemOnHover, setItemOnHover] = useState<Message | undefined>(undefined);
	const [showDeleteMessageAlert, setShowDeleteMessageAlert] = useState(false);
	const [roomId, setRoomId] = useState<string>("");
	const [historyFilters, setHistoryFilters] = useState<CHAT_DEAFULT_FILTERS>({ page: 1, pageSize: 10, totalMessages: 0 });
	const [messagesKey, setMessagesKey] = useState(0);
	const [fileUploaded, setFileUploaded] = useState<FileInfo | undefined>(undefined);
	const [fileModalVisible, setFileModalVisible] = useState(false);
	const [fileInfo, setFileInfo] = useState<FileInfo | undefined>(undefined);
	const [message, setMessage] = useState<Message | undefined>(undefined);

	React.useEffect(() => {
		const checkFlow = async () => {
			const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
			setUserId(id ?? "");
			setLoaderVisible(true);
			if (params.isSpace) {
				connectSpaceSocket();
			} else {
				!!params.userId ? connectSocket() : null;
			}
		};
		focused === true && checkFlow();
	}, [focused]);

	// PRIVATE CHAT
	const connectSocket = async () => {
		if (!!params.privateRoomResponse && !!params.messageHistory) {
			setRoomId(params.privateRoomResponse.item.id);
			setHistoryFilters({ ...historyFilters, totalMessages: params.messageHistory.total, page: params.messageHistory.page.index });
			const token = await AsyncStorage.getItem(asyncStorageUtils.accessToken);
			if (token) {
				const newSocket = new Socket(token, params.userId);
				setSocketService(newSocket);
				!!params.privateRoomResponse && getMessagesFromSocket(params.privateRoomResponse.item.id, newSocket);
				await newSocket.addHistory(
					params.messageHistory.page.items.length > 0 ? (params.messageHistory.page.items.reverse() as Message[]) : undefined,
					(messages: Message[]) => {
						setMessages(messages);
					},
					params.privateRoomResponse.item.members,
					setMessagesKey
				);
				newSocket.readRoomMessages();
			}
		} else if (params.userId) {
			const privateRoomResponse: PrivateRoomResponse = await ChatService.getPrivateRoom(params.userId);
			setRoomId(privateRoomResponse.item.id);
			const history: MessageHistory = await ChatService.getRoomMessages(privateRoomResponse.item.id, 1);
			setHistoryFilters({ ...historyFilters, totalMessages: history.total, page: history.page.index });
			const token = await AsyncStorage.getItem(asyncStorageUtils.accessToken);

			if (token) {
				const newSocket = new Socket(token, params.userId);
				setSocketService(newSocket);
				setMessageToSend("");
				getMessagesFromSocket(privateRoomResponse.item.id, newSocket);
				await newSocket.addHistory(
					history.page.items.length > 0 ? (history.page.items.reverse() as Message[]) : [],
					(messages: Message[]) => {
						setMessages(messages);
					},
					privateRoomResponse.item.members,
					setMessagesKey
				);
				newSocket.readRoomMessages();
			}
		}
		setLoaderVisible(false);
		setMessagesKey(messagesKey + 1);
	};

	// SPACE CHAT
	const connectSpaceSocket = async () => {
		if (params.roomId) {
			setRoomId(params.roomId);
			const history: MessageHistory = await ChatService.getRoomMessages(params.roomId, 1);
			setHistoryFilters({ ...historyFilters, totalMessages: history.total, page: history.page.index });
			const token = await AsyncStorage.getItem(asyncStorageUtils.accessToken);

			if (token) {
				const newSocket = new Socket(token);
				setSocketService(newSocket);
				getMessagesFromSocket(params.roomId, newSocket);
				await newSocket.addHistory(
					history.page.items.length > 0 ? (history.page.items.reverse() as Message[]) : undefined,
					(messages: Message[]) => {
						setMessages(messages);
					},
					params.members,
					setMessagesKey
				);
				newSocket.readRoomMessages();
			}
		}
		setLoaderVisible(false);
	};

	const loadMoreMessages = async () => {
		setLoaderVisible(true);
		if (historyFilters.page * historyFilters.pageSize < historyFilters.totalMessages && socketService) {
			const history: MessageHistory = await ChatService.getRoomMessages(roomId, historyFilters.page + 1);
			setHistoryFilters({ ...historyFilters, totalMessages: history.total, page: history.page.index });
			await socketService.addMessages(
				history.page.items.length > 0 ? (history.page.items as Message[]) : undefined,
				(messages: Message[]) => {
					setMessages(messages);
				},
				setMessagesKey
			);
		}
		setLoaderVisible(false);
	};

	const getMessagesFromSocket = (roomId: string, newSocket: Socket) => {
		newSocket.connectToRoom(roomId);
		newSocket.getMessages(async (messageList: Message[]) => {
			setMessages([...messageList]);
			setMessagesKey(messagesKey + 1);
			newSocket.readRoomMessages();
		}, setMessagesKey);
	};

	const sendMessage = async () => {
		if (socketService) {
			if (!!fileUploaded) {
				setLoaderVisible(true);
				const uploadedFile: any = await ChatService.uploadFile(fileUploaded);
				if (
					!!uploadedFile &&
					(uploadedFile?.status === "ok" || uploadedFile?.status === 200) &&
					((!!uploadedFile.item && !!uploadedFile.item.id) || (!!uploadedFile.data && !!uploadedFile.data.id))
				) {
					socketService.sendMessageToRoom(messageToSend, Platform.OS === "web" ? uploadedFile.data.id : uploadedFile.item.id);
					setMessageToSend("");
					setFileUploaded(undefined);
				} else if (uploadedFile?.status === "error") {
					setBasicAlertValues({
						isVisible: true,
						title: strings.error,
						description: uploadedFile?.error?.message ?? "",
						type: AlertTypes.error,
						buttonText: strings.close,
					});
				}
				setLoaderVisible(false);
			} else if (!!messageToSend) {
				socketService.sendMessageToRoom(messageToSend);
				setMessageToSend("");
			}
		} else {
			console.log("Error: socket undefined");
		}
	};

	const deleteMessage = (mex?: Message) => {
		if (socketService && (messageToManage || mex)) {
			!!mex ? setMessageToManage(mex) : null;
			socketService.deleteMessage(mex ? mex.id : messageToManage ? messageToManage?.id : "");
			if (mex) {
				socketService.deleteMessage(mex.id);
			} else {
				socketService.deleteMessage(messageToManage?.id ?? "");
			}
			const index = socketService.messages.findIndex((obj) => {
				return mex ? obj.id === mex.id : obj.id === messageToManage?.id;
			});
			const listOfMessages = socketService.messages;
			const mexDeleted = listOfMessages[index];
			mexDeleted.isDeleted = true;
			listOfMessages[index] = mexDeleted;
			socketService.setMessageList(listOfMessages);
		}
		setShowDeleteMessageAlert(false);
	};

	const editMessage = (mex?: Message) => {
		if (mex) {
			setMessageToManage(mex);
			setMessageToSend(mex.content);
		} else if (messageToManage) {
			setMessageToSend(messageToManage.content);
		}
	};

	const sendEditMessage = () => {
		if (socketService && messageToManage) {
			socketService?.editMessage(messageToManage?.id, messageToSend, messageToManage?.attachmentId ?? "");
			const index = socketService.messages.findIndex((obj) => {
				return obj.id === messageToManage.id;
			});
			const listOfMessages = socketService.messages;
			const mexEdited = listOfMessages[index];
			mexEdited.isEdited = true;
			mexEdited.content = messageToSend;
			listOfMessages[index] = mexEdited;
			setMessageToSend("");
			setMessageToManage(undefined);
			setEditMode(false);
			socketService.setMessageList(listOfMessages);
		}
	};

	const pickDocument = async () => {
		if (Platform.OS === "web") {
			const DocumentPicker = require("expo-document-picker");
			const doc: any = await DocumentPicker.getDocumentAsync({
				copyToCacheDirectory: true,
				multiple: false,
			});

			setFileUploaded({
				name: doc.name,
				type: doc.mimeType,
				data: doc.uri,
				fileUri: doc.uri,
			});
		} else {
			const result = await DocumentPicker.pickSingle({ type: [DocumentPicker.types.allFiles] });
			setFileUploaded({
				name: result.name ?? "",
				type: result.type ?? "",
				data: Platform.OS === "android" ? result.uri : decodeURI(result.uri),
				fileUri: result.uri,
			});
		}
	};

	const uploadImage = async (takePhoto: boolean) => {
		let imageInfo = await new Promise((resolve, reject) => {
			takePhoto ? attachmentPicker._takePhoto(resolve, reject) : attachmentPicker._pickFromGallery("photo", resolve, reject);
		});
		if (imageInfo) {
			const uri = takePhoto ? (imageInfo as ImageInfo[])[0].uri : (imageInfo as ImageInfo[])[0].fileURI;
			const name = (imageInfo as ImageInfo[])[0].fileName ? (imageInfo as ImageInfo[])[0].fileName : "";
			const type = (imageInfo as ImageInfo[])[0].fileType ? (imageInfo as ImageInfo[])[0].fileType : "image";
			const image = (imageInfo as ImageInfo[])[0];
			setProfilePictureObject(ProfileImageMapper.mapMobile(image));
			setFileUploaded({
				name: name,
				type: type,
				data: (imageInfo as ImageInfo[])[0].base64,
				fileUri: uri,
			});
		}
	};

	const onWebIconClicked = (index: number) => {
		switch (index) {
			/* case 0:
				console.log("CALENDAR clicked");
				break;
			case 1:
				console.log("EMOTICONS clicked");
				break;
			case 2:
				console.log("GIF clicked");
				break; */
			case 0:
				console.log("UPLOAD clicked");
				pickDocument();
				break;
			case 1:
				console.log("VIDEOCHAT clicked");
				sendMeetToRoom();
				break;
		}
	};

	const onMobileIconClicked = (index: number) => {
		setShowModal(false);
		switch (index) {
			case 0:
				console.log("PHOTO clicked");
				uploadImage(false);
				break;
			case 1:
				console.log("CAMERA clicked");
				uploadImage(true);
				break;
			/* case 2:
				console.log("GIF clicked");
				break; */
			case 2:
				console.log("VIDEOCHAT clicked");
				sendMeetToRoom();
				break;
			/* case 4:
				console.log("CALENDAR clicked");
				break; */
			case 3:
				console.log("DOCUMENTO clicked");
				setTimeout(() => {
					pickDocument();
				}, 100);
				break;
		}
	};

	const goBack = () => {
		socketService?.disconnect();
		setSocketService(undefined);
		setRoomId("");
		setIsOnHover(false);
		setItemOnHover(undefined);
		setHistoryFilters({ page: 1, pageSize: 10, totalMessages: 0 });
		setMessages([]);
		setMessagesKey(0);
		setMessageToSend("");
		setEditMode(false);
		setMessageToManage(undefined);
		setFileUploaded(undefined);
		props.navigation.goBack();
	};

	const sendMeetToRoom = async (): Promise<void> => {
		setLoaderVisible(true);
		try {
			if (socketService) {
				const meet = await props.route.params.getMeet();
				socketService.sendMeetToRoom(meet);
			} else {
				console.log("Error: socket undefined");
			}
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
		} finally {
			setLoaderVisible(false);
		}
	};

	const goToMeetInstructions = async (meet: MessageMeet): Promise<void> => {
		props.navigation?.navigate(PageNames.meetInstructions, { meet });
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} />
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />
			<FeaturesChatModal
				visible={showModal}
				setVisible={(isVisible: boolean) => setShowModal(isVisible)}
				onMobileIconClicked={(index: number) => onMobileIconClicked(index)}
			/>
			<ManageMessageModal
				visible={showManageMessageModal && Platform.OS !== "web"}
				setVisible={(isVisible: boolean) => setShowManageMessageModal(isVisible)}
				onDelete={() => setShowDeleteMessageAlert(true)}
				onEdit={() => {
					setEditMode(true);
					editMessage();
				}}
			/>

			<FileOverview
				visible={fileModalVisible}
				setVisible={(isVisible: boolean) => setFileModalVisible(isVisible)}
				file={fileInfo}
				message={message}
				setLoader={(isLoader: boolean) => setLoaderVisible(isLoader)}
			/>

			<ActionAlert
				alertVisible={showDeleteMessageAlert}
				onClose={() => setShowDeleteMessageAlert(false)}
				title={strings.deleteMessage}
				description={strings.deleteMessageDescription}
				actions={[
					{
						label: strings.delete,
						onPress: deleteMessage,
					},
					{
						label: strings.cancel,
						filled: false,
						onPress: () => setShowDeleteMessageAlert(false),
					},
				]}
			/>

			<BasicToast
				title={errorToastVisible.text}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible.isVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
			/>

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<View style={styles.container}>
				<Pressable style={styles.backContainer} onPress={() => goBack()}>
					<FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
					<Text style={styles.backText}>{strings.goBack}</Text>
				</Pressable>

				<View style={styles.nameContainer}>
					{params.isSpace ? (
						<ProfileImage firstName={params.spaceTitle ?? ""} lastName={""} profilePicture={params.coverPhoto} key={"chat-cover-picture"} size={60} />
					) : (
						<ProfileImage
							firstName={params.firstName ?? ""}
							lastName={params.lastName ?? ""}
							profilePicture={params.coverPhoto}
							key={"chat-profile-picture"}
							size={60}
						/>
					)}

					<View style={{ marginLeft: 20, flex: 1 }}>
						<Text style={styles.name}>{params.isSpace ? params.spaceTitle : capitalizeName({ firstName: params.firstName, lastName: params.lastName })}</Text>
						{params.isSpace ? (
							<View style={styles.statusContainer}>
								<Text style={styles.membersLabel}>{(!!params.members ? params.members.length : "") + " " + strings.members}</Text>
							</View>
						) : (
							<View style={styles.statusContainer}>
								<View
									style={[styles.statusIcon, { backgroundColor: socketService ? ChatUtils.getChatStatusColor(socketService.statusPrivateChat) : colors.grey }]}
								/>
								<Text style={styles.statusLabel}>{socketService ? ChatUtils.getChatStatusLabel(socketService?.statusPrivateChat) : null}</Text>
							</View>
						)}
						<View style={styles.line} />
					</View>
				</View>

				<View style={styles.contentContainer}>
					{messages && messages.length > 0 ? (
						<FlatList
							inverted
							contentContainerStyle={{ flexDirection: "column-reverse" }}
							showsVerticalScrollIndicator={false}
							data={messages}
							key={messagesKey}
							extraData={messagesKey}
							renderItem={({ item, index }) => {
								return (
									<View>
										{historyFilters.page * historyFilters.pageSize < historyFilters.totalMessages && index === 0 && (
											<Button style={{ marginBottom: 50 }} handleButtonPress={() => loadMoreMessages()} text={strings.loadMoreMessages} filledButton={false} />
										)}
										<Pressable
											onLongPress={() => {
												if (Platform.OS !== "web" && userId === item.author) {
													setMessageToManage(item);
													setShowManageMessageModal(true);
												}
											}}
											onHoverIn={() => {
												setIsOnHover(true);
												setItemOnHover(item);
											}}
											onHoverOut={() => {
												setIsOnHover(false);
												setItemOnHover(undefined);
											}}
											disabled={item.isDeleted}
										>
											<MessageComponent
												onFilePressed={(file: FileInfo) => {
													if (file.type.includes("image")) {
														setFileInfo(file);
														setMessage(item);
														setFileModalVisible(true);
													} else {
														setLoaderVisible(true);
														FileUtils.downloadFile(file, item, setLoaderVisible);
													}
												}}
												message={item}
												key={`messages${index}`}
												onDelete={() => {
													setShowDeleteMessageAlert(true);
													setMessageToManage(item);
												}}
												onEdit={() => {
													setEditMode(true);
													editMessage(item);
												}}
												isOnHover={isOnHover && item.id === itemOnHover?.id}
												ableToManageMessage={userId === item.author}
												onMeetPressed={(meet) => goToMeetInstructions(meet)}
											/>
										</Pressable>
									</View>
								);
							}}
							keyExtractor={(item, index) => `messages${item.id}`}
						/>
					) : (
						<Text style={styles.emptyChatLabel}>{strings.emptyChat}</Text>
					)}
				</View>

				<View style={styles.textAreaContainer}>
					{Platform.OS !== "web" && (
						<Pressable style={styles.plusIcon} onPress={() => setShowModal(true)}>
							<ChatIcAddCircleIcon />
						</Pressable>
					)}

					<View style={{ flex: 1 }}>
						{Platform.OS === "web" && !editMode ? (
							<View style={styles.chatIconsContainer}>
								{webChatIcons.map((item: WebChatIcons, index: number) => (
									<Pressable style={styles.chatIcon} onPress={() => onWebIconClicked(index)} key={`chat-icon${index}`}>
										{ChatUtils.getChatIcon(item.icon)}
									</Pressable>
								))}
							</View>
						) : null}
						{!!fileUploaded && (
							<View style={styles.fileUploadedContainer}>
								{!!fileUploaded.type && fileUploaded.type.includes("image") && (
									<View style={styles.imageUploadedContainer}>
										{Platform.OS === "web" ? (
											<Image style={styles.imageUploaded} source={fileUploaded.data} />
										) : (
											<Image style={styles.imageUploaded} source={{ uri: fileUploaded.data }} />
										)}
									</View>
								)}
								<View style={[styles.fileContainer, fileUploaded.type.includes("image") && { borderTopLeftRadius: 0, borderTopRightRadius: 0 }]}>
									<Text style={styles.fileName}>{fileUploaded.name}</Text>
									<Pressable
										onPress={() => {
											setFileUploaded(undefined);
										}}
									>
										<FontAwesomeIcon icon={faClose} />
									</Pressable>
								</View>
							</View>
						)}
						<View
							style={[
								styles.textInputContainer,
								!!fileUploaded
									? { paddingBottom: fileUploaded.type.includes("image") ? 155 : 45, height: fileUploaded.type.includes("image") ? 190 : 95, borderRadius: 30 }
									: null,
							]}
						>
							<TextInput
								style={[styles.textInput, editMode ? { paddingRight: 20 } : null]}
								placeholder={strings.chatPlaceholder}
								placeholderTextColor={colors.grey}
								onChangeText={(value) => setMessageToSend(value)}
								value={messageToSend}
								onSubmitEditing={() => (editMode ? sendEditMessage() : sendMessage())}
							/>
						</View>
					</View>
					{editMode ? (
						<View style={{ position: "absolute", right: 50, top: 13 }}>
							<EditIcon />
						</View>
					) : null}
					<Pressable
						style={styles.sendIcon}
						onPress={() => (editMode ? sendEditMessage() : sendMessage())}
						disabled={(messageToSend === "" && fileUploaded === undefined) || (editMode && messageToSend === messageToManage?.content)}
					>
						<SendIcon />
					</Pressable>
				</View>
			</View>
		</SafeAreaView>
	);
};

export default ChatScreen;
