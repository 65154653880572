import React from "react";
import { FlatList, Platform, Text, View } from "react-native";
import { styles } from "../../styles/CreateEventScreen";
import { Utils } from "../../utils/Utils";
import Counter from "../Counter";
import { CreateEventCollaborator } from "../../models/eventsModels";

interface PropsInterface {
	collaborators: Array<CreateEventCollaborator>;
    setCollaborators: (value: Array<CreateEventCollaborator>) => void;
}

const CollaboratorsList = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	return (
		<View style={{ marginTop: 10, marginBottom: 30, width: Utils.isWeb ? "50%" : "100%" }}>
			<FlatList
				data={props.collaborators}
				renderItem={({ item, index }) => {
					return (
						<View style={[styles.rowView, { paddingTop: 10, width: "100%" }]}>
							<View style={{ width: "50%" }}>
								<Text style={[styles.fieldDescription, { paddingTop: 15 }]}>{item.name}</Text>
							</View>
							<View style={{ paddingRight: Utils.isWeb ? "5%" : Platform.OS === "web" ? "5%" : 20, width: "100%" }}>
								<Counter
									value={item.value}
									setValue={(value) =>
										props.setCollaborators([
											...props.collaborators.slice(0, index),
											{ ...props.collaborators[index], value: value },
											...props.collaborators.slice(index + 1),
										])
									}
								/>
							</View>
						</View>
					);
				}}
				keyExtractor={(item) => item.key}
				keyboardShouldPersistTaps="handled"
			/>
		</View>
	);
};

export default CollaboratorsList;
