import { useEffect, useState } from "react";
import { Modal, Platform, Pressable, View ,Text,StyleSheet} from "react-native";
import Button from "../../Button";
import { strings } from "../../../locales/strings";
import { colors } from "../../../resources/colors";
import { fonts } from "../../../resources/fonts";
import { EventActivity } from "../../../models/eventsModels";

interface props {
	modalVisible: boolean;
	setDeleteActivityVisible: (isVisible: boolean) => any;
	delete: (id:string) => any;
	title: string;
	description: string;
    activity: EventActivity
}

const DeleteActivity = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setDeleteActivityVisible(false)} />

			<View style={styles.container}>
				<View style={styles.contenModal}>
					<Text style={styles.title}>{props.title}</Text>
					<Text style={styles.description}>{props.description}</Text>

					<View style={isWeb ? styles.rowView : {}}>
						<Button
							handleButtonPress={() => props.setDeleteActivityVisible(false)}
							text={strings.backToModify}
							filledButton={false}
							style={[isWeb ? { marginRight: 10 } : { marginBottom: 20, marginTop: 20 }]}
						/>
						<Button
							handleButtonPress={() => props.delete(props.activity._id!)}
							text={strings.deletePlaceButton}
							filledButton={true}
							style={[isWeb ? { marginLeft: 10 } : { marginLeft: 10, marginBottom: 20 }]}
						/>
					</View>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	contenModal: {
		backgroundColor: colors.white,
		width: Platform.OS === "web" ? "40%" : "80%",
		borderRadius: 10,
		paddingLeft: "3%",
		paddingRight: "3%",
		paddingTop: "5%",
		paddingBottom: "5%",
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "center",
	},
	description: {
		fontFamily: fonts.RalewayRegular,
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "2%",
		fontSize: 14,
		textAlign: "center",
		color: colors.grey4,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
});

export default DeleteActivity;