import { Platform, Pressable, View, Text, StyleSheet, Image } from "react-native";
import { colors } from "../resources/colors";
import { images } from "../resources/images";
import Logo from "../assets/images/logo-draft.svg";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import Menu from "./Menu/Menu";
import { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import React from "react";
import { strings } from "../locales/strings";
import { fonts } from "../resources/fonts";
import HeaderCreationComponent from "./HeaderCreationComponent";
import HeaderCreationModal from "./HeaderCreationModal";
import Button from "./Button";
import HeaderMenu from "./Menu/HeaderMenu";
import { MenuItems } from "../utils/MenuItems";
import FollowUsersAlert from "./FollowUsersAlert";
import { roles } from "../utils/Roles";
import { PageNames } from "../navigation/NavigationUtils";
import SelectModal from "./Select/SelectModal";
import { MacroAreaEventSelect } from "../utils/SelectUtils";
import { MacroAreaType } from "../utils/types/MacroAreaType";
import { MacroArea } from "../models/models";
import { dashboardType } from "../utils/DashboardType";

interface HeaderProps {
	navigation: any;
	titleStyle?: any;
	showMenu: boolean;
	onPress: () => void;
	width: number;
	showLoginButton?: boolean;
	isWeb?: boolean;
	setIsLogged?: (isLogged: boolean) => void;
	hideButton?: boolean;
	macroarea?: MacroArea;
}

const Header = (props: HeaderProps) => {
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [name, setName] = useState("");
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [showInviteUsers, setShowInvitedUsers] = useState(false);
	const [showCreationModal, setShowCreationModal] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [role, setRole] = useState("");
	const [showLogin, setShowLogin] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const isWeb = width >= 768;

	React.useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	React.useEffect(() => {
		setShowMenu(props.showMenu);
		setShowLogin(!!props.showLoginButton);
		AsyncStorage.getItem(asyncStorageUtils.role).then((role) => {
			setRole(role!);
		});

		const unsubscribe = props.navigation.addListener("focus", async () => {
			let name = await AsyncStorage.getItem(asyncStorageUtils.name);
			name != null ? setName(name) : null;
		});

		return unsubscribe;
	}, [props]);

	const navigateToCreateEvent = () => {
        if (props.macroarea && props.macroarea?.key && props.macroarea?.label) {
            if (props.macroarea.key === dashboardType.food) {
                props.navigation.navigate(PageNames.createEventFood, { event: undefined, userId: undefined, role: undefined, edit: false, macroarea: props?.macroarea });
            } else if (props.macroarea.key === dashboardType.wellness) {
                props.navigation.navigate(PageNames.createEventWellness, { event: undefined, userId: undefined, role: undefined, edit: false, macroarea: props?.macroarea });
            }
		} else {
			setIsModalVisible(true);
		}
	};

	return (
		<View style={styles.header}>
			<HeaderCreationModal
				ableToFollowFriends={role == roles.user}
				navigation={props.navigation}
				setMenuVisible={(isVisible: boolean) => setShowCreationModal(isVisible)}
				isVisible={showCreationModal}
				width={props.width}
				macroarea={props.macroarea}
			/>

			<FollowUsersAlert
				modalVisible={showInviteUsers}
				setModalVisible={(isVisible: boolean) => setShowInvitedUsers(isVisible)}
				isWeb={props.width > 1050}
				invite={false}
			/>

			<SelectModal
				modalVisible={isModalVisible}
				title={strings.createEvent}
				subTitle={strings.subStringEvent}
				onClose={() => setIsModalVisible(false)}
				items={MacroAreaEventSelect}
				buttons={[
					{
						text: strings.create,
                        onPress: (itemId: number) => {
                            if (MacroAreaType[itemId].type === dashboardType.food) {
                                props.navigation.navigate(PageNames.createEventFood,
                                    {
                                        event: undefined,
                                        userId: undefined, role: undefined,
                                        macroarea: {
                                            key: MacroAreaType[itemId].type,
                                            label: MacroAreaType[itemId].label
                                        },
                                        edit: false,
                                    });
                            } else if (MacroAreaType[itemId].type === dashboardType.wellness) {
                                props.navigation.navigate(PageNames.createEventWellness,
                                    {
                                        event: undefined,
                                        userId: undefined, role: undefined,
                                        macroarea: {
                                            key: MacroAreaType[itemId].type,
                                            label: MacroAreaType[itemId].label
                                        },
                                        edit: false,
                                    });
                            }
							setIsModalVisible(false);
						}
					}
				]}
			/>

			<Pressable
				style={styles.logoContainer}
				onPress={() => {
					props.onPress();
					props.navigation?.navigate(PageNames.home);
				}}
			>
				{Platform.OS === "web" ? (
					<Image style={[styles.logo, isWeb && styles.marginWeb]} source={images.logoDraft} />
				) : (
					<Logo style={[styles.logo, isWeb && styles.marginWeb]} width={"100%"} height={50} />
				)}
			</Pressable>
			{showMenu  ? (
				<>
				{(!props.hideButton) ? (
				<>
					{props.width > 1050  ? ( 
						
							<View style={[styles.rowView, isWeb && styles.topWeb]}>
							<HeaderCreationComponent
								title={strings.event}
								text={strings.createNew}
								buttonText={strings.events}
								imagePath={images.icEvent}
								iconType={MenuItems.createEvent}
								onClick={navigateToCreateEvent}
							/>

							<HeaderCreationComponent
								title={strings.myCalendar}
								text={strings.viewThe}
								buttonText={strings.calendar}
								imagePath={images.icCalendar}
								iconType={MenuItems.myCalendar}
								onClick={() => props.navigation.navigate(PageNames.calendar, { isMyCalendar: true })}
							/>

							{role == roles.user ? (
								<HeaderCreationComponent
									title={strings.friend}
									text={strings.addFriend}
									buttonText={strings.friends}
									imagePath={images.icAddUser}
									iconType={MenuItems.addFriend}
									onClick={() => setShowInvitedUsers(true)}
								/>
							) : null}

							<HeaderCreationComponent
								title={strings.group}
								text={strings.groups}
								buttonText={strings.groups}
								imagePath={images.icGroup}
								iconType={MenuItems.createGroup}
								onClick={() => props.navigation.navigate(PageNames.groups)}
							/>
						
							</View>									
					) : (
						<Pressable
							style={[
								isWeb ? { position: "absolute", right: 280, top: -10, marginTop: 15 } : { position: "absolute", right: 60, top: -10 },
								styles.plusIconContainer,
							]}
							onPress={() => setShowCreationModal(true)}
						>
							<View style={isWeb ? null : { backgroundColor: colors.blue, padding: 10, borderRadius: 50 }}>
								<FontAwesomeIcon style={[styles.plusIcon, isWeb && styles.paddingWeb]} size={15} icon={faPlus}></FontAwesomeIcon>
							</View>
						</Pressable>
					)}						
					
					</>
					):( null)}
					

					{isWeb ? (
						<Pressable style={styles.menu} onPress={() => setIsMenuVisible(true)}>
							<HeaderMenu name={name}></HeaderMenu>
						</Pressable>
					) : (
						<Pressable style={styles.userIconContainer} onPress={() => setIsMenuVisible(true)}>
							<View style={styles.userIcon}>
								<FontAwesomeIcon size={30} icon={faUser}></FontAwesomeIcon>
							</View>
						</Pressable>
					)}
					<Menu
						name={name}
						setMenuVisible={(isVisible: boolean) => setIsMenuVisible(isVisible)}
						isVisible={isMenuVisible}
						navigation={props.navigation}
						setShowMenu={(showMenu: boolean) => {
							setShowMenu(showMenu);
							props.setIsLogged ? props.setIsLogged(showMenu) : null;
						}}
						setShowLogin={(showLogin: boolean) => setShowLogin(showLogin)}
					/>
				</>
			) : (
				<>
					{showLogin ? (
						<Button
							style={styles.menu}
							text={props.isWeb ? strings.access : strings.enter}
							filledButton={true}
							handleButtonPress={() => props.navigation.navigate(PageNames.login)}
						></Button>
					) : null}
				</>
			)
			}
		</View >
	);
};

const styles = StyleSheet.create({
	header: {
		height: 80,
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: 20,
		zIndex: 1,
	},
	logoContainer: {
		flex: 1,
		width: 170,
		height: 25,
	},
	logo: {
		width: "inherit",
		height: "inherit",
		marginTop: 0,
	},
	welcome: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
	},
	title: {
		fontSize: 12,
		textAlign: "left",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginLeft: 5,
		marginRight: 5,
	},
	todayView: {
		backgroundColor: colors.primary,
		borderRadius: 10,
		padding: 10,
	},
	webHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 10,
	},
	angleIcon: {
		backgroundColor: colors.white,
		borderRadius: 50,
		padding: 5,
		marginTop: -10,
		marginLeft: 120,
		color: colors.blue,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "center",
		marginLeft: 200,
		position: "absolute",
		top: 5,
		right: 280,
	},
	plusIconContainer: {
		backgroundColor: colors.yellow2,
		padding: 10,
		borderRadius: 10,
		width: 55,
	},
	plusIcon: {
		backgroundColor: colors.blue,
		borderRadius: 50,
		color: colors.white,
		padding: 0,
	},
	userIconContainer: {
		position: "absolute",
		right: 0,
		top: -10,
	},
	userIcon: {
		backgroundColor: colors.white,
		padding: 12,
		borderRadius: 10,
	},
	menu: {
		position: "absolute",
		right: 0,
		top: 5,
	},
	paddingWeb: {
		padding: 10,
	},
	topWeb: {
		top: 10,
	},
	marginWeb: {
		marginTop: 20,
	},
});

export default Header;
