import React from "react";
import { FlatList, Pressable, Text, View, StyleSheet } from "react-native";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";

interface PropsInterface {
	isStartHour: boolean;
	startHour: any;
	setStartHour: (value: any) => void;
	endHour: any;
	setEndHour: (value: any) => void;
	isWeb: boolean;
}

const TimePicker = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	const hoursList = Array.from(Array(24).keys());
	const minutesList = Array.from(Array(60).keys());
	return (
		<View
			style={[
				{ flexDirection: "row" },
				styles.timePicker,
				!props.isWeb ? { marginTop: 340 } : { marginTop: 40 },
				!props.isStartHour && props.isWeb ? { left: 137 } : {},
				!props.isStartHour && !props.isWeb ? { left: 137 } : {},
			]}
		>
			<FlatList
				data={hoursList}
				style={{ marginRight: 10 }}
				showsVerticalScrollIndicator={false}
				renderItem={({ item, index }) => {
					return (
						<Pressable
							onPress={() =>
								props.isStartHour
									? props.setStartHour({ ...props.startHour, hour: item.toString() })
									: props.setEndHour({ ...props.endHour, hour: item.toString() })
							}
						>
							<Text style={[styles.fieldDescription, { padding: 10 }]} numberOfLines={1}>
								{item}
							</Text>
						</Pressable>
					);
				}}
				keyExtractor={(item) => item.toString()}
				keyboardShouldPersistTaps="handled"
			/>
			<FlatList
				data={minutesList}
				style={{ marginLeft: 10 }}
				showsVerticalScrollIndicator={false}
				renderItem={({ item, index }) => {
					return (
						<Pressable
							onPress={() =>
								props.isStartHour
									? props.setStartHour({ ...props.startHour, min: item.toString() })
									: props.setEndHour({ ...props.endHour, min: item.toString() })
							}
						>
							<Text style={[styles.fieldDescription, { padding: 10 }]} numberOfLines={1}>
								{item}
							</Text>
						</Pressable>
					);
				}}
				keyExtractor={(item) => item.toString()}
				keyboardShouldPersistTaps="handled"
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	timePicker: {
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 0,
		borderRadius: 10,
		position: "absolute",
		zIndex: 99999,
		paddingTop: 5,
		flex: 1,
		marginTop: 30,
		height: 200,
	},
	fieldDescription: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
	},
});

export default TimePicker;
