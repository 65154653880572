import { View, Platform } from "react-native";
import Svg, { Image } from 'react-native-svg'
import { images } from "../../resources/images";
import IcUpload from '../../assets/images/ic_upload.svg';
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";


const UploadIcon = ({ size = 24 }: IconProps) => {
    return (
        <View>
            {   Platform.OS === 'web' ?
                <Svg width={`${size}`} height={`${size}`}>
                    <Image width={size} height={size} href={images.icUpload} opacity={0.5}/>
                </Svg>
                :
                <IcUpload width={size} height={size} fill={colors.black} opacity={0.5}/>
            }
        </View>
    );
};  
export default UploadIcon;