import dayjs from "dayjs";
import { strings } from "../locales/strings";

export class FormatDate {

	static formatMessagesDate(date: string | Date): string {
		const oneHourAgo = dayjs(new Date()).hour(dayjs().hour() - 1);
		const formattedDate = dayjs(date).format('DD MM YYYY');
		const today = dayjs().format('DD MM YYYY');
		if (formattedDate == today) { // date is today
			let min = (dayjs().format('m') as unknown as number) - (dayjs(date).format('m') as unknown as number);
			if (min < 0) {
				min = 60 + min;
			}
			if (min === 0) { // message from under 1 min
				return strings.now;
			} else if (dayjs(date).isAfter(oneHourAgo)) { // message from max 1 hour ago
				return min.toString() + strings.min;
			} else {
				return dayjs(date).format('H:mm');
			}
		} else {
			return dayjs(date).format('MMM D, H:mm');
		}
	}

	static createEventDate(date: string | Date): string {
		return dayjs(date).format("MMM DD YYYY") + ", " + strings.houres + " " + dayjs(date).format("H.mm");
	}

	static getTimesSlotArray(): string[] {
		const arr = [];
		for (let i = 0; i < 24; i++) {
			for (let j = 0; j < 4; j++) {
				arr.push(`${i}:${j === 0 ? `00` : 15 * j}`);
			}
		}
		return arr;
	}

	static getTime(date: string | Date): string {
		return dayjs(date).format("H.m");
	}

	static formatAgendaDate(date: string | Date): string {
		return dayjs(date).format("DD MMMM YYYY");
	}
}
