
import { View, Text, Platform } from "react-native";
import { styles } from "../../../../../styles/CreateEventScreen";
import { strings } from "../../../../../locales/strings";
import Button from "../../../../../components/Button";
import { EventType } from "../../../../../utils/types/EventType";
import { roles } from "../../../../../utils/Roles";
import { ThirdPageViewModel } from "../../../../../models/createEventModels";
import useThirdPageController from "../../controller/pages/useThirdPageController";
import Searchbar from "../../../../../components/Searchbar";
import UsersList from "../../../../../components/Events/InvitedUsersList";
import Loader from "../../../../../components/Loader";
import BasicAlert from "../../../../../components/Alerts/BasicAlert";
import BasicToast from "../../../../../components/Toasts/BasicToast";
import { AlertTypes } from "../../../../../utils/AlertTypes";
import Checkbox from "expo-checkbox";
import { EventStatus } from "../../../../../utils/EventStatus";
import { colors } from "../../../../../resources/colors";

export const ThirdPageView = (props: ThirdPageViewModel) => {

    const {
        isWeb, width, DEFAULT_FILTERS, USER_PER_PAGE,
        usersUnFollowing, setUsersUnFollowing, usersFollowing, setUsersFollowing,
        filtersUnFollowing, setFiltersUnFollowing, filtersFollowing, setFiltersFollowing,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible,
    } = useThirdPageController(props);

    return <View style={isWeb ? styles.rowView : {}}>
        <View style={[styles.container, { width: isWeb ? "50%" : "100%" }]}>
            {/* List of users */}
            <Text style={styles.fieldTitle}>{strings.searchAndInviteFriends}</Text>
            <Searchbar
                placeholder={strings.name + " " + strings.surname}
                search={(value: string) => {
                    setFiltersUnFollowing({ ...DEFAULT_FILTERS, userFilter: filtersUnFollowing.userFilter, searchTerm: value });
                    setFiltersFollowing({ ...DEFAULT_FILTERS, userFilter: filtersFollowing.userFilter, searchTerm: value });
                }}
            />
            {!!usersFollowing.totalCounts &&
                <>
                    <Text style={styles.friends}>{strings.friends}</Text>
                    <UsersList
                        isReadOnly={false}
                        users={usersFollowing}
                        setUsers={(value: any) => setUsersFollowing(value)}
                        userIds={props.invitedUserIds}
                        setUserIds={props.setInvitedUserIds}
                        filters={filtersFollowing}
                        setFilters={setFiltersFollowing}
                        USER_PER_PAGE={USER_PER_PAGE}
                        isWeb={width > 1100}
                        text1={strings.invite}
                        text2={strings.invited}
                    />
                    <View style={styles.line}></View>
                </>}
            <UsersList
                isReadOnly={props.isReadOnly}
                users={usersUnFollowing}
                setUsers={(value: any) => setUsersUnFollowing(value)}
                userIds={props.invitedUserIds}
                setUserIds={props.setInvitedUserIds}
                filters={filtersUnFollowing}
                setFilters={setFiltersUnFollowing}
                USER_PER_PAGE={USER_PER_PAGE}
                isWeb={width > 1100}
                text1={strings.invite}
                text2={strings.invited}
            />

            <Button
                handleButtonPress={props.handleThirdPageBackButton}
                text={strings.back}
                filledButton={true}
                style={{ alignSelf: "flex-start", marginTop: 50 }}
            />
        </View>
        <View style={{ width: isWeb ? "50%" : "100%", marginLeft: isWeb ? 50 : 0 }}>
            <Text style={[styles.title, { marginTop: isWeb ? 60 : 40 }]}>{strings.haveYouInvitedAllFriends}</Text>
            <Text style={[styles.label, { width: "100%" }]}>{strings.haveYouInvitedAllFriendsDescription}</Text>
            {props.typology?.key === EventType[0].type &&
                (!props.edit || (props.edit && (props.propsEvent?.correlatedEventId == undefined || props.propsEvent.correlatedEventId == "") &&
                        (props.propsEvent?.status === EventStatus.to_be_approved || props.propsEvent?.status === EventStatus.draft))) ? ( // if LUNCH can create subEvent
                <View style={styles.createSubEventContainer}>
                    <Checkbox value={props.createSubEvent} onValueChange={props. setCreateSubEvent} color={colors.grey} />
                    <Text style={styles.createSubEventsText}>{strings.createSubEvents}</Text>
                </View>
            ) : null}
            <Button
                handleButtonPress={() => props.saveEvent(false, false, false)}
                text={props.edit && props.propsEvent?.status !== EventStatus.draft ? strings.approve : strings.publish}
                filledButton={true}
                style={styles.publishButton}
            />

            {props.edit &&
                ((props.propsEvent?.status === EventStatus.draft && props.propsEvent.userId === props.userId) || props.role === roles.serviceCoordinator) ? (
                <Button
                    style={[styles.publishButton, styles.deleteButton]}
                    handleButtonPress={() => props.setDeleteAlert(true)}
                    text={strings.delete}
                    filledButton={true}
                />
            ) : null}
        </View>
        
        <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

        <BasicAlert
            title={basicAlertValues.title}
            description={basicAlertValues.description}
            buttonText={basicAlertValues.buttonText}
            alertType={basicAlertValues.type}
            alertVisible={basicAlertValues.isVisible}
            setAlertVisible={(isVisible: boolean) => {
                setBasicAlertValues({
                    isVisible: isVisible,
                    title: basicAlertValues.title,
                    description: basicAlertValues.description,
                    type: basicAlertValues.type,
                    buttonText: basicAlertValues.buttonText,
                });
            }}
        />

        <BasicToast
            title={errorToastVisible.text}
            alertType={AlertTypes.error}
            alertVisible={errorToastVisible.isVisible}
            setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
        />

    </View>;
};
