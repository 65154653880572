import { Platform, Pressable, Text, View, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import { styles } from "../styles/CreateGroup.style";
import { useIsFocused } from "@react-navigation/native";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { USERS_FILTERS } from "../models/models";
import { capitalizeName } from "../utils/UserUtils";
import { colors } from "../resources/colors";
import Searchbar from "../components/Searchbar";
import Pagination from "../components/Pagination";
import PeopleIcon from "../components/Icons/PeopleIcon";
import { Group, GroupMember } from "../models/groupsModels";
import { PageNames } from "../navigation/NavigationUtils";
import ProfileImage from "../components/ProfileImage";

interface PropsInterface {
    navigation?: any;
    route: {
        params: {
            group?: Group;
        };
    };
}



const GroupDetailScreen = (props: PropsInterface) => {
    const params = props.route.params;
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;
    const focused = useIsFocused();
    const [group, setGroup] = useState<Group>({
        chatRoomId: "",
        id: "",
        members: [],
        name: "",
        ownerId: "",
        groupPicture: "",
        description: ""
    });
    const [users, setUsers] = useState<{ totalCounts: number; items: GroupMember[] }>({ totalCounts: 0, items: [] });
    const [usersFilters, setUsersFilters] = useState<USERS_FILTERS>({ searchTerm: "", pageSize: 6, page: 1, userFilter: "all", sorting: "asc" });
    const [isOnHover, setIsOnHover] = useState(false);
    const [itemOnHover, setItemOnHover] = useState<GroupMember | undefined>(undefined);
    const [scrollRefScreen, setScrollRefScreen] = useState(null);
    const scrollToTop = () => {
        scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
    };

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        scrollRefScreen != null ? scrollToTop() : null;
        if (!!params.group) {
            setGroup({
                chatRoomId: params.group.chatRoomId,
                ownerId: params.group.ownerId,
                id: params.group.id,
                name: params.group.name,
                members: params.group.members,
                description: params.group.description,
                groupPicture: params.group.groupPicture,
            });
            params.group.members.sort(sortMember)
            searchUsers()
        } else {
            clearFields();
        }
    }, [focused]);

    const sortMember = (a: GroupMember, b: GroupMember) => {
        // è stato trasformato in lower case perchè su mobile non funziona il localeCompare
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase()
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase()
        return nameA.localeCompare(nameB)
    }

    const searchUsers = () => {
        const membersArray: GroupMember[][] = [[]]
        let items: GroupMember[] = []
        let counts = 0
        if (!params.group) {
            return;
        }
        let members = params.group?.members

        if (usersFilters.searchTerm !== "") {
            const searchTerm = usersFilters.searchTerm.toLowerCase();
            members = members.filter((item) => item.firstName.toLowerCase().includes(searchTerm) || item.lastName.toLowerCase().includes(searchTerm))
        }
        for (let i = 0; i < members.length; i += usersFilters.pageSize) {
            membersArray.push(members.slice(i, i + usersFilters.pageSize))
        }
        counts = members.length


        if (counts > 0) {
            items = (isWeb || usersFilters.page === 1) ? membersArray[usersFilters.page] : users.items.concat(membersArray[usersFilters.page])
        }
        setUsers({ totalCounts: counts, items: items })

    };


    useEffect(() => {
        searchUsers();
    }, [usersFilters]);


    const goBack = () => {
        clearFields();
        props.navigation.goBack();
    };

    const clearFields = () => {
        setGroup({ chatRoomId: "", id: "", members: [], name: "", ownerId: "", groupPicture: "", description: "" });
        setUsers({ totalCounts: 0, items: [] });
        setUsersFilters({ searchTerm: "", pageSize: 6, page: 1, userFilter: "all", sorting: "asc" });
    };

    const UserRow = (item: GroupMember, index: number) => {
        return (
            <Pressable
                style={[styles.rowView, styles.userRow, { backgroundColor: isOnHover && !!itemOnHover && itemOnHover._id == item._id ? colors.blue3 : colors.white }]}
                key={`partecipants${index}`}
                onPress={() => {
                    props.navigation.navigate(PageNames.user, { userId: item._id })

                }}
                onHoverIn={() => {
                    setIsOnHover(true);
                    setItemOnHover(item);
                }}
                onHoverOut={() => {
                    setIsOnHover(false);
                    setItemOnHover(undefined);
                }}
            >
                <Text style={[styles.userName, { color: isOnHover && !!itemOnHover && itemOnHover._id == item._id ? colors.white : colors.black }]}>{capitalizeName(item)}</Text>

            </Pressable>
        );
    };

    return (
        <SafeAreaView style={styles.safearea}>
            <Header showMenu={true} width={width} navigation={props.navigation} onPress={clearFields} />

            <ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
                {isWeb ? <PageNavigation navigationEvent={clearFields} /> : null}

                <View style={[styles.whiteContainer, isWeb ? styles.rowView : null]}>
                    <View style={[styles.groupsContainer]}>


                        <Pressable style={styles.rowView} onPress={() => goBack()}>
                            <FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
                            <Text style={styles.backText}>{strings.goBack}</Text>
                        </Pressable>

                        <View style={[isWeb ? [styles.rowView, { marginHorizontal: "5%" }] : null, { marginTop: 50 }]}>
                            <View style={{ width: isWeb ? "50%" : "100%" }}>
                                <Text style={styles.title}>{group.name}</Text>

                                <View style={[styles.rowView, { justifyContent: "space-between", marginTop: 40 }]}>
                                    <Text style={styles.addPartecipants}>{strings.partecipants}</Text>
                                    <View style={styles.rowView}>
                                        <PeopleIcon />
                                        <Text style={styles.partecipants}>{group.members.length}</Text>
                                    </View>
                                </View>
                                <View>

                                    <View style={{ width: "100%" }}>
                                        <View style={{ marginTop: 5, marginBottom: 10, marginHorizontal: 16 }}>
                                            <Searchbar
                                                placeholder={strings.name + " " + strings.surname}
                                                search={(val) => setUsersFilters({ ...usersFilters, page: 1, searchTerm: val })}
                                            />
                                        </View>
                                        <View style={isWeb && { height: 350, flexDirection: "column", justifyContent: "space-between" }}>
                                            <View>
                                                {users.items.map((item: GroupMember, index: number) => UserRow(item, index))}
                                            </View>
                                            {users.items.length > 0 && (
                                                <View style={{ width: "100%" }}>
                                                    <View style={styles.pagination}>
                                                        {isWeb ? (
                                                            <Pagination
                                                                pages={Math.ceil(users.totalCounts / usersFilters.pageSize) || 1}
                                                                currentPage={usersFilters.page}
                                                                handlePageSelect={(newPage: number) => setUsersFilters({ ...usersFilters, page: newPage })}
                                                            />
                                                        ) : Math.ceil(users.totalCounts / usersFilters.pageSize) > usersFilters.page ? (
                                                            <Button
                                                                text={strings.users.loadMore}
                                                                handleButtonPress={() => setUsersFilters({ ...usersFilters, page: usersFilters.page + 1 })}
                                                                filledButton={true}
                                                            />
                                                        ) : null}
                                                    </View>
                                                </View>

                                            )}
                                        </View>
                                    </View>

                                </View>
                            </View>

                            <View style={{ width: isWeb ? "50%" : "100%" }}>
                                <View style={isWeb ? styles.coverImageWebContainer : styles.coverImageMobileContainer}>
                                    {!!group?.groupPicture ? (
                                        <View style={!isWeb ? { flexDirection: "row", justifyContent: "center" } : null}>
                                            <View style={styles.coverImageWeb}>
                                                <Image resizeMode="cover" style={styles.coverImage} source={{ uri: group.groupPicture }}></Image>
                                            </View>


                                        </View>
                                    ) : (
                                        <View style={{ flexDirection: "row", justifyContent: "center" }}>

                                            <ProfileImage
                                                firstName={group.name}
                                                lastName={""}
                                                size={140}
                                                style={[isWeb ? { marginVertical: 20 } : null, { fontSize: 30 }]}
                                            />


                                        </View>
                                    )}
                                    <View style={{ display: "flex", alignItems: "center" }}>
                                        <Text style={isWeb ? styles.groupDescriptionWeb : styles.groupDescriptionMobile}>{group.description || strings.noDescription}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>	                
                <Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
            </ScrollView>
        </SafeAreaView >
    );
};

export default GroupDetailScreen;