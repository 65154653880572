import { Platform, Pressable, View, Text } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { useEffect, useRef, useState } from "react";
import { strings } from "../locales/strings";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import SelectMultiple from "react-native-select-multiple";
import { PlaceManagerMultivalue } from "../models/models";

interface props {
	setSelected: (value: any) => void;
	selected: PlaceManagerMultivalue;
	list: PlaceManagerMultivalue[];
}

const DropDownMenu = (props: props) => {
	const [roleMenuVisible, setRoleMenuVisible] = useState(false);
	const [webItemSelected, setWebItemSelected] = useState([]);

	const dropdownRef = useRef<View>(null);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setRoleMenuVisible(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);

	const getWebLabelSelected = (): string => {
		let item = [];
		item.push(props.selected);
		let label = strings.select;
		props.selected.value != "" && webItemSelected.length === 0 ? (label = props.selected.label) : null;
		props.selected.value != "" && webItemSelected.length > 0 ? (label = webItemSelected[0].label) : null;
		props.selected.value == "" && webItemSelected.length > 0 ? (label = webItemSelected[0].label) : null;
		props.selected.value != "" && webItemSelected.length === 0 ? setWebItemSelected(item) : null;
		return label;
	};

	const CustomInputWeb = () => (
		<Pressable style={styles.rowView} onPress={() => setRoleMenuVisible(!roleMenuVisible)}>
			<Text style={[styles.roleText, props.selected.value != "" || webItemSelected.length > 0 ? { color: colors.black } : { color: colors.grey }]}>
				{getWebLabelSelected()}
			</Text>

			{roleMenuVisible ? (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleUp}></FontAwesomeIcon>
			) : (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleDown}></FontAwesomeIcon>
			)}
		</Pressable>
	);

	return (
		<View ref={dropdownRef} style={{ zIndex: 9999 }}>
			<CustomInputWeb />
			{roleMenuVisible ? (
				<View style={styles.multiselect}>
					<SelectMultiple
						nestedScrollEnabled={false}
						items={props.list}
						selectedItems={webItemSelected}
						maxSelect={2}
						onSelectionsChange={(selected: any) => {
							if (webItemSelected.length > 0 && selected.length > 0) {
								if (webItemSelected[0].value === selected[0].value && selected.length < 2) {
									props.setSelected(selected);
									setWebItemSelected([]);
								} else {
									props.setSelected(selected[1]);
									const select = [];
									select.push(selected[1]);
									setWebItemSelected(select);
								}
							} else {
								if (selected.length > 0) {
									props.setSelected(selected[0]);
									const select = [];
									select.push(selected[0]);
									setWebItemSelected(select);
								} else {
									props.setSelected(selected);
									setWebItemSelected([]);
								}
							}
							setRoleMenuVisible(false);
						}}
					/>
				</View>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: "3%",
		marginRight: "3%",
	},
	roleContainer: {
		fontFamily: fonts.RalewayRegular,
		backgroundColor: colors.white,
		color: colors.black,
		padding: 15,
		width: "90%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
	},
	multiselect: {
		marginLeft: "5%",
		marginRight: "5%",
		width: "90%",
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 5,
		borderRadius: 10,
		position: Platform.OS === "android" ? "relative" : "absolute",
		marginTop: Platform.OS === "android" ? 0 : 50,
		zIndex: 9999,
		paddingTop: 5,
		maxHeight: 250,
	},
	roleText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 30,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: colors.white,
		width: "96%",
		marginLeft: "2%",
		marginRight: "2%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
	},
	roleIcons: {
		right: 40,
		top: 18,
		zIndex: 9999,
		position: Platform.OS === "web" ? "absolute" : "relative",
	},
});

export default DropDownMenu;
