import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
    safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		backgroundColor: colors.white,
		margin: "5%",
        marginTop: 20,
		borderRadius: 10,
		paddingBottom: "2%",
		paddingTop: "2%",
	},
    rowView: {
        flexDirection: 'row',
    },
    title: {
        fontFamily: fonts.RalewayRegular,
        textTransform: 'uppercase',
        fontSize: 16,
        marginBottom: 10
    },
    description: {
        fontFamily: fonts.RalewayRegular,
        fontSize: 14
    },
    line: {
        borderBottomColor: colors.grey,
        borderBottomWidth: 1
    },
    pageTitle: {
		fontSize: 24,
		marginBottom: 0,
        marginLeft: '5%',
        marginRight: '5%',
		fontFamily: fonts.RalewayBold,
	},
    pageDescription: {
		fontSize: 20,
        marginLeft: '5%',
        marginRight: '5%',
		fontFamily: fonts.RalewayBold,
	},
});
