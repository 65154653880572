import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { View, Pressable, StyleSheet, Text } from "react-native";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface BackButtonProps {
	onPress: () => void | Promise<void>;
}

const BackButton = ({ onPress }: BackButtonProps) => {
	return (
		<Pressable onPress={onPress}>
			<View style={styles.rowView}>
				<FontAwesomeIcon size={15} icon={faArrowLeft}></FontAwesomeIcon>
				<Text style={styles.backText}>{strings.back}</Text>
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	rowView: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	backText: {
		fontSize: 18,
		marginLeft: 8,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
});

export default BackButton;
