
import { View, Text, Platform } from "react-native";
import { styles } from "../../../../../styles/CreateEventScreen";
import CoverImage from "../../../../../components/Events/CoverImage";
import CreateEventCalendarsComponent from "../../../../../components/Calendar/CreateEventCalendarsComponent";
import CustomTextInput from "../../../../../components/CustomTextInput";
import SelectPlaceComponent from "../../../../../components/Events/SelectPlaceComponent";
import ParticipationTypeComponent from "../../../../../components/Events/ParticipationTypeComponent";
import PlaceImage from "../../../../../components/Events/PlaceImage";
import { strings } from "../../../../../locales/strings";
import CalendarComponent from "../../../../../components/Calendar/CalendarComponent";
import Button from "../../../../../components/Button";
import { EventParticipation, EventParticipationType } from "../../../../../utils/types/EventType";
import { roles } from "../../../../../utils/Roles";
import useFistPageController from "../../controller/pages/useFirstPageController";
import Loader from "../../../../../components/Loader";
import BasicAlert from "../../../../../components/Alerts/BasicAlert";
import BasicToast from "../../../../../components/Toasts/BasicToast";
import { AlertTypes } from "../../../../../utils/AlertTypes";
import { FistPageViewModel } from "../../../../../models/createEventModels";
import { PageNames } from "../../../../../navigation/NavigationUtils";
import Select from '../../../../../components/Select';
import { PlaceType, placeTypeOptions } from '../../../../../models/PlaceType';

export const FirstPageView = (props: FistPageViewModel) => {

    const {
        navigation, isWeb,
        participationType, setParticipationType, openParticipationTypeComponent, setOpenParticipationTypeComponent, selectParticipationType,
        image, addImage, removeImage, monthDataWeb, monthDataMobile,
        webAgendaDaySelected, markedDates, agendaKey, placeImages, setPlaceImages,
        participationTypeModify,
        openConventionedPlace, setOpenConventionedPlace,
        loaderVisible, setLoaderVisible, basicAlertValues, setBasicAlertValues, errorToastVisible, setErrorToastVisible, modifyDate,
        selectPlaceType, placeType
    } = useFistPageController(props);

    return <View style={styles.container}>
        <View style={isWeb ? [styles.rowView, { zIndex: 2 }] : null}>
            <View style={isWeb ? { width: "50%" } : null}>



                <>
                    <Text style={styles.fieldTitle}>{strings.selectTypologyWellness + " *"}</Text>
                    <ParticipationTypeComponent
                        pageName={PageNames.createEventWellness}
                        navigation={navigation}
                        event={props.event}
                        openParticipationTypeComponent={openParticipationTypeComponent}
                        setOpenParticipationComponent={setOpenParticipationTypeComponent}
                        setEvent={props.setEvent}
                        setParticipationType={setParticipationType}
                        participationType={participationType}
                        selectParticipationType={selectParticipationType}
                        isReadOnly={props.isReadOnly}
                        list={EventParticipation}
                    />
                </>

                {/* Cover image Mobile */}
                {!isWeb &&
                    <CoverImage
                        isWeb={isWeb}
                        isReadOnly={props.edit && props.role != roles.serviceCoordinator}
                        image={image?.img}
                        addImage={addImage}
                        removeImage={removeImage}
                    />}

                {/* Title */}
                <View style={isWeb ? { marginRight: 20 } : {}}>
                    {props.isReadOnly ? (
                        <>
                            <Text style={[styles.fieldTitle]}>{strings.eventTitle + " *"}</Text>
                            <Text style={styles.readonly}>{props.event.title}</Text>
                        </>
                    ) : (
                        <CustomTextInput
                            title={strings.eventTitle + " *"}
                            value={props.event.title}
                            placeholder={strings.eventTitlePlaceholder}
                            obligatory={false}
                            styleText={{ marginLeft: 0, marginRight: 0, marginBottom: 10 }}
                            styleTextInput={[{ marginLeft: 0, marginRight: 0, width: "100%" }]}
                            setValue={(value: string) => props.setEvent({ ...props.event, title: value })}
                        />
                    )}
                </View>

                {/* start date & end date */}
                <View style={isWeb ? { zIndex: 9999, marginRight: 20 } : null}>
                    <CreateEventCalendarsComponent
                        maxDate={undefined}
                        minDate={new Date().toString()}
                        isReadOnly={props.isReadOnly}
                        startDate={props.event.startDate ?? undefined}
                        endDate={props.event.endDate ?? undefined}
                        event={props.event}
                        setEvent={props.setEvent}
                        setValidDate={props.setValidDate}
                        monthDataWeb={monthDataWeb}
                        monthDataMobile={monthDataMobile}
                        webAgendaDaySelected={webAgendaDaySelected}
                        markedDates={markedDates}
                    />
                    <View style={isWeb ? { position:"relative",zIndex:-1} : null}>
                        {!modifyDate && <Text style={styles.errorLabel}>{strings.dateNotModify} </Text>}
                    </View>
                </View>
            </View>

            {/* Cover image Web */}
            {isWeb ? (
                <View style={[isWeb ? { width: "50%" } : null, Platform.OS === "web" ? { zIndex: -1 } : null]}>
                    <Text style={[styles.fieldTitle, { marginVertical: 20, zIndex: -1 }]}>{strings.eventCoverImage}</Text>
                    <CoverImage
                        isWeb={isWeb}
                        isReadOnly={props.edit && props.role != roles.serviceCoordinator}
                        image={image?.img}
                        addImage={addImage}
                        removeImage={removeImage}
                    />
                </View>
            ) : null}
        </View>
        <View style={[isWeb ? styles.rowView : null, Platform.OS === "web" ? { zIndex: -1 } : null]}>
            <View style={isWeb ? { width: "50%" } : null}>
                {!participationTypeModify && <Text style={styles.errorLabel}>{strings.participationNotModify} </Text>}
            </View>

        </View>
        <View style={styles.line} />

        <View style={[isWeb ? styles.rowView : null, Platform.OS === "web" ? { zIndex: 1 } : null]}>
            <View style={isWeb ? { width: "50%" } : null}>
                {/* Place type */}
                {props.event.participationType !== EventParticipationType.online ? (
                    <View style={{ zIndex: 2 }}>
                        <Text style={[styles.fieldTitle, { marginTop: 20, marginBottom: 10 }]}>{strings.selectPlaceTypes + " *"}</Text>
                        <Select
                            options={placeTypeOptions}
                            placeholder={strings.select}
                            value={placeType}
                            disabled={props.isReadOnly}
                            onChange={(value) => selectPlaceType(value)}
                        />
                    </View>
                ) : null}

                {/* Conventioned place */}
                {placeType === PlaceType.CONVENTIONED || props.event.participationType === EventParticipationType.online ? (
                    <View style={{ zIndex: 1 }}>
                        <Text style={[styles.fieldTitle, { marginTop: 20 }]}>{strings.conventionedPlace + " *"}</Text>
                        <SelectPlaceComponent
                            pageName={PageNames.createEventWellness}
                            navigation={navigation}
                            isReadOnly={props.isReadOnly || props.event.participationType === EventParticipationType.online}
                            event={props.event}
                            setEvent={props.setEvent}
                            openConventionedPlace={openConventionedPlace}
                            setOpenConventionedPlace={setOpenConventionedPlace}
                            placesList={props.placesList}
                            setPlaceImages={(value: any) => setPlaceImages(value)}
                            />
                    </View>
                ) : null}

                {/* Free text place */}
                {placeType === PlaceType.OTHER && props.event.participationType !== EventParticipationType.online ? (
                    <>
                        <CustomTextInput
                            title={strings.otherPlace.label}
                            description={strings.otherPlace.description}
                            value={props.event.placeFreeText ?? ''}
                            placeholder={strings.otherPlace.placeholder}
                            obligatory={true}
                            isReadOnly={props.isReadOnly}
                            styleText={{ marginLeft: 0, marginRight: 0, marginBottom: 10 }}
                            styleTextInput={[{ marginLeft: 0, marginRight: 16 }]}
                            setValue={(value) => props.setEvent({ ...props.event, placeFreeText: value })}
                        />
                    </>
                ) : null}
            </View>

            <View style={isWeb ? { width: "50%" } : null}>
                {/* Images */}
                {placeType === PlaceType.OTHER ?
                    (<Text style={[styles.fieldDescription, styles.freeTextPlaceImagesText, { marginTop: 20 }]}>{strings.imagesFreeTextPlaceSelected}</Text>)
                    :
                    (
                        <>
                            <Text style={[styles.fieldDescription, { marginTop: 30 }]}>{strings.imagesPlaceSelected}</Text>
                            <View style={[styles.rowView, { zIndex: -1 }]}>
                                <PlaceImage rightImage={false} placeImage={placeImages?.img1 ?? ''} />
                                <PlaceImage rightImage={false} placeImage={placeImages?.img2 ?? ''} />
                                <PlaceImage rightImage={false} placeImage={placeImages?.img3 ?? ''} />
                                <PlaceImage rightImage={false} placeImage={placeImages?.img4 ?? ''} />
                            </View>
                        </>
                    )
                }
            </View>
        </View>

        {placeType !== PlaceType.OTHER ?
            <View key={agendaKey} style={[styles.calendarPlaceContainer, Platform.OS === "web" ? null : { padding: 5 }]}>
                {/* Calendar with the availability of the place selected */}
                <CalendarComponent
                    position={"relative"}
                    minDate={undefined}
                    maxDate={undefined}
                    date={undefined}
                    readonly={false}
                    onDayPress={(day: string) => console.log("day")}
                    showTimePicker={false}
                    showAgenda={true}
                    startDate={!!props.event.startDate ? props.event.startDate : undefined}
                    monthDataWeb={monthDataWeb}
                    monthDataMobile={monthDataMobile}
                    webAgendaDaySelected={webAgendaDaySelected}
                    markedDates={markedDates}
                />
            </View>
            : null}

        <Button
            handleButtonPress={props.handleFirstPageForwardButton}
            disabled={

                props.event.participationType === "" || props.event.title === "" || props.event.startDate === null ||
                props.event.endDate === null || (props.event.participationType !== EventParticipationType.online ? props.event.place?._id === "" && !props.event.placeFreeText : null) ||
                (!!props.validDate && !props.edit) ||
                !participationTypeModify || !modifyDate

            }
            text={strings.forward}
            filledButton={true}
            style={{ alignSelf: "flex-end", marginTop: 50 }}
        />

        <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

        <BasicAlert
            title={basicAlertValues.title}
            description={basicAlertValues.description}
            buttonText={basicAlertValues.buttonText}
            alertType={basicAlertValues.type}
            alertVisible={basicAlertValues.isVisible}
            setAlertVisible={(isVisible: boolean) => {
                setBasicAlertValues({
                    isVisible: isVisible,
                    title: basicAlertValues.title,
                    description: basicAlertValues.description,
                    type: basicAlertValues.type,
                    buttonText: basicAlertValues.buttonText,
                });
            }}
        />

        <BasicToast
            title={errorToastVisible.text}
            alertType={AlertTypes.error}
            alertVisible={errorToastVisible.isVisible}
            setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
        />

    </View>;
};
