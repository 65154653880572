import React, { useState } from "react";
import { Text, StyleSheet, View, Platform } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";

interface PageNumberProps {
	pageSelected: number;
	pageNumber: number;
	style?: any;
}

const Number = (pageNumber: number, pageSelected: number) => {
	return (
		<View style={[styles.pageCircle, pageNumber === pageSelected ? { backgroundColor: colors.red2 } : { backgroundColor: colors.grey2 }]}>
			<Text style={styles.pageNumber}>{pageNumber}</Text>
		</View>
	);
};
const PageNumber = (props: PageNumberProps) => {
	const [pages, setPages] = useState([{}]);
	const [width, setWidth] = useState<number>(window.innerWidth);
	React.useEffect(() => {
		let pg = [];
		for (let i = 0; i < props.pageNumber; i++) {
			pg.push({ value: i, key: i });
		}
		setPages(pg);
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, [props.pageNumber, props.pageSelected]);

	const Dash = () => {
		return <Text style={styles.slashPageNumber}>-</Text>;
	};

	return (
		<View style={[styles.container, width > 500 ? styles.alignRight : null, props.style && props.style]}>
			{pages.map((value, index) => {
				return (
					<View key={`pagenumber-${index}`} style={{ flexDirection: 'row' }}>
						{Number(index + 1, props.pageSelected)}
						{index + 1 < props.pageNumber ? <Dash></Dash> : null}
					</View>
				);
			})}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		justifyContent: "center",
		marginRight: "1%",
		marginBottom: 10,
		marginTop: Platform.OS === "web" ? 50 : 20,
	},
	alignRight: {
		alignSelf: "flex-end",
	},
	pageCircle: {
		borderRadius: 30,
		width: 40,
		height: 40,
	},
	pageNumber: {
		color: colors.white,
		fontSize: 15,
		textAlign: "center",
		alignSelf: "center",
		textAlignVertical: "center",
		alignContent: "center",
		alignItems: "center",
		marginTop: Platform.OS === "web" ? 10 : 12,
		fontFamily: fonts.RalewayBold,
	},
	slashPageNumber: {
		marginTop: Platform.OS === "web" ? 10 : 12,
		marginLeft: Platform.OS === "web" ? 20 : 10,
		marginRight: Platform.OS === "web" ? 20 : 10,
	},
});

export default PageNumber;
