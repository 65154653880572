export enum images {
	//img
	testImage = require("../assets/images/testImage.png"),
	foodImage = require("../assets/images/foodImg.jpg"),
	groceryImage = require("../assets/images/groceryImg.jpg"),
	wellnessImage = require("../assets/images/wellnessImg.jpg"),
	cultureImage = require("../assets/images/cultureImg.jpg"),
	login = require("../assets/images/login.jpg"),
	logoDraft = require("../assets/images/logo-draft.svg"),
	signupImg = require("../assets/images/signupImg.jpg"),
	placeImgPlaceholder = require("../assets/images/placeImagePlaceholder.jpg"),
	addFriends = require("../assets/images/addfriends.jpg"),
	sponsor = require("../assets/images/sponsor.png"),


	//icons
	icEvent = require("../assets/images/ic_event.svg"),
	icAddUser = require("../assets/images/ic_add-user.svg"),
	icGroup = require("../assets/images/ic_group.svg"),
	icFacebook = require("../assets/images/ic_facebook.svg"),
	icInstagram = require("../assets/images/ic_instagram.svg"),
	icGoogle = require("../assets/images/ic_google.svg"),
	icApple = require("../assets/images/ic_apple.svg"),
	icMail = require("../assets/images/ic_mail.svg"),
	icBirthday = require("../assets/images/ic_birthday.svg"),
	icMan = require("../assets/images/ic_man.svg"),
	icWoman = require("../assets/images/ic_women.svg"),
	icGender = require("../assets/images/ic_gender.svg"),
	icUsers = require("../assets/images/ic_users.svg"),
	treeDots = require("../assets/images/ect.svg"),
	icPeople = require("../assets/images/ic_people.svg"),
	icWellness = require("../assets/images/ic_wellness.svg"),
	icFood = require("../assets/images/ic_food.svg"),

	//chat icons
	icEmoji = require("../assets/images/ic_emoji.svg"),
	icGif = require("../assets/images/ic_gif.svg"),
	icUpload = require("../assets/images/ic_upload.svg"),
	icVideocam = require("../assets/images/ic_videocam.svg"),
	icAddCircle = require("../assets/images/ic_add_circle.svg"),
	icSend = require("../assets/images/ic_send.svg"),
	icImage = require("../assets/images/ic_image.svg"),
	icCamera = require("../assets/images/ic_camera.svg"),
	icChat = require("../assets/images/ic_chat.svg"),
	icChatUnred = require("../assets/images/ic_chat_unread.svg"),
	icDelete = require("../assets/images/ic_delete.svg"),
	icEdit = require("../assets/images/ic_edit.svg"),
	icExit = require("../assets/images/ic_exit.svg"),
	icDocument = require("../assets/images/ic_document.svg"),
	icDownload = require("../assets/images/ic_download.svg"),


	icCalendar = require("../assets/images/ic_calendar.svg"),
	icClock = require("../assets/images/ic_clock.svg"),

	// Meet instructions steps
	meetStep2 = require("../assets/images/meet_instructions/step2.jpg"),
	meetStep3 = require("../assets/images/meet_instructions/step3.jpg"),
	meetStep4 = require("../assets/images/meet_instructions/step4.jpg"),
}
