import { strings } from "../../locales/strings";

export const MacroAreaType = [
	{
		type: "food",
		label: strings.foodTitle,
	},
	{
		type: 'wellness',
		label: strings.wellnessTitle
	},
	// {
	//     type: 'culture',
	//     label: strings.cultureTitle
	// },
];
