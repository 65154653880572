import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		marginTop: 10,
		marginBottom: 30,
		width: "80%",
		height: "80%",
		marginHorizontal: "10%",
		borderRadius: 10,
		backgroundColor: colors.white,
		paddingHorizontal: Platform.OS === "web" ? "8%" : "3%",
		paddingVertical: "4%",
	},
	backContainer: {
		flexDirection: "row",
		textDecorationLine: "underline",
		color: colors.blue,
	},
	backText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.blue,
		marginLeft: 5,
	},
	backIcon: {
		marginTop: 2,
	},
	nameContainer: {
		flexDirection: "row",
		marginVertical: 30,
	},
	profileImage: {
		height: 60,
		width: 60,
		borderRadius: 100,
	},
	name: {
		fontSize: 20,
		fontFamily: fonts.RalewayBold,
		marginRight: 30,
	},
	statusIcon: {
		height: 10,
		width: 10,
		borderRadius: 50,
		marginTop: 5,
	},
	statusContainer: {
		flexDirection: "row",
		marginTop: 10,
	},
	statusLabel: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		marginLeft: 10,
	},
	membersLabel: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey,
	},
	contentContainer: {
		flex: 1,
		backgroundColor: colors.white,
		marginBottom: 10,
	},
	textAreaContainer: {
		flexDirection: "row",
		width: "100%",
	},
	textInput: {
		flex: 1,
		outlineStyle: "none",
		zIndex: 0,
		paddingRight: Platform.OS === "web" ? 100 : 20,
		paddingLeft: 20,
		height: 45,
	},
	textInputContainer: {
		height: 45,
		borderWidth: 1,
		borderColor: colors.grey,
		borderRadius: 50,
		zIndex: 0,
		marginHorizontal: Platform.OS === "web" ? 20 : "10%",
	},
	chatIconsContainer: {
		flexDirection: "row",
		position: "absolute",
		right: 20,
		marginTop: 15,
		bottom: 10,
		zIndex: 999,
	},
	chatIcon: {
		paddingHorizontal: 4,
	},
	plusIcon: {
		position: "absolute",
		left: Platform.OS === "web" ? -20 : 0,
		bottom: 10,
		zIndex: 999,
	},
	sendIcon: {
		position: "absolute",
		right: Platform.OS === "web" ? -20 : 0,
		bottom: 10,
	},
	plusIconContainer: {
		position: "absolute",
		left: "10%",
		bottom: 100,
		shadowColor: colors.grey4,
		shadowRadius: 4,
		shadowOffset: { height: 4, width: 0 },
		shadowOpacity: 0.5,
		padding: 15,
		height: 100,
		width: 100,
		zIndex: 999,
	},
	addLabel: {
		fontSize: 14,
		fontFamily: fonts.RalewayBold,
		color: colors.grey,
	},
	line: {
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		width: "100%",
	},
	fileContainer: {
		flexDirection: "row",
		backgroundColor: colors.grey5,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 10,
	},
	fileName: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
		marginRight: 5,
	},
	imageUploaded: {
		height: "100%",
		width: "100%",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	imageUploadedContainer: {
		height: 70,
		width: "100%",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	fileUploadedContainer: {
		position: "absolute",
		bottom: 10,
		marginLeft: Platform.OS === "web" ? 45 : "15%",
		width: Platform.OS === "web" ? 200 : "60%",
		zIndex: 999,
	},
	emptyChatLabel: {
		fontSize: 16,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
		marginVertical: 20,
		textAlign: "center",
	},
});
