import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { Platform, StyleSheet } from "react-native";
import { Utils } from "../utils/Utils";

export const styles = StyleSheet.create({
	safeArea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	title: {
		fontSize: 24,
		marginTop: 50,
		marginBottom: 0,
		fontFamily: fonts.RalewayBold,
	},
	subTitle: {
		fontSize: 16,
		marginTop: 10,
		marginBottom: 10,
		fontFamily: fonts.RalewayBold,
	},
	scrollView: {
		paddingTop: 30,
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 20,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	line: {
		borderTopColor: colors.grey,
		borderTopWidth: 1,
		marginTop: 20,
		marginBottom: 20,
		zIndex: -1,
	},
	fieldTitle: {
		fontFamily: fonts.RalewayBold,
		fontSize: 16,
		marginTop: 20,
	},
	coverImageContainer: {
		backgroundColor: colors.grey3,
		borderRadius: 10,
		height: Platform.OS === "web" ? "100%" : 180,
		marginTop: Platform.OS === "web" ? 0 : 20,
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	placeImageContainer: {
		backgroundColor: colors.grey3,
		borderRadius: 10,
		height: 100,
		marginTop: 10,
		marginRight: Platform.OS === "web" ? 15 : 0,
		zIndex: -1,
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	fieldDescription: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
	},
	selectIcon: {
		right: Platform.OS === "web" ? 40 : 50,
		top: 28,
		zIndex: 9999,
		position: Platform.OS === "web" ? "absolute" : "relative",
	},
	selectComponent: {
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 0,
		borderRadius: 10,
		position: "absolute",
		zIndex: 4,
		marginRight: 20,
		paddingTop: 5,
		width: "95%",
		flex: 1,
		marginTop: 60,
	},
	selectTitle: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 30,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: colors.white,
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
		marginTop: 10,
		marginRight: 20,
		overflow: "hidden",
	},
	placeName: {
		fontFamily: fonts.RalewayBold,
		fontSize: 14,
		marginTop: 10,
		paddingLeft: 10,
		paddingRight: 40,
	},
	placeAddress: {
		fontSize: 12,
		fontFamily: fonts.RalewayRegular,
		opacity: 0.5,
		paddingLeft: 10,
		paddingRight: 40,
		marginBottom: 10,
	},
	checkIcon: {
		right: 20,
		top: 15,
		position: "absolute",
	},
	calendarContainer: {
		borderWidth: 1,
		borderColor: colors.grey,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		zIndex: 999,
	},
	calendarPlaceContainer: {
		borderWidth: 1,
		borderColor: colors.grey,
		borderRadius: 10,
		marginTop: 50,
	},
	hourPickerContainer: {
		borderWidth: 1,
		borderColor: colors.grey,
		marginTop: 10,
		borderRadius: 10,
		padding: 10,
	},
	hourPickerText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		textAlign: "center",
	},
	errorLabel: {
		color: colors.red,
		marginTop: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
	},
	timePickerWeb: {
		borderWidth: 1,
		borderColor: colors.grey,
		borderRadius: 10,
	},
	timeInput: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		width: 40,
		padding: 10,
	},
	timeInputSlash: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		marginLeft: 5,
		marginRight: 5,
		paddingTop: 10,
	},
	clockIcon: {
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		marginTop: Platform.OS === "web" ? 0 : 10,
		marginRight: Platform.OS === "web" ? 0 : 10,
	},
	timePicker: {
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 0,
		borderRadius: 10,
		position: "absolute",
		zIndex: 99999,
		paddingTop: 5,
		flex: 1,
		marginTop: 30,
		height: 200,
	},
	description: {
		fontSize: 20,
		marginTop: 10,
		fontFamily: fonts.RalewayBold,
		width: "70%",
	},
	label: {
		fontSize: 14,
		marginTop: 10,
		fontFamily: fonts.RalewayRegular,
		marginBottom: 20,
		width: "70%",
	},
	saveDraftContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		justifyContent: "center",
		alignItems: "center",
		paddingTop: 30,
		paddingBottom: 30,
		marginTop: 20,
	},

	//second page
	descriptionTextInput: {
		width: "100%",
		height: 150,
		borderColor: colors.grey,
		borderWidth: 1,
		borderRadius: 10,
	},
	menuIcons: {
		backgroundColor: colors.blue,
	},
	menuIconsContainer: {
		borderRadius: 30,
		padding: 10,
		backgroundColor: colors.blue,
	},
	activityContainer: {
		marginVertical: 10,
		paddingTop: 30,
		paddingBottom: 30,
		backgroundColor: colors.primary,
		justifyContent: "center",
		borderRadius: 10,
		width:'100%',

	},
	rowViewButton: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	activity: {
		marginVertical: 32,
	},
	button: {
		alignSelf: "center",
		paddingLeft: 15,
		paddingRight: 15,
		borderRadius: 25,
		paddingTop: 10,
		paddingBottom: 10,
		alignContent: "center",
		zIndex: 1,
		backgroundColor: colors.blue,
	},
	textButton: {
		fontSize: 14,
		marginLeft: 6,
		fontFamily: fonts.RalewayBold,
		color: colors.white,
	},
	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	webRightItem: {
		alignItems: "flex-end",
    },
    noActivityContainer: {
        alignSelf: "center",
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 10,
        alignContent: "center",
        zIndex: 1,
        borderRadius: 10,
        backgroundColor: colors.infoBanner,
    },
    noActivityRowViewButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    noActivityDescription: {
        color: colors.black,
        marginLeft: 6,
        fontSize: 12,
        fontFamily: fonts.RalewayMedium,
    },
	//third page
	invite: {
		color: colors.blue,
		textDecorationLine: "underline",
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingTop: 5,
		paddingBottom: 5,
		margin: 2,
	},
	usersContainer: {
		paddingLeft: 15,
		paddingTop: 5,
		paddingRight: 15,
		paddingBottom: 5,
		width: "100%",
		marginTop: 5,
		borderRadius: 10,
	},
	publishButton: {
		alignSelf: "flex-start",
		marginTop: Utils.isWeb ? 20 : 10,
		marginBottom: 20,
		paddingLeft: 30,
		paddingRight: 30,
	},

	createSubEventContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10,
		marginBottom: 20,
	},
	createSubEventsText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		color: colors.grey4,
		marginLeft: 10,
	},

	readonly: {
		backgroundColor: colors.greyReadOnly,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		fontSize: 14,
		padding: 15,
		marginTop: 10,
		fontFamily: fonts.RalewayRegular,
		borderColor: colors.grey,
		borderWidth: 1,
		opacity: 0.8,
		overflow: "hidden",
	},
	deleteButton: {
		backgroundColor: colors.red,
		marginTop: 5,
	},
	datePickerContainer: {
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		backgroundColor: colors.white,
		borderColor:colors.grey,
		borderWidth: 1,
		flexDirection: "row",
		paddingHorizontal: 20,
		paddingVertical: 10,
		marginTop: 10,
		zIndex: 0,
		height:50,
	},
	datePickerText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		color: colors.blue,
		marginVertical: 3,
		marginLeft: 15,
	},
	friends: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		fontWeight: "bold",
		color: colors.grey,
		marginTop: 14,
		marginVertical: 12,
		marginLeft: 8,
	},
	freeTextPlaceImagesText: {
		opacity: 0.5,
		paddingVertical: 20
	}
});
