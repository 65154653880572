import { NavigationProp, useIsFocused } from "@react-navigation/native";
import { Platform, Text, Image, View, Alert } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import { styles } from "../styles/Profile.style";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Loader from "../components/Loader";
import { ImageInfo, Profile, ProfileImageRequest, UpdateProfile } from "../models/models";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { genderSelection } from "../utils/UserUtils";
import Button from "../components/Button";
import { EndPoints } from "../services/EndPoints";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ProfileImage from "../components/ProfileImage";
import { Formik } from "formik";
import CustomTextInput from "../components/CustomTextInput";
import DatePickerComponent from "../components/Datepicker";
import { RadioGroup } from "react-native-radio-buttons-group";
import { AttachmentPicker } from "../utils/AttachmentPicker";
import { ActionSheetProvider, connectActionSheet, useActionSheet } from "@expo/react-native-action-sheet";
import { ProfileImageMapper } from "../mappers/ProfileImageMapper";
import { roles } from "../utils/Roles";
import PageNumber from "../components/PageNumber";
import * as yup from "yup";
import SearchBarWithAutocomplete from "../components/SearchBarWithAutocomplete";
import { EntityResponse } from "../models/exchange";
import ActionAlert from "../components/Alerts/ActionAlert";
import Select from "../components/Select";
import BasicToast from "../components/Toasts/BasicToast";
import PrivacyPolicyAlert from "../components/PrivacyPolicyAlert";

import UserInfo from "../components/UserInfo";
import { fonts } from "../resources/fonts";
import ChangePassword from "../components/ChangePassword";
import FollowUsersAlert from "../components/FollowUsersAlert";
import { PageNames, externalPageName } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";
import { images } from "../resources/images";
import { rules } from "../utils/Rules";

const ROLE_EDITABLE_FIELDS: roles[] = [roles.user, roles.serviceCoordinator];
const attachmentPicker = new AttachmentPicker();

interface PropsInterface {
	navigation: NavigationProp<any>;
}

const ProfileScreen = ({ navigation }: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isPlatformWeb = Platform.OS === "web";
	const isWeb = width >= 768;

	const [user, setUser] = useState<Profile | null>(null);
	const [role, setRole] = useState<roles | null>(null);
	const [isEditing, setIsEditing] = useState(false);
	const [formStep, setFormStep] = useState(1);

	const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
	const [profilePicture, setProfilePicture] = useState(user?.profilePicture || "");
	const [profilePictureLoaded, setProfilePictureLoaded] = useState(!!user?.profilePicture);
	const [profilePictureObject, setProfilePictureObject] = useState<ProfileImageRequest | null>(null);
	const [shouldLogout, setShouldLogout] = useState<boolean>(false);
	const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
	const [changedRole, setChangedRole] = useState<roles | null>(null);
	const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

	const [changePasswordVisible, setChangePasswordVisible] = useState(false);

	const validator = yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		gender: yup.string().required(),
		phoneNumber: yup.string().required(),
	});

	const focused = useIsFocused();

	const availableToEdit = role && ROLE_EDITABLE_FIELDS.includes(role);

	const clearState = () => {
		setIsEditing(false);
		setFormStep(1);
	};

	useEffect(() => {
		if (focused) {
			if (isPlatformWeb) {
				window.addEventListener("resize", () => {
					setWidth(window.innerWidth);
				});
			}
			scrollRefScreen != null ? scrollToTop() : null;
			clearState();
			checkRoles();
			getMyProfile();
		}
	}, [focused]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	useEffect(() => {
		setProfilePictureLoaded(!!user?.profilePicture);
		setProfilePicture(user?.profilePicture || "");
	}, [user]);

	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const [loaderVisible, setLoaderVisible] = useState(false);

	const getMyProfile = async () => {
		await EndPoints.getProfile()
			.then((res) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setUser(res.item);
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.ok });
				setShouldLogout(e.code == 409);
			});
	};

	const checkRoles = async () => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setRole(userRole as roles);
	};

	const { showActionSheetWithOptions } = useActionSheet();

	const deleteProfilePicture = async () => {
		if (isPlatformWeb) {
			setProfilePicture("");
			setProfilePictureLoaded(false);
			setProfilePictureObject(null);
		} else {
			Alert.alert(strings.sureToDeleteImage, "", [
				{
					text: strings.no,
					onPress: () => console.log("Cancel Pressed"),
					style: "cancel",
				},
				{
					text: strings.yes,
					onPress: () => {
						setProfilePicture("");
						setProfilePictureLoaded(false);
						setProfilePictureObject(null);
					},
				},
			]);
		}
	};

	const addProfilePicture = async () => {
		const imageInfo = isPlatformWeb ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
		if (imageInfo) {
			if (isPlatformWeb && !(imageInfo as ImageInfo).uri.includes("image")) {
				setErrorToastVisible({ isVisible: true, text: strings.errorPhotoUploaded });
			} else {
				if (attachmentPicker.isSmallSize((Platform.OS === 'web') ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
					setProfilePicture(isPlatformWeb ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].fileURI);
					setProfilePictureLoaded(true);
					const image = isPlatformWeb ? (imageInfo as ImageInfo) : (imageInfo as ImageInfo[])[0];
					setProfilePictureObject(isPlatformWeb ? ProfileImageMapper.mapWeb(image) : ProfileImageMapper.mapMobile(image));
				} else {
					isPlatformWeb ? setErrorToastVisible({ isVisible: true, text: strings.errorPhotoTooBig }) : setBasicAlertValues({ isVisible: true, title: strings.error, description: strings.errorPhotoTooBig, type: AlertTypes.error, buttonText: strings.ok });
				}
			}
		}
	};

	const openActionSheet = () => {
		const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
		const destructiveButtonIndex = undefined;
		const cancelButtonIndex = 2;
		return new Promise((resolve, reject) => {
			showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
				switch (buttonIndex) {
					case 0: {
						attachmentPicker._pickFromGallery("photo", resolve, reject);
						break;
					}
					case 1: {
						attachmentPicker._takePhoto(resolve, reject);
						break;
					}
					case 2: {
						break;
					}
				}
			});
		});
	};

	const updateProfile = async (values: Profile) => {
		const updateValues: UpdateProfile = {
			address: values.address,
			phoneNumber: values.phoneNumber,
			birthDate: values.birthDate,
			coordinates: values.coordinates,
			firstName: values.firstName,
			gender: values.gender,
			lastName: values.lastName,
			notes: values.notes,
			relativesPhoneNumber: values.relativesPhoneNumber,
		};

		setLoaderVisible(true);
		await EndPoints.updateUserProfile(updateValues)
			.then(async (res: EntityResponse<Profile>) => {
				if (values.profilePicture && profilePictureObject === null) {
					const res = await EndPoints.deleteProfilePicture();
					return res;
				} else if (profilePictureObject) {
					const _res = await EndPoints.uploadProfilePictureAuthorized(profilePictureObject);
					return {
						...res,
						item: {
							...res.item,
							profilePicture: _res.item.link,
						},
					};
				} else {
					return res;
				}
			})
			.then((res) => {
				setUser(res.item);
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
						setBasicAlertValues({
							isVisible: true,
							title: strings.profileUpdated,
							description: strings.profileUpdatedDescription,
							type: AlertTypes.success,
							buttonText: strings.close,
						});
					}, 500);
				} else {
					setLoaderVisible(false);
					setBasicAlertValues({
						isVisible: true,
						title: strings.profileUpdated,
						description: strings.profileUpdatedDescription,
						type: AlertTypes.success,
						buttonText: strings.close,
					});
				}
				clearState();

			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
						setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.ok });
					}, 500);
				} else {
					setLoaderVisible(false);
					setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.ok });
				}
				setShouldLogout(e.code == 409);
			});
	};

	const handleChangeRoleRequest = () => {
		setShowRoleModal(!showRoleModal);
	};

	const handleDeleteUser = () => {
		setShowDeleteUserModal(!showDeleteUserModal);
	};

	const handleDeleteUserSubmit = async () => {
		setShowDeleteUserModal(false);
		try {
			setLoaderVisible(true);
			await EndPoints.deleteUser();
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			await AsyncStorage.clear();
			navigation?.navigate(PageNames.home);
		} catch (e: any) {
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.ok });
			setShouldLogout(e.code == 409);
		}
	};

	const changeRoleApi = async () => {
		console.log("entro1");

		if (changedRole && changedRole !== role) {
			try {
				setLoaderVisible(true);
				console.log("avanti");

				const res = await EndPoints.changeRole(changedRole);
				await AsyncStorage.setItem(asyncStorageUtils.accessToken, res.item.accessToken);
				await AsyncStorage.setItem(asyncStorageUtils.refreshToken, res.item.refreshToken);
				await AsyncStorage.setItem(asyncStorageUtils.role, res.item.role);
				checkRoles();
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			} catch (e: any) {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.ok });
				setShouldLogout(e.code == 409);
			}
		}
		setChangedRole(null);
	};

	const handleChangeRoleSubmit = async () => {
		setShowRoleModal(false);
		if (changedRole && !user?.readTermsAndConditions[changedRole]?.general) {
			console.log("Da accettare termini e condizioni");
			setPrivacyPolicyVisible(true);
			return;
		}

		await changeRoleApi();
	};

	const handleChangeUserTerm = async (res: any) => {
		!!res.item && setUser(res.item);
		await changeRoleApi();
	};

	const selectRole = (item: string | number) => {
		setChangedRole(item as roles);
	};

	const [showInviteUsers, setShowInvitedUsers] = useState(false);

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={navigation} onPress={clearState} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicToast
				title={errorToastVisible.text}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible.isVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible({ ...errorToastVisible, isVisible: isVisible })}
			/>

			{changedRole && (
				<PrivacyPolicyAlert
					modalVisible={privacyPolicyVisible}
					termsAndConditions={true}
					setPrivacyPolicyVisible={(isVisible: boolean) => setPrivacyPolicyVisible(isVisible)}
					changeTermAndConditionsStatus={true}
					role={changedRole}
					onRoleUpdated={handleChangeUserTerm}
				/>
			)}

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
					if (shouldLogout) {
						AsyncStorage.clear();
						navigation?.navigate(PageNames.home);
					}
				}}
			/>

			<ChangePassword isWeb={isWeb} modalVisible={changePasswordVisible} setModalVisible={(isVisible: boolean) => setChangePasswordVisible(isVisible)} />

			<ActionAlert
				title={strings.profilePage.warningDelete.title}
				description={strings.profilePage.warningDelete.description}
				alertVisible={showDeleteUserModal}
				onClose={() => {
					setShowDeleteUserModal(false);
				}}
				actions={[
					{
						label: strings.profilePage.warningDelete.button,
						onPress: handleDeleteUserSubmit,
					},
				]}
			></ActionAlert>

			<ActionAlert
				title={strings.profilePage.warningDelete.title}
				description={strings.profilePage.warningDelete.description}
				alertVisible={showDeleteUserModal}
				onClose={() => {
					setShowDeleteUserModal(false);
				}}
				actions={[
					{
						label: strings.profilePage.warningDelete.button,
						onPress: handleDeleteUserSubmit,
					},
				]}
			></ActionAlert>

			<ActionAlert
				title={strings.profilePage.changeRoleModal.title}
				description={strings.profilePage.changeRoleModal.desc}
				alertVisible={showRoleModal}
				onClose={() => {
					setChangedRole(null);
					setShowRoleModal(false);
				}}
				actions={[
					{
						label: strings.profilePage.changeRoleModal.btn,
						onPress: handleChangeRoleSubmit,
					},
				]}
			>
				<View style={{ zIndex: 3 }}>
					<Select
						options={
							user?.roles.map((role) => ({
								label: (strings.roles as any)[role] || role,
								value: role,
							})) || []
						}
						value={changedRole || role || ""}
						onChange={selectRole}
					/>
				</View>
			</ActionAlert>

			<FollowUsersAlert modalVisible={showInviteUsers} setModalVisible={(isVisible: boolean) => setShowInvitedUsers(isVisible)} isWeb={width > 1050} invite={false} />

			<ScrollView style={isPlatformWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb ? (
					<PageNavigation navigationEvent={clearState} />
				) : (
					isEditing && (
						<View style={styles.mobileHeader}>
							<Text style={[styles.title, styles.titleMargin]}>{strings.profilePage.title}</Text>
							<PageNumber pageNumber={2} pageSelected={formStep} />
						</View>
					)
				)}

				{user && (
					<View>
						<View style={[styles.whiteContainer, isWeb ? styles.rowView : null]}>
							<View style={[styles.usersMobile, isEditing ? null : { paddingBottom: 0 }]}>
								{!isEditing && (
									<View style={[styles.userTitleMobile, isWeb && styles.userTitleWeb]}>
										{!isWeb && (
											<ProfileImage
												firstName={user.firstName}
												lastName={user.lastName}
												profilePicture={user.profilePicture}
												key={"profile-picture"}
												size={180}
												style={!isWeb ? styles.userTitleMargin : null}
											/>
										)}
										<Text style={[styles.title, !isWeb ? styles.userTitleMargin : { marginRight: 20 }]}>{strings.profilePage.title}</Text>
										<Button
											text={strings.profilePage.edit}
											handleButtonPress={() => setIsEditing(!isEditing)}
											filledButton={true}
											style={!isWeb ? styles.userTitleMargin : null}
										/>
										{!isEditing && (
											<Button
												text={strings.profilePage.delete}
												handleButtonPress={handleDeleteUser}
												filledButton={true}
												style={[!isWeb ? styles.userTitleMargin : styles.marginLeft, styles.deleteButton]}
											/>
										)}
										<Button
											text={strings.changePassword}
											handleButtonPress={() => setChangePasswordVisible(true)}
											filledButton={true}
											style={[!isWeb ? styles.userTitleMargin : null, isWeb ? { marginLeft: 10, marginRight: 10 } : null]}
										/>
										{user.roles.length >= 2 && !isEditing && (
											<Button
												text={strings.profilePage.changeRole}
												handleButtonPress={handleChangeRoleRequest}
												filledButton={true}
												style={[!isWeb ? styles.userTitleMargin : styles.marginLeft]}
											/>
										)}
									</View>
								)}

								{isEditing && isWeb && (
									<View style={[styles.mobileHeader, styles.editWebHeader]}>
										<Text style={[styles.title]}>{strings.profilePage.title}</Text>
										<PageNumber style={styles.paginator} pageNumber={2} pageSelected={formStep} />
									</View>
								)}

								{!isEditing && <UserInfo user={user} role = {role} isWeb={isWeb} />}
								{isEditing && (
									<View>
										<Formik initialValues={user} onSubmit={updateProfile} enableReinitialize={true} validationSchema={validator}>
											{({ handleChange, handleBlur, handleSubmit, values, setFieldValue, errors }) => (
												<View>
													{formStep === 1 && (
														<View style={[styles.editFormContainer, isWeb && styles.editFormContainerWeb]}>
															<View style={[isWeb && styles.editFormColumn]}>
																<View style={isWeb && styles.editFormowView}>
																	{/* name */}
																	<CustomTextInput
																		placeholder={strings.insertName}
																		title={strings.name}
																		obligatory={true}
																		halfWidth={isWeb}
																		value={values.firstName}
																		setValue={handleChange("firstName")}
																		onBlur={() => handleBlur("firstName")}
																		disabled={!availableToEdit}
																		styleText={{ marginLeft: 0, marginRight: 0 }}
																		styleTextInput={{ marginLeft: 0, marginRight: isWeb ? 10 : 0, width: isWeb ? "auto" : "100%" }}
																	/>

																	{/* surname */}
																	<CustomTextInput
																		placeholder={strings.insertSurname}
																		title={strings.surname}
																		obligatory={true}
																		halfWidth={isWeb}
																		value={values.lastName}
																		setValue={handleChange("lastName")}
																		onBlur={() => handleBlur("lastName")}
																		disabled={!availableToEdit}
																		styleText={{ marginLeft: 0, marginRight: 0 }}
																		styleTextInput={{ marginLeft: 0, marginRight: isWeb ? "2%" : 0, width: isWeb ? "auto" : "100%" }}
																	/>
																</View>

																{/* birthDate */}
																<View style={styles.titleContainer}>
																	<Text style={styles.textInputTitle}>{strings.birthDate}</Text>
																	<Text style={styles.asterisk}>*</Text>
																</View>
																<DatePickerComponent
																	onDateChange={(value: Date) => setFieldValue("birthDate", value)}
																	value={values.birthDate}
																	disabled={!availableToEdit}
																	styleTextInput={{ marginLeft: 0, marginRight: isWeb ? "2%" : 0, width: isWeb ? "auto" : "100%" }}
																/>

																{/* gender */}
																<View style={styles.titleContainer}>
																	<Text style={styles.textInputTitle}>{strings.gender}</Text>
																	<Text style={styles.asterisk}>*</Text>
																</View>
																<RadioGroup
																	containerStyle={styles.gender}
																	radioButtons={genderSelection.map((gender) => ({
																		...gender,
																		selected: values.gender === gender.value,
																		labelStyle: { fontFamily: fonts.RalewayRegular },
																	}))}
																	layout={isWeb ? "row" : "column"}
																	onPress={(radioButtons) => {
																		radioButtons.forEach((radio) => {
																			radio.selected && radio.value != null ? setFieldValue("gender", radio.value) : null;
																		});
																	}}
																	disabled={!availableToEdit}
																/>

																{/* email */}
																<CustomTextInput
																	value={values.email}
																	placeholder={strings.insertEmail}
																	title={strings.email}
																	obligatory={false}
																	disabled={true}
																	styleText={{ marginLeft: 0, marginRight: 0 }}
																	styleTextInput={{ marginLeft: 0, marginRight: isWeb ? "2%" : 0, width: isWeb ? "auto" : "100%" }}
																/>
															</View>

															{isWeb && (
																<View style={[isWeb && styles.editFormColumn]}>
																	<View style={[styles.profilePictureWebContainer, { marginLeft: "3%", marginTop: 20 }]}>
																		<Text style={[styles.loadPicture, styles.loadPictureMTop, styles.loadPictureCenter, { marginBottom: 10 }]}>
																			{strings.loadYourImage}
																		</Text>
																		{profilePictureLoaded ? (
																			<>
																				<View style={[styles.profilePictureWeb, { marginBottom: 10 }]}>
																					<Image
																						resizeMode="cover"
																						style={[styles.profilePicture, styles.alignCenter]}
																						source={{ uri: profilePicture }}
																					></Image>
																				</View>

																				<View style={{ flexDirection: "row", justifyContent: "center" }}>
																					<View style={styles.buttonProfilePictureWebContainer}>
																						<Button
																							handleButtonPress={() => addProfilePicture()}
																							text={strings.changeImage}
																							style={styles.buttonProfilePictureWeb}
																							filledButton={true}
																						/>
																					</View>
																					<View style={styles.buttonProfilePictureWebContainer}>
																						<Button
																							handleButtonPress={() => deleteProfilePicture()}
																							text={strings.deleteImage}
																							style={styles.buttonProfilePictureWeb}
																							filledButton={false}
																						/>
																					</View>
																				</View>
																			</>
																		) : (
																			<>
																				<View>
																					<FontAwesomeIcon style={[styles.profilePicture, { alignSelf: "center" }]} size={140} icon={faUserCircle} />
																					<Button
																						handleButtonPress={() => addProfilePicture()}
																						text={strings.uploadImage}
																						style={{ alignSelf: "center" }}
																						filledButton={true}
																					/>
																				</View>
																			</>
																		)}
																		<CustomTextInput
																			numberOfLine={4}
																			maxLenght={500}
																			value={values.notes || ""}
																			placeholder={strings.insertNote}
																			title={""}
																			obligatory={false}
																			styleTextInput={styles.noteWeb}
																			setValue={handleChange("notes")}
																			onBlur={() => handleBlur("notes")}
																			disabled={!availableToEdit}
																		/>
																	</View>
																</View>
															)}
														</View>
													)}
													{formStep === 2 && (
														<View>
															<View style={[isWeb && styles.editFormColumn]}>
																<CustomTextInput
																	value={values.phoneNumber}
																	placeholder={strings.insertPhoneNumber}
																	title={strings.phoneNumber}
																	obligatory={true}
																	setValue={handleChange("phoneNumber")}
																	onBlur={() => handleBlur("phoneNumber")}
																	disabled={!availableToEdit}
																	styleText={{ marginLeft: 0, marginRight: 0 }}
																	styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
																	fieldValidationPattern={rules.phoneNumberRegex}
																	patternErrorMsg={strings.phoneNumberNotValid}
																/>

																{role !== roles.serviceCoordinator && (
																	<CustomTextInput
																		value={values.relativesPhoneNumber?.toString() || ""}
																		placeholder={strings.insertParentPhoneNumber}
																		title={strings.parentPhoneNumber}
																		obligatory={false}
																		keyboardType={"numeric"}
																		disabled={!availableToEdit}
																		setValue={handleChange("relativesPhoneNumber")}
																		onBlur={() => handleBlur("relativesPhoneNumber")}
																		styleText={{ marginLeft: 0, marginRight: 0 }}
																		styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
																		fieldValidationPattern={rules.phoneNumberRegex}
																		patternErrorMsg={strings.phoneNumberNotValid}
																	/>
																)}

																<View style={styles.titleContainer}>
																	<Text style={styles.textInputTitle}>{strings.address}</Text>
																	<Text style={styles.asterisk}>*</Text>
																</View>
																<View style={{ zIndex: 9999, marginLeft: 0, marginRight: 0, width: "100%" }}>
																	<SearchBarWithAutocomplete
																		placeholder={strings.insertAddress}
																		address={values.address}
																		setAddress={(value: any) => setFieldValue("address", value)}
																		setCoordinates={(lat: any, lng: any) => setFieldValue("coordinates", { lat: lat, lng: lng })}
																		disabled={!availableToEdit}
																		style={{ marginHorizontal: 0 }}
																	/>
																</View>

																{!isWeb && (
																	<>
																		<CustomTextInput
																			numberOfLine={4}
																			maxLenght={500}
																			value={values.notes || ""}
																			placeholder={strings.insertNote}
																			title={strings.note}
																			obligatory={false}
																			setValue={handleChange("notes")}
																			onBlur={() => handleBlur("notes")}
																			disabled={!availableToEdit}
																			styleText={{ marginLeft: 0, marginRight: 0 }}
																			styleTextInput={{ marginLeft: 0, marginRight: 0, width: "100%" }}
																		/>
																		<View>
																			<Text style={[styles.loadPicture, styles.loadPictureMTop, { marginBottom: 10 }]}>{strings.loadYourImage}</Text>

																			<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
																				{profilePictureLoaded ? (
																					<>
																						<View style={styles.profilePictureMobile}>
																							<Image resizeMode="cover" style={[styles.profilePicture]} source={{ uri: profilePicture }}></Image>
																						</View>
																						<View style={{ marginTop: 30 }}>
																							<Button
																								handleButtonPress={() => addProfilePicture()}
																								text={strings.changeImage}
																								style={styles.buttonProfilePictureMobile}
																								filledButton={true}
																							/>
																							<Button
																								handleButtonPress={() => deleteProfilePicture()}
																								text={strings.deleteImage}
																								style={styles.buttonProfilePictureMobile}
																								filledButton={false}
																							/>
																						</View>
																					</>
																				) : (
																					<>
																						<View style={styles.profilePictureMobile}>
																							<FontAwesomeIcon style={[styles.profilePicture]} size={140} icon={faUserCircle} />
																						</View>
																						<View style={styles.profilePictureMobile}>
																							<Button
																								handleButtonPress={() => addProfilePicture()}
																								text={strings.uploadImage}
																								style={styles.buttonProfilePictureMobile}
																								filledButton={true}
																							/>
																						</View>
																					</>
																				)}
																			</View>
																		</View>
																	</>
																)}
															</View>
														</View>
													)}

													<View style={styles.editAction}>
														<Button
															handleButtonPress={() => (formStep === 1 ? setIsEditing(false) : setFormStep(formStep - 1))}
															text={formStep === 1 ? strings.cancel : strings.back}
															filledButton={false}
														/>
														<Button
															handleButtonPress={() => (formStep === 1 ? setFormStep(formStep + 1) : handleSubmit())}
															disabled={Object.keys(errors).length > 0}
															text={formStep === 1 ? strings.next : strings.updateProfile}
															filledButton={true}
														/>
													</View>
												</View>
											)}
										</Formik>
									</View>
								)}
							</View>
						</View>

						{role === 'user' ?
							<View style={[styles.whiteContainer, !isWeb ? { marginTop: 50 } : null]}>
								<View style={[styles.usersMobile]}>
									<View style={isWeb ? styles.rowView : null}>
										<View style={isWeb ? { width: "30%" } : null}>
											<View style={[{ height: 220, width: "100%", transform: isWeb ? [{ translateX: -90 }] : [{ translateY: -50 }], marginRight: -60, borderRadius: 10 }]}>
												<Image
													source={images.addFriends}
													style={{ width: "100%", height: 220, position: "absolute", top: 0, borderRadius: 10 }}
												></Image>
											</View>
										</View>
										<View style={isWeb ? { width: "70%" } : { marginTop: -20 }}>
											<Text style={styles.title}>{strings.profilePage.promoTile.title}</Text>
											<Text style={styles.text}>{strings.profilePage.promoTile.body}</Text>
											<Button style={isWeb ? { alignSelf: "flex-start" } : null} text={strings.profilePage.promoTile.cta} handleButtonPress={() => setShowInvitedUsers(true)} filledButton={true} />
										</View>
									</View>
								</View>
							</View>
							: null
						}

						<Footer isWeb={isWeb} width={width} navigation={navigation} showFAQ={true} />

					</View>
				)}
			</ScrollView>
		</SafeAreaView>
	);
};

const ProfileScreenConnected = connectActionSheet(ProfileScreen);

const ProfileScreenContainer = (props: PropsInterface) => {
	return (
		<ActionSheetProvider>
			<ProfileScreenConnected navigation={props.navigation} />
		</ActionSheetProvider>
	);
};

export default ProfileScreenContainer;
