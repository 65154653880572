export enum PageNames {
	home = "Anzitutto",
	signup = "Registrati",
	login = "Accedi",
	dashboard = "Eventi",
	places = "Luoghi",
	placeManager = "Gestione luogo",
	users = "Utenti",
	user = "Utente",
	profile = "Profilo",
	createEventFood = "Creazione evento Cibo",
	createEventWellness = "Creazione evento Benessere",
	yourEvents = "I tuoi eventi",
	filter = "Seleziona filtri",
	eventDetail = "Dettaglio evento",
	groupDetail = "Dettaglio gruppo",
	activityDetail ="Dettaglio attività",
	support = "Supporto",
	privacyPolicy = "Privacy policy",
	faq = "Faq",
	settings = "Impostazioni",
	chat = "Chat",
	groups = "Gruppi",
	createGroup = "Crea gruppo",
	calendar = "Calendario",
	meetInstructions = "Istruzioni meet",
	createEvent = "createEvent"
}

