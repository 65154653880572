export class GoogleServices {
	constructor() {}

	public async searchPlace(searchedPlace: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const autocompleteService = new google.maps.places.AutocompleteService();
			autocompleteService.getPlacePredictions({ input: searchedPlace }, (res: any) => {
				resolve(res);
				console.log(res);
			});
		});
	}

	public async onPlacesTapped(placeId: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const geocoderService = new google.maps.Geocoder();
			geocoderService.geocode(
				{
					placeId: placeId,
				},
				(res: any) => {
					if (res.length > 0) {
						resolve({ lat: res[0].geometry?.location?.lat(), lng: res[0].geometry?.location?.lng() });
					}
					console.log(res);
				}
			);
		});
	}
}
