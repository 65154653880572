import { EntityResponse } from "../models/exchange";
import { Place, ProfileImageRequest, SearchPlacesParams, WorkingHoursDay } from "../models/models";
import { WorkingHoursReponse } from "../utils/WorkingHours";
import { APIBaseService } from "./ApiBaseService";

export class PlacesEndPoint extends APIBaseService {
	public static async getPlaces(placesParams: SearchPlacesParams): Promise<any> {
		let params = new URLSearchParams({});

		for (let k of Object.keys(placesParams)) {
			const c = placesParams[k as keyof SearchPlacesParams];
			if (typeof c == "string" || !isNaN(Number(c))) {
				params.set(k, String(c));
			}
			if (c instanceof Array) {
				c.reverse().forEach((p, i) => {
					params.set(k + "[" + i + "]", String(p));
				});
			}
		}

		return await APIBaseService.performRequest("get", `${APIBaseService.placeBaseUrl}/places?${params.toString()}`, false);
	}

	public static async getPlaceDetails(placeId: string): Promise<any> {
		return await APIBaseService.performRequest("get", `${APIBaseService.placeBaseUrl}/places/${placeId}`, false);
	}

	public static async registerPlace(place: Place): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.placeBaseUrl}/places`, false, place);
	}

	public static async modifyPlace(place: Place, placeId: string): Promise<any> {
		return await APIBaseService.performRequest("put", `${APIBaseService.placeBaseUrl}/places/${placeId}`, false, place);
	}

	public static async addPicture(image: ProfileImageRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.placeBaseUrl}/places/picture`, false, image);
	}

	public static async addDocument(document: ProfileImageRequest): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.placeBaseUrl}/places/document`, false, document);
	}

	public static async getWorkingHours(placeId: string): Promise<EntityResponse<WorkingHoursReponse>> {
		return await APIBaseService.performRequest("get", `${APIBaseService.placeBaseUrl}/places-availability/place/${placeId}/working-hours`, false);
	}

	public static async createWorkingHours(placeId: string, workingHours: WorkingHoursDay[]): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.placeBaseUrl}/places-availability/place/${placeId}/working-hours`, false, workingHours);
	}

	public static async editWorkingHours(placeId: string, workingHours: WorkingHoursDay[]): Promise<any> {
		return await APIBaseService.performRequest("put", `${APIBaseService.placeBaseUrl}/places-availability/place/${placeId}/working-hours`, false, workingHours);
	}

	public static async deleteWorkingHours(placeId: string): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.placeBaseUrl}/places-availability/place/${placeId}/working-hours`, false);
	}
}
