import { View, Platform } from "react-native";
import Svg, { Image } from "react-native-svg";
import { images } from "../../resources/images";
import IcCalendar from "../../assets/images/ic_calendar.svg";
import { colors } from "../../resources/colors";

interface Props {
	color?: string;
	size: number;
}

const CalendarIcon = ({ size = 24, color }: Props) => {
	return (
		<View>
			{Platform.OS === "web" ? (
				<Svg width={`${size}`} height={`${size}`} fill={!!color ? color : colors.black}>
					<Image width={size} height={size} href={images.icCalendar} opacity={!!color ? 1 : 0.5} />
				</Svg>
			) : (
				<IcCalendar width={size} height={size} fill={!!color ? color : colors.black} opacity={!!color ? 1 : 0.5} />
			)}
		</View>
	);
};
export default CalendarIcon;
