import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useState } from "react";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { EndPoints } from "../services/EndPoints";
import { AlertTypes } from "../utils/AlertTypes";
import { rules } from "../utils/Rules";
import BasicAlert from "./Alerts/BasicAlert";
import Button from "./Button";
import CloseIcon from "./CloseIcon";
import CustomTextInput from "./CustomTextInput";
import Loader from "./Loader";

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	isWeb: boolean;
}

const ChangePassword = (props: props) => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [passwordNotMatching, setPasswordNotMatching] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });

	const validatePassword = (password: string) => {
		return rules.passwordRegex.test(password) === true;
	};

	const save = async () => {
		setLoaderVisible(true);
		await EndPoints.changePassword(currentPassword, password, confirmPassword, false)
			.then(() => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				clearFields();
				props.setModalVisible(false);
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
			});
	};

	const clearFields = () => {
		setPassword("");
		setConfirmPassword("");
		setPassword("");
		setInvalidPassword(false);
		setPasswordNotMatching(false);
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => props.setModalVisible(false)}>
			<View style={styles.container}>
			<Pressable
				style={[styles.overlay, styles.backdrop]}
				onPress={() => {
					props.setModalVisible(false);
					clearFields();
				}}
			/>
				<View style={[styles.content, { width: props.isWeb ? "40%" : "80%" }]}>
					<Pressable
						style={styles.closeIcon}
						onPress={() => {
							props.setModalVisible(false);
							clearFields();
						}}
					>
						<Text style={styles.closeBtnText}>{strings.close}</Text>
						<FontAwesomeIcon style={{ marginTop: 2 }} color={colors.white} size={15} icon={faClose}></FontAwesomeIcon>
					</Pressable>

					<BasicAlert
						title={basicAlertValues.title}
						description={basicAlertValues.description}
						buttonText={basicAlertValues.buttonText}
						alertType={basicAlertValues.type}
						alertVisible={basicAlertValues.isVisible}
						setAlertVisible={(isVisible: boolean) => {
							setBasicAlertValues({
								isVisible: isVisible,
								title: basicAlertValues.title,
								description: basicAlertValues.description,
								type: basicAlertValues.type,
								buttonText: basicAlertValues.buttonText,
							});
						}}
					/>
					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<Text style={styles.title}>{strings.changePassword}</Text>

					{/* current password */}
					<CustomTextInput
						value={currentPassword}
						obligatory={false}
						placeholder={strings.insertCurrentPassword}
						title={strings.currentPassword}
						secureTextEntry={true}
						setValue={(value: string) => setCurrentPassword(value)}
					/>

					{/* password */}
					<CustomTextInput
						value={password}
						obligatory={false}
						placeholder={strings.insertPassword}
						title={strings.password}
						secureTextEntry={true}
						onBlur={() => (validatePassword(password) || password.length == 0 ? setInvalidPassword(false) : setInvalidPassword(true))}
						styleTextInput={invalidPassword ? [{ borderColor: colors.red }] : null}
						setValue={(value: string) => {
							setPassword(value);
							validatePassword(value) || value.length == 0 ? setInvalidPassword(false) : null;
						}}
					/>
					{invalidPassword ? <Text style={styles.errorText}>{strings.passwordNotValid}</Text> : null}

					{/* confirm password */}
					<CustomTextInput
						value={confirmPassword}
						obligatory={false}
						placeholder={strings.insertConfirmPassword}
						title={strings.confirmPassword}
						secureTextEntry={true}
						onBlur={() => (password === confirmPassword || confirmPassword.length == 0 ? setPasswordNotMatching(false) : setPasswordNotMatching(true))}
						styleTextInput={passwordNotMatching ? [{ borderColor: colors.red }] : null}
						setValue={(value: string) => {
							setConfirmPassword(value);
							password === value || value.length == 0 ? setPasswordNotMatching(false) : null;
						}}
					/>
					{passwordNotMatching ? <Text style={styles.errorText}>{strings.passwordsNotMatch}</Text> : null}

					<Button
						style={styles.button}
						handleButtonPress={() => save()}
						disabled={currentPassword == "" || password == "" || confirmPassword == "" || password != confirmPassword}
						text={strings.save}
						filledButton={false}
					/>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	errorText: {
		color: colors.red,
		marginLeft: "5%",
		marginRight: "5%",
		fontFamily: fonts.RalewayRegular,
	},
	button: {
		marginTop: Platform.OS === "web" ? "2%" : "10%",
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
	closeIcon: {
		position: "absolute",
		top: -24,
		right: 0,
		zIndex: 9999,
		flexDirection: "row",
		alignItems: "center"
	},
	closeBtnText: {
		color: colors.white,
		fontFamily: fonts.RalewaySemiBold,
		fontSize: 16,
		marginRight: 8,
	},
});

export default ChangePassword;
