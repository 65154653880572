import { View, Platform } from "react-native";
import Svg, { Image } from 'react-native-svg'
import { images } from "../../resources/images";
import IcGroup from '../../assets/images/ic_group.svg';
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";


const GroupIcon = ({ size = 24 }: IconProps) => {
    return (
        <View>
            {   Platform.OS === 'web' ?
                <Svg width={`${size}`} height={`${size}`}>
                    <Image width={size} height={size} href={images.icGroup} opacity={1}/>
                </Svg>
                :
                <IcGroup width={size} height={size} fill={colors.black} opacity={1}/>
            }
        </View>
    );
};  
export default GroupIcon;