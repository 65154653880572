import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	backButtonContainer: {
		marginHorizontal: "5%"
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
	},
	message: {
		fontSize: 16,
		marginLeft: 10,
		fontFamily: fonts.RalewayBold,
	},
	titleMargin: {
		marginRight: 24,
	},
	description: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		marginTop: 20,
		marginRight: "5%",
		marginLeft: "5%",
		marginBottom: "2%",
	},
	rowView: {
		flexDirection: "row",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		marginHorizontal: 16,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	webContainer: {
		marginLeft: "5%",
		marginRight: "5%",
	},
	usersWeb: {
		height: "100%",
		paddingRight: 30,
		paddingLeft: 30,
		marginHorizontal: 0,
	},
	usersMobile: {
		width: "100%",
		paddingTop: 30,
		paddingRight: 16,
		paddingBottom: 30,
		paddingLeft: 16,
	},
	userTitle: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	userTitleMobile: {
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	userTitleMargin: {
		marginVertical: 16,
	},
});
