import { useEffect, useState } from "react";
import { Modal, Pressable, View, Text, StyleSheet, Platform } from "react-native";
import { colors } from "../../resources/colors";
import { strings } from "../../locales/strings";
import { fonts } from "../../resources/fonts";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import React from "react";
import { SelectItem } from "../../models/models";
import SelectCard from "./SelectCard";

interface props {
	modalVisible: boolean;
	title?: string;
	subTitle?: string;
	items: Array<SelectItem>;
	onClose: () => void;
	buttons: Array<{ text: string; onPress: (itemId: number) => void; }>
}

const SelectModal = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const [selectedItem, setSelectedItem] = useState<number>();

	React.useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	useEffect(() => {
		clear();
	}, [props.modalVisible])

	const clear = () => {
		setSelectedItem(undefined);
	}

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={props.onClose}>

			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={props.onClose} />
				<View style={styles.content}>

					<Pressable style={styles.closeBtn} onPress={props.onClose}>
						<Text style={styles.closeBtnText}>{strings.close}</Text>
						<FontAwesomeIcon size={15} color={colors.white} icon={faClose}></FontAwesomeIcon>
					</Pressable>

					{props.title && <Text style={styles.title}>{props.title}</Text>}
					{props.subTitle && <Text style={styles.subtitle}>{props.subTitle}</Text>}

					<View style={[styles.itemList, { flexDirection: isWeb ? 'row' : 'column' }]}>
						{props.items.map((item, index) => (
							<SelectCard
								key={`select-card${index}`}
								data={item}
								onPress={() => setSelectedItem(item.id !== selectedItem ? item.id : undefined)}
								isSelected={item.id === selectedItem}
							/>
						))
						}
					</View>

					<View style={styles.buttonContainer}>
						{props.buttons.map((button, index) =>
							<Button
								key={`select-button${index}`}
								style={{ marginHorizontal: 5 }}
								handleButtonPress={() => {
									if (selectedItem !== undefined) {
										button.onPress(selectedItem);
									}
								}}
								text={button.text}
								filledButton={true}
								disabled={selectedItem === undefined}
							/>)}
					</View>
				</View>
			</View>
		</Modal>
	);
};


const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 30,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "center",
		marginTop: 0,
	},
	overlay: {
		backgroundColor: colors.grey4,
		opacity: 0.8,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginHorizontal: 2,
	},
	subtitle: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayMediumItalic,
		color: colors.grey2,
		marginHorizontal: 5,
		marginBottom: 20,
		marginTop: 10,
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: 10,
		marginLeft: "5%",
		marginRight: "5%",
	},
	rowView: {
		height: '100%',
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	closeBtnText: {
		color: colors.white,
		fontFamily: fonts.RalewaySemiBold,
		fontSize: 16,
		marginRight: 8,
	},
	closeBtn: {
		flexDirection: "row",
		position: "absolute",
		top: -24,
		right: 0,
		alignItems: "center",
	},
	itemList: {
		margin: 16
	}
});

export default SelectModal;