export const faqUserHtml = `
<h1>FAQ USER</h1>
<p>
Porttitor dapibus mauris fermentum lacus magnis blandit interdum porttitor sed. Ligula himenaeos dignissim pulvinar ante purus consectetur ornare purus phasellus. Consectetur semper in vivamus cursus auctor arcu. Sit curabitur purus congue euismod luctus risus. Pretium habitant proin fringilla maecenas vivamus ornare enim laoreet luctus vulputate! Litora vehicula fusce mauris odio? Sollicitudin lobortis tristique nulla. Sagittis dolor a malesuada sem nec. Tempor ligula felis ornare consequat tristique a. Vulputate eget convallis suscipit odio mus amet, luctus est aptent senectus taciti cras. Vestibulum nulla, himenaeos porta scelerisque sollicitudin eros. Nullam, nec elit class praesent tempus nec ridiculus pharetra. Molestie maecenas massa.
</p>
<p>
Maecenas eu, tristique gravida augue. Pretium aptent sagittis augue placerat primis. Facilisis, dui condimentum tristique dolor dolor sociis hac aliquam lacus auctor integer! Maecenas hac luctus massa proin est. Ante porttitor dis egestas primis metus tempor sapien justo platea. Taciti duis a lacus felis pulvinar. Facilisi conubia hac porta etiam potenti parturient in turpis? Conubia, nisi sem orci ac malesuada leo. Suspendisse ridiculus vehicula odio fermentum dictumst consequat id nibh. Justo tempus interdum augue, proin natoque. Vulputate vehicula imperdiet curae; ipsum aenean inceptos. Curabitur fringilla curae; primis arcu. Vivamus.
</p>
<p>
Cum a cum est ad sociis malesuada adipiscing netus rhoncus! Elementum placerat platea diam egestas erat ornare. Nostra mus sem fermentum vehicula urna phasellus montes est auctor habitant mollis condimentum. Rutrum litora porttitor primis magna pulvinar orci pellentesque platea id nec fringilla. Hendrerit at etiam ad pretium. Mauris penatibus, mus netus odio turpis. Iaculis mattis dictumst suscipit ante ultricies. Eleifend fermentum convallis conubia purus. Magna posuere ut lorem vehicula lacinia vivamus; dolor ac erat felis penatibus quam. Tellus dis cursus gravida aenean lectus! Conubia, accumsan proin non.
</p>
<p>
Nibh feugiat aptent ut nostra cras porta suscipit etiam. Facilisis aliquet metus blandit mi auctor mattis bibendum in cubilia tempor interdum vivamus. Blandit id ipsum scelerisque donec vel. Duis mollis dictum cubilia, pharetra adipiscing nec consequat iaculis vehicula. Primis hendrerit nibh litora. Pharetra, mattis amet suspendisse risus in nisl. Nascetur nec sociis posuere est aptent magna.
</p>
<p>
Massa orci dis accumsan est varius in tincidunt vitae. At urna cursus himenaeos felis velit donec tempor pulvinar pharetra gravida nascetur eu. Maecenas habitasse lorem purus facilisis sapien leo ut vitae pharetra. Urna sollicitudin leo gravida dapibus scelerisque varius magnis tincidunt facilisis sociosqu orci pretium. Cum torquent tempor lectus lacinia sed dictumst venenatis ridiculus potenti suscipit. Diam habitant class imperdiet molestie tempor leo ornare euismod laoreet libero. Aliquam fringilla donec arcu massa leo dictumst inceptos, libero egestas. Nisl cras eros.
</p>`;

export const faqCoordinatorHtml = `
<h1>FAQ SREVICE COORDINATOR</h1>
<p>
Porttitor dapibus mauris fermentum lacus magnis blandit interdum porttitor sed. Ligula himenaeos dignissim pulvinar ante purus consectetur ornare purus phasellus. Consectetur semper in vivamus cursus auctor arcu. Sit curabitur purus congue euismod luctus risus. Pretium habitant proin fringilla maecenas vivamus ornare enim laoreet luctus vulputate! Litora vehicula fusce mauris odio? Sollicitudin lobortis tristique nulla. Sagittis dolor a malesuada sem nec. Tempor ligula felis ornare consequat tristique a. Vulputate eget convallis suscipit odio mus amet, luctus est aptent senectus taciti cras. Vestibulum nulla, himenaeos porta scelerisque sollicitudin eros. Nullam, nec elit class praesent tempus nec ridiculus pharetra. Molestie maecenas massa.
</p>
<p>
Maecenas eu, tristique gravida augue. Pretium aptent sagittis augue placerat primis. Facilisis, dui condimentum tristique dolor dolor sociis hac aliquam lacus auctor integer! Maecenas hac luctus massa proin est. Ante porttitor dis egestas primis metus tempor sapien justo platea. Taciti duis a lacus felis pulvinar. Facilisi conubia hac porta etiam potenti parturient in turpis? Conubia, nisi sem orci ac malesuada leo. Suspendisse ridiculus vehicula odio fermentum dictumst consequat id nibh. Justo tempus interdum augue, proin natoque. Vulputate vehicula imperdiet curae; ipsum aenean inceptos. Curabitur fringilla curae; primis arcu. Vivamus.
</p>
<p>
Cum a cum est ad sociis malesuada adipiscing netus rhoncus! Elementum placerat platea diam egestas erat ornare. Nostra mus sem fermentum vehicula urna phasellus montes est auctor habitant mollis condimentum. Rutrum litora porttitor primis magna pulvinar orci pellentesque platea id nec fringilla. Hendrerit at etiam ad pretium. Mauris penatibus, mus netus odio turpis. Iaculis mattis dictumst suscipit ante ultricies. Eleifend fermentum convallis conubia purus. Magna posuere ut lorem vehicula lacinia vivamus; dolor ac erat felis penatibus quam. Tellus dis cursus gravida aenean lectus! Conubia, accumsan proin non.
</p>
<p>
Nibh feugiat aptent ut nostra cras porta suscipit etiam. Facilisis aliquet metus blandit mi auctor mattis bibendum in cubilia tempor interdum vivamus. Blandit id ipsum scelerisque donec vel. Duis mollis dictum cubilia, pharetra adipiscing nec consequat iaculis vehicula. Primis hendrerit nibh litora. Pharetra, mattis amet suspendisse risus in nisl. Nascetur nec sociis posuere est aptent magna.
</p>
<p>
Massa orci dis accumsan est varius in tincidunt vitae. At urna cursus himenaeos felis velit donec tempor pulvinar pharetra gravida nascetur eu. Maecenas habitasse lorem purus facilisis sapien leo ut vitae pharetra. Urna sollicitudin leo gravida dapibus scelerisque varius magnis tincidunt facilisis sociosqu orci pretium. Cum torquent tempor lectus lacinia sed dictumst venenatis ridiculus potenti suscipit. Diam habitant class imperdiet molestie tempor leo ornare euismod laoreet libero. Aliquam fringilla donec arcu massa leo dictumst inceptos, libero egestas. Nisl cras eros.
</p>`;

export const faqPlacesManagerHtml = `
<h1>FAQ PLACE MANAGER</h1>
<p>
Porttitor dapibus mauris fermentum lacus magnis blandit interdum porttitor sed. Ligula himenaeos dignissim pulvinar ante purus consectetur ornare purus phasellus. Consectetur semper in vivamus cursus auctor arcu. Sit curabitur purus congue euismod luctus risus. Pretium habitant proin fringilla maecenas vivamus ornare enim laoreet luctus vulputate! Litora vehicula fusce mauris odio? Sollicitudin lobortis tristique nulla. Sagittis dolor a malesuada sem nec. Tempor ligula felis ornare consequat tristique a. Vulputate eget convallis suscipit odio mus amet, luctus est aptent senectus taciti cras. Vestibulum nulla, himenaeos porta scelerisque sollicitudin eros. Nullam, nec elit class praesent tempus nec ridiculus pharetra. Molestie maecenas massa.
</p>
<p>
Maecenas eu, tristique gravida augue. Pretium aptent sagittis augue placerat primis. Facilisis, dui condimentum tristique dolor dolor sociis hac aliquam lacus auctor integer! Maecenas hac luctus massa proin est. Ante porttitor dis egestas primis metus tempor sapien justo platea. Taciti duis a lacus felis pulvinar. Facilisi conubia hac porta etiam potenti parturient in turpis? Conubia, nisi sem orci ac malesuada leo. Suspendisse ridiculus vehicula odio fermentum dictumst consequat id nibh. Justo tempus interdum augue, proin natoque. Vulputate vehicula imperdiet curae; ipsum aenean inceptos. Curabitur fringilla curae; primis arcu. Vivamus.
</p>
<p>
Cum a cum est ad sociis malesuada adipiscing netus rhoncus! Elementum placerat platea diam egestas erat ornare. Nostra mus sem fermentum vehicula urna phasellus montes est auctor habitant mollis condimentum. Rutrum litora porttitor primis magna pulvinar orci pellentesque platea id nec fringilla. Hendrerit at etiam ad pretium. Mauris penatibus, mus netus odio turpis. Iaculis mattis dictumst suscipit ante ultricies. Eleifend fermentum convallis conubia purus. Magna posuere ut lorem vehicula lacinia vivamus; dolor ac erat felis penatibus quam. Tellus dis cursus gravida aenean lectus! Conubia, accumsan proin non.
</p>
<p>
Nibh feugiat aptent ut nostra cras porta suscipit etiam. Facilisis aliquet metus blandit mi auctor mattis bibendum in cubilia tempor interdum vivamus. Blandit id ipsum scelerisque donec vel. Duis mollis dictum cubilia, pharetra adipiscing nec consequat iaculis vehicula. Primis hendrerit nibh litora. Pharetra, mattis amet suspendisse risus in nisl. Nascetur nec sociis posuere est aptent magna.
</p>
<p>
Massa orci dis accumsan est varius in tincidunt vitae. At urna cursus himenaeos felis velit donec tempor pulvinar pharetra gravida nascetur eu. Maecenas habitasse lorem purus facilisis sapien leo ut vitae pharetra. Urna sollicitudin leo gravida dapibus scelerisque varius magnis tincidunt facilisis sociosqu orci pretium. Cum torquent tempor lectus lacinia sed dictumst venenatis ridiculus potenti suscipit. Diam habitant class imperdiet molestie tempor leo ornare euismod laoreet libero. Aliquam fringilla donec arcu massa leo dictumst inceptos, libero egestas. Nisl cras eros.
</p>`;

export const faqInternalOperatorHtml = `
<h1>FAQ INTERNAL OPERATOR</h1>
<p>
Porttitor dapibus mauris fermentum lacus magnis blandit interdum porttitor sed. Ligula himenaeos dignissim pulvinar ante purus consectetur ornare purus phasellus. Consectetur semper in vivamus cursus auctor arcu. Sit curabitur purus congue euismod luctus risus. Pretium habitant proin fringilla maecenas vivamus ornare enim laoreet luctus vulputate! Litora vehicula fusce mauris odio? Sollicitudin lobortis tristique nulla. Sagittis dolor a malesuada sem nec. Tempor ligula felis ornare consequat tristique a. Vulputate eget convallis suscipit odio mus amet, luctus est aptent senectus taciti cras. Vestibulum nulla, himenaeos porta scelerisque sollicitudin eros. Nullam, nec elit class praesent tempus nec ridiculus pharetra. Molestie maecenas massa.
</p>
<p>
Maecenas eu, tristique gravida augue. Pretium aptent sagittis augue placerat primis. Facilisis, dui condimentum tristique dolor dolor sociis hac aliquam lacus auctor integer! Maecenas hac luctus massa proin est. Ante porttitor dis egestas primis metus tempor sapien justo platea. Taciti duis a lacus felis pulvinar. Facilisi conubia hac porta etiam potenti parturient in turpis? Conubia, nisi sem orci ac malesuada leo. Suspendisse ridiculus vehicula odio fermentum dictumst consequat id nibh. Justo tempus interdum augue, proin natoque. Vulputate vehicula imperdiet curae; ipsum aenean inceptos. Curabitur fringilla curae; primis arcu. Vivamus.
</p>
<p>
Cum a cum est ad sociis malesuada adipiscing netus rhoncus! Elementum placerat platea diam egestas erat ornare. Nostra mus sem fermentum vehicula urna phasellus montes est auctor habitant mollis condimentum. Rutrum litora porttitor primis magna pulvinar orci pellentesque platea id nec fringilla. Hendrerit at etiam ad pretium. Mauris penatibus, mus netus odio turpis. Iaculis mattis dictumst suscipit ante ultricies. Eleifend fermentum convallis conubia purus. Magna posuere ut lorem vehicula lacinia vivamus; dolor ac erat felis penatibus quam. Tellus dis cursus gravida aenean lectus! Conubia, accumsan proin non.
</p>
<p>
Nibh feugiat aptent ut nostra cras porta suscipit etiam. Facilisis aliquet metus blandit mi auctor mattis bibendum in cubilia tempor interdum vivamus. Blandit id ipsum scelerisque donec vel. Duis mollis dictum cubilia, pharetra adipiscing nec consequat iaculis vehicula. Primis hendrerit nibh litora. Pharetra, mattis amet suspendisse risus in nisl. Nascetur nec sociis posuere est aptent magna.
</p>
<p>
Massa orci dis accumsan est varius in tincidunt vitae. At urna cursus himenaeos felis velit donec tempor pulvinar pharetra gravida nascetur eu. Maecenas habitasse lorem purus facilisis sapien leo ut vitae pharetra. Urna sollicitudin leo gravida dapibus scelerisque varius magnis tincidunt facilisis sociosqu orci pretium. Cum torquent tempor lectus lacinia sed dictumst venenatis ridiculus potenti suscipit. Diam habitant class imperdiet molestie tempor leo ornare euismod laoreet libero. Aliquam fringilla donec arcu massa leo dictumst inceptos, libero egestas. Nisl cras eros.
</p>`;

export const faqExternalOperatorHtml = `
<h1>FAQ EXTERNAL OPERATOR</h1>
<p>
Porttitor dapibus mauris fermentum lacus magnis blandit interdum porttitor sed. Ligula himenaeos dignissim pulvinar ante purus consectetur ornare purus phasellus. Consectetur semper in vivamus cursus auctor arcu. Sit curabitur purus congue euismod luctus risus. Pretium habitant proin fringilla maecenas vivamus ornare enim laoreet luctus vulputate! Litora vehicula fusce mauris odio? Sollicitudin lobortis tristique nulla. Sagittis dolor a malesuada sem nec. Tempor ligula felis ornare consequat tristique a. Vulputate eget convallis suscipit odio mus amet, luctus est aptent senectus taciti cras. Vestibulum nulla, himenaeos porta scelerisque sollicitudin eros. Nullam, nec elit class praesent tempus nec ridiculus pharetra. Molestie maecenas massa.
</p>
<p>
Maecenas eu, tristique gravida augue. Pretium aptent sagittis augue placerat primis. Facilisis, dui condimentum tristique dolor dolor sociis hac aliquam lacus auctor integer! Maecenas hac luctus massa proin est. Ante porttitor dis egestas primis metus tempor sapien justo platea. Taciti duis a lacus felis pulvinar. Facilisi conubia hac porta etiam potenti parturient in turpis? Conubia, nisi sem orci ac malesuada leo. Suspendisse ridiculus vehicula odio fermentum dictumst consequat id nibh. Justo tempus interdum augue, proin natoque. Vulputate vehicula imperdiet curae; ipsum aenean inceptos. Curabitur fringilla curae; primis arcu. Vivamus.
</p>
<p>
Cum a cum est ad sociis malesuada adipiscing netus rhoncus! Elementum placerat platea diam egestas erat ornare. Nostra mus sem fermentum vehicula urna phasellus montes est auctor habitant mollis condimentum. Rutrum litora porttitor primis magna pulvinar orci pellentesque platea id nec fringilla. Hendrerit at etiam ad pretium. Mauris penatibus, mus netus odio turpis. Iaculis mattis dictumst suscipit ante ultricies. Eleifend fermentum convallis conubia purus. Magna posuere ut lorem vehicula lacinia vivamus; dolor ac erat felis penatibus quam. Tellus dis cursus gravida aenean lectus! Conubia, accumsan proin non.
</p>
<p>
Nibh feugiat aptent ut nostra cras porta suscipit etiam. Facilisis aliquet metus blandit mi auctor mattis bibendum in cubilia tempor interdum vivamus. Blandit id ipsum scelerisque donec vel. Duis mollis dictum cubilia, pharetra adipiscing nec consequat iaculis vehicula. Primis hendrerit nibh litora. Pharetra, mattis amet suspendisse risus in nisl. Nascetur nec sociis posuere est aptent magna.
</p>
<p>
Massa orci dis accumsan est varius in tincidunt vitae. At urna cursus himenaeos felis velit donec tempor pulvinar pharetra gravida nascetur eu. Maecenas habitasse lorem purus facilisis sapien leo ut vitae pharetra. Urna sollicitudin leo gravida dapibus scelerisque varius magnis tincidunt facilisis sociosqu orci pretium. Cum torquent tempor lectus lacinia sed dictumst venenatis ridiculus potenti suscipit. Diam habitant class imperdiet molestie tempor leo ornare euismod laoreet libero. Aliquam fringilla donec arcu massa leo dictumst inceptos, libero egestas. Nisl cras eros.
</p>`;
