import { faAngleLeft, faLocationDot, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useEffect, useState } from "react";
import { View, Image, Pressable, Dimensions } from "react-native";
import { Platform, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Button from "../components/Button";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Tag from "../components/Tag";
import { strings } from "../locales/strings";
import { EventActivity, getFieldColor } from "../models/eventsModels";
import { colors } from "../resources/colors";
import { getActivityDate, getDefaultImageByScope } from "../utils/EventUtils";
import { styles } from "../styles/EventDetail.style";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import { AlertTypes } from "../utils/AlertTypes";
import BasicAlert from "../components/Alerts/BasicAlert";
import MapView from "../components/Places/MapView";
import { PageNames, } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";
import PlaceImage from "../components/Events/PlaceImage";
import VideochatIcon from "../components/Icons/VideochatIcon";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GetPartepationType } from "../utils/types/Partecipants";
import { EndPoints } from "../services/EndPoints";
import { GetProfile } from "../models/models";
import { PlacesEndPoint } from "../services/PlacesEndPoint";

const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

interface PropsInterface {
    navigation: any;
    route: {
        params: {
            activity: EventActivity;
            event: Event;
        };
    };

}

const ActitivyDetailScreen = (props: PropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;

    const [propsActivity, setPropsActivity] = useState(props.route.params.activity);

    const [propsEvent, setPropsEvent] = useState(props.route.params.event);



    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        setPropsActivity(props.route.params.activity);
        props.route.params.activity.place ? getPlace(props.route.params.activity.place._id) : null;

        setPropsEvent(props.route.params.event);
        getProfile(props.route.params.event);


    }, [props]);




    const [scrollRefScreen, setScrollRefScreen] = useState(null);
    const scrollToTop = () => {
        scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
    };

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [mapViewVisible, setMapViewVisible] = useState(false);
    const startDate = getActivityDate(propsActivity.startDate);
    const endDate = getActivityDate(propsActivity.endDate);
    const [partecipantType, setPartecipantType] = useState("");
    const [partecipantsNumber, setPartecipantsNumber] = useState(0);
    const [partecipantsList, setPartecipantsList] = useState<{ userId: string; name: string; type: string; friends: boolean }[]>([]);

    const [followingUserIds, setFollowingUserIds] = useState<{ items: string[] | undefined }>({ items: [] });

    const getProfile = async (eventDetails: any) => {
        await EndPoints.getProfile()
            .then((res: GetProfile) => {
                if (Platform.OS === "ios") {
                    setTimeout(() => {
                        setLoaderVisible(false);
                    }, 500);
                } else {
                    setLoaderVisible(false);
                }
                setFollowingUserIds({ items: res.item.follows });
                getPartecipants(eventDetails, true, res.item.follows);
            })
            .catch((e) => {
                if (Platform.OS === "ios") {
                    setTimeout(() => {
                        setLoaderVisible(false);
                    }, 500);
                } else {
                    setLoaderVisible(false);
                }
                setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
            });
    };

    const getPartecipants = async (eventDetails: any, isShortList: boolean, following?: string[]) => {
        if (eventDetails) {
            let userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
            let partecipants = eventDetails.participants ? eventDetails.participants : [];

            if (partecipants && partecipants.length > 0) {
                partecipants.forEach((user: any) => {
                    console.log("partecipants " + JSON.stringify(user));
                    if (user.userId === userId) {
                        setPartecipantType(GetPartepationType(user.type));
                    }
                    user.friends = userId === user.userId ? true : following ? following.includes(user.userId) : followingUserIds.items?.includes(user.userId);
                });
                isShortList ? setPartecipantsList(partecipants.slice(0, 6)) : setPartecipantsList(partecipants);
            }

            setPartecipantsNumber(partecipants.length);
        }
    };


    const [placeImages, setPlaceImages] = useState({ img1: "", img2: "", img3: "", img4: "" });

    const placePhotos = () => {
        return (
            <>
                <Text style={[styles.somePhotoOf, { marginTop: isWeb ? 20 : 0 }]}>{strings.somePhotosOf}</Text>
                {propsActivity.place ? (<Text style={[styles.subtitle, { marginBottom: 10 }]}>{propsActivity.place.name}</Text>) : (null)}
                <View style={!isWeb ? { marginBottom: -40 } : null}>
                    <View style={[styles.rowView, { zIndex: -1, width: "100%" }, isWeb ? { transform: [{ translateX: -60 }], marginRight: -60 } : null]}>
                        <PlaceImage rightImage={false} placeImage={placeImages.img1} hidePlaceholcer={true} />
                        <PlaceImage rightImage={true} placeImage={placeImages.img2} hidePlaceholcer={true} />
                    </View>
                    <View style={[styles.rowView, isWeb ? { transform: [{ translateX: -60 }] } : null]}>
                        <PlaceImage rightImage={false} placeImage={placeImages.img3} hidePlaceholcer={true} />
                        <PlaceImage rightImage={true} placeImage={placeImages.img4} hidePlaceholcer={true} />
                    </View>
                </View>
            </>
        );
    };

    const getPlace = (id: string) => {
        PlacesEndPoint.getPlaceDetails(id)
            .then((res: any) => {
                console.log("images " + JSON.stringify(res.item.imgLinks));
                setPlaceImages({
                    img1: res.item.imgLinks.length > 0 ? res.item.imgLinks[0] : "",
                    img2: res.item.imgLinks.length > 1 ? res.item.imgLinks[1] : "",
                    img3: res.item.imgLinks.length > 2 ? res.item.imgLinks[2] : "",
                    img4: res.item.imgLinks.length > 3 ? res.item.imgLinks[3] : "",
                });
            })
            .catch((e: any) => { });
    };


    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[{ height: windowHeight * 0.8, width: "100%" }, styles.slideContainer]}>
                <Image
                    source={propsActivity.img ? { uri: propsActivity.img } :
                        getDefaultImageByScope(propsActivity.field)}
                    style={{ width: "100%", height: windowHeight * 0.8, position: "absolute", top: 0 }}
                ></Image>
                <View style={styles.overlay} />
            </View>

            <Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} />



            <Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

            <BasicAlert
                title={basicAlertValues.title}
                description={basicAlertValues.description}
                buttonText={basicAlertValues.buttonText}
                alertType={basicAlertValues.type}
                alertVisible={basicAlertValues.isVisible}
                setAlertVisible={(isVisible: boolean) => {
                    setBasicAlertValues({
                        isVisible: isVisible,
                        title: basicAlertValues.title,
                        description: basicAlertValues.description,
                        type: basicAlertValues.type,
                        buttonText: basicAlertValues.buttonText,
                    });
                }}
            />



            <MapView
                navigation={props.navigation}
                modalVisible={mapViewVisible}
                setModalVisible={(isVisible: boolean) => setMapViewVisible(isVisible)}
                event={propsActivity}
            />

            {isWeb ? <PageNavigation /> : null}

            <ScrollView ref={(c) => setScrollRefScreen(c)} contentContainerStyle={{ paddingBottom: 100 }}>
                <View style={[styles.content, isWeb ? { flexDirection: "row" } : null]}>
                    <View style={[styles.mainContainer, {
                        borderColor: getFieldColor(propsActivity.field)
                        , borderWidth: 6
                    }, isWeb ? styles.mainContainerWeb : null]}>
                        <View style={{ width: isWeb ? "40%" : "100%" }}>
                            <Pressable
                                style={styles.backContainer}
                                onPress={() => {
                                    props.navigation.goBack();
                                }}
                            >
                                <FontAwesomeIcon style={styles.backIcon} color={colors.blue} size={15} icon={faAngleLeft} />
                                <Text style={styles.backText}>{strings.goBack}</Text>
                            </Pressable>

                            <View style={[styles.imageContainer, isWeb ? styles.webImageContainer : null]}>
                                <Image
                                    source={propsActivity.img ? { uri: propsActivity.img } : getDefaultImageByScope(propsActivity.field)}
                                    style={[styles.coverImage, {
                                        borderColor: getFieldColor(propsActivity.field),
                                        borderWidth: 6
                                    }, isWeb ? null : { width: "100%" }]}
                                ></Image>
                            </View>

                            {isWeb ? <>{placePhotos()}</> : null}
                        </View>

                        <View style={{ width: isWeb ? "60%" : "100%" }}>
                            <Text style={styles.title}>{propsActivity.title}</Text>

                            {/* Date and time */}
                            <View>
                                {startDate && endDate ? (
                                    <>
                                        <View style={isWeb ? styles.eventDateTags : null}>
                                            {startDate.day === endDate.day ? (
                                                <Tag
                                                    text={`${startDate.day} ${strings.from} ${startDate.hour} ${strings.to} ${endDate.hour}`} color={getFieldColor(propsActivity.field)} style={styles.eventDateTag} />

                                            ) : (
                                                <>
                                                    <Tag text={`${startDate.day} ${strings.itHour} ${startDate.hour}`} color={getFieldColor(propsActivity.field)} style={styles.eventDateTag} />
                                                    <Tag text={`${endDate.day} ${strings.itHour} ${endDate.hour}`} color={getFieldColor(propsActivity.field)} style={styles.eventDateTag} />
                                                </>
                                            )}
                                        </View>
                                    </>
                                ) : (null)}
                            </View>

                            {/* Address and maps */}
                            <View style={[styles.rowView, { marginTop: 20, marginBottom: 20 }]}>
                                {propsActivity.place ?
                                    <>
                                        <Text style={[styles.eventPlace]}>{propsActivity.place.name}</Text>
                                        {isWeb ? (
                                            <Button handleButtonPress={() => setMapViewVisible(true)} text={strings.map} filledButton={false} />
                                        ) : (
                                            <Pressable style={styles.locationIcon} onPress={() => setMapViewVisible(true)}>
                                                <FontAwesomeIcon size={20} color={colors.blue} icon={faLocationDot} />
                                            </Pressable>
                                        )}
                                    </>
                                    : (null)}
                            </View>


                            {propsActivity.description ? <Text style={styles.description}>{propsActivity.description}</Text> : null}


                            {/* External Operator */}
                            {propsActivity.externalOperator?.id ? (
                                <>
                                    <View style={styles.line} />
                                    <View style={[isWeb ? styles.rowView : null]}>
                                        <Text style={styles.subtitle}>{strings.externalOperator}</Text>
                                        <Pressable onPress={() => props.navigation.navigate(PageNames.user, { userId: propsActivity.externalOperator.id })}>
                                            <Text style={styles.operatorName}>{propsActivity.externalOperator.firstName + " " + propsActivity.externalOperator.lastName}</Text>
                                        </Pressable>
                                    </View>
                                </>
                            ) : null}


                        </View>
                    </View>

                    <View style={[isWeb ? styles.sideColumn : { marginBottom: 100 }]}>

                        <View style={[styles.smallWhiteContainer, isWeb ? { marginBottom: "2%" } : { marginTop: "2%" }]}>
                            <Text style={[styles.subtitle, { marginBottom: 20 }]}>{strings.partecipants}</Text>
                            {partecipantsList.length > 0 ? (
                                <>
                                    <View style={{ width: "100%" }}>
                                        {partecipantsList.map((item: any, index: number) => (
                                            <View style={[styles.rowView, { marginBottom: 10 }]} key={`partecipant${index}`}>
                                                <Pressable
                                                    style={styles.partecipantNameContainer}
                                                    onPress={() => props.navigation.navigate(PageNames.user, { userId: item.userId })}>
                                                    <Text style={[styles.partecipantName, { textDecorationLine: "underline" }]}>
                                                        {item.name}
                                                    </Text>
                                                    {item.mode === 'online' &&
                                                        <VideochatIcon style={styles.videochatIcon} color={colors.black} />}
                                                </Pressable>


                                            </View>
                                        ))}
                                    </View>
                                    <View style={styles.line} />
                                    <View style={styles.rowView}>
                                        <FontAwesomeIcon style={styles.partecipantsIcon} size={15} icon={faUserGroup}></FontAwesomeIcon>
                                        <Text style={[styles.partecipantName, { color: colors.grey, marginLeft: 25, marginTop: 10 }]}>
                                            {partecipantsNumber + " " + strings.partecipants}
                                        </Text>
                                        {partecipantsNumber > 6 ? (
                                            <Button
                                                handleButtonPress={() => getPartecipants(propsEvent, partecipantsNumber === partecipantsList.length)}
                                                text={partecipantsNumber === partecipantsList.length ? strings.less : strings.all}
                                                filledButton={false}
                                            />
                                        ) : null}
                                    </View>
                                </>
                            ) : (
                                <Text style={[styles.partecipantName, { marginTop: 10, marginBottom: 10 }]}>{strings.noPartecipants}</Text>
                            )}
                        </View>

                    </View>


                </View>
                <Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
            </ScrollView>
        </SafeAreaView>
    );
};



export default ActitivyDetailScreen;



