import { FunctionComponent, useEffect, useState } from "react";
import { StyleSheet, View, TextInput, FlatList, Text, Pressable, Platform } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { PredictionType } from "../screens/SignUpScreen";
import { GoogleServices } from "../services/GoogleServices";
const googleServices = new GoogleServices();

type SearchBarProps = {
	setAddress: (value: any) => any;
	setCoordinates: (lat: any, lng: any) => any;
	address?: string;
	placeholder: string;
	disabled?: boolean;
	style?: any;
};

const SearchBarWithAutocomplete: FunctionComponent<SearchBarProps> = ({ setAddress, setCoordinates, address, disabled = false, placeholder, style }) => {
	const [search, setSearch] = useState({ term: address || "", fetchPredictions: false });
	const [showPredictions, setShowPredictions] = useState(false);
	const [predictions, setPredictions] = useState<PredictionType[]>([]);

	useEffect(() => {
		address && address != "" ? setSearch({ term: address, fetchPredictions: true }) : null;
	}, [address]);

	const onChangeText = async (searchTerm: string) => {
		if (searchTerm.trim() === "") return setAddress("");
		if (!search.fetchPredictions) return setAddress(searchTerm);
		try {
			let result = await googleServices.searchPlace(searchTerm);
			if (result) {
				setPredictions(result);
				setShowPredictions(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onPredictionTapped = async (placeId: string, description: string) => {
		try {
			let result = await googleServices.onPlacesTapped(placeId);
			if (result) {
				const { lat, lng } = result;
				setShowPredictions(false);
				setSearch({ term: description, fetchPredictions: false });
				setAddress(description);
				setCoordinates(lat, lng);
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<View style={styles.container}>
			<View style={[styles.inputContainer, style && style]}>
				<TextInput
					style={[styles.textInput, Platform.OS === "web" ? { outlineWidth: 0 } : null, disabled && { backgroundColor: colors.greyReadOnly}]}
					placeholder={placeholder}
					placeholderTextColor={address ? colors.black : colors.grey}
					value={search.term}
					onChangeText={(text: string) => {
						setSearch({ term: text, fetchPredictions: true });
						onChangeText(text);
					}}
					returnKeyType={"search"}
					editable={!disabled}
				/>
			</View>
			{showPredictions ? (
				<View style={[{ marginHorizontal: 16 }, style, { zIndex: 9999 }]}>
					<FlatList
						data={predictions}
						renderItem={({ item, index }) => {
							return (
								<Pressable style={styles.predictionRow} onPress={() => onPredictionTapped(item.place_id, item.description)}>
									<Text numberOfLines={1}> {item.description} </Text>
								</Pressable>
							);
						}}
						keyExtractor={(item) => item.place_id}
						keyboardShouldPersistTaps="handled"
						style={[styles.predictionsContainer]}
					/>
				</View>
			) : null}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.white,
		zIndex: 99999,
	},
	inputContainer: {
		backgroundColor: colors.white,
		// width: '90%',
		// marginLeft: '5%',
		// marginRight: '5%',
		marginHorizontal: 16,
		flexDirection: "column",
		alignItems: "flex-start",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
	},
	textInput: {
		backgroundColor: colors.white,
		color: colors.black,
		alignContent: "center",
		width: "100%",
		height: 50,
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
	},
	predictionsContainer: {
		position: "relative",
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 1,
		borderRadius: 10,
		paddingTop: 5,
		height: 257,
		zIndex: 9999,
		backgroundColor: colors.white,
	},
	predictionRow: {
		borderColor: colors.grey,
		paddingBottom: 15,
		paddingTop: 15,
		borderBottomColor: colors.grey,
		borderBottomWidth: 1,
		width: "100%",
		zIndex: 9999,
	},
	predictionText: {
		fontFamily: fonts.RalewayRegular,
	},
});

export default SearchBarWithAutocomplete;
