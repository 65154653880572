import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
	},
	carouselContainer: {
		height: "100%",
		width: "100%",
		position: "absolute",
		top: 0,
		zIndex: 0,
	},
	contentBox: {
		marginHorizontal: "5%",
		marginBottom: "5%"
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
		marginBottom: 12,
	},

	actions: {
		display: "flex",
		flexDirection: "column",
	},
	actionsWeb: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	events: {
		marginVertical: 32,
	},
	locationIcon: {
		borderRadius: 50,
		borderWidth: 1,
		borderColor: colors.blue,
		padding: 8,
		marginTop: 8,
	},
});
