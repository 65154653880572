import { Platform, View, StyleSheet, Text, Pressable } from "react-native";
import { useEffect, useRef, useState } from "react";
import { Agenda, Calendar, DateData, LocaleConfig, WeekCalendar } from "react-native-calendars";
import dayjs from "dayjs";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { FlatList } from "react-native-gesture-handler";
import { FormatDate } from "../../utils/FormatDateUtils";
import ClockIcon from "../Icons/ClockIcon";
import TimePickerMobile from "./TimePickerMobile";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import BigCalendar from "./BigCalendarWrapper";
import { ICalendarEventBase, getDatesInWeek } from "react-native-big-calendar";

dayjs.extend(require("dayjs/plugin/localeData"));
dayjs.extend(require("dayjs/plugin/weekday"));
dayjs.extend(require("dayjs/plugin/isBetween"));
dayjs.locale("it");

interface props {
	minDate: string | undefined;
	maxDate: string | undefined;
	date: string | undefined;
	readonly: boolean;
	onDayPress: (day: string) => void;
	showTimePicker: boolean;
	position: "relative" | "absolute";
	showAgenda: boolean;
	startDate?: string;
	monthDataWeb: ICalendarEventBase[];
	monthDataMobile: any;
	webAgendaDaySelected: any;
	markedDates: any;
	containerStyle?: any;
	isStartDate?: boolean;
	onWeekOnChange?: (Date: dayjs.Dayjs[]) => void;
	dateActivityContained?: ICalendarEventBase[]
}

const CalendarComponent = ({
	minDate,
	maxDate,
	date,
	readonly,
	onDayPress,
	showTimePicker,
	position,
	showAgenda,
	startDate,
	monthDataWeb,
	monthDataMobile,
	webAgendaDaySelected,
	markedDates,
	containerStyle,
	isStartDate,
	onWeekOnChange,
	dateActivityContained,
}: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	LocaleConfig.locales["it"] = {
		monthNames: dayjs.months(),
		monthNamesShort: dayjs.monthsShort(),
		dayNames: dayjs.weekdays(),
		dayNamesShort: dayjs.weekdaysShort(),
		today: strings.today,
	};
	LocaleConfig.defaultLocale = "it";

	const [timeSelected, setTimeSelected] = useState<number>(32); // 8:00
	const [showMobileTimepicker, setShowMobileTimepicker] = useState(false);
	const [agendaDaySelected, setAgendaDaySelected] = useState(new Date().toString());
	const [weeklyCalendarKey, setWeeklyCalendarKey] = useState<number>(0);

	const flatListHoursRef = useRef<FlatList>(null);

	useEffect(() => {
		!!startDate ? setAgendaDaySelected(startDate) : undefined;
		!!startDate ? setTimeSelected(FormatDate.getTimesSlotArray().indexOf(dayjs(date).format("H:mm"))) : setTimeSelected(timeSelected);
	}, [startDate]);

	useEffect(() => {
		setWeeklyCalendarKey(weeklyCalendarKey + 1);
	}, [monthDataWeb]);

	useEffect(() => {
		if (showTimePicker) {
			setTimeout(() => {
				flatListHoursRef?.current?.scrollToIndex({ index: timeSelected, animated: false });
			}, 0);
		}
	}, [showTimePicker]);

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	const buildDate = (time: string, dateSelected?: string): string => {
		const timeSplitted: string[] = time.split(":");
		const d = dayjs(dateSelected ? dateSelected : !!date ? date : minDate)
			.set("hour", parseInt(timeSplitted[0]))
			.set("minute", parseInt(timeSplitted[1]))
			.set("second", 0)
			.toString();
		return d;
	};

	const addTime = (time: string, dateSelected?: string) => {
		onDayPress(buildDate(time, dateSelected));
	};

	const isAvailable = (time: string): boolean => {
		if (!!monthDataWeb) {
			const inclusivity = isStartDate ? "[)" : "(]";
			const date = buildDate(time);
			let slotAvailable = true;
			monthDataWeb.forEach((el) => {
				if (slotAvailable) {
					slotAvailable = !dayjs(date).isBetween(dayjs(el.start), dayjs(el.end), "minutes", inclusivity);
				}
			});
			return slotAvailable;
		} else return true;
	};

	const activityIsAvailable = (time: string): boolean => {
		if (!!dateActivityContained) {
			const inclusivity = isStartDate ? "[)" : "(]";
			const date = buildDate(time);

			let activityAvailable = true;
			dateActivityContained?.forEach((el) => {
				if (activityAvailable) {
					activityAvailable = dayjs(date).isBetween(dayjs(el.start), dayjs(el.end), "minutes", inclusivity)
				}
			});
			return activityAvailable;
		}
		else return true;
	}

	const getItemLayout = (data: unknown, index: number) => ({ length: 60, offset: 60 * index, index });

	return (
		<View>
			{showAgenda ? (
				<View>
					{Platform.OS === "web" ? (
						<View style={{ flex: 1 }}>
							<BigCalendar events={monthDataWeb} focusDate={webAgendaDaySelected} swipeEnabled={false}
								onWeekChange={(isForward: any) => {
									if (!!onWeekOnChange) {
										onWeekOnChange(isForward)
									}

								}} />
						</View>
					) : (
						<View>
							<View style={[styles.agendaHeaderContainer, containerStyle && containerStyle]}>
								<Pressable
									style={styles.agendaArrow}
									onPress={() => {
										const date = dayjs(agendaDaySelected).weekday(-7);
										setAgendaDaySelected(new Date(date).toString());
									}}
								>
									<FontAwesomeIcon icon={faArrowLeft} />
								</Pressable>
								<Text style={styles.agendaDateSelected}>{FormatDate.formatAgendaDate(agendaDaySelected)}</Text>
								<Pressable
									style={styles.agendaArrow}
									onPress={() => {
										const date = dayjs(agendaDaySelected).weekday(+7);
										setAgendaDaySelected(new Date(date).toString());
									}}
								>
									<FontAwesomeIcon icon={faArrowRight} />
								</Pressable>
							</View>
							<Agenda
								selected={agendaDaySelected}
								onDayPress={(date: DateData) => {
									!!date ? setAgendaDaySelected(new Date(date.timestamp).toString()) : null;
								}}
								showOnlySelectedDayItems={true}
								firstDay={1}
								items={monthDataMobile}
								renderItem={(item) => {
									return (
										<View
											key={`agenda-events${item}`}
											style={[
												containerStyle ? containerStyle : styles.agendaContainer,
												{ backgroundColor: item.type === "event" ? colors.primary : colors.grey },
											]}
										>
											<Text style={styles.agendaTime}>{FormatDate.getTime(item.start) + " - " + FormatDate.getTime(item.end)}</Text>
											<Text style={styles.agendaName}>{item.name}</Text>
										</View>
									);
								}}
								renderEmptyData={() => {
									return <Text style={styles.noEventsAvailable}>{strings.noEventsAvailable}</Text>;
								}}
								rowHasChanged={(r1: any, r2: any) => {
									return r1.text !== r2.text;
								}}
								hideKnob={true}
								showClosingKnob={false}
								scrollEnabled={false}
								theme={{
									dotColor: colors.blue,
									selectedDayBackgroundColor: colors.blue,
									selectedDayTextColor: colors.white,
									selectedDotColor: colors.white,
									textDayFontFamily: fonts.RalewayRegular,
									textDayHeaderFontFamily: fonts.RalewayRegular,
									textMonthFontFamily: fonts.RalewayRegular,
									todayTextColor: colors.blue,
									agendaDayNumColor: colors.black,
									agendaDayTextColor: colors.black,
									agendaTodayColor: colors.blue,
									textDayFontSize: Platform.select({ ios: 14, android: 16 }),
									"stylesheet.calendar.header": { week: { marginTop: Platform.OS === "ios" ? 6 : 2, flexDirection: "row", justifyContent: "space-between" } },
								}}
							/>
						</View>
					)}
				</View>
			) : (
				<View>
					<View
						style={[
							showTimePicker ? { flexDirection: "row" } : null,
							Platform.OS === "web" && position === "absolute" ? [styles.calendarContainer, { height: 350 }] : null,
						]}
					>
						<View style={showTimePicker && Platform.OS === "web" ? { width: "70%" } : null}>
							<Calendar
								style={{ zIndex: 9999, backgroundColor: colors.white, flex: 1 }}
								headerStyle={{ minWidth: 200 }}
								minDate={minDate}
								maxDate={maxDate}
								date={date}
								initialDate={!!date ? date : startDate}
								onDayPress={(day) => {
									if (!readonly) {
										addTime(FormatDate.getTimesSlotArray()[timeSelected], day.dateString);
									}
								}}
								monthFormat={"MMMM"}
								hideArrows={false}
								firstDay={1}
								onPressArrowLeft={(subtractMonth) => subtractMonth()}
								onPressArrowRight={(addMonth) => addMonth()}
								disableAllTouchEventsForDisabledDays={true}
								enableSwipeMonths={!readonly}
								markedDates={{
									[dayjs(date).format("YYYY-MM-DD")]: { selected: !!date, selectedColor: colors.blue3 },
								}}
								disableMonthChange={readonly}
								renderArrow={(direction) => {
									if (direction == "left") {
										return <Text>{strings.previous}</Text>;
									} else {
										return <Text>{strings.next}</Text>;
									}
								}}
								theme={{ textDayFontFamily: fonts.RalewayRegular, textDayHeaderFontFamily: fonts.RalewayRegular }}
							/>
						</View>
						{showTimePicker && Platform.OS === "web" && (
							<View style={{ zIndex: 999, position: "absolute", right: 0, flex: 1, width: "30%" }}>
								<FlatList
									ref={flatListHoursRef}
									style={{ height: 340 }}
									getItemLayout={getItemLayout}
									data={FormatDate.getTimesSlotArray()}
									renderItem={({ item, index }) => {
										return (
											<Pressable
												key={`times-${index}`}
												style={[
													styles.timePickerContainer,
													timeSelected === index ? { backgroundColor: colors.blue3 } : { backgroundColor: isAvailable(item) && activityIsAvailable(item) ? colors.white : colors.grey },
												]}
												onPress={() => {
													if (isAvailable(item) && activityIsAvailable(item)) {
														setTimeSelected(index);
														addTime(item);
													}
												}}
											>
												<Text style={[styles.timePickerText, timeSelected === index ? { color: colors.white } : null]}>{item}</Text>
											</Pressable>
										);
									}}
									keyExtractor={(index) => index}
								/>
							</View>
						)}
					</View>

					{showTimePicker && Platform.OS !== "web" && (
						<Pressable
							style={styles.mobileTimePickerContainer}
							onPress={() => {
								setShowMobileTimepicker(true);
							}}
						>
							<View style={styles.clockIconContainer}>
								<ClockIcon />
							</View>
							<Text style={[styles.timePickerText, { paddingVertical: 5 }]}>{!!date ? FormatDate.getTime(date) : strings.tapToSelectTime}</Text>
						</Pressable>
					)}

					{showMobileTimepicker && Platform.OS !== "web" && (
						<TimePickerMobile
							date={date}
							readonly={readonly}
							onDayPress={(day: string) => onDayPress(day)}
							showMobileTimepicker={showMobileTimepicker}
							setShowMobileTimepicker={(isVisible: boolean) => setShowMobileTimepicker(isVisible)}
						/>
					)}
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	calendarContainer: {
		zIndex: 999,
		width: "100%",
		backgroundColor: colors.white,
		borderWidth: 1,
		borderColor: colors.grey,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		position: "absolute",
	},
	timePickerContainer: {
		borderBottomColor: colors.primary,
		borderBottomWidth: 4,
		paddingLeft: 10,
		marginRight: 0,
		zIndex: 999,
	},
	timePickerText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingVertical: 20,
		paddingLeft: 10,
	},
	mobileTimePickerContainer: {
		flexDirection: "row",
		justifyContent: "center",
		paddingBottom: 10,
	},
	clockIconContainer: {
		paddingTop: 1,
	},
	agendaName: {
		fontFamily: fonts.RalewaySemiBold,
		paddingHorizontal: 15,
		fontSize: 14,
		paddingVertical: 10,
		marginBottom: 10,
		color: colors.black,
		textTransform: "uppercase",
	},
	agendaTime: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 12,
		marginTop: 10,
		paddingHorizontal: 15,
		color: colors.black,
	},
	agendaContainer: {
		borderWidth: 1,
		borderColor: colors.grey,
	},
	noEventsAvailable: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 12,
		alignSelf: "center",
		paddingVertical: 20,
		paddingHorizontal: 15,
	},
	agendaDateSelected: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		alignSelf: "center",
	},
	agendaArrow: {
		paddingTop: 5,
		height: 20,
		width: 20,
	},
	agendaHeaderContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: 20,
		paddingTop: 10,
	},
});

export default CalendarComponent;
