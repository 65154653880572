import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safeArea: {
		backgroundColor: colors.white,
		height: "100%",
	},
	scrollView: {
		marginTop: 20,
		marginHorizontal: 10,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	buttonsContainer: {
		position: "absolute",
		bottom: 0,
		height: 120,
		borderTopColor: colors.grey,
		borderTopWidth: 1,
		backgroundColor: colors.white,
		width: "100%",
		paddingBottom: 50,
		paddingHorizontal: 15,
	},
	title: {
		fontFamily: fonts.RalewayBold,
		fontSize: 24,
		marginTop: 30,
		marginHorizontal: 15,
	},
	filtersLabel: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
	},
});
