



import { View, Text } from "react-native";
import { strings } from "../../../locales/strings";
import { styles } from "../../../styles/CreateEventScreen";
import { EventStatus } from "../../../utils/EventStatus";
import { roles } from "../../../utils/Roles";
import { CreateEventOperator, CreateEventUser, Event } from "../../../models/eventsModels";
import SelectOperator from "../../../components/Events/SelectOperator";
import Counter from "../../../components/Counter";
import { MacroArea, Place } from "../../../models/models";
import { useState } from "react";
import { dashboardType } from "../../../utils/DashboardType";
import { PageNames } from "../../../navigation/NavigationUtils";

interface IProps {
    isFullWidth: boolean;
    isWeb: boolean;
    edit: boolean;
    role: roles;
    isReadOnly: boolean;

    typology: MacroArea;
    placesList: Array<Place>;

    propsEvent?: Event;
    event: any;
    setEvent: (event: any) => void;

    internalOperator: CreateEventOperator;
    externalOperator: CreateEventOperator;
    internalUsers: Array<CreateEventUser>;
    externalUsers: Array<CreateEventUser>;

    setInternalOperator: (value: CreateEventOperator) => void;
    setExternalOperator: (value: CreateEventOperator) => void;
}

export const OperatorsAndNumberOfPartecipants = (props: IProps) => {
    const [openInternalUserPicker, setOpenInternalUserPicker] = useState<boolean>(false);
    const [openExternalUserPicker, setOpenExternalUserPicker] = useState<boolean>(false);


    return <View style={{ width: props.isFullWidth ? "100%" : "50%" }}>
        <View style={{ marginLeft: false ? 10 : 0 }}>
            <View style={{ zIndex: 2 }}>
                {/* Internal Operator */}
                <SelectOperator
                    pageName={props.event.field === dashboardType.food ? PageNames.createEventFood : PageNames.createEventWellness}
                    isReadOnly={props.isReadOnly && (props.role !== roles.internalUser || (props.role === roles.internalUser && props.event.status === EventStatus.wait_internal_operator))}
                    typology={props.typology}
                    event={props.event}
                    setEvent={props.setEvent}
                    openInternalUserPicker={openInternalUserPicker}
                    userSelected={props.internalOperator}
                    setOpenInternalUserPicker={(value: boolean) => setOpenInternalUserPicker(value)}
                    internalUsers={props.internalUsers}
                    setUserSelected={(firstName: string, lastName: string) => props.setInternalOperator({ firstName: firstName, lastName: lastName })}
                    isInternal={true}
                />
            </View>
            {/* External Operator - ONLY VISIBLE TO INTERNAL, EXTERNAL OPERATORS AND SERVICE COORDINATOR */}
            {props.edit && props.propsEvent?.status != EventStatus.draft ? (
                <View style={{ zIndex: 1 }}>
                    <SelectOperator
                        pageName={props.event.field === dashboardType.food ? PageNames.createEventFood : PageNames.createEventWellness}
                        isReadOnly={props.role !== roles.externalUser && props.role !== roles.internalUser}
                        typology={props.typology}
                        event={props.event}
                        setEvent={props.setEvent}
                        openInternalUserPicker={openExternalUserPicker}
                        userSelected={props.externalOperator}
                        setOpenInternalUserPicker={(value: boolean) => setOpenExternalUserPicker(value)}
                        internalUsers={props.externalUsers}
                        setUserSelected={(firstName: string, lastName: string) => props.setExternalOperator({ firstName: firstName, lastName: lastName })}
                        isInternal={false}
                    />
                </View>
            ) : null}

            {/* Max Number of Users */}
            <Text style={[styles.fieldTitle, { marginTop: 20 }]}>{strings.maxNumberOfUsers}</Text>
            <Text style={[styles.fieldDescription]}>{strings.maxNumberOfPartecipans}</Text>
            <View style={{ width: "100%", marginBottom: props.isWeb ? 50 : 0, marginTop: 10 }}>
                <Counter
                    isReadOnly={props.isReadOnly}
                    value={props.event.maxNumberOfUsers}
                    setValue={(value) => props.setEvent({ ...props.event, maxNumberOfUsers: value.toString() })}
                    style={{ marginLeft: 0 }}
                    maxValue={props.placesList.find((place) => place.id === props.event.place._id)?.maxNumberOfUsers}
                    maxValueMessage={strings.maxUsersReached}
                />
            </View>
        </View>
    </View>;
}
