import { View, Platform } from "react-native";
import Svg, { Image } from 'react-native-svg'
import { images } from "../../resources/images";
import IcEmoji from '../../assets/images/ic_emoji.svg';
import { IconProps } from "../../models/models";
import { colors } from "../../resources/colors";


const EmojiIcon = ({ size = 24 }: IconProps) => {
    return (
        <View>
            {   Platform.OS === 'web' ?
                <Svg width={`${size}`} height={`${size}`}>
                    <Image width={size} height={size} href={images.icEmoji} opacity={0.5}/>
                </Svg>
                :
                <IcEmoji width={size} height={size} fill={colors.black} opacity={0.5}/>
            }
        </View>
    );
};  
export default EmojiIcon;