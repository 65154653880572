import { Platform, Pressable, Text, View, FlatList } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import FilterButton from "../components/FilterButton";
import Searchbar from "../components/Searchbar";
import Button from "../components/Button";
import { styles } from "../styles/Users.style";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleRight, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import Loader from "../components/Loader";
import { GetProfile, Profile } from "../models/models";
import { UsersEndpoints } from "../services/UsersEndpoints";
import Pagination from "../components/Pagination";
import { capitalizeName } from "../utils/UserUtils";
import { EndPoints } from "../services/EndPoints";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ProfileImage from "../components/ProfileImage";
import { roles } from "../utils/Roles";
import RoleTags from "../components/RoleTags";
import { useIsFocused } from "@react-navigation/native";
import { PageNames, externalPageName } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";


interface PropsInterface {
	navigation?: any;
}

interface SearchProps {
	searchTerm: string;
	page: number;
	userFilter: "all" | "following" | "followers";
	sorting: "asc" | "desc";
}

const USER_PER_PAGE = 15;

const DEFAULT_FILTERS: SearchProps = {
	searchTerm: "",
	page: 1,
	userFilter: "all",
	sorting: "asc",
};

const UsersScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isPlatformWeb = Platform.OS === "web";
	const isWeb = width >= 768;
	const [user, setUser] = useState<Profile | null>(null);
	const [isUser, setIsUser] = useState(false);
	const [isServiceCoordinator, setIsServiceCoordinator] = useState(false);
	const focused = useIsFocused();

	const userFollows = user?.follows || [];

	useEffect(() => {
		if (isPlatformWeb) {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		getProfile();
		checkRoles();
	}, [focused]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const [filters, setFilters] = useState<SearchProps>(DEFAULT_FILTERS);
	const clearFields = () => {};
	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });

	const [users, setUsers] = useState<{
		totalCounts: number;
		items: Profile[];
	}>({
		totalCounts: 0,
		items: [],
	});

	const [loaderVisible, setLoaderVisible] = useState(false);

	const [shouldLogout, setShouldLogout] = useState<boolean>(false);

	const searchUsers = async () => {
		setLoaderVisible(true);
		try {
			const usersRes = await UsersEndpoints.getUsers(
				filters.page,
				USER_PER_PAGE,
				filters.searchTerm,
				filters.sorting,
				[],
				filters.userFilter != "all" ? filters.userFilter : undefined
			);
			const items = isWeb ? usersRes.page.items : filters.page === 1 ? usersRes.page.items : users.items.concat(usersRes.page.items);

			setUsers({
				totalCounts: usersRes.total,
				items,
			});
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
			setShouldLogout(e.code == 409);
		} finally {
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	useEffect(() => {
		searchUsers();
	}, [filters]);

	const setUserFilter = (filter: "all" | "following" | "followers") => {
		setFilters({
			...DEFAULT_FILTERS,
			userFilter: filter,
		});
	};

	const toogleFollow = async (userId: string, type: "follow" | "unfollow") => {
		setLoaderVisible(true);
		try {
			const result = await UsersEndpoints.updateUserFollow({
				userId,
				type,
			});
			result.item && setUser(result.item);
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
			setShouldLogout(e.code == 409);
		} finally {
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const getProfile = async () => {
		await EndPoints.getProfile()
			.then((res: GetProfile) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setUser(res.item);
			})
			.catch((e) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setError({ isVisible: true, title: e.status, description: e.error.message });
				setShouldLogout(e.code == 409);
			});
	};

	const checkRoles = async () => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setIsUser(userRole === "user");
		setIsServiceCoordinator(userRole === "service-coordinator");
	};

	const renderTableItem = ({ item, index }: { item: Profile, index: number }) => {
		if (!item || !item.id) {
			return <></>;
		}

		const followUser = userFollows.includes(item.id || "");

		const goToProfile = () => item.id && props.navigation.navigate(PageNames.user, { userId: item.id });

		return isWeb ? (
			<View style={styles.userRow} key={`users${index}`}>
				<View style={styles.userItemName}>
					<ProfileImage profilePicture={item.profilePicture} firstName={item.firstName} lastName={item.lastName} />
					<Text style={styles.userName}>{capitalizeName(item)}</Text>				
				</View>
				<View style={{ flexDirection: 'row', marginHorizontal: 50 }}>
					{	isUser && item.roles.includes(roles.user) &&
						<Button
							style={{ width: 180, marginRight: 10 }}
							text={followUser ? strings.users.unfollow : strings.users.followFriend}
							handleButtonPress={() => item.id && toogleFollow(item.id, followUser ? "unfollow" : "follow")}
							filledButton={followUser ? false : true}
						/>
					}
						<RoleTags roles={item.roles || []} rolesToNotShow={[roles.user]} />		
				</View>
				<View style={{ flexGrow: 1 }}>
					<TouchableOpacity onPress={goToProfile}>
						<Text style={styles.userProfileCta}>
							{strings.users.goToProfile} <FontAwesomeIcon style={styles.nextIcon} size={12} icon={faAngleRight}></FontAwesomeIcon>
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		) : (
			<View style={styles.userRow} key={`users${index}`}>
				<Pressable style={styles.userItemNameMobile} onPress={goToProfile}>
					<ProfileImage profilePicture={item.profilePicture} firstName={item.firstName} lastName={item.lastName} />
					<Text style={styles.userName}>{capitalizeName(item)}</Text>
				</Pressable>
			</View>
		);
	};

	const TableHeader = () => {
		return (
			<View style={[styles.userRow, styles.userRowHead]}>
				<View style={{ width: isWeb ? 300 : "100%" }}>
					<Pressable
						style={styles.pressableHeader}
						onPress={() =>
							setFilters({
								...filters,
								page: 1,
								sorting: filters.sorting === "asc" ? "desc" : "asc",
							})
						}
					>
						<Text style={[styles.userNameHead]}>{strings.users.filters.name}</Text>
						<FontAwesomeIcon style={styles.sortIcon} size={8} icon={filters.sorting === "asc" ? faAngleDown : faAngleUp}></FontAwesomeIcon>
					</Pressable>
				</View>
				{isWeb ? <View style={{ flexGrow: 1 }}></View> : null}
				{isWeb ? <View style={{ flexGrow: 2 }}></View> : null}
			</View>
		);
	}

	return (
		<SafeAreaView style={styles.safearea}>
			<Header showMenu={true} width={width} navigation={props.navigation} onPress={clearFields} />

			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={error.title}
				description={error.description}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={error.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setError({ isVisible: isVisible, title: error.title, description: error.description });
					if (shouldLogout) {
						AsyncStorage.clear();
						props.navigation?.navigate(PageNames.home);
					}
				}}
			/>

			<ScrollView style={isWeb ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				{isWeb ? (
					<PageNavigation navigationEvent={clearFields}/>
				) : null}

				<View style={[styles.whiteContainer, isWeb ? styles.rowView : null, isWeb ? styles.webContainer : styles.mobileContainer]}>
					<View style={[styles.usersMobile, isWeb ? styles.usersWeb : null]}>
						<Text style={styles.title}>{strings.users.title}</Text>

						<View style={[styles.actions, isWeb ? styles.actionsWeb : null]}>
							<ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
								<FilterButton
									text={strings.users.filters.all}
									selected={filters.userFilter === "all"}
									onChange={() => filters.userFilter !== "all" && setUserFilter("all")}
								/>
								{	isUser ?
									<>
										<FilterButton
											text={strings.users.filters.following}
											selected={filters.userFilter === "following"}
											onChange={() => filters.userFilter !== "following" && setUserFilter("following")}
										/>
										<FilterButton
											text={strings.users.filters.followers}
											selected={filters.userFilter === "followers"}
											onChange={() => filters.userFilter !== "followers" && setUserFilter("followers")}
										/>
									</>
									: null
								}								
							</ScrollView>
							<View>
								<Searchbar
									placeholder={strings.users.filters.search}
									search={(val) =>
										setFilters({
											...DEFAULT_FILTERS,
											userFilter: filters.userFilter,
											searchTerm: val,
										})
									}
								/>
							</View>
						</View>

						<View style={styles.userTable}>
							{users.items.length > 0 ? (
								<View style={{ width: "100%" }}>
									{TableHeader()}
									{
										users.items.map((item: Profile, index: number) => (
											renderTableItem({ item: item, index: index })
										))
									}
								</View>
							) : (
								<></>
							)}
						</View>

						<View style={{ width: "100%" }}>
							<View style={styles.pagination}>
								{isWeb ? (
									<Pagination
										pages={Math.ceil(users.totalCounts / USER_PER_PAGE) || 1}
										currentPage={filters.page}
										handlePageSelect={(newPage: number) =>
											setFilters({
												...filters,
												page: newPage,
											})
										}
									/>
								) : Math.ceil(users.totalCounts / USER_PER_PAGE) > filters.page ? (
									<Button
										text={strings.users.loadMore}
										handleButtonPress={() =>
											setFilters({
												...filters,
												page: filters.page + 1,
											})
										}
										filledButton={true}
									/>
								) : null}
							</View>
						</View>
					</View>
				</View>					
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default UsersScreen;
