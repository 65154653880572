import { SafeAreaProvider } from "react-native-safe-area-context";

import Menu from "./navigation/Menu";

import {
	useFonts,
	Raleway_100Thin as RalewayThin,
	Raleway_200ExtraLight as RalewayExtraLight,
	Raleway_300Light as RalewayLight,
	Raleway_400Regular as RalewayRegular,
	Raleway_500Medium as RalewayMedium,
	Raleway_600SemiBold as RalewaySemiBold,
	Raleway_700Bold as RalewayBold,
	Raleway_800ExtraBold as RalewayExtraBold,
	Raleway_900Black as RalewayBlack,
	Raleway_100Thin_Italic as RalewayThinItalic,
	Raleway_200ExtraLight_Italic as RalewayExtraLightItalic,
	Raleway_300Light_Italic as RalewayLightItalic,
	Raleway_400Regular_Italic as RalewayItalic,
	Raleway_500Medium_Italic as RalewayMediumItalic,
	Raleway_600SemiBold_Italic as RalewaySemiBoldItalic,
	Raleway_700Bold_Italic as RalewayBoldItalic,
	Raleway_800ExtraBold_Italic as RalewayExtraBoldItalic,
	Raleway_900Black_Italic as RalewayBlackItalic,
} from "@expo-google-fonts/raleway";
import { FirebaseMessaging } from "./services/FirebaseMessaging";

export default function App() {
	let [fontsLoaded] = useFonts({
		RalewayThin,
		RalewayExtraLight,
		RalewayLight,
		RalewayRegular,
		RalewayMedium,
		RalewaySemiBold,
		RalewayBold,
		RalewayExtraBold,
		RalewayBlack,
		RalewayThinItalic,
		RalewayExtraLightItalic,
		RalewayLightItalic,
		RalewayItalic,
		RalewayMediumItalic,
		RalewaySemiBoldItalic,
		RalewayBoldItalic,
		RalewayExtraBoldItalic,
		RalewayBlackItalic,
	});

	new FirebaseMessaging();

	return (
		<SafeAreaProvider>
			<Menu />
		</SafeAreaProvider>
	);
}
