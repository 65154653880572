import React from "react";
import CalendarIcon from "../components/Icons/CalendarIcon";
import CameraIcon from "../components/Icons/CameraIcon";
import DocumentIcon from "../components/Icons/Document";
import EmojiIcon from "../components/Icons/EmojiIcon";
import GifIcon from "../components/Icons/GifIcon";
import ImageIcon from "../components/Icons/ImageIcon";
import UploadIcon from "../components/Icons/UploadIcon";
import VideochatIcon from "../components/Icons/VideochatIcon";
import { strings } from "../locales/strings";
import { MobileChatIcons, WebChatIcons } from "../models/models";
import { colors } from "../resources/colors";

export enum ChatStatus { active, meeting, away };

export enum ChatIcon { calendar, emoticons, gif, upload, videochat, photo, camera, document }

export const webChatIcons: WebChatIcons[] = [
    /* {
        icon: ChatIcon.calendar,
    },
    {
        icon: ChatIcon.emoticons,
    },
    {
        icon: ChatIcon.gif,
    }, */
    {
        icon: ChatIcon.upload,
    },
    {
        icon: ChatIcon.videochat,
    },
]


export const mobileChatIcons: MobileChatIcons[] = [
    {
        icon: ChatIcon.photo,
        name: strings.chatIcons.photos,
    },
    {
        icon: ChatIcon.camera,
        name: strings.chatIcons.camera,
    },
    /* {
        icon: ChatIcon.gif,
        name: strings.chatIcons.gif,
    }, */
    {
        icon: ChatIcon.videochat,
        name: strings.chatIcons.videochat,
    },
    /* {
        icon: ChatIcon.calendar,
        name: strings.chatIcons.calendar,
    }, */
    {
        icon: ChatIcon.document,
        name: strings.chatIcons.document,
    },
]

export class ChatUtils {
    static getChatStatusColor(status: ChatStatus): string {
        switch (status) {
            case ChatStatus.active:
                return colors.green;
            case ChatStatus.meeting:
                return colors.orange;
            case ChatStatus.away:
                return colors.grey;
        }
    }

    static getChatStatusLabel(status: ChatStatus): string {
        switch (status) {
            case ChatStatus.active:
                return strings.chatStatus.active;
            case ChatStatus.meeting:
                return strings.chatStatus.inAMeeting;
            case ChatStatus.away:
                return strings.chatStatus.away;
        }
    }

    static getChatIcon(icon: ChatIcon): React.ReactElement | undefined {
        switch (icon) {
            case ChatIcon.calendar:
                return CalendarIcon({ size: 24 });
            case ChatIcon.emoticons:
                return EmojiIcon({ size: 24 });
            case ChatIcon.gif:
                return GifIcon({ size: 24 });
            case ChatIcon.upload:
                return UploadIcon({ size: 24 });
            case ChatIcon.videochat:
                return VideochatIcon({ size: 24 });
            case ChatIcon.camera:
                return CameraIcon({ size: 24 })
            case ChatIcon.photo:
                return ImageIcon({ size: 24 })
            case ChatIcon.document:
                return DocumentIcon({ size: 24 })
        }
    }

}