import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faCircleXmark, faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Text, StyleSheet, View, Modal, Pressable } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { AlertTypes } from "../../utils/AlertTypes";

interface props {
	title: string;
	alertType: AlertTypes;
	alertVisible: boolean;
	setAlertVisible: (isVisible: boolean) => any;
}

const BasicToast = (props: props) => {
	const getIconColor = (): string => {
		let color = colors.black;
		switch (props.alertType) {
			case AlertTypes.success:
				color = colors.green;
				break;
			case AlertTypes.error:
				color = colors.red3;
				break;
			case AlertTypes.info:
				color = colors.blue3;
				break;
			case AlertTypes.warning:
				color = colors.yellow;
				break;
		}
		return color;
	};

	const getIcon = (): IconProp => {
		let icon: IconProp = faCheckCircle;
		switch (props.alertType) {
			case AlertTypes.success:
				icon = faCheckCircle;
				break;
			case AlertTypes.error:
				icon = faCircleXmark;
				break;
			case AlertTypes.info:
				icon = faInfoCircle;
				break;
			case AlertTypes.warning:
				icon = faWarning;
				break;
		}
		return icon;
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.alertVisible} onRequestClose={() => props.setAlertVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setAlertVisible(false)} />
			<View style={styles.container}>
				<View style={[styles.content, { backgroundColor: getIconColor() }]}>
					<FontAwesomeIcon size={20} color={colors.white} icon={getIcon()}></FontAwesomeIcon>
					<Text style={styles.text}>{props.title}</Text>
					<Pressable onPress={() => props.setAlertVisible(false)}>
						<Text style={styles.close}>{strings.close}</Text>
					</Pressable>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "flex-start",
		alignItems: "flex-end",
		flex: 1,
		margin: 50,
	},
	content: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 15,
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	close: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.white,
		textTransform: "uppercase",
		marginLeft: 10,
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.white,
		marginLeft: 10,
	},
});

export default BasicToast;
