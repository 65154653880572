import React from "react";
import { Text, View, Image } from "react-native";
import { strings } from "../../locales/strings";
import { styles } from "../../styles/CreateEventScreen";
import Button from "../Button";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { colors } from "../../resources/colors";

interface PropsInterface {
	image: string;
	addImage: () => void;
	removeImage: () => void;
	isReadOnly: boolean;
	isWeb: boolean;
}

const CoverImage = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	return (
		<>
			<View
				style={[
					styles.coverImageContainer,
					{ height: 150, marginTop: 20, paddingTop: props.image === "" ? 50 : 0, paddingBottom: props.image === "" ? 50 : 0 },
				]}
			>
				
				{!props.image || props.image === "" ? (
					<>
						<FontAwesomeIcon size={30} icon={faImage}></FontAwesomeIcon>
						<Text style={[styles.fieldDescription, { margin: 10 }]}>{strings.coverImage}</Text>
						{props.isReadOnly ? null : (
							<Button style={{ backgroundColor: colors.grey3 }} handleButtonPress={() => props.addImage()} text={strings.choose2} filledButton={false} />
						)}
					</>
				) : (
					<Image style={{ height: props.isWeb ? 310 : 180, width: "100%", borderRadius: 10 }} source={{ uri: props.image }}></Image>
				)}
			</View>
			{props.image && props.image !== "" && !props.isReadOnly ? (
				<View style={{ flexDirection: "row", justifyContent: "center", marginTop: 10 }}>
					<Button handleButtonPress={() => props.removeImage()} text={strings.deleteImage} style={{ marginRight: 5 }} filledButton={false} />
					<Button handleButtonPress={() => props.addImage()} text={strings.changeImage} style={{ marginLeft: 5 }} filledButton={true} />
				</View>
			) : null}
		</>
	);
};

export default CoverImage;
