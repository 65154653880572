import { Pressable, View, StyleSheet, Text, Linking, Image } from "react-native";
import { colors } from "../resources/colors";
import { strings } from "../locales/strings";
import { fonts } from "../resources/fonts";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { PageNames } from "../navigation/NavigationUtils";
import Banner from "./Banner";
import { urls } from "../resources/urls";
import { images } from "../resources/images";


interface FooterProps {
    navigation: any;
    titleStyle?: any;
    width: number;
    isWeb?: boolean;
    showFAQ?: boolean;
}

const Footer = (props: FooterProps) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isWeb = width >= 768;
    const [showFAQ, setShowFAQ] = useState(false);
    useEffect(() => {
        getProfile();
    }, [props]);

    const getProfile = async () => {
        const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
        if (id && id != '') {
            setShowFAQ(true);
        } else {
            setShowFAQ(false);
        }
    };

    return (
        <View style={{position:"relative",zIndex:-1}}>
            <View style={props.isWeb ? [{ flexDirection: 'row', marginHorizontal: '5%', justifyContent: 'space-between' }] : null}>
                <Banner navigation={props.navigation} title={strings.needHelp} descriptions={strings.needHelpDescription} buttonText={strings.askAnOperator}
                    onClick={() => {
                        props.navigation.navigate(PageNames.support);
                    }} />
                <Banner navigation={props.navigation} title={strings.anzituttoPlace} descriptions={strings.placeDescription} buttonText={strings.showPlaces} 
                    onClick={() => {
                        props.navigation.navigate(PageNames.places);
                    }} />
            </View>
            <View style={props.isWeb ? [{ flexDirection: 'row', marginHorizontal: '5%', justifyContent: 'space-between' }] : null}>
                <Banner navigation={props.navigation} title={strings.braccialetti} descriptions={strings.braccialettiDescription} buttonText={strings.shopBraccialetti}
                    onClick={() => {
                        isWeb ? window.open(urls.braccialettiLink, '_blank') :
                            Linking.openURL(urls.braccialettiLink);
                    }} />
                <Banner navigation={props.navigation} title={strings.channelYouTube} descriptions={strings.youTubeDescription} buttonText={strings.linkYouTube}
                    onClick={() => {
                        isWeb ? window.open(urls.channelYouTube, '_blank') :
                            Linking.openURL(urls.channelYouTube);
                    }} />
            </View>
            {props.isWeb ? (
                <View style={[styles.logoContainer]}>
                    <Image style={styles.img}
                        source={images.sponsor}></Image>
                </View>
            ) : (null)}



            <View style={props.isWeb ? styles.footer : styles.mobileFooter}>

                {
                    props.isWeb ?
                        <Text style={styles.footerText}>
                            {strings.footer.copyright}
                        </Text>
                        : null
                }
                <Pressable style={props.isWeb ? styles.linkFooter : null}
                    onPress={() => {
                        props.navigation.navigate(PageNames.support);
                    }}
                >
                    <View>
                        <Text style={[styles.linkColor, styles.footerText]}>
                            {strings.footer.support}
                        </Text>
                    </View>
                </Pressable>
                <Pressable style={props.isWeb ? styles.linkFooter : null}
                    onPress={() => {
                        props.navigation.navigate(PageNames.privacyPolicy);
                    }}
                >
                    <View>
                        <Text style={[styles.linkColor, styles.footerText]}>
                            {strings.footer.privacy}
                        </Text>
                    </View>
                </Pressable>
                {
                    /* TODO make this visible once we have the real text in page
                      use this condition -> (props.showFAQ ? props.showFAQ : showFAQ)
                    */
                    (false) ?
                        <Pressable style={props.isWeb ? styles.linkFooter : null}
                            onPress={() => {
                                props.navigation.navigate(PageNames.faq);
                            }}
                        >
                            <View>
                                <Text style={[styles.linkColor, styles.footerText]}>
                                    {strings.footer.faq}
                                </Text>
                            </View>
                        </Pressable>
                        : null
                }
                {
                    props.isWeb == false ?
                        <Text style={[styles.marginFooter, styles.footerText]}>
                            {strings.footer.copyright}
                        </Text>
                        : null
                }
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    footer: {
        height: 80,
        marginLeft: "5%",
        marginRight: "5%",
        marginBottom: 20,
        backgroundColor: colors.primary,
        fontFamily: fonts.RalewayRegular,
        display: "flex",
        flexDirection: "row",
        zIndex: 0
    },
    mobileFooter: {
        marginLeft: "5%",
        marginRight: "5%",
        fontFamily: fonts.RalewayRegular,
    },
    footerText: {
        fontFamily: fonts.RalewayRegular,
        fontSize: 14,
    },
    linkFooter: {
        marginLeft: "2%",
    },
    linkColor: {
        color: colors.blue3,
        marginBottom: 5,
    },
    marginFooter: {
        marginBottom: 20
    },
    logoContainer:{
        height: 100,
        marginBottom: 20,
        marginLeft: "5%",
        marginRight: "5%",
        zIndex: 0
    },
    img: {
        width: '100%',
        height: '100%',
        resizeMode:'contain'
    },
});

export default Footer;