import { Platform, Text } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import PlaceManager from "../components/Places/PlaceManager";
import { styles } from "../styles/Places.style";
import { strings } from "../locales/strings";
import { CommonActions } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { roles } from "../utils/Roles";
import { PageNames } from "../navigation/NavigationUtils";

interface PropsInterface {
	navigation?: any;
	route: {
		params: {
			update: boolean;
			placeId: string;
			helper: number;
			role: roles;
		};
	};
}

const PlaceManagerScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		scrollRefScreen != null ? scrollToTop() : null;
		checkRoles();
	}, [props]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const clearFields = () => {};

	const [ableToDelete, setAbleToDelete] = useState(false);
	const checkRoles = async () => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setAbleToDelete(userRole === roles.serviceCoordinator);
	};

	return (
		<SafeAreaView style={styles.safearea}>
			<Header width={width} navigation={props.navigation} onPress={() => clearFields()} showMenu={true} />

			<ScrollView contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
				<Text style={[styles.title, { marginBottom: 50 }]}>{props.route.params.update ? strings.updatePlace : strings.addPlace}</Text>

				<PlaceManager
					helper={props.route.params.helper}
					ableToDelete={ableToDelete}
					update={props.route.params.update}
					placeId={props.route.params.placeId}
					setPlaceManagerVisible={(isVisible: boolean) => {
						if (!isVisible) {
							props.navigation?.dispatch(
								CommonActions.reset({
									index: 1,
									routes: [{ name: PageNames.placeManager }],
								})
							);
							props.navigation.goBack();
						}
					} }
					delete={() => { } } 
					role={props.route.params.role}				
				/>
			</ScrollView>
		</SafeAreaView>
	);
};

export default PlaceManagerScreen;
