import React, { useEffect, useState } from "react";
import { Platform, Pressable, Text, TextInput, View, StyleSheet } from "react-native";
import { strings } from "../../locales/strings";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { colors } from "../../resources/colors";
import TimePicker from "./TimePicker";
import { TimePickerModal } from "react-native-paper-dates";
import { fonts } from "../../resources/fonts";
import { RangeHours } from "../../utils/WorkingHours";

interface PropsInterface {
	rangeHours: RangeHours;
	onHoursChange: (rangeHours: RangeHours) => void;
	isReadOnly: boolean;
	hideLabels?: boolean;
	isWeb: boolean;
}

const TimePickerComponent = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	const [rangeHours, setRangeHours] = useState(props.rangeHours);
	const [showStartHourPicker, setShowStartHourPicker] = useState(false);
	const [showEndHourPicker, setShowEndHourPicker] = useState(false);
	const [openStartHourPicker, setOpenStartHourPicker] = useState(false);
	const [openEndHourPicker, setOpenEndHourPicker] = useState(false);

	useEffect(() => {
		props.onHoursChange(rangeHours);
	}, [rangeHours]);

	return (		
		<View>
			<View style={{ flexDirection: "row" }}>
				{/*  Start hour */}
				<View>
					{props.hideLabels ? null : <Text style={[styles.fieldTitle, { marginBottom: 10 }]}>{strings.startHour}</Text>}
					<View style={[{ flexDirection: "row" }, styles.timePickerWeb, props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {}]}>
						{props.isReadOnly ? (
							<Text style={styles.timeInput}>{props.rangeHours.start.hour}</Text>
						) : (
							<TextInput
								style={[styles.timeInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
								keyboardType={"numeric"}
								value={props.rangeHours.start.hour}
								maxLength={2}
								placeholder="__"
								onChangeText={(hour) => {
									if (
										(parseInt(props.rangeHours.start.hour) === 2 && parseInt(hour) < 24) ||
										props.rangeHours.start.hour === "" ||
										hour.length === 1 ||
										hour.length === 0 ||
										parseInt(props.rangeHours.start.hour) === 1 ||
										parseInt(props.rangeHours.start.hour) === 0
									) {
										rangeHours.start.hour = hour.replace(/[^0-9]/g, "");
										setRangeHours({ ...rangeHours });
									}
								}}
							/>
						)}
						<Text style={styles.timeInputSlash}>{":"}</Text>
						{props.isReadOnly ? (
							<Text style={styles.timeInput}>{props.rangeHours.start.min}</Text>
						) : (
							<TextInput
								style={[styles.timeInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
								keyboardType={"numeric"}
								value={props.rangeHours.start.min}
								maxLength={2}
								placeholder="__"
								onChangeText={(min) => {
									if (
										props.rangeHours.start.min === "" ||
										min.length === 1 ||
										min.length === 0 ||
										(parseInt(props.rangeHours.start.min) === 5 && parseInt(min) < 60) ||
										parseInt(props.rangeHours.start.min) === 4 ||
										parseInt(props.rangeHours.start.min) === 3 ||
										parseInt(props.rangeHours.start.min) === 2 ||
										parseInt(props.rangeHours.start.min) === 1 ||
										parseInt(props.rangeHours.start.min) === 0
									) {
										rangeHours.start.min = min.replace(/[^0-9]/g, "");
										setRangeHours({ ...rangeHours });
									}
								}}
							/>
						)}
						<Pressable onPress={() => {
								if (!props.isReadOnly) {
									Platform.OS === "web" ? setShowStartHourPicker(!showStartHourPicker) : setOpenStartHourPicker(true);
								}
							}}>
							<FontAwesomeIcon style={styles.clockIcon} size={18} icon={faClock}/>
						</Pressable>
					</View>
				</View>

				{/*  End hour */}
				<View>
					{props.hideLabels ? null : <Text style={[styles.fieldTitle, { marginLeft: 15, marginBottom: 10 }]}>{strings.duration}</Text>}
					<View
						style={[
							{ flexDirection: "row", marginLeft: 15 },
							styles.timePickerWeb,
							props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
						]}
					>
						{props.isReadOnly ? (
							<Text style={styles.timeInput}>{props.rangeHours.end.min}</Text>
						) : (
							<TextInput
								style={[styles.timeInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
								keyboardType={"numeric"}
								value={props.rangeHours.end.hour}
								maxLength={2}
								placeholder="__"
								onChangeText={(hour) => {
									if (
										(parseInt(props.rangeHours.end.hour) === 2 && parseInt(hour) < 24) ||
										props.rangeHours.end.hour === "" ||
										hour.length === 1 ||
										hour.length === 0 ||
										parseInt(props.rangeHours.end.hour) === 1 ||
										parseInt(props.rangeHours.end.hour) === 0
									) {
										rangeHours.end.hour = hour.replace(/[^0-9]/g, "");
										setRangeHours({ ...rangeHours });
									}
								}}
							/>
						)}
						<Text style={styles.timeInputSlash}>{":"}</Text>
						{props.isReadOnly ? (
							<Text style={styles.timeInput}>{props.rangeHours.end.min}</Text>
						) : (
							<TextInput
								style={[styles.timeInput, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
								keyboardType={"numeric"}
								maxLength={2}
								value={props.rangeHours.end.min}
								placeholder="__"
								onChangeText={(min) => {
									if (
										props.rangeHours.end.min === "" ||
										min.length === 1 ||
										min.length === 0 ||
										(parseInt(props.rangeHours.end.min) === 5 && parseInt(min) < 60) ||
										parseInt(props.rangeHours.end.min) === 4 ||
										parseInt(props.rangeHours.end.min) === 3 ||
										parseInt(props.rangeHours.end.min) === 2 ||
										parseInt(props.rangeHours.end.min) === 1 ||
										parseInt(props.rangeHours.end.min) === 0
									) {
										rangeHours.end.min = min.replace(/[^0-9]/g, "");
										setRangeHours({ ...rangeHours });
									}
								}}
							/>
						)}
						<Pressable onPress={() => {
								if (!props.isReadOnly) {
									Platform.OS === "web" ? setShowEndHourPicker(!showEndHourPicker) : setOpenEndHourPicker(true);
								}
							}}>
							<FontAwesomeIcon style={styles.clockIcon} size={18} icon={faClock}></FontAwesomeIcon>
						</Pressable>
					</View>
				</View>
			</View>
			{/* start hour - web picker */}
			{showStartHourPicker ? (
				<TimePicker
					isStartHour={true}
					startHour={props.rangeHours.start}
					setStartHour={(value: any) => {
						props.rangeHours.start = value;
						setRangeHours({ ...props.rangeHours });
					}}
					endHour={props.rangeHours.end}
					setEndHour={(value: any) => {
						props.rangeHours.end = value;
						setRangeHours({ ...props.rangeHours });
					}}
					isWeb={props.isWeb}
				/>
			) : null}

			{/* duration - mobile picker */}
			{showEndHourPicker ? (
				<TimePicker
					isStartHour={false}
					startHour={props.rangeHours.start}
					setStartHour={(value: any) => {
						props.rangeHours.start = value;
						setRangeHours({ ...props.rangeHours });
					}}
					endHour={props.rangeHours.end}
					setEndHour={(value: any) => {
						props.rangeHours.end = value;
						setRangeHours({ ...props.rangeHours });
					}}
					isWeb={props.isWeb}
				/>
			) : null}

			{/* start hour - mobile picker */}
			<TimePickerModal
				visible={openStartHourPicker}
				onDismiss={() => setOpenStartHourPicker(false)}
				onConfirm={(value) => {
					props.rangeHours.start.hour = value.hours.toString();
					props.rangeHours.start.min = value.minutes.toString();
					setRangeHours({ ...props.rangeHours });
					setOpenStartHourPicker(false);
				}}
				hours={Number(props.rangeHours.start.hour)}
				minutes={Number(props.rangeHours.start.min)}
				label={strings.selectStartHour}
				uppercase={false}
				cancelLabel={strings.cancel}
				confirmLabel={strings.ok}
				animationType="fade"
				locale="it"
			/>

			{/* end hour - mobile picker */}
			<TimePickerModal
				visible={openEndHourPicker}
				onDismiss={() => setOpenEndHourPicker(false)}
				onConfirm={(value) => {
					props.rangeHours.end.hour = value.hours.toString();
					props.rangeHours.end.min = value.minutes.toString();
					setRangeHours({ ...props.rangeHours });
					setOpenEndHourPicker(false);
				}}
				hours={Number(props.rangeHours.end.hour)}
				minutes={Number(props.rangeHours.end.min)}
				label={strings.selectEndHour}
				uppercase={false}
				cancelLabel={strings.cancel}
				confirmLabel={strings.ok}
				animationType="fade"
				locale="it"
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	fieldTitle: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		marginTop: 10,
	},
	timePickerWeb: {
		borderWidth: 1,
		borderColor: colors.grey,
		borderRadius: 10,
	},
	timeInput: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		width: 40,
		padding: 10,
	},
	timeInputSlash: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		marginLeft: 5,
		marginRight: 5,
		paddingTop: 10,
	},
	clockIcon: {
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		marginTop: Platform.OS === "web" ? 0 : 10,
		marginRight: Platform.OS === "web" ? 0 : 10,
	},
	hourPickerContainer: {
		borderWidth: 1,
		borderColor: colors.grey,
		marginTop: 10,
		borderRadius: 10,
		padding: 10,
	},
	hourPickerText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 16,
		textAlign: "center",
	},
});

export default TimePickerComponent;
