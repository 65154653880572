import { useState } from "react";
import { Text, StyleSheet, View, Platform, Pressable, Image } from "react-native";
import { strings } from "../../locales/strings";
import { FileInfo, Message, MessageMeet } from "../../models/chatModels";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import { FormatDate } from "../../utils/FormatDateUtils";
import Button from "../Button";
import DeleteIcon from "../Icons/DeleteIcon";
import EditIcon from "../Icons/EditIcon";
import ProfileImage from "../ProfileImage";
import { useIsFocused } from "@react-navigation/native";
import React from "react";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";
import { FileUtils } from "../../utils/FileUtils";
import { gif } from "../../resources/gif";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

interface ModalProps {
	message: Message | undefined;
	onDelete: () => void;
	onEdit: () => void;
	isOnHover: boolean;
	ableToManageMessage: boolean;
	onFilePressed: (file: FileInfo) => void;
	onMeetPressed: (meet: MessageMeet) => void;
}

const MessageComponent = ({ message, onDelete, onEdit, isOnHover, ableToManageMessage, onFilePressed, onMeetPressed }: ModalProps) => {
	const [onHover, setOnHover] = useState(false);

	const focused = useIsFocused();

	React.useEffect(() => {
		console.log(message);
	}, [focused]);

	return (
		<View style={styles.container}>
			<View style={{ flexDirection: "row" }}>
				<ProfileImage
					firstName={message?.firstName ?? ""}
					lastName={message?.lastName ?? ""}
					profilePicture={message?.profileImage}
					key={"chat-message-image"}
					size={25}
				/>
				<View style={{ flexDirection: "row", paddingHorizontal: 5 }}>
					<Text style={styles.senderName}>{message?.firstName + " " + message?.lastName}</Text>
					<Text style={styles.timestamp}>{FormatDate.formatMessagesDate(message?.createdOn ?? "")}</Text>
					{message?.isEdited && <Text style={styles.timestamp}>{" " + strings.edited}</Text>}
				</View>
			</View>
			{message?.isDeleted ? (
				<Text style={styles.deletedMessage}>{strings.messageDeleted}</Text>
			) : (
				<View
					style={[
						{ zIndex: 0, flexDirection: "row", justifyContent: "space-between", marginLeft: 20 },
						isOnHover || onHover ? { backgroundColor: colors.grey3 } : null,
					]}
				>
					<View>
						{!!message?.meet && (
							<View style={styles.meetButton}>
								<Button text={strings.openMeet} handleButtonPress={() => onMeetPressed(message.meet)} filledButton={false}></Button>
							</View>
						)}
						{!!message?.attachmentId &&
							message.file !== undefined &&
							(message.file === null ? (
								<View style={styles.fileAlert}>
									<Text style={styles.fileAlertTitle}>{strings.attachmentUnavailable.title}</Text>
									<Text>{strings.attachmentUnavailable.description}</Text>
								</View>
							) : (
								<Pressable style={styles.fileContainer} onPress={() => !!message && !!message.file && onFilePressed(message.file)}>
									{message.file.type.includes("image") ? (
										<>
											{Platform.OS === "web" ? (
												<Image style={styles.file} source={{ uri: message.file?.data }} />
											) : (
												<Image style={styles.file} source={{ uri: message.file?.data }} />
											)}
										</>
									) : (
										<View style={[{ display: "flex", flexDirection: "row", alignItems: "center" }]}>
											{Platform.OS === "web" ? (
												// Library not supported on mobile
												<FileIcon
													extension={FileUtils.getFileExtension(message.file.name)}
													{...defaultStyles[FileUtils.getFileExtension(message.file.name) as DefaultExtensionType]}
												/>
											) : (
												<FontAwesomeIcon size={30} icon={faFile}></FontAwesomeIcon>
											)}
											<Text style={{ marginLeft: 10 }}>{message.file.name}</Text>
										</View>
									)}
								</Pressable>
							))}
						{!!message?.attachmentId && message.file === undefined && <Image source={gif.loader} style={{ width: 40, height: 40, marginLeft: 50 }} />}
						{!!message?.content && <Text style={[styles.textMessage]}>{message?.content}</Text>}
					</View>
					{Platform.OS === "web" && ableToManageMessage && (
						<View style={{ flexDirection: "row", marginTop: 3 }}>
							<Pressable onHoverIn={() => setOnHover(true)} onHoverOut={() => setOnHover(false)} onPress={() => onEdit()}>
								{(isOnHover || onHover) && <EditIcon />}
							</Pressable>
							<Pressable onHoverIn={() => setOnHover(true)} onHoverOut={() => setOnHover(false)} onPress={() => onDelete()}>
								{(isOnHover || onHover) && <DeleteIcon />}
							</Pressable>
						</View>
					)}
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginVertical: 10,
		marginHorizontal: 20,
		zIndex: 0,
	},
	senderName: {
		fontFamily: fonts.RalewayBold,
		color: colors.black,
		fontSize: 14,
		marginBottom: 5,
	},
	textMessage: {
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
		fontSize: 14,
		marginBottom: 5,
		paddingHorizontal: 10,
		paddingVertical: 5,
	},
	meetButton: {
		paddingHorizontal: 10,
	},
	deletedMessage: {
		fontFamily: fonts.RalewayItalic,
		color: colors.grey,
		fontSize: 14,
		marginBottom: 5,
		paddingHorizontal: 10,
		paddingVertical: 5,
		marginLeft: 20,
	},
	timestamp: {
		fontFamily: fonts.RalewayRegular,
		color: colors.grey2,
		fontSize: 12,
		marginLeft: 10,
	},
	manageMessageContainer: {
		position: "absolute",
		top: 20,
		right: 50,
		zIndex: 999,
		paddingHorizontal: 15,
		borderRadius: 20,
		borderWidth: 1,
		borderColor: colors.grey,
		flexDirection: "row",
	},
	fileContainer: {
		height: 70,
		width: 150,
		borderRadius: 10,
		marginHorizontal: 10,
	},
	file: {
		height: "100%",
		width: "100%",
	},
	fileAlert: {
		border: "1px solid #D5D5D5",
		borderRadius: 20,
		paddingVertical: 5,
		paddingHorizontal: 15,
		maxWidth: 300,
	},
	fileAlertTitle: {
		fontWeight: "bold",
		marginBottom: 5,
	},
});

export default MessageComponent;
