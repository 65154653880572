import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet, Text, View, Image, Platform, Pressable } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	container: {
		backgroundColor: colors.white,
		width: "90%",
		marginHorizontal: "5%",
		marginBottom: "5%",
		borderRadius: 10,
		paddingVertical: "3%",
		paddingHorizontal: "5%"
	},
	title: {
		marginHorizontal: "5%",
		fontFamily: fonts.RalewayBold,
		color: colors.black,
		fontSize: 18,
		marginVertical: "3%" 
	}
});
