export const formatDate = (_date: string | Date) => {
	if (!_date) return "";

	const date = typeof _date === "string" ? new Date(_date) : _date;

	return date.toLocaleDateString("it-IT", {
		day: "numeric",
		month: "long",
	});
};
