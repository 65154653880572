import { useEffect, useState } from "react";
import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { EventActivity } from "../../../models/eventsModels";
import { colors } from "../../../resources/colors";
import { fonts } from "../../../resources/fonts";
import { strings } from "../../../locales/strings";
import { getActivityDate, getDefaultImageByScope } from "../../../utils/EventUtils";
import Tag from "../../Tag";
import { PlacesEndPoint } from "../../../services/PlacesEndPoint";
import { EventActivityStatus } from "../../../utils/EventStatus";
interface Props {
    activity: EventActivity;
    edit: () => void;
    cancel: () => void;
    changeStatus: () => void;
    canEdit?: boolean;


}

const ActivityCard = ({ activity, edit, cancel, canEdit, changeStatus }: Props) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isWeb = width >= 768;
    const startDate = getActivityDate(activity.startDate);
    const endDate = getActivityDate(activity.endDate);
    const [addressPlace, setAddressPlace] = useState("");

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        activity.place?._id ? getPlaceDetails(activity.place?._id) : null
    }, [activity])

    const getPlaceDetails = async (id: string) => {

        const res = await PlacesEndPoint.getPlaceDetails(id);
        setAddressPlace(res.item.address)

    }

    const getStatus = (status: string): string => {
        let color = "";
        switch (status) {
            case EventActivityStatus.APPROVED: {
                color = colors.white;
                break;
            }
            case EventActivityStatus.WAIT_EXTERNAL_OPERATOR: {
                color = colors.infoBanner;
                break;
            }
            default: {
                color = colors.white;
                break;
            }

        }
        return color;
    };
    return (
        <View style={[{ borderColor: getStatus(activity.status), borderWidth: 6 },
        styles.eventContainer, isWeb && styles.webEventContainer,
        { backgroundColor: colors.primary }]}>
            {activity.img ? (
                <Image source={{ uri: activity.img }} style={[styles.image, isWeb && styles.webImage]}></Image>
            ) : (
                <Image resizeMode="cover" style={[styles.image, isWeb && styles.webImage]} source={getDefaultImageByScope(activity.field)} ></Image>
            )}
            <View style={[styles.infoContainer, isWeb && styles.webInfoContainer]}>
                <View style={[styles.items, isWeb ? { width: "60%" } : null]}>
                    <Text numberOfLines={isWeb ? 1 : 0} style={[styles.eventName, isWeb ? {} : { marginVertical: 0, marginBottom: 5 }]}>
                        {activity.title}
                    </Text>
                    <View style={styles.rowViewDatePlace}>
                        {startDate && endDate ? (
                            <>
                                <View style={isWeb ? styles.eventDateTags : null}>
                                    {startDate.day === endDate.day ? (
                                        <Tag text={`${startDate.day} | ${strings.from} ${startDate.hour} ${strings.to} ${endDate.hour}`} color={colors.white} style={styles.eventDateTag} />
                                    ) : (
                                        <>
                                            <Tag text={`dal ${startDate.day} | ${strings.itHour} ${startDate.hour}`} color={colors.white} style={styles.eventDateTag} />
                                            <Tag text={`al ${endDate.day} | ${strings.itHour} ${endDate.hour}`} color={colors.white} style={styles.eventDateTag} />
                                        </>
                                    )}
                                </View>
                            </>
                        ) : (null)}
                    </View>
                    {activity.place && activity.place.name ? <Text style={[styles.eventPlace, styles.marginVertical]}>{activity.place.name} - {addressPlace}</Text> : null}
                    {activity.placeFreeText ? <Text style={[styles.eventPlace, styles.marginVertical]}>{activity.placeFreeText}</Text> : null}

                </View>
                {canEdit && activity.status === EventActivityStatus.WAIT_EXTERNAL_OPERATOR ?
                    <View style={[styles.items, isWeb && styles.webRightItem, !isWeb && styles.secondItemMobile]}>
                        <View style={styles.rowView}>
                            <Pressable
                                style={[styles.button, {
                                    backgroundColor: colors.infoBanner,
                                    borderWidth: 1,
                                    borderColor: colors.orange
                                }]}
                                onPress={changeStatus}>
                                <View style={styles.rowViewButton}>
                                    <Text style={[styles.textButton]}>{strings.approve}</Text>
                                </View>
                            </Pressable>
                        </View>
                    </View>
                    :
                    <>
                        {activity.status === EventActivityStatus.APPROVED ?
                            <View style={[styles.items, isWeb && styles.webRightItem, !isWeb && styles.secondItemMobile]}>
                                <View style={styles.rowView}>
                                    <Pressable
                                        style={[styles.button, { backgroundColor: colors.greenCard, borderColor: colors.green, borderWidth: 1, }]}
                                        disabled={true}
                                        onPress={() => { }}>
                                        <View style={styles.rowViewButton}>
                                            <Text style={[styles.textButton, { color: colors.green }]}>{strings.approved}</Text>
                                        </View>
                                    </Pressable>
                                </View>
                            </View>
                            :
                            <View style={[styles.items, isWeb && styles.webRightItem, !isWeb && styles.secondItemMobile]}>
                                <View style={styles.rowView}>
                                    <Pressable onPress={edit}>
                                        <Text style={[styles.label]}>{strings.modify}</Text>
                                    </Pressable>
                                    <View style={styles.verticalLine}></View>
                                    <Pressable onPress={cancel}>
                                        <Text style={[styles.label]}>{strings.cancelCard}</Text>
                                    </Pressable>
                                </View>
                            </View>
                        }
                    </>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    eventContainer: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderRadius: 10,
        backgroundColor: colors.white,
        marginTop: 20,
        marginBottom: 16,
    },

    webEventContainer: {
        paddingHorizontal: 10,
        marginLeft: 40,
        marginTop: 0,
        flexDirection: "row",
    },
    infoContainer: {
        width: "auto",
        flex: 1,
        flexDirection: "column",
        paddingVertical: Platform.OS === "web" ? 10 : 1,
        paddingHorizontal: 10,
    },

    webInfoContainer: {
        marginTop: 0,
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
    },

    items: {
        flexDirection: "column",
    },

    rowView: {
        marginTop: 30,
        flexDirection: "row",
        justifyContent: "space-between"

    },
    rowViewDatePlace: {
        flexDirection: "row",
    },
    webRightItem: {
        alignItems: "flex-end",
    },
    eventPlace: {
        color: colors.grey4,
        marginTop: Platform.OS === "web" ? undefined : 20,
        fontSize: 14,
    },

    eventType: {
        textTransform: "uppercase",
        color: colors.grey4,
        opacity: 0.5,
    },
    eventName: {
        color: colors.blue3,
        fontSize: Platform.OS === "web" ? 30 : 20,
        fontWeight: "bold",
        marginVertical: Platform.OS === "web" ? 8 : 4,
    },
    eventDateTags: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
    },

    eventDateTag: {
        marginRight: Platform.OS === "web" ? 8 : 0,
        marginTop: Platform.OS === "web" ? 0 : 4,

    },

    white: {
        color: colors.white,
    },
    marginVertical: {
        marginVertical: 8,
    },
    marginVertical16: {
        marginVertical: 16,
    },
    buttonContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    share: {
        marginTop: 16,
        fontSize: 14,
        textAlign: "center",
        color: colors.grey4,
    },
    shareIconsContainer: {
        marginBottom: 16,
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    shareIcon: {
        margin: 10,
        height: 30,
        width: 30,
    },
    attendees: {
        flexDirection: "row",
    },
    attendeesLabel: {
        color: colors.grey4,
        opacity: 0.5,
        marginHorizontal: 8,
    },
    image: {
        width: 100,
        height: 80,
        borderRadius: 10,
        transform: [{ translateY: -30 }],
        zIndex: 2,
    },
    webImage: {
        width: 130,
        height: 100,
        transform: [{ translateX: -40 }],
    },
    secondItemMobile: {
        marginTop: 5,
        position: 'absolute',
        top: -100,
        right: 0,

    },
    invitedText: {
        backgroundColor: colors.red2,
        color: colors.white,
        borderRadius: 10,
        padding: 5,
        paddingHorizontal: 10,
    },
    label: {
        color: colors.blue,
        fontFamily: fonts.RalewayRegular,
        fontSize: 14,
        textDecorationLine: "underline",
    },
    verticalLine: {
        borderColor: colors.grey,
        borderLeftWidth: 2,
        height: 26,
        marginHorizontal: 10
    },
    button: {
        alignSelf: "center",
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 25,
        paddingTop: 10,
        paddingBottom: 10,
        alignContent: "center",
        zIndex: 1,
    },

    textButton: {
        fontSize: 14,
        marginLeft: 6,
        fontFamily: fonts.RalewayBold,
        color: colors.orange,
    },
    rowViewButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
});

export default ActivityCard;