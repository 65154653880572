import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { useState } from "react";
import { Text, StyleSheet, View, Platform, Modal, Pressable } from "react-native";
import { strings } from "../locales/strings";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { EndPoints } from "../services/EndPoints";
import { rules } from "../utils/Rules";
import Button from "./Button";
import CustomTextInput from "./CustomTextInput";
import Loader from "./Loader";

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean, isError?: boolean, title?: string, message?: string) => any;
	isWeb: boolean;
}

const ResetPassword = (props: props) => {
	const [email, setEmail] = useState("");
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [unwrittenEmail, setUnwrittenEmail] = useState(true);

	const save = async () => {
		setLoaderVisible(true);
		EndPoints.markPasswordForReset(email).then((res: any) => {
			setLoaderVisible(false);
			clearFields();
			props.setModalVisible(false, false, '', strings.resetPasswordCompleted)
		}).catch((e: any) => {
			setLoaderVisible(false);
			clearFields();
			props.setModalVisible(false, false, e?.status, e?.error?.message)
		})
	};
	
	const validateEmail = (email: string) => {
		return rules.emailRegex.test(email) === true;
	};

	const clearFields = () => {
		setEmail("");
		setInvalidEmail(false);
		setUnwrittenEmail(true);
		props.setModalVisible(false);
	}

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible} onRequestClose={() => clearFields()}>
			
			<View style={styles.container}>
				<Pressable
					style={[styles.overlay, styles.backdrop]}
					onPress={() => clearFields()}
				/>
				<View style={[styles.content, { width: props.isWeb ? "30%" : "80%" }]}>

					<Pressable
						style={[styles.closeIcon]}
						onPress={() => clearFields()}
					>
						<Text style={styles.closeBtnText}>{strings.close}</Text>
						<FontAwesomeIcon style={{ marginTop: 2 }} color={colors.white} size={15} icon={faClose}></FontAwesomeIcon>
					</Pressable>
					
					<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

					<Text style={styles.title}>{strings.resetPassword}</Text>

					{/* current email */}
					<CustomTextInput
						value={email}
						placeholder={strings.email}
						title={strings.insertEmail}
						obligatory={false}
						secureTextEntry={false}
						styleTextInput={invalidEmail ? [{ borderColor: colors.red }] : null}
						onBlur={() => {
							setUnwrittenEmail(email.length === 0);
							validateEmail(email) || email.length == 0 ? setInvalidEmail(false) : setInvalidEmail(true);
						}}
						setValue={(value: string) => {
							setEmail(value);
							validateEmail(email) || email.length == 0 ? setInvalidEmail(false) : null;
						}}
					/>
					{invalidEmail ? <Text style={[styles.text, { color: colors.red }]}>{strings.emailNotValid}</Text> : null}

					<Button
						style={styles.button}
						handleButtonPress={() => save()}
						disabled={invalidEmail || unwrittenEmail}
						text={strings.send}
						filledButton={false}
					/>
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: "3%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "2%" : "5%",
		marginTop: Platform.OS === "web" ? 0 : "10%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: Platform.OS === "web" ? "1%" : "5%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	button: {
		marginTop: Platform.OS === "web" ? "2%" : "10%",
		marginBottom: Platform.OS === "web" ? 0 : "10%",
	},
	closeIcon: {
		position: "absolute",
		top: -24,
		right: 0,
		zIndex: 9999,
		flexDirection: "row",
		alignItems: "center"
	},
	closeBtnText: {
		color: colors.white,
		fontFamily: fonts.RalewaySemiBold,
		fontSize: 16,
		marginRight: 8,
	},
});

export default ResetPassword;
