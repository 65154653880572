import { Platform, Text, View, Image, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { fonts } from "../resources/fonts";
import { useEffect, useState } from "react";
import { AttachmentPicker } from "../utils/AttachmentPicker";
import { ActionSheetProvider, connectActionSheet, useActionSheet } from "@expo/react-native-action-sheet";
import { ImageInfo, ProfileImageRequest } from "../models/models";
import { ProfileImageMapper } from "../mappers/ProfileImageMapper";
import { strings } from "../locales/strings";
import Button from "./Button";
const attachmentPicker = new AttachmentPicker();

interface props {
	showActionSheetWithOptions: any;
	image: string;
	setImage: (value: string) => void;
	showError: (text: string) => void;
	disabled?: boolean;
}

const AddImage = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, []);

	const [imageLoaded, setImageLoaded] = useState(false);
	const [imageName, setImageName] = useState("");
	const [imageObject, setImageObject] = useState<ProfileImageRequest | null>(null);

	const addImage = async () => {
		const imageInfo = Platform.OS === "web" ? await attachmentPicker.pickImageFromWeb() : await openActionSheet();
		if (imageInfo) {
			if (Platform.OS === "web" && !(imageInfo as ImageInfo).uri.includes("image")) {
				props.showError(strings.errorPhotoUploaded);
			} else {
				if (attachmentPicker.isSmallSize((Platform.OS === 'web') ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].base64)) {
					props.setImage(Platform.OS === "web" ? (imageInfo as ImageInfo).uri : (imageInfo as ImageInfo[])[0].fileURI);
					setImageLoaded(true);
					setImageName(Platform.OS === "web" ? (imageInfo as ImageInfo).fileName : (imageInfo as ImageInfo[])[0].fileName);
					const image = Platform.OS === "web" ? (imageInfo as ImageInfo) : (imageInfo as ImageInfo[])[0];
					setImageObject(Platform.OS === "web" ? ProfileImageMapper.mapWeb(image) : ProfileImageMapper.mapMobile(image));
				} else {
					props.showError(strings.errorPhotoTooBig);
				}
			}
		}
	};

	const { showActionSheetWithOptions } = useActionSheet();
	const openActionSheet = () => {
		const options = [strings.uploadImage, strings.takePhoto, strings.cancel];
		const destructiveButtonIndex = undefined;
		const cancelButtonIndex = 2;
		return new Promise((resolve, reject) => {
			showActionSheetWithOptions({ options, cancelButtonIndex, destructiveButtonIndex }, (buttonIndex) => {
				switch (buttonIndex) {
					case 0: {
						attachmentPicker._pickFromGallery("photo", resolve, reject);
						break;
					}
					case 1: {
						attachmentPicker._takePhoto(resolve, reject);
						break;
					}
					case 2: {
						break;
					}
				}
			});
		});
	};

	const removeImage = async () => {
		props.setImage("");
		setImageLoaded(false);
		setImageObject(null);
	};

	return (
		<View style={[styles.container]}>
			<View style={[styles.inputContainer]}>
				<View style={styles.leftView}>
					{imageLoaded || props.image.length > 0 ? (
						<Image resizeMode="cover" style={styles.image} source={{ uri: props.image }}></Image>
					) : (
						<FontAwesomeIcon style={styles.icon} size={20} icon={faImage}></FontAwesomeIcon>
					)}
				</View>
				{isWeb && (imageLoaded || props.image.length > 0) ? <Text style={styles.text}>{imageName}</Text> : null}
				{	!!props.disabled ? 
					null 
					:				
					<View style={styles.rightView}>
						{imageLoaded || props.image.length > 0 ? (
							<Button handleButtonPress={() => removeImage()} text={strings.deleteImage} filledButton={false} />
						) : (
							<Button handleButtonPress={() => addImage()} text={strings.uploadImage} filledButton={true} />
						)}
					</View>
				}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		//marginTop: "1%",
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "60%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
	},
	leftView: {
		width: 80,
		height: 80,
		backgroundColor: colors.grey,
		borderRadius: 10,
	},
	inputContainer: {
		backgroundColor: colors.grey3,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		height: 80,
		marginBottom:27.5,
		width: "100%",
	},
	icon: {
		margin: 30,
	},
	image: {
		width: 80,
		height: 80,
		borderRadius: 10,
	},
	rightView: {
		position: "absolute",
		right: 20,
	},
});

const AddImageConnected = connectActionSheet(AddImage);

const AddImageConnectedContainer = (props: any) => {
	return (
		<ActionSheetProvider>
			<AddImageConnected
				showActionSheetWithOptions={props.showActionSheetWithOptions}
				image={props.image}
				setImage={(value: string) => props.setImage(value)} 
				showError={(text: string) => props.showError(text)}
				disabled={props.disabled}/>
		</ActionSheetProvider>
	);
};

export default AddImageConnectedContainer;
