import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
	},
	text: {
		fontSize: 16,
		fontFamily: fonts.RalewayRegular,
		marginTop: 10,
		marginBottom: 15,
	},
	titleMargin: {
		marginLeft: "5%",
	},
	description: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		marginTop: 20,
		marginRight: "5%",
		marginLeft: "5%",
		marginBottom: "2%",
	},
	rowView: {
		flexDirection: "row",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		marginHorizontal: 16,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		marginRight: "5%",
		paddingRight: "5%",
		marginLeft: "5%",
		paddingLeft: "5%",
		marginBottom: "5%"
	},
	webContainer: {
		marginLeft: "5%",
		marginRight: "5%",
	},
	usersWeb: {
		height: "100%",
		paddingRight: 30,
		paddingLeft: 30,
		marginHorizontal: 0,
	},
	usersMobile: {
		width: "100%",
		paddingTop: 30,
		paddingBottom: 30,
	},
	userTitleWeb: {
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	userTitleMobile: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	userTitleMargin: {
		marginVertical: 16,
		marginLeft: 16,
	},
	flexSelfStart: {
		alignSelf: "flex-start",
	},
	userInfoContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginVertical: 40,
	},
	userInfoContainerWeb: {
		marginVertical: 60,
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		paddingHorizontal: 8,
	},
	userInfoItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 16,
	},
	userInfoIcon: {
		color: colors.blue3,
	},
	userInfoItemType: {
		fontSize: 18,
		fontWeight: "bold",
		marginHorizontal: 8,
		color: colors.grey4,
	},
	userInfoItemVal: {
		fontSize: 16,
		color: colors.grey4,
	},

	userNotes: {
		backgroundColor: colors.grey3,
		display: "flex",
		flexDirection: "row",
		padding: 16,
		alignItems: "center",
		flexGrow: 1,
	},

	userNotesImage: {
		transform: [{ translateX: -106 }],
	},

	userNotesQuote: {
		minHeight: 180,
		width: "100%",
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 16,
		marginLeft: -90,
	},
	editFormContainer: {
		display: "flex",
		flexDirection: "column",
	},
	editFormContainerWeb: {
		flexDirection: "row",
	},
	editFormColumn: {
		flexGrow: 1,
		width: "50%",
	},
	editFormowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	asterisk: {
		fontFamily: fonts.RalewayBold,
		fontSize: 30,
		marginTop: -5,
		marginLeft: 5,
		color: colors.black,
	},
	titleContainer: {
		flexDirection: "row",
		marginTop: 20,
		marginHorizontal: 16,
	},
	textInputTitle: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
	},
	gender: {
		fontFamily: fonts.RalewayRegular,
		marginHorizontal: 16,
		marginBottom: 10,
		alignContent: "flex-start",
		alignItems: "flex-start",
	},
	profilePictureWebContainer: {
		marginHorizontal: 16,
		backgroundColor: colors.grey3,
	},
	loadPicture: {
		fontSize: 15,
		fontFamily: fonts.RalewayBold,
		marginLeft: 16,
	},
	loadPictureMTop: {
		marginTop: 16,
	},
	loadPictureCenter: {
		textAlign: "center",
	},
	profilePictureWeb: {
		width: "50%",
		justifyContent: "center",
		alignSelf: "center",
	},
	buttonProfilePictureWebContainer: {
		marginRight: 5,
	},
	buttonProfilePictureWeb: {
		alignSelf: "flex-end",
	},
	profilePictureMobile: {
		width: "50%",
		justifyContent: "center",
	},
	buttonProfilePictureMobile: {
		marginBottom: "3%",
	},
	alignCenter: {
		alignSelf: "center",
	},
	profilePicture: {
		margin: "5%",
		width: 140,
		height: 140,
		borderRadius: 100,
	},
	noteWeb: {
		height: 80,
		margin: "5%",
		paddingTop: 10,
	},
	mobileHeader: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
	},
	editWebHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-start",
	},
	editAction: {
		marginTop: 24,
		width: "auto",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginHorizontal: 16,
	},
	paginator: {
		marginTop: 0,
		marginBottom: 10,
	},
	icon: {
		width: "inherit",
		height: "inherit",
	},
	infoPadding: {
		paddingHorizontal: "5%",
	},
	marginLeft: {
		marginLeft: 8,
	},
	deleteButton: {
		backgroundColor: colors.red3,
		textDecorationColor: colors.white,
	},
});
