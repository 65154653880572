import { strings } from "../../locales/strings";

export const StatusType = [
	{
		type: "draft",
		label: strings.eventStatus.draft,
	},
	{
		type: "to_be_approved",
		label: strings.eventStatus.to_be_approved,
	},
	{
		type: "wait_internal_operator",
		label: strings.eventStatus.wait_internal_operator,
	},
	{
		type: "wait_external_operator",
		label: strings.eventStatus.wait_external_operator,
	},
	{
		type: "approved",
		label: strings.eventStatus.approved,
	},
];
