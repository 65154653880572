import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safearea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingBottom: "5%",
		paddingTop: "5%",
	},
	title: {
		fontSize: 24,
		fontFamily: fonts.RalewayBold,
		marginBottom: 12,
	},
	description: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		marginTop: 20,
		marginRight: "5%",
		marginLeft: "5%",
		marginBottom: "2%",
	},
	rowView: {
		flexDirection: "row",
	},
	whiteContainer: {
		backgroundColor: colors.white,
		borderRadius: 10,
		marginTop: 30,
		shadowColor: colors.blue2Shadow,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	webContainer: {
		marginLeft: "5%",
		marginRight: "5%",
		marginBottom: "5%",
	},
	mobileContainer: {
		marginBottom: "5%",
	},
	usersWeb: {
		height: "100%",
		paddingRight: 58,
		paddingLeft: 58,
	},
	usersMobile: {
		width: "100%",
		paddingTop: 30,
		paddingRight: 16,
		paddingBottom: 30,
		paddingLeft: 16,
	},

	actions: {
		display: "flex",
		flexDirection: "column",
	},

	actionsWeb: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},

	userName: {
		fontSize: 18,
		marginLeft: 16,
		color: colors.black,
	},

	userRow: {
		flexDirection: "row",
		paddingTop: 8,
		paddingBottom: 8,
		alignItems: "center",
		backgroundColor: colors.white,
	},

	userRowHead: {
		borderBottomWidth: 2,
		borderBottomColor: colors.grey4Border,
		marginBottom: 4,
	},

	userNameHead: {
		color: colors.grey4Border,
	},

	sortIcon: {
		color: colors.grey4Border,
	},

	pressableHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},

	userTable: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: 24,
		marginBottom: 24,
	},
	searchbarContainer: {
		backgroundColor: colors.white,
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderColor: colors.grey4,
		height: 50,
	},

	nextIcon: {
		marginLeft: 10,
		marginRight: 10,
		marginTop: 3,
		color: colors.blue3,
	},

	userProfileCta: {
		fontSize: 16,
		textAlign: "right",
		color: colors.blue3,
		textDecorationLine: "underline",
	},

	back: {
		marginLeft: 10,
		fontSize: 12,
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
	},
	webImageContainer: {
		width: "100%",
		height: 130,
	},
	mobileImageContainer: {
		width: "95%",
		marginLeft: 10,
		marginRight: 10,
		marginTop: 30,
		height: 130,
	},
	image: {
		height: "100%",
		width: "100%",
	},
	backContainer: {
		margin: 20,
	},

	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	userItemName: {
		width: 300,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	userItemNameMobile: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},
});
