import { useEffect, useRef, useState } from "react";
import { ScrollView, View, Text, Platform, AppState, AppStateStatus } from "react-native";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { styles } from "../styles/StaticFooterPages.style";
import RenderHtml from "react-native-render-html";
import * as faqHtml from "../locales/faq";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { roles } from "../utils/Roles";
import { useIsFocused } from "@react-navigation/native";
import { SafeAreaView } from "react-native-safe-area-context";
import { PageNames } from "../navigation/NavigationUtils";

interface PropsInterface {
	navigation: any;
}

const FaqScreen = (props: PropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const isPlatformWeb = Platform.OS === "web";
	const focused = useIsFocused();
	const [isLogged, setIsLogged] = useState(false);
	const [role, setRole ] = useState('');

	const [loaderVisible, setLoaderVisible] = useState(false);

    useEffect(() => {
        if (isPlatformWeb) {
            window.addEventListener("resize", () => {
                console.log('width ' + window.innerWidth )
                setWidth(window.innerWidth);
            });
        }
        checkIsLogged();
		checkRole();
    }, [focused]);

	const checkIsLogged = async () => {
        const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
        if (id && id != '') {
            setIsLogged(true);
        } else {
            setIsLogged(false);
        }
	};

	const checkRole = async () => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setRole(userRole ? userRole : roles.user);
	}
    
    return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				onPress={() => props.navigation.navigate(PageNames.user)}
				showMenu={isLogged}
				showLoginButton={!isLogged}
			/>
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />
			
			<ScrollView>
				<View style={styles.container}>
					{(() => {
						switch (role) {
						case roles.user:
							return <RenderHtml source={{ html: faqHtml.faqUserHtml }} contentWidth={500} />
						case roles.serviceCoordinator:
							return <RenderHtml source={{ html: faqHtml.faqCoordinatorHtml }} contentWidth={500} />
						case roles.placeManager:
							return <RenderHtml source={{ html: faqHtml.faqPlacesManagerHtml }} contentWidth={500} />
						case roles.internalUser:
							return <RenderHtml source={{ html: faqHtml.faqInternalOperatorHtml }} contentWidth={500} />
						case roles.externalUser:
							return <RenderHtml source={{ html: faqHtml.faqExternalOperatorHtml }} contentWidth={500} />
						default:
							return <RenderHtml source={{ html: faqHtml.faqUserHtml }} contentWidth={500} />
						}
					})()}
				</View>
				<Footer
					isWeb={isWeb}
					width={width}
					navigation={props.navigation}
				/>
			</ScrollView>
		</SafeAreaView>
	);

};

export default FaqScreen;
