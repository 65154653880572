import { Platform, View, Text, Pressable, Modal, StyleSheet } from "react-native";
import { useEffect, useState } from "react";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import WorkingHours from "../WorkingHours";
import { mapWorkingHoursDayToWorkingDay, WorkingDay } from "../../utils/WorkingHours";
import { UserCalendarService } from "../../services/UserCalendarService";
import { strings } from "../../locales/strings";
import Button from "../Button";

interface Props {
	alertVisible: boolean;
	setAlertVisible: (isVisible: boolean) => any;
	userId: string;
}

const DisponibilityAlert = (props: Props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	const [workingDays, setWorkingDays] = useState<WorkingDay[]>([]);

	useEffect(() => {
		if (!props.alertVisible) {
			return;
		}

		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		getWorkingHours();
	}, [props.alertVisible]);

	const getWorkingHours = async () => {
		try {
			const wh = await UserCalendarService.getWorkingHours(props.userId);
			if (wh.item.days.length > 0) {
				setWorkingDays(mapWorkingHoursDayToWorkingDay(wh.item.days));
			}
		} catch (e: any) {
			console.log("error " + JSON.stringify(e));
		}
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.alertVisible} onRequestClose={() => props.setAlertVisible(false)}>
			<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => props.setAlertVisible(false)} />
			<View style={styles.container}>
				<View style={styles.content}>
					<Text style={styles.title}>{strings.disponibility}</Text>

					<WorkingHours
						workingDays={workingDays}
						onWorkingDaysChange={(workingDays: WorkingDay[]) => setWorkingDays(workingDays)}
						isWeb={isWeb}
						style={undefined}
					/>

					<Button handleButtonPress={() => props.setAlertVisible(false)} text={strings.close} filledButton={true} style={{ marginTop: 50 }} />
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingLeft: 30,
		paddingRight: 30,
		paddingTop: 30,
		paddingBottom: 50,
		width: Platform.OS === "web" ? 500 : "80%",
	},
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	title: {
		fontSize: 24,
		textAlign: "center",
		fontFamily: fonts.RalewayBold,
		color: colors.grey4,
		marginBottom: 20,
		marginTop: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
	text: {
		fontSize: 16,
		textAlign: "center",
		fontFamily: fonts.RalewayRegular,
		color: colors.grey4,
		marginBottom: 20,
		marginLeft: "5%",
		marginRight: "5%",
	},
});

export default DisponibilityAlert;
