import { Platform, Pressable, Text, View } from "react-native";
import { styles } from "../styles/YourEvents.style";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import { AlertTypes } from "../utils/AlertTypes";
import BasicAlert from "../components/Alerts/BasicAlert";
import Loader from "../components/Loader";
import BasicToast from "../components/Toasts/BasicToast";
import Button from "../components/Button";
import { colors } from "../resources/colors";
import { EventsEndPoint } from "../services/EventsEndPoint";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import { Event } from "../models/eventsModels";
import { ActivityStatus, EventStatus } from "../utils/EventStatus";
import Pagination from "../components/Pagination";
import EventCard from "../components/EventCard";
import Searchbar from "../components/Searchbar";
import FilterEventWeb from "../components/Events/FilterEventWeb";
import { roles } from "../utils/Roles";
import FilterButton from "../components/FilterButton";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { StatusType } from "../utils/types/StatusType";
import { PageNames } from "../navigation/NavigationUtils";
import Footer from "../components/Footer";
import SelectModal from "../components/Select/SelectModal";
import { MacroAreaEventSelect } from "../utils/SelectUtils";
import { MacroAreaType } from "../utils/types/MacroAreaType";
import { dashboardType } from "../utils/DashboardType";
import { useRoute } from "@react-navigation/native";

interface PropsInterface {
	navigation: any;
	route: {
		params: {
			filterSelected: { type: ""; label: "" };
			eventType: EventCategory.partecipate;
		};
	};
}

enum EventCategory {
	partecipate = "partecipate",
	created = "created",
	past = "past",
	invited = "invited",
	toAccept = "toAccept", // for service-coordinator
	toVerify = "toVerify", // for internal and external users
	all = "all",
}
const EventFieldFilter = [{ type: "all", label: strings.all }].concat(MacroAreaType); // EVENT TYPE
const EventStatusFilter = [{ type: "all", label: strings.all }].concat(StatusType); // EVENT STATUS

const EVENTS_STATUS: EventStatus[] = [
	EventStatus.draft,
	EventStatus.to_be_approved,
	EventStatus.wait_internal_operator,
	EventStatus.wait_external_operator,
	EventStatus.approved,
];
const EVENTS_STATUS_PARTECIPATE: EventStatus[] = [EventStatus.wait_internal_operator, EventStatus.wait_external_operator, EventStatus.approved];
const EVENTS_STATUS_USER: EventStatus[] = [
	EventStatus.draft,
	EventStatus.to_be_approved,
	EventStatus.wait_external_operator,
	EventStatus.wait_internal_operator,
	EventStatus.approved,
];
const EVENTS_STATUS_INTERNAL_OPERATOR: EventStatus[] = [EventStatus.wait_internal_operator];
const EVENTS_STATUS_EXTERNAL_OPERATOR: EventStatus[] = [EventStatus.wait_external_operator];
const EVENTS_PER_PAGE = 15;

interface SearchProps {
	search: string;
	page: number;
	pageSize: number;
	status: EventStatus[];
	createdBy: string;
	isParticipatedByMe?: boolean;
	ended?: boolean;
	isInvited?: boolean;
	checkOperator: boolean;
	activityStatus?: ActivityStatus[];
	checkActivityOperator?: boolean;
	dashboardType?: "food" | "wellness" | undefined
}

const DEFAULT_FILTERS: SearchProps = {
	search: "",
	page: 1,
	pageSize: EVENTS_PER_PAGE,
	status: EVENTS_STATUS,
	createdBy: "",
	isParticipatedByMe: false,
	isInvited: false,
	checkOperator: false,
	dashboardType: undefined
};

const YourEventsScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const route = useRoute();


	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		checkUser();
		setFilterSelected(props.route.params.filterSelected);
		setButtonFilters(props.route.params.eventType);
		scrollRefScreen != null ? scrollToTop() : null;
	}, [props]);

	const [scrollRefScreen, setScrollRefScreen] = useState(null);
	const scrollToTop = () => {
		scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
	};

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
	const [errorToastVisible, setErrorToastVisible] = useState(false);

	const [filterList, setFilterList] = useState<{ type: string; label: string }[]>([]);
	const [openFilter, setOpenFilter] = useState(false);
	const [filterSelected, setFilterSelected] = useState<{ type: string; label: string }>({ type: "", label: "" });
	const[buttonFilters, setButtonFilters] = useState<string>();

	const [eventsType, setEventsType] = useState(EventCategory.partecipate);
	const [filters, setFilters] = useState<SearchProps>(DEFAULT_FILTERS);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const clearFields = () => {
		setFilters(DEFAULT_FILTERS);
		setFilterSelected({ type: "all", label: strings.all });
		setFilterList(EventFieldFilter);
		setEventsType(EventCategory.partecipate);
	};

	const [userId, setUserId] = useState("");
	const [role, setRole] = useState("");

	const checkUser = async () => {
		const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		setUserId(id ? id : "");
		setRole(userRole ? userRole : "");
		setFilterSelected({ type: "all", label: strings.all });
		setEventsType(
			props.route.params.eventType
				? props.route.params.eventType
				: userRole === roles.placeManager || userRole === roles.serviceCoordinator
					? EventCategory.created
					: EventCategory.partecipate
		);
		setFilterList(userRole === roles.placeManager || userRole === roles.serviceCoordinator ? EventStatusFilter : EventFieldFilter);

		checkFilters(
			userRole ? userRole : undefined,
			id ? id : undefined,
			props.route.params.eventType
				? props.route.params.eventType
				: userRole === roles.placeManager || userRole === roles.serviceCoordinator
					? EventCategory.created
					: EventCategory.partecipate
		);
	};

	const [events, setEvents] = useState<{ totalCounts: number; items: Event[] }>({ totalCounts: 0, items: [] });
	const searchEvents = async (filtersSelected: SearchProps) => {
		setLoaderVisible(true);
		try {
			const eventsRes = await EventsEndPoint.getEvents(filtersSelected ? filtersSelected : { ...filters });
			const items = isWeb ? eventsRes.page.items : filtersSelected.page === 1 ? eventsRes.page.items : events.items.concat(eventsRes.page.items);
			setEvents({ totalCounts: eventsRes.total, items });
		} catch (e: any) {
			setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.ok });
		}
		if (Platform.OS === "ios") {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 500);
		} else {
			setLoaderVisible(false);
		}
	};

	const checkFilters = (userRole?: string, id?: string, eventTypeSelected?: string) => {
		if ((userRole || role) && (userId != "" || id)) {
			setFilterSelected({ type: "all", label: strings.all });
			setFilterList(EventFieldFilter);
			switch (eventTypeSelected ? eventTypeSelected : eventsType) {
				case EventCategory.partecipate: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, isParticipatedByMe: true, ended: false });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, isParticipatedByMe: true, ended: false });
					break;
				}
				case EventCategory.created: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_USER, createdBy: id ? id : userId });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_USER, createdBy: id ? id : userId });
					break;
				}
				case EventCategory.past: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, isParticipatedByMe: true, ended: true });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, isParticipatedByMe: true, ended: true });
					break;
				}
				case EventCategory.invited: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, ended: false, isInvited: true });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS_PARTECIPATE, ended: false, isInvited: true });
					break;
				}
				case EventCategory.toAccept: {
					// internal and external operators
					const filter: SearchProps = {
						...DEFAULT_FILTERS,
						dashboardType: undefined,
						status:
							userRole === roles.externalUser || role === roles.externalUser ? [EventStatus.wait_external_operator] : [EventStatus.wait_internal_operator],
						checkOperator: true,
						... (userRole === roles.externalUser || role === roles.externalUser) && {
							activityStatus: [ActivityStatus.wait_external_operator],
							checkActivityOperator: true,
						},
					};
					setFilters(filter);
					searchEvents(filter);
					break;
				}
				case EventCategory.toVerify: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: [EventStatus.to_be_approved] });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: [EventStatus.to_be_approved] });
					break;
				}
				case EventCategory.all: {
					setFilters({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS });
					searchEvents({ ...DEFAULT_FILTERS, dashboardType: undefined, status: EVENTS_STATUS });
					break;
				}
			}
		}
	};

	const filterButtons = () => {
		return (
			<>
				{role === roles.user || role === roles.externalUser || role === roles.internalUser ? (
					<FilterButton
						onChange={() => {
							setEventsType(EventCategory.partecipate);
							checkFilters(undefined, undefined, EventCategory.partecipate);
						}}
						text={strings.eventWhereIPartecipate}
						selected={!!buttonFilters ? buttonFilters ===EventCategory.partecipate :  eventsType ===EventCategory.partecipate }
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}

				{role === roles.user || role === roles.externalUser || role === roles.internalUser ? (
					<FilterButton
						onChange={() => {
							setEventsType(EventCategory.past);
							checkFilters(undefined, undefined, EventCategory.past);
						}}
						text={strings.pastEvents}
						selected={!!buttonFilters ? buttonFilters === EventCategory.past : eventsType === EventCategory.past}
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}

				{role === roles.user ? (
					<FilterButton
						onChange={() => {
							setEventsType(EventCategory.invited);
							checkFilters(undefined, undefined, EventCategory.invited);
						}}
						text={strings.eventsWhereIAmInvited}
						selected={!!buttonFilters ? buttonFilters ===EventCategory.invited : eventsType === EventCategory.invited }
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}

				<FilterButton
					onChange={() => {
						setButtonFilters(undefined);
						setEventsType(EventCategory.created);
						checkFilters(undefined, undefined, EventCategory.created);
					}}
					text={strings.eventsCreatedByMe}
					selected={!!buttonFilters ? buttonFilters === EventCategory.created: eventsType === EventCategory.created}
					style={{ width: 200, marginRight: 10, marginBottom: 10 }}
				/>

				{role === roles.externalUser || role === roles.internalUser ? (
					<FilterButton
						onChange={() => {
							setButtonFilters(undefined);

							setEventsType(EventCategory.toAccept);
							checkFilters(undefined, undefined, EventCategory.toAccept);
						}}
						text={strings.eventsToAccept}
						selected={!!buttonFilters ? buttonFilters ===EventCategory.toAccept : eventsType === EventCategory.toAccept}
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}

				{role === roles.serviceCoordinator ? (
					<FilterButton
						onChange={() => {
							setButtonFilters(undefined);

							setEventsType(EventCategory.toVerify);
							checkFilters(undefined, undefined, EventCategory.toVerify);
						}}
						text={strings.eventsToVerify}
						selected={!!buttonFilters ? buttonFilters === EventCategory.toVerify :  eventsType === EventCategory.toVerify }
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}

				{role === roles.serviceCoordinator ? (
					<FilterButton
						onChange={() => {
							setButtonFilters(undefined);
							setEventsType(EventCategory.all);
							checkFilters(undefined, undefined, EventCategory.all);
						}}
						text={strings.all}
						selected={!!buttonFilters ? buttonFilters === EventCategory.all : eventsType === EventCategory.all}
						style={{ width: 200, marginRight: 10, marginBottom: 10 }}
					/>
				) : null}
			</>
		);
	};

	return (
		<SafeAreaView style={styles.safeArea}>
			<Header width={width} navigation={props.navigation} onPress={() => props.navigation.navigate(PageNames.home)} showMenu={true} />
			<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

			<BasicAlert
				title={basicAlertValues.title}
				description={basicAlertValues.description}
				buttonText={basicAlertValues.buttonText}
				alertType={basicAlertValues.type}
				alertVisible={basicAlertValues.isVisible}
				setAlertVisible={(isVisible: boolean) => {
					setBasicAlertValues({
						isVisible: isVisible,
						title: basicAlertValues.title,
						description: basicAlertValues.description,
						type: basicAlertValues.type,
						buttonText: basicAlertValues.buttonText,
					});
				}}
			/>

			<BasicToast
				title={strings.errorPhotoUploaded}
				alertType={AlertTypes.error}
				alertVisible={errorToastVisible}
				setAlertVisible={(isVisible: boolean) => setErrorToastVisible(isVisible)}
			/>

			<SelectModal
				modalVisible={isModalVisible}
				title={strings.createEvent}
				subTitle={strings.subStringEvent}
				onClose={() => setIsModalVisible(false)}
				items={MacroAreaEventSelect}
				buttons={[
					{
						text: strings.create,
						onPress: (itemId: number) => {

							if (MacroAreaType[itemId].type === dashboardType.food) {
								props.navigation.navigate(PageNames.createEventFood,
									{
										event: undefined,
										userId: undefined, role: undefined,
										macroarea: {
											key: MacroAreaType[itemId].type,
											label: MacroAreaType[itemId].label
										},
										edit: false,
									});
							} else if (MacroAreaType[itemId].type === dashboardType.wellness) {
								props.navigation.navigate(PageNames.createEventWellness,
									{
										event: undefined,
										userId: undefined, role: undefined,
										macroarea: {
											key: MacroAreaType[itemId].type,
											label: MacroAreaType[itemId].label
										},
										edit: false,
									});
							}
							setIsModalVisible(false);
						}
					}
				]}
			/>

			<ScrollView style={{ marginTop: Platform.OS === "web" ? 30 : 0 }} ref={(c) => setScrollRefScreen(c)}>
				{Platform.OS === "web" ? <PageNavigation navigationEvent={clearFields} /> : null}

				<View style={[styles.container, isWeb ? { backgroundColor: colors.white, marginLeft: "5%", marginRight: "5%", marginTop: 50 } : {}, { zIndex: 2 }]}>
					<View style={!isWeb ? styles.rowView : null}>
						<Text style={styles.title}>{strings.yourEvents}</Text>
						{!isWeb &&
							<Button
								style={{ marginTop: 10 }}
								handleButtonPress={() => setIsModalVisible(true)}
								text={strings.create}
								filledButton={true}
							/>}
					</View>

					{isWeb ? (
						<>
							<View style={[styles.rowView, { marginTop: 30 }]}>
								<View style={[{ flexDirection: "row", width: isWeb ? "80%" : "100%" }, isWeb ? { flexWrap: "wrap" } : { overflow: "scroll" }]}>
									{filterButtons()}
								</View>
								<View style={[styles.rowView]}>
									<Button
										handleButtonPress={() => setIsModalVisible(true)}
										text={strings.createEvent}
										filledButton={true}
										style={{ marginBottom: 10 }}
									/>
								</View>
							</View>

						</>
					) : (
						<>
							<ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.mobileFilterContainer}>
								{filterButtons()}
							</ScrollView>
						</>
					)}
					<View style={[isWeb || Platform.OS !== "web" ? styles.rowView : null, { zIndex: 999 }]}>
						<View style={{ width: isWeb || Platform.OS !== "web" ? "80%" : "100%" }}>
							<View style={{ marginRight: 10, width: "100%" }}>
								<Searchbar
									placeholder={strings.searchEvent}
									search={(val: string) => {
										const updatedFilters: SearchProps = { ...filters, search: val };
										setFilters(updatedFilters);
										searchEvents(updatedFilters);
									}}
								/>
							</View>
						</View>
						{Platform.OS === "web" ? (
							<View style={{ width: isWeb ? "20%" : "100%" }}>
								<FilterEventWeb
									openComponent={openFilter}
									setOpenComponent={(value: boolean) => setOpenFilter(value)}
									value={filterSelected}
									setValue={(value: any) => {
										setFilterSelected(value);
										if (filterList === EventStatusFilter) {
											searchEvents({ ...filters, status: [value.type] });
										} else {
											searchEvents({ ...filters, dashboardType: value.type === "all" ? undefined : value.type });

										}
									}}
									list={filterList}
									isWeb={isWeb}
								/>
							</View>
						) : (
							<Pressable
								style={styles.filterIconContainer}
								onPress={() =>
									props.navigation.navigate(PageNames.filter, {
										title: strings.filterEvent,
										filters: filterList,
										filterSelected: filterSelected,
										navigatePage: PageNames.yourEvents,
										setFilter: (value: any) => {
											setFilterSelected(value);
											if (filterList === EventStatusFilter) {
												searchEvents({ ...filters, status: [value.type] });
											} else {
												searchEvents({ ...filters, dashboardType: value.type === "all" ? undefined : value.type });
											}
										},
									})
								}
							>
								<FontAwesomeIcon style={styles.filterIcon} size={20} icon={faFilter} />
							</Pressable>
						)}
					</View>

					{events.totalCounts ? (
						<>
							<View style={styles.events}>
								{events.items.map((item: Event, index: number) => (
									<View style={{ marginBottom: 20 }} key={`myevents${index}`}>
										<EventCard
											backgroundColor={isWeb ? colors.primary : ""}
											tagsColor={isWeb ? colors.white : ""}
											partecipants={eventsType === EventCategory.partecipate || eventsType === EventCategory.past}
											partecipantsText={eventsType === EventCategory.past ? strings.invitedPartecipant : strings.invitePartecipant}
											isPastEvent={eventsType === EventCategory.past}
											created={
												eventsType === EventCategory.created ||
												eventsType === EventCategory.toAccept ||
												eventsType === EventCategory.toVerify ||
												eventsType === EventCategory.all
											}
											edit={() => {
												clearFields();
												if (item.field === dashboardType.food) {
													props.navigation.navigate(PageNames.createEventFood, { event: item, userId: userId, role: role, edit: true });
												} else if (item.field === dashboardType.wellness) {
													props.navigation.navigate(PageNames.createEventWellness, { event: item, userId: userId, role: role, edit: true, page:route.name, filters:eventsType});
												}
											}}
											canEdit={
												((item.status === EventStatus.draft || item.status === EventStatus.to_be_approved || item.status === EventStatus.approved) &&
													role === roles.serviceCoordinator) ||
												(item.status === EventStatus.to_be_approved && role === roles.serviceCoordinator) ||
												(item.status === EventStatus.wait_internal_operator && item?.internalOperator?.id === userId) ||
												(item.status === EventStatus.wait_external_operator && item?.externalOperator?.id === userId) ||
												(item.status === EventStatus.wait_external_operator
													&& !!item.activities?.some((value) => {
														return value.externalOperator?.id === userId;
													}))
												|| (item.status === EventStatus.draft && item.userId === userId)
											}
											event={item}
											navigation={props.navigation}
										/>
									</View>
								))}
							</View>

							<View style={{ width: "100%" }}>
								<View style={styles.pagination}>
									{isWeb ? (
										<Pagination
											pages={Math.ceil(events.totalCounts / EVENTS_PER_PAGE) || 1}
											currentPage={filters.page}
											handlePageSelect={(newPage: number) => {
												setFilters({ ...filters, page: newPage });
												searchEvents({ ...filters, page: newPage });
											}}
										/>
									) : Math.ceil(events.totalCounts / EVENTS_PER_PAGE) > filters.page ? (
										<Button
											text={strings.profilePage.loadMoreEvents}
											handleButtonPress={() => {
												setFilters({ ...filters, page: filters.page + 1 });
												searchEvents({ ...filters, page: filters.page + 1 });
											}}
											filledButton={true}
										/>
									) : null}
								</View>
							</View>
						</>
					) : (
						<Text>{strings.dashboard.noEvents}</Text>
					)}
				</View>
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default YourEventsScreen;
