
import { FileInfo, GetChatFile, Message, MessageHistory, PrivateRoomResponse, ResponseUploadChunk } from "../../models/chatModels";
import { APIBaseService } from "../ApiBaseService";
import { ManageFiles } from "./ManageFiles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { asyncStorageUtils } from "../../utils/AsyncStorageUtils";
import { Platform } from "react-native";
import axios, { AxiosRequestConfig } from 'axios'
import { UploadHelper } from "./UploadFile";
import { EntityResponse } from "../../models/exchange";

export class ChatService extends APIBaseService {


	// PRIVATE ROOM
	public static async getPrivateRoom(userdId: string): Promise<PrivateRoomResponse> {
		return await APIBaseService.performRequest("get", `${APIBaseService.chatBaseUrl}/v1/rooms/private?withUser=${userdId}`, false);
	}


	public static async getRoomMessages(roomId: string, page: number): Promise<MessageHistory> {
		return await APIBaseService.performRequest("get", `${APIBaseService.chatBaseUrl}/v1/rooms/${roomId}/messages?page=${page}`, false);
	}

	public static async getRoomUnreadMessagesNumber(roomId: string): Promise<EntityResponse<{ unreadMessages: number }>> {
		return await APIBaseService.performRequest("get", `${APIBaseService.chatBaseUrl}/v1/rooms/${roomId}/messages/unread`, false);
	}

	public static async createRoom(members: string, isSpace: boolean): Promise<any> {
		return await APIBaseService.performRequest("post", `${APIBaseService.chatBaseUrl}/v1/rooms`, false,
			{ members: members, isSpace: isSpace });
	}

	public static async joinRoom(roomId: string): Promise<any> {
		return await APIBaseService.performRequest("put", `${APIBaseService.chatBaseUrl}/v1/rooms/${roomId}/join`, false);
	}

	public static async leaveRoom(roomId: string): Promise<any> {
		return await APIBaseService.performRequest("put", `${APIBaseService.chatBaseUrl}/v1/rooms/${roomId}/leave`, false);
	}

	public static urlToBlob(url: string): Promise<Blob> {
		return new Promise((resolve, reject) => {
			var xhr = new XMLHttpRequest();
			xhr.onerror = reject;
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					resolve(xhr.response);
				}
			};
			xhr.open('GET', url);
			xhr.responseType = 'blob'; // convert type
			xhr.send();
		})
	}



	public static async uploadFile(fileData: FileInfo): Promise<any> {
		try {
			let blob = null;
			if (Platform.OS === "android") {
				blob = await ChatService.urlToBlob(fileData.fileUri);
			} else {
				const fetchResponse = await fetch(Platform.OS === 'ios' ? fileData.fileUri.split('//')[1] : fileData.fileUri);
				blob = await fetchResponse.blob();
			}

			const body = {
				meta: {
					name: fileData.name,
					mimeType: fileData.type,
					contentLength: blob.size,
				}
			};

			const res = await APIBaseService.performRequest("post", `${APIBaseService.baseUrl}/attachments/uploadSessionUrl`, false, body);
			let chunkResponse: any = undefined;

			if (Platform.OS !== "web") {
				chunkResponse = await UploadHelper.uploadFile(blob, fileData, res);
				const json = JSON.parse(chunkResponse.data)
				return json;
			} else {
				const fileChunks = await ManageFiles.divideFileInChunks(fileData, blob.size, blob);
				for (const index of fileChunks.keys()) {
					const axiosConfig: AxiosRequestConfig = {
						headers: {
							"Content-Range": `bytes ${fileChunks[index].start}-${(fileChunks[index].end).toString()}/${(blob.size).toString()}`
						},
						validateStatus(status) {
							return status < 400;
						},
					}
					chunkResponse = await axios.put(res?.item?.sessionUrl, fileChunks[index].blob, axiosConfig)
				}
				return chunkResponse;
			}

		} catch (err: any) {
			console.log("Error uploading file:" + JSON.stringify(err));
			return err;
		};
	}



	public static async getInfoFile(fileId: string, ownerId: string): Promise<any> {
		try {
			const res: GetChatFile = await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/attachments/${fileId}/metadata?ownerId=${ownerId}`, false);
			return res;

		} catch (err: any) {
			console.log("Error downloading file:" + err);
		}
	}
	public static async getFiles(fileId: string, ownerId: string, start: string, end: string): Promise<any> {
		const token = await AsyncStorage.getItem(asyncStorageUtils.accessToken);

		const response = await fetch(
			`${APIBaseService.baseUrl}/attachments/${fileId}/chunk?ownerId=${ownerId}&start=${start}&end=${end}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + token,
					responseType: "blob",
				},
			},

		);
		return response
	}

	public static async downloadFile(res: GetChatFile, message: Message, callback: (error: any | null, fileInfo?: FileInfo) => void): Promise<any> {
		const fileSize = res.item.size;
		const fileChunks = ManageFiles.divideDownloadFileInChunks(parseInt(fileSize));

		const fileMimeType = res.item.mimeType;
		const blob = [];
		for (let i = 0; i < fileChunks.length; i += 1) {
			const response = await ChatService.getFiles(message!.attachmentId!, message!.author, fileChunks[i].start, fileChunks[i].end)
			const partialBlob = await response.blob();
			blob.push(partialBlob);
			console.log(`${i + 1} Chunk Downloaded of ${fileChunks.length}`);
		}
		const blob2 = new Blob(blob);
		const file = new Blob([blob2], { fileMimeType });
		const fileReaderInstance = new FileReader();
		fileReaderInstance.readAsDataURL(file);
		fileReaderInstance.onload = () => {
			const base64data = fileReaderInstance.result;
			callback(null, {
				name: res.item.name,
				type: res.item.mimeType,
				size: res.item.size,
				data: base64data?.toString() ?? '',
				fileUri: ""
			});
		}
		return message;

	}

}


