import { View, StyleSheet, Text, Image } from "react-native";
import { colors } from "../resources/colors";

interface ProfileImageProps {
	profilePicture?: string;
	firstName: string;
	lastName: string;
	size?: number;
	style?: any;
}

const ProfileImage = ({ profilePicture, firstName, lastName, size, style }: ProfileImageProps) => {
	const text = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
	const dim = !!size ? { width: size, height: size, borderRadius: size / 2 } : null;
	return (
		<View style={[styles.profileBorder, style, dim]}>
			<View style={[styles.profileImageBack, style, dim]}>
				{profilePicture ? (
					<View>
						<Image
							resizeMode="cover"
							style={[styles.logo, dim]}
							source={{
								uri: profilePicture,
							}}
						/>
					</View>
				) : (
					<Text style={[styles.text, !!size && size < 30 ? {fontSize: 12} : null]}>{text}</Text>
				)}
			</View>
		</View>
	);
};

export default ProfileImage;

const styles = StyleSheet.create({
	profileBorder: {
		borderRadius: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 48,
		height: 48,
	},
	profileImageBack: {
		borderRadius: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: 48,
		height: 48,
		backgroundColor: colors.grey,
	},
	text: {
		color: colors.blue2,
		fontSize: 18,
		fontWeight: "bold",
	},
	logo: {
		width: 48,
		height: 48,
		borderRadius: 50,
	},
});
