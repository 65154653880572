import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useEffect, useRef } from "react";
import { FlatList, Platform, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { PageNames } from "../../navigation/NavigationUtils";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/CreateEventScreen";
import { MacroAreaType } from "../../utils/types/MacroAreaType";

interface PropsInterface {
	navigation: any;
	event: any;
	openTypologyComponent: boolean;
	setOpenTypologyComponent: (value: boolean) => void;
	setEvent: (value: any) => void;
	setTypology: (value: any) => void;
	typology: any;
	selectTypology: (value: any) => void;
	isReadOnly: boolean;
    list: any;
    pageName: string;
}

const TypologyComponent = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	const dropdownRef = useRef<View>(null);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				props.setOpenTypologyComponent(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);

	return (
		<View ref={dropdownRef} style={{ zIndex: props.list === MacroAreaType ? 2 : 1 }}>
			<Pressable
				style={styles.rowView}
				onPress={() =>
					!props.isReadOnly
						? Platform.OS !== "web"
							? props.navigation.navigate(PageNames.filter, {
									title: props.list === MacroAreaType ? strings.scope : strings.selectTypology,
									filters: props.list,
									filterSelected: props.typology,
									navigatePage: props.pageName,
									setFilter: (filter: any) => {
										props.list === MacroAreaType
											? props.setEvent({ ...props.event, field: filter.type })
											: props.setEvent({ ...props.event, type: filter.type });
										props.setTypology({ key: filter.type, label: filter.label });
									},
							  })
							: props.setOpenTypologyComponent(!props.openTypologyComponent)
						: null
				}
			>
				<Text
					style={[
						styles.selectTitle,
						(props.list === MacroAreaType ? props.event.field != "" : props.event.type != "") ? { color: colors.black } : { color: colors.grey },
						props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
					]}
				>
					{(props.list === MacroAreaType ? props.event.field != "" : props.event.type != "") ? props.typology.label : strings.select}
				</Text>
				{props.openTypologyComponent ? (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleUp}></FontAwesomeIcon>
				) : (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleDown}></FontAwesomeIcon>
				)}
			</Pressable>
			{props.openTypologyComponent ? (
				<View style={[styles.selectComponent]}>
					<FlatList
						data={props.list}
						renderItem={({ item, index }) => {
							return (
								<Pressable
									style={[styles.rowView, { borderBottomColor: colors.grey3, borderBottomWidth: 1, zIndex: 9999 }]}
									onPress={() => {
										if (props.event.type === item.type) {
											props.list === MacroAreaType ? props.setEvent({ ...props.event, field: "" }) : props.setEvent({ ...props.event, type: "" });
											props.setTypology({ key: "", label: "" });
										} else {
											props.list === MacroAreaType ? props.setEvent({ ...props.event, field: item.type }) : props.setEvent({ ...props.event, type: item.type });
											props.setTypology({ key: item.type, label: item.label });
										}
										props.setOpenTypologyComponent(false);
									}}
								>
									<Text style={[styles.fieldDescription, { padding: 10 }]} numberOfLines={1}>
										{item.label}
									</Text>
									{(props.list === MacroAreaType ? item.type === props.event.field : item.type === props.event.type) ? (
										<FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon>
									) : null}
								</Pressable>
							);
						}}
						keyExtractor={(item) => item.type}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
			) : null}
		</View>
	);
};

export default TypologyComponent;
