
import { Platform, Pressable, Text, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useEffect, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { styles } from "../../styles/FilterScreen.styles";
import { strings } from "../../locales/strings";
import Button from "../../components/Button";
import { RadioButton } from "react-native-radio-buttons-group";
import { fonts } from "../../resources/fonts";
import { FilterScreenPropsInterface } from "../../models/models";
import { useNavigation } from "@react-navigation/native";

const FilterView = (params: FilterScreenPropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [filterSelected, setFilterSelected] = useState(params.filterSelected);
    const [scrollRefScreen, setScrollRefScreen] = useState(null);
    const isWeb = width >= 768;
    const navigation = useNavigation<any>();

    useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        scrollRefScreen != null ? scrollToTop() : null;
    }, [params]);

    const scrollToTop = () => {
        scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
    };

    return (
        <SafeAreaView style={styles.safeArea}>
            <Text style={styles.title}>{params.title + " :"}</Text>
            <ScrollView contentContainerStyle={styles.scrollView} ref={(c) => setScrollRefScreen(c)}>
                <View style={{ width: "100%" }}>
                    {
                        params.filters.map((item: any, index: number) => (
                            <Pressable style={[{ flexDirection: "row" }]} onPress={() => console.log()} key={`filter${index}`}>
                                <RadioButton layout="row" selected={filterSelected?.type === item.type} onPress={() => setFilterSelected(item)} id={"filters"} />
                                <View>
                                    <Text
                                        style={[
                                            styles.filtersLabel,
                                            item.description ? { paddingTop: 15, paddingLeft: 10, paddingRight: 10, fontFamily: fonts.RalewayBold } : { padding: 10 },
                                        ]}
                                        numberOfLines={1}
                                    >
                                        {item.label}
                                    </Text>
                                    {item.description ? (
                                        <Text style={[styles.filtersLabel, { paddingLeft: 10, paddingRight: 10, paddingBottom: 15 }]} numberOfLines={1}>
                                            {item.description}
                                        </Text>
                                    ) : null}
                                </View>
                            </Pressable>
                        ))
                    }
                </View>
            </ScrollView>
            <View style={[styles.rowView, styles.buttonsContainer]}>
                <Button
                    handleButtonPress={() => {
                        if (params.handleBackPress) {
                            params.handleBackPress();
                        } else {
                            navigation.goBack();
                        }
                    }}
                    text={strings.back}
                    filledButton={true}
                />
                <Button
                    handleButtonPress={() => {
                        if (params.handleOnPress && Platform.OS !== 'web') {
                            params.handleOnPress(filterSelected);
                        } else {
                            if (params.setFilter) {
                                params.setFilter(filterSelected);
                                params.setOpenActivity && params.setOpenActivity(params.isActivity ?? false);
                                navigation.goBack();
                            } else {
                                navigation.navigate({
                                    name: params.navigatePage,
                                    params: { filterSelected: filterSelected },
                                    merge: true,
                                });
                            }
                        }
                    }}
                    text={strings.apply}
                    filledButton={true}
                />
            </View>
        </SafeAreaView>
    );
};

export default FilterView;
