import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	safeArea: {
		backgroundColor: colors.primary,
		height: "100%",
	},
	title: {
		fontSize: 24,
		marginTop: 20,
		marginBottom: 0,
		fontFamily: fonts.RalewayBold,
	},
	scrollView: {
		paddingBottom: 50,
	},
	container: {
		borderRadius: 10,
		padding: 20,
		marginBottom: "5%"
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	pagination: {
		display: "flex",
		flexDirection: "row",
		marginVertical: 16,
		justifyContent: "space-around",
	},
	events: {
		marginVertical: 32,
	},
	selectIcon: {
		right: 35,
		top: 20,
		zIndex: 9999,
		position: Platform.OS === "web" ? "absolute" : "relative",
	},
	selectComponent: {
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 0,
		borderRadius: 10,
		position: "absolute",
		right: 0,
		zIndex: 4,
		paddingTop: 5,
		flex: 1,
		marginTop: 55,
	},
	selectTitle: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 30,
		paddingTop: 10,
		paddingBottom: 15,
		backgroundColor: colors.white,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 38,
		marginTop: 10,
	},
	selectItem: {
		fontSize: 14,
		fontFamily: fonts.RalewayRegular,
		color: colors.black,
	},
	checkIcon: {
		right: 15,
		top: 10,
		position: "absolute",
	},
	mobileFilterContainer: {
		marginTop: 20,
		marginLeft: -20,
		marginRight: -20,
		paddingLeft: 15,
		paddingRight: 15,
	},
	filterIconContainer: {
		padding: 10,
		backgroundColor: colors.blue,
		width: 40,
		height: 40,
		borderRadius: 50,
		marginTop: 8,
	},
	filterIcon: {
		color: colors.white,
		backgroundColor: colors.blue,
	},
});
