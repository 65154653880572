import { useState, useEffect, useRef, useCallback } from "react";
import { Text, StyleSheet, Platform, Modal, Pressable, View, Image, FlatList, Dimensions } from "react-native";
import { strings } from "../../locales/strings";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import Button from "../Button";
import Map from "./Map";
import * as Location from "expo-location";
import { defaultLocation } from "../../utils/GoogleUtils";
import { Markers, Place } from "../../models/models";
import { PlacesEndPoint } from "../../services/PlacesEndPoint";
import Tag from "../Tag";
import { getDefaultImageByType, getEventDate, getTypeString } from "../../utils/EventUtils";
import UsersIcon from "../Icons/UsersIcon";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { EventsEndPoint } from "../../services/EventsEndPoint";
import { EventStatus } from "../../utils/EventStatus";
import Loader from "../Loader";
import { AlertTypes } from "../../utils/AlertTypes";
import BasicAlert from "../Alerts/BasicAlert";
import { PageNames } from "../../navigation/NavigationUtils";
const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

interface props {
	modalVisible: boolean;
	setModalVisible: (isVisible: boolean) => any;
	event?: any;
	navigation: any;
}

const MapView = (props: props) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		if (props.modalVisible) {
			if (props.event != null) {
				getPlaceLocation();
				getEvents(props.event.place._id);
			} else {
				getPlaces();
			}
		}
	}, [props]);

	const [showAll, setShowAll] = useState(false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });

	const [latitude, setLatitude] = useState(defaultLocation.latitude);
	const [longitude, setLongitude] = useState(defaultLocation.longitude);
	const [latitudeDelta, setLatitudeDelta] = useState(defaultLocation.latitudeDelta);
	const [longitudeDelta, setLongitudeDelta] = useState(defaultLocation.longitudeDelta);
	const [placeDetails, setPlaceDetails] = useState({
		address: "",
		placeName: "",
		numberOfSeats: 0,
		kitchen: false,
		kitchenNote: "",
		parkingSpots: 0,
		architecturalBarriers: "",
		maxNumberOfUsers: 0,
		image: "",
		openingInfo: "",
		note: "",
		placeManager: {
			name: "",
			id: "",
		},
	});
	const [markers, setMarkers] = useState<Markers[]>([
		{
			coordinates: { latitude: 0, longitude: 0 },
			id: "",
			userId: "",
		},
	]);

	const getPlaceLocation = async () => {
		if (props.event.place && props.event.place?.coordinates && props.event.place?.coordinates.length === 2) {
			setLatitude(props.event.place.coordinates[1]);
			setLongitude(props.event.place.coordinates[0]);
			let marks: Markers[] = [];
			const placeMarker: Markers = {
				coordinates: {
					latitude: props.event.place.coordinates[1],
					longitude: props.event.place.coordinates[0],
				},
				title: props.event.name,
				description: props.event.address,
				id: props.event.id!,
				userId: props.event.placeManager?.id,
			};
			marks.push(placeMarker);
			setMarkers(marks);
			getCurrentLocation(marks);
		}
	};

	const getCurrentLocation = async (mark?: Markers[]) => {
		let { status } = await Location.requestForegroundPermissionsAsync();
		if (status !== "granted") {
			console.log("Permission to access location was denied");
			return;
		}
		const currentLocation = await Location.getCurrentPositionAsync({});
		setLatitude(currentLocation.coords.latitude);
		setLongitude(currentLocation.coords.longitude);

		let marks: Markers[] = mark ? mark : [];
		marks.push({
			coordinates: {
				latitude: currentLocation.coords.latitude,
				longitude: currentLocation.coords.longitude,
			},
			userId: "",
			id: "location",
			pinColor: "yellow",
			dummy: true,
		});
		setMarkers(marks);
	};

	const getPlaces = async () => {
		setLoaderVisible(true);
		try {
			const { page, status, total } = await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100 });
			const places = page.items;

			let marks: Markers[] = [];
			places.forEach((place: Place) => {
				if (place.coordinates) {
					const placeMarker: Markers = {
						coordinates: {
							latitude: place.coordinates[1],
							longitude: place.coordinates[0],
						},
						title: place.name,
						description: place.address,
						id: place.id!,
						userId: place.placeManager?.id,
					};
					marks.push(placeMarker);
				}
			});
			setMarkers(marks);
			getCurrentLocation(marks);
		} catch (e: any) {
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
			setError({ isVisible: true, title: e.status, description: e.error.message });
		} finally {
			if (Platform.OS === 'ios') {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 500);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const getEvents = async (placeId: string) => {
		await EventsEndPoint.getEvents({ pageSize: 15, page: 1, placeId: placeId, status: [EventStatus.approved, EventStatus.wait_internal_operator, EventStatus.wait_external_operator] })
			.then((res: any) => {
				setEvents(res.page.items);
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
			})
			.catch((e: any) => {
				if (Platform.OS === 'ios') {
					setTimeout(() => {
						setLoaderVisible(false);
					}, 500);
				} else {
					setLoaderVisible(false);
				}
				setError({ isVisible: true, title: e.status, description: e.error.message });
			});
	};

	const getPlaceDetails = async (id: string, placeManager: string) => {
		await getEvents(id);
	};

	const goToPlaceDetails = (event: Event) => {
		props.setModalVisible(false); 
		clearFields();
		props.navigation.navigate(PageNames.eventDetail, { event: event, navigation: props.navigation });
	}

	const clearFields = () => {
		setIndex(0);
		setEvents([]);
	}

	const [events, setEvents] = useState([]);
	const [index, setIndex] = useState(0);
	const indexRef = useRef(index);
	indexRef.current = index;
	const flatListRef = useRef<FlatList<any>>(null);

	const handleChange = (index: number) => {
		if (flatListRef.current) {
			flatListRef.current.scrollToIndex({
				animated: true,
				index,
			});
		}
	};

	const onScroll = useCallback((event) => {
		const slideSize = event.nativeEvent.layoutMeasurement.width;
		const index = event.nativeEvent.contentOffset.x / slideSize;
		const roundIndex = Math.round(index);

		const distance = Math.abs(roundIndex - index);
		const isNoMansLand = 0.4 < distance;

		if (roundIndex !== indexRef.current && !isNoMansLand) {
			setIndex(roundIndex);
		}
	}, []);

	const flatListOptimizationProps = {
		initialNumToRender: 0,
		maxToRenderPerBatch: 1,
		removeClippedSubviews: true,
		scrollEventThrottle: 16,
		windowSize: 2,
		keyExtractor: useCallback((e) => e.id, []),
		getItemLayout: useCallback(
			(_, index) => ({
				index,
				length: 220,
				offset: index * 220,
			}),
			[]
		),
	};

	const Pagination = ({ index, slides, onPress }: { index: number; slides: any[]; onPress: (index: number) => void }) => {
		return (
			<View
				style={[
					styles.pagination,
					{ position: "absolute", width: isWeb ? 220 : "100%", zIndex: 9999, padding: 10, borderRadius: 10 },
					isWeb ? { bottom: 110, right: -80 } : { bottom: 0, alignContent: "center", justifyContent: "center" },
				]}
			>
				<View style={{ alignContent: "center", justifyContent: "center", flexDirection: "row", }}>
					{slides.map((_, i) => {
						return (
							<Pressable
								key={i}
								style={[styles.paginationDot, index === i && styles.paginationDotActive]}
								onPress={() => {
									index !== i && onPress(i);
								}}
							/>
						);
					})}
				</View>
			</View>
		);
	};

	return (
		<Modal animationType="fade" transparent={true} visible={props.modalVisible}>

			<View style={styles.container}>
				<Pressable style={[styles.overlay, styles.backdrop]} onPress={() => { clearFields(); props.setModalVisible(false); }} />
				<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />
				<BasicAlert
					title={error.title}
					description={error.description}
					buttonText={strings.ok}
					alertType={AlertTypes.error}
					alertVisible={error.isVisible}
					setAlertVisible={(isVisible: boolean) => {
						setError({ isVisible: isVisible, title: error.title, description: error.description });
					}}
				/>

				<View style={[styles.contenModal, !isWeb ? { width: "95%", height: "85%", marginTop: '10%' } : null]}>
					<Pressable
						style={styles.close}
						onPress={() => {
							clearFields();
							props.setModalVisible(false);
						}}
					>
						<Text style={styles.closeText}>{strings.close}</Text>
						<FontAwesomeIcon color={colors.white} size={15} icon={faClose} />
					</Pressable>

					<View style={{ position: "absolute", height: !isWeb ? "100%" : "100%", width: "100%", flex: 1, alignItems: "center", zIndex: 0, borderRadius: 10 }}>
						<Map
							style={{ height: "100%" }}
							latitude={latitude}
							longitude={longitude}
							latitudeDelta={latitudeDelta}
							longitudeDelta={longitudeDelta}
							markers={markers}
							placeDetails={placeDetails}
							getPlaceDetails={getPlaceDetails}
							heightMapMobile={"100%"}						/>
					</View>
					{events.length > 0 ? (
						<>
							<Pressable
								style={[styles.closeEvents, isWeb ? { top: 65, right: -80 } : { bottom: showAll ? 310 : 240, right: '5%', alignContent: "center", flex: 1 },]}
								onPress={() => {
									setEvents([]);
								}}
							>
								<FontAwesomeIcon color={colors.white} size={20} icon={faClose} />
							</Pressable>
							<FlatList
								ref={flatListRef}
								style={[
									{
										position: "absolute",
										width: isWeb ? 220 : 380,
										zIndex: 9999,
										padding: 10,
										borderRadius: 10,
										height: isWeb ? "100%" : showAll ? 290 : 220,
										paddingTop: 20,
									},
									isWeb ? { top: 90, right: -80 } : { bottom: 20, alignContent: "center", flex: 1 },
								]}
								data={events}
								renderItem={({ item }: any) => {
									return (
										<View style={[{ backgroundColor: colors.white, zIndex: 9999, padding: 10, borderRadius: 10, marginRight: 20, width: isWeb ? 220 : 350 }]}>
											<View style={{}}>
												{isWeb ? (
													<View style={[styles.imageContainer, isWeb ? styles.webImageContainer : null]}>
														<Image
															source={item.img ? { uri: item.img } : getDefaultImageByType(item.type)}
															style={[styles.coverImage, isWeb ? null : { width: "100%" }]}
														></Image>
													</View>
												) : null}

												<Text numberOfLines={1} style={[styles.eventType, !isWeb ? { marginTop: 0 } : null]}>{getTypeString(item.type)}</Text>
												<Text numberOfLines={1} style={styles.title}>{item.title}</Text>

												{/* Date and time */}
												<View>
													{getEventDate(item) ? (
														<View style={styles.eventDateTags}>
															<Tag text={getEventDate(item).day} color={colors.primary} style={styles.eventDateTag} />
															<Tag text={getEventDate(item).hour} color={colors.primary} style={styles.eventDateTag} />
														</View>
													) : null}
												</View>
												{item.place.name ? <Text style={[styles.eventPlace,{ marginBottom: isWeb ? 15 : showAll ? 35 : 40 } ]}>{item.place.name}</Text> : null}
												{isWeb || (!isWeb && showAll) ? (
													<>
														<View style={[styles.attendees]}>
															<UsersIcon size={20} />
															<Text style={styles.attendeesLabel}>{`${ item.participants ? item.participants.length : 0 } ${strings.eventCard.attendees}`}</Text>
														</View>
														<View style={[styles.buttonContainer]}>
															<Button text={strings.join} handleButtonPress={() => goToPlaceDetails(item)} filledButton={true} />
														</View>
													</>
												) : null}
												{!isWeb ? (
													<View style={styles.iconView}>
														<Pressable style={styles.angleIconContainer} onPress={() => setShowAll(!showAll)}>
															<FontAwesomeIcon style={styles.angleIcon} size={15} icon={showAll ? faAngleUp : faAngleDown} />
														</Pressable>
													</View>
												) : null}
											</View>
										</View>
									);
								}}
								pagingEnabled={true}
								horizontal={true}
								showsHorizontalScrollIndicator={false}
								onScroll={onScroll}
								snapToInterval={220 * 0.8}
								snapToAlignment="start"
								{...flatListOptimizationProps}
							/>
							<Pagination index={indexRef.current} slides={events} onPress={handleChange} />
						</>
					) : null}
				</View>
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	overlay: {
		backgroundColor: colors.black,
		opacity: 0.4,
	},
	backdrop: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	container: {
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
	},
	content: {
		backgroundColor: colors.white,
		borderRadius: 10,
	},
	contenModal: {
		backgroundColor: colors.white,
		width: "80%",
		borderRadius: 10,
		height: "75%",
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "center",
	},
	title: {
		fontSize: 21,
		fontFamily: fonts.RalewayBold,
	},
	description: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		marginTop: 50,
	},
	eventDateTag: {
		marginRight: 8,
	},
	eventDateTags: {
		display: "flex",
		flexDirection: "row",
		marginTop: 20,
	},
	imageContainer: {
		width: "100%",
		height: 120,
		marginTop: 20,
		borderRadius: 10,
	},
	webImageContainer: {
		marginTop: -30,
	},
	coverImage: {
		height: "100%",
		borderRadius: 10,
	},
	eventType: {
		color: colors.grey,
		fontFamily: fonts.RalewayBold,
		fontSize: 12,
		textTransform: "uppercase",
		marginTop: 10,
	},
	eventPlace: {
		fontSize: 15,
		fontFamily: fonts.RalewayRegular,
		marginTop: 15,
	},
	label: {
		color: colors.blue,
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		textDecorationLine: "underline",
	},
	verticalLine: {
		borderColor: colors.grey,
		borderLeftWidth: 1,
	},
	attendees: {
		flexDirection: "row",
		marginBottom: 10,
	},
	attendeesLabel: {
		color: colors.grey4,
		opacity: 0.5,
		marginHorizontal: 8,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	close: {
		position: "absolute",
		flexDirection: "row",
		top: -24,
		right: 0,
		alignItems: "center",
		zIndex: 9999,
	},
	closeEvents: {
		position: "absolute",
		zIndex: 9999,
	},
	closeText: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
		color: colors.white,
		marginRight: 8,
	},
	iconView: {
		justifyContent: "center",
		alignItems: "center",
	},
	angleIconContainer: {
		backgroundColor: colors.white,
		borderRadius: 50,
		width: 30,
		height: 30
	},
	angleIcon: {
		backgroundColor: colors.white,
		borderRadius: 50,
		padding: 10,
		color: colors.blue,
		marginTop: 5,
		marginLeft: 5
	},
	pagination: {
		width: "auto",
		zIndex: 2,
		paddingHorizontal: "2%",
		alignContent: "center",
	},
	paginationDot: {
		width: 30,
		height: 4,
		borderRadius: 20,
		marginHorizontal: 2,
		opacity: 0.4,
		backgroundColor: colors.white,
	},
	paginationDotActive: {
		opacity: 1,
	},
});

export default MapView;
