import { Text, StyleSheet, View, Platform, Linking } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import Button from "./Button";
import { useState } from "react";
import React from "react";

interface PropsInterface {
    navigation: any;
    title: string,
    descriptions: string;
    buttonText: string;
	onClick: () => void;
	
}
const Banner = (props: PropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const isWeb = width >= 768;
    React.useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }

    }, [props]);
    return (
        <View style={[styles.container, Platform.OS === "web" ? styles.rowView : null]}>
            <Text style={styles.title}>{props.title}</Text>
            <Text style={styles.description}>{props.descriptions}</Text>
            <Button style={styles.button}
                handleButtonPress={() => {
					props.onClick();
                }}
                text={props.buttonText} filledButton={true}></Button>
        </View>
    );
};


const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.blue2,
        width: Platform.OS === "web" ? "48%" : "90%",
        marginHorizontal:Platform.OS ==="web" ? 0 : "5%",
		marginBottom: "2%",
		borderRadius: 10,
		paddingBottom: "2%",
		paddingTop: "2%",
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: 'center', 
	},
	title: {
		color: colors.white,
		marginLeft: "2%",
		marginRight: Platform.OS === "web" ? "0%" : "5%",
		marginTop: Platform.OS === "web" ? "0%" : "3%",
		fontFamily: fonts.RalewayBold,
		fontSize: 25,
	},
	description: {
		color: colors.white,
		marginLeft: "5%",
		marginRight: "5%",
		marginTop: Platform.OS === "web" ? "0%" : "5%",
		width: Platform.OS === "web" ? "50%" : "90%",
		fontSize: 15,
		fontFamily: fonts.RalewayRegular,
	},
	button: {
		alignContent: Platform.OS === "web" ? "center" : "flex-end",
		alignSelf: Platform.OS === "web" ? "center" : "flex-end",
		marginRight: "2%",
		marginTop: Platform.OS === "web" ? "0%" : "10%",
		marginBottom: Platform.OS === "web" ? "0%" : "3%",
	},
});

export default Banner;