
import { FilterScreenPropsInterface } from "../../models/models";
import FilterView from "./FilterView";

interface PropsInterface {
	navigation?: any;
	route: {
		params: FilterScreenPropsInterface;
	};
}

const FilterScreen = (props: PropsInterface) => {
	const params = props.route.params;

	return <FilterView
		title={params.title}
		filters={params.filters}
		filterSelected={params.filterSelected}
		navigatePage={params.navigatePage}
		setFilter={params.setFilter}
		isActivity={params.isActivity}
        setOpenActivity={params.setOpenActivity}
        handleBackPress={params.handleBackPress}
        handleOnPress={params.handleOnPress}
	/>
};

export default FilterScreen;
