import { Platform } from "react-native";
import { SaveFile } from "../services/SaveFile";
import { FileInfo, Message } from "../models/chatModels";
import * as Permissions from "expo-permissions";
import * as FileSystem from "expo-file-system";
import { ChatService } from "../services/chat/ChatService";
import { StorageAccessFramework } from 'expo-file-system';
import * as Sharing from 'expo-sharing';

export class FileUtils {
  static getFileExtension(fileName: string): string {
    const fileSplit = fileName.split(".");
    return fileSplit[fileSplit.length - 1];
  };


  static async downloadMobileFile(file: FileInfo) {
    const base64Code = file.data.split("data:application/octet-stream;base64,")[1];
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    if (status === "granted") {
      let fileUri = FileSystem.documentDirectory + file.name;
      await FileSystem.writeAsStringAsync(fileUri, base64Code, { encoding: FileSystem.EncodingType.Base64 });

      if (Platform.OS === "android") {
        const permissions = await StorageAccessFramework.requestDirectoryPermissionsAsync();
        if (!permissions.granted) {
          return;
        }

        try {
          await StorageAccessFramework.createFileAsync(permissions.directoryUri, file.name, file.type)
            .then(async (uri) => {

              await FileSystem.writeAsStringAsync(uri, base64Code, { encoding: FileSystem.EncodingType.Base64 });
            })
        } catch (e) {
          console.log(e)
        }

      }

      if (Platform.OS === "ios") {
        try {
          await Sharing.shareAsync(fileUri)
        } catch (e) {
          console.log(e)
        }
      }


    }
  }

  static async downloadFile(file: FileInfo, message: Message, setLoader?: Function) {
    if (!!file) {

      if (Platform.OS === "web") {
        if (!file.type.includes('image/')) {
          ChatService.downloadFile({ item: { name: file.name, size: file.size!, mimeType: file.type } }, message, (error, fileInfo) => {
            if (error) {
              message.file = null;
              return;
            }

            message.file = fileInfo;
            if (!!setLoader) {
              setLoader(false)
            }
            SaveFile.saveFile(message.file!.data, message.file!.name);
          })
        }
        else {
          SaveFile.saveFile(file.data, file.name)
        }
      }

      else {
        if (!file.type.includes('image/')) {
          ChatService.downloadFile({ item: { name: file.name, size: file.size!, mimeType: file.type } }, message, async (error, fileInfo) => {
            if (error) {
              message.file = null
              return
            }
            message.file = fileInfo;
            if (!!setLoader) {
              setLoader(false)
            }
            FileUtils.downloadMobileFile(message.file!)

          })

        } else {
          FileUtils.downloadMobileFile(file)

        }
      }
    }
  }

}



