import IcEmail from '../../assets/images/ic_mail.svg';
import Svg, { Image } from 'react-native-svg'
import { Platform, View } from "react-native";
import { images } from "../../resources/images";
import { IconProps } from "../../models/models";

const EmailIcon = ({ size = 24 }: IconProps) => {
  return (
    <View >
      {
        Platform.OS === 'web' ?
          <Svg width={`${size}`} height={`${size}`}>
            <Image width={size} height={size} href={images.icMail} />
          </Svg>
          :
          <IcEmail width={size} height={size} />
      }
    </View>
  );
};

export default EmailIcon;
