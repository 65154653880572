import { Text, View } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { Profile } from "../models/models";
import EmailIcon from "./Icons/EmailIcon";
import BirthdayIcon from "./Icons/BirthdayIcon";
import UserGenderIcon from "./Icons/UserGenderIcon";
import ProfileImage from "./ProfileImage";
import { strings } from "../locales/strings";
import { getGenderLabel } from "../utils/UserUtils";
import { formatDate } from "../utils/Date";
import { fonts } from "../resources/fonts";
import { roles } from "../utils/Roles";
import ScopeIcon from "./Icons/ScopeIcon";
import VatIcon from "./Icons/VATIcon";
import FiscalCodeIcon from "./Icons/FiscalCodeIcon";

interface props {
	isWeb?: boolean;
	user: Profile;
	role? : roles;
}

const UserInfo = ({ isWeb = true, user, role }: props) => {
	return (
		<View style={[styles.userInfoContainer, isWeb ? styles.userInfoContainerWeb : null]}>
			<View style={[styles.userInfo, isWeb && styles.autoWidth]}>
				<View style={[styles.userInfoItem, !isWeb && styles.hide]}>
					<EmailIcon />
					<Text style={styles.userInfoItemType}>{strings.email}</Text>
					<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"} numberOfLines={1}>
						{user.email}
					</Text>
				</View>
				<View style={[styles.userInfoItem]}>
					<BirthdayIcon />
					<Text style={styles.userInfoItemType}>{strings.users.bithday}</Text>
					<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"}>
						{!user.birthDate ? strings.notAvalilable : formatDate(user.birthDate)}
					</Text>
				</View>
				{["man", "woman", "other"].includes(user.gender) && (
					<View style={[styles.userInfoItem]}>
						<UserGenderIcon gender={user.gender} />
						<Text style={styles.userInfoItemType}>{strings.gender}</Text>
						<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"}>
							{getGenderLabel(user.gender)}
						</Text>
					</View>
				)}
				{role === roles.externalUser  ? (
					//TODO add icon Field,FiscalCode,VAT
					<>
						<View style={[styles.userInfoItem]}>
							<UserGenderIcon gender={user.gender}/>
							<Text style={styles.userInfoItemType}>{strings.users.field}</Text>
							<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"}>
								{!user.field ? strings.notAvalilable : user.field}
							</Text>
						</View>
						<View style={[styles.userInfoItem]}>
							<UserGenderIcon gender={user.gender} />
							<Text style={styles.userInfoItemType}>{strings.users.fiscalCode}</Text>
							<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"}>
								{!user.fiscalCode ? strings.notAvalilable : user.fiscalCode}
							</Text>
						</View>
						<View style={[styles.userInfoItem]}>
							<UserGenderIcon gender={user.gender} />
							<Text style={styles.userInfoItemType}>{strings.users.VAT}</Text>
							<Text style={styles.userInfoItemVal} ellipsizeMode={"tail"}>
								{!user.VAT ? strings.notAvalilable : user.VAT}
							</Text>
						</View>
					</>

				) : null}
			</View>
			{isWeb && (
				<View style={[styles.userNotes]}>
					<ProfileImage
						firstName={user.firstName}
						lastName={user.lastName}
						profilePicture={user.profilePicture}
						key={"profile-picture"}
						size={180}
						style={styles.userNotesImage}
					/>
					<Text style={[styles.userNotesQuote]}>{user.notes || strings.users.voidNote}</Text>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	userInfoContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginVertical: 40,
		width: "100%",
	},
	userInfoContainerWeb: {
		marginVertical: 60,
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		width: "100%",
	},
	userInfoItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 16,
		width: "100%",
	},
	userInfoIcon: {
		color: colors.blue3,
	},
	userInfoItemType: {
		fontSize: 18,
		marginHorizontal: 8,
		color: colors.grey4,
		fontFamily: fonts.RalewayBold,
	},
	userInfoItemVal: {
		fontSize: 16,
		color: colors.grey4,
		fontFamily: fonts.RalewayRegular,
	},

	userNotes: {
		backgroundColor: colors.grey3,
		display: "flex",
		flexDirection: "row",
		padding: 16,
		alignItems: "center",
		width: '50%'
	},
	userNotesImage: {
		transform: [{ translateX: -106 }],
	},
	userNotesQuote: {
		minHeight: 180,
		width: '100%',
		backgroundColor: colors.white,
		borderRadius: 10,
		padding: 16,
		marginLeft: -90,
		fontFamily: fonts.RalewayRegular
	},
	autoWidth: {
		width: "auto",
	},
	hide: {
		overflow: "hidden",
	},
});

export default UserInfo;
