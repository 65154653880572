import { Platform, Pressable, Text, View, Image, } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { strings } from "../locales/strings";
import { ScrollView } from "react-native-gesture-handler";
import PageNavigation from "../components/PageNavigation";
import FilterButton from "../components/FilterButton";
import Searchbar from "../components/Searchbar";
import Button from "../components/Button";
import { PlacesEndPoint } from "../services/PlacesEndPoint";
import { styles } from "../styles/Places.style";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleUp, faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { images } from "../resources/images";
import { activityFilter, distanceFilter } from "../utils/Filters";
import BasicAlert from "../components/Alerts/BasicAlert";
import { AlertTypes } from "../utils/AlertTypes";
import PlaceManagerAlert from "../components/Places/PlaceManagerAlert";
import { asyncStorageUtils } from "../utils/AsyncStorageUtils";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { roles } from "../utils/Roles";
import { colors } from "../resources/colors";
import Loader from "../components/Loader";
import { Markers, Place, SearchPlacesParams } from "../models/models";
import * as Location from "expo-location";
import { defaultLocation } from "../utils/GoogleUtils";
import { useIsFocused } from "@react-navigation/native";
import Map from "../components/Places/Map";
import PlacesList from "../components/Places/PlacesList";
import EventsList from "../components/Places/EventList";
import { EventsEndPoint } from "../services/EventsEndPoint";
import { getEventDate } from "../utils/EventUtils";
import { PageNames } from "../navigation/NavigationUtils";
import { EventStatus } from "../utils/EventStatus";
import Footer from "../components/Footer";
import { dashboardType } from "../utils/DashboardType";



interface PropsInterface {
	navigation?: any;
}

const PlacesScreen = (props: PropsInterface) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	const focused = useIsFocused();

	const PAGE_SIZE = Infinity;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		if (focused) {
			getCurrentLocation();
			checkRoles("");
			setSearchParameters({ page: 1, pageSize: PAGE_SIZE, name: undefined, distance: undefined, position: undefined });
			setSelectedMarker(null);
		}
		const unsubscribe = props.navigation.addListener("focus", async () => {
			const isLogged = !!await AsyncStorage.getItem(asyncStorageUtils.accessToken);
			setIsLogged(isLogged);
		});
		return unsubscribe;
	}, [focused]);

	const [searchPlace, setSearchPlace] = useState("");
	const [placeManagerVisible, setPlaceManagerVisible] = useState(false);
	const [placeUpdate, setPlaceUpdate] = useState(false);
	const [placeId, setPlaceId] = useState("");
	const [placeManagerId, setPlaceManagerId] = useState("");

	const [showPlaceDetails, setShowPlaceDetails] = useState(false);
	const [expandPlaceDetails, setExpandPlaceDetails] = useState(false);
	const [placeDetails, setPlaceDetails] = useState({
		field: "",
		address: "",
		placeName: "",
		numberOfSeats: 0,
		kitchen: false,
		kitchenNote: "",
		parkingSpots: 0,
		architecturalBarriers: "",
		maxNumberOfUsers: "",
		image: "" as any,
		openingInfo: "",
		note: "",
		placeManager: {
			name: "",
			id: "",
			workspaceEmail: "",
		},
		isPrivatePlace: false,
		activityDetails: "",

	});

	const [error, setError] = useState({ isVisible: false, title: strings.errorTitle, description: strings.errorText });

	const [inProgramEvents, setInProgramEvents] = useState(true);
	const [placesList, setPlacesList] = useState([]);
	const [eventsList, setEventsList] = useState([]);
	const [showFilters, setShowFilters] = useState(true);


	const [selectedMarker, setSelectedMarker] = useState<Markers | null>(null);

	const [activityFilterSelected, setActivityFilterSelected] = useState({ all: true, food: false, wellness: false, culture: false });
	const [field, setField] = useState<string[] | undefined>(undefined);

	const [isLogged, setIsLogged] = useState(false);

	const checkActivityFilter = (all: boolean, food: boolean, wellness: boolean, culture: boolean) => {

		const filterSelected = ({ all: all, food: food, culture: culture, wellness: wellness });

		let fieldFilter = (Object.keys(filterSelected).filter(
			(key) => filterSelected[key as keyof typeof filterSelected]))
		if (fieldFilter.length === 0 || fieldFilter?.includes('all')) {
			setSearchParameters({ page: 1, pageSize: PAGE_SIZE, field: undefined, distance: distanceFilterSelected.value, position: [latitude, longitude] });
			setField(undefined)
			setActivityFilterSelected({ all: true, food: false, culture: false, wellness: false })

		} else {
			setSearchParameters({ page: 1, pageSize: PAGE_SIZE, field: fieldFilter, distance: distanceFilterSelected.value, position: [latitude, longitude] });
			setField(fieldFilter)
			setActivityFilterSelected(filterSelected);

		}
	};


	const [distanceFilterSelected, setDistanceFilterSelected] = useState({ value: NaN });

	const checkDistanceFilter = (value: number) => {

		if (!isNaN(value)) {

			setSearchParameters({ page: 1, pageSize: PAGE_SIZE, distance: value, position: [latitude, longitude], field: field });

		} else {
			setSearchParameters({ page: 1, pageSize: PAGE_SIZE, distance: undefined, position: undefined, field: field });
		}
		const delta = isNaN(value) ? 13 : value;
		setLatitudeDelta(delta * 0.015);
		setLongitudeDelta(delta * 0.015);
		setDistanceFilterSelected({ value });
	};

	const getCurrentLocation = async () => {
		let { status } = await Location.requestForegroundPermissionsAsync();
		if (status !== "granted") {
			console.error("Permission to access location was denied");
			return;
		}

		const currentLocation = await Location.getCurrentPositionAsync({});
		setLatitude(currentLocation.coords.latitude);
		setLongitude(currentLocation.coords.longitude);
	};

	const [searchParameters, setSearchParameters] = useState<SearchPlacesParams>(null as any);

	useEffect(() => {
		setMarkers([]);
		getPlaces();
	}, [searchParameters]);

	useEffect(() => {
		let marks: Markers[] = [];
		placesList.forEach((place: Place) => {
			if (place.coordinates) {
				const placeMarker: Markers = {
					coordinates: {
						latitude: place.coordinates[1],
						longitude: place.coordinates[0],
					},
					title: place.name,
					description: place.address,
					id: place.id!,
					userId: place.placeManager?.id,
				};
				marks.push(placeMarker);
			}
		});
		setMarkers(marks);
	}, [placesList]);

	const getPlaces = async () => {
		setLoaderVisible(true);
		try {
			if (!searchParameters) {
				return;
			}
			const { page, status, total } = await PlacesEndPoint.getPlaces(searchParameters);
			const places = page.items.sort((a: Place, b: Place) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

			setPlacesList(places);
		} catch (e: any) {
			console.error(e);
			setError({ isVisible: true, title: e.status, description: e.error.message });
		} finally {
			if (Platform.OS === "ios") {
				setTimeout(() => {
					setLoaderVisible(false);
				}, 1000);
			} else {
				setLoaderVisible(false);
			}
		}
	};

	const clearFields = () => {
		setSearchPlace("");
		setPlaceManagerVisible(false);
		setPlaceUpdate(false);
		setPlaceId("");
		setPlaceManagerId("");
		setShowPlaceDetails(false);
		setPlaceDetails({
			field: "",
			address: "",
			placeName: "",
			numberOfSeats: 0,
			kitchen: false,
			kitchenNote: "",
			parkingSpots: 0,
			architecturalBarriers: "",
			maxNumberOfUsers: "",
			image: "",
			openingInfo: "",
			note: "",
			placeManager: { name: "", id: "", workspaceEmail: "" },
			isPrivatePlace: false,
			activityDetails: ""

		});
		setError({ isVisible: false, title: strings.errorTitle, description: strings.errorText });
		setInProgramEvents(true);
		setPlacesList([]);
		setEventsList([]);
		setShowFilters(true);
		setActivityFilterSelected({ all: true, food: false, wellness: false, culture: false });
		setDistanceFilterSelected({ value: NaN });
	};

	const search = async (value: string) => {
		setSearchParameters({ page: 1, pageSize: searchParameters.pageSize, name: value, distance: undefined, position: undefined });
	};

	const getPlaceDetails = async (id: string, placeManager: string) => {
		setLoaderVisible(true);
		setPlaceId(id);
		setPlaceManagerId(placeManager);
		try {
			const res = await PlacesEndPoint.getPlaceDetails(id);

			setPlaceDetails({
				field: res.item.field,
				address: res.item.address,
				placeName: res.item.name,
				numberOfSeats: res.item.numberOfSeats,
				kitchen: res.item.kitchen,
				kitchenNote: res.item.kitchenNote,
				parkingSpots: res.item.parkingSpots,
				architecturalBarriers: res.item.barrierArch,
				maxNumberOfUsers: res.item.maxNumberOfUsers,
				image: res.item.imgLinks && res.item.imgLinks[0] ? { uri: res.item.imgLinks[0] } : images.placeImgPlaceholder,
				openingInfo: res.item.opening,
				note: res.item.notes,
				placeManager: {
					name: res.item.placeManager.name,
					id: res.item.placeManager?.id ?? "",
					workspaceEmail: res.item.placeManager?.workspaceEmail ?? "",
				},
				isPrivatePlace: res.item.isPrivatePlace,
				activityDetails: res.item.activityDetails
			});
			setLatitude(res.item.coordinates[1] ?? 0);
			setLongitude(res.item.coordinates[0] ?? 0);
			setShowPlaceDetails(true);
			checkRoles(placeManager);
			getEvents(id);
		} catch (e: any) {
			setError({ isVisible: true, title: e.status, description: e.error.message });
		}
		if (Platform.OS === "ios") {
			setTimeout(() => {
				setLoaderVisible(false);
			}, 1000);
		} else {
			setLoaderVisible(false);
		}
	};

	const [presentEvents, setPresentEvents] = useState([]);
	const [pastEvents, setPastEvents] = useState([]);

	// get all events in that place
	const getEvents = async (placeId: string) => {
		// get present events // FIXME - add filter
		await EventsEndPoint.getEvents({
			page: 1,
			pageSize: 30,
			placeId: placeId,
			ended: false,
			status: [EventStatus.approved, EventStatus.wait_internal_operator, EventStatus.wait_external_operator],
		})
			.then((res: any) => {
				let presentEvents: [] = [];
				res.page.items.forEach((item: any) => {
					const date = getEventDate(item);
					let event = {
						key: item.id,
						name: item.title,
						address: placeDetails.address,
						date: date,
						event: item,
					};
					presentEvents.push(event);
				});
				setEventsList(presentEvents);
				setPresentEvents(presentEvents);
			})
			.catch((e: any) => {
				setError({ isVisible: true, title: e.status, description: e.error.message });
			});

		// get past events // FIXME - add filter
		await EventsEndPoint.getEvents({
			page: 1,
			pageSize: 30,
			placeId: placeId,
			ended: true,
			status: [EventStatus.approved, EventStatus.wait_internal_operator, EventStatus.wait_external_operator],
		})
			.then((res: any) => {
				let pastEvents: [] = [];
				res.page.items.forEach((item: any) => {
					const date = getEventDate(item);
					let event = {
						key: item.id,
						name: item.title,
						address: placeDetails.address,
						date: date,
						event: item,
					};
					pastEvents.push(event);
				});
				setPastEvents(pastEvents);
			})
			.catch((e: any) => {
				setError({ isVisible: true, title: e.status, description: e.error.message });
			});
	};

	const [helper, setHelper] = useState(0);
	const openPlaceManager = async (modify: boolean) => {
		if (modify) {
			setShowPlaceDetails(true);
			setPlaceUpdate(true);
		}

		if (Platform.OS === "web") {
			setPlaceManagerVisible(true);
		} else {
			setHelper(helper + 1);
			const role = await AsyncStorage.getItem(asyncStorageUtils.role);
			props.navigation.navigate(PageNames.placeManager, { helper: helper, update: modify, placeId: placeId, role: role });
		}
	};

	const [ableToAddPlace, setAbleToAddPlace] = useState(false);
	const [ableToModifyPlace, setAbleToModifyPlace] = useState(false);
	const [role, setRole] = useState<roles | undefined>(undefined);

	const checkRoles = async (id: string) => {
		const userRole = await AsyncStorage.getItem(asyncStorageUtils.role);
		const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
		!!userRole ? setRole(userRole as roles) : null;
		switch (userRole) {
			case roles.user || roles.externalUser || roles.internalUser:
				setAbleToAddPlace(false);
				setAbleToModifyPlace(false);
				break;
			case roles.placeManager:
				setAbleToAddPlace(false);
				id === userId ? setAbleToModifyPlace(true) : setAbleToModifyPlace(false);
				break;
			case roles.serviceCoordinator:
				setAbleToAddPlace(true);
				setAbleToModifyPlace(true);
				break;
		}
	};

	const [markers, setMarkers] = useState<Markers[]>([
		{
			coordinates: { latitude: 0, longitude: 0 },
			id: "",
			userId: "",
		},
	]);

	const [latitude, setLatitude] = useState(defaultLocation.latitude);
	const [longitude, setLongitude] = useState(defaultLocation.longitude);
	const [latitudeDelta, setLatitudeDelta] = useState(defaultLocation.latitudeDelta);
	const [longitudeDelta, setLongitudeDelta] = useState(defaultLocation.longitudeDelta);

	const [loaderVisible, setLoaderVisible] = useState(false);
	const [deletedPlace, setDeletedPlace] = useState(false);

	const Places = () => (
		<>
			{Platform.OS === "web" ? <Text style={styles.title}>{strings.placesTitle}</Text> : null}
			<View style={[isWeb ? styles.rowView : null, isWeb ? styles.webMapContainer : styles.mobileMapContainer, !isWeb ? { marginBottom: "5%" } : null]}>
				<View style={isWeb ? styles.placesWeb : styles.placesMobile}>
					{!isWeb && Platform.OS === "web" ? (
						<>
							<View style={styles.mobileSearchbar}>
								<Searchbar placeholder={strings.searchPlace} search={(value: string) => search(value)} objectKey="placeSearchbar" />
							</View>
							<Map
								latitude={latitude}
								longitude={longitude}
								latitudeDelta={latitudeDelta}
								longitudeDelta={longitudeDelta}
								markers={markers}
								placeDetails={placeDetails}
								getPlaceDetails={getPlaceDetails}
								selectedMarker={selectedMarker}
								placesLoading={loaderVisible}
								heightMapMobile={300}
							/>
						</>
					) : null}
					{showPlaceDetails ? (
						<View style={styles.mobileContainer}>
							<Pressable
								style={styles.backContainer}
								onPress={async () => {
									setShowPlaceDetails(false);
									setPlaceId("");
									setPlaceManagerId("");
									setExpandPlaceDetails(false);
									setSelectedMarker(null);
								}}
							>
								<View style={[styles.rowView, { marginLeft: Platform.OS === "web" ? 0 : -10 }]}>
									<FontAwesomeIcon size={15} icon={faArrowLeft}></FontAwesomeIcon>
									<Text style={styles.back}>{strings.back}</Text>
								</View>
							</Pressable>

							{isWeb ? (
								<View style={styles.webImageContainer}>
									<Image style={styles.image} source={placeDetails.image}></Image>
								</View>
							) : null}

							<View style={styles.placeInfo}>
								<View style={{ flexDirection: "row", justifyContent: "space-between", marginRight: 10 }}>
									<Text style={[styles.placeName, { fontSize: 20, marginTop: 5, color: colors.blue, flex: 1 }]}>{placeDetails.placeName}</Text>
									{ableToModifyPlace ? <Button handleButtonPress={() => openPlaceManager(true)} text={strings.modifyPlace} filledButton={false} /> : null}
								</View>
								<Text style={styles.placeInfoDescription}>{placeDetails.address}</Text>

								{expandPlaceDetails ? (

									<>
										{placeDetails.field === dashboardType.food ? (
											<>
												<View style={{ flexDirection: "row", marginTop: 10 }}>
													<Text style={styles.placeInfoTitle}>{strings.field + " :"}</Text>
													{placeDetails.field === dashboardType.food &&
														<Text style={styles.placeInfoDescription}> {strings.foodTitle}
														</Text>}
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.numberOfSeats + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.numberOfSeats}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.parkingSpots + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.parkingSpots}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.maxNumberOfUsers + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.maxNumberOfUsers}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.kitchen + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.kitchen ? strings.yes : strings.no}</Text>
												</View>
												{placeDetails.kitchenNote ? <Text style={styles.placeInfoDescription}>{placeDetails.kitchenNote}</Text> : null}
												{placeDetails.architecturalBarriers ? (
													<View>
														<Text style={styles.placeInfoTitle}>{strings.architecturalBarriers + " :"}</Text>
														<Text style={styles.placeInfoDescription}>{placeDetails.architecturalBarriers}</Text>
													</View>
												) : null}
												{placeDetails.openingInfo ? (
													<View>
														<Text style={styles.placeInfoTitle}>{strings.openingInfo + " :"}</Text>
														<Text style={styles.placeInfoDescription}>{placeDetails.openingInfo}</Text>
													</View>
												) : null}
												{placeDetails.note ? (
													<View>
														<Text style={styles.placeInfoTitle}>{strings.note + " :"}</Text>
														<Text style={styles.placeInfoDescription}>{placeDetails.note}</Text>
													</View>
												) : null}

												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.placeManager + " :"}</Text>
													{isLogged ?
														<Pressable onPress={() => props.navigation.navigate(PageNames.user, { userId: placeDetails.placeManager.id })}>
															<Text style={[styles.placeInfoDescription, { textDecorationLine: "underline" }]}>{placeDetails.placeManager.name}</Text>
														</Pressable>
														:
														<Text style={[styles.placeInfoDescription]}>{placeDetails.placeManager.name}</Text>
													}
												</View>

												{isLogged && <Button
													handleButtonPress={() =>
														props.navigation.navigate(PageNames.calendar, {
															isMyCalendar: false,
															placeId: placeId,
															placeName: placeDetails.placeName,
														})
													}
													text={strings.openPlaceCalendar}
													filledButton={true}
													style={styles.openCalendarButton}
												/>}

												<Pressable onPress={() => setExpandPlaceDetails(false)}>
													<FontAwesomeIcon style={styles.expandIcons} size={15} icon={faAngleUp}></FontAwesomeIcon>
												</Pressable>

											</>
										) : (null)}

										{placeDetails.field === dashboardType.wellness ? (
											<>
												<View style={{ flexDirection: "row", marginTop: 10 }}>
													<Text style={styles.placeInfoTitle}>{strings.field + " :"}</Text>

													{placeDetails.field === dashboardType.wellness &&
														<Text style={styles.placeInfoDescription}> {strings.wellnessTitle}
														</Text>}
												</View>
												<View style={{ flexDirection: "row" }}>
													{placeDetails.isPrivatePlace ? (
														<Text style={styles.placeInfoTitle}>{strings.privatePlace}</Text>

													) : (
														<Text style={styles.placeInfoTitle}>{strings.publicPlace}</Text>
													)}
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.numberOfSeats + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.numberOfSeats}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.activityDetail + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.activityDetails}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.parkingSpots + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.parkingSpots}</Text>
												</View>
												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.maxNumberOfUsers + " :"}</Text>
													<Text style={styles.placeInfoDescription}>{placeDetails.maxNumberOfUsers}</Text>
												</View>
												{placeDetails.note ? (
													<View>
														<Text style={styles.placeInfoTitle}>{strings.note + " :"}</Text>
														<Text style={styles.placeInfoDescription}>{placeDetails.note}</Text>
													</View>
												) : null}

												{placeDetails.openingInfo ? (
													<View>
														<Text style={styles.placeInfoTitle}>{strings.openingInfo + " :"}</Text>
														<Text style={styles.placeInfoDescription}>{placeDetails.openingInfo}</Text>
													</View>
												) : null}


												<View style={{ flexDirection: "row" }}>
													<Text style={styles.placeInfoTitle}>{strings.placeManager + " :"}</Text>
													{isLogged ?
														<Pressable onPress={() => props.navigation.navigate(PageNames.user, { userId: placeDetails.placeManager.id })}>
															<Text style={[styles.placeInfoDescription, { textDecorationLine: "underline" }]}>{placeDetails.placeManager.name}</Text>
														</Pressable>
														:
														<Text style={[styles.placeInfoDescription]}>{placeDetails.placeManager.name}</Text>
													}
												</View>
												{role === roles.serviceCoordinator
													|| (!placeDetails.isPrivatePlace && isLogged) || (role === roles.placeManager) ? <Button
													handleButtonPress={() =>
														props.navigation.navigate(PageNames.calendar, {
															isMyCalendar: false,
															placeId: placeId,
															placeName: placeDetails.placeName,
														})
													}
													text={strings.openPlaceCalendar}
													filledButton={true}
													style={styles.openCalendarButton}
												/> : (null)
												}

												<Pressable onPress={() => setExpandPlaceDetails(false)}>
													<FontAwesomeIcon style={styles.expandIcons} size={15} icon={faAngleUp}></FontAwesomeIcon>
												</Pressable>

											</>
										) : (null)}


									</>
								)


									: (
										<Pressable onPress={() => setExpandPlaceDetails(true)}>
											<FontAwesomeIcon style={styles.expandIcons} size={15} icon={faAngleDown}></FontAwesomeIcon>
										</Pressable>
									)}
								{!isWeb ? (
									<View style={styles.mobileImageContainer}>
										<Image style={styles.image} source={placeDetails.image}></Image>
									</View>
								) : null}
							</View>

							<View>
								<View style={styles.eventsButtonsContainer}>
									<FilterButton
										onChange={() => {
											if (!inProgramEvents) {
												setEventsList(presentEvents);
												setInProgramEvents(!inProgramEvents);
											}
										}}
										text={strings.inProgram}
										selected={inProgramEvents}
									/>
									<FilterButton
										onChange={() => {
											if (inProgramEvents) {
												setEventsList(pastEvents);
												setInProgramEvents(!inProgramEvents);
											}
										}}
										text={strings.pastEvents}
										selected={!inProgramEvents}
									/>
								</View>
								{Platform.OS === "web" ? (
									<ScrollView style={{ height: 250 }}>
										<EventsList navigation={props.navigation} eventsList={eventsList} disableNavigation={!isLogged} />
									</ScrollView>
								) : (
									<EventsList navigation={props.navigation} eventsList={eventsList} disableNavigation={!isLogged} />
								)}
							</View>
						</View>
					) : (
						<View>
							<View style={[styles.rowView, !isWeb ? styles.mobileContainer : {}, { marginTop: 15 }]}>
								{isWeb ? (
									<View style={{ width: "50%", marginLeft: "4%" }}>
										<Searchbar objectKey="placeSearchbar2" placeholder={strings.searchPlace} search={(value: string) => search(value)} />
									</View>
								) : null}
								<View style={{ width: 100, marginTop: 10, marginLeft: Platform.OS === "web" ? 5 : -10 }}>
									<Button handleButtonPress={() => setShowFilters(!showFilters)} text={strings.filter} filledButton={true}></Button>
								</View>
								{ableToAddPlace ? (
									<View style={[{ marginTop: 10 }]}>
										{width >= 768 && width < 1200 ? (
											<Pressable onPress={() => openPlaceManager(false)} style={{ backgroundColor: colors.blue, borderRadius: 30, padding: 10 }}>
												<FontAwesomeIcon color={colors.white} size={15} icon={faPlus}></FontAwesomeIcon>
											</Pressable>
										) : (
											<Button handleButtonPress={() => openPlaceManager(false)} text={strings.add} filledButton={true}></Button>
										)}
									</View>
								) : null}
							</View>

							{showFilters ? (
								<View style={{ backgroundColor: colors.white }}>
									<Text style={styles.placesTitle}>{activityFilter.title + " :"}</Text>
									<ScrollView style={styles.filterList} horizontal={true} showsHorizontalScrollIndicator={false}>
										<FilterButton
											text={activityFilter.filters[0].value}
											selected={activityFilterSelected.all}
											onChange={() => { (!activityFilterSelected.all ? checkActivityFilter(true, false, false, false) : null) }}

										/>
										<FilterButton
											text={activityFilter.filters[1].value}
											selected={activityFilterSelected.food}
											onChange={() => {

												checkActivityFilter(false, !activityFilterSelected.food, activityFilterSelected.wellness, activityFilterSelected.culture)
											}
											}
										/>
										<FilterButton
											text={activityFilter.filters[2].value}
											selected={activityFilterSelected.wellness}
											onChange={() => {

												checkActivityFilter(false, activityFilterSelected.food, !activityFilterSelected.wellness, activityFilterSelected.culture)
											}}
										/>
										{/* TODO activate this and fix api

										<FilterButton
											text={activityFilter.filters[3].key}
											selected={activityFilterSelected.culture}
											onChange={() => checkActivityFilter(false, activityFilterSelected.food, activityFilterSelected.wellness, !activityFilterSelected.culture)}
										/> */}
									</ScrollView>

									<Text style={styles.placesTitle}>{distanceFilter.title + " :"}</Text>
									<ScrollView style={styles.filterList} horizontal={true} showsHorizontalScrollIndicator={false}>
										<FilterButton
											text={distanceFilter.filters[0].key}
											selected={isNaN(distanceFilterSelected.value)}
											onChange={() => checkDistanceFilter(distanceFilter.filters[0].value)}
										/>
										<FilterButton
											text={distanceFilter.filters[1].key}
											selected={distanceFilterSelected.value == distanceFilter.filters[1].value}
											onChange={() => checkDistanceFilter(distanceFilter.filters[1].value)}
										/>
										<FilterButton
											text={distanceFilter.filters[2].key}
											selected={distanceFilterSelected.value == distanceFilter.filters[2].value}
											onChange={() => checkDistanceFilter(distanceFilter.filters[2].value)}
										/>
										<FilterButton
											text={distanceFilter.filters[3].key}
											selected={distanceFilterSelected.value == distanceFilter.filters[3].value}
											onChange={() => checkDistanceFilter(distanceFilter.filters[3].value)}
										/>
									</ScrollView>
								</View>
							) : null}

							<View style={{ paddingTop: 30, backgroundColor: colors.white }}>
								{Platform.OS === "web" ? (
									<ScrollView showsVerticalScrollIndicator={true} style={{ height: 350 }}>
										<PlacesList
											placesList={placesList}
											currentPage={searchParameters?.page}
											getPlaceDetails={getPlaceDetails}
											setSearchParameters={setSearchParameters}
										/>
									</ScrollView>
								) : (
									<PlacesList
										placesList={placesList}
										currentPage={searchParameters?.page}
										getPlaceDetails={getPlaceDetails}
										setSearchParameters={setSearchParameters}
									/>
								)}
							</View>
						</View>
					)}
				</View>
				{isWeb ? (
					<View style={styles.mapWebContainer}>
						<Map
							latitude={latitude}
							longitude={longitude}
							latitudeDelta={latitudeDelta}
							longitudeDelta={longitudeDelta}
							markers={markers}
							placeDetails={placeDetails}
							getPlaceDetails={getPlaceDetails}
							selectedMarker={selectedMarker}
							placesLoading={loaderVisible}
							heightMapMobile={300}
						/>
					</View>
				) : null}
			</View>
		</>
	);

	return (
		<SafeAreaView style={styles.safearea}>
			<Header
				isWeb={isWeb}
				width={width}
				navigation={props.navigation}
				showMenu={isLogged}
				showLoginButton={!isLogged}
				onPress={() => clearFields()}
				setIsLogged={(isLogged: boolean) => setIsLogged(isLogged)}
			/>

			<PlaceManagerAlert
				ableToDelete={ableToAddPlace}
				delete={() => setDeletedPlace(true)}
				update={placeUpdate}
				placeId={placeId}
				modalVisible={placeManagerVisible}
				role={role}
				setPlaceManagerVisible={(isVisible: boolean) => {
					setPlaceManagerVisible(isVisible);
					if (placeUpdate && !isVisible) {
						if (deletedPlace) {
							setShowPlaceDetails(false);
							setDeletedPlace(false);
						} else {
							getPlaceDetails(placeId, placeManagerId);
						}
					}
					setSearchParameters({ page: 1, pageSize: PAGE_SIZE, name: undefined, distance: undefined, position: undefined });
					setTimeout(() => {
						setPlaceUpdate(false);
					}, 1000);
				}}
			/>

			<BasicAlert
				title={error.title}
				description={error.description}
				buttonText={strings.ok}
				alertType={AlertTypes.error}
				alertVisible={error.isVisible}
				setAlertVisible={(isVisible: boolean) => setError({ isVisible: isVisible, title: error.title, description: error.description })}
			/>

			<ScrollView style={Platform.OS === "web" ? { marginTop: 30 } : null} contentContainerStyle={styles.scrollView}>
				<Loader loaderVisible={loaderVisible} setLoaderVisible={(isVisible: boolean) => setLoaderVisible(isVisible)} />

				{Platform.OS === "web" ? <PageNavigation navigationEvent={clearFields} /> : null}

				{!showPlaceDetails && Platform.OS != "web" ? (
					<View style={styles.searchbarMobile}>
						<Searchbar objectKey="placeSearchbar3" placeholder={strings.searchPlace} search={(value: string) => search(value)} />
					</View>
				) : null}

				{Platform.OS !== "web" ? (
					<Map
						latitude={latitude}
						longitude={longitude}
						latitudeDelta={latitudeDelta}
						longitudeDelta={longitudeDelta}
						markers={markers}
						placeDetails={placeDetails}
						getPlaceDetails={getPlaceDetails}
						selectedMarker={selectedMarker}
						placesLoading={loaderVisible}
						heightMapMobile={300}
					/>
				) : null}

				<Places />
				<Footer isWeb={isWeb} width={width} navigation={props.navigation} showFAQ={true} />
			</ScrollView>
		</SafeAreaView>
	);
};

export default PlacesScreen;
