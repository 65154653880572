import React from "react";
import { useState } from "react";
import { Platform, Pressable, View } from "react-native";
import { Text, StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { MenuItems } from "../utils/MenuItems";
import Button from "./Button";
import IcEvent from "../assets/images/ic_event.svg";
import IcAddUser from "../assets/images/ic_add-user.svg";
import IcGroup from "../assets/images/ic_group.svg";
import IcCalendar from "../assets/images/ic_calendar.svg";
import Svg, { Image } from "react-native-svg";

interface IButtonProps {
	buttonText: string;
	title: string;
	text: string;
	iconType: MenuItems;
	imagePath: any;
	onClick: () => void;
}

const HeaderCreationComponent = (props: IButtonProps) => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;
	React.useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
	}, [props]);

	return (
		<Pressable
			style={[styles.container, isWeb ? { width: 180, marginLeft: 5, marginRight: 5 } : { height: "20%", width: "60%", marginBottom: 10 }]}
			onPress={() => props.onClick()}
		>
			<View style={isWeb ? styles.rowView : null}>
				<View style={styles.icon}>
					{Platform.OS === "web" ? (
						<Svg width="30" height="30">
							<Image width={30} height={30} href={props.imagePath} />
						</Svg>
					) : (
						<>
							{props.iconType === MenuItems.addFriend ? <IcAddUser width={30} height={30} /> : null}
							{props.iconType === MenuItems.createEvent ? <IcEvent width={30} height={30} /> : null}
							{props.iconType === MenuItems.createGroup ? <IcGroup width={30} height={30} /> : null}
							{props.iconType === MenuItems.myCalendar ? <IcCalendar width={30} height={30} /> : null}
						</>
					)}
				</View>
				{!isWeb ? (
					<>
						<Text style={styles.text}>{props.text}</Text>
						<Text style={styles.title}>{props.title}</Text>
					</>
				) : null}
				<Button style={isWeb ? { marginLeft: "-0.5rem", width: 120 } : { width: "95%" }} handleButtonPress={() => props.onClick()} text={props.buttonText} filledButton={true} />
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.yellow2,
		borderRadius: 10,
		padding: 10,
		marginBottom: Platform.OS === "web" ? 50 : 0,
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	icon: {
		marginRight: "10%",
	},
	text: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 12,
		marginTop: Platform.OS === "web" ? 20 : "10%",
	},
	title: {
		fontFamily: fonts.RalewayBold,
		fontSize: 16,
		textTransform: "uppercase",
		marginBottom: Platform.OS === "web" ? 20 : Platform.OS === "android" ? 5 : 20,
	},
});

export default HeaderCreationComponent;
