import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import { Platform, Pressable, StyleProp, StyleSheet, Text, TextStyle, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { RangeHours, Weekday, WeekDayTranslations, WorkingDay } from "../utils/WorkingHours";
import Button from "./Button";
import TimePickerComponent from "./Events/TimePickerComponent";

interface props {
	workingDays: WorkingDay[];
	onWorkingDaysChange: (workingDays: WorkingDay[]) => void;
	isWeb: boolean;
	style: StyleProp<TextStyle> | undefined;
}

const workingDaysInit: WorkingDay[] = [
	{
		weekDay: Weekday.MO,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.TU,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.WE,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.TH,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.FR,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.SA,
		selected: false,
		workingHours: [],
	},
	{
		weekDay: Weekday.SU,
		selected: false,
		workingHours: [],
	},
];

const WorkingHours = (props: props) => {
	const [workingDays, setWorkingDays] = useState(workingDaysInit);
	const [width, setWidth] = useState<number>(window.innerWidth);
	const isWeb = width >= 768;

	useEffect(() => {
		if (Platform.OS === "web") {
			window.addEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
		}
		if (props.workingDays?.length > 0) {
			console.log(props.workingDays);
			setWorkingDays([...props.workingDays]);
		}
	}, [props.workingDays]);

	const toggleDaySelection = (day: WorkingDay) => {
		day.selected = !day.selected;
		if (day.selected && day.workingHours.length === 0) {
			day.workingHours = [{ start: { hour: "9", min: "0" }, end: { hour: "18", min: "0" } }];
		}
		setWorkingDays([...workingDays]);
		props.onWorkingDaysChange(workingDays);
	};

	const addWorkingHoursRow = (day: WorkingDay) => {
		const lastWorkingHoursRow = day.workingHours[day.workingHours.length - 1];
		const newWorkingHoursRow: RangeHours = {
			start: {
				hour: "0",
				min: "0",
			},
			end: {
				hour: "0",
				min: "0",
			},
		};

		if (parseInt(lastWorkingHoursRow.end.hour) === 23) {
			newWorkingHoursRow.start.hour = lastWorkingHoursRow.end.hour;
			newWorkingHoursRow.start.min = ((parseInt(lastWorkingHoursRow.end.min) + 1) % 60).toString();
			newWorkingHoursRow.end = {
				hour: "0",
				min: "0",
			};
		} else {
			newWorkingHoursRow.start.hour = ((parseInt(lastWorkingHoursRow.end.hour) + 1) % 24).toString();
			newWorkingHoursRow.end.hour = ((parseInt(lastWorkingHoursRow.end.hour) + 2) % 24).toString();
		}

		day.workingHours.push(newWorkingHoursRow);
		setWorkingDays([...workingDays]);
		props.onWorkingDaysChange(workingDays);
	};

	const removeWorkingHoursRow = (day: WorkingDay, index: number) => {
		day.workingHours.splice(index, 1);
		setWorkingDays([...workingDays]);
		props.onWorkingDaysChange(workingDays);
	};

	const days = () => {
		return (
			workingDays.map((day) => (
				<Button
					key={day.weekDay}
					text={WeekDayTranslations[day.weekDay].charAt(0)}
					filledButton={day.selected}
					handleButtonPress={() => toggleDaySelection(day)}
					style={styles.buttonStyle}
					textStyle={styles.buttonTextStyle}
				/>
			))		
		)
	}

	return (
		<View style={props.style}>
			<View style={styles.toggleContainer}>
				{	isWeb ?
					<> {days()} </>
					:
					<ScrollView horizontal={true} showsHorizontalScrollIndicator={(Platform.OS === "web") ? true : false}>
						{days()}
					</ScrollView>
				}
			</View>
			<View style={styles.hoursContainer}>
				{workingDays.map((day, rowIndex) => {
					return day.selected ? (
						<React.Fragment key={WeekDayTranslations[day.weekDay]}>
							{day.workingHours.map((hours, hourIndex, workingHours) => {
								const isAddButtonDisabled = (parseInt(hours.end.hour) === 23 && parseInt(hours.end.min) >= 58) || parseInt(hours.end.hour) === 0;
								return (
									<View style={[{ zIndex: 9999 - rowIndex * 50 - hourIndex }]}>
										{
											hourIndex === 0 && <Text style={[styles.defaultFont, { minWidth: 90, marginTop: 20 }]}>{WeekDayTranslations[day.weekDay]}</Text>
										}
										<View style={[styles.hoursRowContainer]} key={hourIndex}>
											
											<TimePickerComponent
												isReadOnly={false}
												rangeHours={hours}
												hideLabels={true}
												isWeb={props.isWeb}
												onHoursChange={() => {
													setWorkingDays([...workingDays]);
													props.onWorkingDaysChange(workingDays);
												}}
											/>

											{hourIndex === workingHours.length - 1 ? (
												<View style={{ marginLeft: isWeb ? 30 : 20, marginRight: isWeb ? 10 : 5 }}>
													<Pressable onPress={() => addWorkingHoursRow(day)} disabled={isAddButtonDisabled}>
														<FontAwesomeIcon size={22} icon={faPlusCircle} style={isAddButtonDisabled ? styles.disabledIcon : [false]}></FontAwesomeIcon>
													</Pressable>
												</View>
											) : (
												<View style={{ minWidth: isWeb ? 62 : 0 }}></View>
											)}
											{hourIndex === 0 ? null : (
												<View>
													<Pressable onPress={() => removeWorkingHoursRow(day, hourIndex)}>
														<FontAwesomeIcon size={22} icon={faMinusCircle}></FontAwesomeIcon>
													</Pressable>
												</View>
											)}
											
										</View>
									</View>
								);
							})}
						</React.Fragment>
					) : null;
				})}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	toggleContainer: {
		flexDirection: "row",
	},
	buttonStyle: {
		marginLeft: 2,
		marginRight: 2
	},
	buttonTextStyle: {
		fontSize: 15,
		borderRadius: 50
	},
	hoursContainer: {
		marginTop: 20,
		marginLeft: 5,
		marginRight: 5,
	},
	hoursRowContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10,
		width: "100%"
	},
	disabledIcon: {
		color: colors.grey,
	},
	defaultFont: {
		fontSize: 16,
		fontFamily: fonts.RalewayBold,
	},
});

export default WorkingHours;
