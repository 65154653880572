import { GoogleCalendarEvent, CreateCalendarEventPayload } from "../models/calendarEventsModels";
import { EntityResponse } from "../models/exchange";
import { WorkingHoursDay } from "../models/models";
import { WorkingHoursReponse } from "../utils/WorkingHours";
import { APIBaseService } from "./ApiBaseService";

export class UserCalendarService extends APIBaseService {
	private static readonly baseCalendarUrl = `${APIBaseService.baseUrl}/calendar`;

	public static async getCalendarEvents(userId: string, month: number, year: number) {
		const queryParams = new URLSearchParams({
			month: String(month),
			year: String(year),
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, //resolves to system timezone
		}).toString();

		return APIBaseService.performRequest("GET", `${UserCalendarService.baseCalendarUrl}/user/${userId}/events?${queryParams}`, false);
	}

	public static async createClosedEvent(userId: string, payload: CreateCalendarEventPayload) {
		return APIBaseService.performRequest("POST", `${UserCalendarService.baseCalendarUrl}/user/${userId}/closed-event`, false, payload);
	}

	public static async editClosedEvent(userId: string, eventId: string) {
		return APIBaseService.performRequest("PUT", `${UserCalendarService.baseCalendarUrl}/user/${userId}/closed-event/${eventId}`, false);
	}

	public static async deleteClosedEvent(userId: string, eventId: string) {
		return APIBaseService.performRequest("DELETE", `${UserCalendarService.baseCalendarUrl}/user/${userId}/closed-event/${eventId}`, false);
	}


	// USER WORKING HOURS
	public static async getWorkingHours(userId: string): Promise<EntityResponse<WorkingHoursReponse>> {
		return APIBaseService.performRequest("GET", `${UserCalendarService.baseCalendarUrl}/user/${userId}/working-hours`, false);
	}

	public static async createWorkingHours(userId: string, workingHours: WorkingHoursDay[]): Promise<any> {
		return APIBaseService.performRequest("POST", `${UserCalendarService.baseCalendarUrl}/user/${userId}/working-hours`, false, workingHours);
	}

	public static async editWorkingHours(userId: string, workingHours: WorkingHoursDay[]): Promise<any> {
		return APIBaseService.performRequest("PUT", `${UserCalendarService.baseCalendarUrl}/user/${userId}/working-hours`, false, workingHours);
	}

	public static async deleteWorkingHours(userId: string): Promise<any> {
		return APIBaseService.performRequest("DELETE", `${UserCalendarService.baseCalendarUrl}/user/${userId}/working-hours`, false);
	}
}
