import dayjs from "dayjs";
import { strings } from "../locales/strings";
import { Event, EventActivity } from "../models/eventsModels";
import { images } from "../resources/images";

export const translateEventType = (event: Event) => {
	switch (event.type) {
		case "grocery":
			return strings.groupGrocery;
		case "lunch":
			return strings.lunchTogether;
		default:
			"";
	}
};

export const getEventDate = (event: Event | EventActivity): ActivityDate | null => {
	if (!event.startDate) {
		return null;
	}

	const date = dayjs(event.startDate);

	dayjs.locale("it");

	return {
		day: date.format("D MMMM"),
		hour: date.format("HH:mm"),
	};
};

export const getActivityDate = (value?: Date | null): ActivityDate | null => {
	const date = dayjs(value);
	dayjs.locale("it");
	return {
		day: date.format("D MMMM"),
		hour: date.format("HH:mm"),
	};
};

export const getTypeString = (type: string) => {
	switch (type) {
		case "lunch":
			return strings.lunchTogether;
		case "grocery":
			return strings.groupGrocery;
		default:
			return "";
	}
};

export const getDefaultImageByType = (type: string) => {
	switch (type) {
		case "lunch":
			return images.foodImage;
		case "grocery":
			return images.groceryImage;
		default:
			return images.foodImage;
	}
};

export const getDefaultImageByScope = (type: string) => {
	switch (type) {
		case "food":
			return images.foodImage;
		case "wellness":
			return images.wellnessImage;
		default:
			return images.foodImage;
	}
};
