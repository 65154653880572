import TreeDots from "../../assets/images/ect.svg";
import Svg, { Image } from "react-native-svg";
import { Platform, View, StyleSheet } from "react-native";
import { images } from "../../resources/images";
import { IconProps } from "../../models/models";

const TreeDotsIcon = ({ size = 24 }: IconProps) => {
	return (
		<View>
			{Platform.OS === "web" ? (
				<Svg width={`${size}`} height={`${size}`} style={styles.rotate}>
					<Image width={size} height={size} href={images.treeDots} />
				</Svg>
			) : (
				<TreeDots width={size} height={size} style={styles.rotate} />
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	rotate: {
		transform: [{ rotate: "90deg" }],
	},
});

export default TreeDotsIcon;
