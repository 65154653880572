import { FileChunck, FileInfo } from "../../models/chatModels";

export class ManageFiles {

    static divideFileInChunks = async (fileData: FileInfo, size: number, blob: Blob):  Promise<FileChunck[]> => {
        const fileChunks: FileChunck[] = [];
        const maxBlob = 262144; // each chunk size (2.5MB)
        let offset = 0;

        while (offset < size) {
            const chunkSize = Math.min(maxBlob, size - offset);
            try {
                const chunk = blob.slice(offset, offset + chunkSize);
                fileChunks.push({
                    blob: chunk,
                    start: offset.toString(),
                    end: (offset + chunkSize - 1).toString(),
                });
                offset += chunkSize;
			} catch (error: any) {
				console.log('ERR: ' + error?.message);
			}           
        }
        return fileChunks;
    }

    static divideDownloadFileInChunks = (fileSize: number):  FileChunck[] => {
        const maxBlob = 262144; // each chunk size (2.5MB)
        const fileChunks: FileChunck[] = [];
        let offset = 0;
        while (offset < fileSize) {
            const chunkSize = Math.min(maxBlob, fileSize - offset);
            fileChunks.push({
                blob: '',
                start: offset.toString(),
                end: (offset + chunkSize - 1).toString(),
            });
            offset += chunkSize;
        }
        console.log(fileChunks);
        return fileChunks;
    }
 

    static downloadBrowserFile = (data: any, name: string, type: string | undefined) => {
        const file = new Blob([data], { type });
        const url = window.URL || window.webkitURL;
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("href", url.createObjectURL(file));
        downloadLink.setAttribute("target", "_self");
        downloadLink.setAttribute("download", name);
        downloadLink.click();
      }
} 