
import { useState } from 'react';
import { EventParticipation } from '../../../../utils/types/EventType';
import { EventActivityStatus, EventStatus, respStatus } from '../../../../utils/EventStatus';
import { MacroArea, Place, PlaceImageDocRes, ProfileImageRequest } from '../../../../models/models';
import { CreateEventImage, CreateEventOperator, CreateEventUser, Event, EventActivity } from '../../../../models/eventsModels';
import { Platform } from 'react-native';
import { strings } from '../../../../locales/strings';
import { AlertTypes } from '../../../../utils/AlertTypes';
import { EventsEndPoint } from '../../../../services/EventsEndPoint';
import { roles } from '../../../../utils/Roles';
import { asyncStorageUtils } from '../../../../utils/AsyncStorageUtils';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PageNames } from '../../../../navigation/NavigationUtils';
import React from 'react';
import dayjs from 'dayjs';
import { PlacesEndPoint } from '../../../../services/PlacesEndPoint';
import { UsersEndpoints } from '../../../../services/UsersEndpoints';
import { MacroAreaType } from '../../../../utils/types/MacroAreaType';

interface SearchProps {
    searchTerm: string;
    page: number;
    userFilter: "all" | "following" | "followers";
    sorting: "asc" | "desc";
}

interface PropsInterface {
    navigation?: any;
    showActionSheetWithOptions: any;
    route: {
        params: {
            event: Event;
            userId: string;
            role: roles;
            edit: boolean;
            macroarea: MacroArea;
            page:PageNames;
            filters?:string,
        };
    };
}


const useCreateEventWellnessController = (props: PropsInterface) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const isWeb = width >= 768;
    const propsEvent = props.route?.params?.event;
    const [scrollRefScreen, setScrollRefScreen] = useState<any>(null);
    const [role, setRole] = useState<roles>();
    const [pageSelected, setPageSelected] = useState<number>(1);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [basicAlertValues, setBasicAlertValues] = useState({ isVisible: false, title: "", description: "", type: AlertTypes.success, buttonText: "" });
    const [errorToastVisible, setErrorToastVisible] = useState({ isVisible: false, text: strings.errorPhotoUploaded });
    const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
    const [deleteActivityAlert, setDeleteActivityAlert] = useState<boolean>(false);

    const [macroArea, setMacroArea] = useState<MacroArea>({ key: "", label: "" });
    const [placesList, setPlacesList] = useState<Array<Place>>([]);
    const [invitedUserIds, setInvitedUserIds] = useState<{ items: Array<string> }>({ items: [] });
    const [eventId, setEventId] = useState<string>("");
    const [internalOperator, setInternalOperator] = useState<CreateEventOperator>({ firstName: "", lastName: "" });
    const [externalOperator, setExternalOperator] = useState<CreateEventOperator>({ firstName: "", lastName: "" });
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [internalUsers, setInternalUsers] = useState<Array<CreateEventUser>>([{ label: "", value: "", firstName: "", lastName: "" }]);
    const [showExternalOperatorSelect, setShowExternalOperatorSelect] = useState(false);
    const [externalUsers, setExternalUsers] = useState<Array<CreateEventUser>>([{ label: "", value: "", firstName: "", lastName: "" }]);
    const [validDate, setValidDate] = useState<boolean>(false);
    const [image, setImage] = useState<CreateEventImage>({ img: "", modified: true });
    const [imageObject, setImageObject] = useState<ProfileImageRequest | null>(null);
    const [activities, setActivities] = useState<{ totalCounts: number; items: EventActivity[] }>({ totalCounts: 0, items: [] });
    const [openInternalUserPicker, setOpenInternalUserPicker] = useState<boolean>(false);
    const [openExternalUserPicker, setOpenExternalUserPicker] = useState<boolean>(false);
    const [calendarEventId, setCalendarEventId] = useState<string>("");
    const [placeImages, setPlaceImages] = useState({ img1: "", img2: "", img3: "", img4: "" });




    const [event, setEvent] = useState<Event>({
        userId: "",
        id: "",
        field: "",
        participationType: EventParticipation[0].type,
        title: "",
        img: "",
        description: "",
        operatorId: "", // INTERNAL OPERATOR
        externalOperatorId: "", // EXTERNAL OPERATOR
        placeId: "",
        placeName: "",
        place: {
            _id: "",
            name: "",
            placeManagerId: "",
            coordinates: [null, null],
            calendarId: "",
            workspaceEmail: "",
        },

        listOfInvitedUser: [],
        maxNumberOfUsers: "",
        startDate: null,
        endDate: null,
        status: EventStatus.draft,
        activities: [],
    });

    const [activity, setActivity] = useState<EventActivity>({
        _id: "",
        field: "",
        title: "",
        startDate: null,
        endDate: null,
        place: {
            _id: "",
            name: "",
            placeManagerId: "",
            coordinates: [null, null],
            workspaceEmail: "",
            calendarId: "",
        },
        img: "",
        description: "",
        externalOperator: { id: "", firstName: "", lastName: "" },
        status: undefined,
    });

    const uploadImage = async (eventId: string) => {
        try {
            if (image?.img !== "" && image?.modified) {
                await EventsEndPoint.addPicture(eventId, { fileURI: image.img }).then((res: PlaceImageDocRes) => {
                    setImage({ ...image, modified: false });
                    setEvent({ ...event, img: res.item.link });
                });
            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
        }
    };

    const saveEvent = async (save: boolean, nextStepSave: boolean, deleted: boolean) => {

        let startDate = !!event.startDate ? new Date(event.startDate) : null;
        let endDate = !!event.endDate ? new Date(event.endDate) : null;


        const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);


        const eventInfo: Event = {
            datetime: new Date(),
            id: event.id,
            userId: event.userId ? event.userId : userId ?? "",
            field: event.field,
            participationType: event.participationType,
            title: event.title,
            description: event.description,
            internalOperator: internalOperator.firstName !== "" && internalOperator.lastName !== "" ? {
                id: event.operatorId,
                firstName: internalOperator.firstName,
                lastName: internalOperator.lastName,
            } : undefined,
            externalOperator:
                externalOperator.firstName !== "" && externalOperator.lastName !== "" ? {
                    id: activity?.externalOperatorId,
                    firstName: externalOperator.firstName,
                    lastName: externalOperator.lastName,
                } : undefined,
            // Only one from place or placeFreeText should be defined
            ...event.place?._id && {
                place: {
                    _id: event.place._id,
                    name: event.place.name,
                    placeManagerId: event.place.placeManagerId,
                    coordinates: [event.place.coordinates[0], event.place.coordinates[1]],
                    calendarId: event.place.calendarId,
                    workspaceEmail: event.place.workspaceEmail,
                }
            },
            ...event.placeFreeText && {
                placeFreeText: event.placeFreeText,
            },
            listOfInvitedUser: invitedUserIds.items,
            maxNumberOfUsers: event.maxNumberOfUsers,
            startDate: startDate,
            endDate: endDate,
            status: event.status,
            activities: activities.items,
        };

        switch (true) {
            case deleted: {
                eventInfo.status = EventStatus.deleted;
                deleteEvent(eventInfo);
                break;
            }

            // post DRAFT
            case !props.route.params.edit && save && eventId === "": {
                eventInfo.status = EventStatus.draft;
                // Loader to avoid going to next step before getting id of created event, causing the UI to re-do the create event call
                setLoaderVisible(true);
                EventsEndPoint.createEvent(eventInfo)
                    .then((res) => {
                        uploadImage(res.item._id);
                        setEventId(res.item._id);
                        setEvent({ ...event, id: res.item._id })
                        setCalendarEventId(res.item.calendarEventId)
                        nextStepSave
                            ? null
                            : setBasicAlertValues({ isVisible: true, title: "", description: strings.draftSaved, type: AlertTypes.success, buttonText: strings.close });
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                        }
                    })
                    .catch((e) => {
                        if (Platform.OS === "ios") {
                            setTimeout(() => {
                                setLoaderVisible(false);
                                setBasicAlertValues({ isVisible: true, title: AlertTypes[e?.status as keyof typeof AlertTypes], description: e?.error?.message, type: AlertTypes.error, buttonText: strings.back });
                            }, 500);
                        } else {
                            setLoaderVisible(false);
                            setBasicAlertValues({ isVisible: true, title: AlertTypes[e?.status as keyof typeof AlertTypes], description: e?.error?.message, type: AlertTypes.error, buttonText: strings.back });

                        }
                    });
                break;
            }

            // put TO_BE_APPROVED
            case !props.route.params.edit && !save: {
                eventInfo.status = EventStatus.to_be_approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da DRAFT a TO_BE_APPROVED
            case props.route.params.edit && propsEvent.status === EventStatus.draft && !save: {
                eventInfo.status = EventStatus.to_be_approved;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da TO_BE_APPROVED a WAIT_INTERNAL_OPERATOR
            case props.route.params.edit && propsEvent.status === EventStatus.to_be_approved && !save: {
                eventInfo.status = EventStatus.wait_internal_operator;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }

            // modifica da WAIT_INTERNAL_OPERATOR a WAIT_EXTERNAL_OPERATOR se presente external operator
            case props.route.params.edit &&
                propsEvent.status === EventStatus.wait_internal_operator &&
                !save && !!propsEvent.activities?.some((value) => {
                    return !!value.externalOperator?.id;
                }): {

                    eventInfo.status = EventStatus.wait_external_operator;
                    postEvent(eventInfo, save, nextStepSave);
                    break;
                }

            // modifica da WAIT_INTERNAL_OPERATOR a APPROVED se non presente external operator
            case props.route.params.edit &&
                propsEvent.status === EventStatus.wait_internal_operator
                && !save && !!propsEvent.activities?.every((value) => {

                    return !value.externalOperator?.id;

                }): {
                    eventInfo.status = EventStatus.approved;
                    postEvent(eventInfo, save, nextStepSave);
                    break;
                }
            // modifica da WAIT_EXTERNAL_OPERATOR a APPROVED (da eliminare)
            case props.route.params.edit && propsEvent.status === EventStatus.wait_external_operator && (
                !!propsEvent.activities?.every((value) => {
                    return value.status === EventActivityStatus.APPROVED
                })
            ) && !save: {
                    console.log(event.status)
                    //postEvent(eventInfo, save, nextStepSave);
                    break;
                }

            // JUST SAVE
            default: {
                eventInfo.status = event.status;
                postEvent(eventInfo, save, nextStepSave);
                break;
            }
        }
    };

    const deleteEvent = (eventInfo: any) => {
        eventInfo.status = EventStatus.deleted;
        EventsEndPoint.modifyEvent(eventInfo, eventId)
            .then(() => {
                setBasicAlertValues({ isVisible: true, title: "", description: strings.eventDeleted, type: AlertTypes.success, buttonText: strings.close });
                clearFields();
                props.navigation.navigate(PageNames.home);

            })
            .catch((e) => {
                setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
            });
    };
    const searchActivity = async () => {
        setLoaderVisible(true);
        try {
            if (eventId) {
                const res = await EventsEndPoint.getEvent(eventId);
                setActivities({ totalCounts: res.item.activities.length, items: res.item.activities });

            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: e?.status, description: e?.error?.message, type: AlertTypes.error, buttonText: strings.ok });
            setLoaderVisible(false)
        }
        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
    };
    const deleteActivity = async (id: string) => {
        if (eventId) {

            await EventsEndPoint.deleteEventActivity(eventId!, id)
                .then(() => {
                    setBasicAlertValues({ isVisible: true, title: "", description: strings.eventDeleted, type: AlertTypes.success, buttonText: strings.close });
                    searchActivity();

                })
                .catch((e) => {
                    setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
                });
        }

    };


    const postEvent = (eventInfo: any, save: boolean, nextStepSave: boolean) => {
        console.log("ACTUAL STATUS2 " + eventInfo.status + "   save ?" + save + "edit? " + props.route.params.edit);

        // PUBLISH EVENT
        EventsEndPoint.modifyEvent(eventInfo, eventId)
            .then((res) => {
                uploadImage(res.item.id);

                nextStepSave
                    ? null
                    : setBasicAlertValues({
                        isVisible: true,
                        title: "",
                        description:
                            eventInfo.status === EventStatus.to_be_approved
                                ? strings.eventPublished
                                : eventInfo.status === EventStatus.draft
                                    ? strings.draftSaved
                                    : strings.eventApproved,
                        type: AlertTypes.success,
                        buttonText: strings.close,
                    });
            })
            .catch((e) => {
                nextStepSave
                    ? null
                    : setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
            });

        if (!save) {
            clearFields();
            props.navigation.navigate(PageNames.home);
        }


    };

    const scrollToTop = () => {
        scrollRefScreen && scrollRefScreen.scrollTo({ x: 0, y: 0, animated: true });
    };

    const handleFirstPageForwardButton = () => {
        setPageSelected(2);
        setFirstRender(true);
        searchActivity();
        saveEvent(true, true, false);
        scrollToTop();
    }

    const handleSecondPageBackButton = () => {
        setPageSelected(1);
        scrollToTop();
    }

    const handleSecondPageForwardButton = () => {
        setPageSelected(3);
        scrollToTop();
        saveEvent(true, true, false);
    }

    const handleThirdPageBackButton = () => {
        setFirstRender(true);
        setPageSelected(2);
        scrollToTop();
    }


    const clearFields = () => {
        setEvent({
            userId: "",
            id: "",
            field: !!props.route.params.macroarea ? props.route.params.macroarea.key : "",
            participationType: EventParticipation[0].type,
            title: "",
            img: "",
            description: "",
            operatorId: "", // INTERNAL OPERATOR
            externalOperatorId: "", // EXTERNAL OPERATOR
            placeId: "",
            placeName: "",
            place: {
                _id: "",
                name: "",
                placeManagerId: "",
                coordinates: [null, null],
                calendarId: "",
                workspaceEmail: "",
            },
            placeFreeText:"",
            listOfInvitedUser: [],
            maxNumberOfUsers: "",
            startDate: null,
            endDate: null,
            status: EventStatus.draft,
            activities: []
        });
        !!props.route.params.macroarea ? setMacroArea(props.route.params.macroarea) : setMacroArea({ key: "", label: "" });
        setInvitedUserIds({ items: [] });
        setInternalOperator({ firstName: "", lastName: "" });
        setExternalOperator({ firstName: "", lastName: "" });
        setImage({ img: "", modified: true });
        setImageObject(null);
        setEventId("");
        setCalendarEventId("");
        setValidDate(false);
        setPageSelected(1);
        setPlaceImages({ img1: "", img2: "", img3: "", img4: "" });

        setActivity({
            _id: "",
            field: "",
            title: "",
            startDate: null,
            endDate: null,
            place: {
                _id: "",
                name: "",
                placeManagerId: "",
                coordinates: [null, null],
                workspaceEmail: "",
                calendarId: "",
            },
            img: "",
            description: "",
            externalOperator: { id: "", firstName: "", lastName: "" },
            status: EventActivityStatus.WAIT_EXTERNAL_OPERATOR
        })
        setActivities({ totalCounts: 0, items: [] })
    };

    const flow = async () => {
        try {
            clearFields();
            setLoaderVisible(true);
            const userId = await AsyncStorage.getItem(asyncStorageUtils.userId);
            const role = await AsyncStorage.getItem(asyncStorageUtils.role);
            setPage(props.route.params.page)
            setShowExternalOperatorSelect(role === roles.externalUser || role === roles.internalUser || role === roles.serviceCoordinator);
            setRole(role ? role as roles : undefined);
            setIsReadOnly(props?.route?.params?.edit && props?.route?.params?.event?.status === EventStatus.wait_external_operator);

            // GET INTERNAL USERS
            setShowExternalOperatorSelect(true);
            let internalUsersList: any[] = [];
            const internalUserRes = await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.internalUser], undefined, true);
            internalUserRes.page.items.forEach((value: any) => {
                const user: any = {
                    value: value.id,
                    label: value.firstName + " " + value.lastName,
                    firstName: value.firstName,
                    lastName: value.lastName,
                };
                internalUsersList.push(user);
            });
            setInternalUsers(internalUsersList);

            // GET EXTERNAL USERS
            let externalUsersList: any[] = [];
            if (role === roles.externalUser || role === roles.internalUser || role === roles.serviceCoordinator) {
                const externalUserRes = await UsersEndpoints.getUsers(1, 100, "", "asc", [roles.externalUser], undefined, true);
                externalUserRes.page.items.forEach((value: any) => {
                    const user: any = {
                        value: value.id,
                        label: value.firstName + " " + value.lastName,
                        firstName: value.firstName,
                        lastName: value.lastName,
                    };
                    externalUsersList.push(user);
                });
                setExternalUsers(externalUsersList);
            }

            //CHECK USER
            checkUser();

            // GET PLACES
            const placesRes = props.route.params.edit ? await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined, field: Array(props.route?.params?.event.field) }) :
                await PlacesEndPoint.getPlaces({ page: 1, pageSize: 100, name: undefined, distance: undefined, position: undefined, field: Array(props.route.params.macroarea.key) });
            let placeList = [];

            setPlacesList(placesRes.page.items);
            placeList = placesRes.page.items;

            props.route.params.edit ? editEvent(placeList) : null;
            if (Platform.OS === "ios") {
                setTimeout(() => {
                    setLoaderVisible(false);
                }, 500);
            } else {
                setLoaderVisible(false);
            }
        } catch (e: any) {
            setBasicAlertValues({ isVisible: true, title: ' e?.status', description: e?.error?.message, type: AlertTypes.error, buttonText: strings.close });
            if (Platform.OS === "ios") {
                setTimeout(() => {
                    setLoaderVisible(false);
                }, 500);
            } else {
                setLoaderVisible(false);
            }
        }
    };

    const editEvent = (places: []) => {
        let placeName = "";
        let place = { _id: "", name: "", placeManagerId: "", coordinates: [null, null], calendarId: "", workspaceEmail: "" };
        places.forEach((item: any) => {
            if (propsEvent.place && item.id === propsEvent?.place._id) {
                setPlaceImages({
                    img1: item.imgLinks?.length > 0 ? item.imgLinks[0] : "",
                    img2: item.imgLinks?.length > 1 ? item.imgLinks[1] : "",
                    img3: item.imgLinks?.length > 2 ? item.imgLinks[2] : "",
                    img4: item.imgLinks?.length > 3 ? item.imgLinks[3] : "",
                });
                placeName = item.name;
                place = {
                    _id: item.id,
                    name: item.name,
                    placeManagerId: item.placeManager.id,
                    coordinates: [item.coordinates[0], item.coordinates[1]],
                    calendarId: item.calendarId,
                    workspaceEmail: item.placeManager.workspaceEmail,
                };

            }
        });

        setEventId(propsEvent.id ? propsEvent.id : "");
        setEvent({
            userId: propsEvent?.userId ? propsEvent?.userId : "",
            id: propsEvent?.id ? propsEvent.id : "",
            field: propsEvent?.field ? propsEvent?.field : "",
            participationType: propsEvent?.participationType ? propsEvent.participationType : EventParticipation[0].type,
            title: propsEvent?.title ? propsEvent?.title : "",
            img: propsEvent?.img ? propsEvent?.img : "",
            description: propsEvent?.description ? propsEvent?.description : "",
            operatorId: propsEvent?.internalOperator?.id ? propsEvent?.internalOperator.id : "", // INTERNAL OPERATOR
            externalOperatorId: propsEvent?.externalOperator?.id ? propsEvent?.externalOperator.id : "", // EXTERNAL OPERATOR
            place: {
                _id: place._id,
                name: place.name,
                placeManagerId: place.placeManagerId,
                coordinates: place.coordinates,
                calendarId: place.calendarId,
                workspaceEmail: place.workspaceEmail,
            },
            ...propsEvent.placeFreeText && { placeFreeText: propsEvent.placeFreeText },
            listOfInvitedUser: propsEvent?.listOfInvitedUser ? propsEvent?.listOfInvitedUser : [],
            maxNumberOfUsers: propsEvent?.maxNumberOfUsers ? propsEvent?.maxNumberOfUsers : "",
            startDate: propsEvent?.startDate ? propsEvent?.startDate : null,
            endDate: propsEvent?.endDate ? propsEvent?.endDate : null,
            status: propsEvent.status,
        });
        setValidDate(propsEvent?.startDate ? true : false);
        setImage({ img: propsEvent.img, modified: false });
        setInternalOperator({ firstName: propsEvent.internalOperator?.firstName, lastName: propsEvent.internalOperator?.lastName });
        setExternalOperator({ firstName: propsEvent.externalOperator?.firstName, lastName: propsEvent.externalOperator?.lastName });

        MacroAreaType.forEach((item: any) => {
            item.type === propsEvent?.field ? setMacroArea({ key: item.type, label: item.label }) : null;
        });


        setInvitedUserIds({ items: propsEvent.listOfInvitedUser });

        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }
    };
    const [userId, setUserId] = useState("");
    const [page, setPage] = useState("");
    const [filters,setFilters] = useState();


    const checkUser = async () => {
        const id = await AsyncStorage.getItem(asyncStorageUtils.userId);
        setUserId(id ? id : "");


    };

    const changeActivityStatus = async (id: string) => {
        setLoaderVisible(true)
        if (propsEvent?.id) {
            const status = EventActivityStatus.APPROVED;
            await EventsEndPoint.changeActivityStatus(propsEvent.id, id, status).then((resp: respStatus) => {
                setBasicAlertValues({ isVisible: true, title: "", description: strings.activityApproved, type: AlertTypes.success, buttonText: strings.close });
                !!resp.item.eventStatus ? setEvent({ ...event, status: resp.item.eventStatus }) : null;
                setActivity({ ...activity, status: resp.item.status });
                searchActivity();
            })
                .catch((e) => {
                    setBasicAlertValues({ isVisible: true, title: e.status, description: e.error.message, type: AlertTypes.error, buttonText: strings.close });
                });

        }
        if (Platform.OS === "ios") {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 500);
        } else {
            setLoaderVisible(false);
        }

    }
    React.useEffect(() => {
        if (Platform.OS === "web") {
            window.addEventListener("resize", () => {
                setWidth(window.innerWidth);
            });
        }
        dayjs.extend(require("dayjs/plugin/localeData"));
        dayjs.extend(require("dayjs/plugin/customParseFormat"));
        scrollRefScreen != null ? scrollToTop() : null;
        flow();
    }, [props]);

    return {
        isReadOnly, pageSelected, macroArea, role,
        eventId, event, setEvent, saveEvent, calendarEventId,
        activity, setActivity,
        activities, setActivities,
        loaderVisible, setLoaderVisible,
        basicAlertValues, setBasicAlertValues,
        errorToastVisible, setErrorToastVisible,
        deleteAlert, setDeleteAlert,
        deleteActivityAlert, setDeleteActivityAlert,

        setScrollRefScreen, clearFields,


        // FIRST PAGE
        placesList, setPlacesList,
        validDate, setValidDate,
        image, setImage, uploadImage,
        handleFirstPageForwardButton,
        setPlaceImages, placeImages,

        // SECOND PAGE
        handleSecondPageBackButton, handleSecondPageForwardButton,
        firstRender, setFirstRender,
        deleteActivity, searchActivity, changeActivityStatus,
        propsEvent,
        internalOperator, setInternalOperator,
        externalOperator, setExternalOperator,
        internalUsers, externalUsers,
        openInternalUserPicker, setOpenInternalUserPicker,
        openExternalUserPicker, setOpenExternalUserPicker,
        userId,


        // THIRD PAGE
        invitedUserIds, setInvitedUserIds,
        handleThirdPageBackButton,page,filters
    };
};

export default useCreateEventWellnessController;

