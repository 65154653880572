import { APIBaseService } from "./ApiBaseService";

export class NotificationEndPoints extends APIBaseService {
	public static async getCountNotifications(): Promise<any> {
		return await APIBaseService.performRequest("get", `${APIBaseService.baseUrl}/notifications`, false);
	}
	public static async deleteNotifications(): Promise<any> {
		return await APIBaseService.performRequest("delete", `${APIBaseService.baseUrl}/notifications`, false);
	}
}
