import { FlatList, Platform, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { SearchPlacesParams } from "../../models/models";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/Places.style";

interface PropsInterface {
	placesList: any;
	currentPage: number;
	getPlaceDetails: (itemId: string, placeManagerId: string) => any;
	setSearchParameters: (searchItem: SearchPlacesParams) => any;
}

const PlacesList = (props: PropsInterface) => {
	return (
		<>
			{props.placesList.length > 0 ? (
				<>
					<FlatList
						style={[{ paddingBottom: 50, backgroundColor: colors.white }, props.placesList && props.placesList.length < 3 ? { height: 400 } : null]}
						data={props.placesList}
						showsVerticalScrollIndicator={false}
						renderItem={({ item }) => (
							<Pressable style={styles.placeContainer} onPress={() => props.getPlaceDetails(item.id, item.placeManager.id)}>
								<Text style={[styles.placeName, { color: colors.blue }]}>{item.name}</Text>
								<Text style={styles.placeAddress}>{item.address}</Text>
							</Pressable>
						)}
					/>
				</>
			) : (
				<View style={{ height: 300 }}>
					<Text style={styles.emptyState}>{strings.emptyPlaces}</Text>
				</View>
			)}
		</>
	);
};

export default PlacesList;
