import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useEffect, useRef } from "react";
import { FlatList, Platform, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { PageNames } from "../../navigation/NavigationUtils";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/CreateEventScreen";
import { useNavigation } from "@react-navigation/native";

interface PropsInterface {
	typology: any;
	event: any;
	setEvent: (value: any) => void;
	openInternalUserPicker: boolean;
	userSelected: any;
	setOpenInternalUserPicker: (value: boolean) => void;
	internalUsers: any;
	setUserSelected: (firstName: string, lastName: string) => void;
	isInternal: boolean;
    isReadOnly: boolean;
    pageName: string;
    obligatory?: boolean;
    handleOnPress?: () => void;
}

const SelectOperator = (props: PropsInterface) => {
    const navigation = useNavigation<any>();
	const dropdownRef = useRef<View>(null);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				props.setOpenInternalUserPicker(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);

	const getList = (): { type: string; label: string }[] => {
		let list: { type: string; label: string }[] = [];
		props.internalUsers.forEach((item: any) => {
			list.push({ type: item.value, label: item.label });
		});
		return list;
	};

	return (
		<View ref={dropdownRef}>
			<Text style={[styles.fieldTitle, { marginTop: 20 }]}>{props.isInternal ? props.obligatory ? strings.referenceOperator + " *": strings.referenceOperator: strings.externalOperator}</Text>

			<Pressable
				style={[styles.rowView]}
                onPress={() => {
                    if (!props.isReadOnly) {
                        if (props.handleOnPress && Platform.OS !== 'web') {
                            props.handleOnPress();
                        } else {
                            if (Platform.OS === "web") {
                                props.setOpenInternalUserPicker(!props.openInternalUserPicker);
                            } else {
                                navigation.navigate(PageNames.filter, {
                                    title: props.isInternal ? strings.referenceOperator : strings.externalOperator,
                                    filters: getList(),
                                    filterSelected: props.isInternal ? props.event.operatorId : props.event.externalOperatorId,
                                    navigatePage: props.pageName,
                                    setFilter: (filter: any) => {
                                        props.isInternal
                                            ? props.setEvent({ ...props.event, operatorId: filter.value })
                                            : props.setEvent({ ...props.event, externalOperatorId: filter.value });
                                        props.internalUsers.forEach((item: any) => (item.value === filter.type ? props.setUserSelected(item.firstName, item.lastName) : null));
                                    },
                                });
                            }
                        }
                    }
				}}
			>
				<Text
					style={[
						styles.selectTitle,
						(props.isInternal && props.event.operatorId != "") || (!props.isInternal && props.event.externalOperatorId != "")
							? { color: colors.black }
							: { color: colors.grey },
						{ marginRight: 0 },
						props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
					]}
				>
					{(props.isInternal && props.event.operatorId != "") || (!props.isInternal && props.event.externalOperatorId != "")
						? props.userSelected.firstName + " " + props.userSelected.lastName
						: strings.select}
				</Text>
				{props.openInternalUserPicker ? (
					<FontAwesomeIcon style={[styles.selectIcon]} size={15} icon={faAngleUp}></FontAwesomeIcon>
				) : (
					<FontAwesomeIcon style={[styles.selectIcon]} size={15} icon={faAngleDown}></FontAwesomeIcon>
				)}
			</Pressable>
			{props.openInternalUserPicker ? (
				<View style={[styles.selectComponent, { marginTop: 98 }]}>
					<FlatList
						style={{ height: 140, zIndex: 4 }}
						data={props.internalUsers}
						renderItem={({ item, index }) => {
							return (
								<Pressable
									style={[styles.rowView, { borderBottomColor: colors.grey3, borderBottomWidth: 1, zIndex: 9999 }]}
									onPress={() => {
										if ((props.isInternal && props.event.operatorId === item.value) || (!props.isInternal && props.event.externalOperatorId === item.value)) {
											props.isInternal ? props.setEvent({ ...props.event, operatorId: "" }) : props.setEvent({ ...props.event, externalOperatorId: "" });
											props.setUserSelected("", "");
										} else {
											props.isInternal
												? props.setEvent({ ...props.event, operatorId: item.value })
												: props.setEvent({ ...props.event, externalOperatorId: item.value });
											props.setUserSelected(item.firstName, item.lastName);
										}
										props.setOpenInternalUserPicker(false);
									}}
								>
									<View>
										<Text style={[styles.placeName, { paddingBottom: 10 }]} numberOfLines={1}>
											{item.label}
										</Text>
									</View>
									{(props.isInternal && item.value === props.event.operatorId) || (!props.isInternal && item.value === props.event.externalOperatorId) ? (
										<FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon>
									) : null}
								</Pressable>
							);
						}}
						keyExtractor={(item) => item.value}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
			) : null}
		</View>
	);
};

export default SelectOperator;
