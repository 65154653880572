import { Platform, Pressable, View, Text } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { useState } from "react";
import { strings } from "../locales/strings";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import SelectMultiple from "react-native-select-multiple";
import { rolesList } from "../utils/Roles";
import { Menu, MenuItem } from "react-native-material-menu";
import { RoleOption, Roles } from "../models/models";

interface props {
	setWebRoles: (value: Array<Roles> | undefined) => void;
	setMobileRole1: (value: Roles | undefined) => void;
	setMobileRole2: (value: Roles | undefined) => void;
	isVisible: (value: any) => void;
	value?: Array<Roles | undefined>;
}

const MultiselectComponent = (props: props) => {
	// web
	const [roleMenuVisible, setRoleMenuVisible] = useState(false);
	const [selectedItems, setSelectedItems] = useState(props.value || []);

	// mobile
	const prefix = rolesList;
	const [mobileRole1, setMobileRole1] = useState(props.value && props.value[0]);
	const [mobileRole2, setMobileRole2] = useState(props.value && props.value[1]);

	const hideRoleMenu = (prefix?: RoleOption) => {
		console.log(prefix, mobileRole1, mobileRole2);

		if (prefix) {
			switch (true) {
				case !!mobileRole1 && !mobileRole2 && prefix.value != mobileRole1:
					setMobileRole2(prefix.value);
					props.setMobileRole2(prefix.value);
					break;
				case !!mobileRole1 && prefix.value == mobileRole1:
					if (!!mobileRole2) {
						setMobileRole1(mobileRole2);
						props.setMobileRole1(mobileRole2);
						setMobileRole2(undefined);
						props.setMobileRole2(undefined);
					} else {
						setMobileRole1(undefined);
						props.setMobileRole1(undefined);
					}
					break;
				case !mobileRole1:
					setMobileRole1(prefix.value);
					props.setMobileRole1(prefix.value);
					break;
				case !!mobileRole1 && !!mobileRole2 && prefix.value == mobileRole2:
					setMobileRole2(undefined);
					props.setMobileRole2(undefined);
					break;
			}
		} else {
			setRoleMenuVisible(false);
		}
	};

	const isWeb = Platform.OS === "web";
	const webText1 = rolesList.find((i) => i.value == selectedItems[0])?.label || strings.selectRole;
	const webText2 = rolesList.find((i) => i.value == selectedItems[1])?.label || "";
	const mobileText1 = rolesList.find((i) => i.value == mobileRole1)?.name || strings.selectRole;
	const mobileText2 = rolesList.find((i) => i.value == mobileRole2)?.name || "";
	const selectRoleLabelShown = isWeb ? selectedItems.length == 0 || selectedItems == null : !mobileRole1;

	const CustomInputWeb = () => (
		<Pressable
			style={styles.rowView}
			onPress={() => {
				props.isVisible(!roleMenuVisible);
				setRoleMenuVisible(!roleMenuVisible);
			}}
		>
			<Text style={[styles.roleText, !selectRoleLabelShown ? { color: colors.black } : { color: colors.grey }]}>
				{isWeb ? [webText1, webText2].join(", ") : [mobileText1, mobileText2].join(", ")}
			</Text>
			{roleMenuVisible ? (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleUp}></FontAwesomeIcon>
			) : (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleDown}></FontAwesomeIcon>
			)}
		</Pressable>
	);

	return (
		<View>
			<CustomInputWeb />
			{Platform.OS === "web" ? (
				<>
					{roleMenuVisible ? (
						<View style={styles.multiselect}>
							<SelectMultiple
								nestedScrollEnabled={false}
								items={rolesList}
								selectedItems={selectedItems}
								maxSelect={2}
								onSelectionsChange={(selected: any) => {
									const selects = selected.map((s: { label: string; value: string }) => s.value);
									props.setWebRoles(selects);
									setSelectedItems(selects);
								}}
							/>
						</View>
					) : null}
				</>
			) : (
				<>
					<Menu visible={roleMenuVisible} onRequestClose={() => hideRoleMenu()}>
						<MenuItem style={{ width: 300 }} onPress={() => hideRoleMenu(prefix[0])}>
							{prefix[0].name}
						</MenuItem>
						<MenuItem onPress={() => hideRoleMenu(prefix[1])}>{prefix[1].name}</MenuItem>
						<MenuItem onPress={() => hideRoleMenu(prefix[2])}>{prefix[2].name}</MenuItem>
						<MenuItem onPress={() => hideRoleMenu(prefix[3])}>{prefix[3].name}</MenuItem>
					</Menu>
				</>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginHorizontal: 16,
	},
	roleContainer: {
		fontFamily: fonts.RalewayRegular,
		backgroundColor: colors.white,
		color: colors.black,
		padding: 15,
		width: "90%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
	},
	multiselect: {
		marginLeft: "5%",
		marginRight: "5%",
		width: "90%",
		backgroundColor: colors.white,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingBottom: 0,
		borderRadius: 10,
		position: "absolute",
		marginTop: 50,
		zIndex: 9999,
		paddingTop: 5,
	},
	roleText: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 30,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: colors.white,
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
	},
	roleIcons: {
		right: 20,
		top: 18,
		zIndex: 9999,
		position: Platform.OS === "web" ? "absolute" : "relative",
	},
});

export default MultiselectComponent;
