import { Platform, Pressable, View, Text, FlatList, TouchableOpacity } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { fonts } from "../resources/fonts";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "react-native-material-menu";

type ValueType = string | number;

export type SelectOption<T> = {
	value: T;
	label: string;
}

interface props<T> {
	options: SelectOption<T>[];
	onChange: (value: T) => void;
	value?: T;
	placeholder?: string;
	disabled?: boolean;
}

const Select = <T extends ValueType>({ options, onChange, value, placeholder = "", disabled = false }: props<T>) => {
	const [visible, setVisible] = useState(false);
	const hideMenu = () => setVisible(false);

	const handleChange = (value: T) => {
		onChange(value);
		setVisible(false);
	};

	const dropdownRef = useRef<View>(null);

	useEffect(() => {
		if (Platform.OS === "web") {
			const handler = (event: MouseEvent) => {
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					setVisible(false)
				}
			};

			document.addEventListener("mousedown", handler);

			return () => {
				document.removeEventListener("mousedown", handler);
			};
		}
	}, []);

	const CustomInputWeb = () => (
		<Pressable
			style={[styles.rowView, styles.width100]}
			onPress={() => {
				!disabled && setVisible(!visible);
			}}
		>
			<Text style={[styles.text, !disabled ? value !== "" ? { color: colors.black } : { color: colors.grey } : { color: colors.grey }]}>
				{options.find((o) => o.value === value)?.label || placeholder}
			</Text>
			{visible ? (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleUp}></FontAwesomeIcon>
			) : (
				<FontAwesomeIcon style={styles.roleIcons} size={15} icon={faAngleDown}></FontAwesomeIcon>
			)}
		</Pressable>
	);

	const renderWebItem = ({
		item,
	}: {
		item: {
			value: T;
			label: string;
		};
	}) => (
		<TouchableOpacity style={styles.dropdownItem} onPress={() => handleChange(item.value)}>
			<Text style={styles.menuItem}>{item.label}</Text>
		</TouchableOpacity>
	);

	return (
		<View ref={dropdownRef} style={styles.width100}>
			<CustomInputWeb />
			{Platform.OS === "web" ? (
				<View>
					{visible && <FlatList style={[styles.dropdown]} data={options} renderItem={renderWebItem} keyExtractor={(item, index) => item.value.toString()} />}
				</View>
			) : (
				<Menu visible={visible} onRequestClose={hideMenu} style={styles.menu}>
					{options.map(({ label, value }) => (
						<MenuItem key={value} onPress={() => handleChange(value)}>
							<Text style={styles.menuItem}>{label}</Text>
						</MenuItem>
					))}
				</Menu>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	width100: {
		zIndex: 3,
		width: Platform.OS === "web" ? undefined : '100%'
	},
	rowView: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginRight: 16

	},
	menu: {
		alignItems: "center",
		justifyContent: "center",
		borderColor: colors.grey4Border,
		marginLeft: "3%",
		marginRight: "3%",
	},
	text: {
		fontFamily: fonts.RalewayRegular,
		fontSize: 14,
		paddingLeft: 10,
		paddingRight: 45,
		paddingTop: 15,
		paddingBottom: 15,
		backgroundColor: colors.white,
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		height: 50,
	},
	roleIcons: {
		right: 20,
		top: 16,
		zIndex: 9999,
		position: Platform.OS === "web" ? "absolute" : "relative",
	},
	menuItem: {
		fontFamily: fonts.RalewayRegular,
	},
	dropdown: {
		position: "absolute",
		backgroundColor: colors.white,
		borderRadius: 10,
		paddingTop: 5,
		width: "95%",
		shadowColor: colors.grey4,
		shadowRadius: 4,
		shadowOffset: { height: 4, width: 0 },
		shadowOpacity: 0.5,
		zIndex: 2,
		elevation: 50,
	},
	dropdownItem: {
		padding: 8,
	},
});

export default Select;
