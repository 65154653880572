import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { colors } from "../../resources/colors";
import { fonts } from "../../resources/fonts";
import CustomTextInput from "../CustomTextInput";

interface PropsInterface {
	objectKey: string;
	value: string;
	placeholder: string;
	title: string;
	isReadOnly: boolean;
	setValue: (value: string) => void;
}

const ScopeListMenu = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	return (
		<View style={{ marginRight: 20 }}>
			{props.isReadOnly ? (
				<>
					<Text style={[styles.fieldTitle, { marginTop: 20 }]}>{props.title}</Text>
					<Text style={styles.readOnlyText}>{props.value}</Text>
				</>
			) : (
				<CustomTextInput
					maxLenght={500}
					key={props.objectKey}
					numberOfLine={10}
					value={props.value}
					placeholder={props.placeholder}
					title={props.title}
					obligatory={false}
					styleText={[
						{ marginLeft: 0, marginRight: 0, fontFamily: fonts.RalewayRegular },
						props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
					]}
					styleTextInput={{ marginLeft: 0, marginRight: 0, width: 280, height: 300 }}
					setValue={(value: string) => props.setValue(value)}
				/>
			)}
		</View>
	);
};

export default ScopeListMenu;

const styles = StyleSheet.create({
	readOnlyText: {
		width: 280,
		height: 300,
		backgroundColor: colors.greyReadOnly,
		color: colors.black,
		borderRadius: 10,
		fontSize: 14,
		padding: 15,
		marginTop: 10,
		fontFamily: fonts.RalewayRegular,
		borderColor: colors.grey,
		borderWidth: 1,
		opacity: 0.8,
	},
	fieldTitle: {
		fontFamily: fonts.RalewayBold,
		fontSize: 16,
		marginTop: 10,
	},
});
