import { strings } from "../../locales/strings";

export const EventType = [
	{
		type: "lunch",
		label: strings.lunchTogether,
	},
	{
		type: "grocery",
		label: strings.groupGrocery,
	},
];


export enum EventParticipationType {
	inPerson = 'inPerson',
	online = 'online',
	both = 'both',
}
export const EventParticipation  = [
	{
		type: EventParticipationType.inPerson,
		label: strings.inPerson
	},
	{
		type: EventParticipationType.both,
		label:strings.both
	},
	{
		type:EventParticipationType.online,
		label: strings.online,
	}
]

export enum EventTypeEnum {
	lunch = "lunch",
	grocery = "grocery",
}
