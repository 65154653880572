import { Platform, Pressable, TextInput, View } from "react-native";
import { StyleSheet } from "react-native";
import { colors } from "../resources/colors";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { fonts } from "../resources/fonts";
import { useState } from "react";

interface props {
	placeholder: string;
	objectKey?: string;
	search: (value: string) => void;
}

const Searchbar = ({ placeholder, search, objectKey }: props) => {
	const [value, setValue] = useState("");

	return (
		<View style={[styles.container]}>
			<View style={[styles.inputContainer]}>
				<TextInput
					maxLength={100}
					autoCapitalize="none"
					numberOfLines={1}
					style={[styles.textInput, Platform.OS === "web" ? ({ outlineWidth: 0 } as any) : null]}
					onChangeText={(value) => setValue(value)}
					value={value}
					placeholder={placeholder}
					placeholderTextColor={colors.grey}
					key={objectKey ?? "searchbar"}
					onSubmitEditing={() => search(value)}
				/>
				<Pressable style={styles.icon} onPress={() => search(value)}>
					<FontAwesomeIcon size={20} icon={faSearch} />
				</Pressable>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: 10,
	},
	textInput: {
		backgroundColor: colors.white,
		color: colors.black,
		width: "100%",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		height: 35,
		fontFamily: fonts.RalewayRegular,
	},
	inputContainer: {
		backgroundColor: colors.white,
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 10,
		borderColor: colors.grey,
		borderWidth: 1,
		paddingRight: "15%",
	},
	icon: {
		position: "absolute",
		right: 15,
	},
});

export default Searchbar;
