import { io } from 'socket.io-client';
import { ChatMember, FileInfo, Message } from '../../models/chatModels';
import { Meet } from '../../models/models';
import { ChatStatus } from '../../utils/ChatUtils';
import { UsersEndpoints } from '../UsersEndpoints'
import { ChatService } from './ChatService';
export class Socket {
    socket: any;
    messages: Message[];
    chatMembers: ChatMember[];
    statusPrivateChat: ChatStatus = ChatStatus.away; // available only in private chat
    userId: string = ''; // user id in private chat

    constructor(token: string, userId?: string) {
        this.messages = [];
        this.chatMembers = [];
        if (userId) {
            this.userId = userId;
        }

        this.socket = io(process.env.CHAT_BASE_URL!, {
            transports: ['websocket'],
            auth: {
                token: "Bearer " + token
            }
        });

        this.socket.on("connect_error", (error: any) => {
            console.log("socker error: " + JSON.stringify(error));
        });

    }

    public addHistory = async (messageHistory: Message[] | undefined, callback: (messages: Message[]) => void, members?: string[], reload?: Function) => {
        if (members) {
            for (const el of members) {
                const userInfo = await UsersEndpoints.getUser(el);
                this.chatMembers.push({
                    userId: userInfo.item.id ?? '',
                    firstName: userInfo.item.firstName,
                    lastName: userInfo.item.lastName,
                    profileImage: userInfo.item.profilePicture,
                    isOnline: false
                })
            };
        }
        if (!!messageHistory && messageHistory.length > 0) {
            for (const el of messageHistory) {
                const info = await this.fillUserInformations(el, reload);
                this.messages.push(info);
            };
        }
        callback(this.messages);
    }

    // add messages when load more
    public addMessages = async (messageHistory: Message[] | undefined, callback: (messages: Message[]) => void, reload?: Function) => {
        if (!!messageHistory && messageHistory.length > 0) {
            for (const el of messageHistory) {
                this.messages.unshift(await this.fillUserInformations(el, reload));
            };
        }
        callback(this.messages);
    }


    public fillUserInformations = async (message: Message, reload?: Function): Promise<Message> => {
        const index = this.chatMembers.findIndex(obj => {
            return obj.userId === message.author;
        });
        message.firstName = this.chatMembers[index].firstName;
        message.lastName = this.chatMembers[index].lastName;
        message.profileImage = this.chatMembers[index].profileImage;
        if (!!message.attachmentId) {
            ChatService.getInfoFile(message.attachmentId!, message.author)
                .then(res => {
                    if (res.item.mimeType.includes('image/')) {
                        ChatService.downloadFile(res, message, (error, fileInfo) => {
                            if (error) {
                                message.file = null;
                                return;
                            }
                            message.file = fileInfo;
                            if(!!reload) {
                                reload(Math.random())
                            }
                        })
                    }
                    message.file = {
                        name: res.item.name,
                        size: res.item.size,
                        type: res.item.mimeType,
                        fileUri: ""
                    }


                }).catch(error => {
                    message.file = null;
                    if(!!reload) {
                        reload(Math.random())
                    }
                    return
                }).finally(() => {
                    if(!!reload) {
                        reload(Math.random())
                    }
                })


        }

        return message;
    }

    public setMessageList = (listOfMessages: Message[]) => {
        this.messages = listOfMessages;
    }

    public connectToRoom(roomId: string) {
        this.socket.on("exception", (error: any) => {
            console.log('socker error: ' + JSON.stringify(error));
        });

        this.socket.on("connect", () => {
            this.socket.emit('join', roomId);
        });

        this.socket.on("connected-users", (users: any) => {
            console.log('connected-users: ' + JSON.stringify(users));
            if (!!users[this.userId.toString()]) {
                this.statusPrivateChat = (users[this.userId.toString()] === true) ? ChatStatus.active : ChatStatus.away;
            }
        });

        this.socket.on("user-connected", (user: string) => {
            console.log('user-connected: ' + user);
            if (user === this.userId) {
                this.statusPrivateChat = ChatStatus.active;
            }
        });

        this.socket.on("user-disconnected", (user: string) => {
            console.log('user-disconnected: ' + user);
            if (user === this.userId) {
                this.statusPrivateChat = ChatStatus.away;
            }
        });
    }

    public sendMessageToRoom(message: string, attachmentId?: string) {
        if (!!attachmentId) {
            console.log("send message " + JSON.stringify({ content: message, attachmentId: attachmentId }))
            this.socket.emit("message", { content: message, attachmentId: attachmentId });
        } else {
            console.log("send message " + JSON.stringify({ content: message }))
            this.socket.emit("message", { content: message })
        }
    }

    public sendMeetToRoom(meet: Meet) {
        console.log("send message " + JSON.stringify({ id: meet.id, link: meet.link }))
        this.socket.emit("message", { meet: { id: meet.id, link: meet.link } })
    }

    public getMessages(callback: (message: Message[]) => void, reload?: Function) {
        this.socket.on("message", async (data: Message) => {
            console.log("get message " + JSON.stringify(data))
            const info = await this.fillUserInformations(data, reload);
            this.messages.push(info);
            callback(this.messages);
        });
    }

    public deleteMessage(idMessage: string) {
        this.socket.emit("delete-message", { id: idMessage });
    }

    public editMessage(idMessage: string, content: string, attachmentId?: string) {
        if (!!attachmentId) {
            console.log("edit message " + JSON.stringify({ id: idMessage, content: content, attachmentId: attachmentId }))
            this.socket.emit("edit-message", { id: idMessage, content: content, attachmentId: attachmentId });
        } else {
            console.log("edit message " + JSON.stringify({ id: idMessage, content: content, attachmentId: attachmentId }))
            this.socket.emit("edit-message", { id: idMessage, content: content });
        }
    }

    public disconnect() {
        this.socket.offAny();
    }

    public readRoomMessages() {
        this.socket.emit("read-messages")
    }
}