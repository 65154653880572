import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { FlatList, Platform, Pressable, Text, View } from "react-native";
import { strings } from "../../locales/strings";
import { PageNames } from "../../navigation/NavigationUtils";
import { colors } from "../../resources/colors";
import { styles } from "../../styles/CreateEventScreen";

interface PropsInterface {
	navigation: any;
	event: any;
	openParticipationTypeComponent: boolean;
	setOpenParticipationComponent: (value: boolean) => void;
	setEvent: (value: any) => void;
	setParticipationType: (value: any) => void;
	participationType: any;
	selectParticipationType: (value: any) => void;
	isReadOnly: boolean;
    list: any;
    pageName: string;
}

const ParticipationTypeComponent = (props: PropsInterface) => {
	React.useEffect(() => {}, [props]);

	const dropdownRef = useRef<View>(null);
	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				props.setOpenParticipationComponent(false);
			}
		};

		Platform.OS === "web" && document.addEventListener("mousedown", handler);

		return () => {
			Platform.OS === "web" && document.removeEventListener("mousedown", handler);
		};
	}, []);

	return (
		<View ref={dropdownRef} style={{ zIndex: 9999 }}>
			<Pressable
				style={styles.rowView}
				onPress={() =>
					!props.isReadOnly
						? Platform.OS !== "web"
							? props.navigation.navigate(PageNames.filter, {
								title: strings.selectTypologyWellness,
								filters: props.list,
								filterSelected: props.participationType,
								navigatePage: props.pageName,
								setFilter: (filter: any) => {
									props.setEvent({ ...props.event, participationType: filter.type });
									props.setParticipationType({ key: filter.type, label: filter.label });

								},
							})
							: props.setOpenParticipationComponent(!props.openParticipationTypeComponent)
						: null
				}
			>
				<Text
					style={[
						styles.selectTitle,
						(props.event?.participationType !== "") ? { color: colors.black } : { color: colors.grey },
						props.isReadOnly ? { backgroundColor: colors.greyReadOnly, opacity: 0.8 } : {},
					]}
				>
					
					{(props.event?.participationType !== "") ? props.participationType?.label : strings.select}
				</Text>
				{props.openParticipationTypeComponent ? (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleUp}></FontAwesomeIcon>
				) : (
					<FontAwesomeIcon style={styles.selectIcon} size={15} icon={faAngleDown}></FontAwesomeIcon>
				)}
			</Pressable>
			{props.openParticipationTypeComponent ? (
				<View style={[styles.selectComponent]}>
					<FlatList
						data={props.list}
						renderItem={({ item }) => {
							return (
								<Pressable
									style={[styles.rowView, { borderBottomColor: colors.grey3, borderBottomWidth: 1, zIndex: 9999 }]}

									onPress={() => {
										props.setEvent({
											...props.event,
											place: { _id: "", name: "", placeManagerId: "", coordinates: [null, null] },
											participationType: item.type
										});
										props.setParticipationType({ key: item.type, label: item.label });
										props.setOpenParticipationComponent(false);
									}}
								>
									<Text style={[styles.fieldDescription, { padding: 10 }]} numberOfLines={1}>
										{item.label}
									</Text>
									{(props.event.participationType === item.type) ? (
										<FontAwesomeIcon style={styles.checkIcon} size={15} icon={faCheck}></FontAwesomeIcon>
									) : null}
								</Pressable>
							);
						}}
						keyExtractor={(item) => item.participationType}
						keyboardShouldPersistTaps="handled"
					/>
				</View>
			) : null}
		</View>
	);
};

export default ParticipationTypeComponent;